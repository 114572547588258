import React, { Dispatch, SetStateAction } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';

import { UseOutletContext } from '../Main';
import { User } from '../../../models/user.model';
import { AppService} from '../../../models/app.service.model';
import { Company}  from '../../../models/company.model';
import { Contact } from '../../../models/contact.model';
import { TreasuryAccount } from '../../../models/treasury.account.model';
import { AccountingAccount } from '../../../models/accounting.account.model';

export interface ContactPageProps {};

type ContexType = {
  setRoute: Dispatch<SetStateAction<{path: {root: string, branch: string} | null, company: boolean} | null>>,
  userLoggedIn: User | undefined | null,
  appServiceForUser: AppService | undefined | null,
  companyForUser: Company | undefined | null,
  accountingAccountsForCompany: AccountingAccount[] | null | undefined,
  treasuryAccountsForCompany: TreasuryAccount[] | null | undefined,
  contactsForCompany: Contact[] | undefined | null,
  loadCompanyForUser: Function,
  loadContactsForCompany: Function
};

const ContactPage: React.FunctionComponent<ContactPageProps> = props => {
  const {setRoute, userLoggedIn, appServiceForUser, companyForUser, accountingAccountsForCompany, treasuryAccountsForCompany, contactsForCompany, loadCompanyForUser, loadContactsForCompany} = UseOutletContext()

  return (
    <Outlet context={{setRoute, userLoggedIn, appServiceForUser, companyForUser, accountingAccountsForCompany, treasuryAccountsForCompany, contactsForCompany, loadCompanyForUser, loadContactsForCompany}}></Outlet>
  );
}

export function UseOutletContextContact() {
  return useOutletContext<ContexType>()
};

export default ContactPage;
