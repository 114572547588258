import { Language } from "../languages/labels/language.labels";

export function sortList(lang: Language): {code: string, display: string}[] {
  return [
    { code: 'asc', display: lang.labels.ascending },
    { code: 'desc', display: lang.labels.descending }
  ]
}

export function sortValue(lang: Language, code: string): string {
  let contactType = sortList(lang).find((item) => (item.code === code))
  return contactType ? contactType.display : lang.labels.undefined
}
