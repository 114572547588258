import { Language } from "../languages/labels/language.labels";

export function monthList(lang: Language): {code: string, display: string}[] {
  return [
    { code: 'january', display: lang.labels.january },
    { code: 'february', display: lang.labels.february },
    { code: 'march', display: lang.labels.march },
    { code: 'april', display: lang.labels.april },
    { code: 'may', display: lang.labels.may },
    { code: 'june', display: lang.labels.june },
    { code: 'july', display: lang.labels.july },
    { code: 'august', display: lang.labels.august },
    { code: 'september', display: lang.labels.september },
    { code: 'october', display: lang.labels.october },
    { code: 'november', display: lang.labels.november },
    { code: 'december', display: lang.labels.december }
  ]
}

export function monthValue(lang: Language, code: string): string {
  let month = monthList(lang).find((item) => (item.code === code))
  return month ? month.display : lang.labels.undefined
}
