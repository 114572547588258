import { Language } from "../languages/labels/language.labels";

export function contactTypeList(lang: Language): {code: string, display: string}[] {
  return [
    { code: 'company', display: lang.labels.company },
    { code: 'person', display: lang.labels.person }
  ]
}

export function contactTypeValue(lang: Language, code: string): string {
  let contactType = contactTypeList(lang).find((item) => (item.code === code))
  return contactType ? contactType.display : lang.labels.undefined
}
