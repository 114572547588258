export const contentSpanish = {
  code: "ES",
  labels: {
    // App Digital Admin
    digitalAdmin: "Digital Admin",
    digitalAdminApp: "App Digital Admin",
    digitalAdminPro: "Digital Admin Pro",
    digitalAdminSarl: "Digital Admin SARL",
    welcomeToDigitalAdmin: "Bienvenido a Digital Admin",
    purchasePro: "Comprar Digital Admin Pro",
    signIn: "Iniciar sesión en Digital Admin",

    // Words sentences
    about: "Acerca de",
    successful: "Con éxito",
    error: "Error",
    accountSettings: "Configuración de la cuenta",
    actions: "Acciones",
    additional: "Adicional",
    address: "Dirección",
    administrativeArea: "Area administrativa / Departamento",
    amount: "Cantidad",
    amountTotal: "Monto total",
    apply: "Aplicar",
    approved: "Aprobado",
    ascending: "Ascendente",
    addContact: "Agregar contacto",
    addContent: "Agregar contenido",
    addSignature: "Agregar firma",
    avatar: "Avatar",
    back: "Atras",
    bban: "BBAN",
    bicSwift: "BIC/SWIFT",
    bimester: "Bimestre",
    calculatedValuesAreReferential: "Los valores calculados son referenciales",
    cancel: "Cancelar",
    canceled: "Cancelada",
    city: "Ciudad",
    comment: "Comentario",
    completed: "Completado",
    companyContact: "Contacto de la empresa",
    content: "Contenido",
    registerNumber: "Numero de registro",
    concerning: "Asunto",
    contactInformation: "Información del contacto",
    continue: "Continuar",
    country: "País",
    create: "Crear",
    createdDate: "Fecha de creación",
    currency: "Moneda",
    customer: "Cliente",
    date: "Fecha",
    day: "Día",
    days: "Días",
    default: "Predeterminada",
    delete: "Eliminar",
    descending: "Descendente",
    description: "Descripción",
    detail: "Detalle",
    details: "Detalles",
    discard: "Descartar",
    doNotGroup: "No agrupar",
    edit: "Editar",
    email: "Correo electrónico",
    end: "Fin",
    endDate: "Fecha de finalización",
    excel: "Excel",
    export: "Exportar",
    filter: "Filtros",
    filterOptions: "Opciones de filtro",
    firstName: "Nombres",
    group: "Grupo",
    halfYear: "Medio año",
    iban: "IBAN",
    identity: "Identidad",
    inProgress: "En progreso",
    justNow: "Justo ahora",
    lastDay: "Último día",
    lastName: "Apellidos",
    legalInformation: "Información legal",
    list: "Lista",
    location: "Ubicación",
    menu: "Menú",
    message: "Mensaje",
    noNumber: "Sin número",
    noMatchingRecordsFound: "No se encontraron registros coincidentes",
    month: "Mes",
    months: "Meses",
    number: "Número",
    sortAlphabetically: "Ordenar alfabéticamente",
    pages: "Páginas",
    pdf: "PDF",
    pending: "Pendiente",
    person: "Persona",
    phone: "Teléfono",
    phoneNumber: "Número de teléfono",
    priceGross: "Precio bruto",
    priceNet: "Precio neto",
    priceTotal: "Precio total",
    product: "Producto",
    products: "Productos",
    qrIban: "QR-IBAN",
    quantity: "Cantidad",
    rejected: "Rechazado",
    remove: "Remover",
    removeReference: "Eliminar referencia",
    removeContent: "Eliminar contenido",
    removeSignature: "Eliminar firma",
    removeRequirement: "Eliminar bloque",
    removeVoucher: "Eliminar comprobante",
    requirement: "Bloque",
    requirements: "Bloques",
    reset: "Restablecer",
    saveChanges: "Guardar cambios",
    searchResults: "Resultados de búsqueda",
    selectExportFormat: "Seleccionar formato de exportación",
    send: "Enviar",
    service: "Servicio",
    services: "Servicios",
    appService: "Servicio de la aplicacion",
    appServices: "Servicios de la aplicacion",
    signature: "Firma",
    signatures: "Firmas",
    start: "Inicio",
    startDate: "Fecha de inicio",
    status: "Estado",
    street: "Calle",
    subTotal: "Subtotal",
    support: "Soporte",
    symbol: "Símbolo",
    tax: "Impuesto",
    taxRate: "Tasa de impuestos",
    taxTotal: "Total de impuestos",
    trimester: "Trimestre",
    total: "Total",
    totalAmount: "Monto total",
    top: "Top",
    type: "Tipo",
    timeInterval: "Intervalo de tiempo",
    interval: "Intervalo",
    unitMeasure: "Unidad de medida",
    unitPrice: "Precio unitario",
    update: "Actualizar",
    validity: "Validez",
    value: "Valor",
    vatNumber: "Número VAT/IDE",
    webSite: "Sitio web",
    week: "Semana",
    weeks:  "Semanas",
    view: "Ver",
    yes: "Si",
    zipCode: "Código postal",
    gross: "Bruto",
    net: "Neto",
    rebate: "Rebaja",
    rebateTotal: "Total de rebaja",
    selectOptionAddAmountPercentageDesired: "Seleccione una opción y agregue la cantidad o porcentaje deseado",
    changeImage: "Cambiar imagen",
    changeLogo: "Cambiar logotipo",
    exportError: "Error al exportar",
    failedToLoadTheScript: "No se pudo cargar el script",
    identificationNumberForACompany: "Número de identificación de una empresa que desea realizar operaciones a nivel europeo",
    internationalBankAccountNumberIban: "Número de cuenta bancaria internacional (IBAN)",
    listOfCompaniesIHaveAccess: "Lista de empresas a las que tengo acceso",
    noRecordsFound: "No se encontraron registros",
    noResultsFound: "No se encontraron resultados",
    removeImage: "Remover imagen",
    selectTheTypeOfContact: "Seleccione el tipo de contacto que desea crear",
    specifyTheUniqueUUID: "Especifique el código UUID único de un usuario o empresa",
    theDefaultBankAccountIsTheCompanys: "La cuenta de tesorería predeterminada es la de la empresa",
    theDefaultCurrencyIsTheCompanys: "La moneda predeterminada es la de la empresa",
    theDefaultShapeOrVersionOfAQrInvoiceToDownloadIsTheClients: "La forma o versión predeterminada de una factura QR para descargar es la del cliente",
    theDefaultLanguageToDownloadIsTheClients: "El idioma predeterminado para descargar es el del cliente",
    youMustAcceptTheTermsAndConditions: "Debe aceptar los términos y condiciones",
    acceptingInvitationToAccessCompany: "Aceptando la invitación para acceder a la empresa",
    verificationYourAccount: "Verificación de su cuenta",
    addCompany: "Agregar empresa",
    allInLicense: "Licencia completa",
    buyNow: "Comprar ahora",
    comingSoon: "Próximamente",
    licenseTerms: "Términos de licencia",
    pageNotFound: "Página no encontrada",
    viewInvitations: "Ver invitaciones",
    purchase: "Comprar",
    reload: "Recargar",
    systemError: "Error del sistema",
    dashboard: "Dashboard",
    abbreviation: "Abreviatura",
    amountPurchases: "Monto de compras",
    amountSales: "Monto de ventas",
    best: "Mejor",
    companyInsight: "Perspectiva de la empresa",
    comparativeInterval: "Intervalo de comparación",
    comparisonIncomeAndExpenses: "Comparación de ingresos & gastos",
    customerPayments: "Pagos del cliente",
    customRange: "Rango personalizado",
    debtPercent: "Deuda(%)",
    distributionOfQuotes: "Distribución de cotizaciones según su estado",
    distributionOfOrders: "Distribución de pedidos según su estado",
    expirationDate: "Fecha de vencimiento",
    invoicesStillToBePaid: "Facturas pendientes de pago",
    invoicesStillToBePaidByTheCustomer: "Facturas pendientes de pago por parte del cliente",
    invoicesWithMoreDebt: "Facturas con más deuda",
    ordersByState: "Pedidos por estado",
    ordersToBilled: "Pedidos a facturar",
    paidPercent: "Pagado(%)",
    percentage: "Porcentaje",
    quotesByState: "Cotizaciones por estado",
    quotesAccepted: "Cotizaciones aceptadas",
    quotesApproved: "Cotizaciones aprobadas",
    quotesToExpire: "Cotizaciones a expirar",
    retailAnalysisSample: "Muestra de análisis minorista",
    representationOfSalesAndPurchases: "Representación de las ventas y compras de una empresa por mes durante un año",
    salesVsPurchases: "Ventas vs Compras",
    thisBimester: "Este bimestre",
    thisTrimester: "Este trimestre",
    thisHalfYear: "Este medio año",
    thisMonth: "Este mes",
    thisWeek: "Esta semana",
    thisYear: "Este año",
    today: "Hoy",
    turnout: "Monto de facturas",
    viewAll: "Ver todo",
    worst: "Peor",
    yesterday: "Ayer",
    fixedAmount: "Cantidad fija",
    discount: "Descuento",
    account: "Cuenta",
    changeEmail: "Cambiar correo electrónico",
    changePassword: "Cambiar contraseña",
    confirmNewPassword: "Confirmar nueva contraseña",
    currentPassword: "Contraseña actual",
    emailAddress: "Dirección de correo electrónico",
    enterNewEmailAddress: "Ingrese una nueva dirección de correo electrónico",
    fullName: "Nombre completo",
    newPassword: "Nueva contraseña",
    pleaseCheckYourEmailInbox: "Por favor revise su bandeja de entrada de su correo electrónico",
    profileCompleation: "Perfil de finalización",
    profileDetails: "Detalles del perfil",
    signInMethod: "Método de inicio de sesión",
    updateEmail: "Actualizar correo electrónico",
    updatePassword: "Actualizar contraseña",
    updateUser: "Actualizar usuario",
    validateAccount: "Validar cuenta",
    viewUser: "Ver usuario",
    user: "Usuario",
    users: "Usuarios",
    confirmPassword: "Confirmar contraseña",
    createAnAccount: "Crear una cuenta",
    enterTheNewPasswordForTheMail: "Ingrese la nueva contraseña para el correo",
    enterYourEmailToResetYourPassword: "Ingrese su correo electrónico para restablecer su contraseña",
    goToLoginPage: "Ir a la página de inicio de sesión",
    goToPreviousPage: "Ir a la página anterior",
    language: "Idioma",
    languages: "Idiomas",
    myProfile: "Mi perfil",
    notVerified: "No verificado",
    password: "Contraseña",
    resetPassword: "Reiniciar contraseña",
    signInHere: "Ingrese aquí",
    signOut: "Cerrar sesión",
    submit: "Enviar",
    userProfile: "Perfil de usuario",
    use8OrMoreCharacters: "Usa 8 o más caracteres con una combinación de letras (minúsculas y mayúsculas), números y símbolos",
    verified: "Verificado",
    welcome: "Bienvenido",
    company: "Empresa",
    companyParameterSettings: "Configuración de parámetros de la empresa",
    companies: "Empresas",
    current: "Actual",
    addDeadlinePaymentParameter: "Agregar parámetro de pago de fecha límite",
    addImage: "Agregar imagen",
    addTax: "Agregar impuesto",
    addDeadline: "Agregar fecha límite",
    addTaxRateParameter: "Agregar parámetro de tasa de impuestos",
    addUnit: "Agregar unidad",
    addUnitMeasureParameter: "Agregar parámetro de medida de unidad",
    advanceSettings: "Configuración avanzada",
    aNewCompanyWillBeCreated: "Se creará una nueva empresa a partir de los datos ingresados",
    browseUsers: "Explorar usuarios",
    companyAddress: "Dirección de la empresa",
    companyAdvanceSettings: "Configuración avanzada de la empresa",
    companyDownloadSettings: "Configuración de descarga de la empresa",
    companyGeneralSettings: "Configuración general de la empresa",
    companyIdentity: "Identidad de la empresa",
    companyLogo: "Logotipo de la empresa",
    companyName: "Nombre de la empresa",
    createCompany: "Crear empresa",
    createAndSave: "Crear & Guardar",
    createNewCompany: "Crear nueva empresa",
    designOfDocumentToDownloadQuotesAndInvoices: "Diseño de documento para descargar cotizaciones pedidos y facturas",
    downloadSettings: "Configuración de descarga",
    dueDates: "Fechas de vencimiento para el pago de una factura",
    editCompany: "Editar empresa",
    free: "Gratis",
    generalSettings: "Configuraciones generales",
    helpPage: "Página de ayuda",
    image: "Imagen",
    inviteUser: "Invitar a un usuario",
    legal: "Legal",
    listUsers: "Lista de usuarios",
    logo: "Logotipo",
    name: "Nombre",
    new: "Nuevo",
    noResultFound: "No se encontraron resultados",
    overview: "Resumen",
    parameterSettings: "Configuración de parámetros",
    pleaseTryAgainWithADifferentQuery: "Por favor inténtalo de nuevo con una consulta diferente",
    predefinedTaxesFees: "Tasas de impuestos predefinidas",
    private: "Privado",
    removeLogo: "Eliminar logotipo",
    selectModel: "Seleccionar modelo",
    selectAndSaveDesiredLayoutToDownload: "Seleccione y guarde el diseño deseado para descargar la cotización, el pedido y la factura",
    selectAndSaveOneToFourDesiredColors: "Seleccione y guarde de uno a cuatro colores deseados para el diseño de cotizaciones, pedidos y facturas",
    sendInvitation: "Enviar invitación",
    settings: "Configuraciones",
    taxValue: "Valor fiscal",
    templateColors: "Colores de plantilla",
    templateModel: "Modelo de plantilla",
    theEnteredInformationHasAlreadyValidated: "La información ingresada ya ha sido validada, ahora puede confirmar y crear una nueva empresa",
    theNonRequiredFields: "Los campos no obligatorios que se hayan llenado con datos erróneos se eliminarán y no se guardarán",
    unitsOfMeasure: "Unidades de medida para una factura",
    uuidCode: "Código UUID",
    uuidCompany: "UUID de empresa",
    uuidOrName: "UUID/Nombre",
    usersWhoAreSubscribed: "Usuarios que están suscritos y tienen acceso a la empresa",
    viewCompany: "Ver empresa",
    viewMoreUsers: "Ver mas usuarios",
    treasury: "Tesorería",
    treasuryAccount: "Cuenta de tesorería",
    treasuryAccounts: "Cuentas de tesorería",
    codeOfBelongingToABankingOrganization: "Código de pertenencia a una entidad bancaria",
    createNewTreasuryAccount: "Crear nueva cuenta de tesorería",
    createTreasuryAccount: "Crear cuenta de tesorería",
    listTreasuryAccounts: "Lista de cuentas de tesorería",
    membership: "Membresía",
    nameOfAccount: "Nombre de la cuenta",
    contactUs: "Contáctenos",
    typeOfTreasuryAccount: "Tipo de cuenta de tesorería",
    updateTreasuryAccountData: "Actualizar datos de cuenta de tesorería",
    viewTreasuryAccountsList: "Ver lista de cuentas de tesorería",
    notification: "Notificación",
    notifications: "Notificaciones",
    accept: "Aceptar",
    accessToCompany: "Acceso a la empresa",
    activities: "Actividades",
    answers: "Respuestas",
    close: "Cerrar",
    companyInvitations: "Invitaciones de empresa",
    hasReceivedANotification: "Ha recibido una notificación",
    hasReceivedAQuote: "Ha recibido una cotización",
    hasReceivedAnInvitation: "Ha recibido una invitación",
    hasReceivedAnOrder: "Ha recibido una pedido",
    newNotification: "Nueva notificación",
    noNotifications: "Sin notificaciones",
    other: "Otro",
    quoteEvaluation: "Evaluación de la cotización",
    quoteNotification: "Notificación de la cotización",
    reject: "Rechazar",
    contact: "Contacto",
    contacts: "Contactos",
    basicBankAccountNumber: "Número de cuenta de tresorería básica",
    bankIdentifierCode: "Código de identificación bancaria / Sociedad para las telecomunicaciones financieras interbancarias mundiales",
    contactsList: "Lista de Contactos",
    contactUUID: "UUID de contacto",
    contactIdentity: "Identidad de contacto",
    contactIsNotAnAppUser: "El contacto no es un usuario de la aplicación",
    contactType: "Tipo de contacto",
    contactUuidAndType: "UUID de contacto y tipo",
    countryContact: "País de contacto",
    createContact: "Crear contacto",
    createNewContact: "Crear nuevo contacto",
    createContactCompanyStructure: "Crear una estructura de contacto de tipo empresa",
    createContactPersonStructure: "Crear una estructura de contacto de tipo persona",
    deleteContact: "Eliminar contacto",
    deleteSelected: "Eliminar seleccionados",
    definingTheIdentityOfContact: "Definición de la identidad del contacto",
    definingTheInformationOfContact: "Definición de la información de contacto",
    definingTheLegalInformationOfContact: "Definición de la información legal de contacto",
    definingTheSettingsToDownloadQuotesAndInvoices: "Definición de la configuración para descargar cotizaciones y facturas",
    dragOrClickOnTheBoxToAddTheCompanyLogo: "Arrastre o haga clic en el cuadro para agregar el logotipo de la empresa",
    editContact: "Editar contacto",
    enterThePostalCodeOfTheCity: "Introduce el código postal de la ciudad",
    exportContacts: "Exportar contactos",
    exportEntryContacts: "Exportar asientos contables",
    extendChanges: "Extender cambios",
    fileDownloadSettings: "Configuración de descarga de archivos",
    financialEntity: "Entidad financiera",
    identityOfContact: "Identidad del contacto",
    ifAddressDoesNotHaveANumber: "Si la dirección no tiene un número, ingrese 'nn' o 'NN",
    ifNecessaryEnterSomethingReferentialRegardingAddress: "Si es necesario, ingrese algo referencial con respecto a la dirección",
    informationOfContact: "Información de contacto",
    internationalBankAccountNumber: "Número de cuenta de Banco Internacional",
    languageToDownload: "Idioma para descargar cotización, pedido o factura",
    legalInformationOfContact: "Información legal de contacto",
    qrType: "Tipo de QR",
    shapesOrVersionsOfQR: "Formas o versiones de un QR-Factura",
    selectCompanyAndSave: "Seleccionar empresa y guardar",
    selectCompaniesToCreateContact: "Seleccione las empresas a la que se va a agregar un nuevo contacto",
    typeOfContact: "Tipo de contacto",
    updateContactData: "Actualizar datos de contacto",
    uuidAndType: "UUID y tipo",
    userUuid: "UUID de usuario",
    viewContact: "Ver contacto",
    viewContactsList: "Ver lista de contactos",
    quote: "Cotización",
    quotes: "Cotizaciones",
    addQuote: "Agregar cotización",
    approveQuote: "Aprobar cotización",
    companyQuoteList: "Lista de cotizaciones de la empresa",
    createQuote: "Crear cotización",
    newQuote: "Nueva cotización",
    dateOfQuote: "Fecha de cotización, esto se puede modificar más adelante",
    editQuote: "Editar cotización",
    enterADateOrSelectADefaultPaymentDeadline: "Ingrese una fecha o seleccione una fecha límite de pago predeterminada",
    expired: "Caducado",
    exportQuotes: "Exportar cotizaciones",
    generateQuote: "Generar cotización",
    inTime: "A tiempo",
    listQuotes: "Lista de cotizaciones",
    quoteDate: "Fecha de cotización",
    quoteGroups: "Cotizaciones grupos",
    quoteNumber: "Número de cotización",
    quoteReference: "Referencia de la cotización",
    quoteStatus: "Estado de la cotización",
    quoteValidity: "Validez de cotización",
    rejectQuote: "Rechazar cotización",
    sendQuote: "Enviar cotización",
    updateQuote: "Actualizar cotización",
    viewQuote: "Ver cotización",
    purchases: "Compras",
    sales: "Ventas",
    viewInvoices: "Ver facturas",
    viewOrders: "Ver pedidos",
    viewQuotes: "Ver cotizaciones",
    viewProviderOrders: "Ver pedidos de proveedores",
    viewProviderInvoices: "Ver facturas de proveedores",
    viewExpenses: "Ver gastos",
    expenses: "Gastos",
    addExpense: "Agregar gasto",
    createExpense: "Crear gasto",
    editExpense: "Editar gasto",
    expenseDate: "Fecha de gasto",
    expenseGroups: "Grupos de gastos",
    expenseNumber: "Número de gasto",
    expenseValidity: "Validez del gasto",
    invalidateExpense: "Invalidar gasto",
    listExpenses: "Lista de gastos",
    removeExpense: "Eliminar gasto",
    updateExpense: "Actualizar gasto",
    validateExpense: "Validar gasto",
    viewExpense: "Ver gasto",
    addProviderInvoice: "Agregar factura de proveedor",
    createProviderInvoice: "Crear Factura de Proveedor",
    editProviderInvoice: "Editar factura de proveedor",
    invalidateProviderInvoice: "Invalidar factura de proveedor",
    listProviderInvoices: "Lista de facturas de proveedores",
    myBankAccount: "Mi cuenta de tresorería",
    provider: "Proveedor",
    providerBankAccount: "Cuenta de tresorería del proveedor",
    providerInvoiceDate: "Fecha de la factura del proveedor",
    providerInvoiceGroups: "Grupos de facturas del proveedor",
    providerInvoiceStatus: "Estado de la factura del proveedor",
    providerInvoiceValidity: "Validez de la factura del proveedor",
    providerInvoices: "Facturas proveedor",
    providerOrderNumber: "Número de pedido del proveedor",
    updateProviderInvoice: "Actualizar factura de proveedor",
    validateProviderInvoice: "Validar factura de proveedor",
    viewProviderInvoice: "Ver factura del proveedor",
    addProviderOrder: "Agregar pedido de proveedor",
    approveProviderOrder: "Aprobar pedido de proveedor",
    cancelProviderOrder: "Cancelar pedido de proveedor",
    createProviderOrder: "Crear pedido de proveedor",
    editProviderOrder: "Editar pedido de proveedor",
    generateProviderInvoice: "Generar factura de proveedor",
    generateProviderOrder: "Generar pedido de proveedor",
    listProviderOrders: "Lista de pedidos de proveedores",
    providerOrderDate: "Fecha de pedido de proveedor",
    providerOrders: "Pedidos de proveedores",
    providerOrderGroups: "Grupos de pedidos de proveedores",
    providerOrderReference: "Referencia del pedido de proveedor",
    providerInvoiceReference: "Referencia de la factura de proveedor",
    expenseReference: "Referencia del gasto",
    providerOrderStatus: "Estado de la orden de proveedor",
    updateProviderOrder: "Actualizar pedido de proveedor",
    viewProviderOrder: "Ver pedido de proveedor",
    addVouchers: "Añadir comprobante(s)",
    download: "Descargar",
    file: "Archivo",
    lastModified: "Última modificación",
    size: "Tamaño",
    vouchers: "Comprobantes",
    addFiles: "Añadir archivos",
    attachFiles: "Adjuntar archivos",
    removeAll: "Eliminar todo",
    uploadFiles: "Subir archivos",
    orders: "Pedidos",
    order: "Pedido",
    orderEvaluation: "Evaluación del pedido",
    orderGroups: "Grupos de pedidos",
    orderNotification: "Notificación de pedido",
    orderReference: "Referencia del pedido",
    orderStatus: "Estado del pedido",
    addOrder: "Agregar pedido",
    approveOrder: "Aprobar pedido",
    cancelOrder: "Cancelar pedido",
    companyListOfOrders: "Lista de pedidos de la empresa",
    createOrder: "Crear pedido",
    delivery: "Entrega",
    deliveyAddress: "Dirección de entrega",
    deliveryNote: "Nota de entrega",
    editOrder: "Editar pedido",
    exportOrder: "Exportar pedido",
    exportOrders: "Exportar pedidos",
    generateInvoice: "Generar factura",
    generateOrder: "Generar pedido",
    listOrders: "Lista de pedidos",
    orderDate: "Fecha del pedido",
    orderFile: "Archivo de pedido",
    orderNumber: "Número de pedido",
    selectExportType: "Seleccionar tipo de exportación",
    sendOrder: "Enviar pedido",
    thisOptionIsUniqueAndNewInvoice: "Esta opción es única y no se puede crear una nueva factura a partir de este pedido",
    updateOrder: "Actualizar pedido",
    viewOrder: "Ver pedido",
    invoice: "Factura",
    invoices: "Facturas",
    addInvoice: "Agregar factura",
    amountDebt: "Monto de deuda",
    amountPaid: "Monto pagado",
    companyInvoiceList: "Lista de facturas de la empresa",
    createInvoice: "Crear factura",
    editInvoice: "Editar factura",
    exportInvoices: "Exportar facturas",
    debt: "Deuda",
    fullyPaid: "Totalmente pagado",
    invalidInvoices: "Facturas no válidas",
    invalidateInvoice: "Invalidar factura",
    invoiceGroups: "Grupos de facturas",
    invoiceDate: "Fecha de factura",
    invoiceNumber: "Número de factura",
    invoiceStatus: "Estado de factura",
    invoiceValidity: "Validez de factura",
    listInvoices: "Lista de facturas",
    notPayed: "No pagado",
    partiallyPaid: "Parcialmente pagado",
    payed: "Pagado",
    thisInvoiceHasNoPayments: "Esta factura no tiene pagos",
    updateInvoice: "Actualizar factura",
    validated: "Validado",
    validateInvoice: "Validar factura",
    viewInvoice: "Ver factura",
    youWillBeAbleToUndoThisAction: "Podrá deshacer esta acción",
    payment: "Pago",
    payments: "Pagos",
    amountToPay: "Cantidad a pagar",
    createNewPayment: "Crear nuevo pago",
    deleteSomePayment: "Eliminar algún pago",
    doNotDeletePayments: "No eliminar pagos",
    makePayment: "Realizar un pago",
    method: "Método",
    paymentDate: "Fecha de pago",
    paymentMethod: "Método de pago",
    save: "Guardar",
    sourceOfIncome: "Fuente de ingresos",
    sourceTheAmount: "Fuente a donde se ingresa el monto de dinero de la transaccion",
    cash: "Efectivo",
    card: "Tarjeta",
    payPal: "PayPal",
    transfer: "Transferencia",
    mobile: "Móvil",
    thisOptionIncludeTaxPrice: "Esta opción incluye el impuesto en el precio indicado",
    thisOptionNotIncludeTaxPrice: "Esta opción no incluye el impuesto en el precio indicado",
    addOrRemoveContactCompanies: "Agregar o eliminar contactos de empresas",
    notifyMe: "Notificarme",
    background: "Fondo",
    inventory: "Inventario",
    viewProducts: "Ver productos",
    viewProduct: "Ver producto",
    viewServices: "Ver servicios",
    viewCategories: "Ver categorías",
    viewStorageCenter: "Ver centro de almacenamiento",
    appLanguage: "Idioma de la aplicación",
    theUuidCanBeUsedToCreateNnewContact: "El UUID se puede utilizar para crear un nuevo contacto",
    settingInformation: "Información de configuración",
    addOrRemoveCompanies: "Agregar/Eliminar empresas",
    notGrouped: "Sin agrupar",
    notFiltered: "Sin filtrar",
    orderValidity: "Validez del pedido",
    expiration: "Vencimiento",
    quoteExpiration: "Vencimiento de cotización",
    orderExpiration: "Vencimiento de orden",
    invoiceExpiration: "Vencimiento de factura",
    discountTotal: "Descuento total",
    addService: "Agregar servicio",
    addProduct: "Agregar producto",
    noRecords: "Sin registros",
    quantityMinimumForRebate: "Cantidad mínima para rebaja",
    thisOptionRebateIsFixedAmount: "Esta opción indica que la rebaja es un monto fijo",
    thisOptionRebateIsPercentage: "Esta opción indica que la rebaja es un porcentaje",
    thisOptionRebateIsNone: "Esta opción indica que la rebaja es nula",
    totalAfterRebates: "Total después de rebajas",
    calculator: "Calculadora",
    selectTypeRebateAndEnterValueAndQuantity: "Seleccione el tipo de rebaja en e ingrese el valor y cantidad",
    reference: "Referencia",
    newOrder: "Nuevo pedido",
    invoiceReference: "Referencia de la factura",
    newInvoice: "Nueva factura",
    providerOrderValidity: "Validez del pedido del proveedor",
    expenseStatus: "Estado de gastos",
    newProviderOrder: "Nuevo pedido de proveedor",
    newProvederInvoice: "Nueva factura de proveedor",
    newExpense: "Nuevo gasto",
    viewVoucher: "Ver comprobante",
    informationAdditional: "Información adicional",
    no: "No",
    bank: "Banco",
    fullNameBankAccountHolder: "Nombre completo del titular de la cuenta bancaria",
    bankAccountHolder: "Titular de la cuenta bancaria",
    isNecessaryToHaveReferenceToActivateOption: "Es necesario contar con una referencia para activar esta opción",
    isNecessaryToHaveAtLeastTwoTreasuryAccountsToActivateOption: "Es necesario contar con al menos dos cuentas de tesoreria para activar esta opción",
    none: "Ninguno",
    undefined: "Indefinido",
    colorCombinationForDesignOfDocumentToDownload: "Combinación de colores para el diseño del documento a descargar",
    all: "Todo",
    lettersTitlesSubtitles: "Letras títulos subtítulos",
    firstBar: "Primera barra",
    secondBar: "Segunda barra",
    barLetters: "Letras de la barra",
    qrIbanAndQrReference: "QR-IBAN y QR-Referencia",
    ibanAndStructuredCreditorReference: "IBAN y Referencia de Acreedor Estructurado (SCOR)",
    ibanWithoutReference: "IBAN Sin Referencia",
    cashAccount: "Cuenta efectiva",
    postalAccount: "Cuenta postal",
    bankAccount: "Cuenta bancaria",
    productsList: "Lista de productos",
    category: "Categoría",
    categories: "Categorias",
    listCategories: "Lista de categorias",
    tags: "Etiquetas",
    variants: "Variantes",
    photography: "Fotografía",
    reminder: "Recordatorio",
    reminders: "Recordatorios",
    paymentStatus: "Estado de pago",
    year: "Año",
    createReminder: "Crear recordatorio",
    updateReminder: "Actualizar recordatorio",
    makeCopy: "Crear copia",
    reminderTotal: "Total recordatorio",
    inDispute: "En disputa",
    createReminderFee: "Crear tarifa de recordatorio",
    addCategory: "Agregar categoría",
    qtyAssets: "QTY activos",
    qtyServices: "QTY servicios",
    qtyProducts: "QTY productos",
    viewCategory: "Ver categoría",
    editCategory: "Editar categoria",
    deleteCategory: "Eliminar categoria",
    names: "Nombres",
    listCategoryItems: "Lista de elementos de la categoria",
    editListing: "Editar listado",
    updateCategoryItemList: "Actualizar lista de elementos de la categoría",
    updateCategory: "Actualizar categoría",
    nameInOtherLanguages: "Nombre en otros idiomas",
    enterInLanguageWant: "Ingresa el idioma que desees",
    enterNameOfRequestedLanguages: "Ingresa el nombre de los idiomas solicitados",
    enterOrModifyInLanguage: "Ingresa o modifica en el idioma que desees",
    general: "General",
    categoryName: "Nombre de la categoría",
    categoryType: "Tipo de categoría",
    createCategory: "Crear categoría",
    editProduct: "Editar producto",
    deleteProduct: "Eliminar producto",
    productReferencePictures: "Imágenes de referencia del producto",
    code: "Código",
    sku: "SKU",
    codebar: "Codigo de barras",
    color: "Color",
    unit: "Unidad",
    price: "Precio",
    fixed: "Fijado",
    productName: "Nombre del producto",
    productDetails: "Detalles del producto",
    advanced: "Avanzado",
    addVariant: "Añadir variante",
    uniqueProductCodeAssignedByCompany: "Código de producto único asignado por la empresa",
    additionalCommentDescriptionProductVariant: "Comentario o descripcion adicional a la variante del producto",
    selectDiscountTypeAppliedToProduct: "Seleccione un tipo de descuento que se aplicará a este producto",
    discountType: "Tipo de descuento",
    selectDiscountTypeThatWillAppliedProduct: "Seleccione un tipo de descuento que se aplicará a este producto",
    noDiscount: "Sin descuento",
    setDiscountPercentage: "Establecer porcentaje de descuento",
    fixedDiscountedAmount: "Monto fijo del descuento",
    quantityMinimumDiscount: "Cantidad mínima para descuento",
    createProduct: "Crear producto",
    dropFilesHereOrClickUpload: "Suelte los archivos aquí o haga clic para cargarlos",
    dropFileHereOrClickUpload: "Suelte el archivo aquí o haga clic para cargarlo",
    uploadUpToFiles: "Cargue hasta 10 archivos",
    success: "Éxito",
    addNewProductVariant: "Agregar nueva variante de producto",
    editVariant: "Editar variante",
    editProductVariant: "Editar variante del producto",
    updateProduct: "Actualizar producto",
    saveVariant: "Guardar variante",
    servicesList: "Lista de servicios",
    viewService: "Ver servicio",
    editService: "Editar servicio",
    deleteService: "Eliminar servicio",
    serviceReferencePictures: "Imágenes de referencia de servicio",
    serviceName: "Nombre del servicio",
    serviceDetails: "Detalles del servicio",
    createService: "Crear servicio",
    updateService: "Actualizar servicio",
    uniqueServiceCodeAssignedByCompany: "Código de servicio único asignado por la empresa",
    selectDiscountTypeAppliedToService: "Seleccione un tipo de descuento que se aplicará a este servicio",
    expenseAccount: "Cuenta de gastos",
    vatAccount: "Cuenta de IVA",
    accountProduct: "Cuenta de producto",
    accountingAccount: "Cuenta contable",
    accountingAccounts: "Cuentas contables",
    accountingAccountPurchases: "Cuenta contable para compras",
    accountingAccountSales: "Cuenta contable para ventas",
    addAccountingAccount: "Agregar cuenta contable",
    rangeAllowedCodes: "Rango de codigos permitidos",
    effective: "Efectivo",
    ntdr: "Deuda tributaria neta TDNF",
    taf: "Tasa fija",
    vatSpecialRegime: "Régimen especial del IVA",
    methodAapply: "Metodo a aplicar",
    theVatRegimeCanOnlyChangedOnceAYear: "El regimen de la IVA solo se puede cambiar una vez al año, justo antes de empezar un nuevo año contable",
    debit: "Débito",
    credit: "Crédito",
    customerReceivable: "Cliente por cobrar",
    vatDue: "IVA adeudado",
    vatDebtMethod: "Método de la deuda del IVA",
    invoicing: "Facturación",
    cashing: "Cobro",
    valid: "Válido",
    invalid: "Inválido",
    colorIsInvalid: "Color inválido",
    theValueIsNotAValid: "El valor no es válido",
    theValueIsNotAValidUUIDOrNameUser: "El valor no es un UUID o nombre de usuario válido",
    theValueIsAtLeast8Digits: "El valor tiene al menos 8 dígitos",
    theValueDoesNotMatch: "El valor no coincide",
    theValueAlreadyExists: "El valor ya existe",
    limitFilesMaximum: "Límite de 10 archivos máximo",
    required: "Requerido",
    theValueIsRequired: "El valor es obligatorio",
    colorIsRequired: "El color es obligatorio",
    requirementsIsRequired: "Se requieren bloques",
    contentsToRequirementIsRequired: "Se requiere el contenido del bloque",
    transaction: "Transacción",
    createNewPaymentForInvoice: "Crear nuevo pago para la factura",
    addTransaction: "Agregar transacción",
    accountingEntry: "Asiento contable",
    amountAvailable: "Monto disponible",
    selectExistingTransaction: "Seleccionar transacción existente",
    totalAmountDebt: "Monto total de la deuda",
    presetCurrency: "Moneda preestablecida",
    depositAmount: "Monto de deposito",
    providerInvoice: "Factura del proveedor",
    expense: "Gasto",
    viewAccountingEntry: "Ver asiento contable",
    enterNewTransaction: "Introducir una nueva transacción",
    useExistingTransaction: "Utilizar una transacción existente",
    transactionToRecordPayment: "Transacción para poder registrar el pago",
    amountMoneyEnteredInTransaction: "Monto del dinero ingresado en la transacción",
    identifiedTransactions: "Transacciones identificadas",
    unidentifiedTransactions: "Transacciones no identificadas",
    totalDepositAmount: "Monto total del depósito",
    makeCorrespondingPayment: "Realizar el pago correspondiente",
    balance: "Saldo",
    accountingEntries: "Asientos contables",
    contactAccountStatements: "Estados de cuenta por contacto",
    contactBalance: "Saldo del contacto",
    accountStatement: "Estado de cuenta",
    invoiceIssuance: "Emisión de la factura",
    allocationInvoiceReminder: "Asignación por recordatorio de factura",
    transactionPostFinance: "Transacción a la cuenta PostFinance",
    balancePrevious: "Saldo anterior",
    balanceFavorContact: "Saldo a tu favor",
    balanceFavorCompany: "Saldo a nuestro favor",
    addCredit: "Añadir crédito",
    createClientCredit: "Crear un crédito del cliente",
    unidentifiedTransactionToRegisterInContact: "Transacción no identificada para registrarlo en el contacto",
    draft: "Borrador",
    beforeCreatingProviderInvoice: "Antes de crear una factura de proveedor",

    // Start sentence
    selectOneOption: "Seleccione una de las opciones,",
    iAgree: "Acepto &",
    youWantToChangeOrderStatusTo: "¿Desea cambiar el estado del pedido a",
    createdAt: "Creado en",
    updatedAt: "Actualizado en",
    validUntil: "Válido hasta",
    clickOn: "Hacer clic en",
    thisContactWillOnlyBeRegisteredInCompany: "Este contacto solo se registrara en la empresa",
    securedPaymentBy: "Pago asegurado por",
    anInvitationWillBeSent: "Se enviará una invitación para que un usuario tenga acceso a la empresa",
    ifYouNeedMoreInfo: "Si necesita más información, consulte en",
    toFinishCreationNewCompany: "Para finalizar con la creación de la nueva empresa, haga clic en",
    theInvitationToAccess: "La invitación para acceder a la empresa por parte del usuario",
    theQuoteThatYouSent: "La cotización que envió la empresa",
    youHaveAlreadyEvaluatedOrder: "Ya has evaluado la orden de la empresa",
    youHaveAlreadyEvaluatedQuote: "Ya has evaluado la cotización de la empresa",
    youHaveAlreadyRespondedTo: "Ya has respondido a",
    youHaveReceivedAQuote: "Ha recibido una cotización de la empresa",
    ifYouDoNotWishToExtendContactModificationsToOtherCompanies: "Si no desea extender las modificaciones de contacto a otras empresas, no marque ninguna y seleccione",
    youWantToChangeQuoteStatusTo: "Desea cambiar el estado de la cotización a",
    youWantToChangeProviderOrderStatusTo: "Desea cambiar el estado del pedido de proveedor a",
    theOrderThatYouSent: "El pedido que envió la empresa",
    youHaveReceivedAnOrder: "Ha recibido un pedido de la empresa",
    theCurrentCompanyIs: "La empresa actual es",
    toCreateA: "Para crear una",
    createdBy: "Creado por",
    groupBy: "Agrupar por",
    checkOrUncheck: "Marque o desmarque los",
    ifYouWishChangeVatRegimePleaseContact: "Si desea cambiar el regimen del IVA tiene que contactar a",
    ifYouInvalidate: "Si invalida la factura",
    the: "La",
    planYourBusinessApplications: "Planifique sus aplicaciones comerciales eligiendo una de",
    yourAccountIsNotVerified: "Su cuenta no está verificada. Valide su cuenta a través de su dirección de correo electrónico, haciendo clic en",

    // End sentence
    companyPleaseEvaluateQuote: ", evalúe la cotización.",
    theAssociatedAccountingEntriesWillBeDeleted: ", las escrituras contables asociadas serán eliminadas. Esto implica que no podrá realizar pagos ni generar tarifas de recordatorios relacionados con dicha factura.",
    ifYouWishToSaveTheChangesMade: "si desea guardar los cambios realizados.",
    youWantToRun: "que desea ejecutar.",
    toContinue: "para continuar.",
    waitAMomentToVerifyYourAccess: "espera un momento para verificar tu acceso.",
    hasSentARequestForAccessToTheFollowingCompany: "ha enviado una solicitud de acceso a la siguiente empresa, por favor responda.",
    ifYouWantToSaveTheNewQuote: "si desea guardar la nueva cotización.",
    ifYouWantToSaveTheNewQuoteWithTheChangesMade: "si desea guardar la nueva cotización con los cambios realizados.",
    ifYouWantToSaveTheNewInvoiceWithTheChangesMade: "si desea guardar la nueva factura con los cambios realizados.",
    ifYouWantToSaveTheNewExpense: "si desea guardar el nuevo gasto.",
    ifYouWantToSaveTheNewProviderInvoice: "si desea guardar la nueva factura del proveedor.",
    ifYouWantToSaveTheNewProviderOrder: "si desea guardar el nuevo pedido de proveedor.",
    ifYouWantToSaveTheNewProviderOrderWithTheChangesMade: "si desea guardar el nuevo pedido de proveedor con los cambios realizados.",
    ifYouWantToSaveTheNewProviderInvoiceWithTheChangesMade: "si desea guardar la nueva factura de proveedor con los cambios realizados.",
    ifYouWantToSaveTheNewOrder: "si desea guardar el nuevo pedido.",
    ifYouWantToSaveTheNewOrderWithTheChangesMade: "si desea guardar el nuevo pedido con los cambios realizados.",
    companyPleaseEvaluateTheOrder: "empresa, evalúe el pedido.",
    ifYouWantToSaveTheNewInvoice: "si desea guardar la nueva factura.",
    youWillNotBeAbleToRemoveItFromThisCompany: ", no podrás eliminarlo de esta empresa, puedes seleccionar las otras empresas.",
    inThisCategory: "en esta categoría.",
    theCompanyContainTreasutyAccount: "la empresa debe contener al menos una cuenta de tesorería.",
    toBeAddedInOrderToDownloadTheInvoice: "ser agregada para descargar la factura.",

    // All sentence
    youAreWishingToEnterWithTheFollowingEmailAddress: "Desea ingresar con la siguiente dirección de correo electrónico:",
    ifYouChangeYourMind: "Si cambia de opinión, solicite que se le envíe una nueva solicitud.",
    messageWillBeSentYourEmailToRecoverAccount: "Se enviará un mensaje a su correo electrónico para recuperar la cuenta, verifique su correo electrónico. Será redirigido a la página de inicio de sesión.",
    nowYouCanLogIn: "Ahora puede iniciar sesión con la nueva cuenta creada, será redirigido a la página de inicio de sesión.",
    newCompanyIsSelectedByDefault: "La nueva empresa está seleccionada de forma predeterminada y se redirige a la página principal.",
    notificationWillBeDeleted: "Se eliminará la notificación de la invitación para acceder a la empresa.",
    theAppWillBeTranslated: "La aplicación se traducirá al idioma indicado, excepto la información ingresada en los documentos.",
    userWillRespondInvitation: "El usuario responderá si acepta o no la invitación para acceder a la empresa.",
    youWillNotBeAbleToUndo: "No podrás deshacer esta acción.",
    tokenValidForAUserWhoHasNotLoggedIn: "Token válido para un usuario que no ha iniciado sesión, redirigir a la página de inicio de sesión.",
    toValidateYourAccount: "Para validar su cuenta, se le enviará un correo electrónico de validación desde donde podrá verificar su cuenta e ingresar nuevamente.",
    validatedAccountForLoggedInUser: "Cuenta validada para el usuario que inició sesión, redirigir a la página principal.",
    validatedAccountForADifferentUser: "Cuenta validada para un usuario diferente al que está conectado, redirigir a la página de inicio de sesión.",
    validatedVerifiedForAUserWhoHasNotLoggedIn: "Validado verificado para un usuario que no ha iniciado sesión, redirigir a la página de inicio de sesión.",
    newServicesWithinTheSameApp: "Nuevos servicios dentro de la misma aplicación.",
    pageInProduction: "Página en producción, muy pronto podrás acceder a nuevas funcionalidades dentro de la aplicación.",
    pleaseCorrectThePathOfThePageInOrderToAccess: "Corrija la ruta de la página para acceder a la aplicación.",
    pleaseTryAgainLater: "Por favor, inténtalo de nuevo más tarde.",
    thereAreNoCompaniesAddedYet: "Todavía no se han añadido empresas.",
    tokenValidForAnUserThatIsDifferentFromLoggedIn: "Token válido para un usuario que es diferente al que inició sesión, redirigiendo a la página de inicio de sesión.",
    validTokenForTheUserThatLoggedIn: "Token válido para el usuario que inició sesión, redirigir a la página del panel de control.",
    redirectingToLogin: "Redireccionando a la página de inicio de sesión.",
    willNotBeAbleToReturnUntilYouLogin: "No podrá volver hasta que vuelva a iniciar sesión.",
    allTheFieldsAreValidated: "Todos los campos están validados, en caso de ingresar un dato incorrecto en un formulario requerido no podrá avanzar a la siguiente etapa, por el contrario, al ingresar un dato incorrecto en un formulario no requerido, no se salvará.",
    pleaseClickContinue: "Haga clic en continuar para ingresar los detalles de la nueva empresa que se creará.",
    searchUserForInvitate: "Busca al usuario por su UUID o por nombre y envía una notificación para acceder a la empresa.",
    theFileMustBeFormat: "El archivo debe estar en formato jpeg, jpg o png.",
    ifYouHaveAnyQuestionsContactUs: "Si tiene alguna pregunta, puede contactarnos.",
    noNotificationsSeenYet: "No se han visto notificaciones todavía.",
    thereAreNoNotifications: "Aún no se han recibido notificaciones.",
    byDefaultValuesOfTheCompanyAreTaken: "Por defecto se toman valores de la empresa.",
    selectTheOptionsThatBestConsiderWhenDownloading: "Selecciona las opciones que mejor consideres a la hora de descargar una cotización o una factura en formato PDF.",
    notificationsAboutInvitationsToSubscribe: "Notificaciones sobre invitaciones para suscribirse y tener acceso a una empresa.",
    whenCreatingAContactByUUID: "Al crear un contacto por UUID no podrás modificar determinados campos según el tipo: persona (nombre, apellidos y email) y empresa (nombre).",
    youCanDirectlyEnterUUIDOfAUserOrCompanyToAddAsContact: "Puede ingresar directamente el UUID de un usuario o una empresa para agregarlo como contacto; de lo contrario, elija el tipo de contacto que desea crear.",
    descriptionCreateQuote: "Ingrese los siguientes datos, son necesarios para crear una nueva cotización.",
    descriptionCreateOrder: "Ingrese los siguientes datos, son necesarios para crear una nuevo pedido.",
    descriptionCreateInvoice: "Ingrese los siguientes datos, son necesarios para crear una nueva factura.",
    sorryLooksLikeThereAreSomeErrorstryAgain: "Lo sentimos, parece que se han detectado algunos errores, inténtalo de nuevo.",
    sorryLooksLikeThereAreSomeErrorsTrySolve: "Lo sentimos, parece que se han detectado algunos errores, intente resolverlos.",
    sorryThereIsNothingToAdd: "Lo sentimos, no hay nada que agregar, inténtalo de nuevo.",
    thankYouVeryMuchForSubscribingRedirectingToPreviousPage: "Muchas gracias por suscribirte, redirigiendo a la página anterior.",
    actionCompletedReturningToPage: "Acción completada, volviendo a la página.",
    actionCompletedRedirectingToPage: "Acción completada, redirigiendo a la página.",
    pleaseModifyAddOrDeleteFieldsFromQuote: "Por favor modifique, agregue y/o elimine los campos que desee de la cotización.",
    thisOptionIsUniqueAndNewOrderCannotBeCreatedFromThisQuote: "Esta opción es única y no se puede crear un nuevo pedido a partir de esta cotización.",
    pleaseEnterTheFollowingDataToCreateANewProviderInvoice: "Ingrese los siguientes datos, son necesarios para crear una nueva factura de proveedor.",
    pleaseModifyAddOrDeleteFieldsFromProviderInvoice: "Modifique, agregue y/o elimine los campos que desee de la factura del proveedor.",
    pleaseModifyAddOrDeleteFieldsFromExpense: "Modifique, agregue y/o elimine los campos que desee del gasto.",
    pleaseEnterTheFollowingDataToCreateANewExpense: "Por favor ingrese los siguientes datos, son necesarios para crear un nuevo gasto.",
    pleaseEnterThFollowingDataToCreateANewProviderOrder: "Ingrese los siguientes datos, son necesarios para crear un nuevo pedido de proveedor.",
    pleaseModifyAddAndOrDeleteFieldsFromProviderOrder: "Modifique, agregue y/o elimine los campos que desee del pedido del proveedor.",
    fileInDifferentFormat: "Archivo en diferente formato. Formato de archivo: '.pdf'.",
    fileIsTooBig: "El archivo es demasiado grande. Tamaño máximo de archivo: 1 MB.",
    fileFormatMaximum1MBPerFile: "El formato de archivo es '.pdf' y el tamaño máximo de archivo es de 1 MB.",
    someOfTheUploadedFilesDoNotMeetTheRequiredCharacteristics: "Algunos de los archivos cargados no cumplen con las características requeridas.",
    pleaseModifyAddOrDeleteFieldsFromOrder: "Modifique, agregue y/o elimine los campos que desee del pedido.",
    pleaseModifyAddOrDeleteFieldsFromInvoice: "Modifique, agregue y/o elimine los campos que desee de la factura.",
    thisAmountCanNotBeGreater: "Esta cantidad no puede ser mayor que la deuda total de la factura.",
    toCreateAnDocument: "Para crear un documento, la empresa debe contener al menos una cuenta de tresorería.",
    newContactWwillAddedToCompanyAndSelectDefault: "New contact will be added to company and select as default, returning to page.",
    youHaveSuccessfullyResetYourPassword: "Ha restablecido con éxito su contraseña, ahora puede iniciar sesión nuevamente. Será redirigido a la página de inicio de sesión.",
    kickstartYourBusinessByAddingAYourFirstCompany: "Inicie su negocio agregando su primera empresa.",
    ifNoAmountIsEnteredTheValueWillBeZero: "Si no se ingresa ningun monto, el valor sera 0.",
    ifYouWishYouCanAssignNameOfCategoryInLanguagesNecessary: "Si lo deseas puedes asignar el nombre de la categoría en los idiomas que consideres necesarios.",
    productNameRequiredAndRecommendedUnique: "Se requiere un nombre de producto y se recomienda que sea único.",
    addProductToCategory: "Agregar producto a una categoría.",
    addTagsToProduct: "Agregar etiquetas al producto, pueden ser opcionales.",
    setProductMediaGallery: "Configurar la galería multimedia del producto.",
    serviceNameRequiredAndRecommendedUnique: "Se requiere un nombre de servicio y se recomienda que sea único.",
    addServiceToCategory: "Agregar servicio a una categoría.",
    addTagsToService: "Agregar etiquetas al servicio, pueden ser opcionales.",
    setServiceMediaGallery: "Establecer la galería multimedia del servicio.",
    aCategoryContainServicesProducts: "Una categoria puede contener tanto servicios como productos.",
    ifYouBelongToRegimeMoreInformationIsRequired: "Si pertenece a algún régimen se requerira más información.",
    toPerformAnySubsequentActionWithInvoice: "Para realizar cualquier acción posterior con la factura, será necesario volver a validarla, especificando las cuentas contables correspondientes a cada producto y/o servicio asociado.",
    validatingInvoiceSystemWillCreateToFollowingAccountingEntries: "Al validar la factura, el sistema creará los siguientes asientos contables. Confirme las cuentas contables de cada servicio o producto de la factura.",
    allowedFileTypes: "Tipos de archivo permitidos: png, jpg, jpeg.",
    whenYouUploadFileItWillPlacedReferenceProviderInvoiceCreationForm: "Al cargar un archivo PDF, éste se colocará como referencia en el formulario de creación de factura del proveedor.",

    // Lowercase sentences
    yearl: "año",
    years: "años",
    hour: "hora",
    hours: "horas",
    minute: "minuto",
    minutes: "minutos",
    information: "información",
    items: "elementos",
    groups: "grupos",
    selected: "seleccionado",
    accountNeedsA: "cuenta necesita",
    options: "opciones",
    with: "con",
    bestCustomers: "mejores clientes",
    byRecentUpdates: "por actualizaciones recientes",
    byTimeRange: "por rango de tiempo",
    template: "plantilla",
    evaluated: "evaluado",
    noMailFound: "no se encuentra ningún correo",
    report: "reporte",
    reports: "reportes",
    requestOnAccess: "solicitud de acceso a la empresa, su respuesta fue",
    userHasBeen: "ha sido",
    yourAnswerHasBeen: ", tu respuesta ha sido",
    toUser: "al usuario",
    hasBeen: "ha sido",
    byYear: "por año",
    or: "o",
    quotesToExpireThisMonth: "cotizaciones vencerán este mes",
    worstCustomers: "peores clientes",
    button: "botón",
    termsAndConditions: "términos y condiciones",
    and: "y",
    ourServicesOrRequesting: "nuestros servicios o solicitando uno nuevo",
    toAddOrRemoveCategory: "que desea agregar o eliminar a la categoría",
    orCall: "o al número",
    invoicesNotYetPaid: "facturas aún no pagadas",

    // Interrogative sentences
    isDeliveryAddressTheSameAsCustomersAddress: "¿La dirección de entrega es la misma que la dirección del cliente?",
    youWantLogOut: "¿Quieres cerrar la sesión?",
    doesItTakeALongTimeToLoadTheApp: "¿Toma mucho tiempo cargar la aplicación?",
    alreadyHaveAnCccount: "¿Ya tienes una cuenta?",
    forgotPassword: "¿Olvidó su contraseña?",
    newHere: "¿Nuevo aquí?",
    canNotFindTheUserYouWant: "¿No puede encontrar el usuario que desea?",
    selectThisTreasuryAccountAsTheMainOne: "¿Seleccionar esta cuenta de tesorería como la principal?",
    youWantToDeleteThisTreasuryAccount: "¿Desea eliminar esta cuenta de tesorería?",
    youWantToSelectThisTreasuryAccountAsPrimary: "¿Desea seleccionar esta cuenta de tesorería como principal?",
    wantToExtendChangesToOtherCompanies: "¿Quiere extender los cambios a otras empresas?",
    youWantToDeleteThisContact: "¿Quieres eliminar este contacto?",
    youWantToDeleteSelectedContacts: "¿Desea eliminar los contactos seleccionados?",
    youWantSendQuote: "¿Quiere enviar la cotización al cliente?",
    youWantToChangeQuoteStatusRejected: "¿Desea cambiar el estado de la cotización a rechazada?",
    youWantToGenerateAnOrderFromThisQuote: "¿Desea generar un pedido a partir de esta cotización?",
    youWantToChangeStatusTheExpense: "¿Quiere cambiar el estado del gasto?",
    youWantToDeleteThisExpense: "¿Desea eliminar este gasto?",
    youWantToChangeStatusTheProviderInvoice: "¿Quiere cambiar el estado de la factura del proveedor?",
    youWantToGenerateAProviderInvoiceFromThisProviderOrder: "¿Desea generar una factura de proveedor a partir de este pedido de proveedor?",
    youWantToChangeOrderStatusToCanceled: "¿Desea cambiar el estado del pedido a cancelado?",
    youWantToGenerateAnInvoiceFromThisOrder: "¿Desea generar una factura de este pedido?",
    youWantToSendTheOrderToCustomer: "¿Desea enviar el pedido al cliente?",
    youWantToDeleteThisPayment: "¿Quiere eliminar este pago?",
    youWantToChangeStatusTheInvoice: "¿Quiere cambiar el estado de la factura?",
    isCompanyOwnerOfBankAccount: "¿La empresa es la titular de la cuenta bancaria?",
    useReferenceWithinQR: "¿Usar una referencia dentro del QR?",
    youWantDeleteProduct: "¿Quieres eliminar este producto?",
    youWantDeleteSelectedProducts: "¿Quieres eliminar los productos seleccionados?",
    youWantToDeleteThisReminder: "¿Quiere eliminar este recordatorio?",
    youWantToDeleteThisCategory: "¿Quieres eliminar esta categoría?",
    youWantToDeleteSelectedCategories: "¿Quieres eliminar categorías seleccionadas?",
    youWantDeleteService: "¿Quieres eliminar este producto?",
    youWantDeleteSelectedServices: "¿Quieres eliminar los servicios seleccionados?",
    isCompanySubjectToVatRegime: "¿La empresa está sujeta a un régimen de IVA?",
    doYouWantUploadPdfFileToFacilitateRregistrationInvoice: "¿Desea cargar un archivo PDF para facilitar el registro de un nueva factura?",

    // Exclamative sentences
    important: "¡Importante!",
    yourSessionHasBeenClosed: "¡Su sesión ha sido cerrada, por favor regístrese!",
    yourSessionHasExpired: "¡Su sesión ha caducado, regístrese de nuevo!",
    accountNotVerifiedYet: "¡Cuenta aún no verificada!",
    moneyBackGuarantee: "¡100% garantía de devolución de dinero!",
    somethingWentWrong: "¡Algo salió mal!",
    thePageYouLookedNotFound: "¡La página que buscó no se encuentra!",
    yourSessionIsActiveCongratulations: "Tu sesión está activa, ¡felicidades!",
    weNeedYourAttention: "¡Necesitamos su atención!",
    yourAreDone: "¡Terminaste!",
    startCreatingNewTreasuryAccountsForYourCompany: "¡Empiece a crear nuevas cuentas de tesorería para su empresa, es muy importante para el funcionamiento de la aplicación!",
    getNotifiedWhenWeLaunch: "¡Recibe una notificación cuando lancemos!",
    processOfCreatingANewInvoiceCompleted: "¡Proceso de creación de una nueva factura completado!",
    processOfUpdatingAInvoiceCompleted: "¡Proceso de actualización de una factura completado!",
    thereAreNoTreasuryAccounts: "¡No hay cuentas bancarias!",
    incorrectFiles: "¡Archivos incorrectos!",
    invalidFileType: "¡Tipo de archivo no válido! Tipos de archivo permitidos: jpeg, jpg o png",
    fileSizeNotAllowed: "¡Tamaño de archivo no permitido! Tamaño máximo de archivo: 1 MB",
    startAddingNewElementsToThisCategory: "¡Empieza a añadir nuevos elementos a esta categoría!",
    accountingAccountAlreadySelected: "¡Cuenta contable ya seleccionada, por favor seleccione otra!",
    servicesProductsWithTaxGreaterThanZero: "¡Servicios o productos con impuesto mayor a cero!",
    youHaveSuccessfullySignedOut: "¡Has cerrado sesión correctamente!",
    selectContactViewTheirAccountStatement: "¡Selecciona un contacto para visualizar su estado de cuenta!",
    processOfCreatingANewOrderCompleted: "¡Proceso de creación de un nuevo pedido completado!",
    processOfUpdatingAnOrderCompleted: "Proceso de actualización de un pedido completado",
    processOfCreatingQuoteCompleted: "¡Proceso de creación de una nueva cotización completado!",
    processOfCreatingAQuoteFromAnotherQuoteCompleted: "¡Se completó el proceso de creación de una cotización a partir de otra cotización!",
    processOfCreatingAOrderFromAnotherOrderCompleted: "¡Se completó el proceso de creación de una orden a partir de otra orden!",
    processOfCreatingAInvoiceFromAnotherInvoiceCompleted: "¡Se completó el proceso de creación de una factura a partir de otra factura!",
    processOfUpdatingAQuoteCompleted: "¡Proceso de actualización de una cotización completado!",
    processOfCreatingANewExpenseCompleted: "¡Proceso de creación de un nuevo gasto completado!",
    processOfUpdatingAnExpenseCompleted: "¡Proceso de actualización de un gasto completado!",
    processOfCreatingANewProviderInvoiceCompleted: "¡Proceso de creación de una nueva factura de proveedor completado!",
    processOfUpdatingAProviderInvoiceCompleted: "¡Proceso de actualización de una factura de proveedor completado!",
    processOfCreatedAProviderOrderCompleted: "¡Se completó el proceso de creación de un pedido de proveedor!",
    processOfCreatedAProviderInvoiceCompleted: "¡Se completó el proceso de creación de una factura de proveedor!",
    processOfCreatingANewProviderOrderCompleted: "¡Proceso de creación de un nuevo pedido de proveedor completado!",
    processOfUpdatingAProviderOrderCompleted: "¡Proceso de actualización de un pedido de proveedor completado!",
    successfullyCreatedAccountingAccount: "¡Cuenta contable creada exitosamente!",
    successfullyCreatedCategory: "¡Categoría creada exitosamente!",
    successfullyUpdatedCategory: "¡Categoría actualizada exitosamente!",
    successfullyInvitationSent: "¡Invitación enviada con éxito!",
    successfullyInvitationCancel: "¡La invitación se canceló con éxito!",
    successfullyCreatedContact: "¡Contacto creado con éxito!",
    successfullyUpdatedContact: "¡Contacto actualizado con éxito!",
    successfullyCreatedReminder: "¡Recordatorio creado con éxito!",
    successfullyUpdatedStatus: "¡Estado actualizado con éxito!",
    successfullyUpdatedReminder: "¡Recordatorio actualizado con éxito!",
    successfullyCreatedPayment: "¡Pago creado con éxito!",
    successfullyCreatedCompanyParameters: "¡Parámetros de la empresa creados con éxito!",
    successfullyUpdatedTreasuryAccount: "¡Cuenta de tresorería actualizada con éxito!",
    successfullyAcceptedInvitation: "¡Invitación aceptada con éxito!",
    successfullyLoggedIn: "¡Has iniciado sesión correctamente!",
    successfullyUpdatedCompanySettings: "¡Configuración de la empresa actualizada con éxito!",
    successfullyCreatedCompany: "¡Empresa creada con éxito!",
    successfullyDeletedPayment: "¡Pago eliminado con éxito!",
    successfullyCreatedInvoice: "¡Factura creada con éxito!",
    successfullyDeletedReminder: "¡Recordatorio eliminado con éxito!",
    successfullyOrderSent: "¡Pedido enviado con éxito!",
    successfullyQuoteSent: "¡Cotización enviada con éxito!",
    successfullyCreatedOrder: "Pedido creado con éxito!",
    successfullyCreatedTreasuryAccount: "¡Cuenta de tresorería creada con éxito!",
    successfullyUpdatedDefaultTreasuryAccount: "¡Cuenta de tresorería predeterminada actualizada con éxito!",
    successfullyDeletedTreasuryAccount: "¡Cuenta de tresorería eliminada con éxito!",
    successfullyCreatedAccountingEntry: "¡Entrada contable creada exitosamente!",
    successfullyUpdatedAccountingEntry: "¡Entrada contable actualizada exitosamente!",

    // Answer sentences
    okGotIt: "Ok, entendido",
    yesLogOut: "Sí, cerrar sesión",
    noCancel: "No, cancelar",
    noContinue: "No, continuar",
    yesDelete: "Si, eliminar",
    yesResolveError: "Sí, resolver el error",
    yesSelect: "Sí, seleccionar",
    yesCanceled: "Si, cancelar",
    okClose: "Ok, cerrar",
    yesReject: "Sí, rechazar",
    yesGenerate: "Sí, generar",
    yesSend: "Sí, enviar",
    yesChangeStatus: "Sí, cambiar estado",
    yesCreateTreasuryAccount: "Sí, crear cuenta de tresorería",
    yesValidate: "Si, validar",
    yesInvalidate: "Si, invalidar",
    yesUpload: "Si, cargar",

    // Suspensive sentences
    pleaseWait: "Por favor espere...",
    selectOption: "Seleccionar opción...",
    searchContact: "Buscar contacto y seleccionar...",
    searchCategory: "Buscar categoria y seleccionar...",
    searchProduct: "Buscar producto y seleccionar...",
    searchService: "Buscar servicio y seleccionar...",
    search: "Buscar...",
    writeCommentToInvite: "Escribe un comentario para la invitación...",
    loading: "Cargando...",

    // Abbreviated sentences
    n: "N°",
    mb: "MB",
    hrs: "hrs",
    min: "min",
    sec: "seg",

    // Enumeration
    first: "primero",
    second: "segundo",
    third: "tercero",
    fourth: "cuarto",
    fifth: "quinto",
    sixth: "sexto",

    // Languages
    english: "Inglés",
    french: "Francés",
    italian: "Italiano",
    german: "Alemán",
    spanish: "Español",

    // Months
    january: "Enero",
    february: "Febrero",
    march: "Marzo",
    april: "Abril",
    may: "Mayo",
    june: "Junio",
    july: "Julio",
    august: "Agosto",
    september: "Septiembre",
    october: "Octubre",
    november: "Noviembre",
    december: "Diciembre",
  }
}