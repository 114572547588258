import React, { Dispatch, SetStateAction, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { getError } from '../../languages/translations/response';
import { SettingsDA } from '../../services/settings.services';
import { Error } from '../../models/error.model';
import { Company } from '../../models/company.model';
import { Settings } from '../../models/settings.model';
import { Deadline } from '../../models/deadline.model';
import { legendValidInvalidIconRestart, legendInvalidIcon, legendValid, legendInvalid } from '../../tools/legend.data.entry.tool';
import { modalHide } from '../../tools/modal.tool';
import { evaluateLegendValidRequiredIcon } from '../../scripts/validate.legend.script';
import { expressions } from '../../libraries/regular.expressions.library';

import AppLegend from '../element/Legend';

export interface AppModalSettingsCreateDeadlineProps {
  companyForUser: Company | undefined | null,
  setValidUntil: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setPaymentDeadline: Dispatch<SetStateAction<{value: string, valid: boolean}>>
  loadSettingsForCompany: Function
};

let errorResponse: Error, settingsResponse: Settings;

const AppModalSettingsCreateDeadline: React.FunctionComponent<AppModalSettingsCreateDeadlineProps> = ({companyForUser, setValidUntil, setPaymentDeadline, loadSettingsForCompany}) => {
  const {lang} = StateLanguage()
  const navigate = useNavigate()

  const [loadIndicator, setLoadIndicator] = useState('off')
  const [type, setType] = useState({value: '', valid: false})
  const [quantity, setQuantity] = useState({value: '', valid: false})

  const submitSettingsParameterCreate = async () => {
    setLoadIndicator('on')

    if (companyForUser && type.valid && quantity.valid) {
      let deadline: {type: string, quantity: string} = {type: type.value, quantity: quantity.value}

      await SettingsDA.putSettingsParameters(companyForUser.id, null, deadline, null).then( (response) => {
        if (response.status === 200) {
          settingsResponse = response.data

          let tempDeadline = settingsResponse.payment_deadlines.find((temp_item) => (temp_item.type === deadline.type && temp_item.quantity === Number(deadline.quantity))) as Deadline
          let tempDate: Date = new Date()

          switch (tempDeadline.type) {
            case 'day':
              tempDate.setDate(tempDate.getDate() + tempDeadline.quantity)
              break;
            case 'week':
              tempDate.setDate(tempDate.getDate() + tempDeadline.quantity * 7)
              break;
            case 'month':
              tempDate.setMonth(tempDate.getMonth() + tempDeadline.quantity)
              break;  
            case 'year':
              tempDate.setFullYear(tempDate.getFullYear() + tempDeadline.quantity)
              break;
          }

          loadSettingsForCompany(companyForUser.id)
          setValidUntil({value: format(tempDate, 'yyyy-MM-dd'), valid: true})
          setPaymentDeadline({value: tempDeadline.id, valid: true})
          legendValid('legend-valid-until-required')

          Swal.fire({
            title: lang.labels.successfullyCreatedCompanyParameters,
            text: lang.labels.actionCompletedReturningToPage,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            executeHideModalSettingUnitCreate()
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.code),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        navigate('/error')
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        if (type.value.length === 0) {
          legendInvalid('modal-settings-create-deadline-legend-type-required')
        }
        if (quantity.value.length === 0) {
          legendInvalidIcon('modal-settings-create-deadline-input-quantity', 'modal-settings-create-deadline-legend-quantity-required')
        }
        setLoadIndicator('off')
      })
    }
  }

  const handleChangeType = (item: string) => {
    setType({...type, value: item, valid: true})
    legendValid('modal-settings-create-deadline-legend-type-required')
  }

  const handleChangeQuantity = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setQuantity({...quantity, value: event.target.value})
  }

  const validateQuantity = () => {
    evaluateLegendValidRequiredIcon(expressions.number, quantity, setQuantity, 'modal-settings-create-deadline-input-tax', 'modal-settings-create-deadline-legend-quantity-valid', 'modal-settings-create-deadline-legend-quantity-required')
  }

  const executeHideModalSettingUnitCreate = () => {
    modalHide('modal-settings-create-deadline')

    setTimeout( () => {
      restartModal()
    }, 200 )
  }

  function restartModal() {
    legendValid('modal-settings-create-deadline-legend-type-valid')
    legendValidInvalidIconRestart('modal-settings-create-deadline-input-name', 'modal-settings-create-deadline-legend-quantity-valid')
    legendValidInvalidIconRestart('modal-settings-create-deadline-input-name', 'modal-settings-create-deadline-legend-quantity-required')

    setType({value: '', valid: false})
    setQuantity({value: '', valid: false})
  }

  return (
    <div id="modal-settings-create-deadline" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog mw-500px">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="text-capitalize">{lang.labels.addDeadlinePaymentParameter}</h2>
          </div>
          <div className="modal-body">
            <div className="form">
              <div className="row mb-2">
                <label className="col-lg-3 col-form-label required">{lang.labels.type}</label>
                <div className="col-lg-9">
                  <Dropdown>
                    <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                      <span className="selection">
                        <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                          <span className="select2-selection__rendered" role="textbox">
                            <span className={`select2-selection__placeholder ${type.valid && "text-capitalize"}`}>
                              { type.valid ? `${type.value}` : lang.labels.selectOption }
                            </span>
                          </span>
                        </span>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                      <span className="select2-dropdown select2-dropdown--below">
                        <span className="select2-results">
                          <ul className="select2-results__options" role="listbox">
                            <li className={`select2-results__option select2-results__option--selectable ${type.value === "day" && "select2-results__option--selected"}`} role="option" aria-selected={type.value === "day"? "true" : "false"}>
                              <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeType("day")}>{lang.labels.day}</Dropdown.Item>
                            </li>
                            <li className={`select2-results__option select2-results__option--selectable ${type.value === "week" && "select2-results__option--selected"}`} role="option" aria-selected={type.value === "week"? "true" : "false"}>
                              <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeType("week")}>{lang.labels.week}</Dropdown.Item>
                            </li>
                            <li className={`select2-results__option select2-results__option--selectable ${type.value === "month" && "select2-results__option--selected"}`} role="option" aria-selected={type.value === "month"? "true" : "false"}>
                              <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeType("month")}>{lang.labels.month}</Dropdown.Item>
                            </li>
                            <li className={`select2-results__option select2-results__option--selectable ${type.value === "year" && "select2-results__option--selected"}`} role="option" aria-selected={type.value === "year"? "true" : "false"}>
                              <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeType("year")}>{lang.labels.year}</Dropdown.Item>
                            </li>
                          </ul>
                        </span>
                      </span>
                    </Dropdown.Menu>
                  </Dropdown>
                  <AppLegend component={"modal-settings-create-deadline"} attribute={{validity: type.valid, name: "type", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                </div>
              </div>
              <div className="row">
                <label className="col-lg-3 col-form-label required">{lang.labels.quantity}</label>
                <div className="col-lg-9">
                  <input id="modal-settings-create-deadline-input-quantity" className="form-control form-control-solid" type="text" name="quantity" value={quantity.value} onChange={handleChangeQuantity} onKeyUp={validateQuantity} onBlur={validateQuantity} />
                  <AppLegend component={"modal-settings-create-deadline"} attribute={{validity: quantity.valid, name: "quantity", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer flex-center">
            <button className="btn btn-light mx-2" type="reset" onClick={executeHideModalSettingUnitCreate}>{lang.labels.discard}</button>
            <button className="btn btn-primary mx-2" type="button" data-kt-indicator={loadIndicator} onClick={submitSettingsParameterCreate}>
              <span className="indicator-label">{lang.labels.create}</span>
              <span className="indicator-progress">
                {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
};

export default AppModalSettingsCreateDeadline;
