import React, { Dispatch, SetStateAction, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { getError } from '../../languages/translations/response';
import { ContactDA } from '../../services/contact.service';
import { Error } from '../../models/error.model';
import { Company } from '../../models/company.model';
import { Currency } from '../../models/currency.model';
import { Contact } from '../../models/contact.model';
import { modalHide, modalShow } from '../../tools/modal.tool';
import { imageCompany } from '../../scripts/image.value.script';

export interface AppModalContactUpdateExtendProps {
  companyForUser: Company | undefined | null,
  contact: Contact | undefined | null,
  contacts: Contact[] | undefined | null,
  companiesOfContact: Company[] | undefined | null,
  values: {name: string, first_name: string, last_name: string, logo: any, email: string, phone: string, website: string, register_number: string, vat_number: string, street: string, street_number: string, additional: string, administrative_area: string, city: string, zip_code: string, country: string, currency: Currency, financial_entity: string, iban: string, bban: string, bic: string, language: string, qr_type: string},
  setContact: Dispatch<SetStateAction<Contact | undefined | null>>,
  setContacts: Dispatch<SetStateAction<Contact[] | undefined | null>> | null,
  loadContactsForCompany: Function | null,
  restartModalMain: Function
};

let errorResponse: Error, contactResponse: Contact;

const AppModalContactUpdateExtend: React.FunctionComponent<AppModalContactUpdateExtendProps> = ({companyForUser, contact, contacts, companiesOfContact, values, setContact, setContacts, loadContactsForCompany, restartModalMain}) => {
  const {lang} = StateLanguage()
  const navigate = useNavigate()

  const [loadIndicator, setLoadIndicator] = useState('off')
  const [companies, setCompanies] = useState<string[]>([])

  const submitContactUpdate = async () => {
    setLoadIndicator('on')

    if (companyForUser && contact) {
      let addressStreetNumber: string = (values.street_number === 'NN' || values.street_number === 'nn') ? values.street_number.toUpperCase() : values.street_number
      let valueIban: string = values.iban.toUpperCase().trim().replaceAll(' ', '')
      let valueBban: string = values.bban.toUpperCase().trim().replaceAll(' ', '')
      let valueBic: string = values.bic.toUpperCase().trim().replaceAll(' ', '')

      await ContactDA.putContact(companyForUser.id, contact.data.id, contact.type, values.name, values.first_name, values.last_name, values.logo, values.email, values.phone, values.website, values.register_number, values.vat_number, values.street, addressStreetNumber, values.additional, values.administrative_area, values.city, values.zip_code, values.country, values.currency, values.financial_entity, valueIban, valueBban, valueBic, values.language, values.qr_type, companies).then( (response) => {
        if (response.status === 200) {
          contactResponse = response.data

          if (typeof loadContactsForCompany === 'function' && contacts && setContacts) {
            let tempContacts = contacts.map((temp_item) => {
              if (temp_item.data.id === contact.data.id) {
                temp_item = contactResponse
              }
              return temp_item
            })

            setContacts(tempContacts)
            loadContactsForCompany(companyForUser.id)
          } else {
            setContact(contactResponse)
          }

          Swal.fire({
            title: lang.labels.successfullyUpdatedContact,
            text: lang.labels.actionCompletedReturningToPage,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            executeHideModalContactUpdateExtend()
            restartModalMain()
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.code),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        navigate('/error')
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        setLoadIndicator('off')
      })
    }
  }

  const handleChangeCompanies = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    if (companies.includes(event.target.value)) {
      setCompanies(companies.filter((item) => (item !== event.target.value)))
    } else {
      setCompanies([...companies, event.target.value])
    }
  }

  const executeShowModalContactUpdate = () => {
    executeHideModalContactUpdateExtend()
    modalShow('modal-contact-update')
  }

  const executeHideModalContactUpdateExtend = () => {
    modalHide('modal-contact-update-extend')

    setTimeout( () => {
      restartModal()
    }, 200 )
  }

  function restartModal() {
    setCompanies([])
  }

  return (
    <div id="modal-contact-update-extend" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog mw-500px">
        <div className="modal-content">
          <div className="modal-body">
            <div className="mb-5">
              <h5 className="mb-3">{lang.labels.wantToExtendChangesToOtherCompanies}</h5>
              <div className="text-gray-700 text-justify">
                {lang.labels.ifYouDoNotWishToExtendContactModificationsToOtherCompanies}
                <strong className="mx-1">{lang.labels.update}</strong>
                {lang.labels.toContinue}
              </div>
            </div>
            <div className="mh-300px hover-scroll-y px-3 border-top border-bottom">
              { companyForUser && companiesOfContact
                ?
                <>
                  { companiesOfContact.map (( (item, index) => { return (
                    <div key={index}>
                      { companyForUser.id !== item.id &&
                        <label className="d-flex flex-stack cursor-pointer my-3">
                          <span className="d-flex align-items-center me-3">
                            <span className="symbol symbol-50px me-5">
                              <span className="symbol-label bg-light">
                                <img src={imageCompany(item.logo)} alt={lang.labels.logo} className="w-75 h-75" />
                              </span>
                            </span>
                            <span className="d-flex flex-column">
                              <span className="fs-6 fw-bolder">{item.name}</span>
                              <span className="text-muted">{item.website}</span>
                            </span>
                          </span>
                          <span className="form-check form-check-custom form-check-solid">
                            <input className="form-check-input" type="checkbox" value={item.id} checked={companies.includes(item.id)} onChange={handleChangeCompanies} />
                          </span>
                        </label>
                      }
                    </div>
                  )}))}
                </>
                :
                <div className="d-flex justify-content-center align-items-center w-100 h-200px">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">{lang.labels.loading}</span>
                  </div>
                </div>
              }
            </div>
          </div>
          <div className="modal-footer flex-stack">
            <button className="btn btn-light mx-2" type="button" onClick={executeShowModalContactUpdate}>
              <div className="d-flex align-items-center">
                <span className="svg-icon svg-icon-3 ms-0">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="black" />
                    <path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="black" />
                  </svg>
                </span>
                {lang.labels.back}
              </div>
            </button>
            <button className="btn btn-primary mx-2" type="button" data-kt-indicator={loadIndicator} onClick={submitContactUpdate}>
              <span className="indicator-label">{lang.labels.update}</span>
              <span className="indicator-progress">
                {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
};

export default AppModalContactUpdateExtend;
