import moment, { Moment } from 'moment';

export function colorStatusQuote(status: string): string {
  switch (status) {
    case ('in progress'):
      return 'info'
    case ('pending'):
      return 'warning'
    case ('rejected'):
      return 'danger'
    case ('approved'):
      return 'success'
    case ('completed'):
      return 'primary'
    default:
      return 'dark'
  }
}

export function colorStatusOrder(status: string): string {
  switch (status) {
    case ('in progress'):
      return 'info'
    case ('pending'):
      return 'warning'
    case ('canceled'):
      return 'danger'
    case ('approved'):
      return 'success'
    case ('completed'):
      return 'primary'
    default:
      return 'dark'
  }
}

export function colorStatusInvoice(status: string): string {
  switch (status) {
    case ('in progress'):
      return 'info'
    case ('reminder'):
      return 'warning'
    case ('in dispute'):
      return 'primary'
    case ('completed'):
      return 'success'
    default:
      return 'dark'
  }
}

export function colorStatusPayment(status: string): string {
  switch (status) {
    case ('fully paid'):
      return 'success'
    case ('partially paid'):
      return 'warning'
    case ('not payed'):
      return 'danger'
    default:
      return 'dark'
  }
}

export function colorValidUntilQuote(status: string, valid_until: string): string {
  if (status === 'in progress') {
    let dateString: string = (valid_until.length === 10) ? `${valid_until}T00:00:00.000` : valid_until
    let dateValue: Date = new Date(dateString)
    let dateCurrent: Date = new Date()
    let momentInit: Moment = moment(dateValue)
    let momentCurrent: Moment = moment(dateCurrent)
    let days: number = momentInit.diff(momentCurrent, 'day')

    switch (true) {
      case (7 < days):
        return 'success'
      case (0 <= days && days <= 7):
        return 'warning'
      default:
        return 'danger'
    }
  } else {
    return 'dark'
  }
}

export function colorValidUntilOrder(status: string, valid_until: string): string {
  if (status === 'in progress') {
    let dateString: string = (valid_until.length === 10) ? `${valid_until}T00:00:00.000` : valid_until
    let dateValue: Date = new Date(dateString)
    let dateCurrent: Date = new Date()
    let momentInit: Moment = moment(dateValue)
    let momentCurrent: Moment = moment(dateCurrent)
    let days: number = momentInit.diff(momentCurrent, 'day')

    switch (true) {
      case (7 < days):
        return 'success'
      case (0 <= days && days <= 7):
        return 'warning'
      default:
        return 'danger'
    }
  } else {
    return 'dark'
  }
}

export function colorValidUntilInvoice(status: string, valid_until: string): string {
  if (status === 'in progress') {
    let dateString: string = (valid_until.length === 10) ? `${valid_until}T00:00:00.000` : valid_until
    let dateValue: Date = new Date(dateString)
    let dateCurrent: Date = new Date()
    let momentInit: Moment = moment(dateValue)
    let momentCurrent: Moment = moment(dateCurrent)
    let days: number = momentInit.diff(momentCurrent, 'day')

    switch (true) {
      case (7 < days):
        return 'success'
      case (0 <= days && days <= 7):
        return 'warning'
      default:
        return 'danger'
    }
  } else {
    return 'dark'
  }
}

export function colorPercentage(total: number, amount: number): string {
  let percentage: number = (amount * 100) / total

  switch (true) {
    case (percentage <= 10):
      return 'success'
    case (10 < percentage && percentage < 90):
      return 'warning'
    default:
      return 'danger'
  }
}

export function colorValidated(validated: boolean): string {
  if (validated) {
    return 'success'
  } else {
    return 'danger'
  }
}
