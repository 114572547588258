export function isValidObjectAttributes(item: any): boolean {
  for (let key in item) {
    if (item[key] !== null && typeof item[key] === 'object') {
      if (Array.isArray(item[key])) {
        for (let i = 0; i < item[key].length; i++) {
          if (!isValidObjectAttributes(item[key][i])) {
            return false
          }
        }
      } else {
        if (!isValidObjectAttributes(item[key])) {
          return false
        }
      }
    } else {
      if (!item[key]) {
        return false
      }
    }
  }

  return true
}

export function isEqualObjects(item_1: any, item_2: any): boolean {
  if (item_1 !== null || item_2 !== null || typeof item_1 !== 'object' || typeof item_2 !== 'object') {
    return false
  }

  let keys1: string[] = Object.keys(item_1)
  let keys2: string[] = Object.keys(item_2)

  if (keys1.length !== keys2.length) {
    return false
  }

  for (let key of keys1) {
    if (!item_2.hasOwnProperty(key)) {
      return false
    }
    if (item_1[key] !== item_2[key]) {
      return false
    }
  }

  return true
}
