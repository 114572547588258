import React, { Dispatch, SetStateAction } from 'react';
import { Dropdown } from 'react-bootstrap';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { legendValid } from '../../tools/legend.data.entry.tool';
import { languageList, languageValue } from '../../libraries/language.library';

import AppLegend from './Legend';

export interface AppInputLanguageProps {
  language: {value: string, valid: boolean},
  setLanguage: Dispatch<SetStateAction<{value: string, valid: boolean}>>
};

const AppInputLanguage: React.FunctionComponent<AppInputLanguageProps> = ({language, setLanguage}) => {
  const {lang} = StateLanguage()

  const handleChangeLanguage = (item: string) => {
    setLanguage({value: item, valid: true})
    legendValid('legend-language-required')
  }

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
          <span className="selection">
            <span className="select2-selection select2-selection--single form-select form-select-solid">
              <span className="select2-selection__rendered" role="textbox">
                {language.valid ? languageValue(lang, language.value) : lang.labels.selectOption}
              </span>
            </span>
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
          <span className="select2-dropdown select2-dropdown--below">
            <span className="select2-results">
              <ul className="select2-results__options" role="listbox">
                { languageList(lang).map (( (item, index) => { return (
                  <li key={index} className={`select2-results__option select2-results__option--selectable ${item.code === language.value && "select2-results__option--selected"}`} role="option" aria-selected={item.code === language.value}>
                    <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeLanguage(item.code)}>{item.display}</Dropdown.Item>
                  </li>
                )}))}
              </ul>
            </span>
          </span>
        </Dropdown.Menu>
      </Dropdown>
      <AppLegend attribute={{validity: language.valid, name: "language", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
    </>
  )
}

export default AppInputLanguage;
