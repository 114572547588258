import React, { Dispatch, SetStateAction } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';

import { UseOutletContext } from '../Main';
import { User } from '../../../models/user.model';
import { AppService } from '../../../models/app.service.model';
import { Company } from '../../../models/company.model';
import { Settings } from '../../../models/settings.model';
import { Contact } from '../../../models/contact.model';
import { TreasuryAccount } from '../../../models/treasury.account.model';
import { Service } from '../../../models/service.model';
import { Product } from '../../../models/product.model';
import { AccountingAccount } from '../../../models/accounting.account.model';

export interface SalePageProps {};

type ContexType = {
  setRoute: Dispatch<SetStateAction<{path: {root: string, branch: string} | null, company: boolean} | null>>,
  userLoggedIn: User | undefined | null,
  appServiceForUser: AppService | undefined | null,
  companyForUser: Company | undefined | null,
  settingsForCompany: Settings | null | undefined,
  accountingAccountsForCompany: AccountingAccount[] | undefined | null,
  treasuryAccountsForCompany: TreasuryAccount[] | null | undefined,
  contactsForCompany: Contact[] | undefined | null,
  servicesForCompany: Service[] | null | undefined,
  productsForCompany: Product[] | null | undefined,
  loadSettingsForCompany: Function,
  loadContactsForCompany: Function
};

const SalePage: React.FunctionComponent<SalePageProps> = props => {
  const {setRoute, userLoggedIn, appServiceForUser, companyForUser, settingsForCompany, accountingAccountsForCompany, treasuryAccountsForCompany, contactsForCompany, servicesForCompany, productsForCompany, loadSettingsForCompany, loadContactsForCompany} = UseOutletContext()

  return (
    <Outlet context={{setRoute, userLoggedIn, appServiceForUser, companyForUser, settingsForCompany, accountingAccountsForCompany, treasuryAccountsForCompany, contactsForCompany, servicesForCompany, productsForCompany, loadSettingsForCompany, loadContactsForCompany}}></Outlet>
  );
}

export function UseOutletContextSale() {
  return useOutletContext<ContexType>()
};

export default SalePage;
