import React, { useEffect, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { FaExclamationCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import { UseOutletContextCompany } from '../Company';
import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { getError, getMessage } from '../../../../languages/translations/response';
import { CompanyDL } from '../../../../services/company.service';
import { Error } from '../../../../models/error.model';
import { Message } from '../../../../models/message.model';
import { legendInvalidIcon, legendValidRequiredIcon, legendValidInvalidRestart, legendValidInvalidIconRestart, legendInvalid } from '../../../../tools/legend.data.entry.tool';
import { removeTooltip, uploadTooltip } from '../../../../tools/tooltip.tool';
import { removeUploadedImage, uploadImage } from '../../../../scripts/file.load.script';
import { evaluateLegendValidEmptyIcon, evaluateLegendValidRequiredIcon } from '../../../../scripts/validate.legend.script';
import { expressions } from '../../../../libraries/regular.expressions.library';
import { taxMethodCompanyValue } from '../../../../libraries/tax.method.company.library';
import { taxRateFormat } from '../../../../scripts/format.value.script';

import AppInputCountry from '../../../../components/element/InputCountry';
import AppInputCurrency from '../../../../components/element/InputCurrency';
import AppInputLanguage from '../../../../components/element/InputLanguage';
import AppInputQrType from '../../../../components/element/InputQrType';
import AppLoading from '../../../../components/Loading';
import AppLegend from '../../../../components/element/Legend';

import imgDefault from '../../../../assets/images/default.png';

export interface AppCompanyUpdateGeneralProps {};

let errorResponse: Error, messageResponse: Message;

const AppCompanyUpdateGeneral: React.FunctionComponent<AppCompanyUpdateGeneralProps> = props => {
  const {appServiceForUser, companyForUser, reloadUserLoggedIn} = UseOutletContextCompany()
  const {lang} = StateLanguage()
  const navigate = useNavigate()

  const [loadIndicator, setLoadIndicator] = useState('off')
  const [name, setName] = useState({value: '', valid: false})
  const [logo, setLogo] = useState({value: '', file: null as any, valid: false})
  const [street, setStreet] = useState({value: '', valid: false})
  const [streetNumber, setStreetNumber] = useState({value: '', valid: false})
  const [additional, setAdditional] = useState({value: '', valid: false})
  const [administrativeArea, setAdministrativeArea] = useState({value: '', valid: false})
  const [city, setCity] = useState({value: '', valid: false})
  const [zipCode, setZipCode] = useState({value: '', valid: false})
  const [country, setCountry] = useState({value: '', valid: false})
  const [email, setEmail] = useState({value: '', valid: false})
  const [phone, setPhone] = useState({value: '', valid: false})
  const [website, setWebsite] = useState({value: '', valid: false})
  const [vatNumber, setVatNumber] = useState({value: '', valid: false})
  const [registerNumber, setRegisterNumber] = useState({value: '', valid: false})
  const [currency, setCurrency] = useState({value: '', object: null as any, valid: false})
  const [language, setLanguage] = useState({value: '', valid: false})
  const [qrType, setQrTtype] = useState({value: '', valid: false})

  const submitCompanyUpdate = async () => {
    setLoadIndicator('on')

    if (appServiceForUser && companyForUser && name.valid && logo.valid && street.valid && streetNumber.valid && additional.valid && administrativeArea.valid && city.valid && zipCode.valid && country.valid && email.valid && phone.valid && website.valid && vatNumber.valid && registerNumber.valid && currency.valid && language.valid && qrType.valid) {
      let addressStreetNumber: string = (streetNumber.value === 'NN' || streetNumber.value === 'nn') ? streetNumber.value.toUpperCase() : streetNumber.value

      await CompanyDL.putCompany(appServiceForUser.api_key, companyForUser.id, name.value, logo.file, street.value, addressStreetNumber, additional.value, administrativeArea.value, city.value, zipCode.value, country.value, email.value, phone.value, website.value, vatNumber.value, registerNumber.value, currency.object, language.value, qrType.value).then( (response) => {
        if (response.status === 200) {
          messageResponse = response.data

          reloadUserLoggedIn(null, null, null, () => {
            Swal.fire({
              title: getMessage(messageResponse.message, lang.code),
              text: lang.labels.actionCompletedReturningToPage,
              icon: 'success',
              showConfirmButton: false,
              timer: 1800
            } as SweetAlertOptions).then( () => {
              setLoadIndicator('off')
              restartForm()
            })
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.code),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        navigate('/error')
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        if (name.value.length === 0) {
          legendInvalidIcon('input-name', 'legend-name-required')
        }
        if (street.value.length === 0) {
          legendInvalidIcon('input-street', 'legend-street-required')
        }
        if (streetNumber.value.length === 0) {
          legendInvalidIcon('input-street-number', 'legend-street-number-required')
        }
        if (administrativeArea.value.length === 0) {
          legendInvalidIcon('input-administrative-area', 'legend-administrative-area-required')
        }
        if (city.value.length === 0) {
          legendInvalidIcon('input-city', 'legend-city-required')
        }
        if (zipCode.value.length === 0) {
          legendInvalidIcon('input-zip-code', 'legend-zip-code-required')
        }
        if (country.value.length === 0) {
          legendInvalid('legend-country-required')
        }
        if (email.value.length === 0) {
          legendInvalidIcon('input-email', 'legend-email-required')
        }
        if (phone.value.length === 0) {
          legendInvalidIcon('input-phone', 'legend-phone-required')
        }
        if (currency.value.length === 0) {
          legendInvalid('legend-currency-required')
        }
        if (language.value.length === 0) {
          legendInvalid('legend-language-required')
        }
        if (qrType.value.length === 0) {
          legendInvalid('legend-qr-type-required')
        }
        setLoadIndicator('off')
      })
    }
  }

  const handleChangeName = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setName({...name, value: event.target.value})
  }

  const handleChangeLogo = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    uploadImage(event.target.files[0], setLogo, 'legend-logo-type', 'legend-logo-size')
  }

  const handleChangeStreet = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setStreet({...street, value: event.target.value})
  }

  const handleChangeNumber = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setStreetNumber({...streetNumber, value: event.target.value})
  }

  const handleChangeAdditional = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setAdditional({...additional, value: event.target.value})
  }

  const handleChangeAdministrativeArea = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setAdministrativeArea({...administrativeArea, value: event.target.value})
  }

  const handleChangeCity = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setCity({...city, value: event.target.value})
  }

  const handleChangeZipCode = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setZipCode({...zipCode, value: event.target.value})
  }

  const handleChangeEmail = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setEmail({...email, value: event.target.value})
  }

  const handleChangePhone = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setPhone({...phone, value: event.target.value})
  }

  const handleChangeWebsite = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setWebsite({...website, value: event.target.value})
  }

  const handleChangeRegisterNumber = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setRegisterNumber({...registerNumber, value: event.target.value})
  }

  const handleChangeVatNumber = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setVatNumber({...vatNumber, value: event.target.value})
  }

  const handleRemoveLogo = () => {
    removeUploadedImage(setLogo, 'legend-logo-type', 'legend-logo-size')
    removeTooltip()
  }

  const validateName = () => {
    evaluateLegendValidRequiredIcon(expressions.name, name, setName, 'input-name', 'legend-name-valid', 'legend-name-required')
  }

  const validateStreet = () => {
    evaluateLegendValidRequiredIcon(expressions.street, street, setStreet, 'input-street', 'legend-street-valid', 'legend-street-required')
  }

  const validateNumber = () => {
    if (streetNumber.value === 'NN' || streetNumber.value === 'nn') {
      setStreetNumber({...streetNumber, valid: true})
      legendValidRequiredIcon('input-street-number', 'legend-street-number-valid', 'legend-street-number-required')
    } else {
      evaluateLegendValidRequiredIcon(expressions.streetnumber, streetNumber, setStreetNumber, 'input-street-number', 'legend-street-number-valid', 'legend-street-number-required')
    }
  }

  const validateAdditional = () => {
    evaluateLegendValidEmptyIcon(expressions.text, additional, setAdditional, 'input-additional', 'legend-additional-valid')
  }

  const validateAdministrativeArea = () => {
    evaluateLegendValidRequiredIcon(expressions.location, administrativeArea, setAdministrativeArea, 'input-administrative-area', 'legend-administrative-area-valid', 'legend-administrative-area-required')
  }

  const validateCity = () => {
    evaluateLegendValidRequiredIcon(expressions.location, city, setCity, 'input-city', 'legend-city-valid', 'legend-city-required')
  }

  const validateZipCode = () => {
    evaluateLegendValidRequiredIcon(expressions.zipcode, zipCode, setZipCode, 'input-zip-code', 'legend-zip-code-valid', 'legend-zip-code-required')
  }

  const validateEmail = () => {
    evaluateLegendValidRequiredIcon(expressions.email, email, setEmail, 'input-email', 'legend-email-valid', 'legend-email-required')
  }

  const validatePhone = () => {
    evaluateLegendValidRequiredIcon(expressions.phone, phone, setPhone, 'input-phone', 'legend-phone-valid', 'legend-phone-required')
  }

  const validateWebsite = () => {
    evaluateLegendValidEmptyIcon(expressions.website, website, setWebsite, 'input-website', 'legend-website-valid')
  }

  const validateRegisterNumber = () => {
    evaluateLegendValidEmptyIcon(expressions.registernumber, registerNumber, setRegisterNumber, 'input-register-number', 'legend-register-number-valid')
  }

  const validateVatNumber = () => {
    evaluateLegendValidEmptyIcon(expressions.vat, vatNumber, setVatNumber, 'input-vat-number', 'legend-vat-number-valid')
  }

  const executeFormRestart = () => {
    uploadData()
    restartForm()
  }

  function restartForm() {
    legendValidInvalidIconRestart('input-name', 'legend-name-valid')
    legendValidInvalidIconRestart('input-name', 'legend-name-required')
    legendValidInvalidRestart('legend-logo-type')
    legendValidInvalidRestart('legend-logo-size')
    legendValidInvalidIconRestart('input-street', 'legend-street-valid')
    legendValidInvalidIconRestart('input-street', 'legend-street-required')
    legendValidInvalidIconRestart('input-street-number', 'legend-street-number-valid')
    legendValidInvalidIconRestart('input-street-number', 'legend-street-number-required')
    legendValidInvalidIconRestart('input-additional', 'legend-additional-valid')
    legendValidInvalidIconRestart('input-administrative-area', 'legend-administrative-area-valid')
    legendValidInvalidIconRestart('input-administrative-area', 'legend-administrative-area-required')
    legendValidInvalidIconRestart('input-city', 'legend-city-valid')
    legendValidInvalidIconRestart('input-city', 'legend-city-required')
    legendValidInvalidIconRestart('input-zip-code', 'legend-zip-code-valid')
    legendValidInvalidIconRestart('input-zip-code', 'legend-zip-code-required')
    legendValidInvalidRestart('legend-country-required')
    legendValidInvalidIconRestart('input-email', 'legend-email-valid')
    legendValidInvalidIconRestart('input-email', 'legend-email-required')
    legendValidInvalidIconRestart('input-phone', 'legend-phone-valid')
    legendValidInvalidIconRestart('input-phone', 'legend-phone-required')
    legendValidInvalidIconRestart('input-website', 'legend-website-valid')
    legendValidInvalidIconRestart('input-register-number', 'legend-register-number-valid')
    legendValidInvalidIconRestart('input-vat-number', 'legend-vat-number-valid')
    legendValidInvalidRestart('legend-currency-required')
    legendValidInvalidRestart('legend-language-required')
    legendValidInvalidRestart('legend-qr-type-required')
  }

  function uploadData() {
    if (companyForUser) {
      setName({value: companyForUser.name, valid: true})
      setLogo({value: companyForUser.logo, file: companyForUser.logo, valid: true})
      setStreet({value: companyForUser.address.street, valid: true})
      setStreetNumber({value: companyForUser.address.number, valid: true})
      setAdditional({value: companyForUser.address.additional, valid: true})
      setAdministrativeArea({value: companyForUser.address.administrative_area, valid: true})
      setCity({value: companyForUser.address.city, valid: true})
      setZipCode({value: companyForUser.address.zip_code, valid: true})
      setCountry({value: companyForUser.address.country, valid: true})
      setEmail({value: companyForUser.email, valid: true})
      setPhone({value: companyForUser.phone, valid: true})
      setWebsite({value: companyForUser.website, valid: true})
      setRegisterNumber({value: companyForUser.register_number, valid: true})
      setVatNumber({value: companyForUser.vat_number, valid: true})
      setCurrency({value: companyForUser.currency.code, object: companyForUser.currency, valid: true})
      setLanguage({value: companyForUser.language, valid: true})
      setQrTtype({value: companyForUser.qr_type, valid: true})
    }
  }

  useEffect( () => {
    uploadData()
    uploadTooltip()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyForUser])

  return (
    <div className="card">
      <div className="card-header">
        <div className="card-title fs-4 fw-bolder">{lang.labels.companyGeneralSettings}</div>
      </div>
      { companyForUser
        ?
        <div className="form">
          <div className="card-body">
            <div className="row mb-3 mb-xl-5">
              <div className="col-xl-3">
                <div className="col-form-label required">{lang.labels.name}</div>
              </div>
              <div className="col-xl-9 fv-row fv-plugins-icon-container">
                <input id="input-name" className="form-control form-control-solid" type="text" name="name" value={name.value} onChange={handleChangeName} onKeyUp={validateName} onBlur={validateName} />
                <AppLegend attribute={{validity: name.valid, name: "name", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-3 mb-xl-5">
              <div className="col-xl-3">
                <div className="col-form-label">{lang.labels.logo}</div>
              </div>
              <div className="col-lg-8">
                <div className="image-input image-input-outline" data-kt-image-input="true">
                  <img src={logo.valid && logo.value.length > 0 ? logo.value : imgDefault} alt={lang.labels.logo} className="image-input-wrapper w-auto h-125px bgi-position-center" />
                  <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.changeImage} onClick={removeTooltip}>
                    <i className="bi bi-pencil-fill fs-8"></i>
                    <input type="file" name="avatar" accept=".png, .jpg, .jpeg" onChange={handleChangeLogo} />
                  </label>
                  <span className={`btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow ${(!logo.valid || logo.value.length === 0) && "d-none"}`} data-kt-image-input-action="remove" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.removeImage} onClick={handleRemoveLogo}>
                    <i className="bi bi-x fs-2"></i>
                  </span>
                </div>
                <div className="form-text text-justify">{lang.labels.allowedFileTypes}</div>
                <AppLegend attribute={{validity: logo.valid, name: "logo", index: null, sub_index: null}} container={{valid: false, required: false, size: true, type: true, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-xl-3">
                <div className="col-form-label required">{lang.labels.street}</div>
              </div>
              <div className="col-xl-9 fv-row fv-plugins-icon-container">
                <input  id="input-street" className="form-control form-control-solid" type="text" name="street" value={street.value} onChange={handleChangeStreet} onKeyUp={validateStreet} onBlur={validateStreet} />
                <AppLegend attribute={{validity: street.valid, name: "street", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-xl-3">
                <div className="col-form-label">
                  <div className="d-flex align-items-center">
                    <span className="required">{lang.labels.number}</span>
                    <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.ifAddressDoesNotHaveANumber}><FaExclamationCircle /></i>
                  </div>
                </div>
              </div>
              <div className="col-xl-9 fv-row fv-plugins-icon-container">
                <input id="input-street-number" className="form-control form-control-solid" type="text" name="street-number" value={streetNumber.value} onChange={handleChangeNumber} onKeyUp={validateNumber} onBlur={validateNumber} />
                <AppLegend attribute={{validity: streetNumber.valid, name: "street-number", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-xl-3">
                <div className="col-form-label">
                  <div className="d-flex align-items-center">
                    {lang.labels.additional}
                    <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.ifNecessaryEnterSomethingReferentialRegardingAddress}><FaExclamationCircle /></i>
                  </div>
                </div>
              </div>
              <div className="col-xl-9 fv-row fv-plugins-icon-container">
                <input id="input-additional" className="form-control form-control-solid" type="text" name="additional" value={additional.value} onChange={handleChangeAdditional} onKeyUp={validateAdditional} onBlur={validateAdditional} />
                <AppLegend attribute={{validity: additional.valid, name: "additional", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-xl-3">
                <div className="col-form-label required">{lang.labels.administrativeArea}</div>
              </div>
              <div className="col-xl-9 fv-row fv-plugins-icon-container">
                <input id="input-administrative-area" className="form-control form-control-solid" type="text" name="administrative-area" value={administrativeArea.value} onChange={handleChangeAdministrativeArea} onKeyUp={validateAdministrativeArea} onBlur={validateAdministrativeArea} />
                <AppLegend attribute={{validity: administrativeArea.valid, name: "administrative-area", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-xl-3">
                <div className="col-form-label">
                  <div className="d-flex align-items-center">
                    <span className="required">{lang.labels.zipCode}</span>
                    <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.enterThePostalCodeOfTheCity}><FaExclamationCircle /></i>
                  </div>
                </div>
              </div>
              <div className="col-xl-9 fv-row fv-plugins-icon-container">
                <input id="input-zip-code" className="form-control form-control-solid" type="text" name="zip-code" value={zipCode.value} onChange={handleChangeZipCode} onKeyUp={validateZipCode} onBlur={validateZipCode} />
                <AppLegend attribute={{validity: zipCode.valid, name: "zip-code", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-xl-3">
                <div className="col-form-label required">{lang.labels.city}</div>
              </div>
              <div className="col-xl-9 fv-row fv-plugins-icon-container">
                <input id="input-city" className="form-control form-control-solid" type="text" name="city" value={city.value} onChange={handleChangeCity} onKeyUp={validateCity} onBlur={validateCity} />
                <AppLegend attribute={{validity: city.valid, name: "city", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-xl-3">
                <div className="col-form-label required">{lang.labels.country}</div>
              </div>
              <div className="col-xl-9 fv-row fv-plugins-icon-container">
                <AppInputCountry country={country} setCountry={setCountry}></AppInputCountry>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-xl-3">
                <div className="col-form-label required">{lang.labels.email}</div>
              </div>
              <div className="col-xl-9 fv-row fv-plugins-icon-container">
                <input id="input-email" className="form-control form-control-solid" type="text" name="email" value={email.value} onChange={handleChangeEmail} onKeyUp={validateEmail} onBlur={validateEmail} />
                <AppLegend attribute={{validity: email.valid, name: "email", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-xl-3">
                <div className="col-form-label required">{lang.labels.phoneNumber}</div>
              </div>
              <div className="col-xl-9 fv-row fv-plugins-icon-container">
                <input id="input-phone" className="form-control form-control-solid" type="text" name="phone" value={phone.value} onChange={handleChangePhone} onKeyUp={validatePhone} onBlur={validatePhone} />
                <AppLegend attribute={{validity: phone.valid, name: "phone", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-xl-3">
                <div className="col-form-label">{lang.labels.webSite}</div>
              </div>
              <div className="col-xl-9 fv-row fv-plugins-icon-container">
                <input id="input-website" className="form-control form-control-solid" type="text" name="website" value={website.value} onChange={handleChangeWebsite} onKeyUp={validateWebsite} onBlur={validateWebsite} />
                <AppLegend attribute={{validity: website.valid, name: "website", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-3 d-none">
              <div className="col-xl-3">
                <div className="col-form-label">{lang.labels.registerNumber}</div>
              </div>
              <div className="col-xl-9 fv-row fv-plugins-icon-container">
                <input id="input-register-number" className="form-control form-control-solid" type="text" name="register-number" value={registerNumber.value} onChange={handleChangeRegisterNumber} onKeyUp={validateRegisterNumber} onBlur={validateRegisterNumber} />
                <AppLegend attribute={{validity: registerNumber.valid, name: "register-number", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-xl-3">
                <div className="col-form-label">
                  <div className="d-flex align-items-center">
                    {lang.labels.vatNumber}
                    <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.identificationNumberForACompany}><FaExclamationCircle /></i>
                  </div>
                </div>
              </div>
              <div className="col-xl-9 fv-row fv-plugins-icon-container">
                <input id="input-vat-number" className="form-control form-control-solid" type="text" name="vat-number" value={vatNumber.value} onChange={handleChangeVatNumber} onKeyUp={validateVatNumber} onBlur={validateVatNumber} />
                <AppLegend attribute={{validity: vatNumber.valid, name: "vat-number", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-xl-3">
                <div className="col-form-label required">{lang.labels.currency}</div>
              </div>
              <div className="col-xl-9 fv-row fv-plugins-icon-container">
                <AppInputCurrency currency={currency} setCurrency={setCurrency}></AppInputCurrency>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-xl-3">
                <div className="col-form-label">
                  <div className="d-flex align-items-center">
                    <span className="required">{lang.labels.language}</span>
                    <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.languageToDownload}><FaExclamationCircle /></i>
                  </div>
                </div>
              </div>
              <div className="col-xl-9 fv-row fv-plugins-icon-container">
                <AppInputLanguage language={language} setLanguage={setLanguage}></AppInputLanguage>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-xl-3">
                <div className="col-form-label">
                  <div className="d-flex align-items-center">
                    <span className="required">{lang.labels.qrType}</span>
                    <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.shapesOrVersionsOfQR}><FaExclamationCircle /></i>
                  </div>
                </div>
              </div>
              <div className="col-xl-9 fv-row fv-plugins-icon-container">
                <AppInputQrType qrType={qrType} setQrTtype={setQrTtype}></AppInputQrType>
              </div>
            </div>
            <div className="row mb-8">
              <div className="col-xl-3">
                <div className="col-form-label">
                  <div className="d-flex align-items-center">
                    {lang.labels.vatSpecialRegime}
                    <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.theVatRegimeCanOnlyChangedOnceAYear}><FaExclamationCircle /></i>
                  </div>
                </div>
              </div>
              <div className="col-xl-9 fv-row fv-plugins-icon-container">
                <input id="input-register-number" className="form-control form-control-solid" type="text" name="regime-vat" value={companyForUser.subject_tax ? `${taxMethodCompanyValue(lang, companyForUser.tax_method)} ${companyForUser.tax_rate.length > 0 && `(${taxRateFormat(companyForUser.tax_rate)})`}` : lang.labels.none} disabled />
              </div>
            </div>
            <div className="notice d-flex align-items-center bg-light-warning border border-warning border-dashed rounded w-100 p-5">
              <span className="svg-icon svg-icon-3x svg-icon-warning me-5">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black" />
                  <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black" />
                  <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black" />
                </svg>
              </span>
              <div className="d-grid">
                <span className="text-start text-dark fw-bold">{lang.labels.important}</span>
                <span className="text-justify text-gray-700 fw-bold">
                  {lang.labels.ifYouWishChangeVatRegimePleaseContact}
                  <a href="mailto:support@digitaladmin.ch" className="text-primary text-lowercase fw-bolder mx-1">support@digitaladmin.ch</a>
                  {lang.labels.orCall}
                  <a href="tel:+41792830646" className="text-primary text-lowercase fw-bolder mx-1">+41 79 283 06 46</a>
                  .
                </span>
              </div>
            </div>
          </div>
          <div className="card-footer d-flex justify-content-end">
            <button className="btn btn-light btn-active-light-primary" type="reset" onClick={executeFormRestart}>{lang.labels.discard}</button>
            <button className="btn btn-primary ms-2" type="button" data-kt-indicator={loadIndicator} onClick={submitCompanyUpdate}>
              <span className="indicator-label">
                {lang.labels.saveChanges}
              </span>
              <span className="indicator-progress">
                {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
        </div>
        :
        <AppLoading></AppLoading>
      }
    </div>
  )
};

export default AppCompanyUpdateGeneral;
