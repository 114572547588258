import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { getError } from '../../languages/translations/response';
import { TreasuryAccountDA } from '../../services/treasury.account.service';
import { Error } from '../../models/error.model';
import { Company } from '../../models/company.model';
import { TreasuryAccount } from '../../models/treasury.account.model';
import { TreasuryAccountHolder } from '../../models/treasury.account.holder.model';
import { legendInvalidIcon, legendInvalid } from '../../tools/legend.data.entry.tool';
import { modalHide } from '../../tools/modal.tool';
import { isEqualObjects } from '../../scripts/compare.object.script';

import AppFormTreasuryCreate from '../../interfaces/main/treasury/component/FormTreasuryCreate';

export interface AppModalTreasuryAccountUpdateProps {
  companyForUser: Company | undefined | null,
  treasuryAccount: TreasuryAccount | undefined | null,
  setTreasuryAccount: Dispatch<SetStateAction<TreasuryAccount | undefined | null>>,
  loadTreasuryAccountsForCompany: Function
};

let errorResponse: Error, treasuryAccountsResponse: TreasuryAccount[];

const AppModalTreasuryAccountUpdate: React.FunctionComponent<AppModalTreasuryAccountUpdateProps> = ({companyForUser, treasuryAccount, setTreasuryAccount, loadTreasuryAccountsForCompany}) => {
  const {lang} = StateLanguage()
  const navigate = useNavigate()

  const [loadIndicator, setLoadIndicator] = useState('off')
  const [nameAccount, setNameAccount] = useState({value: '', valid: false})
  const [iban, setIban] = useState({value: '', valid: false})
  const [bban, setBban] = useState({value: '', valid: false})
  const [bic, setBic] = useState({value: '', valid: false})
  const [membership, setMembership] = useState({value: '', valid: false})
  const [qrIban, setQrIban] = useState({value: '', valid: false})
  const [reference, setReference] = useState({value: '', valid: false})
  const [nameHolder, setNameHolder] = useState({value: '', valid: false})
  const [street, setStreet] = useState({value: '', valid: false})
  const [streetNumber, setStreetNumber] = useState({value: '', valid: false})
  const [additional, setAdditional] = useState({value: '', valid: false})
  const [administrativeArea, setAdministrativeArea] = useState({value: '', valid: false})
  const [city, setCity] = useState({value: '', valid: false})
  const [zipCode, setZipCode] = useState({value: '', valid: false})
  const [country, setCountry] = useState({value: '', valid: false})
  const [options, setOptions] = useState({is_default: false, is_reference: false, is_holder: false})

  const submitTreasuryAccounttUpdate = async () => {
    setLoadIndicator('on')

    if (companyForUser && treasuryAccount && nameAccount.valid && iban.valid && bban.valid && bic.valid && membership.valid && qrIban.valid && reference.valid && reference.valid && nameHolder.valid && street.valid && streetNumber.valid && additional.valid && administrativeArea.valid && city.valid && zipCode.valid && country.valid) {
      let valueIban: string = iban.value.toUpperCase().trim().replaceAll(' ', '')
      let valueBban: string = bban.value.toUpperCase().trim().replaceAll(' ', '')
      let valueBic: string = bic.value.toUpperCase().trim().replaceAll(' ', '')
      let valueQrIban: string = qrIban.value.toUpperCase().trim().replaceAll(' ', '')
      let addressStreetNumber: string = (streetNumber.value === 'NN' || streetNumber.value === 'nn') ? streetNumber.value.toUpperCase() : streetNumber.value
      let accountHolder: TreasuryAccountHolder = {full_name: nameHolder.value, address: {street: street.value, number: addressStreetNumber, additional: additional.value, administrative_area: administrativeArea.value, city: city.value, zip_code: zipCode.value, country: country.value}}

      await TreasuryAccountDA.putTreasuryAccount(companyForUser.id, treasuryAccount.id, nameAccount.value, valueIban, valueBban, valueBic, membership.value, valueQrIban, reference.value, options.is_reference, accountHolder).then( (response) => {
        if (response.status === 200) {
          treasuryAccountsResponse = response.data
          loadTreasuryAccountsForCompany(companyForUser.id)

          Swal.fire({
            title: lang.labels.successfullyUpdatedTreasuryAccount,
            text: lang.labels.actionCompletedReturningToPage,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            executeHideModalTreasuryAccountUpdate()
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.code),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions)
        }
      }).catch( (error) => {
        console.error(error)
        navigate('/error')
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        if (nameAccount.value.length === 0) {
          legendInvalid('legend-name-account-required')
        }
        if (nameHolder.value.length === 0) {
          legendInvalidIcon('input-name-holder', 'legend-name-holder-required')
        }
        if (street.value.length === 0) {
          legendInvalidIcon('input-street', 'legend-street-required')
        }
        if (streetNumber.value.length === 0) {
          legendInvalidIcon('input-street-number', 'legend-street-number-required')
        }
        if (administrativeArea.value.length === 0) {
          legendInvalidIcon('input-administrative-area', 'legend-administrative-area-required')
        }
        if (city.value.length === 0) {
          legendInvalidIcon('input-city', 'legend-city-required')
        }
        if (zipCode.value.length === 0) {
          legendInvalidIcon('input-zip-code', 'legend-zip-code-required')
        }
        if (country.value.length === 0) {
          legendInvalid('legend-country-required')
        }
        setLoadIndicator('off')
      })
    }
  }

  const executeHideModalTreasuryAccountUpdate = () => {
    modalHide('modal-treasury-account-update')

    setTimeout( () => {
      restartModal()
    }, 200 )
  }

  function restartModal() {
    setTreasuryAccount(null)
  }

  useEffect( () => {
    if (treasuryAccount) {
      setNameAccount({value: treasuryAccount.name, valid: true})
      setIban({value: treasuryAccount.iban, valid: true})
      setBban({value: treasuryAccount.bban, valid: true})
      setBic({value: treasuryAccount.bic, valid: true})
      setMembership({value: treasuryAccount.membership, valid: true})
      setQrIban({value: treasuryAccount.qr_iban, valid: true})
      setReference({value: treasuryAccount.reference, valid: true})
      setNameHolder({value: treasuryAccount.account_holder.full_name, valid: true})
      setStreet({value: treasuryAccount.account_holder.address.street, valid: true})
      setStreetNumber({value: treasuryAccount.account_holder.address.number, valid: true})
      setAdditional({value: treasuryAccount.account_holder.address.additional, valid: true})
      setAdministrativeArea({value: treasuryAccount.account_holder.address.administrative_area, valid: true})
      setCity({value: treasuryAccount.account_holder.address.city, valid: true})
      setZipCode({value: treasuryAccount.account_holder.address.zip_code, valid: true})
      setCountry({value: treasuryAccount.account_holder.address.country, valid: true})
      setOptions({is_default: treasuryAccount.default, is_reference: treasuryAccount.is_reference, is_holder: (!!companyForUser && companyForUser.name === treasuryAccount.account_holder.full_name && isEqualObjects(companyForUser.address, treasuryAccount.account_holder.address))})
    } else {
      setNameAccount({value: '', valid: false})
      setIban({value: '', valid: false})
      setBban({value: '', valid: false})
      setBic({value: '', valid: false})
      setMembership({value: '', valid: false})
      setQrIban({value: '', valid: false})
      setReference({value: '', valid: false})
      setNameHolder({value: '', valid: false})
      setStreet({value: '', valid: false})
      setStreetNumber({value: '', valid: false})
      setAdditional({value: '', valid: false})
      setAdministrativeArea({value: '', valid: false})
      setCity({value: '', valid: false})
      setZipCode({value: '', valid: false})
      setCountry({value: '', valid: false})
      setOptions({is_default: false, is_reference: false, is_holder: false})
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treasuryAccount])

  return (
    <div id="modal-treasury-account-update" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="text-capitalize">{lang.labels.updateTreasuryAccountData}</h2>
          </div>
          <div className="modal-body">
            { companyForUser && treasuryAccount
              ?
              <AppFormTreasuryCreate component="modal-treasury-account-update" treasuryAccount={treasuryAccount} typeTreasuryAccount={null} accountingAccount={null} nameAccount={nameAccount} iban={iban} bban={bban} bic={bic} membership={membership} qrIban={qrIban} reference={reference} nameHolder={nameHolder} street={street} streetNumber={streetNumber} additional={additional} administrativeArea={administrativeArea} city={city} zipCode={zipCode} country={country} options={options} setTypeTreasuryAccount={null} setAccountingAccount={null} setNameAccount={setNameAccount} setIban={setIban} setBban={setBban} setBic={setBic} setMembership={setMembership} setQrIban={setQrIban} setReference={setReference} setNameHolder={setNameHolder} setStreet={setStreet} setStreetNumber={setStreetNumber} setAdditional={setAdditional} setAdministrativeArea={setAdministrativeArea} setCity={setCity} setZipCode={setZipCode} setCountry={setCountry} setOptions={setOptions}></AppFormTreasuryCreate>
              :
              <div className="d-flex justify-content-center align-items-center w-100 h-200px">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">{lang.labels.loading}</span>
                </div>
              </div>
            }
          </div>
          <div className="modal-footer flex-center">
            <button className="btn btn-light mx-2" type="reset" onClick={executeHideModalTreasuryAccountUpdate}>{lang.labels.discard}</button>
            <button className="btn btn-primary mx-2" type="button" data-kt-indicator={loadIndicator} onClick={submitTreasuryAccounttUpdate}>
              <span className="indicator-label">{lang.labels.update}</span>
              <span className="indicator-progress">
                {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
};

export default AppModalTreasuryAccountUpdate;
