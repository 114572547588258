import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { Message } from '../models/message.model';
import { CompanyNonUsers } from '../models/company.non.users.model';
import { TokenCompany } from '../models/token.company.model';
import { User } from '../models/user.model';
import { Company } from '../models/company.model';
import { Currency } from '../models/currency.model';
import { appToken } from '../scripts/app.token.script';
import { extractBase64 } from '../scripts/extract.base.64.script';

export class CompanyDL {
  static apiResponse: ApiResponse

  public static async getCompany(api_key: string, id_company: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let companyMain: Company | null = null
      let companyOther: Company | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/company.data.json').then(response => { companyMain = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/company.intermittent.data.json').then(response => { companyOther = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (companyMain!.id === id_company || companyOther!.id === id_company) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: (id_company === companyMain!.id) ? companyMain : companyOther
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_LOGIN}/${api_key}/companies/${id_company}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.get(url, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async getCompanyNonUsers(api_key: string, id_company: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let nonUsersByCompany: CompanyNonUsers | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/company.non.users.data.json').then(response => { nonUsersByCompany = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (nonUsersByCompany!.id_company === id_company) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: nonUsersByCompany
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_LOGIN}/${api_key}/users/invitation/${id_company}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.get(url, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async postCompany(api_key: string, name: string, logo: any, street: string, street_number: string, additional: string, administrative_area: string, city: string, zip_code: string, country: string, email: string, phone: string, website: string, register_number: string, vat_number: string, currency: Currency, subject_tax: boolean, vat_debt_method: string, tax_method: string, tax_rate: string, language: string, qr_type: string, ): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let company: Company | null = null
      let tokenData: TokenCompany | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/company.data.json').then(response => { company = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (company!.id) {
        tokenData = {
          authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI1NGE4Y2U2MThlOTFiMGIxMzY2NWUyZjkiLCJpYXQiOiIxNDI0MTgwNDg0IiwiZXhwIjoiMTQyNTM5MDE0MiJ9.yk4nouUteW54F1HbWtgg1wJxeDjqDA_22BASALUAN22',
          data: {
            id: company!.id,
            uuid: `8a6e0804-2bd0-4672-b79d-d97027f90a$${(Math.floor(Math.random() * 100)).toString()}`,
            name: name,
            logo: (logo && logo.length > 0) ? logo : '',
            email: email,
            phone: phone,
            website: website,
            register_number: register_number,
            vat_number: vat_number,
            currency: currency,
            subject_tax: subject_tax,
            vat_debt_method: vat_debt_method,
            tax_method: tax_method,
            tax_rate:  tax_rate,
            language: language,
            qr_type: qr_type,
            template_model: 'default',
            address: {
              street: street,
              number: street_number,
              additional: additional,
              administrative_area: administrative_area,
              city: city,
              zip_code: zip_code,
              country: country
            }
          }
        }

        if (logo && logo.length === undefined) {
          extractBase64(logo).then((image: any) => { tokenData!.data.logo = image.base })
        }

        this.apiResponse = {
          status: 201,
          message: 'Success',
          data: tokenData
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_LOGIN}/${api_key}/companies`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      let data: any = new FormData()
      data.append('logo', logo)
      data.append('name', name)
      data.append('phone', phone)
      data.append('email', email)
      data.append('website', website)
      data.append('register_number', register_number)
      data.append('vat_number', vat_number)
      data.append('street', street)
      data.append('number', street_number)
      data.append('additional', additional)
      data.append('administrative_area', administrative_area)
      data.append('city', city)
      data.append('zip_code', zip_code)
      data.append('country', country)
      data.append('currency_name', currency.name)
      data.append('currency_code', currency.code)
      data.append('currency_symbol', currency.symbol)
      data.append('subject_tax', subject_tax)
      data.append('vat_debt_method', vat_debt_method)
      data.append('tax_method', tax_method)
      data.append('tax_rate', tax_rate)
      data.append('language', language)
      data.append('qr_type', qr_type)

      await axios.post(url, data, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async putCompany(api_key: string, id_company: string, name: string, logo: any, street: string, street_number: string, additional: string, administrative_area: string, city: string, zip_code: string, country: string, email: string, phone: string, website: string, register_number: string, vat_number: string, currency: Currency, language: string, qr_type: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let company: Company | null = null
      let message: Message | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/company.data.json').then(response => { company = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/message.data.json').then(response => { message = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (company!.id === id_company) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: message
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_LOGIN}/${api_key}/companies/${id_company}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      let data: any = new FormData()
      data.append('logo', logo)
      data.append('name', name)
      data.append('phone', phone)
      data.append('email', email)
      data.append('website', website)
      data.append('register_number', register_number)
      data.append('vat_number', vat_number)
      data.append('street', street)
      data.append('number', street_number)
      data.append('additional', additional)
      data.append('administrative_area', administrative_area)
      data.append('city', city)
      data.append('zip_code', zip_code)
      data.append('country', country)
      data.append('currency_name', currency.name)
      data.append('currency_code', currency.code)
      data.append('currency_symbol', currency.symbol)
      data.append('language', language)
      data.append('qr_type', qr_type)

      await axios.put(url, data, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async putCompanyInvitation(api_key: string, id_company: string, id_user: string, comment: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let company: Company | null = null
      let user: User | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/company.non.users.data.json').then(response => { company = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (company!.users.some(item => item.id === id_user)) {
        user = company!.users.find(item => item.id === id_user)!

        switch (user.invitation) {
          case 'not sent':
            user.invitation = 'sent'
            break;
          case 'sent':
            user.invitation = 'not sent'
            break;
          case 'accepted':
            user.invitation = 'not sent'
            break;
        }

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: user
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_LOGIN}/${api_key}/companies/${id_company}/invitation/${id_user}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      let data: any = {
        comment: comment
      }

      await axios.put(url, data, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async putCompanyInvitationReply(api_key: string, id_notification: string, id_company: string, id_user: string, reply: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let company: Company | null = null
      let user: User | null = null
      let tokenAccept: TokenCompany | null = null
      let tokenReject: TokenCompany | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/company.data.json').then(response => { company = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/user.data.json').then(response => { user = response.data.user }).catch(error => { alert(error) })
      await axios.get('../../../../../database/token.company.accept.data.json').then(response => { tokenAccept = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/token.company.reject.data.json').then(response => { tokenReject = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (company!.id === id_company && user!.id !== id_user) {
        if (reply === 'accept') {
          company!.users.push(user!)
        }

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: (reply === 'accept') ? tokenAccept : tokenReject
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_LOGIN}/${api_key}/notifications/${id_notification}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      let data: any = {
        id_company: id_company,
        id_user: id_user,
        reply: reply
      }

      await axios.put(url, data, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }
}
