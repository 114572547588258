// import CurrencyCodes from 'currency-codes'
// import CurrencyList from 'currency-list'
import { Currency } from '../models/currency.model'
import { currencies } from '../languages/translations/currencies';

const codeEnMap: { [key: string]: Currency } = {}
const codeEsMap: { [key: string]: Currency } = {}
const codeFrMap: { [key: string]: Currency } = {}

function mapCodeAndNameAndSymbol(currency: any): void {
  codeEnMap[currency.code.toLowerCase()] = { code: currency.code, name: currency.en, symbol: currency.symbol }
  codeEsMap[currency.code.toLowerCase()] = { code: currency.code, name: currency.es, symbol: currency.symbol }
  codeFrMap[currency.code.toLowerCase()] = { code: currency.code, name: currency.fr, symbol: currency.symbol }
}

currencies.forEach(mapCodeAndNameAndSymbol)

export function currencyList(code_language: string): Currency[] {
  let key = code_language.toLowerCase() as 'en' | 'es' | 'fr'
  return currencies.map((item) => ({code: item.code, name: item[key] || item.en, symbol: item.symbol}))
}

export function currencyValue(code_language: string, code: string): string {
  let key = code_language.toLowerCase() as 'en' | 'es' | 'fr'
  let currency = (key === 'es' ? codeEsMap : key === 'fr' ? codeFrMap : codeEnMap)[code.toLowerCase()]
  return `${currency.code}: ${currency.name} (${currency.symbol})` 
}

export function currencyAll(): Currency[] {
  let currencies: Currency[] = []

  /* for (let code of CurrencyCodes.codes()) {
    if (CurrencyList.get(code)) {
      currencies.push({code: CurrencyList.get(code).code, name: CurrencyList.get(code).name, symbol: CurrencyList.get(code).symbol})
    }
  } */

  return currencies
}