import { Dispatch, SetStateAction } from "react"
import { expressions } from "../libraries/regular.expressions.library"
import { controllerFiveApproved, controllerFourApproved, controllerOneApproved, controllerThreeApproved, controllerTwoApproved, controllerZeroApproved } from "../tools/meter.security.password.tool"
import { legendInvalidRequiredConfirmationIcon, legendInvalidEmpty, legendInvalidEmptyIcon, legendInvalidEmptyConfirmationIcon, legendValidRequired, legendValidRequiredConfirmationIcon, legendValidRequiredIcon } from "../tools/legend.data.entry.tool"

export function evaluatePassword(password: {value: string, valid: boolean}, set_password: Dispatch<SetStateAction<{value: string, valid: boolean}>>, identifier: string, container_1: string, container_2: string) {
  if (expressions) {
    let options: boolean[] = [false, false, false, false, false]

    options[0] = (expressions.islowercase.test(password.value)) ? true : false
    options[1] = (expressions.isuppercase.test(password.value)) ? true : false
    options[2] = (expressions.isnumber.test(password.value)) ? true : false
    options[3] = (expressions.issymbol.test(password.value)) ? true : false
    options[4] = (expressions.limit.test(password.value)) ? true : false

    if (options.filter((item) => (item === false)).length === 0) {
      set_password({...password, valid: true})
      legendValidRequiredIcon(identifier, container_1, container_2)
    } else {
      set_password({...password , valid: false})
      legendInvalidEmptyIcon(password.value, identifier, container_1, container_2)
    }
  }
}

export function evaluatePasswordController(password: {value: string, valid: boolean}, set_password: Dispatch<SetStateAction<{value: string, valid: boolean}>>, input_container_1: string, input_container_2: string, controller_container_1: string, controller_container_2: string, controller_container_3: string, controller_container_4: string, controller_container_5: string) {
  if (expressions) {
    let options: boolean[] = [false, false, false, false, false]

    options[0] = (expressions.islowercase.test(password.value)) ? true : false
    options[1] = (expressions.isuppercase.test(password.value)) ? true : false
    options[2] = (expressions.isnumber.test(password.value)) ? true : false
    options[3] = (expressions.issymbol.test(password.value)) ? true : false
    options[4] = (expressions.limit.test(password.value)) ? true : false

    switch (options.filter((item) => (item === true)).length) {
      case 0:
        set_password({...password, valid: false})
        legendInvalidEmpty(password.value, input_container_1, input_container_2)
        controllerZeroApproved(controller_container_1, controller_container_2, controller_container_3, controller_container_4, controller_container_5)
        break;
      case 1:
        set_password({...password, valid: false})
        legendInvalidEmpty(password.value, input_container_1, input_container_2)
        controllerOneApproved(controller_container_1, controller_container_2, controller_container_3, controller_container_4, controller_container_5)
        break;
      case 2:
        set_password({...password, valid: false})
        legendInvalidEmpty(password.value, input_container_1, input_container_2)
        controllerTwoApproved(controller_container_1, controller_container_2, controller_container_3, controller_container_4, controller_container_5)
        break;
      case 3:
        set_password({...password, valid: false})
        legendInvalidEmpty(password.value, input_container_1, input_container_2)
        controllerThreeApproved(controller_container_1, controller_container_2, controller_container_3, controller_container_4, controller_container_5)
        break;
      case 4:
        set_password({...password, valid: false})
        legendInvalidEmpty(password.value, input_container_1, input_container_2)
        controllerFourApproved(controller_container_1, controller_container_2, controller_container_3, controller_container_4, controller_container_5)
        break;
      case 5:
        set_password({...password, valid: true})
        legendValidRequired(input_container_1, input_container_2)
        controllerFiveApproved(controller_container_1, controller_container_2, controller_container_3, controller_container_4, controller_container_5)
        break;
    }
  }
}

export function evaluatePasswordConfirmation(is_input_password: boolean, password: {value: string, valid: boolean}, password_confirmation: {value: string, valid: boolean}, set_password_confirmation: Dispatch<SetStateAction<{value: string, valid: boolean}>>, identifier: string, container_1: string, container_2: string, container_3: string) {
  if (is_input_password === true && password.value.length === 0) {
    set_password_confirmation({...password_confirmation , valid: false})
    legendInvalidRequiredConfirmationIcon(identifier, container_1, container_2, container_3)
  } else {
    if (expressions && password_confirmation.value.length > 0) {
      if (expressions.password.test(password_confirmation.value) && password_confirmation.value === password.value) {
        set_password_confirmation({...password_confirmation , valid: true})
        legendValidRequiredConfirmationIcon(identifier, container_1, container_2, container_3)
      } else {
        set_password_confirmation({...password_confirmation , valid: false})
        legendInvalidEmptyConfirmationIcon(expressions.password, password.value, password_confirmation.value, identifier, container_1, container_2, container_3)
      }
    } else {
      set_password_confirmation({...password_confirmation , valid: false})
      legendInvalidRequiredConfirmationIcon(identifier, container_1, container_2, container_3)  
    }
  }
}
