import { Language } from "../languages/labels/language.labels";

export function deadlineTypeList(lang: Language): {code: string, display_singular: string, display_plural: string}[] {
  return [
    { code: 'year', display_singular: lang.labels.year, display_plural: lang.labels.years },
    { code: 'month', display_singular: lang.labels.month, display_plural: lang.labels.months  },
    { code: 'week', display_singular: lang.labels.week, display_plural: lang.labels.week },
    { code: 'day', display_singular: lang.labels.day, display_plural: lang.labels.days  }
  ]
}

export function deadlineTypeValue(lang: Language, code: string, usePlural: boolean): string {
  let deadlineType = deadlineTypeList(lang).find((item) => (item.code === code))
  return deadlineType ? usePlural ? deadlineType.display_plural : deadlineType.display_singular : lang.labels.undefined
}
