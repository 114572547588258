import flagDefault from '../assets/images/icons/flag-icon.svg';
import flagUnitedStates from '../assets/images/icons/flag-united-states-icon.svg';
import flagFrance from '../assets/images/icons/flag-france-icon.svg';
import flagSpain from '../assets/images/icons/flag-spain-icon.svg';

export function appLanguageList(): {code: string, label: string}[] {
  return [
    { code: 'english', label: 'English' },
    { code: 'french', label: 'Français' },
    { code: 'spanish', label: 'Español' }
  ]
}

export function appLanguageValue(code: string): string {
  let language = appLanguageList().find((item) => (item.code === code))
  return language?.label ?? 'English'
}

export function appLanguageIcon(language: string): string {
  switch (language) {
    case 'english':
      return flagUnitedStates
    case 'french':
      return flagFrance
    case 'spanish':
      return flagSpain
    default:
      return flagDefault
  }
}

export function appLanguageUpload(dispatch_language: any): string {
  let idiom: string = localStorage.getItem('language') || 'english'

  if (!localStorage.getItem('language')) {
    localStorage.setItem('language', idiom)
  }

  dispatch_language({type: idiom})

  return idiom
}

export function appLanguageIsValidRoute(param_language: any): boolean {
  return !param_language || ['en', 'fr', 'es'].includes(param_language);
}

export function appLanguageSaveRoute(param_language: any) {
  let language: Record<string, string> = { en: 'english', fr: 'french', es: 'spanish' }

  if (param_language && language[param_language]) {
    localStorage.setItem('language', language[param_language])
  } else if (!localStorage.getItem('language')) {
    localStorage.setItem('language', '')
  }
}
