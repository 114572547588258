export const contentFrench = {
  code: "FR",
  labels: {
    // App Digital Admin
    digitalAdmin: "Digital Admin",
    digitalAdminApp: "App Digital Admin",
    digitalAdminPro: "Digital Admin Pro",
    digitalAdminSarl: "Digital Admin SARL",
    welcomeToDigitalAdmin: "Bienvenue dans Digital Admin",
    purchasePro: "Acheter Digital Admin Pro",
    signIn: "Se connecter à Digital Admin",

    // Words sentences
    about: "À propos",
    successful: "Avec succès",
    error: "Erreur",
    accountSettings: "Paramètres du compte",
    actions: "Actions",
    additional: "Supplémentaire",
    address: "Adresse",
    administrativeArea: "Zone administrative / Département",
    amount: "Montant",
    amountTotal: "Montant total",
    apply: "Appliquer",
    approved: "Approuvé",
    ascending: "Ascendant",
    addContact: "Ajouter un contact",
    addContent: "Ajouter du contenu",
    addSignature: "Ajouter une signature",
    avatar: "Avatar",
    back: "Retour",
    bban: "BBAN",
    bicSwift: "BIC/SWIFT",
    bimester: "Bimestre",
    calculatedValuesAreReferential: "Les valeurs calculées sont indicatives",
    cancel: "Annuler",
    canceled: "Annulé",
    city: "Ville",
    comment: "Commentaire",
    completed: "Terminé",
    companyContact: "Contact de l'entreprise",
    content: "Contenu",
    registerNumber: "Numéro d'enregistrement",
    concerning: "Objet",
    contactInformation: "Informations de contact",
    continue: "Continuer",
    country: "Pays",
    create: "Créer",
    createdDate: "Date de création",
    currency: "Devise",
    customer: "Client",
    date: "Date",
    day: "Jour",
    days: "Jours",
    default: "Par défaut",
    delete: "Supprimer",
    descending: "Descendant",
    description: "Description",
    detail: "Détail",
    details: "Détails",
    discard: "Ignorer",
    doNotGroup: "Ne pas regrouper",
    edit: "Éditer",
    email: "E-mail",
    end: "Fin",
    endDate: "Date de fin",
    excel: "Excel",
    export: "Exporter",
    filter: "Filtre",
    filterOptions: "Options de filtre",
    firstName: "Prénom",
    group: "Groupe",
    halfYear: "Demi-année",
    iban: "IBAN",
    identity: "Identité",
    inProgress: "En cours",
    justNow: "À l’instant",
    lastDay: "Dernier jour",
    lastName: "Nom de famille",
    legalInformation: "Informations légales",
    list: "Liste",
    location: "Emplacement",
    menu: "Menu",
    message: "Message",
    noNumber: "Sans numéro",
    noMatchingRecordsFound: "Aucun enregistrement correspondant trouvé",
    month: "Mois",
    months: "Mois",
    number: "Numéro",
    sortAlphabetically: "Trier par ordre alphabétique",
    pages: "Pages",
    pdf: "PDF",
    pending: "En attente",
    person: "Personne",
    phone: "Téléphone",
    phoneNumber: "Numéro de téléphone",
    priceGross: "Prix brut",
    priceNet: "Prix net",
    priceTotal: "Prix total",
    product: "Produit",
    products: "Produits",
    qrIban: "QR-IBAN",
    quantity: "Quantité",
    rejected: "Rejeté",
    remove: "Supprimer",
    removeReference: "Supprimer la référence",
    removeContent: "Supprimer le contenu",
    removeSignature: "Supprimer la signature",
    removeRequirement: "Supprimer le bloc",
    removeVoucher: "Supprimer le reçu",
    requirement: "Bloc",
    requirements: "Blocs",
    reset: "Réinitialiser",
    saveChanges: "Enregistrer les modifications",
    searchResults: "Résultats de recherche",
    selectExportFormat: "Sélectionner le format d'exportation",
    send: "Envoyer",
    service: "Service",
    services: "Services",
    appService: "Service de l'application",
    appServices: "Services de l'application",
    signature: "Signature",
    signatures: "Signatures",
    start: "Début",
    startDate: "Date de début",
    status: "Statut",
    street: "Rue",
    subTotal: "Sous-total",
    support: "Support",
    symbol: "Symbole",
    tax: "Taxe",
    taxRate: "Taux de taxe",
    taxTotal: "Total des taxes",
    trimester: "Trimestre",
    total: "Total",
    totalAmount: "Montant total",
    top: "Top",
    type: "Type",
    timeInterval: "Intervalle de temps",
    interval: "Intervalle",
    unitMeasure: "Unité de mesure",
    unitPrice: "Prix unitaire",
    update: "Mettre à jour",
    validity: "Validité",
    value: "Valeur",
    vatNumber: "Numéro de VAT/IDE",
    webSite: "Site web",
    week: "Semaine",
    weeks: "Semaines",
    view: "Voir",
    yes: "Oui",
    zipCode: "Code postal",
    gross: "Brut",
    net: "Net",
    rebate: "Remise",
    rebateTotal: "Total de la remise",
    selectOptionAddAmountPercentageDesired: "Sélectionnez une option et ajoutez le montant ou pourcentage souhaité",
    changeImage: "Changer l'image",
    changeLogo: "Changer le logo",
    exportError: "Erreur lors de l'exportation",
    failedToLoadTheScript: "Échec du chargement du script",
    identificationNumberForACompany: "Numéro d'identification d'une entreprise souhaitant effectuer des opérations au niveau européen",
    internationalBankAccountNumberIban: "Numéro de compte bancaire international (IBAN)",
    listOfCompaniesIHaveAccess: "Liste des entreprises auxquelles j'ai accès",
    noRecordsFound: "Aucun enregistrement trouvé",
    noResultsFound: "Aucun résultat trouvé",
    removeImage: "Supprimer l'image",
    selectTheTypeOfContact: "Sélectionnez le type de contact que vous souhaitez créer",
    specifyTheUniqueUUID: "Spécifiez l'UUID unique d'un utilisateur ou d'une entreprise",
    theDefaultBankAccountIsTheCompanys: "Le compte de trésorerie par défaut est celui de l'entreprise",
    theDefaultCurrencyIsTheCompanys: "La devise par défaut est celle de l'entreprise",
    theDefaultShapeOrVersionOfAQrInvoiceToDownloadIsTheClients: "La forme ou version par défaut d'une facture QR à télécharger est celle du client",
    theDefaultLanguageToDownloadIsTheClients: "La langue par défaut pour le téléchargement est celle du client",
    youMustAcceptTheTermsAndConditions: "Vous devez accepter les termes et conditions",
    acceptingInvitationToAccessCompany: "Acceptation de l'invitation pour accéder à l'entreprise",
    verificationYourAccount: "Vérification de votre compte",
    addCompany: "Ajouter une entreprise",
    allInLicense: "Licence complète",
    buyNow: "Acheter maintenant",
    comingSoon: "Bientôt disponible",
    licenseTerms: "Conditions de licence",
    pageNotFound: "Page non trouvée",
    viewInvitations: "Voir les invitations",
    purchase: "Acheter",
    reload: "Recharger",
    systemError: "Erreur système",
    dashboard: "Tableau de bord",
    abbreviation: "Abréviation",
    amountPurchases: "Montant des achats",
    amountSales: "Montant des ventes",
    best: "Meilleur",
    companyInsight: "Aperçu de l'entreprise",
    comparativeInterval: "Intervalle de comparaison",
    comparisonIncomeAndExpenses: "Comparaison des revenus & dépenses",
    customerPayments: "Paiements du client",
    customRange: "Plage personnalisée",
    debtPercent: "Dette(%)",
    distributionOfQuotes: "Répartition des devis selon leur état",
    distributionOfOrders: "Répartition des commandes selon leur état",
    expirationDate: "Date d'expiration",
    invoicesStillToBePaid: "Factures en attente de paiement",
    invoicesStillToBePaidByTheCustomer: "Factures en attente de paiement par le client",
    invoicesWithMoreDebt: "Factures avec plus de dette",
    ordersByState: "Commandes par état",
    ordersToBilled: "Commandes à facturer",
    paidPercent: "Payé(%)",
    percentage: "Pourcentage",
    quotesByState: "Devis par état",
    quotesAccepted: "Devis acceptés",
    quotesApproved: "Devis approuvés",
    quotesToExpire: "Devis à expirer",
    retailAnalysisSample: "Échantillon d'analyse du commerce de détail",
    representationOfSalesAndPurchases: "Représentation des ventes et achats d'une entreprise par mois sur un an",
    salesVsPurchases: "Ventes vs Achats",
    thisBimester: "Ce bimestre",
    thisTrimester: "Ce trimestre",
    thisHalfYear: "Ce semestre",
    thisMonth: "Ce mois-ci",
    thisWeek: "Cette semaine",
    thisYear: "Cette année",
    today: "Aujourd'hui",
    turnout: "Montant des factures",
    viewAll: "Voir tout",
    worst: "Pire",
    yesterday: "Hier",
    fixedAmount: "Montant fixe",
    discount: "Remise",
    account: "Compte",
    changeEmail: "Changer l'e-mail",
    changePassword: "Changer le mot de passe",
    confirmNewPassword: "Confirmer le nouveau mot de passe",
    currentPassword: "Mot de passe actuel",
    emailAddress: "Adresse e-mail",
    enterNewEmailAddress: "Saisissez une nouvelle adresse e-mail",
    fullName: "Nom complet",
    newPassword: "Nouveau mot de passe",
    pleaseCheckYourEmailInbox: "Veuillez vérifier votre boîte de réception",
    profileCompleation: "Finalisation du profil",
    profileDetails: "Détails du profil",
    signInMethod: "Méthode de connexion",
    updateEmail: "Mettre à jour l'e-mail",
    updatePassword: "Mettre à jour le mot de passe",
    updateUser: "Mettre à jour l'utilisateur",
    validateAccount: "Valider le compte",
    viewUser: "Voir l'utilisateur",
    user: "Utilisateur",
    users: "Utilisateurs",
    confirmPassword: "Confirmer le mot de passe",
    createAnAccount: "Créer un compte",
    enterTheNewPasswordForTheMail: "Saisissez le nouveau mot de passe pour l'e-mail",
    enterYourEmailToResetYourPassword: "Saisissez votre e-mail pour réinitialiser votre mot de passe",
    goToLoginPage: "Aller à la page de connexion",
    goToPreviousPage: "Aller à la page précédente",
    language: "Langue",
    languages: "Langues",
    myProfile: "Mon profil",
    notVerified: "Non vérifié",
    password: "Mot de passe",
    resetPassword: "Réinitialiser le mot de passe",
    signInHere: "Connectez-vous ici",
    signOut: "Se déconnecter",
    submit: "Soumettre",
    userProfile: "Profil utilisateur",
    use8OrMoreCharacters: "Utilisez 8 caractères ou plus avec une combinaison de lettres (minuscules et majuscules), de chiffres et de symboles",
    verified: "Vérifié",
    welcome: "Bienvenue",
    company: "Entreprise",
    companyParameterSettings: "Paramètres des paramètres de l'entreprise",
    companies: "Entreprises",
    current: "Actuel",
    addDeadlinePaymentParameter: "Ajouter un paramètre de paiement de date limite",
    addImage: "Ajouter une image",
    addTax: "Ajouter une taxe",
    addDeadline: "Ajouter une date limite",
    addTaxRateParameter: "Ajouter un paramètre de taux de taxe",
    addUnit: "Ajouter une unité",
    addUnitMeasureParameter: "Ajouter un paramètre d'unité de mesure",
    advanceSettings: "Paramètres avancés",
    aNewCompanyWillBeCreated: "Une nouvelle entreprise sera créée à partir des données saisies",
    browseUsers: "Parcourir les utilisateurs",
    companyAddress: "Adresse de l'entreprise",
    companyAdvanceSettings: "Paramètres avancés de l'entreprise",
    companyDownloadSettings: "Paramètres de téléchargement de l'entreprise",
    companyGeneralSettings: "Paramètres généraux de l'entreprise",
    companyIdentity: "Identité de l'entreprise",
    companyLogo: "Logo de l'entreprise",
    companyName: "Nom de l'entreprise",
    createCompany: "Créer une entreprise",
    createAndSave: "Créer & Enregistrer",
    createNewCompany: "Créer une nouvelle entreprise",
    designOfDocumentToDownloadQuotesAndInvoices: "Conception du document pour télécharger les devis, commandes et factures",
    downloadSettings: "Paramètres de téléchargement",
    dueDates: "Dates d'échéance pour le paiement d'une facture",
    editCompany: "Modifier l'entreprise",
    free: "Gratuit",
    generalSettings: "Paramètres généraux",
    helpPage: "Page d'aide",
    image: "Image",
    inviteUser: "Inviter un utilisateur",
    legal: "Légal",
    listUsers: "Liste des utilisateurs",
    logo: "Logo",
    name: "Nom",
    new: "Nouveau",
    noResultFound: "Aucun résultat trouvé",
    overview: "Résumé",
    parameterSettings: "Paramètres des paramètres",
    pleaseTryAgainWithADifferentQuery: "Veuillez réessayer avec une requête différente",
    predefinedTaxesFees: "Taxes et frais prédéfinis",
    private: "Privé",
    removeLogo: "Supprimer le logo",
    selectModel: "Sélectionner un modèle",
    selectAndSaveDesiredLayoutToDownload: "Sélectionnez et enregistrez la mise en page souhaitée pour télécharger le devis, la commande et la facture",
    selectAndSaveOneToFourDesiredColors: "Sélectionnez et enregistrez de une à quatre couleurs souhaitées pour la mise en page des devis, commandes et factures",
    sendInvitation: "Envoyer une invitation",
    settings: "Paramètres",
    taxValue: "Valeur fiscale",
    templateColors: "Couleurs du modèle",
    templateModel: "Modèle de modèle",
    theEnteredInformationHasAlreadyValidated: "Les informations saisies ont déjà été validées, vous pouvez maintenant confirmer et créer une nouvelle entreprise",
    theNonRequiredFields: "Les champs non obligatoires remplis avec des données erronées seront supprimés et non enregistrés",
    unitsOfMeasure: "Unités de mesure pour une facture",
    uuidCode: "Code UUID",
    uuidCompany: "UUID de l'entreprise",
    uuidOrName: "UUID/Nom",
    usersWhoAreSubscribed: "Utilisateurs abonnés ayant accès à l'entreprise",
    viewCompany: "Voir l'entreprise",
    viewMoreUsers: "Voir plus d'utilisateurs",
    treasury: "Trésorerie",
    treasuryAccount: "Compte de trésorerie",
    treasuryAccounts: "Comptes de trésorerie",
    codeOfBelongingToABankingOrganization: "Code d'appartenance à une organisation bancaire",
    createNewTreasuryAccount: "Créer un nouveau compte de trésorerie",
    createTreasuryAccount: "Créer un compte de trésorerie",
    listTreasuryAccounts: "Liste des comptes de trésorerie",
    membership: "Adhésion",
    nameOfAccount: "Nom du compte",
    contactUs: "Contactez-nous",
    typeOfTreasuryAccount: "Type de compte de trésorerie",
    updateTreasuryAccountData: "Mettre à jour les données du compte de trésorerie",
    viewTreasuryAccountsList: "Voir la liste des comptes de trésorerie",
    notification: "Notification",
    notifications: "Notifications",
    accept: "Accepter",
    accessToCompany: "Accès à l'entreprise",
    activities: "Activités",
    answers: "Réponses",
    close: "Fermer",
    companyInvitations: "Invitations d'entreprise",
    hasReceivedANotification: "Vous avez reçu une notification",
    hasReceivedAQuote: "Vous avez reçu un devis",
    hasReceivedAnInvitation: "Vous avez reçu une invitation",
    hasReceivedAnOrder: "Vous avez reçu une commande",
    newNotification: "Nouvelle notification",
    noNotifications: "Aucune notification",
    other: "Autre",
    quoteEvaluation: "Évaluation du devis",
    quoteNotification: "Notification du devis",
    reject: "Rejeter",
    contact: "Contact",
    contacts: "Contacts",
    basicBankAccountNumber: "Numéro de compte bancaire de base",
    bankIdentifierCode: "Code d'identification bancaire / Société pour les télécommunications financières interbancaires mondiales",
    contactsList: "Liste des contacts",
    contactUUID: "UUID du contact",
    contactIdentity: "Identité du contact",
    contactIsNotAnAppUser: "Le contact n'est pas un utilisateur de l'application",
    contactType: "Type de contact",
    contactUuidAndType: "UUID et type de contact",
    countryContact: "Pays du contact",
    createContact: "Créer un contact",
    createNewContact: "Créer un nouveau contact",
    createContactCompanyStructure: "Créer une structure de contact de type entreprise",
    createContactPersonStructure: "Créer une structure de contact de type personne",
    deleteContact: "Supprimer le contact",
    deleteSelected: "Supprimer les éléments sélectionnés",
    definingTheIdentityOfContact: "Définition de l'identité du contact",
    definingTheInformationOfContact: "Définition des informations du contact",
    definingTheLegalInformationOfContact: "Définition des informations légales du contact",
    definingTheSettingsToDownloadQuotesAndInvoices: "Définition des paramètres pour télécharger les devis et factures",
    dragOrClickOnTheBoxToAddTheCompanyLogo: "Glissez ou cliquez sur la boîte pour ajouter le logo de l'entreprise",
    editContact: "Modifier le contact",
    enterThePostalCodeOfTheCity: "Saisissez le code postal de la ville",
    exportContacts: "Exporter les contacts",
    exportEntryContacts: "Exporter les écritures comptables",
    extendChanges: "Étendre les modifications",
    fileDownloadSettings: "Paramètres de téléchargement de fichiers",
    financialEntity: "Entité financière",
    identityOfContact: "Identité du contact",
    ifAddressDoesNotHaveANumber: "Si l'adresse n'a pas de numéro, entrez 'nn' ou 'NN'",
    ifNecessaryEnterSomethingReferentialRegardingAddress: "Si nécessaire, entrez une indication référentielle concernant l'adresse",
    informationOfContact: "Informations du contact",
    internationalBankAccountNumber: "Numéro de compte bancaire international",
    languageToDownload: "Langue de téléchargement du devis, de la commande ou de la facture",
    legalInformationOfContact: "Informations légales du contact",
    qrType: "Type de QR",
    shapesOrVersionsOfQR: "Formes ou versions d'une QR-Facture",
    selectCompanyAndSave: "Sélectionner une entreprise et enregistrer",
    selectCompaniesToCreateContact: "Sélectionnez les entreprises pour ajouter un nouveau contact",
    typeOfContact: "Type de contact",
    updateContactData: "Mettre à jour les données du contact",
    uuidAndType: "UUID et type",
    userUuid: "UUID de l'utilisateur",
    viewContact: "Voir le contact",
    viewContactsList: "Voir la liste des contacts",
    quote: "Devis",
    quotes: "Devis",
    addQuote: "Ajouter un devis",
    approveQuote: "Approuver le devis",
    companyQuoteList: "Liste des devis de l'entreprise",
    createQuote: "Créer un devis",
    newQuote: "Nouveau devis",
    dateOfQuote: "Date du devis, modifiable ultérieurement",
    editQuote: "Modifier le devis",
    enterADateOrSelectADefaultPaymentDeadline: "Entrez une date ou sélectionnez une échéance de paiement par défaut",
    expired: "Expiré",
    exportQuotes: "Exporter les devis",
    generateQuote: "Générer un devis",
    inTime: "À temps",
    listQuotes: "Liste des devis",
    quoteDate: "Date du devis",
    quoteGroups: "Groupes de devis",
    quoteNumber: "Numéro de devis",
    quoteReference: "Référence du devis",
    quoteStatus: "Statut du devis",
    quoteValidity: "Validité du devis",
    rejectQuote: "Rejeter le devis",
    sendQuote: "Envoyer le devis",
    updateQuote: "Mettre à jour le devis",
    viewQuote: "Voir le devis",
    purchases: "Achats",
    sales: "Ventes",
    viewInvoices: "Voir factures",
    viewOrders: "Voir commandes",
    viewQuotes: "Voir devis",
    viewProviderOrders: "Voir commandes fournisseurs",
    viewProviderInvoices: "Voir factures fournisseurs",
    viewExpenses: "Voir dépenses",
    expenses: "Dépenses",
    addExpense: "Ajouter dépense",
    createExpense: "Créer dépense",
    editExpense: "Modifier dépense",
    expenseDate: "Date de dépense",
    expenseGroups: "Groupes de dépenses",
    expenseNumber: "Numéro de dépense",
    expenseValidity: "Validité de la dépense",
    invalidateExpense: "Invalider dépense",
    listExpenses: "Liste des dépenses",
    removeExpense: "Supprimer dépense",
    updateExpense: "Mettre à jour dépense",
    validateExpense: "Valider dépense",
    viewExpense: "Voir dépense",
    addProviderInvoice: "Ajouter facture fournisseur",
    createProviderInvoice: "Créer facture fournisseur",
    editProviderInvoice: "Modifier facture fournisseur",
    invalidateProviderInvoice: "Invalider facture fournisseur",
    listProviderInvoices: "Liste des factures fournisseurs",
    myBankAccount: "Mon compte de trésorerie",
    provider: "Fournisseur",
    providerBankAccount: "Compte de trésorerie du fournisseur",
    providerInvoiceDate: "Date de la facture fournisseur",
    providerInvoiceGroups: "Groupes de factures fournisseurs",
    providerInvoiceStatus: "Statut de la facture fournisseur",
    providerInvoiceValidity: "Validité de la facture fournisseur",
    providerInvoices: "Factures fournisseurs",
    providerOrderNumber: "Numéro de commande fournisseur",
    updateProviderInvoice: "Mettre à jour facture fournisseur",
    validateProviderInvoice: "Valider facture fournisseur",
    viewProviderInvoice: "Voir facture fournisseur",
    addProviderOrder: "Ajouter commande fournisseur",
    approveProviderOrder: "Approuver commande fournisseur",
    cancelProviderOrder: "Annuler commande fournisseur",
    createProviderOrder: "Créer commande fournisseur",
    editProviderOrder: "Modifier commande fournisseur",
    generateProviderInvoice: "Générer facture fournisseur",
    generateProviderOrder: "Générer commande fournisseur",
    listProviderOrders: "Liste des commandes fournisseurs",
    providerOrderDate: "Date de commande fournisseur",
    providerOrders: "Commandes fournisseurs",
    providerOrderGroups: "Groupes de commandes fournisseurs",
    providerOrderReference: "Référence de la commande fournisseur",
    providerInvoiceReference: "Référence de la facture fournisseur",
    expenseReference: "Référence de la dépense",
    providerOrderStatus: "Statut de la commande fournisseur",
    updateProviderOrder: "Mettre à jour commande fournisseur",
    viewProviderOrder: "Voir commande fournisseur",
    addVouchers: "Ajouter justificatif(s)",
    download: "Télécharger",
    file: "Fichier",
    lastModified: "Dernière modification",
    size: "Taille",
    vouchers: "Justificatifs",
    addFiles: "Ajouter fichiers",
    attachFiles: "Joindre fichiers",
    removeAll: "Tout supprimer",
    uploadFiles: "Téléverser fichiers",
    orders: "Commandes",
    order: "Commande",
    orderEvaluation: "Évaluation de la commande",
    orderGroups: "Groupes de commandes",
    orderNotification: "Notification de commande",
    orderReference: "Référence de la commande",
    orderStatus: "Statut de la commande",
    addOrder: "Ajouter commande",
    approveOrder: "Approuver commande",
    cancelOrder: "Annuler commande",
    companyListOfOrders: "Liste des commandes de l'entreprise",
    createOrder: "Créer commande",
    delivery: "Livraison",
    deliveyAddress: "Adresse de livraison",
    deliveryNote: "Bon de livraison",
    editOrder: "Modifier commande",
    exportOrder: "Exporter commande",
    exportOrders: "Exporter commandes",
    generateInvoice: "Générer facture",
    generateOrder: "Générer commande",
    listOrders: "Liste des commandes",
    orderDate: "Date de la commande",
    orderFile: "Fichier de commande",
    orderNumber: "Numéro de commande",
    selectExportType: "Sélectionner type d'exportation",
    sendOrder: "Envoyer commande",
    thisOptionIsUniqueAndNewInvoice: "Cette option est unique et il n'est pas possible de créer une nouvelle facture à partir de cette commande",
    updateOrder: "Mettre à jour commande",
    viewOrder: "Voir commande",
    invoice: "Facture",
    invoices: "Factures",
    addInvoice: "Ajouter facture",
    amountDebt: "Montant dette",
    amountPaid: "Montant payé",
    companyInvoiceList: "Liste des factures de l'entreprise",
    createInvoice: "Créer facture",
    editInvoice: "Modifier facture",
    exportInvoices: "Exporter factures",
    debt: "Dette",
    fullyPaid: "Totalement payé",
    invalidInvoices: "Factures non valides",
    invalidateInvoice: "Invalider facture",
    invoiceGroups: "Groupes de factures",
    invoiceDate: "Date de facture",
    invoiceNumber: "Numéro de facture",
    invoiceStatus: "Statut de la facture",
    invoiceValidity: "Validité de la facture",
    listInvoices: "Liste des factures",
    notPayed: "Non payé",
    partiallyPaid: "Partiellement payé",
    payed: "Payé",
    thisInvoiceHasNoPayments: "Cette facture n'a pas de paiements",
    updateInvoice: "Mettre à jour facture",
    validated: "Validée",
    validateInvoice: "Valider facture",
    viewInvoice: "Voir facture",
    youWillBeAbleToUndoThisAction: "Vous pourrez annuler cette action",
    payment: "Paiement",
    payments: "Paiements",
    amountToPay: "Montant à payer",
    createNewPayment: "Créer un nouveau paiement",
    deleteSomePayment: "Supprimer un paiement",
    doNotDeletePayments: "Ne pas supprimer les paiements",
    makePayment: "Effectuer un paiement",
    method: "Méthode",
    paymentDate: "Date de paiement",
    paymentMethod: "Méthode de paiement",
    save: "Enregistrer",
    sourceOfIncome: "Source de revenus",
    sourceTheAmount: "Source où le montant d'argent pour la transaction est saisi",
    cash: "Espèces",
    card: "Carte",
    payPal: "PayPal",
    transfer: "Virement",
    mobile: "Mobile",
    thisOptionIncludeTaxPrice: "Cette option inclut la taxe dans le prix indiqué",
    thisOptionNotIncludeTaxPrice: "Cette option n'inclut pas la taxe dans le prix indiqué",
    addOrRemoveContactCompanies: "Ajouter ou supprimer des contacts d'entreprises",
    notifyMe: "Me notifier",
    background: "Arrière-plan",
    inventory: "Inventaire",
    viewProducts: "Voir produits",
    viewProduct: "Voir produit",
    viewServices: "Voir services",
    viewCategories: "Voir catégories",
    viewStorageCenter: "Voir centre de stockage",
    appLanguage: "Langue de l'application",
    theUuidCanBeUsedToCreateNnewContact: "L'UUID peut être utilisé pour créer un nouveau contact",
    settingInformation: "Informations de configuration",
    addOrRemoveCompanies: "Ajouter/Supprimer des entreprises",
    notGrouped: "Non groupé",
    notFiltered: "Non filtré",
    orderValidity: "Validité de la commande",
    expiration: "Expiration",
    quoteExpiration: "Expiration du devis",
    orderExpiration: "Expiration de la commande",
    invoiceExpiration: "Expiration de la facture",
    discountTotal: "Remise totale",
    addService: "Ajouter service",
    addProduct: "Ajouter produit",
    noRecords: "Aucun enregistrement",
    quantityMinimumForRebate: "Quantité minimale pour remise",
    thisOptionRebateIsFixedAmount: "Cette option indique que la remise est un montant fixe",
    thisOptionRebateIsPercentage: "Cette option indique que la remise est un pourcentage",
    thisOptionRebateIsNone: "Cette option indique qu'il n'y a pas de remise",
    totalAfterRebates: "Total après remises",
    calculator: "Calculatrice",
    selectTypeRebateAndEnterValueAndQuantity: "Sélectionnez le type de remise et entrez la valeur et la quantité",
    reference: "Référence",
    newOrder: "Nouvelle commande",
    invoiceReference: "Référence de la facture",
    newInvoice: "Nouvelle facture",
    providerOrderValidity: "Validité de la commande fournisseur",
    expenseStatus: "Statut des dépenses",
    newProviderOrder: "Nouvelle commande fournisseur",
    newProvederInvoice: "Nouvelle facture fournisseur",
    newExpense: "Nouvelle dépense",
    viewVoucher: "Voir justificatif",
    informationAdditional: "Informations supplémentaires",
    no: "Non",
    bank: "Banque",
    fullNameBankAccountHolder: "Nom complet du titulaire du compte bancaire",
    bankAccountHolder: "Titulaire du compte bancaire",
    isNecessaryToHaveReferenceToActivateOption: "Il est nécessaire d'avoir une référence pour activer cette option",
    isNecessaryToHaveAtLeastTwoTreasuryAccountsToActivateOption: "Il est nécessaire d'avoir au moins deux comptes de trésorerie pour activer cette option",
    none: "Aucun",
    undefined: "Indéfini",
    colorCombinationForDesignOfDocumentToDownload: "Combinaison de couleurs pour le design du document à télécharger",
    all: "Tout",
    lettersTitlesSubtitles: "Lettres, titres, sous-titres",
    firstBar: "Première barre",
    secondBar: "Deuxième barre",
    barLetters: "Lettres de la barre",
    qrIbanAndQrReference: "QR-IBAN et QR-Référence",
    ibanAndStructuredCreditorReference: "IBAN et Référence de Créancier Structurée (SCOR)",
    ibanWithoutReference: "IBAN sans référence",
    cashAccount: "Compte en espèces",
    postalAccount: "Compte postal",
    bankAccount: "Compte bancaire",
    productsList: "Liste des produits",
    category: "Catégorie",
    categories: "Catégories",
    listCategories: "Liste des catégories",
    tags: "Étiquettes",
    variants: "Variantes",
    photography: "Photographie",
    reminder: "Rappel",
    reminders: "Rappels",
    paymentStatus: "Statut de paiement",
    year: "Année",
    createReminder: "Créer un rappel",
    updateReminder: "Mettre à jour le rappel",
    makeCopy: "Créer une copie",
    reminderTotal: "Total du rappel",
    inDispute: "En litige",
    createReminderFee: "Créer des frais de rappel",
    addCategory: "Ajouter une catégorie",
    qtyAssets: "QTY actifs",
    qtyServices: "QTY services",
    qtyProducts: "QTY produits",
    viewCategory: "Voir la catégorie",
    editCategory: "Modifier la catégorie",
    deleteCategory: "Supprimer la catégorie",
    names: "Noms",
    listCategoryItems: "Liste des éléments de la catégorie",
    editListing: "Modifier la liste",
    updateCategoryItemList: "Mettre à jour la liste des éléments de la catégorie",
    updateCategory: "Mettre à jour la catégorie",
    nameInOtherLanguages: "Nom dans d'autres langues",
    enterInLanguageWant: "Entrez la langue souhaitée",
    enterNameOfRequestedLanguages: "Entrez le nom des langues demandées",
    enterOrModifyInLanguage: "Entrez ou modifiez dans la langue souhaitée",
    general: "Général",
    categoryName: "Nom de la catégorie",
    categoryType: "Type de catégorie",
    createCategory: "Créer une catégorie",
    editProduct: "Modifier le produit",
    deleteProduct: "Supprimer le produit",
    productReferencePictures: "Images de référence du produit",
    code: "Code",
    sku: "SKU",
    codebar: "Code-barres",
    color: "Couleur",
    unit: "Unité",
    price: "Prix",
    fixed: "Fixé",
    productName: "Nom du produit",
    productDetails: "Détails du produit",
    advanced: "Avancé",
    addVariant: "Ajouter une variante",
    uniqueProductCodeAssignedByCompany: "Code produit unique attribué par l'entreprise",
    additionalCommentDescriptionProductVariant: "Commentaire ou description supplémentaire de la variante du produit",
    selectDiscountTypeAppliedToProduct: "Sélectionnez un type de remise à appliquer à ce produit",
    discountType: "Type de remise",
    selectDiscountTypeThatWillAppliedProduct: "Sélectionnez un type de remise à appliquer à ce produit",
    noDiscount: "Pas de remise",
    setDiscountPercentage: "Définir le pourcentage de remise",
    fixedDiscountedAmount: "Montant fixe de la remise",
    quantityMinimumDiscount: "Quantité minimale pour remise",
    createProduct: "Créer un produit",
    dropFilesHereOrClickUpload: "Déposez les fichiers ici ou cliquez pour télécharger",
    dropFileHereOrClickUpload: "Déposez le fichier ici ou cliquez pour charger",
    uploadUpToFiles: "Téléchargez jusqu'à 10 fichiers",
    success: "Succès",
    addNewProductVariant: "Ajouter une nouvelle variante de produit",
    editVariant: "Modifier la variante",
    editProductVariant: "Modifier la variante du produit",
    updateProduct: "Mettre à jour le produit",
    saveVariant: "Enregistrer la variante",
    servicesList: "Liste des services",
    viewService: "Voir le service",
    editService: "Modifier le service",
    deleteService: "Supprimer le service",
    serviceReferencePictures: "Images de référence du service",
    serviceName: "Nom du service",
    serviceDetails: "Détails du service",
    createService: "Créer un service",
    updateService: "Mettre à jour le service",
    uniqueServiceCodeAssignedByCompany: "Code de service unique attribué par l'entreprise",
    selectDiscountTypeAppliedToService: "Sélectionnez un type de remise à appliquer à ce service",
    expenseAccount: "Compte de dépenses",
    vatAccount: "Compte de TVA",
    accountProduct: "Compte de produit",
    accountingAccount: "Compte comptable",
    accountingAccounts: "Comptes comptables",
    accountingAccountPurchases: "Compte comptable pour les achats",
    accountingAccountSales: "Compte comptable pour les ventes",
    addAccountingAccount: "Ajouter un compte comptable",
    rangeAllowedCodes: "Plage de codes autorisés",
    effective: "Effectif",
    ntdr: "Dette fiscale nette TDNF",
    taf: "Taux fixe",
    vatSpecialRegime: "Régime spécial de TVA",
    methodAapply: "Méthode à appliquer",
    theVatRegimeCanOnlyChangedOnceAYear: "Le régime de TVA ne peut être modifié qu'une fois par an, juste avant le début d'une nouvelle année comptable",
    debit: "Débit",
    credit: "Crédit",
    customerReceivable: "Créance client",
    vatDue: "TVA due",
    vatDebtMethod: "Méthode de la dette TVA",
    invoicing: "Facturation",
    cashing: "Encaissement",
    valid: "Valide",
    invalid: "Invalide",
    colorIsInvalid: "Couleur invalide",
    theValueIsNotAValid: "La valeur n'est pas valide",
    theValueIsNotAValidUUIDOrNameUser: "La valeur n'est pas un UUID ou un nom d'utilisateur valide",
    theValueIsAtLeast8Digits: "La valeur comporte au moins 8 chiffres",
    theValueDoesNotMatch: "La valeur ne correspond pas",
    theValueAlreadyExists: "La valeur existe déjà",
    limitFilesMaximum: "Limite de 10 fichiers maximum",
    required: "Requis",
    theValueIsRequired: "La valeur est requise",
    colorIsRequired: "La couleur est requise",
    requirementsIsRequired: "Les blocs sont requis",
    contentsToRequirementIsRequired: "Le contenu du bloc est requis",
    transaction: "Transaction",
    createNewPaymentForInvoice: "Créer un nouveau paiement pour la facture",
    addTransaction: "Ajouter une transaction",
    accountingEntry: "Écriture comptable",
    amountAvailable: "Montant disponible",
    selectExistingTransaction: "Sélectionner une transaction existante",
    totalAmountDebt: "Montant total de la dette",
    presetCurrency: "Devise prédéfinie",
    depositAmount: "Montant du dépôt",
    providerInvoice: "Facture du fournisseur",
    expense: "Dépense",
    viewAccountingEntry: "Afficher l'écriture comptable",
    enterNewTransaction: "Entrer une nouvelle transaction",
    useExistingTransaction: "Utilizes une transaction existante",
    transactionToRecordPayment: "Transaction pour pouvoir enregistrer le paiement",
    amountMoneyEnteredInTransaction: "Montant d'argent saisi dans la transaction",
    identifiedTransactions: "Transactions identifiées",
    unidentifiedTransactions: "Transactions non identifiées",
    totalDepositAmount: "Montant total du dépôt",
    makeCorrespondingPayment: "Effectuer le paiement correspondant",
    balance: "Solde",
    accountingEntries: "Écritures comptables",
    contactAccountStatements: "Relevés de compte par contact",
    contactBalance: "Solde de contact",
    accountStatement: "Relevé de compte",
    invoiceIssuance: "Émission de la facture",
    allocationInvoiceReminder: "Affectation pour rappel de facture",
    transactionPostFinance: "Transaction au Compte PostFinance",
    balancePrevious: "Solde précédent",
    balanceFavorContact: "SSolde en votre faveur",
    balanceFavorCompany: "Solde en notre faveur",
    addCredit: "Ajouter du crédit",
    createClientCredit: "Créer un avoir client",
    unidentifiedTransactionToRegisterInContact: "Transaction non identifiée à enregistrer en contact",
    draft: "Brouillon",
    beforeCreatingProviderInvoice: "Avant de créer une facture fournisseur",

    // Start sentence
    selectOneOption: "Sélectionnez une des options,",
    iAgree: "J'accepte &",
    youWantToChangeOrderStatusTo: "Souhaitez-vous changer le statut de la commande en",
    createdAt: "Créé le",
    updatedAt: "Mis à jour le",
    validUntil: "Valide jusqu'au",
    clickOn: "Cliquez sur",
    thisContactWillOnlyBeRegisteredInCompany: "Ce contact sera uniquement enregistré dans l'entreprise",
    securedPaymentBy: "Paiement sécurisé par",
    anInvitationWillBeSent: "Une invitation sera envoyée pour permettre à un utilisateur d'accéder à l'entreprise",
    ifYouNeedMoreInfo: "Si vous avez besoin de plus d'informations, consultez",
    toFinishCreationNewCompany: "Pour finaliser la création de la nouvelle entreprise, cliquez sur",
    theInvitationToAccess: "L'invitation pour accéder à l'entreprise de la part de l'utilisateur",
    theQuoteThatYouSent: "Le devis que vous avez envoyé à l'entreprise",
    youHaveAlreadyEvaluatedOrder: "Vous avez déjà évalué la commande de l'entreprise",
    youHaveAlreadyEvaluatedQuote: "Vous avez déjà évalué le devis de l'entreprise",
    youHaveAlreadyRespondedTo: "Vous avez déjà répondu à",
    youHaveReceivedAQuote: "Vous avez reçu un devis de l'entreprise",
    ifYouDoNotWishToExtendContactModificationsToOtherCompanies: "Si vous ne souhaitez pas étendre les modifications du contact à d'autres entreprises, ne cochez rien et sélectionnez",
    youWantToChangeQuoteStatusTo: "Souhaitez-vous changer le statut du devis en",
    youWantToChangeProviderOrderStatusTo: "Souhaitez-vous changer le statut de la commande fournisseur en",
    theOrderThatYouSent: "La commande que vous avez envoyée à l'entreprise",
    youHaveReceivedAnOrder: "Vous avez reçu une commande de l'entreprise",
    theCurrentCompanyIs: "L'entreprise actuelle est",
    toCreateA: "Pour créer un(e)",
    createdBy: "Créé par",
    groupBy: "Grouper par",
    checkOrUncheck: "Cochez ou décochez les",
    ifYouWishChangeVatRegimePleaseContact: "Si vous souhaitez modifier le régime de TVA, veuillez contacter",
    ifYouInvalidate: "Si vous invalidez la facture",
    the: "Le / La",
    planYourBusinessApplications: "Planifiez vos applications commerciales en choisissant l'une des",
    yourAccountIsNotVerified: "Votre compte n'est pas vérifié. Veuillez valider votre compte via votre adresse e-mail en cliquant sur",

    // End sentence
    companyPleaseEvaluateQuote: ", veuillez évaluer le devis.",
    theAssociatedAccountingEntriesWillBeDeleted: ", les écritures comptables associées seront supprimées. Cela signifie que vous ne pourrez pas effectuer de paiements ni générer des frais de rappels liés à cette facture.",
    ifYouWishToSaveTheChangesMade: "si vous souhaitez enregistrer les modifications effectuées.",
    youWantToRun: "que vous souhaitez exécuter.",
    toContinue: "pour continuer.",
    waitAMomentToVerifyYourAccess: "attendez un moment pour vérifier votre accès.",
    hasSentARequestForAccessToTheFollowingCompany: "a envoyé une demande d'accès à l'entreprise suivante, veuillez répondre.",
    ifYouWantToSaveTheNewQuote: "si vous souhaitez enregistrer le nouveau devis.",
    ifYouWantToSaveTheNewQuoteWithTheChangesMade: "si vous souhaitez enregistrer le nouveau devis avec les modifications effectuées.",
    ifYouWantToSaveTheNewInvoiceWithTheChangesMade: "si vous souhaitez enregistrer la nouvelle facture avec les modifications effectuées.",
    ifYouWantToSaveTheNewExpense: "si vous souhaitez enregistrer la nouvelle dépense.",
    ifYouWantToSaveTheNewProviderInvoice: "si vous souhaitez enregistrer la nouvelle facture fournisseur.",
    ifYouWantToSaveTheNewProviderOrder: "si vous souhaitez enregistrer la nouvelle commande fournisseur.",
    ifYouWantToSaveTheNewProviderOrderWithTheChangesMade: "si vous souhaitez enregistrer la nouvelle commande fournisseur avec les modifications effectuées.",
    ifYouWantToSaveTheNewProviderInvoiceWithTheChangesMade: "si vous souhaitez enregistrer la nouvelle facture fournisseur avec les modifications effectuées.",
    ifYouWantToSaveTheNewOrder: "si vous souhaitez enregistrer la nouvelle commande.",
    ifYouWantToSaveTheNewOrderWithTheChangesMade: "si vous souhaitez enregistrer la nouvelle commande avec les modifications effectuées.",
    companyPleaseEvaluateTheOrder: "entreprise, veuillez évaluer la commande.",
    ifYouWantToSaveTheNewInvoice: "si vous souhaitez enregistrer la nouvelle facture.",
    youWillNotBeAbleToRemoveItFromThisCompany: ", vous ne pourrez pas le supprimer de cette entreprise, mais vous pouvez sélectionner les autres entreprises.",
    inThisCategory: "dans cette catégorie.",
    theCompanyContainTreasutyAccount: "l'entreprise doit contenir au moins un compte de trésorerie.",
    toBeAddedInOrderToDownloadTheInvoice: "doit être ajoutée pour télécharger la facture.",

    // All sentence
    youAreWishingToEnterWithTheFollowingEmailAddress: "Vous souhaitez vous connecter avec l'adresse e-mail suivante :",
    ifYouChangeYourMind: "Si vous changez d'avis, demandez à recevoir une nouvelle demande.",
    messageWillBeSentYourEmailToRecoverAccount: "Un message sera envoyé à votre adresse e-mail pour récupérer le compte. Veuillez vérifier votre boîte de réception. Vous serez redirigé vers la page de connexion.",
    nowYouCanLogIn: "Vous pouvez maintenant vous connecter avec le nouveau compte créé. Vous serez redirigé vers la page de connexion.",
    newCompanyIsSelectedByDefault: "La nouvelle entreprise est sélectionnée par défaut et vous serez redirigé vers la page principale.",
    notificationWillBeDeleted: "La notification de l'invitation à accéder à l'entreprise sera supprimée.",
    theAppWillBeTranslated: "L'application sera traduite dans la langue indiquée, sauf pour les informations saisies dans les documents.",
    userWillRespondInvitation: "L'utilisateur répondra s'il accepte ou non l'invitation à accéder à l'entreprise.",
    youWillNotBeAbleToUndo: "Vous ne pourrez pas annuler cette action.",
    tokenValidForAUserWhoHasNotLoggedIn: "Jeton valide pour un utilisateur qui ne s'est pas connecté, redirection vers la page de connexion.",
    toValidateYourAccount: "Pour valider votre compte, un e-mail de validation vous sera envoyé, à partir duquel vous pourrez vérifier votre compte et vous reconnecter.",
    validatedAccountForLoggedInUser: "Compte validé pour l'utilisateur connecté, redirection vers la page principale.",
    validatedAccountForADifferentUser: "Compte validé pour un utilisateur différent de celui connecté, redirection vers la page de connexion.",
    validatedVerifiedForAUserWhoHasNotLoggedIn: "Validation vérifiée pour un utilisateur qui ne s'est pas connecté, redirection vers la page de connexion.",
    newServicesWithinTheSameApp: "Nouveaux services au sein de la même application.",
    pageInProduction: "Page en production, vous pourrez bientôt accéder à de nouvelles fonctionnalités dans l'application.",
    pleaseCorrectThePathOfThePageInOrderToAccess: "Corrigez le chemin de la page pour accéder à l'application.",
    pleaseTryAgainLater: "Veuillez réessayer plus tard.",
    thereAreNoCompaniesAddedYet: "Aucune entreprise n'a encore été ajoutée.",
    tokenValidForAnUserThatIsDifferentFromLoggedIn: "Jeton valide pour un utilisateur différent de celui connecté, redirection vers la page de connexion.",
    validTokenForTheUserThatLoggedIn: "Jeton valide pour l'utilisateur connecté, redirection vers la page du tableau de bord.",
    redirectingToLogin: "Redirection vers la page de connexion.",
    willNotBeAbleToReturnUntilYouLogin: "Vous ne pourrez pas revenir tant que vous ne vous serez pas reconnecté.",
    allTheFieldsAreValidated: "Tous les champs sont validés. Si une donnée incorrecte est saisie dans un formulaire obligatoire, vous ne pourrez pas passer à l'étape suivante. En revanche, si une donnée incorrecte est saisie dans un formulaire non obligatoire, elle ne sera pas enregistrée.",
    pleaseClickContinue: "Cliquez sur continuer pour saisir les détails de la nouvelle entreprise à créer.",
    searchUserForInvitate: "Recherchez l'utilisateur par son UUID ou par son nom et envoyez une notification pour accéder à l'entreprise.",
    theFileMustBeFormat: "Le fichier doit être au format jpeg, jpg ou png.",
    ifYouHaveAnyQuestionsContactUs: "Si vous avez des questions, vous pouvez nous contacter.",
    noNotificationsSeenYet: "Aucune notification vue pour le moment.",
    thereAreNoNotifications: "Aucune notification reçue pour l'instant.",
    byDefaultValuesOfTheCompanyAreTaken: "Par défaut, les valeurs de l'entreprise sont prises.",
    selectTheOptionsThatBestConsiderWhenDownloading: "Sélectionnez les options que vous jugez les plus appropriées lors du téléchargement d'un devis ou d'une facture au format PDF.",
    notificationsAboutInvitationsToSubscribe: "Notifications concernant les invitations à s'abonner et à accéder à une entreprise.",
    whenCreatingAContactByUUID: "Lors de la création d'un contact par UUID, vous ne pourrez pas modifier certains champs en fonction du type : personne (nom, prénom et e-mail) et entreprise (nom).",
    youCanDirectlyEnterUUIDOfAUserOrCompanyToAddAsContact: "Vous pouvez entrer directement l'UUID d'un utilisateur ou d'une entreprise pour l'ajouter comme contact ; sinon, choisissez le type de contact que vous souhaitez créer.",
    descriptionCreateQuote: "Veuillez saisir les informations suivantes, elles sont nécessaires pour créer un nouveau devis.",
    descriptionCreateOrder: "Veuillez saisir les informations suivantes, elles sont nécessaires pour créer une nouvelle commande.",
    descriptionCreateInvoice: "Veuillez saisir les informations suivantes, elles sont nécessaires pour créer une nouvelle facture.",
    sorryLooksLikeThereAreSomeErrorstryAgain: "Désolé, il semble qu'il y ait des erreurs, veuillez réessayer.",
    sorryLooksLikeThereAreSomeErrorsTrySolve: "Désolé, il semble qu'il y ait des erreurs, essayez de les résoudre.",
    sorryThereIsNothingToAdd: "Désolé, il n'y a rien à ajouter, veuillez réessayer.",
    thankYouVeryMuchForSubscribingRedirectingToPreviousPage: "Merci beaucoup pour votre abonnement, redirection vers la page précédente.",
    actionCompletedReturningToPage: "Action terminée, retour à la page.",
    actionCompletedRedirectingToPage: "Action terminée, redirection vers la page.",
    pleaseModifyAddOrDeleteFieldsFromQuote: "Veuillez modifier, ajouter et/ou supprimer les champs souhaités du devis.",
    thisOptionIsUniqueAndNewOrderCannotBeCreatedFromThisQuote: "Cette option est unique et une nouvelle commande ne peut pas être créée à partir de ce devis.",
    pleaseEnterTheFollowingDataToCreateANewProviderInvoice: "Veuillez saisir les informations suivantes, elles sont nécessaires pour créer une nouvelle facture fournisseur.",
    pleaseModifyAddOrDeleteFieldsFromProviderInvoice: "Veuillez modifier, ajouter et/ou supprimer les champs souhaités de la facture fournisseur.",
    pleaseModifyAddOrDeleteFieldsFromExpense: "Veuillez modifier, ajouter et/ou supprimer les champs souhaités de la dépense.",
    pleaseEnterTheFollowingDataToCreateANewExpense: "Veuillez saisir les informations suivantes, elles sont nécessaires pour créer une nouvelle dépense.",
    pleaseEnterThFollowingDataToCreateANewProviderOrder: "Veuillez saisir les informations suivantes, elles sont nécessaires pour créer une nouvelle commande fournisseur.",
    pleaseModifyAddAndOrDeleteFieldsFromProviderOrder: "Veuillez modifier, ajouter et/ou supprimer les champs souhaités de la commande fournisseur.",
    fileInDifferentFormat: "Fichier dans un format différent. Format de fichier : '.pdf'.",
    fileIsTooBig: "Le fichier est trop volumineux. Taille maximale : 1 Mo.",
    fileFormatMaximum1MBPerFile: "Le format de fichier est '.pdf' et la taille maximale est de 1 Mo.",
    someOfTheUploadedFilesDoNotMeetTheRequiredCharacteristics: "Certains des fichiers téléchargés ne respectent pas les caractéristiques requises.",
    pleaseModifyAddOrDeleteFieldsFromOrder: "Veuillez modifier, ajouter et/ou supprimer les champs souhaités de la commande.",
    pleaseModifyAddOrDeleteFieldsFromInvoice: "Veuillez modifier, ajouter et/ou supprimer les champs souhaités de la facture.",
    thisAmountCanNotBeGreater: "Ce montant ne peut pas être supérieur à la dette totale de la facture.",
    toCreateAnDocument: "Pour créer un document, l'entreprise doit contenir au moins un compte de trésorerie.",
    newContactWwillAddedToCompanyAndSelectDefault: "Un nouveau contact sera ajouté à l'entreprise et sélectionné par défaut, retour à la page.",
    youHaveSuccessfullyResetYourPassword: "Vous avez réinitialisé votre mot de passe avec succès, vous pouvez maintenant vous reconnecter. Redirection vers la page de connexion.",
    kickstartYourBusinessByAddingAYourFirstCompany: "Lancez votre entreprise en ajoutant votre première société.",
    ifNoAmountIsEnteredTheValueWillBeZero: "Si aucun montant n'est saisi, la valeur sera de 0.",
    ifYouWishYouCanAssignNameOfCategoryInLanguagesNecessary: "Si vous le souhaitez, vous pouvez attribuer le nom de la catégorie dans les langues que vous jugez nécessaires.",
    productNameRequiredAndRecommendedUnique: "Un nom de produit est requis et il est recommandé qu'il soit unique.",
    addProductToCategory: "Ajouter un produit à une catégorie.",
    addTagsToProduct: "Ajouter des étiquettes au produit, elles peuvent être facultatives.",
    setProductMediaGallery: "Configurer la galerie multimédia du produit.",
    serviceNameRequiredAndRecommendedUnique: "Un nom de service est requis et il est recommandé qu'il soit unique.",
    addServiceToCategory: "Ajouter un service à une catégorie.",
    addTagsToService: "Ajouter des étiquettes au service, elles peuvent être facultatives.",
    setServiceMediaGallery: "Configurer la galerie multimédia du service.",
    aCategoryContainServicesProducts: "Une catégorie peut contenir à la fois des services et des produits.",
    ifYouBelongToRegimeMoreInformationIsRequired: "Si vous appartenez à un régime spécifique, des informations supplémentaires seront requises.",
    toPerformAnySubsequentActionWithInvoice: "Pour effectuer toute action ultérieure avec la facture, il sera nécessaire de la valider à nouveau en spécifiant les comptes comptables correspondants à chaque produit et/ou service associé.",
    validatingInvoiceSystemWillCreateToFollowingAccountingEntries: "Lors de la validation de la facture, le système créera les écritures comptables suivantes. Veuillez confirmer les comptes comptables de chaque service ou produit de la facture.",
    allowedFileTypes: "Types de fichiers autorisés : png, jpg, jpeg.",
    whenYouUploadFileItWillPlacedReferenceProviderInvoiceCreationForm: "Lorsque vous téléchargez un fichier PDF, il sera placé comme référence dans le formulaire de création de facture du fournisseur.",

    // Lowercase sentences
    yearl: "an",
    years: "ans",
    hour: "heure",
    hours: "heures",
    minute: "minute",
    minutes: "minutes",
    information: "information",
    items: "éléments",
    groups: "groupes",
    selected: "sélectionné",
    accountNeedsA: "le compte a besoin d'un",
    options: "options",
    with: "avec",
    bestCustomers: "meilleurs clients",
    byRecentUpdates: "par mises à jour récentes",
    byTimeRange: "par plage de temps",
    template: "modèle",
    evaluated: "évalué",
    noMailFound: "aucun e-mail trouvé",
    report: "rapport",
    reports: "rapports",
    requestOnAccess: "demande d'accès à l'entreprise, votre réponse a été",
    userHasBeen: "l'utilisateur a été",
    yourAnswerHasBeen: ", votre réponse a été",
    toUser: "à l'utilisateur",
    hasBeen: "a été",
    byYear: "par année",
    or: "ou",
    quotesToExpireThisMonth: "les devis expireront ce mois-ci",
    worstCustomers: "pires clients",
    button: "bouton",
    termsAndConditions: "termes et conditions",
    and: "et",
    ourServicesOrRequesting: "nos services ou en demandant un nouveau",
    toAddOrRemoveCategory: "que vous souhaitez ajouter ou supprimer de la catégorie",
    orCall: "ou appelez le numéro",
    invoicesNotYetPaid: "factures non encore payées",

    // Interrogative sentences
    isDeliveryAddressTheSameAsCustomersAddress: "L'adresse de livraison est-elle la même que l'adresse du client ?",
    youWantLogOut: "Voulez-vous vous déconnecter ?",
    doesItTakeALongTimeToLoadTheApp: "L'application met-elle beaucoup de temps à charger ?",
    alreadyHaveAnCccount: "Vous avez déjà un compte ?",
    forgotPassword: "Mot de passe oublié ?",
    newHere: "Nouveau ici ?",
    canNotFindTheUserYouWant: "Vous ne trouvez pas l'utilisateur que vous recherchez ?",
    selectThisTreasuryAccountAsTheMainOne: "Sélectionner ce compte de trésorerie comme principal ?",
    youWantToDeleteThisTreasuryAccount: "Voulez-vous supprimer ce compte de trésorerie ?",
    youWantToSelectThisTreasuryAccountAsPrimary: "Voulez-vous sélectionner ce compte de trésorerie comme principal ?",
    wantToExtendChangesToOtherCompanies: "Voulez-vous étendre les modifications à d'autres entreprises ?",
    youWantToDeleteThisContact: "Voulez-vous supprimer ce contact ?",
    youWantToDeleteSelectedContacts: "Voulez-vous supprimer les contacts sélectionnés ?",
    youWantSendQuote: "Voulez-vous envoyer le devis au client ?",
    youWantToChangeQuoteStatusRejected: "Voulez-vous changer le statut du devis à refusé ?",
    youWantToGenerateAnOrderFromThisQuote: "Voulez-vous générer une commande à partir de ce devis ?",
    youWantToChangeStatusTheExpense: "Voulez-vous changer le statut de la dépense ?",
    youWantToDeleteThisExpense: "Voulez-vous supprimer cette dépense ?",
    youWantToChangeStatusTheProviderInvoice: "Voulez-vous changer le statut de la facture du fournisseur ?",
    youWantToGenerateAProviderInvoiceFromThisProviderOrder: "Voulez-vous générer une facture fournisseur à partir de cette commande fournisseur ?",
    youWantToChangeOrderStatusToCanceled: "Voulez-vous changer le statut de la commande à annulé ?",
    youWantToGenerateAnInvoiceFromThisOrder: "Voulez-vous générer une facture à partir de cette commande ?",
    youWantToSendTheOrderToCustomer: "Voulez-vous envoyer la commande au client ?",
    youWantToDeleteThisPayment: "Voulez-vous supprimer ce paiement ?",
    youWantToChangeStatusTheInvoice: "Voulez-vous changer le statut de la facture ?",
    isCompanyOwnerOfBankAccount: "L'entreprise est-elle titulaire du compte bancaire ?",
    useReferenceWithinQR: "Utiliser une référence dans le QR ?",
    youWantDeleteProduct: "Voulez-vous supprimer ce produit ?",
    youWantDeleteSelectedProducts: "Voulez-vous supprimer les produits sélectionnés ?",
    youWantToDeleteThisReminder: "Voulez-vous supprimer ce rappel ?",
    youWantToDeleteThisCategory: "Voulez-vous supprimer cette catégorie ?",
    youWantToDeleteSelectedCategories: "Voulez-vous supprimer les catégories sélectionnées ?",
    youWantDeleteService: "Voulez-vous supprimer ce service ?",
    youWantDeleteSelectedServices: "Voulez-vous supprimer les services sélectionnés ?",
    isCompanySubjectToVatRegime: "L'entreprise est-elle soumise à un régime de TVA ?",
    doYouWantUploadPdfFileToFacilitateRregistrationInvoice: "Vous souhaitez télécharger un fichier PDF pour faciliter l'enregistrement d'une nouvelle facture ?",

    // Exclamative sentences
    important: "Important !",
    yourSessionHasBeenClosed: "Votre session a été fermée, veuillez vous reconnecter !",
    yourSessionHasExpired: "Votre session a expiré, veuillez vous reconnecter !",
    accountNotVerifiedYet: "Compte non encore vérifié !",
    moneyBackGuarantee: "Garantie de remboursement à 100 % !",
    somethingWentWrong: "Une erreur est survenue !",
    thePageYouLookedNotFound: "La page que vous recherchez est introuvable !",
    yourSessionIsActiveCongratulations: "Votre session est active, félicitations !",
    weNeedYourAttention: "Votre attention est requise !",
    yourAreDone: "C'est terminé !",
    startCreatingNewTreasuryAccountsForYourCompany: "Commencez à créer de nouveaux comptes de trésorerie pour votre entreprise, c'est très important pour le fonctionnement de l'application !",
    getNotifiedWhenWeLaunch: "Recevez une notification lors de notre lancement !",
    processOfCreatingANewInvoiceCompleted: "Processus de création d'une nouvelle facture terminé !",
    processOfUpdatingAInvoiceCompleted: "Processus de mise à jour d'une facture terminé !",
    thereAreNoTreasuryAccounts: "Aucun compte bancaire disponible !",
    incorrectFiles: "Fichiers incorrects !",
    invalidFileType: "Type de fichier non valide ! Types de fichiers autorisés : jpeg, jpg ou png.",
    fileSizeNotAllowed: "Taille de fichier non autorisée ! Taille maximale : 1 Mo.",
    startAddingNewElementsToThisCategory: "Commencez à ajouter de nouveaux éléments à cette catégorie !",
    accountingAccountAlreadySelected: "Compte comptable déjà sélectionné, veuillez en choisir un autre !",
    servicesProductsWithTaxGreaterThanZero: "Services ou produits avec une taxe supérieure à zéro !",
    youHaveSuccessfullySignedOut: "Vous vous êtes déconnecté avec succès !",
    selectContactViewTheirAccountStatement: "Sélectionnez un contact pour consulter son relevé de compte !",
    processOfCreatingANewOrderCompleted: "Processus de création d'une nouvelle commande terminé !",
    processOfUpdatingAnOrderCompleted: "Processus de mise à jour d'une commande terminé.",
    processOfCreatingQuoteCompleted: "Processus de création d'un nouveau devis terminé !",
    processOfCreatingAQuoteFromAnotherQuoteCompleted: "Processus de création d'un devis à partir d'un autre devis terminé !",
    processOfCreatingAOrderFromAnotherOrderCompleted: "Processus de création d'une commande à partir d'une autre commande terminé !",
    processOfCreatingAInvoiceFromAnotherInvoiceCompleted: "Processus de création d'une facture à partir d'une autre facture terminé !",
    processOfUpdatingAQuoteCompleted: "Processus de mise à jour d'un devis terminé !",
    processOfCreatingANewExpenseCompleted: "Processus de création d'une nouvelle dépense terminé !",
    processOfUpdatingAnExpenseCompleted: "Processus de mise à jour d'une dépense terminé !",
    processOfCreatingANewProviderInvoiceCompleted: "Processus de création d'une nouvelle facture fournisseur terminé !",
    processOfUpdatingAProviderInvoiceCompleted: "Processus de mise à jour d'une facture fournisseur terminé !",
    processOfCreatedAProviderOrderCompleted: "Processus de création d'une commande fournisseur terminé !",
    processOfCreatedAProviderInvoiceCompleted: "Processus de création d'une facture fournisseur terminé !",
    processOfCreatingANewProviderOrderCompleted: "Processus de création d'une nouvelle commande fournisseur terminé !",
    processOfUpdatingAProviderOrderCompleted: "Processus de mise à jour d'une commande fournisseur terminé !",
    successfullyCreatedAccountingAccount: "Compte comptable créé avec succès !",
    successfullyCreatedCategory: "Catégorie créée avec succès !",
    successfullyUpdatedCategory: "Catégorie mise à jour avec succès !",
    successfullyInvitationSent: "Invitation envoyée avec succès !",
    successfullyInvitationCancel: "Invitation annulée avec succès !",
    successfullyCreatedContact: "Contact créé avec succès !",
    successfullyUpdatedContact: "Contact mis à jour avec succès !",
    successfullyCreatedReminder: "Rappel créé avec succès !",
    successfullyUpdatedStatus: "Statut mis à jour avec succès !",
    successfullyUpdatedReminder: "Rappel mis à jour avec succès !",
    successfullyCreatedPayment: "Paiement créé avec succès !",
    successfullyCreatedCompanyParameters: "Paramètres de l'entreprise créés avec succès !",
    successfullyUpdatedTreasuryAccount: "Compte de trésorerie mis à jour avec succès !",
    successfullyAcceptedInvitation: "Invitation acceptée avec succès !",
    successfullyLoggedIn: "Connexion réussie !",
    successfullyUpdatedCompanySettings: "Paramètres de l'entreprise mis à jour avec succès !",
    successfullyCreatedCompany: "Entreprise créée avec succès !",
    successfullyDeletedPayment: "Paiement supprimé avec succès !",
    successfullyCreatedInvoice: "Facture créée avec succès !",
    successfullyDeletedReminder: "Rappel supprimé avec succès !",
    successfullyOrderSent: "Commande envoyée avec succès !",
    successfullyQuoteSent: "Devis envoyé avec succès !",
    successfullyCreatedOrder: "Commande créée avec succès !",
    successfullyCreatedTreasuryAccount: "Compte de trésorerie créé avec succès !",
    successfullyUpdatedDefaultTreasuryAccount: "Compte de trésorerie par défaut mis à jour avec succès !",
    successfullyDeletedTreasuryAccount: "Compte de trésorerie supprimé avec succès !",
    successfullyCreatedAccountingEntry: "Écriture comptable créée avec succès !",
    successfullyUpdatedAccountingEntry: "Écriture comptable mise à jour avec succès !",

    // Answer sentences
    okGotIt: "Ok, compris",
    yesLogOut: "Oui, se déconnecter",
    noCancel: "Non, annuler",
    noContinue: "Non, continue",
    yesDelete: "Oui, supprimer",
    yesResolveError: "Oui, résoudre l'erreur",
    yesSelect: "Oui, sélectionner",
    yesCanceled: "Oui, annuler",
    okClose: "Ok, fermer",
    yesReject: "Oui, rejeter",
    yesGenerate: "Oui, générer",
    yesSend: "Oui, envoyer",
    yesChangeStatus: "Oui, changer le statut",
    yesCreateTreasuryAccount: "Oui, créer un compte de trésorerie",
    yesValidate: "Oui, valider",
    yesInvalidate: "Oui, invalider",
    yesUpload: "Oui, charge",

    // Suspensive sentences
    pleaseWait: "Veuillez patienter...",
    selectOption: "Sélectionner une option...",
    searchContact: "Rechercher un contact et sélectionner...",
    searchCategory: "Rechercher une catégorie et sélectionner...",
    searchProduct: "Rechercher un produit et sélectionner...",
    searchService: "Rechercher un service et sélectionner...",
    search: "Rechercher...",
    writeCommentToInvite: "Écrivez un commentaire pour l'invitation...",
    loading: "Chargement...",

    // Abbreviated sentences
    n: "N°",
    mb: "MB",
    hrs: "hrs",
    min: "min",
    sec: "sec",

    // Enumeration
    first: "premier",
    second: "deuxième",
    third: "troisième",
    fourth: "quatrième",
    fifth: "cinquième",
    sixth: "sixième",

    // Languages
    english: "Anglais",
    french: "Français",
    italian: "Italien",
    german: "Allemand",
    spanish: "Espagnol",

    // Months
    january: "Janvier",
    february: "Février",
    march: "Mars",
    april: "Avril",
    may: "Mai",
    june: "Juin",
    july: "Juillet",
    august: "Août",
    september: "Septembre",
    october: "Octobre",
    november: "Novembre",
    december: "Décembre",
  }
}