import React, { Dispatch, SetStateAction } from 'react';
import { Form } from 'react-bootstrap';

import { pageCurrent, pageLimitDisabled, pageLimitEnabled } from '../../tools/pagination.tool';

export interface AppPaginationProps {
  property: {total_items: number, show_options: boolean},
  pagination: {current: number, total: number, pages: number[], limits: {min: number, max: number}},
  setPagination: Dispatch<SetStateAction<{current: number, total: number, pages: number[], limits: {min: number, max: number}}>>
};

const AppPagination: React.FunctionComponent<AppPaginationProps> = ({property, pagination, setPagination}) => {
  const handleChangePaginationTotal = (event: React.ChangeEvent <HTMLFormElement | HTMLSelectElement>) => {
    let totalPages: number = 1
    let totalByPage: number = event.target.value
    let pagesOfPagination: number[] = []

    totalPages = (property.total_items % totalByPage === 0) ? Math.floor(property.total_items / totalByPage) : Math.floor(property.total_items / totalByPage) + 1

    for (let i = 1; i <= totalPages; i++) {
      pagesOfPagination.push(i)
    }

    setPagination({...pagination, current: 1, total: totalByPage, pages: pagesOfPagination, limits: {min: 0, max: totalByPage}})
  }

  const executePaginationSelect = (item: number) => {
    let minLimitNew: number = pagination.total * (item - 1)
    let maxLimitNew: number = pagination.total * item
    let elements: string[] = []
  
    if (item === 1) {
      pageLimitDisabled('pagination-button-previous')
    } else {
      pageLimitEnabled('pagination-button-previous')
    }

    if (item === pagination.pages.length) {
      pageLimitDisabled('pagination-button-next')
    }else {
      pageLimitEnabled('pagination-button-next')
    }

    for (let item of pagination.pages) {
      elements.push('pagination-button-' + item)
    }

    pageCurrent(item, elements)
    setPagination({...pagination, current: item, limits: {min: minLimitNew, max: maxLimitNew}})
  }

  const executePaginationPrevious = () => {
    let currentNew: number = pagination.current - 1
    let minLimitNew: number = pagination.total * (currentNew - 1)
    let maxLimitNew: number = pagination.total * currentNew
    let elements: string[] = []

    if (currentNew === 1) {
      pageLimitDisabled('pagination-button-previous')
    }

    if (currentNew < pagination.pages.length) {
      pageLimitEnabled('pagination-button-next')
    }

    for (let item of pagination.pages) {
      elements.push('pagination-button-' + item)
    }

    pageCurrent(currentNew, elements)
    setPagination({...pagination, current: currentNew, limits: {min: minLimitNew, max: maxLimitNew}})
  }

  const executePaginationNext = () => {
    let currentNew: number = pagination.current + 1
    let minLimitNew: number = pagination.total * (currentNew - 1)
    let maxLimitNew: number = pagination.total * currentNew
    let elements: string[] = []

    if (currentNew > 1) {
      pageLimitEnabled('pagination-button-previous')
    }

    if (currentNew === pagination.pages.length) {
      pageLimitDisabled('pagination-button-next')
    }

    for (let item of pagination.pages) {
      elements.push('pagination-button-' + item)
    }

    pageCurrent(currentNew, elements)
    setPagination({...pagination, current: currentNew, limits: {min: minLimitNew, max: maxLimitNew}})
  }

  return (
    <>
      { property.total_items > 0 &&
        <div className="row">
          <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
            { property.show_options &&
              <div className="dataTables_length">
                <Form.Select bsPrefix="form-select form-select-sm form-select-solid" name="country" value={pagination.total} onChange={handleChangePaginationTotal}>
                  <option value="" className={`${pagination.total !== 0 && "d-none"}`}>0</option>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </Form.Select>
              </div>
            }
          </div>
          { pagination.pages.length > 1 &&
            <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
              <div className="dataTables_paginate paging_simple_numbers">
                <ul className="pagination">
                  <li id="pagination-button-previous" className="paginate_button page-item previous disabled">
                    <button className="page-link" type="button" onClick={executePaginationPrevious}>
                      <i className="previous"></i>
                    </button>
                  </li>
                  { pagination.pages.map (( (item, index) => { return (
                    <li key={index} id={"pagination-button-" + item} className={`paginate_button page-item ${item === pagination.current && 'active' }`}>
                      <button className="page-link" type="button" onClick={() => executePaginationSelect(item)}>{item}</button>
                    </li>
                  )}))}
                  <li id="pagination-button-next" className="paginate_button page-item next">
                    <button className="page-link" type="button" onClick={executePaginationNext}>
                      <i className="next"></i>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          }
        </div>
      }
    </>
  )
}

export default AppPagination;
