import React, { useEffect, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';
import { Carousel, Dropdown } from 'react-bootstrap';

import { UseOutletContextProduct } from './Product';
import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { getError, getMessage } from '../../../../languages/translations/response';
import { ProductDA } from '../../../../services/product.service';
import { Error } from '../../../../models/error.model';
import { Message } from '../../../../models/message.model';
import { CompanyProducts } from '../../../../models/company.products.model';
import { Product } from '../../../../models/product.model';
import { Category } from '../../../../models/category.model';
import { Container } from '../../../../styles/container.style';
import { imageProduct } from '../../../../scripts/image.value.script';
import { datetimeFormat12h } from '../../../../scripts/datetime.format.script';
import { categorySelected } from '../../../../scripts/list.selected.item.script';

import AppSortTable from '../../../../components/element/SortTable';
import AppPagination from '../../../../components/element/Pagination';
import AppPreloader from '../../../../components/Preloader';

import imgProductDefault from '../../../../assets/images/product.png';

export interface ProductListPageProps {};

let errorResponse: Error, messageResponse: Message, productsResponse: CompanyProducts;

const ProductListPage: React.FunctionComponent<ProductListPageProps> = props => {
  const {setRoute, companyForUser, categoriesForCompany, loadProductsForCompany} = UseOutletContextProduct()
  const {lang} = StateLanguage()
  const navigate = useNavigate()

  const [mounted, setMounted] = useState(false)
  const [filter, setFilter] = useState({search: '', options: {category: '', sort_field: '', sort_mode: ''}})
  const [checked, setChecked] = useState({count: 0, list: [] as string[]})
  const [pagination, setPagination] = useState({current: 0, total: 0, pages: [] as number[], limits: {min: 0, max: 0}})
  const [products, setProducts] = useState<Product[] | undefined | null>(null)

  const loadProducts = async (id_company: string, callback: any) => {
    let totalPages: number = 1
    let totalByPage: number = (pagination.total !== 0) ? pagination.total : 10
    let pagesOfPagination: number[] = []

    await ProductDA.getProducts(id_company, filter.search, filter.options.category, filter.options.sort_field, filter.options.sort_mode).then( (response) => {
      if (response.status === 200) {
        productsResponse = response.data

        totalPages = (productsResponse.products.length % totalByPage === 0) ? Math.floor(productsResponse.products.length / totalByPage) : Math.floor(productsResponse.products.length / totalByPage) + 1

        for (let i = 1; i <= totalPages; i++) {
          pagesOfPagination.push(i)
        }

        setProducts(productsResponse.products)
        setFilter({search: productsResponse.filter.name, options: {category: productsResponse.filter.category, sort_field: productsResponse.filter.sort_field, sort_mode: productsResponse.filter.sort_mode}})
        setChecked({count: 0, list: []})
        setPagination({current: 1, total: totalByPage, pages: pagesOfPagination, limits: {min: 0, max: totalByPage}})
        callback()
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.code),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.okGotIt,
          customClass: {confirmButton: 'btn btn-primary'}
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      navigate('/error')
    })
  }

  const submitProductUpdate = (item: string) => {
    navigate("/app/inventory/product/update/" + item)
  }

  const submitProductDelete = (item: string) => {
    if (companyForUser) {
      Swal.fire({
        title: lang.labels.youWantDeleteProduct,
        text: lang.labels.youWillNotBeAbleToUndo,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yesDelete,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary'}
      }).then(async (result) => {
        if (result.isConfirmed) {
          await ProductDA.deleteProducts(companyForUser.id, [item]).then( (response) => {
            if (response.status === 200) {
              messageResponse = response.data

              loadProducts(companyForUser.id, () => {
                loadProductsForCompany(companyForUser.id)

                Swal.fire({
                  title: getMessage(messageResponse.message, lang.code),
                  text: lang.labels.actionCompletedReturningToPage,
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1800
                } as SweetAlertOptions)
              })
            } else {
              errorResponse = response.data

              Swal.fire({
                title: getError(errorResponse.code, lang.code),
                text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
                icon: 'error',
                buttonsStyling: !1,
                confirmButtonText: lang.labels.okGotIt,
                customClass: {confirmButton: 'btn btn-primary'}
              } as SweetAlertOptions)
            }
          }).catch( (error) => {
            console.error(error)
            navigate('/error')
          })
        }
      })
    }
  }

  const submitProductDeleteList = () => {
    if (companyForUser) {
      Swal.fire({
        title: lang.labels.youWantDeleteSelectedProducts,
        text: lang.labels.youWillNotBeAbleToUndo,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yesDelete,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary'}
      }).then(async (result) => {
        if (result.isConfirmed) {
          await ProductDA.deleteProducts(companyForUser.id, checked.list).then( (response) => {
            if (response.status === 200) {
              messageResponse = response.data

              loadProducts(companyForUser.id, () => {
                loadProductsForCompany(companyForUser.id)

                Swal.fire({
                  title: getMessage(messageResponse.message, lang.code),
                  text: lang.labels.actionCompletedReturningToPage,
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1800
                } as SweetAlertOptions)
              })
            } else {
              errorResponse = response.data

              Swal.fire({
                title: getError(errorResponse.code, lang.code),
                text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
                icon: 'error',
                buttonsStyling: !1,
                confirmButtonText: lang.labels.okGotIt,
                customClass: {confirmButton: 'btn btn-primary'}
              } as SweetAlertOptions)
            }
          }).catch( (error) => {
            console.error(error)
            navigate('/error')
          })
        }
      })
    }
  }

  const handleChangeSelectAllProducts = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let list: string[] = (event.target.checked) ? event.target.value.split(',') : []
    setChecked({...checked, count: list.length, list: list})
  }

  const handleChangeSelectProduct = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let list: string[] = (event.target.checked) ? (!checked.list.includes(event.target.value)) ? checked.list.concat(event.target.value) : checked.list : checked.list.filter((item) => (item !== event.target.value))
    setChecked({...checked, count: list.length, list: list})
  }

  const handleChangeSearch = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    filter.search = event.target.value
    filter.options = {category: '', sort_mode: '', sort_field: ''}

    if (companyForUser) {
      loadProducts(companyForUser.id, () => {})
    }
  }

  const executeProductCategory = (item: string) => {
    filter.search = ''
    filter.options.category = item
    filter.options.sort_field= ''
    filter.options.sort_mode = ''

    if (companyForUser) {
      loadProducts(companyForUser.id, () => {})
    }
  }

  const executeSortList = (item: string) => {
    if (companyForUser) {
      if (item === filter.options.sort_field) {
        switch (filter.options.sort_mode) {
          case 'asc':
            filter.options.sort_mode = 'desc'
            break;
          case 'desc':
            filter.options.sort_mode = 'asc'
            break;
        }
      } else {
        filter.options.sort_field = item
        filter.options.sort_mode = 'asc'
      }
      loadProducts(companyForUser.id, () => {})
    }
  }

  function productVariety(quantity: number): number {
    switch(Math.floor(quantity / 10)) {
      case 0:
        return 1
      case 1:
        return 2
      case 2:
        return 3
      case 3:
        return 4
      default:
        return 5
    }
  }

  function productCategories(list: Category[]): Category[] {
    return list.filter((item) => (item.products.length > 0))
  }

  useEffect( () => {
    if (companyForUser) {
      filter.search = ''
      filter.options.category = ''
      filter.options.sort_field = ''
      filter.options.sort_mode = ''
      checked.count = 0
      checked.list = []
      pagination.current = 0
      pagination.total = 0
      pagination.pages = []
      pagination.limits.min = 0
      pagination.limits.max = 0

      setProducts(null)
      loadProducts(companyForUser.id, () => {})
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyForUser])

  useEffect( () => {
    setMounted(true)
    setRoute({path: {root: lang.labels.products, branch: lang.labels.productsList}, company: true})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { categoriesForCompany && products
        ?
        <div className="card card-flush">
          <div className="card-header">
            <div className="card-title">
              <div className="d-flex align-items-center position-relative">
                <span className="svg-icon svg-icon-1 position-absolute ms-4">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black" />
                    <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black" />
                  </svg>
                </span>
                <input className="form-control form-control-solid w-350px ps-14" type="text" placeholder={lang.labels.searchProduct} value={filter.search} onChange={handleChangeSearch} />
              </div>
            </div>
            <div className="card-toolbar">
              <Container property={(checked.count === 0).toString()}>
                <div className="d-flex justify-content-end align-items-center">
                  <Dropdown>
                    <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-200px p-0 me-2">
                      <span className="selection">
                        <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                          <span className="select2-selection__rendered" role="textbox">
                            <span className="select2-selection__placeholder">
                              {categorySelected(lang, productCategories(categoriesForCompany), filter.options.category)}
                            </span>
                          </span>
                        </span>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100" align="end">
                      <span className="select2-dropdown select2-dropdown--below">
                        <span className="select2-results">
                          <ul className="select2-results__options" role="listbox">
                            <li className={`select2-results__option select2-results__option--selectable ${filter.options.category.length === 0 && "select2-results__option--selected"}`} role="option" aria-selected={filter.options.category.length === 0}>
                              <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => executeProductCategory("")}>{lang.labels.all}</Dropdown.Item>
                            </li>
                            { productCategories(categoriesForCompany).map (( (item, index) => { return (
                              <li key={index} className={`select2-results__option select2-results__option--selectable ${item.id === filter.options.category && "select2-results__option--selected"}`} role="option" aria-selected={item.id === filter.options.category}>
                                <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => executeProductCategory(item.id)}>{item.display_name}</Dropdown.Item>
                              </li>
                            )}))}
                          </ul>
                        </span>
                      </span>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Link to="/app/inventory/product/create" className="btn btn-primary">
                    <div className="d-flex align-items-center">
                      <span className="svg-icon svg-icon-2 ms-0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                          <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                          <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                        </svg>
                      </span>
                      {lang.labels.addProduct}
                    </div>
                  </Link>
                </div>
              </Container>
              <Container property={(checked.count > 0).toString()}>
                <div className="d-flex justify-content-end align-items-center">
                  <div className="fw-bolder me-5">
                    <span className="me-2">{checked.count}</span>
                    {lang.labels.selected}
                  </div>
                  <button className="btn btn-danger" type="button" onClick={submitProductDeleteList}>{lang.labels.deleteSelected}</button>
                </div>
              </Container>
            </div>
          </div>
          <div className="card-body pt-0">
            <div className="dataTables_wrapper">
              <div className="table-responsive">
                <table className="table align-middle table-row-dashed mb-2">
                  <thead className="border-bottom">
                    <tr className="text-start text-muted text-uppercase fw-bolder fs-7">
                      <th className="w-25px">
                        <div className="form-check form-check-sm form-check-custom form-check-solid">
                          <input className="form-check-input" type="checkbox" value={products.map(item => item.id)} checked={products.length === checked.count && checked.count > 0} onChange={handleChangeSelectAllProducts} />
                        </div>
                      </th>
                      <th className="min-w-250px">
                        <div className="d-flex align-items-center text-nowrap cursor-pointer" onClick={() => executeSortList("product")}>
                          <span className="me-1">{lang.labels.product}</span>
                          <AppSortTable attribute={"product"} field={filter.options.sort_field} mode={filter.options.sort_mode}></AppSortTable>
                        </div>
                      </th>
                      <th className="min-w-150px">
                        <div className="d-flex align-items-center text-nowrap cursor-pointer" onClick={() => executeSortList("category")}>
                          <span className="me-1">{lang.labels.category}</span>
                          <AppSortTable attribute={"category"} field={filter.options.sort_field} mode={filter.options.sort_mode}></AppSortTable>
                        </div>
                      </th>
                      <th className="min-w-150px">
                        <div className="d-flex align-items-center text-nowrap cursor-pointer" onClick={() => executeSortList("tags")}>
                          <span className="me-1">{lang.labels.tags}</span>
                          <AppSortTable attribute={"tags"} field={filter.options.sort_field} mode={filter.options.sort_mode}></AppSortTable>
                        </div>
                      </th>
                      <th className="w-100px">
                        <div className="d-flex align-items-center text-nowrap cursor-pointer" onClick={() => executeSortList("last modified")}>
                          <span className="me-1">{lang.labels.lastModified}</span>
                          <AppSortTable attribute={"last modified"} field={filter.options.sort_field} mode={filter.options.sort_mode}></AppSortTable>
                        </div>
                      </th>
                      <th className="w-100px">
                        <div className="d-flex align-items-center text-nowrap cursor-pointer" onClick={() => executeSortList("variants")}>
                          <span className="me-1">{lang.labels.variants}</span>
                          <AppSortTable attribute={"variants"} field={filter.options.sort_field} mode={filter.options.sort_mode}></AppSortTable>
                        </div>
                      </th>
                      <th className="w-100px text-end">{lang.labels.actions}</th>
                    </tr>
                  </thead>
                  { products.length > 0
                    ?
                    <tbody className="text-gray-700 fw-bold">
                      { products.map (( (item, index) => { return (
                        <tr key={index} className="row-dashed">
                          <td>
                            <div className="form-check form-check-sm form-check-custom form-check-solid">
                              <input className="form-check-input" type="checkbox" value={item.id} checked={checked.list.includes(item.id)} onChange={handleChangeSelectProduct} />
                            </div>
                          </td>
                          <td className="d-flex align-items-center">
                            <div className="me-5">
                              <Link to={"/app/inventory/product/view/" + item.id}>
                                { item.photos.length > 0
                                  ?
                                  <Carousel slide={false} controls={false} indicators={false} touch={false}>
                                    { item.photos.map (( (item, index) => { return (
                                      <Carousel.Item key={index}>
                                        <img src={imageProduct(item)} className="d-block w-50px h-50px" alt={lang.labels.photography + "-" + index} />
                                      </Carousel.Item>
                                    )}))}
                                  </Carousel>
                                  :
                                  <div className="symbol symbol-50px overflow-hidden">
                                    <span className="symbol-label">
                                      <img src={imgProductDefault} alt={lang.labels.photography} className="w-100 h-100" />
                                    </span>
                                  </div>
                                }
                              </Link>
                            </div>
                            <Link to={"/app/inventory/product/view/" + item.id} className="fw-bolder text-dark text-hover-primary">{item.display_name}</Link>
                          </td>
                          <td>{item.category.display_name}</td>
                          <td>
                            { item.tags.length > 0
                              ?
                              <>
                                { item.tags.map (( (sub_item, sub_index) => { return (
                                  <div key={sub_index} className="badge badge-light m-1">{sub_item}</div>
                                )}))}
                              </>
                              :
                              <small>{lang.labels.none}</small>
                            }
                          </td>
                          <td className="text-nowrap fs-7">{datetimeFormat12h(lang.code, item.updated_at)}</td>
                          <td className="text-nowrap text-end">
                            <div className="rating justify-content-end align-content-center">
                              { Array.from ( {length: productVariety(item.variants.length)}, (_, index) => (
                                <div key={index} className={`rating-label ${item.variants.length > 0 ? "checked" : "unchecked"}`}>
                                  <span className="svg-icon svg-icon-3">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M11.1359 4.48359C11.5216 3.82132 12.4784 3.82132 12.8641 4.48359L15.011 8.16962C15.1523 8.41222 15.3891 8.58425 15.6635 8.64367L19.8326 9.54646C20.5816 9.70867 20.8773 10.6186 20.3666 11.1901L17.5244 14.371C17.3374 14.5803 17.2469 14.8587 17.2752 15.138L17.7049 19.382C17.7821 20.1445 17.0081 20.7069 16.3067 20.3978L12.4032 18.6777C12.1463 18.5645 11.8537 18.5645 11.5968 18.6777L7.69326 20.3978C6.99192 20.7069 6.21789 20.1445 6.2951 19.382L6.7248 15.138C6.75308 14.8587 6.66264 14.5803 6.47558 14.371L3.63339 11.1901C3.12273 10.6186 3.41838 9.70867 4.16744 9.54646L8.3365 8.64367C8.61089 8.58425 8.84767 8.41222 8.98897 8.16962L11.1359 4.48359Z" fill="currentColor"></path>
                                    </svg>
                                  </span>
                                </div>
                              ))}
                            </div>
                          </td>
                          <td className="text-end">
                            <Dropdown>
                              <Dropdown.Toggle variant="btn btn-sm btn-light btn-active-light-primary">
                                <div className="d-flex align-items-center">
                                  {lang.labels.actions}
                                  <span className="svg-icon svg-icon-5 me-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                      <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black"></path>
                                    </svg>
                                  </span>
                                </div>
                              </Dropdown.Toggle>
                              <Dropdown.Menu variant="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary w-auto p-3 fs-7" align="end">
                                <Dropdown.Item bsPrefix="menu-item" onClick={() => {submitProductUpdate(item.id)} }>
                                  <p className="menu-link fw-bold px-3 py-1 m-0">{lang.labels.edit}</p>
                                </Dropdown.Item>
                                <Dropdown.Item bsPrefix="menu-item" onClick={() => submitProductDelete(item.id)}>
                                  <p className="menu-link fw-bold px-3 py-1 m-0">{lang.labels.delete}</p>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      )}))}
                    </tbody>
                    :
                    <tbody>
                      <tr className="text-gray-700 fw-bold">
                        <td valign="top" colSpan={7} className="text-center">{lang.labels.noRecordsFound}</td>
                      </tr>
                    </tbody>
                  }
                </table>
              </div>
              <AppPagination property={{total_items: products.length, show_options: true}} pagination={pagination} setPagination={setPagination}></AppPagination>
            </div>
          </div>
        </div>
        :
        <AppPreloader></AppPreloader>
      }
    </div>
  )
};

export default ProductListPage;
