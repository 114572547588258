import React, { Dispatch, SetStateAction } from 'react';
import { Dropdown } from 'react-bootstrap';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { legendValid } from '../../tools/legend.data.entry.tool';
import { currencyList, currencyValue } from '../../libraries/currency.library';
import { Currency } from '../../models/currency.model';

import AppLegend from './Legend';

export interface AppInputCurrencyProps {
  currency: {value: string, object: any, valid: boolean},
  setCurrency: Dispatch<SetStateAction<{value: string, object: any, valid: boolean}>>
};

const AppInputCurrency: React.FunctionComponent<AppInputCurrencyProps> = ({currency, setCurrency}) => {
  const {lang} = StateLanguage()

  const handleChangeCurrency = (item: Currency) => {
    setCurrency({value: item.code, object: item, valid: true})
    legendValid('legend-currency-required')
  }

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
          <span className="selection">
            <span className="select2-selection select2-selection--single form-select form-select-solid">
              <span className="select2-selection__rendered" role="textbox">
                {currency.valid ? currencyValue(lang.code, currency.value) : lang.labels.selectOption}
              </span>
            </span>
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
          <span className="select2-dropdown select2-dropdown--below">
            <span className="select2-results">
              <ul className="select2-results__options" role="listbox">
                { currencyList(lang.code).map (( (item, index) => { return (
                  <li key={index} className={`select2-results__option select2-results__option--selectable ${item.code === currency.value && "select2-results__option--selected"}`} role="option" aria-selected={item.code === currency.value}>
                    <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeCurrency(item)}>{item.code}: {item.name} ({item.symbol})</Dropdown.Item>
                  </li>
                )}))}
              </ul>
            </span>
          </span>
        </Dropdown.Menu>
      </Dropdown>
      <AppLegend attribute={{validity: currency.valid, name: "currency", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
    </>
  )
}

export default AppInputCurrency;
