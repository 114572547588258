import React, { useEffect, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { UseOutletContextProviderOrder } from './ProviderOrder';
import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { getError } from '../../../../languages/translations/response';
import { ProviderOrderDA } from '../../../../services/provider.order.service';
import { ProviderInvoiceDA } from '../../../../services/provider.invoice.service';
import { Error } from '../../../../models/error.model';
import { ProviderOrder } from '../../../../models/provider.order.model';
import { ProviderInvoice } from '../../../../models/provider.invoice.model';
import { colorStatusOrder } from '../../../../scripts/badge.color.script';
import { statusDocumentValue } from '../../../../libraries/status.document.library';

import AppPurchaseViewDetail from '../card/PurchaseViewDetail';
import AppPurchaseViewRequirements from '../card/PurchaseViewRequirements';
import AppPurchaseViewAmount from '../card/PurchaseViewAmount';
import AppPurchaseViewSignatures from '../card/PurchaseViewSignatures';
import AppPurchaseViewVouchers from '../card/PurchaseViewVouchers';
import AppPreloader from '../../../../components/Preloader';

export interface ProviderOrderViewPageProps {};

let errorResponse: Error, providerOrderResponse: ProviderOrder, providerInvoiceResponse: ProviderInvoice;

const ProviderOrderViewPage: React.FunctionComponent<ProviderOrderViewPageProps> = props => {
  const {setRoute, appServiceForUser, companyForUser} = UseOutletContextProviderOrder()
  const {lang} = StateLanguage()
  const param = useParams()
  const navigate = useNavigate()

  const [mounted, setMounted] = useState(false)
  const [providerOrder, setProviderOrder] = useState<ProviderOrder | undefined | null>(null)

  const loadProviderOrder = async (id_company: string, id_provider_order: string) => {
    await ProviderOrderDA.getProviderOrder(id_company, id_provider_order).then( (response) => {
      if (response.status === 200) {
        providerOrderResponse = response.data
        setProviderOrder(providerOrderResponse)
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.code),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.okGotIt,
          customClass: {confirmButton: 'btn btn-primary'}
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      navigate('/error')
    }) 
  }

  const submitProviderOrderStatus = async (item: string) => {
    if (appServiceForUser && companyForUser && providerOrder) {
      Swal.fire({
        title: `${lang.labels.youWantToChangeProviderOrderStatusTo} ${statusDocumentValue(lang, item).toLowerCase()}?`,
        text: lang.labels.youWillNotBeAbleToUndo,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `${lang.labels.yes}, ${statusDocumentValue(lang, item).toLowerCase()}`,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary'}
      }).then(async (result) => {
        if (result.isConfirmed) {
          await ProviderOrderDA.putProviderOrderStatus(companyForUser.id, providerOrder.id, item).then( async (response) => {
            if (response.status === 200) {
              providerOrderResponse = response.data
              setProviderOrder(providerOrderResponse)

              Swal.fire({
                title: lang.labels.successfullyUpdatedStatus,
                text: lang.labels.actionCompletedReturningToPage,
                icon: 'success',
                showConfirmButton: false,
                timer: 1800
              } as SweetAlertOptions)
            } else {
              errorResponse = response.data

              Swal.fire({
                title: getError(errorResponse.code, lang.code),
                text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
                icon: 'error',
                buttonsStyling: !1,
                confirmButtonText: lang.labels.okGotIt,
                customClass: {confirmButton: 'btn btn-primary'}
              } as SweetAlertOptions)
            }
          }).catch( (error) => {
            console.error(error)
            navigate('/error')
          })
        }
      })
    }
  }

  const submitProviderInvoiceCreate = async () => {
    if (companyForUser && providerOrder) {
      Swal.fire({
        title: lang.labels.youWantToGenerateAProviderInvoiceFromThisProviderOrder,
        text: lang.labels.thisOptionIsUniqueAndNewInvoice,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yesGenerate,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary'}
      }).then(async (result) => {
        if (result.isConfirmed) {
          await ProviderInvoiceDA.postProviderInvoiceReference(companyForUser.id, providerOrder.id).then( (response) => {
            if (response.status === 201) {
              providerInvoiceResponse = response.data

              Swal.fire({
                title: lang.labels.successfullyCreatedInvoice,
                text: lang.labels.actionCompletedRedirectingToPage,
                icon: 'success',
                showConfirmButton: false,
                timer: 1800
              } as SweetAlertOptions).then( () => {
                navigate('/app/purchase/invoice/view/' + providerInvoiceResponse.id)
              })
            } else {
              errorResponse = response.data

              Swal.fire({
                title: getError(errorResponse.code, lang.code),
                text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
                icon: 'error',
                buttonsStyling: !1,
                confirmButtonText: lang.labels.okGotIt,
                customClass: {confirmButton: 'btn btn-primary'}
              } as SweetAlertOptions)
            }
          }).catch( (error) => {
            console.error(error)
            navigate('/error')
          })
        }
      })
    }
  }

  useEffect( () => {
    if (companyForUser && param.id) {
      loadProviderOrder(companyForUser.id, param.id)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyForUser, param])

  useEffect( () => {
    setMounted(true)
    setRoute({path: {root: lang.labels.providerOrders, branch: lang.labels.viewProviderOrder}, company: false})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { companyForUser && providerOrder
        ?
        <>
          <div className="card card-flush mb-5">
            <div className="card-header pt-3">
              <div className="card-title align-items-start flex-column">
                <h1>{providerOrder.provider_order_number}</h1>
                <label className="fs-6 fw-bold mt-1">
                  {lang.labels.status}:
                  <span className={`badge badge-light-${colorStatusOrder(providerOrder.status)} text-uppercase fs-7 ms-2`}>{statusDocumentValue(lang, providerOrder.status)}</span>
                </label>
              </div>
              <div className="card-toolbar">
                <div className="d-flex justify-content-end">
                  { providerOrder.status === "in progress" &&
                    <>
                      <button className="btn btn-light-primary mb-1 me-2" type="button" onClick={() => submitProviderOrderStatus("approved")}>
                        <div className="d-flex align-items-center">
                          <span className="svg-icon svg-icon-2 ms-0">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path opacity="0.3" d="M10.3 14.3L11 13.6L7.70002 10.3C7.30002 9.9 6.7 9.9 6.3 10.3C5.9 10.7 5.9 11.3 6.3 11.7L10.3 15.7C9.9 15.3 9.9 14.7 10.3 14.3Z" fill="black"/>
                              <path d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM11.7 15.7L17.7 9.70001C18.1 9.30001 18.1 8.69999 17.7 8.29999C17.3 7.89999 16.7 7.89999 16.3 8.29999L11 13.6L7.70001 10.3C7.30001 9.89999 6.69999 9.89999 6.29999 10.3C5.89999 10.7 5.89999 11.3 6.29999 11.7L10.3 15.7C10.5 15.9 10.8 16 11 16C11.2 16 11.5 15.9 11.7 15.7Z" fill="black"/>
                            </svg>
                          </span>
                          {lang.labels.approveProviderOrder}
                        </div>
                      </button>
                      <button className="btn btn-light-primary mb-1 me-2" type="button" onClick={() => submitProviderOrderStatus("canceled")}>
                        <div className="d-flex align-items-center">
                          <span className="svg-icon svg-icon-2 ms-0">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path opacity="0.3" d="M12 10.6L14.8 7.8C15.2 7.4 15.8 7.4 16.2 7.8C16.6 8.2 16.6 8.80002 16.2 9.20002L13.4 12L12 10.6ZM10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 8.99999 16.4 9.19999 16.2L12 13.4L10.6 12Z" fill="black"/>
                              <path d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM13.4 12L16.2 9.20001C16.6 8.80001 16.6 8.19999 16.2 7.79999C15.8 7.39999 15.2 7.39999 14.8 7.79999L12 10.6L9.2 7.79999C8.8 7.39999 8.2 7.39999 7.8 7.79999C7.4 8.19999 7.4 8.80001 7.8 9.20001L10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 9 16.4 9.2 16.2L12 13.4L14.8 16.2C15 16.4 15.3 16.5 15.5 16.5C15.7 16.5 16 16.4 16.2 16.2C16.6 15.8 16.6 15.2 16.2 14.8L13.4 12Z" fill="black"/>
                            </svg>
                          </span>
                          {lang.labels.cancelProviderOrder}
                        </div>
                      </button>
                      <Link to={"/app/purchase/order/update/" + providerOrder.id} className="btn btn-primary mb-1 me-2">
                        <div className="d-flex align-items-center">
                          <span className="svg-icon svg-icon-2 ms-0">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path opacity="0.3" d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z" fill="black"/>
                              <path d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z" fill="black"/>
                              <path d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z" fill="black"/>
                            </svg>
                          </span>
                          {lang.labels.editProviderOrder}
                        </div>
                      </Link>
                    </>
                  }
                  { providerOrder.status === "approved" &&
                    <button className="btn btn-primary mb-1 me-2" type="button" onClick={submitProviderInvoiceCreate}>
                      <div className="d-flex align-items-center">
                        <span className="svg-icon svg-icon-2 ms-0">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z" fill="black"/>
                            <path d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z" fill="black"/>
                            <path d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z" fill="black"/>
                          </svg>
                        </span>
                        {lang.labels.generateProviderInvoice}
                      </div>
                    </button>
                  }
                  <Link to={"/app/purchase/order/create/reference/" + providerOrder.id} className="btn btn-primary mb-1">
                    <div className="d-flex align-items-center">
                      <span className="svg-icon svg-icon-2 ms-0">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect opacity="0.5" x="7" y="2" width="14" height="16" rx="3" fill="black"/>
                          <rect x="3" y="6" width="14" height="16" rx="3" fill="black"/>
                        </svg>
                      </span>
                      {lang.labels.makeCopy}
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <AppPurchaseViewDetail purchase={"order"} record={providerOrder}></AppPurchaseViewDetail>
          </div>
          <AppPurchaseViewRequirements record={providerOrder}></AppPurchaseViewRequirements>
          <AppPurchaseViewAmount purchase={"order"} record={providerOrder}></AppPurchaseViewAmount>
          <AppPurchaseViewVouchers purchase={"order"} record={providerOrder}></AppPurchaseViewVouchers>
          <AppPurchaseViewSignatures record={providerOrder}></AppPurchaseViewSignatures>
        </>
        :
        <AppPreloader></AppPreloader>
      }
    </div>
  )
}

export default ProviderOrderViewPage;
