import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FaExclamationCircle } from 'react-icons/fa';

import { UseOutletContextSale } from '../Sale';
import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { Currency } from '../../../../models/currency.model';
import { InventoryReference } from '../../../../models/inventory.reference.model';
import { removeTooltip, uploadTooltip } from '../../../../tools/tooltip.tool';
import { modalShow } from '../../../../tools/modal.tool';
import { Legend } from '../../../../styles/legend.style';
import { legendInvalid, legendInvalidIcon, legendInvalidEmpty, legendValid, legendValidIcon, legendValidRequired, legendValidInvalidRestart } from '../../../../tools/legend.data.entry.tool';
import { evaluateLegendValidEmptyObjectList, evaluateLegendValidRequiredObjectMap } from '../../../../scripts/validate.legend.script';
import { unitProductList, unitServiceList } from '../../../../scripts/list.values.script';
import { moneyFormat } from '../../../../scripts/format.value.script';
import { contentReferenceSelected, measureUnitSelected } from '../../../../scripts/list.selected.item.script';
import { expressions } from '../../../../libraries/regular.expressions.library';
import { inventoryTypeValue } from '../../../../libraries/inventory.type.library';

import AppLegend from '../../../../components/element/Legend';
import AppModalSettingCreateTax from '../../../../components/modal/ModalSettingsCreateTax';
import AppModalSettingCreateUnit from '../../../../components/modal/ModalSettingsCreateUnit';

export interface AppSaleFormRequirementsProps {
  currency: Currency | undefined | null,
  requirements: {list: boolean, value: {type: string, description: string, contents: { detail: string, reference: string, quantity: string, price_unit: string, tax_included: boolean, tax_rate: string, rebate_type: string, rebate_value: string, rebate_quantity: string, unit: string }[]}[], valid: {type: boolean, description: boolean, content: boolean, contents: {detail: boolean, quantity: boolean, price_unit: boolean, tax_rate: boolean, rebate_value: boolean, rebate_quantity: boolean, unit: boolean}[]}[]},
  setRequirements: Dispatch<SetStateAction<{list: boolean, value: {type: string, description: string, contents: { detail: string, reference: string, quantity: string, price_unit: string, tax_included: boolean, tax_rate: string, rebate_type: string, rebate_value: string, rebate_quantity: string, unit: string }[]}[], valid: {type: boolean, description: boolean, content: boolean, contents: {detail: boolean, quantity: boolean, price_unit: boolean, tax_rate: boolean, rebate_value: boolean, rebate_quantity: boolean, unit: boolean}[]}[]}>>
};

const AppSaleFormRequirements: React.FunctionComponent<AppSaleFormRequirementsProps> = ({currency, requirements, setRequirements}) => {
  const {companyForUser, settingsForCompany, servicesForCompany, productsForCompany, loadSettingsForCompany} = UseOutletContextSale()
  const {lang} = StateLanguage()

  const [list, setList] = useState<{services: InventoryReference[], products: InventoryReference[]}>({services: [], products: []})
  const [searchService, setSearchService] = useState<{text: string, list: InventoryReference[]}>({text: '', list: []})
  const [searchProduct, setSearchProduct] = useState<{text: string, list: InventoryReference[]}>({text: '', list: []})
  const [newTax, setNewTax] = useState<{main_modal: string | null, index: number | null, sub_index: number | null}>({main_modal: null, index: null, sub_index: null})
  const [newUnit, setNewUnit] = useState<{type: string, main_modal: string | null, index: number | null, sub_index: number | null}>({type: '', main_modal: null, index: null, sub_index: null})

  const handleChangeDescriptionToRequirement = (index: number, event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let tempRequirementsValue = requirements.value.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item.description = event.target.value
      }
      return temp_item
    })

    setRequirements({...requirements, value: tempRequirementsValue})
  }

  const handleChangeDetailContentToRequirement = (index: number, sub_index: number, event: React.ChangeEvent <HTMLFormElement | HTMLTextAreaElement>) => {
    let text: string = event.target.value
    let listProducts: InventoryReference[] = []
    let listService: InventoryReference[] = []

    let tempRequirementsValue = requirements.value.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item.contents[sub_index].detail = text
      }
      return temp_item
    })

    if (servicesForCompany && tempRequirementsValue[index].type === 'service') {
      if (text.length > 0) {
        for (let service of list.services.flatMap(item => item)) {
          let name: string = service.display_name.toLowerCase()
          let code: string = service.code.toLowerCase()
          if (name.indexOf(text.toLowerCase()) !== -1 || code.indexOf(text.toLowerCase()) !== -1) {
            listService.push(service)
          }
        }
      } else {
        listService = list.services
      }
    }

    if (productsForCompany && tempRequirementsValue[index].type === 'product') {
      if (text.length > 0) {
        for (let product of list.products.flatMap(item => item)) {
          let name: string = product.display_name.toLowerCase()
          let description: string = product.description.toLowerCase()
          let code: string = product.code.toLowerCase()
          if (name.indexOf(text.toLowerCase()) !== -1 || description.indexOf(text.toLowerCase()) !== -1 || code.indexOf(text.toLowerCase()) !== -1) {
            listProducts.push(product)
          }
        }
      } else {
        listProducts = list.products
      }
    }

    setRequirements({...requirements, value: tempRequirementsValue})
    setSearchProduct({text: text, list: listProducts})
    setSearchService({text: text, list: listService})
  }

  const handleChangeSelectDetailContentToRequirement = (index: number, sub_index: number, item: InventoryReference) => {
    let tempRequirementsValue = requirements.value.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item.contents[sub_index].detail = contentDetail(item)
        temp_item.contents[sub_index].reference = (item.type === 'service' ) ? item.id_service : item.id_variant
        temp_item.contents[sub_index].price_unit = item.price.toString()
        temp_item.contents[sub_index].tax_included = false
        temp_item.contents[sub_index].tax_rate = item.tax_rate
        temp_item.contents[sub_index].unit = item.unit.id
        temp_item.contents[sub_index].rebate_type = item.discount.type
        temp_item.contents[sub_index].rebate_value = item.discount.value.toString()
        temp_item.contents[sub_index].rebate_quantity = item.discount.quantity.toString()
      }
      return temp_item
    })
    let tempRequirementsValid = requirements.valid.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item.contents[sub_index].detail = true
        temp_item.contents[sub_index].price_unit = true
        temp_item.contents[sub_index].tax_rate = true
        temp_item.contents[sub_index].unit = true
        temp_item.contents[sub_index].rebate_value = true
        temp_item.contents[sub_index].rebate_quantity = true
      }
      return temp_item
    })

    setRequirements({...requirements, value: tempRequirementsValue, valid: tempRequirementsValid})
    legendValidRequired('legend-requirement-content-detail-valid-', 'legend-requirement-content-detail-required-' + index + sub_index)
    legendValidRequired('legend-requirement-content-price-valid-', 'legend-requirement-content-price-required-' + index + sub_index)
    legendValid('legend-requirement-content-tax-required-' + index + sub_index)
    legendValid('legend-requirement-content-unit-required-' + index + sub_index)
    legendValid('legend-requirement-content-rebate-valid-' + index + sub_index)
    uploadTooltip()
  }

  const handleChangeUnitContentToRequirement = (index: number, sub_index: number, item: string) => {
    let tempRequirementsValue = requirements.value.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item.contents[sub_index].unit = item
      }
      return temp_item
    })
    let tempRequirementsValid = requirements.valid.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item.contents[sub_index].unit = true
      }
      return temp_item
    })

    setRequirements({...requirements, value: tempRequirementsValue, valid: tempRequirementsValid})
    legendValid('legend-requirement-content-unit-required-' + index + sub_index)
  }

  const handleChangeQuantityContentToRequirement = (index: number, sub_index: number, event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let tempRequirementsValue = requirements.value.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item.contents[sub_index].quantity = event.target.value
      }
      return temp_item
    })

    setRequirements({...requirements, value: tempRequirementsValue})
  }

  const handleChangePriceContentToRequirement = (index: number, sub_index: number, event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let tempRequirementsValue = requirements.value.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item.contents[sub_index].price_unit = event.target.value
      }
      return temp_item
    })

    setRequirements({...requirements, value: tempRequirementsValue})
  }

  const handleChangeTaxIncludeContentToRequirement = (index: number, sub_index: number, item: boolean) => {
    let tempRequirementsValue = requirements.value.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item.contents[sub_index].tax_included = !item
      }
      return temp_item
    })

    setRequirements({...requirements, value: tempRequirementsValue})
  }

  const handleChangeTaxRateContentToRequirement = (index: number, sub_index: number, item: string) => {
    let tempRequirementsValue = requirements.value.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item.contents[sub_index].tax_rate = item
      }
      return temp_item
    })
    let tempRequirementsValid = requirements.valid.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item.contents[sub_index].tax_rate = true
      }
      return temp_item
    })

    setRequirements({...requirements, value: tempRequirementsValue, valid: tempRequirementsValid})
    legendValid('legend-requirement-content-tax-required-' + index + sub_index)
  }

  const handleChangeRebateTypeContentToRequirement = (index: number, sub_index: number, item: string) => {
    let rebateType: string = (item === 'percentage') ? 'fixed' : (item === 'fixed') ? 'none' : (item === 'none') ? 'percentage' : 'none'

    let tempRequirementsValue = requirements.value.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item.contents[sub_index].rebate_type = rebateType
        temp_item.contents[sub_index].rebate_value = (rebateType === 'none') ? '' : temp_item.contents[sub_index].rebate_value
        temp_item.contents[sub_index].rebate_quantity = (rebateType === 'none') ? '' : temp_item.contents[sub_index].rebate_quantity
      }
      return temp_item
    })
    let tempRequirementsValid = requirements.valid.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item.contents[sub_index].rebate_value = (rebateType === 'none' || ((rebateType === 'percentage' || rebateType === 'fixed') && tempRequirementsValue[index].contents[sub_index].rebate_value.length > 0))
        temp_item.contents[sub_index].rebate_quantity = (rebateType === 'none' || ((rebateType === 'percentage' || rebateType === 'fixed') && tempRequirementsValue[index].contents[sub_index].rebate_value.length > 0))
      }
      return temp_item
    })

    setRequirements({...requirements, value: tempRequirementsValue, valid: tempRequirementsValid})

    if ((rebateType === 'percentage' || rebateType === 'fixed') && tempRequirementsValue[index].contents[sub_index].rebate_value.length === 0 && tempRequirementsValue[index].contents[sub_index].rebate_quantity.length === 0) {
      legendInvalid('legend-requirement-content-rebate-required-' + index + sub_index)
    } else {
      switch (rebateType) {
        case 'percentage':
          if (expressions.percentage.test(tempRequirementsValue[index].contents[sub_index].rebate_value)) {
            legendValid('legend-requirement-content-rebate-valid-' + index + sub_index)
          } else {
            legendInvalid('legend-requirement-content-rebate-valid-' + index + sub_index)
          }
          break;
        case 'fixed':
          if (expressions.currency.test(tempRequirementsValue[index].contents[sub_index].rebate_value)) {
            legendValid('legend-requirement-content-rebate-valid-' + index + sub_index)
          } else {
            legendInvalid('legend-requirement-content-rebate-valid-' + index + sub_index)
          }
          break;
        case 'none':
          legendValid('legend-requirement-content-rebate-valid-' + index + sub_index)
          legendValid('legend-requirement-content-rebate-required-' + index + sub_index)
          break;
        default:
          legendValidInvalidRestart('legend-requirement-content-rebate-valid-' + index + sub_index)
          legendValidInvalidRestart('legend-requirement-content-rebate-required-' + index + sub_index)
          break;
        }
    }
  }

  const handleChangeRebateValueContentToRequirement = (index: number, sub_index: number, event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let tempRequirementsValue = requirements.value.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item.contents[sub_index].rebate_value = event.target.value
      }
      return temp_item
    })

    setRequirements({...requirements, value: tempRequirementsValue})
  }

  const handleChangeRebateQuantityContentToRequirement = (index: number, sub_index: number, event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let tempRequirementsValue = requirements.value.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item.contents[sub_index].rebate_quantity = event.target.value
      }
      return temp_item
    })

    setRequirements({...requirements, value: tempRequirementsValue})
  }

  const validateDescriptionToRequirement = (index: number) => {
    evaluateLegendValidEmptyObjectList(expressions.text, index, 'description', requirements, setRequirements, 'legend-requirement-description-valid-' + index)
  }

  const validateDetailContentToRequirement = (index: number, sub_index: number) => {
    evaluateLegendValidRequiredObjectMap(expressions.text, index, sub_index, 'detail', requirements, setRequirements, 'legend-requirement-content-detail-valid-' + index + sub_index, 'legend-requirement-content-detail-required-' + index + sub_index)
  }

  const validateQuantityContentToRequirement = (index: number, sub_index: number) => {
    evaluateLegendValidRequiredObjectMap(expressions.quantity, index, sub_index, 'quantity', requirements, setRequirements, 'legend-requirement-content-quantity-valid-' + index + sub_index, 'legend-requirement-content-quantity-required-' + index + sub_index)
  }

  const validatePriceContentToRequirement = (index: number, sub_index: number) => {
    evaluateLegendValidRequiredObjectMap(expressions.currency, index, sub_index, 'price_unit', requirements, setRequirements, 'legend-requirement-content-price-valid-' + index + sub_index, 'legend-requirement-content-price-required-' + index + sub_index)
  }

  const validateRebateContentToRequirement = (index: number, sub_index: number) => {
    if ((requirements.value[index].contents[sub_index].rebate_type === 'percentage' || requirements.value[index].contents[sub_index].rebate_type === 'fixed') && requirements.value[index].contents[sub_index].rebate_value.length === 0 && requirements.value[index].contents[sub_index].rebate_quantity.length === 0) {
      let requerimentsValid = requirements.valid.map((temp_item, temp_index) => {
        if (index === temp_index) {
          temp_item.contents[sub_index].rebate_value = false
          temp_item.contents[sub_index].rebate_quantity = false
        }
        return temp_item
      })
      setRequirements({...requirements, valid: requerimentsValid})
      legendValid('legend-requirement-content-rebate-valid-' + index + sub_index)
      legendInvalid('legend-requirement-content-rebate-required-' + index + sub_index)
    } else {
      switch (requirements.value[index].contents[sub_index].rebate_type) {
        case 'percentage':
          if (expressions.percentage.test(requirements.value[index].contents[sub_index].rebate_value) && expressions.percentage.test(requirements.value[index].contents[sub_index].rebate_quantity)) {
            let requerimentsValid = requirements.valid.map((temp_item, temp_index) => {
              if (index === temp_index) {
                temp_item.contents[sub_index].rebate_value = true
                temp_item.contents[sub_index].rebate_quantity = true
              }
              return temp_item
            })
            setRequirements({...requirements, valid: requerimentsValid})
            legendValid('legend-requirement-content-rebate-valid-' + index + sub_index)
            legendValid('legend-requirement-content-rebate-required-' + index + sub_index)
          } else {
            let requerimentsValid = requirements.valid.map((temp_item, temp_index) => {
              if (index === temp_index) {
                temp_item.contents[sub_index].rebate_value = expressions.percentage.test(requirements.value[index].contents[sub_index].rebate_value)
                temp_item.contents[sub_index].rebate_quantity = expressions.percentage.test(requirements.value[index].contents[sub_index].rebate_quantity)
              }
              return temp_item
            })
            setRequirements({...requirements, valid: requerimentsValid})
            legendInvalid('legend-requirement-content-rebate-valid-' + index + sub_index)
            legendValid('legend-requirement-content-rebate-required-' + index + sub_index)
          }
          break;
        case 'fixed':
          if (expressions.currency.test(requirements.value[index].contents[sub_index].rebate_value) && expressions.currency.test(requirements.value[index].contents[sub_index].rebate_quantity)) {
            let requerimentsValid = requirements.valid.map((temp_item, temp_index) => {
              if (index === temp_index) {
                temp_item.contents[sub_index].rebate_value = true
                temp_item.contents[sub_index].rebate_quantity = true
              }
              return temp_item
            })
            setRequirements({...requirements, valid: requerimentsValid})
            legendValid('legend-requirement-content-rebate-valid-' + index + sub_index)
            legendValid('legend-requirement-content-rebate-required-' + index + sub_index)
          } else {
            let requerimentsValid = requirements.valid.map((temp_item, temp_index) => {
              if (index === temp_index) {
                temp_item.contents[sub_index].rebate_value = expressions.currency.test(requirements.value[index].contents[sub_index].rebate_value)
                temp_item.contents[sub_index].rebate_quantity = expressions.currency.test(requirements.value[index].contents[sub_index].rebate_quantity)
              }
              return temp_item
            })
            setRequirements({...requirements, valid: requerimentsValid})
            legendInvalid('legend-requirement-content-rebate-valid-' + index + sub_index)
            legendValid('legend-requirement-content-rebate-required-' + index + sub_index)
          }
          break;
        case 'none':
          legendValid('legend-requirement-content-rebate-valid-' + index + sub_index)
          legendValid('legend-requirement-content-rebate-required-' + index + sub_index)
          break;
        default:
          legendValidInvalidRestart('legend-requirement-content-rebate-valid-' + index + sub_index)
          legendValidInvalidRestart('legend-requirement-content-rebate-required-' + index + sub_index)
          break;
        }
    }
  }

  const executeAddRequirement = (item: string) => {
    if (allowAddRequirement()) {
      let newRequirimentValue: {type: string, description: string, contents: { detail: string, reference: string, quantity: string, price_unit: string, tax_included: boolean, tax_rate: string, rebate_type: string, rebate_value: string, rebate_quantity: string, unit: string }[]} = {
        type: item,
        description: '',
        contents: [
          {
            detail: '',
            reference: '',
            quantity: '',
            price_unit: '',
            tax_rate: '',
            tax_included: false,
            rebate_type: 'none',
            rebate_value: '',
            rebate_quantity: '',
            unit: ''
          }
        ]
      }
      let newRequirementValid: {type: boolean, description: boolean, content: boolean, contents: {detail: boolean, quantity: boolean, price_unit: boolean, tax_rate: boolean, rebate_value: boolean, rebate_quantity: boolean, unit: boolean}[]} = {
        type: true,
        description: true,
        content: true,
        contents: [
          {
            detail: false,
            quantity: false,
            price_unit: false,
            tax_rate: false,
            rebate_value: true,
            rebate_quantity: true,
            unit: false
          }
        ]
      }

      setRequirements({list: true, value: [...requirements.value, newRequirimentValue], valid: [...requirements.valid, newRequirementValid]})

      setTimeout( () => {
        let scroll = document.getElementById('container-requirement-' + requirements.value.length)
        scroll?.scrollIntoView({behavior: 'smooth', block: 'center'})
      }, 100 )

      uploadSearch()
      uploadTooltip()
    }

    if (requirements.value.length === 0 && requirements.valid.length === 0) {
      legendValid('legend-requirements-required')
    }
  }

  const executeRemoveRequirement = (index: number) => {
    let tempRequirementsValue = requirements.value.filter((_, temp_index) => (temp_index !== index))
    let tempRequirementsValid = requirements.valid.filter((_, temp_index) => (temp_index !== index))
    setRequirements({list: (tempRequirementsValue.length > 0 && tempRequirementsValid.length > 0), value: tempRequirementsValue, valid: tempRequirementsValid})

    if (tempRequirementsValue.length === 0 && tempRequirementsValid.length === 0) {
      legendInvalid('legend-requirements-required')
    }

    restartFormRemoveRequirement(tempRequirementsValue)
    removeTooltip()
  }

  const executeAddContentToRequirement = (index: number) => {
    if (allowAddContentToRequirement(index)) {
      let newContentValue: { detail: string, reference: string, quantity: string, price_unit: string, tax_included: boolean, tax_rate: string, rebate_type: string, rebate_value: string, rebate_quantity: string, unit: string } = {
        detail: '',
        reference: '',
        quantity: '',
        price_unit: '',
        tax_rate: '',
        tax_included: false,
        rebate_type: 'none',
        rebate_value: '',
        rebate_quantity: '',
        unit: ''
      }
      let newContentValid: {detail: boolean, quantity: boolean, price_unit: boolean, tax_rate: boolean, rebate_value: boolean, rebate_quantity: boolean, unit: boolean} = {
        detail: false,
        quantity: false,
        price_unit: false,
        tax_rate: false,
        rebate_value: true,
        rebate_quantity: true,
        unit: false
      }
      let tempRequirementsValue = requirements.value.map((temp_item, temp_index) => {
        if (index === temp_index) {
          temp_item.contents.push(newContentValue)
        }
        return temp_item
      })
      let tempRequirementsValid = requirements.valid.map((temp_item, temp_index) => {
        if (index === temp_index) {
          temp_item.contents.push(newContentValid)
          temp_item.content = true
        }
        return temp_item
      })

      setRequirements({...requirements, value: tempRequirementsValue, valid: tempRequirementsValid})

      if (tempRequirementsValue[index].contents.length > 0 && tempRequirementsValid[index].contents.length > 0 && tempRequirementsValid[index].content) {
        legendValid('legend-requirement-contents-required-' + index)
      }

      uploadSearch()
      uploadTooltip()
      removeTooltip()
    }
  }

  const executeRemoveReferenceContentToRequirement = (index: number, sub_index: number) => {
    let tempRequirementsValue = requirements.value.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item.contents[sub_index].reference = ''
      }
      return temp_item
    })

    setRequirements({...requirements, value: tempRequirementsValue})
    removeTooltip()
  }

  const executeRemoveContentToRequirement = (index: number, sub_index: number) => {
    let tempRequirementsValue = requirements.value.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item.contents = temp_item.contents.filter((_, temp_sub_index) => (temp_sub_index !== sub_index))
      }
      return temp_item
    })
    let tempRequirementsValid = requirements.valid.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item.contents = temp_item.contents.filter((_, temp_sub_index) => (temp_sub_index !== sub_index))
        if (temp_item.contents.length === 0) {
          temp_item.content = false
        }
      }
      return temp_item
    })

    setRequirements({...requirements, value: tempRequirementsValue, valid: tempRequirementsValid})

    if (tempRequirementsValue[index].contents.length === 0 && tempRequirementsValid[index].contents.length === 0 && !tempRequirementsValid[index].content) {
      legendInvalid('legend-requirement-contents-required-' + index)
    }

    restartFormRemoveContentToRequirement(index, tempRequirementsValue)
    removeTooltip()
  }

  const executeShowModalSettingsCreateTax = (index: number, sub_index: number) => {
    setNewTax({...newTax, index: index, sub_index: sub_index})
    modalShow('modal-settings-create-tax')
  }

  const executeShowModalSettingsCreateUnit = (item: string, index: number, sub_index: number) => {
    setNewUnit({...newUnit, type: item, index: index, sub_index: sub_index})
    modalShow('modal-settings-create-unit')
  }

  function restartFormRemoveRequirement(items: any[]) {
    for (let i = 0; i < items.length; i++) {
      if (expressions && items[i].description.length > 0) {
        if (expressions.text.test(items[i].description)) {
          legendValidIcon('input-requirement-description-' + i, 'legend-requirement-description-valid-' + i)
        } else {
          legendInvalidIcon('input-requirement-description-' + i, 'legend-requirement-description-valid-' + i)
        }
      }
      if (items[i].contents.length === 0) {
        legendInvalid('legend-requirement-contents-required-' + i)
      } else {
        legendValid('legend-requirement-description-valid-' + i)
        restartFormRemoveContentToRequirement(i, items)
      }
    }
  }

  function restartFormRemoveContentToRequirement(index: number, items: any[]) {
    for (let i = 0; i < items[index].contents.length; i++) {
      if (expressions) {
        if (expressions.text.test(items[index].contents[i].detail)) {
          legendValidRequired('legend-requirement-content-detail-valid-' + index + i, 'legend-requirement-content-detail-required-' + index + i)
        } else {
          legendInvalidEmpty(items[index].contents[i].detail, 'legend-requirement-content-detail-valid-' + index + i, 'legend-requirement-content-detail-required-' + index + i)
        }
        if (expressions.quantity.test(items[index].contents[i].quantity)) {
          legendValidRequired('legend-requirement-content-quantity-valid-' + index + i, 'legend-requirement-content-quantity-required-' + index + i)
        } else {
          legendInvalidEmpty(items[index].contents[i].quantity, 'legend-requirement-content-quantity-valid-' + index + i, 'legend-requirement-content-quantity-required-' + index + i)
        }
        if (expressions.currency.test(items[index].contents[i].price_unit)) {
          legendValidRequired('legend-requirement-content-price-valid-' + index + i, 'legend-requirement-content-price-required-' + index + i)
        } else {
          legendInvalidEmpty(items[index].contents[i].price_unit, 'legend-requirement-content-price-valid-' + index + i, 'legend-requirement-content-price-required-' + index + i)
        }
        if (expressions.percentage.test(items[index].contents[index].rebate_value)) {
          legendValidRequired('legend-requirement-content-rebate-valid-' + index + i, 'legend-requirement-content-rebate-required-' + index + i)
        } else {
          legendInvalidEmpty(items[index].contents[index].rebate_value, 'legend-requirement-content-rebate-valid-' + index + i, 'legend-requirement-content-rebate-required-' + index + i)
        }
        if (expressions.percentage.test(items[index].contents[index].rebate_quantity)) {
          legendValidRequired('legend-requirement-content-rebate-valid-' + index + i, 'legend-requirement-content-rebate-required-' + index + i)
        } else {
          legendInvalidEmpty(items[index].contents[index].rebate_quantity, 'legend-requirement-content-rebate-valid-' + index + i, 'legend-requirement-content-rebate-required-' + index + i)
        }
      }
      console.log(items[index].contents[i])
      if (items[index].contents[i].unit.id.length > 0) {
        legendValid('legend-requirement-content-unit-required-' + index + i)
      } else {
        legendInvalid('legend-requirement-content-unit-required-' + index + i)
      }
      if (items[index].contents[i].tax_rate.length > 0) {
        legendValid('legend-requirement-content-tax-required-' + index + i)
      } else {
        legendInvalid('legend-requirement-content-tax-required-' + index + i)
      }
    }
  }

  function allowAddRequirement(): boolean {
    let allow: boolean = true

    for (let i = 0; i < requirements.valid.length; i++) {
      if (!requirements.valid[i].type || !requirements.valid[i].description || !requirements.valid[i].content) {
        if (!requirements.valid[i].content) {
          legendInvalid('legend-requirement-contents-required-' + i)
        }
        allow = false
      } else {
        allow = allowAddContentToRequirement(i)
      }
    }

    return allow
  }

  function allowAddContentToRequirement(index: number): boolean {
    let allow: boolean = true

    if (requirements.valid[index].contents.length > 0) {
      for (let i = 0; i < requirements.valid[index].contents.length; i++) {
        if (!requirements.valid[index].contents[i].detail || !requirements.valid[index].contents[i].quantity || !requirements.valid[index].contents[i].price_unit || !requirements.valid[index].contents[i].tax_rate || !requirements.valid[index].contents[i].rebate_value || !requirements.valid[index].contents[i].rebate_quantity || !requirements.valid[index].contents[i].unit) {
          if (!requirements.valid[index].contents[i].detail) {
            legendInvalidEmpty(requirements.value[index].contents[i].detail, 'legend-requirement-content-detail-valid-' + index + i, 'legend-requirement-content-detail-required-' + index + i)
          }
          if (!requirements.valid[index].contents[i].unit) {
            legendInvalid('legend-requirement-content-unit-required-' + index + i)
          }
          if (!requirements.valid[index].contents[i].quantity) {
            legendInvalidEmpty(requirements.value[index].contents[i].quantity, 'legend-requirement-content-quantity-valid-' + index + i, 'legend-requirement-content-quantity-required-' + index + i)
          }
          if (!requirements.valid[index].contents[i].price_unit) {
            legendInvalidEmpty(requirements.value[index].contents[i].price_unit, 'legend-requirement-content-price-valid-' + index + i, 'legend-requirement-content-price-required-' + index + i)
          }
          if (!requirements.valid[index].contents[i].tax_rate) {
            legendInvalid('legend-requirement-content-tax-required-' + index + i)
          }
          if (!requirements.valid[index].contents[i].rebate_value || !requirements.valid[index].contents[i].rebate_quantity) {
            legendInvalidEmpty(requirements.value[index].contents[i].rebate_value, 'legend-requirement-content-rebate-valid-' + index + i, 'legend-requirement-content-rebate-required-' + index + i)
          }
          allow = false
        }
      }
    }

    return allow
  }

  function calculatorAmount(): {sub_total: number, tax_total: number, rebate_total: number, after_rebate_total: number, total: number, requirement_prices: number[], requirement_content_prices: number[][]} {
    let requirementContentPrices: number[][] = []
    let requirementPrices: number[] = []
    let subTotal: number = 0
    let rebateTotal: number = 0
    let afterRebateTotal: number = 0
    let taxTotal: number = 0
    let total: number = 0

    for (let requirement of requirements.value) {
      let requirementContentPrice: number[] = []
      let requirementPrice: number = 0

      for (let content of requirement.contents) {
        let quantity: number = Number(content.quantity)
        let price: number = (content.tax_included && Number(content.tax_rate)) ? (Number(content.price_unit) / (1 + (Number(content.tax_rate) / 100))) : Number(content.price_unit)
        let rebate: number = (content.rebate_type === 'percentage' || content.rebate_type === 'fixed') ? (quantity >= Number(content.rebate_quantity)) ? (content.rebate_type === 'percentage') ? ((Number(content.rebate_value ) / 100) * price) : Number(content.rebate_value) : 0 : 0
        let tax: number =  (Number(content.tax_rate) || Number(content.tax_rate) === 0) ? (content.tax_included) ? (Number(content.price_unit) - price) : ((price - rebate) * (Number(content.tax_rate) / 100)) : 0

        requirementContentPrice.push((quantity * (price + tax - rebate)))
        requirementPrice += (quantity * (price + tax - rebate))
        subTotal += (quantity * price)
        rebateTotal += (quantity * rebate)
        afterRebateTotal += ((quantity * price) - (quantity * rebate))
        taxTotal += (quantity * tax)
        total += (quantity * (price + tax - rebate))
      }

      requirementContentPrices.push(requirementContentPrice)
      requirementPrices.push(requirementPrice)
    }

    return {sub_total: subTotal, tax_total: taxTotal, rebate_total: rebateTotal, after_rebate_total: afterRebateTotal, total: total, requirement_prices: requirementPrices, requirement_content_prices: requirementContentPrices}
  }

  function contentDetail(content: InventoryReference): string {
    return `${content.code.length > 0 ? `${content.code}, ` : ''}${content.display_name}${content.type === 'product' ? ` ${content.description}` : ''}`
  }

  function uploadSearch() {
    setSearchService({text: '', list: list.services})
    setSearchProduct({text: '', list: list.products})
  }

  useEffect( () => {
    let tempServices = (servicesForCompany) ? servicesForCompany.flatMap((item) => { return {
      type: 'service',
      id_service: item.id,
      id_product: '',
      id_variant: '',
      display_name: item.display_name,
      description: item.description,
      code: item.code,
      tax_rate: item.tax_rate,
      price: item.price,
      unit: item.unit,
      discount: item.discount
    }}) : []

    let tempProducts = (productsForCompany) ? productsForCompany.flatMap((item) => (item.variants.map((sub_item) => { return {
      type: 'product',
      id_service: '',
      id_product: item.id,
      id_variant: sub_item.id,
      display_name: item.display_name,
      description: sub_item.description_short,
      code: sub_item.code,
      tax_rate: sub_item.tax_rate,
      price: sub_item.price,
      unit: sub_item.unit,
      discount: sub_item.discount
    }}))) : []

      setList({services: tempServices, products: tempProducts})
      setSearchService({text: '', list: tempServices})
      setSearchProduct({text: '', list: tempProducts})
      uploadTooltip()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [servicesForCompany && productsForCompany])

  return (
    <>
      { settingsForCompany && currency &&
        <div className="card card-flush mb-5">
          <div className="card-header">
            <div className="card-title">
              <h4>{lang.labels.requirements}</h4>
            </div>
            <div className="card-toolbar">
              <div className="d-flex justify-content-end align-items-center">
                <button className="btn btn-sm btn-light-primary me-2" type="button" onClick={() => executeAddRequirement("service")}>
                  <div className="d-flex align-items-center">
                    <span className="svg-icon svg-icon-3 ms-0">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                        <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                        <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                      </svg>
                    </span>
                    {lang.labels.addService}
                  </div>
                </button>
                <button className="btn btn-sm btn-light-primary" type="button" onClick={() => executeAddRequirement("product")}>
                  <div className="d-flex align-items-center">
                    <span className="svg-icon svg-icon-3 ms-0">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                        <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                        <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                      </svg>
                    </span>
                    {lang.labels.addProduct}
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div className="card-body pt-0">
            <div className="dataTables_wrapper">
                <div className="table-responsive">
                  <table className="table align-middle g-1 mb-2">
                    <thead className="border-bottom">
                      <tr className="text-start text-muted text-uppercase fw-bolder fs-7">
                        <th className="min-w-225px text-nowrap">{lang.labels.detail}</th>
                        <th className="w-100px text-center text-nowrap">{lang.labels.unitMeasure}</th>
                        <th className="w-125px text-center text-nowrap">{lang.labels.quantity}</th>
                        <th className="w-150px text-center text-nowrap">{lang.labels.unitPrice} ({currency.symbol})</th>
                        <th className="w-100px text-center text-nowrap">{lang.labels.taxRate} (%)</th>
                        <th className="w-100px text-center text-nowrap">
                          <div className="d-flex align-items-center justify-content-center">
                            <span>{lang.labels.rebate}</span>
                            <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.selectTypeRebateAndEnterValueAndQuantity}><FaExclamationCircle /></i>
                          </div>
                        </th>
                        <th className="w-100px"></th>
                      </tr>
                    </thead>
                    { requirements.value.length > 0 && requirements.valid.length > 0
                      ?
                      <>
                        { requirements.value.map (( (item, index) => { return (
                          <tbody key={index} id={"container-requirement-" + index}>
                            <tr>
                              <td colSpan={7}>
                                <div className="d-flex align-items-start">
                                  <div className="d-flex align-items-center text-gray-700 text-uppercase fw-bold fs-6 mt-3 me-5">
                                    <span className="svg-icon svg-icon-2 me-1">
                                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM15 17C15 16.4 14.6 16 14 16H8C7.4 16 7 16.4 7 17C7 17.6 7.4 18 8 18H14C14.6 18 15 17.6 15 17ZM17 12C17 11.4 16.6 11 16 11H8C7.4 11 7 11.4 7 12C7 12.6 7.4 13 8 13H16C16.6 13 17 12.6 17 12ZM17 7C17 6.4 16.6 6 16 6H8C7.4 6 7 6.4 7 7C7 7.6 7.4 8 8 8H16C16.6 8 17 7.6 17 7Z" fill="black"/>
                                        <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black"/>
                                      </svg>
                                    </span>
                                    {inventoryTypeValue(lang, item.type)}
                                  </div>
                                  <div className="w-100">
                                    <input className="form-control form-control-solid px-3" type="text" name="description" autoComplete="off" placeholder={lang.labels.description} value={item.description} onChange={(event) => handleChangeDescriptionToRequirement(index, event)} onKeyUp={() => validateDescriptionToRequirement(index)} onBlur={() => validateDescriptionToRequirement(index)} />
                                    <AppLegend attribute={{validity: requirements.valid[index].description, name: "requirement-description", index: index, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                                  </div>
                                  <button className="btn btn-icon btn-flex btn-light-primary p-5 ms-2" type="button" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="left" data-bs-original-title={lang.labels.addContent} onClick={() => executeAddContentToRequirement(index)}>
                                    <span className="svg-icon svg-icon-3">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                                        <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                                        <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                                      </svg>
                                    </span>
                                  </button>
                                  <button className="btn btn-icon btn-flex btn-light-primary p-3 ms-2" type="button" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="left" data-bs-original-title={lang.labels.removeRequirement} onClick={() => executeRemoveRequirement(index)}>
                                    <span className="svg-icon svg-icon-3">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="black" />
                                        <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="black" />
                                        <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="black" />
                                      </svg>
                                    </span>
                                  </button>
                                </div>
                              </td>
                            </tr>
                            { item.contents.map (( (sub_item, sub_index) => { return (
                              <tr key={sub_index}>
                                <td valign="top">
                                  <Dropdown>
                                    <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                                      <span className="selection d-flex bg-light rounded">
                                        <textarea className="form-control form-control-solid px-2" name="detail" autoComplete="off" rows={1} placeholder={lang.labels.content} value={sub_item.detail} onChange={(event) => handleChangeDetailContentToRequirement(index, sub_index, event)} onKeyUp={() => validateDetailContentToRequirement(index, sub_index)} onBlur={() => validateDetailContentToRequirement(index, sub_index)} />
                                        { requirements.value[index].contents[sub_index].reference.length > 0 &&
                                          <span className="btn btn-icon btn btn-bg-secondary h-auto w-30px m-2 ms-1"  data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" data-bs-original-title={lang.labels.removeReference} onClick={() => executeRemoveReferenceContentToRequirement(index, sub_index)}>
                                            <i className="bi bi-x fs-3 text-primary" ></i>
                                          </span>
                                        }
                                      </span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                                      <span className="select2-dropdown select2-dropdown--below">
                                        <span className="select2-results">
                                          <ul className="select2-results__options" role="listbox">
                                            { (item.type === "service" ? searchService : searchProduct).list.length > 0
                                              ?
                                              <>
                                                { (item.type === "service" ? searchService : searchProduct).list.map (( (other_item, other_index) => { return (
                                                  <li key={other_index} className={`select2-results__option select2-results__option--selectable ${(other_item.type === "service" ? other_item.id_service : other_item.id_variant ) === sub_item.reference && "select2-results__option--selected"}`} role="option" aria-selected={(other_item.type === "service" ? other_item.id_service : other_item.id_variant ) === sub_item.reference }>
                                                    <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeSelectDetailContentToRequirement(index, sub_index, other_item)}>{contentDetail(other_item)}</Dropdown.Item>
                                                  </li>
                                                )}))}
                                              </>
                                              :
                                              <>
                                                { sub_item.reference.length > 0
                                                  ? 
                                                  <li className="select2-results__option select2-results__option--selectable select2-results__option--selected" role="option" aria-selected="true">
                                                    <Dropdown.Item bsPrefix="select2-results__option__text">{contentReferenceSelected(lang, (item.type === "service" ? list.services : list.products), sub_item.reference, item.type)}</Dropdown.Item>
                                                  </li>
                                                  :
                                                  <li className="select2-results__option select2-results__message py-2" role="alert" aria-live="assertive">
                                                    {lang.labels.noRecordsFound}
                                                  </li>
                                                  }
                                              </>
                                            }
                                          </ul>
                                        </span>
                                      </span>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                  <AppLegend attribute={{validity: requirements.valid[index].contents[sub_index].detail, name: "requirement-content-detail", index: index, sub_index: sub_index}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                                </td>
                                <td valign="top">
                                  <Dropdown>
                                    <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                                      <span className="selection">
                                        <span className="select2-selection select2-selection--single form-select form-select-solid bg-light px-2" style={{backgroundImage:(requirements.valid[index].contents[sub_index].unit) ? "none" : "", height: "42.55px"}} aria-disabled="false">
                                          <span className="select2-selection__rendered" role="textbox">
                                            <span className="select2-selection__placeholder">
                                              {requirements.valid[index].contents[sub_index].unit && `${measureUnitSelected(lang, settingsForCompany.measure_units, item.type, sub_item.unit)}`}
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                                      <span className="select2-dropdown select2-dropdown--below">
                                        <span className="select2-results">
                                          <ul className="select2-results__options" role="listbox">
                                            { requirements.value[index].type === "service" &&
                                              <>
                                                <li className="select2-results__option select2-results__option--selectable">
                                                  <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => executeShowModalSettingsCreateUnit(item.type, index, sub_index)}>
                                                    <div className="d-flex align-items-center">
                                                      <span className="svg-icon svg-icon-3 me-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                          <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                                                          <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                                                          <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                                                        </svg>
                                                      </span>
                                                      {lang.labels.addUnit}
                                                    </div>
                                                  </Dropdown.Item>
                                                </li>
                                                <li className={`select2-results__option select2-results__option--selectable ${unitServiceList(settingsForCompany.measure_units).length > 0 && "d-none"}`}>
                                                  <Dropdown.Item bsPrefix="select2-results__option__text">{lang.labels.selectOption}</Dropdown.Item>
                                                </li>
                                                { unitServiceList(settingsForCompany.measure_units).map (( (other_item, other_index) => { return (
                                                  <li key={other_index} className={`select2-results__option select2-results__option--selectable ${other_item.id === sub_item.unit && "select2-results__option--selected"}`} role="option" aria-selected={other_item.id === sub_item.unit}>
                                                    <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeUnitContentToRequirement(index, sub_index, other_item.id)}>{other_item.name}</Dropdown.Item>
                                                  </li>
                                                )}))}
                                              </>
                                            }
                                            { requirements.value[index].type === "product" &&
                                              <>
                                                <li className="select2-results__option select2-results__option--selectable">
                                                  <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => executeShowModalSettingsCreateUnit(item.type, index, sub_index)}>
                                                    <div className="d-flex align-items-center">
                                                      <span className="svg-icon svg-icon-3 me-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                          <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                                                          <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                                                          <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                                                        </svg>
                                                      </span>
                                                      {lang.labels.addUnit}
                                                    </div>
                                                  </Dropdown.Item>
                                                </li>
                                                <li className={`select2-results__option select2-results__option--selectable ${unitProductList(settingsForCompany.measure_units).length > 0 && "d-none"}`}>
                                                  <Dropdown.Item bsPrefix="select2-results__option__text">{lang.labels.selectOption}</Dropdown.Item>
                                                </li>
                                                { unitProductList(settingsForCompany.measure_units).map (( (other_item, other_index) => { return (
                                                  <li key={other_index} className={`select2-results__option select2-results__option--selectable ${other_item.id === sub_item.unit && "select2-results__option--selected"}`} role="option" aria-selected={other_item.id === sub_item.unit}>
                                                    <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeUnitContentToRequirement(index, sub_index, other_item.id)}>{other_item.name}</Dropdown.Item>
                                                  </li>
                                                )}))}
                                              </>
                                            }
                                          </ul>
                                        </span>
                                      </span>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                  <AppLegend attribute={{validity: requirements.valid[index].contents[sub_index].unit, name: "requirement-content-unit", index: index, sub_index: sub_index}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                                </td>
                                <td valign="top">
                                  <input className="form-control form-control-solid px-2" type="text" name="quantity" autoComplete="off" value={sub_item.quantity} onChange={(event) => handleChangeQuantityContentToRequirement(index, sub_index, event)} onKeyUp={() => validateQuantityContentToRequirement(index, sub_index)} onBlur={() => validateQuantityContentToRequirement(index, sub_index)} />
                                  <AppLegend attribute={{validity: requirements.valid[index].contents[sub_index].quantity, name: "requirement-content-quantity", index: index, sub_index: sub_index}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                                </td>
                                <td valign="top">
                                  <div className="position-relative">
                                    <input className="form-control form-control-solid ps-2 pe-20" type="text" name="price" autoComplete="off" value={sub_item.price_unit} onChange={(event) => handleChangePriceContentToRequirement(index, sub_index, event)} onKeyUp={() => validatePriceContentToRequirement(index, sub_index)} onBlur={() => validatePriceContentToRequirement(index, sub_index)} />
                                    <button className="btn btn-bg-secondary btn-flex flex-center position-absolute translate-middle-y top-50 end-0 h-25px w-55px me-2" onClick={() => handleChangeTaxIncludeContentToRequirement(index, sub_index, sub_item.tax_included)}>
                                      <span className="btn-label text-primary text-uppercase fw-bolder fs-7" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" data-bs-original-title={sub_item.tax_included ? lang.labels.thisOptionIncludeTaxPrice : lang.labels.thisOptionNotIncludeTaxPrice} onClick={removeTooltip}>
                                        {sub_item.tax_included ? lang.labels.net : lang.labels.gross}
                                      </span>
                                    </button>
                                  </div>
                                  <AppLegend attribute={{validity: requirements.valid[index].contents[sub_index].price_unit, name: "requirement-content-price", index: index, sub_index: sub_index}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                                </td>
                                <td valign="top">
                                  <Dropdown>
                                    <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                                      <span className="selection">
                                        <span className="select2-selection select2-selection--single form-select form-select-solid bg-light px-2" style={{backgroundImage:(requirements.valid[index].contents[sub_index].tax_rate) ? "none" : "", height: "42.55px"}} aria-disabled="false">
                                          <span className="select2-selection__rendered" role="textbox">
                                            <span className="select2-selection__placeholder text-capitalize">
                                              {requirements.valid[index].contents[sub_index].tax_rate && `${sub_item.tax_rate}`}
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                                      <span className="select2-dropdown select2-dropdown--below">
                                        <span className="select2-results">
                                          <ul className="select2-results__options" role="listbox">
                                            <li className="select2-results__option select2-results__option--selectable d-none">
                                              <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => executeShowModalSettingsCreateTax(index, sub_index)}>
                                                <div className="d-flex align-items-center">
                                                  <span className="svg-icon svg-icon-3 me-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                                                      <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                                                      <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                                                    </svg>
                                                  </span>
                                                  {lang.labels.addTax}
                                                </div>
                                              </Dropdown.Item>
                                            </li>
                                            <li className={`select2-results__option select2-results__option--selectable ${settingsForCompany.tax_fees.length > 0 && "d-none"}`}>
                                              <Dropdown.Item bsPrefix="select2-results__option__text">{lang.labels.selectOption}</Dropdown.Item>
                                            </li>
                                            { settingsForCompany.tax_fees.map (( (other_item, other_index) => { return (
                                              <li key={other_index} className={`select2-results__option select2-results__option--selectable ${other_item === sub_item.tax_rate && "select2-results__option--selected"}`} role="option" aria-selected={other_item === sub_item.tax_rate}>
                                                <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeTaxRateContentToRequirement(index, sub_index, other_item)}>{other_item}</Dropdown.Item>
                                              </li>
                                            )}))}
                                          </ul>
                                        </span>
                                      </span>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                  <AppLegend attribute={{validity: requirements.valid[index].contents[sub_index].tax_rate, name: "requirement-content-tax", index: index, sub_index: sub_index}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                                </td>
                                <td valign="top">
                                  <div className="position-relative">
                                    <input className="form-control form-control-solid fs-8 h-20px ps-2 pe-15" type="text" name="rebate-value" autoComplete="off" value={sub_item.rebate_value} disabled={!(sub_item.rebate_type === 'percentage' || sub_item.rebate_type === 'fixed')} onChange={(event) => handleChangeRebateValueContentToRequirement(index, sub_index, event)} onKeyUp={() => validateRebateContentToRequirement(index, sub_index)} onBlur={() => validateRebateContentToRequirement(index, sub_index)} />
                                    <button className="btn btn-bg-secondary btn-flex flex-center position-absolute translate-middle-y top-50 end-0 h-100 w-35px" onClick={() => handleChangeRebateTypeContentToRequirement(index, sub_index, sub_item.rebate_type)}>
                                      <span className="btn-label text-primary fw-bolder fs-7" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" data-bs-original-title={(sub_item.rebate_type === "percentage") ? lang.labels.thisOptionRebateIsPercentage : (sub_item.rebate_type === "fixed") ? lang.labels.thisOptionRebateIsFixedAmount : lang.labels.thisOptionRebateIsNone} onClick={removeTooltip}>
                                        {(sub_item.rebate_type === "percentage") ? '%' : (sub_item.rebate_type === "fixed") ? currency.symbol : "-0-"}
                                      </span>
                                    </button>
                                  </div>
                                  <div className="position-relative mt-1">
                                    <input className="form-control form-control-solid fs-8 h-20px ps-2 pe-10" type="text" name="rebate-quantity" autoComplete="off" value={sub_item.rebate_quantity} disabled={!(sub_item.rebate_type === 'percentage' || sub_item.rebate_type === 'fixed')} onChange={(event) => handleChangeRebateQuantityContentToRequirement(index, sub_index, event)} onKeyUp={() => validateRebateContentToRequirement(index, sub_index)} onBlur={() => validateRebateContentToRequirement(index, sub_index)} />
                                    <div className="position-absolute translate-middle-y top-50 end-0 me-2">
                                      <i className="d-flex align-items-start" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.quantityMinimumForRebate}><FaExclamationCircle /></i>
                                    </div>  
                                  </div>
                                  <AppLegend attribute={{validity: requirements.valid[index].contents[sub_index].rebate_value, name: "requirement-content-rebate", index: index, sub_index: sub_index}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                                </td>
                                <td valign="top">
                                  <div className="d-flex justify-content-end">
                                    <label className="badge badge-lg badge-light-primary d-flex align-items-center justify-content-end text-nowrap text-gray-800 w-100 ps-0">
                                      {currency.symbol} {moneyFormat(calculatorAmount().requirement_content_prices[index][sub_index])}
                                    </label>
                                    <button className="btn btn-icon btn-flex btn-primary btn-light-primary ms-2" type="button" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="left" data-bs-original-title={lang.labels.removeContent} onClick={() => executeRemoveContentToRequirement(index, sub_index)}>
                                      <span className="svg-icon svg-icon-3 p-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                          <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="black" />
                                          <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="black" />
                                          <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="black" />
                                        </svg>
                                      </span>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            )}))}
                            <tr>
                              <td colSpan={7}>
                                <Legend property={requirements.valid[index].content.toString()}>
                                  <div id={"legend-requirement-contents-required-" + index} className="fv-plugins-message-container invalid-feedback d-none">
                                    <div data-field="contents" data-validator="notEmpty">{lang.labels.contentsToRequirementIsRequired}</div>
                                  </div>
                                </Legend>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={7}>
                                <span className="d-flex justify-content-end">
                                  <div className="text-uppercase text-gray-700 fw-bold fs-8">{lang.labels.priceTotal}:</div>
                                  <div className="text-end fw-bolder ms-10">{currency.symbol} {item.contents.length > 0 ? moneyFormat(calculatorAmount().requirement_prices[index]) : moneyFormat(0)}</div>
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={7}></td>
                            </tr>
                          </tbody>
                        )}))}
                      </>
                      :
                      <tbody>
                        <tr>
                          <td valign="top" colSpan={7}>
                            <div className="form-control form-control-sm form-control-solid text-center text-uppercase">{lang.labels.noRecords}</div>
                          </td>
                        </tr>
                      </tbody>
                    }
                    <tfoot>
                      <tr>
                        <td colSpan={7}>
                          <Legend property={requirements.list.toString()}>
                            <div id="legend-requirements-required" className="fv-plugins-message-container invalid-feedback d-none">
                              <div data-field="requirements" data-validator="notEmpty">{lang.labels.requirementsIsRequired}</div>
                            </div>
                          </Legend>
                        </td>
                      </tr>
                      <tr className="border-top">
                        <td colSpan={7}>
                          <div className="d-flex justify-content-end">
                            <div className="mw-500px">
                              <div className="border-bottom mt-3">
                                <div className="d-flex align-items-center justify-content-end">
                                  <span className="text-dark text-uppercase fw-bolder">{lang.labels.calculator}</span>
                                  <span className="svg-icon svg-icon-1 ms-3" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="left" data-bs-original-title={lang.labels.calculatedValuesAreReferential}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path opacity="0.3" d="M3 3V17H7V21H15V9H20V3H3Z" fill="black"/>
                                      <path d="M20 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H20C20.6 2 21 2.4 21 3V21C21 21.6 20.6 22 20 22ZM19 4H4V8H19V4ZM6 18H4V20H6V18ZM6 14H4V16H6V14ZM6 10H4V12H6V10ZM10 18H8V20H10V18ZM10 14H8V16H10V14ZM10 10H8V12H10V10ZM14 18H12V20H14V18ZM14 14H12V16H14V14ZM14 10H12V12H14V10ZM19 14H17V20H19V14ZM19 10H17V12H19V10Z" fill="black"/>
                                    </svg>
                                  </span>
                                </div>
                              </div>
                              <div className="d-flex flex-stack px-5 py-2 border-bottom">
                                <div className="fw-bold pe-20 text-gray-700 fs-7">{lang.labels.subTotal}:</div>
                                <div className="text-end fw-bolder ps-20 text-gray-800">{currency.symbol} {moneyFormat(calculatorAmount().sub_total)}</div>
                              </div>
                              <div className="d-flex flex-stack px-5 py-2 border-bottom">
                                <div className="fw-bold pe-20 text-gray-700 fs-7">{lang.labels.rebateTotal}:</div>
                                <div className="text-end fw-bolder ps-20 text-gray-800">{currency.symbol} {moneyFormat(calculatorAmount().rebate_total)}</div>
                              </div>
                              <div className="d-flex flex-stack px-5 py-2 border-bottom">
                                <div className="fw-bold pe-20 text-gray-700 fs-7">{lang.labels.totalAfterRebates}:</div>
                                <div className="text-end fw-bolder ps-20 text-gray-800">{currency.symbol} {moneyFormat(calculatorAmount().after_rebate_total)}</div>
                              </div>
                              <div className="d-flex flex-stack px-5 py-2 border-bottom">
                                <div className="fw-bold pe-20 text-gray-700 fs-7">{lang.labels.taxTotal}:</div>
                                <div className="text-end fw-bolder ps-20 text-gray-800">{currency.symbol} {moneyFormat(calculatorAmount().tax_total)}</div>
                              </div>
                              <div className="d-flex flex-stack px-5 py-2 border-bottom">
                                <div className="fw-bold pe-20 text-gray-700 fs-7">{lang.labels.total}:</div>
                                <div className="text-end fw-bolder ps-20 text-gray-800">{currency.symbol} {moneyFormat(calculatorAmount().total)}</div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
            </div>
            
          </div>
        </div>
      }
      <AppModalSettingCreateTax companyForUser={companyForUser} data={newTax} variant={null} requirements={requirements} setData={setNewTax} setTaxRate={null} setVariant={null} setRequirements={setRequirements} loadSettingsForCompany={loadSettingsForCompany}></AppModalSettingCreateTax>
      <AppModalSettingCreateUnit companyForUser={companyForUser} data={newUnit} variant={null} requirements={requirements} setData={setNewUnit} setUnitMeasure={null} setVariant={null} setRequirements={setRequirements} loadSettingsForCompany={loadSettingsForCompany}></AppModalSettingCreateUnit>
    </>
  )
};

export default AppSaleFormRequirements;
