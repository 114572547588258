import React from 'react';
import { StateLanguage } from '../languages/config/StateLanguage';

export interface AppLoadingProps {};

const AppLoading: React.FunctionComponent<AppLoadingProps> = () => {
  const {lang} = StateLanguage()

  return (
    <div className="d-flex justify-content-center align-items-center w-100 h-200px">
      <div className="spinner-border" role="status">
        <span className="visually-hidden">{lang.labels.loading}</span>
      </div>
    </div>
  )
}

export default AppLoading;
