import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FaExclamationCircle } from 'react-icons/fa';
import { format } from 'date-fns';
import TextareaAutosize from 'react-textarea-autosize';

import { UseOutletContextPurchase } from '../Purchase';
import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { Currency } from '../../../../models/currency.model';
import { Contact } from '../../../../models/contact.model';
import { legendInvalid, legendValid } from '../../../../tools/legend.data.entry.tool';
import { modalShow } from '../../../../tools/modal.tool';
import { uploadTooltip } from '../../../../tools/tooltip.tool';
import { contactSelected, treasuryAccountSelected } from '../../../../scripts/list.selected.item.script';
import { evaluateLegendValidRequired } from '../../../../scripts/validate.legend.script';
import { currencyList, currencyValue } from '../../../../libraries/currency.library';
import { paymentMethodList, paymentMethodValue } from '../../../../libraries/payment.library';
import { expressions } from '../../../../libraries/regular.expressions.library';

import AppLegend from '../../../../components/element/Legend';
import AppModalContactCreate from '../../../../components/modal/ModalContactCreate';

export interface AppPurchaseRecordCreateProps {
  purchase: string,
  provider: {value: string, object: any, valid: boolean} | null,
  concerning: {value: string, valid: boolean},
  date: {value: string, valid: boolean},
  currency: {value: string, object: any, valid: boolean},
  treasuryAccount: {value: string, valid: boolean},
  paymentMethod: {value: string, valid: boolean} | null,
  setProvider: Dispatch<SetStateAction<{value: string, object: any, valid: boolean}>> | null,
  setConcerning: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setDate: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setCurrency: Dispatch<SetStateAction<{value: string, object: any, valid: boolean}>>,
  setTreasuryAccount: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setPaymentMethod: Dispatch<SetStateAction<{value: string, valid: boolean}>> | null
};

const AppPurchaseRecordCreate: React.FunctionComponent<AppPurchaseRecordCreateProps> = ({purchase, provider, concerning, date, currency, treasuryAccount, paymentMethod, setProvider, setConcerning, setDate, setCurrency, setPaymentMethod, setTreasuryAccount}) => {
  const {userLoggedIn, companyForUser, settingsForCompany, treasuryAccountsForCompany, contactsForCompany, loadContactsForCompany} = UseOutletContextPurchase()
  const {lang} = StateLanguage()

  const labels: {[key: string]: {title: string; desc: string; date: string}} = {
    order: {title: lang.labels.newProviderOrder, desc: lang.labels.pleaseEnterThFollowingDataToCreateANewProviderOrder, date: lang.labels.providerOrderDate},
    invoice: {title: lang.labels.newProvederInvoice, desc: lang.labels.pleaseEnterTheFollowingDataToCreateANewProviderInvoice, date: lang.labels.providerInvoiceDate},
    expense: {title: lang.labels.newExpense, desc: lang.labels.pleaseEnterTheFollowingDataToCreateANewExpense, date: lang.labels.expenseDate}
  }

  const [searchContact, setSearchContact] = useState<{text: string, list: Contact[]}>({text: '', list: []})

  const handleChangeProvider = (item: Contact) => {
    if (provider && setProvider) {
      setProvider({...provider, value: item.data.id, object: item, valid: true})
      legendValid('legend-provider-required')
    }
  }

  const handleChangeConcerning = (event: React.ChangeEvent <HTMLFormElement | HTMLTextAreaElement>) => {
    setConcerning({...concerning, value: event.target.value})
  }

  const handleChangeDate = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    if (event.target.value.length > 0) {
      setDate({...date, value: event.target.value, valid: true})
      legendValid('legend-date-required')
    } else {
      setDate({...date, value: event.target.value, valid: false})
      legendInvalid('legend-date-required')
    }
  }

  const handleChangeCurrency = (item: Currency) => {
    setCurrency({...currency, value: item.code, object: item, valid: true})
    legendValid('legend-currency-required')
  }

  const handleChangeTreasuryAccount = (item: string) => {
    setTreasuryAccount({...treasuryAccount, value: item, valid: true})
    legendValid('legend-treasury-account-required')
  }

  const handleChangePaymentMethod = (item: string) => {
    if (paymentMethod && setPaymentMethod) {
      setPaymentMethod({...paymentMethod, value: item, valid: true})
      legendValid('legend-payment-method-required')
    }
  }

  const handleChangeSearchContact = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let text: string = event.target.value
    let list: Contact[] = []

    if (contactsForCompany) {
      if (text.length > 0) {
        for (let contact of contactsForCompany) {
          let name: string = (contact.type === 'person') ? (`${contact.data.first_name} ${contact.data.last_name}`).toLowerCase() : contact.data.name.toLowerCase()
          if (name.indexOf(text.toLowerCase()) !== -1) {
            list.push(contact)
          }
        }
      } else {
        list = contactsForCompany
      }
    }

    setSearchContact({text: text, list: list})
  }

  const validateConcerning = () => {
    evaluateLegendValidRequired(expressions.text, concerning, setConcerning, 'legend-concerning-valid', 'legend-concerning-required')
  }

  const executeShowModalContactCreate = () => {
    modalShow('modal-contact-create')
  }

  function maxDate(): string {
    return format((new Date()), 'yyyy-MM-dd')
  }

  function uploadDropdown() {
    if (contactsForCompany) {
      setSearchContact({text: '', list: contactsForCompany})
    } else {
      setSearchContact({text: '', list: []})
    }
  }

  useEffect( () => {
    setCurrency({value: '', object: null, valid: false})
    setTreasuryAccount({value: '', valid: false})

    if (provider && setProvider) {
      setProvider({value: '', object: null, valid: false})
    }

    if (companyForUser) {
      setCurrency({value: companyForUser.currency.code, object: companyForUser.currency, valid: true})
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyForUser])

  useEffect( () => {
    setTreasuryAccount({value: '', valid: false})

    if (treasuryAccountsForCompany) {
      if (treasuryAccountsForCompany.length > 0 && treasuryAccountsForCompany.find((item) => (item.default === true))) {
        setTreasuryAccount({value: treasuryAccountsForCompany.find((item) => (item.default === true))!.id, valid: true})
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treasuryAccountsForCompany])

  useEffect( () => {
    setDate({value: format((new Date()), 'yyyy-MM-dd'), valid: true})
    uploadTooltip()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      { companyForUser && settingsForCompany && contactsForCompany && treasuryAccountsForCompany &&
        <div className="card card-flush mb-5">
          <div className="card-header">
            <div className="card-title">
              <h2>{labels[purchase].title}</h2>
            </div>
          </div>
          <div className="card-body pt-0">
            <div className="fw-bold fs-6 mb-5">{labels[purchase].desc}</div>
            { (purchase === "order" || purchase === "invoice") && provider && setProvider &&
              <div className="row mb-0 mb-lg-2">
                <label className="col-lg-3 col-form-label required">{lang.labels.provider}</label>
                <div className="col-lg-9">
                  <div className="d-flex align-self-center">
                    <div className="flex-grow-1 me-2">
                      <Dropdown onToggle={uploadDropdown}>
                        <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                          <span className="selection">
                            <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                              <span className="select2-selection__rendered" role="textbox">
                                <span className="select2-selection__placeholder">
                                  {provider.valid ? contactSelected(lang, contactsForCompany, provider.value) : lang.labels.selectOption}
                                </span>
                              </span>
                            </span>
                          </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                          <span className="select2-dropdown select2-dropdown--below">
                            <span className="select2-search select2-search--dropdown">
                              <input className="select2-search__field" type="text" name="contact" value={searchContact.text} onChange={handleChangeSearchContact} />
                            </span>
                            <span className="select2-results">
                              <ul className="select2-results__options" role="listbox">
                                { searchContact.list.length > 0
                                  ?
                                  <>
                                    { searchContact.list.map (( (item, index) => { return (
                                      <li key={index} className={`select2-results__option select2-results__option--selectable ${provider.value === item.data.id && "select2-results__option--selected"}`} role="option" aria-selected={provider.value === item.data.id}>
                                        <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeProvider(item)}>{item.type === "person" ? `${item.data.first_name} ${item.data.last_name}` : `${item.data.name}`}</Dropdown.Item>
                                      </li>
                                    )}))}
                                  </>
                                  :
                                  <li className="select2-results__option select2-results__message" role="alert" aria-live="assertive">{lang.labels.noResultsFound}</li>
                                }
                              </ul>
                            </span>
                          </span>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="flex-shrink-0">
                      <button className="btn btn-sm btn-light-primary h-100" type="button" onClick={executeShowModalContactCreate}>
                        <div className="d-flex align-items-center">
                          <span className="svg-icon svg-icon-3 ms-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                              <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                              <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                            </svg>
                          </span>
                          {lang.labels.addContact}
                        </div>
                      </button>
                    </div>
                  </div>
                  <div className="form-text text-justify">{lang.labels.searchContact}</div>
                  <AppLegend attribute={{validity: provider.valid, name: "provider", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                </div>
              </div>
            }
            <div className="row mb-0 mb-lg-2">
              <label className="col-lg-3 col-form-label required">{lang.labels.concerning}</label>
              <div className="col-lg-9">
                <TextareaAutosize className="form-control form-control-solid" name="concerning" minRows={2} autoComplete="off" value={concerning.value} onChange={handleChangeConcerning} onKeyUp={validateConcerning} onBlur={validateConcerning} />
                <AppLegend attribute={{validity: concerning.valid, name: "concerning", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-0 mb-lg-2">
              <label className="col-lg-3 col-form-label">
                <div className="d-flex align-items-center">
                  <span className="required">{labels[purchase].date}</span>
                  <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.dateOfQuote}><FaExclamationCircle /></i>
                </div>
              </label>
              <div className="col-lg-9">
                <input className="form-control form-control-solid" type="date" name="date" max={maxDate()} value={date.value} onChange={handleChangeDate} />
                <AppLegend attribute={{validity: date.valid, name: "date", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-0 mb-lg-2">
              <label className="col-lg-3 col-form-label">
                <div className="d-flex align-items-center">
                  <span className="required">{lang.labels.currency}</span>
                  <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.theDefaultCurrencyIsTheCompanys}><FaExclamationCircle /></i>
                </div>
              </label>
              <div className="col-lg-9">
                <Dropdown>
                  <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                    <span className="selection">
                      <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                        <span className="select2-selection__rendered" role="textbox">
                          <span className="select2-selection__placeholder">
                            {currency.valid ? currencyValue(lang.code, currency.value) : lang.labels.selectOption}
                          </span>
                        </span>
                      </span>
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                    <span className="select2-dropdown select2-dropdown--below">
                      <span className="select2-results">
                        <ul className="select2-results__options" role="listbox">
                          { currencyList(lang.code).map (( (item, index) => { return (
                            <li key={index} className={`select2-results__option select2-results__option--selectable ${item.code === currency.value && "select2-results__option--selected"}`} role="option" aria-selected={item.code === currency.value}>
                              <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeCurrency(item)}>{item.code}: {item.name} ({item.symbol})</Dropdown.Item>
                            </li>
                          )}))}
                        </ul>
                      </span>
                    </span>
                  </Dropdown.Menu>
                </Dropdown>
                <AppLegend attribute={{validity: currency.valid, name: "currency", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className={`row mb-0 ${purchase === "expense" && paymentMethod && setPaymentMethod && "mb-lg-2"}`}>
              <label className="col-lg-3 col-form-label">
                <div className="d-flex align-items-center">
                  <span className="required">{lang.labels.treasuryAccount}</span>
                  <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.theDefaultBankAccountIsTheCompanys}><FaExclamationCircle /></i>
                </div>
              </label>
              <div className="col-lg-9">
                <Dropdown>
                  <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                    <span className="selection">
                      <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                        <span className="select2-selection__rendered" role="textbox">
                          <span className="select2-selection__placeholder">
                            {treasuryAccount.valid ? treasuryAccountSelected(lang, treasuryAccountsForCompany, treasuryAccount.value) : lang.labels.selectOption}
                          </span>
                        </span>
                      </span>
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                    <span className="select2-dropdown select2-dropdown--below">
                      <span className="select2-results">
                        <ul className="select2-results__options" role="listbox">
                          <li className={`select2-results__option select2-results__option--selectable ${treasuryAccountsForCompany.length > 0 && "d-none"}`}>
                            <Dropdown.Item bsPrefix="select2-results__option__text">{lang.labels.selectOption}</Dropdown.Item>
                          </li>
                          { treasuryAccountsForCompany.map (( (item, index) => { return (
                            <li key={index} className={`select2-results__option select2-results__option--selectable ${item.id === treasuryAccount.value && "select2-results__option--selected"}`} role="option" aria-selected={item.id === treasuryAccount.value}>
                              <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeTreasuryAccount(item.id)}>{item.name}</Dropdown.Item>
                            </li>
                          )}))}
                        </ul>
                      </span>
                    </span>
                  </Dropdown.Menu>
                </Dropdown>
                <AppLegend attribute={{validity: treasuryAccount.valid, name: "treasury-account", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            { purchase === "expense" && paymentMethod && setPaymentMethod &&
              <div className="row">
                <label className="col-lg-3 col-form-label required">{lang.labels.paymentMethod}</label>
                <div className="col-lg-9">
                  <Dropdown>
                    <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                      <span className="selection">
                        <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                          <span className="select2-selection__rendered" role="textbox">
                            <span className="select2-selection__placeholder">
                              {paymentMethod.valid ? paymentMethodValue(lang, paymentMethod.value) : lang.labels.selectOption}
                            </span>
                          </span>
                        </span>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                      <span className="select2-dropdown select2-dropdown--below">
                        <span className="select2-results">
                          <ul className="select2-results__options" role="listbox">
                            { paymentMethodList(lang).map (( (item, index) => { return (
                              <li key={index} className={`select2-results__option select2-results__option--selectable ${item.code === paymentMethod.value && "select2-results__option--selected"}`} role="option" aria-selected={item.code === paymentMethod.value}>
                                <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangePaymentMethod(item.code)}>{item.display}</Dropdown.Item>
                              </li>
                            )}))}
                          </ul>
                        </span>
                      </span>
                    </Dropdown.Menu>
                  </Dropdown>
                  <AppLegend attribute={{validity: paymentMethod.valid, name: "payment-method", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                </div>
              </div>
            }
          </div>
        </div>
      }
      { provider && setProvider &&
        <AppModalContactCreate userLoggedIn={userLoggedIn} companyForUser={companyForUser} contactsForCompany={contactsForCompany} languageFile={null} qrTtypeFile={null} setContact={setProvider} setLanguageFile={null} setQrTtypeFile={null} loadContactsForCompany={loadContactsForCompany}></AppModalContactCreate>
      }
    </>
  )
};

export default AppPurchaseRecordCreate;
