import { Dispatch, SetStateAction } from "react"
import { legendInvalidRequired, legendValidInvalidRequiredRestart, legendValidRequired } from "../tools/legend.data.entry.tool"
import { extractBase64 } from "./extract.base.64.script"

export function uploadImage(archive: any, set_image: Dispatch<SetStateAction<{value: string, file: any, valid: boolean}>>, container_type: string, container_size: string) {
  let archiveType: string[] = ['image/png', 'image/jpeg', 'image/jpg']
  let archiveSize: number = 1048576

  if (!(archiveType === undefined || (archiveType && archiveType.includes(archive.type)))) {
    set_image({value: '', file: null, valid: false})
    legendInvalidRequired(container_type, container_size)
  } else if (!((archiveSize !== 0 && archive.size <= archiveSize) || archiveSize === 0)) {
    set_image({value: '', file: null, valid: false})
    legendInvalidRequired(container_size, container_type)
  } else {
    extractBase64(archive).then( (image: any) => {
      set_image({value: image.base, file: archive, valid: true})
      legendValidRequired(container_type, container_size)
    })
  }
}

export function removeUploadedImage(set_image: Dispatch<SetStateAction<{value: string, file: any, valid: boolean}>>, container_type: string, container_size: string) {
  set_image({value: '', file: null, valid: true})
  legendValidInvalidRequiredRestart(container_type, container_size)
}
