import { Language } from "../languages/labels/language.labels"

export function qrTemplateList(lang: Language): {code: string, display: string}[] {
  return [
    { code: 'qr-iban', display: lang.labels.qrIbanAndQrReference },
    { code: 'scor', display: lang.labels.ibanAndStructuredCreditorReference },
    { code: 'no-reference', display: lang.labels.ibanWithoutReference },
    { code: 'none', display: lang.labels.none }
  ]
}

export function qrTemplateValue(lang: Language, code: string): string {
  let qrTemplate = qrTemplateList(lang).find((item) => (item.code === code))
  return qrTemplate ? qrTemplate.display : lang.labels.undefined
}
