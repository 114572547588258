export function percentageCalculate(total: number, amount: number): string {
  let percentage: number = (amount * 100) / total
  return `${percentage === 100 ? percentage : percentage.toFixed(2)}%`;
}

export function accumulatedAmountDocuments(documents: any[]): {subtotal: number, rebate: number, tax: number, total: number, paid: number, debt: number} {
  let amountSubtotal: number = 0
  let amountRebate: number = 0
  let amountTax: number = 0
  let amountTotal: number = 0
  let amountPaid: number = 0
  let amountDebt: number = 0

  for (let document of documents) {
    amountSubtotal += document.amount.sub_total
    amountRebate += document.amount.rebate_total
    amountTax += document.amount.tax_total
    amountTotal += document.amount.total

    if (document.amount.paid) { amountPaid += document.amount.paid }
    if (document.amount.debt) { amountDebt += document.amount.debt }
  }

  return {subtotal: amountSubtotal, rebate: amountRebate, tax: amountTax, total: amountTotal, paid: amountPaid, debt: amountDebt}
}
