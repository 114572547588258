import { Language } from "../languages/labels/language.labels";

export function vatDebtMethodList(lang: Language): {code: string, display: string}[] {
  return [
    { code: 'invoicing', display: lang.labels.invoicing },
    { code: 'cashing', display: lang.labels.cashing }
  ]
}

export function vatDebtMethodValue(lang: Language, code: string): string {
  let vatDebtMethod = vatDebtMethodList(lang).find((item) => (item.code === code))
  return vatDebtMethod ? vatDebtMethod.display : lang.labels.undefined
}
