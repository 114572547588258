import { Language } from "../languages/labels/language.labels";

export function treasuryTypeList(lang: Language): {code: string, display: string}[] {
  return [
    { code: 'cash', display: lang.labels.cashAccount },
    { code: 'postal', display: lang.labels.postalAccount },
    { code: 'bank', display: lang.labels.bankAccount }
  ]
}

export function treasuryAccountTypeValue(lang: Language, code: string): string {
  let treasuryAccountType = treasuryTypeList(lang).find((item) => (item.code === code))
  return treasuryAccountType ? treasuryAccountType.display : lang.labels.undefined
}
