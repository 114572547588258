import { Language } from "../languages/labels/language.labels";

export function expirationList(lang: Language): {code: string, display: string}[] {
  return [
    { code: 'expired', display: lang.labels.expired },
    { code: 'last day', display: lang.labels.lastDay },
    { code: 'in time', display: lang.labels.inTime }
  ]
}

export function expirationValue(lang: Language, code: string): string {
  let expiration = expirationList(lang).find((item) => (item.code === code))
  return expiration ? expiration.display : lang.labels.undefined
}
