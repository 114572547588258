import { Language } from "../languages/labels/language.labels"

export function languageList(lang: Language): {code: string, display: string}[] {
  return [
    { code: 'EN', display: lang.labels.english },
    { code: 'FR', display: lang.labels.french },
    { code: 'IT', display: lang.labels.italian },
    { code: 'DE', display: lang.labels.german }
  ]
}

export function languageValue(lang: Language, code: string): string {
  let language = languageList(lang).find((item) => (item.code === code))
  return language ? language.display : lang.labels.undefined
}
