import { Language } from "../languages/labels/language.labels";
import { Currency } from "../models/currency.model"
import { Discount } from "../models/discount.model"

export function discountType(lang: Language, discount: Discount): string {
  switch (discount.type) {
    case ('percentage'):
      return lang.labels.percentage.toLowerCase()
    case ('fixed'):
      return lang.labels.fixed.toLowerCase()
    case ('none'):
      return lang.labels.none.toLowerCase()
    default:
      return lang.labels.undefined.toLowerCase()
  }
}

export function discountDescription(lang: Language, currency: Currency, discount: Discount): string {
  switch (discount.type) {
    case ('percentage'):
      return `% ${discount.value.toFixed(0)} ≥ ${discount.quantity}`
    case ('fixed'):
      return `${currency.symbol} ${discount.value.toFixed(0)} ≥ ${discount.quantity}`
    case ('none'):
      return lang.labels.none.toLowerCase()
    default:
      return lang.labels.undefined.toLowerCase()
  }
}
