import React, { useLayoutEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { ToastContainer } from 'react-toastify';

import { DispatchLanguage } from '../languages/config/DispatchLanguage';
import { User} from '../models/user.model';
import { authUserLoggedIn } from '../scripts/auth.user.script';
import { appLanguageUpload } from '../scripts/app.language.script';

import { PublicRoute, PrivateRoute, ConditionalRoute, ProtectedRoute, LanguageRoute } from './Route';

import HomePage from './home/Home';
import AccessPage from './access/Access';
import ValidationPage from './access/Validation';
import InvitationPage from './access/Invitation';
import AuthPage from './authentication/Auth';
import LoginPage from './authentication/Login';
import RegisterPage from './authentication/Register';
import RecoverPage from './authentication/Recover';
import ResetPage from './authentication/Reset';
import MainPage from './main/Main';
import WelcomePage from './main/Welcome';
import DashboardPage from './main/Dashboard';
import AccountPage from './main/account/Account';
import AccountViewPage from './main/account/AccountView';
import AccountUpdatePage from './main/account/AccountUpdate';
import CompanyPage from './main/company/Company';
import CompanyViewPage from './main/company/CompanyView';
import CompanyCreatePage from './main/company/CompanyCreate';
import CompanyUpdatePage from './main/company/CompanyUpdate';
import TreasuryPage from './main/treasury/Treasury';
import TreasuryListPage from './main/treasury/TreasuryList';
import TreasuryCreatePage from './main/treasury/TreasuryCreate';
import ContactPage from './main/contact/Contact';
import ContactListPage from './main/contact/ContactList';
import ContactViewPage from './main/contact/ContactView';
import ContactEntryPage from './main/contact/ContactEntry';
import ContactCreatePage from './main/contact/ContactCreate';
import SalePage from './main/sale/Sale';
import QuotePage from './main/sale/quote/Quote';
import QuoteListPage from './main/sale/quote/QuoteList';
import QuoteViewPage from './main/sale/quote/QuoteView';
import QuoteCreatePage from './main/sale/quote/QuoteCreate';
import QuoteReferencePage from './main/sale/quote/QuoteReference';
import QuoteUpdatePage from './main/sale/quote/QuoteUpdate';
import OrderPage from './main/sale/order/Order';
import OrderListPage from './main/sale/order/OrderList';
import OrderViewPage from './main/sale/order/OrderView';
import OrderCreatePage from './main/sale/order/OrderCreate';
import OrderReferencePage from './main/sale/order/OrderReference';
import OrderUpdatePage from './main/sale/order/OrderUpdate';
import InvoicePage from './main/sale/invoice/Invoice';
import InvoiceListPage from './main/sale/invoice/InvoiceList';
import InvoiceViewPage from './main/sale/invoice/InvoiceView';
import InvoiceCreatePage from './main/sale/invoice/InvoiceCreate';
import InvoiceReferencePage from './main/sale/invoice/InvoiceReference';
import InvoiceUpdatePage from './main/sale/invoice/InvoiceUpdate';
import PurchasePage from './main/purchase/Purchase';
import ProviderOrderPage from './main/purchase/order/ProviderOrder';
import ProviderOrderListPage from './main/purchase/order/ProviderOrderList';
import ProviderOrderViewPage from './main/purchase/order/ProviderOrderView';
import ProviderOrderCreatePage from './main/purchase/order/ProviderOrderCreate';
import ProviderOrderReferencePage from './main/purchase/order/ProviderOrderReference';
import ProviderOrderUpdatePage from './main/purchase/order/ProviderOrderUpdate';
import ProviderInvoicePage from './main/purchase/invoice/ProviderInvoice';
import ProviderInvoiceListPage from './main/purchase/invoice/ProviderInvoiceList';
import ProviderInvoiceViewPage from './main/purchase/invoice/ProviderInvoiceView';
import ProviderInvoiceCreatePage from './main/purchase/invoice/ProviderInvoiceCreate';
import ProviderInvoiceUpdatePage from './main/purchase/invoice/ProviderInvoiceUpdate';
import ProviderInvoiceReferencePage from './main/purchase/invoice/ProviderInvoiceReference';
import ExpensePage from './main/purchase/expense/Expense';
import ExpenseListPage from './main/purchase/expense/ExpenseList';
import ExpenseViewPage from './main/purchase/expense/ExpenseView';
import ExpenseCreatePage from './main/purchase/expense/ExpenseCreate';
import ExpenseUpdatePage from './main/purchase/expense/ExpenseUpdate';
import InventoryPage from './main/inventory/Inventory';
import CategoryPage from './main/inventory/category/Category';
import CategoryListPage from './main/inventory/category/CategoryList';
import CategoryViewPage from './main/inventory/category/CategoryView';
import ServicePage from './main/inventory/service/Service';
import ServiceListPage from './main/inventory/service/ServiceList';
import ServiceViewPage from './main/inventory/service/ServiceView';
import ServiceCreatePage from './main/inventory/service/ServiceCreate';
import ServiceUpdatePage from './main/inventory/service/ServiceUpdate';
import ProductPage from './main/inventory/product/Product';
import ProductListPage from './main/inventory/product/ProductList';
import ProductViewPage from './main/inventory/product/ProductView';
import ProductCreatePage from './main/inventory/product/ProductCreate';
import ProductUpdatePage from './main/inventory/product/ProductUpdate';
import ComingSoonPage from './page/ComingSoon';
import SystemErrorPage from './page/SystemError';
import NotFoundAccessPage from './page/NotFoundAccess';
import NotFoundAuthPage from './page/NotFoundAuth';
import NotFoundAppPage from './page/NotFoundApp';
import NotFoundPage from './page/NotFound';

import './App.scss';

export interface AppPageProps {};

const App: React.FunctionComponent<AppPageProps> = (props) => {
  const cookies = new Cookies()
  const dispatchLanguage = DispatchLanguage()

  const [user, setUser] = useState<User | undefined | null>(null)

  useLayoutEffect( () => {
    appLanguageUpload(dispatchLanguage)

    if (!authUserLoggedIn()) {
      if (cookies.get('token_auth') && cookies.get('token_expiry') && cookies.get('app_service')) {
        cookies.remove('token_auth', {path: '/', sameSite: 'lax'})
        cookies.remove('token_expiry', {path: '/', sameSite: 'lax'})
        cookies.remove('app_service', {path: '/', sameSite: 'lax'})
      }
      if (cookies.get('user_email') === undefined) {
        cookies.remove('app_company', {path: '/', sameSite: 'lax'})
        cookies.remove('user_email', {path: '/', sameSite: 'lax'})
      }
    }

    /* console.log("TokenAuth: " + cookies.get('token_auth'))
    console.log("TokenExpy: " + cookies.get('token_expiry'))
    console.log("AppService: " + cookies.get('app_service'))
    console.log("AppCompany: " + cookies.get('app_company'))
    console.log("UserEmail: " + cookies.get('user_email')) */
  })

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<LanguageRoute/>}>
          <Route index element={<HomePage/>}/>
          <Route path='/:language' element={<HomePage/>}/>
          <Route path='/access/*' element={<AccessPage/>}>
            <Route path='validation/account/:email/:token' element={<ValidationPage/>}/>
            <Route path='invitation/company/:email/:token' element={<InvitationPage/>}/>
            <Route path='*' element={<NotFoundAccessPage/>}/>
          </Route>
          <Route path='/:language/access/*' element={<AccessPage/>}>
            <Route path='validation/account/:email/:token' element={<ValidationPage/>}/>
            <Route path='invitation/company/:email/:token' element={<InvitationPage/>}/>
            <Route path='*' element={<NotFoundAccessPage/>}/>
          </Route>
          <Route element={<PublicRoute redirectTo={'/app/main'}/>}>
            <Route path='/auth/*' element={<AuthPage/>}>
              <Route path='login' element={<LoginPage/>}/>
              <Route path='register' element={<RegisterPage/>}/>
              <Route path='register/:email' element={<RegisterPage/>}/>
              <Route path='recover' element={<RecoverPage/>}/>
              <Route path='reset/:email/:token' element={<ResetPage/>}/>
              <Route path='*' element={<NotFoundAuthPage/>}/>
            </Route>
            <Route path='/:language/auth/*' element={<AuthPage/>}>
              <Route path='login' element={<LoginPage/>}/>
              <Route path='register' element={<RegisterPage/>}/>
              <Route path='register/:email' element={<RegisterPage/>}/>
              <Route path='recover' element={<RecoverPage/>}/>
              <Route path='reset/:email/:token' element={<ResetPage/>}/>
              <Route path='*' element={<NotFoundAuthPage/>}/>
            </Route>
          </Route>
          <Route element={<PrivateRoute redirectTo={'/auth/login'}/>}>
            <Route path='/app/*' element={<MainPage userLoggedIn={user} setUserLoggedIn={setUser}/>}>
              { user &&
                <>
                  <Route path='main' element={<ConditionalRoute state={ user.companies.length === 0 ? true : false } redirectTo={'/app/dashboard'} element={<WelcomePage/>}/>}/>
                  <Route path='dashboard' element={<ConditionalRoute state={ user.companies.length > 0 ? true : false } redirectTo={'/app/main'} element={<DashboardPage/>}/>}/>
                  <Route path='account/*' element={<AccountPage/>}>
                    <Route path='view' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<AccountViewPage/>}/>}/>
                    <Route path='update' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<AccountUpdatePage/>}/>}/>
                    <Route path='*' element={<NotFoundAppPage/>}/>
                  </Route>
                  <Route path='company/*' element={<CompanyPage/>}>
                    <Route path='view' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<CompanyViewPage/>}/>}/>
                    <Route path='update' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<CompanyUpdatePage/>}/>}/>
                    <Route path='create' element={<ConditionalRoute state={authUserLoggedIn()} redirectTo={'/auth/login'} element={<CompanyCreatePage/>}/>}/>
                    <Route path='*' element={<NotFoundAppPage/>}/>
                  </Route>
                  <Route path='treasury/*' element={<TreasuryPage/>}>
                    <Route path='list' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<TreasuryListPage/>}/>}/>
                    <Route path='create' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<TreasuryCreatePage/>}/>}/>
                    <Route path='*' element={<NotFoundAppPage/>}/>
                  </Route>
                  <Route path='contact/*' element={<ContactPage/>}>
                    <Route path='list' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ContactListPage/>}/>}/>
                    <Route path='view/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ContactViewPage/>}/>}/>
                    <Route path='create' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ContactCreatePage/>}/>}/>
                    <Route path='entry/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ContactEntryPage/>}/>}/>
                    <Route path='*' element={<NotFoundAppPage/>}/>
                  </Route>
                  <Route path='sale/*' element={<SalePage/>}>
                    <Route path='quote/*' element={<QuotePage/>}>
                      <Route path='list' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<QuoteListPage/>}/>}/>
                      <Route path='view/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<QuoteViewPage/>}/>}/>
                      <Route path='create' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<QuoteCreatePage/>}/>}/>
                      <Route path='create/reference/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<QuoteReferencePage/>}/>}/>
                      <Route path='update/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<QuoteUpdatePage/>}/>}/>
                      <Route path='*' element={<NotFoundAppPage/>}/>
                    </Route>
                    <Route path='order/*' element={<OrderPage/>}>
                      <Route path='list' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<OrderListPage/>}/>}/>
                      <Route path='view/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<OrderViewPage/>}/>}/>
                      <Route path='create' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<OrderCreatePage/>}/>}/>
                      <Route path='create/reference/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<OrderReferencePage/>}/>}/>
                      <Route path='update/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<OrderUpdatePage/>}/>}/>
                      <Route path='*' element={<NotFoundAppPage/>}/>
                    </Route>
                    <Route path='invoice/*' element={<InvoicePage/>}>
                      <Route path='list' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<InvoiceListPage/>}/>}/>
                      <Route path='view/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<InvoiceViewPage/>}/>}/>
                      <Route path='create' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<InvoiceCreatePage/>}/>}/>
                      <Route path='create/reference/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<InvoiceReferencePage/>}/>}/>
                      <Route path='update/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<InvoiceUpdatePage/>}/>}/>
                      <Route path='*' element={<NotFoundAppPage/>}/>
                    </Route>
                    <Route path='*' element={<NotFoundAppPage/>}/>
                  </Route>
                  <Route path='purchase/*' element={<PurchasePage/>}>
                    <Route path='order/*' element={<ProviderOrderPage/>}>
                      <Route path='list' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ProviderOrderListPage/>}/>}/>
                      <Route path='view/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ProviderOrderViewPage/>}/>}/>
                      <Route path='create' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ProviderOrderCreatePage/>}/>}/>
                      <Route path='create/reference/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ProviderOrderReferencePage/>}/>}/>
                      <Route path='update/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ProviderOrderUpdatePage/>}/>}/>
                      <Route path='*' element={<NotFoundAppPage/>}/>
                    </Route>
                    <Route path='invoice/*' element={<ProviderInvoicePage/>}>
                      <Route path='list' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ProviderInvoiceListPage/>}/>}/>
                      <Route path='view/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ProviderInvoiceViewPage/>}/>}/>
                      <Route path='create' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ProviderInvoiceCreatePage/>}/>}/>
                      <Route path='create/reference/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ProviderInvoiceReferencePage/>}/>}/>
                      <Route path='update/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ProviderInvoiceUpdatePage/>}/>}/>
                      <Route path='*' element={<NotFoundAppPage/>}/>
                    </Route>
                    <Route path='expense/*' element={<ExpensePage/>}>
                      <Route path='list' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ExpenseListPage/>}/>}/>
                      <Route path='view/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ExpenseViewPage/>}/>}/>
                      <Route path='create' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ExpenseCreatePage/>}/>}/>
                      <Route path='update/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ExpenseUpdatePage/>}/>}/>
                      <Route path='*' element={<NotFoundAppPage/>}/>
                    </Route>
                    <Route path='*' element={<NotFoundAppPage/>}/>
                  </Route>
                  <Route path='inventory/*' element={<InventoryPage/>}>
                    <Route path='category/*' element={<CategoryPage/>}>
                      <Route path='list' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<CategoryListPage/>}/>}/>
                      <Route path='view/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<CategoryViewPage/>}/>}/>
                      <Route path='*' element={<NotFoundAppPage/>}/>
                    </Route>
                    <Route path='service/*' element={<ServicePage/>}>
                      <Route path='list' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ServiceListPage/>}/>}/>
                      <Route path='view/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ServiceViewPage/>}/>}/>
                      <Route path='create' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ServiceCreatePage/>}/>}/>
                      <Route path='update/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ServiceUpdatePage/>}/>}/>
                      <Route path='*' element={<NotFoundAppPage/>}/>
                    </Route>
                    <Route path='product/*' element={<ProductPage/>}>
                      <Route path='list' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ProductListPage/>}/>}/>
                      <Route path='view/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ProductViewPage/>}/>}/>
                      <Route path='create' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ProductCreatePage/>}/>}/>
                      <Route path='update/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ProductUpdatePage/>}/>}/>
                      <Route path='*' element={<NotFoundAppPage/>}/>
                    </Route>
                    <Route path='*' element={<NotFoundAppPage/>}/>
                  </Route>
                  <Route path='*' element={<NotFoundAppPage/>}/>
                </>
              }
            </Route>
            <Route path='/:language/app/*' element={<MainPage userLoggedIn={user} setUserLoggedIn={setUser}/>}>
              { user &&
                <>
                  <Route path='main' element={<ConditionalRoute state={ user.companies.length === 0 ? true : false } redirectTo={'/app/dashboard'} element={<WelcomePage/>}/>}/>
                  <Route path='dashboard' element={<ConditionalRoute state={ user.companies.length > 0 ? true : false } redirectTo={'/app/main'} element={<DashboardPage/>}/>}/>
                  <Route path='account/*' element={<AccountPage/>}>
                    <Route path='view' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<AccountViewPage/>}/>}/>
                    <Route path='update' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<AccountUpdatePage/>}/>}/>
                    <Route path='*' element={<NotFoundAppPage/>}/>
                  </Route>
                  <Route path='company/*' element={<CompanyPage/>}>
                    <Route path='view' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<CompanyViewPage/>}/>}/>
                    <Route path='update' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<CompanyUpdatePage/>}/>}/>
                    <Route path='create' element={<ConditionalRoute state={authUserLoggedIn()} redirectTo={'/auth/login'} element={<CompanyCreatePage/>}/>}/>
                    <Route path='*' element={<NotFoundAppPage/>}/>
                  </Route>
                  <Route path='treasury/*' element={<TreasuryPage/>}>
                    <Route path='list' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<TreasuryListPage/>}/>}/>
                    <Route path='create' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<TreasuryCreatePage/>}/>}/>
                    <Route path='*' element={<NotFoundAppPage/>}/>
                  </Route>
                  <Route path='contact/*' element={<ContactPage/>}>
                    <Route path='list' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ContactListPage/>}/>}/>
                    <Route path='view/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ContactViewPage/>}/>}/>
                    <Route path='create' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ContactCreatePage/>}/>}/>
                    <Route path='entry/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ContactEntryPage/>}/>}/>
                    <Route path='*' element={<NotFoundAppPage/>}/>
                  </Route>
                  <Route path='sale/*' element={<SalePage/>}>
                    <Route path='quote/*' element={<QuotePage/>}>
                      <Route path='list' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<QuoteListPage/>}/>}/>
                      <Route path='view/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<QuoteViewPage/>}/>}/>
                      <Route path='create' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<QuoteCreatePage/>}/>}/>
                      <Route path='create/reference/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<QuoteReferencePage/>}/>}/>
                      <Route path='update/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<QuoteUpdatePage/>}/>}/>
                      <Route path='*' element={<NotFoundAppPage/>}/>
                    </Route>
                    <Route path='order/*' element={<OrderPage/>}>
                      <Route path='list' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<OrderListPage/>}/>}/>
                      <Route path='view/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<OrderViewPage/>}/>}/>
                      <Route path='create' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<OrderCreatePage/>}/>}/>
                      <Route path='create/reference/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<OrderReferencePage/>}/>}/>
                      <Route path='update/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<OrderUpdatePage/>}/>}/>
                      <Route path='*' element={<NotFoundAppPage/>}/>
                    </Route>
                    <Route path='invoice/*' element={<InvoicePage/>}>
                      <Route path='list' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<InvoiceListPage/>}/>}/>
                      <Route path='view/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<InvoiceViewPage/>}/>}/>
                      <Route path='create' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<InvoiceCreatePage/>}/>}/>
                      <Route path='create/reference/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<InvoiceReferencePage/>}/>}/>
                      <Route path='update/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<InvoiceUpdatePage/>}/>}/>
                      <Route path='*' element={<NotFoundAppPage/>}/>
                    </Route>
                    <Route path='*' element={<NotFoundAppPage/>}/>
                  </Route>
                  <Route path='purchase/*' element={<PurchasePage/>}>
                    <Route path='order/*' element={<ProviderOrderPage/>}>
                      <Route path='list' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ProviderOrderListPage/>}/>}/>
                      <Route path='view/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ProviderOrderViewPage/>}/>}/>
                      <Route path='create' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ProviderOrderCreatePage/>}/>}/>
                      <Route path='create/reference/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ProviderOrderReferencePage/>}/>}/>
                      <Route path='update/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ProviderOrderUpdatePage/>}/>}/>
                      <Route path='*' element={<NotFoundAppPage/>}/>
                    </Route>
                    <Route path='invoice/*' element={<ProviderInvoicePage/>}>
                      <Route path='list' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ProviderInvoiceListPage/>}/>}/>
                      <Route path='view/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ProviderInvoiceViewPage/>}/>}/>
                      <Route path='create' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ProviderInvoiceCreatePage/>}/>}/>
                      <Route path='create/reference/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ProviderInvoiceReferencePage/>}/>}/>
                      <Route path='update/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ProviderInvoiceUpdatePage/>}/>}/>
                      <Route path='*' element={<NotFoundAppPage/>}/>
                    </Route>
                    <Route path='expense/*' element={<ExpensePage/>}>
                      <Route path='list' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ExpenseListPage/>}/>}/>
                      <Route path='view/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ExpenseViewPage/>}/>}/>
                      <Route path='create' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ExpenseCreatePage/>}/>}/>
                      <Route path='update/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ExpenseUpdatePage/>}/>}/>
                      <Route path='*' element={<NotFoundAppPage/>}/>
                    </Route>
                    <Route path='*' element={<NotFoundAppPage/>}/>
                  </Route>
                  <Route path='inventory/*' element={<InventoryPage/>}>
                    <Route path='category/*' element={<CategoryPage/>}>
                      <Route path='list' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<CategoryListPage/>}/>}/>
                      <Route path='view/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<CategoryViewPage/>}/>}/>
                      <Route path='*' element={<NotFoundAppPage/>}/>
                    </Route>
                    <Route path='service/*' element={<ServicePage/>}>
                      <Route path='list' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ServiceListPage/>}/>}/>
                      <Route path='view/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ServiceViewPage/>}/>}/>
                      <Route path='create' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ServiceCreatePage/>}/>}/>
                      <Route path='update/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ServiceUpdatePage/>}/>}/>
                      <Route path='*' element={<NotFoundAppPage/>}/>
                    </Route>
                    <Route path='product/*' element={<ProductPage/>}>
                      <Route path='list' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ProductListPage/>}/>}/>
                      <Route path='view/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ProductViewPage/>}/>}/>
                      <Route path='create' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ProductCreatePage/>}/>}/>
                      <Route path='update/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ProductUpdatePage/>}/>}/>
                      <Route path='*' element={<NotFoundAppPage/>}/>
                    </Route>
                    <Route path='*' element={<NotFoundAppPage/>}/>
                  </Route>
                  <Route path='*' element={<NotFoundAppPage/>}/>
                </>
              }
            </Route>
          </Route>
          <Route path='/coming/soon' element={<ComingSoonPage/>}/>
          <Route path='/:language/coming/soon' element={<ComingSoonPage/>}/>
          <Route path='/error' element={<SystemErrorPage/>}/>
          <Route path='/:language/error' element={<SystemErrorPage/>}/>
          <Route path='*' element={<NotFoundPage/>}/>
        </Route>
      </Routes>
      <Toaster position="bottom-right" reverseOrder={true} toastOptions={{style: {borderRadius: '10px'}}}/>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover={false} theme="dark"/>
    </BrowserRouter>
  );
}

export default App;
