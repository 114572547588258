import { Language } from "../languages/labels/language.labels";

export function paymentStatusList(lang: Language): {code: string, display: string}[] {
  return [
    { code: 'not payed', display: lang.labels.notPayed },
    { code: 'partially paid', display: lang.labels.partiallyPaid },
    { code: 'fully paid', display: lang.labels.fullyPaid }
  ]
}

export function paymentMethodList(lang: Language): {code: string, display: string}[] {
  return [
    { code: 'cash', display: lang.labels.cash },
    { code: 'card', display: lang.labels.card },
    { code: 'paypal', display: lang.labels.payPal },
    { code: 'transfer', display: lang.labels.transfer },
    { code: 'mobile', display: lang.labels.mobile }
  ]
}

export function paymentAllList(lang: Language): {code: string, display: string}[] {
  let paymentAll = [...paymentStatusList(lang), ...paymentMethodList(lang)]
  return Array.from((new Map(paymentAll.map((item) => [item.code, item]))).values())
}

export function paymentStatusValue(lang: Language, code: string): string {
  let paymentStatus = paymentStatusList(lang).find((item) => (item.code === code))
  return paymentStatus ? paymentStatus.display : lang.labels.undefined
}

export function paymentMethodValue(lang: Language, code: string): string {
  let paymentMethod = paymentMethodList(lang).find((item) => (item.code === code))
  return paymentMethod ? paymentMethod.display : lang.labels.undefined
}

export function paymentAllValue(lang: Language, code: string): string {
  let paymentAll = paymentAllList(lang).find((item) => (item.code === code))
  return paymentAll ? paymentAll.display : lang.labels.undefined
}
