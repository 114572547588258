import { Language } from "../languages/labels/language.labels";

export function enumerationList(lang: Language): {code: string, display: string}[] {
  return [
    { code: 'first', display: lang.labels.first },
    { code: 'second', display: lang.labels.second },
    { code: 'third', display: lang.labels.third },
    { code: 'fourth', display: lang.labels.fourth },
    { code: 'fifth', display: lang.labels.fifth },
    { code: 'sixth', display: lang.labels.sixth }
  ]
}

export function enumerationValue(lang: Language, code: string): string {
  let enumeration = enumerationList(lang).find((item) => (item.code === code))
  return enumeration ? enumeration.display : lang.labels.undefined
}
