import React, { Dispatch, SetStateAction, useState } from 'react';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { modalHide } from '../../tools/modal.tool';
import { legendInvalid, legendValidInvalidRestart } from '../../tools/legend.data.entry.tool';

import AppLegend from '../element/Legend';

export interface AppModalInvoicePreviousProps {
  pdf: {value: string, file: any, valid: boolean},
  setPdf: Dispatch<SetStateAction<{value: string, file: any, valid: boolean}>>
};

const AppModalInvoicePrevious: React.FunctionComponent<AppModalInvoicePreviousProps> = ({pdf, setPdf}) => {
  const {lang} = StateLanguage()

  const [loadIndicator, setLoadIndicator] = useState('off')

  const handleChangeFile = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let archivesCapture = event.target.files
    let archivesValue: {name: string, size: number, type: string, view: string, file: any}[] = []
    let archivesValid: {file: boolean, message: string}[] = []
    let archiveType: string[] = ['application/pdf']
    let archiveSize: number = 1048576

    for (let i = 0; i < archivesCapture.length; i++) {
      let archiveValue: {name: string, size: number, type: string, view: string, file: any} = {name: archivesCapture[i].name, size: archivesCapture[i].size, type: archivesCapture[i].type, view: archivesCapture[i].name, file: archivesCapture[i]}
      let archiveValid: {file: boolean, message: string} = {file: true, message: lang.labels.valid}

      /* extractBase64(archivesCapture[i]).then( (pdf: any) => {
        archiveValue.view = pdf.base
      }) */

      if (!(archiveType === undefined || (archiveType && archiveType.includes(archivesCapture[i].type)))) {
        archiveValid = {file: false, message: lang.labels.fileInDifferentFormat}
      } else if (!((archiveSize !== 0 && archivesCapture[i].size <= archiveSize) || archiveSize === 0)) {
        archiveValid = {file: false, message: lang.labels.fileIsTooBig}
      } else {
        archiveValue.size = Number((archivesCapture[i].size / archiveSize).toFixed(2))
      }

      archivesValue.push(archiveValue)
      archivesValid.push(archiveValid)
    }

    /* setUploadVouchersValue(archivesValue)
    setUploadVouchersValid(archivesValid) */
    setLoadIndicator('off')
  }

  const executeUploadFile = () => {
    setLoadIndicator('on')

    setTimeout( () => {
      if (pdf.valid) {
        modalHide('modal-invoice-previous')
        setLoadIndicator('off')
      } else {
        legendInvalid('modal-invoice-previous-legend-pdf-required')
        setLoadIndicator('off')
      }
    }, 500 )
  }

  const executeHideModalInvoiceUpdateInvalidate = () => {
    modalHide('modal-invoice-previous')

    setTimeout( () => {
      restartModal()
    }, 200 )
  }

  function restartModal() {
    legendValidInvalidRestart('modal-invoice-previous-legend-pdf-required')
  }

  return (
    <div id="modal-invoice-previous" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog mw-550px">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="text-capitalize">{lang.labels.beforeCreatingProviderInvoice}</h2>
          </div>
          <div className="modal-body">
            <div className="form">
              <div className="text-center fs-6 mb-10">
                <h5 className="mb-5">{lang.labels.doYouWantUploadPdfFileToFacilitateRregistrationInvoice}</h5>
                <label>{lang.labels.whenYouUploadFileItWillPlacedReferenceProviderInvoiceCreationForm}</label>
              </div>
              <div className="fv-row mb-2">
                <div className="dropzone position-relative ps-2 pe-5">
                  <input className="position-absolute top-0 start-0 w-100 h-100 opacity-0 cursor-pointer" type="file" name="logo" accept=".pdf" onChange={handleChangeFile} />
                  { pdf.valid && pdf.file && pdf.value.length > 0
                    ?
                    <div className="dropzone-file">
                      <div className="dropzone-filename text-dark">
                        <span className="me-1">{pdf.file.name}</span>
                        <strong>
                          (
                          <span>
                            <strong className="me-1">{pdf.file.size}</strong>
                            <strong>{lang.labels.mb}</strong>
                          </span>
                          )
                        </strong>
                      </div>
                    </div>
                    :
                    <div className="d-flex align-items-center">
                      <span className="svg-icon svg-icon-3tx svg-icon-primary">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM16 12.6L12.7 9.3C12.3 8.9 11.7 8.9 11.3 9.3L8 12.6H11V18C11 18.6 11.4 19 12 19C12.6 19 13 18.6 13 18V12.6H16Z" fill="black"/>
                          <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black"/>
                        </svg>
                      </span> 
                      <div className="d-flex flex-column ms-3">
                        <span className="fs-5 fw-bold text-start text-gray-900">{lang.labels.dropFileHereOrClickUpload}</span>
                        <span className="form-text text-justify text-gray-600">{lang.labels.fileFormatMaximum1MBPerFile}</span>
                      </div>
                    </div>
                  }
                </div>
              </div>
              {/* { !pdf.valid &&
                <div className="dropzone-error m-0">{lang.labels.error}</div>
              } */}
              <AppLegend component={"modal-invoice-previous"} attribute={{validity: pdf.valid, name: "pdf", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
            </div>
          </div>
          <div className="modal-footer flex-center">
            <button className="btn btn-light mx-2" type="reset" onClick={executeHideModalInvoiceUpdateInvalidate}>{lang.labels.noContinue}</button>
            <button className="btn btn-primary mx-2" type="button" data-kt-indicator={loadIndicator} onClick={executeUploadFile}>
              <span className="indicator-label">{lang.labels.yesUpload}</span>
              <span className="indicator-progress">
                {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
};

export default AppModalInvoicePrevious;
