import React, { useEffect, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { Dropdown, Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { format, startOfYear } from 'date-fns';

import { UseOutletContextContact } from './Contact';
import { StateLanguage } from '../../../languages/config/StateLanguage';
import { AccountingEntryDA } from '../../../services/accounting.entry.service';
import { CompanyAccountingContactEntries } from '../../../models/company.accounting.contact.entries.model';
import { AccountingEntry } from '../../../models/accounting.entry.model';
import { Debit } from '../../../models/debit.model';
import { Credit } from '../../../models/credit.model';
import { CompanyAccountingEntries } from '../../../models/company.accounting.entries.model';
import { Error } from '../../../models/error.model';
import { Contact } from '../../../models/contact.model';
import { moneyFormat } from '../../../scripts/format.value.script';
import { dateFormatAbbreviated } from '../../../scripts/datetime.format.script';
import { getError } from '../../../languages/translations/response';
import { contactSelected } from '../../../scripts/list.selected.item.script';
import { modalShow } from '../../../tools/modal.tool';

import imgSearch from '../../../assets/images/themes/image-search.png';

import AppModalContactEntryCreate from '../../../components/modal/ModalContactEntryCreate';
import AppModalContactEntryExport from '../../../components/modal/ModalContactEntryExport';
import AppStorageRemove from '../../../components/element/StorageRemove';

export interface ContactAccountPageProps {};

let errorResponse: Error, accountingEntriesResponse: CompanyAccountingEntries, accountingContactEntriesResponse: CompanyAccountingContactEntries;

const ContactEntryPage: React.FunctionComponent<ContactAccountPageProps> = props => {
  const {setRoute, companyForUser, accountingAccountsForCompany, treasuryAccountsForCompany, contactsForCompany} = UseOutletContextContact()
  const {lang} = StateLanguage()
  const param = useParams()
  const navigate = useNavigate()

  const [mounted, setMounted] = useState(false)
  const [button, setButton] = useState(false)
  const [searchContact, setSearchContact] = useState<{text: string, list: Contact[]}>({text: '', list: []})
  const [filter, setFilter] = useState({interval: '', start_date: '', end_date: ''})
  const [table, setTable] = useState<{body: any[], foot: any[]}>({body: [], foot: []})
  const [unidentifiedEntries, setUnidentifiedEntries] = useState<AccountingEntry[] | undefined | null>(null)
  const [accounitngEntries, setAccountingEntries] = useState<AccountingEntry[] | undefined | null>(null)
  const [storage, setStorage] = useState<any>(null)

  const loadAccountingUnidentifiedEntries = async (id_company: string) => {
    await AccountingEntryDA.getAccountingIncompleteEntries(id_company).then( (response) => {
      if (response.status === 200) {
        accountingEntriesResponse = response.data
        setUnidentifiedEntries(accountingEntriesResponse.accounting_entries.filter((item) => (item.id_contact.length === 0)))
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.code),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.okGotIt,
          customClass: {confirmButton: 'btn btn-primary'}
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      navigate('/error')
    })
  }

  const loadAccountingContactEntries = async (id_company: string, id_contact: string) => {
    let tempBody: {date: string, description: string, debit: string, credit: string, balance: string}[] = []
    let tempFoot: {date: string, description: string, debit: string, credit: string, balance: string}[] = []
    let endDate: string=  (button && filter.end_date.length > 0) ? filter.end_date : format((new Date()), `yyyy-MM-dd'T'HH:mm:ss.SSSxxx`)
    let debitTotal: number = 0
    let creditTotal: number = 0
    let balanceTotal: number = 0
    let filtered: boolean = false

    await AccountingEntryDA.getAccountingContactEntries(id_company, id_contact, filter.interval, filter.start_date, filter.end_date).then( (response) => {
      if (response.status === 200) {
        accountingContactEntriesResponse = response.data

        localStorage.setItem('filter_contact_entries', JSON.stringify({ 
          company_id: accountingContactEntriesResponse.id_company,
          contact_id: accountingContactEntriesResponse.id_contact,
          interval: accountingContactEntriesResponse.filter.interval, 
          start_date: accountingContactEntriesResponse.filter.start_date, 
          end_date: accountingContactEntriesResponse.filter.end_date
        }))

        filtered = (accountingContactEntriesResponse.filter.interval === '' && accountingContactEntriesResponse.filter.start_date === '' && accountingContactEntriesResponse.filter.end_date === '') ? false : true

        if (accountingContactEntriesResponse.balance_previous.date.length > 0) {
          tempBody.push({date: dateFormatAbbreviated(lang.code, accountingContactEntriesResponse.balance_previous.date), description: lang.labels.balancePrevious, debit: '', credit: '', balance: moneyFormat(accountingContactEntriesResponse.balance_previous.amount)})
        }

        for (let accounitng_entry of accountingContactEntriesResponse.accounting_entries) {
          let debit: Debit | null = (accounitng_entry.debits.length > 0) ? accounitng_entry.debits[0] : null
          let credit: Credit | null = (accounitng_entry.debits.length > 0) ? accounitng_entry.debits[0] : null

          if (debit && debit.type === 'invoice' && debit.description === 'Account Client') {
            if (accounitng_entry.credits.some((item) => (item.description === "Account Sale"))) {
              tempBody.push({date: dateFormatAbbreviated(lang.code, accounitng_entry.date), description: `${lang.labels.invoiceIssuance} ${debit.reference.invoice_number}`, debit: moneyFormat(debit.amount), credit: '', balance: moneyFormat(accounitng_entry.balance)})
              debitTotal += debit.amount
            }
            if (accounitng_entry.credits.some((item) => (item.description === "Account Reminder"))) {
              tempBody.push({date: dateFormatAbbreviated(lang.code, accounitng_entry.date), description: lang.labels.allocationInvoiceReminder, debit: moneyFormat(debit.amount), credit: '', balance: moneyFormat(accounitng_entry.balance)})
              debitTotal += debit.amount
            }
          } if (credit && credit.type === 'treasuryaccount' && credit.description === 'Account Treasury') {
            tempBody.push({date: dateFormatAbbreviated(lang.code, accounitng_entry.date), description: lang.labels.transactionPostFinance, debit: '', credit: moneyFormat(credit.amount), balance: moneyFormat(accounitng_entry.balance)})
            creditTotal += credit.amount
          }
        }

        balanceTotal = accountingContactEntriesResponse.balance_previous.amount + (creditTotal - debitTotal)

        tempFoot.push({date: dateFormatAbbreviated(lang.code, endDate), description: lang.labels.total, debit: moneyFormat(debitTotal), credit: moneyFormat(creditTotal), balance: ''})
        tempFoot.push({date: dateFormatAbbreviated(lang.code, endDate), description: (balanceTotal >= 0) ? lang.labels.balanceFavorContact : lang.labels.balanceFavorCompany, debit: '', credit: '', balance: moneyFormat((balanceTotal >= 0) ? balanceTotal : balanceTotal * -1)})

        setButton(filtered)
        setTable({body: tempBody, foot: tempFoot})
        setAccountingEntries(accountingContactEntriesResponse.accounting_entries)
        setStorage((localStorage.getItem('filter_contact_entries')) ? JSON.parse(localStorage.getItem('filter_contact_entries')!) : null)
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.code),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.okGotIt,
          customClass: {confirmButton: 'btn btn-primary'}
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      navigate('/error')
    })
  }

  const handleChangeSearchContact = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let text: string = event.target.value
    let list: Contact[] = []

    if (contactsForCompany) {
      if (text.length > 0) {
        for (let contact of contactsForCompany) {
          let name: string = (contact.type === 'person') ? (`${contact.data.first_name} ${contact.data.last_name}`).toLowerCase() : contact.data.name.toLowerCase()
          if (name.indexOf(text.toLowerCase()) !== -1) {
            list.push(contact)
          }
        }
      } else {
        list = contactsForCompany
      }
    }

    setSearchContact({text: text, list: list})
  }

  const handleChangeInterval = (event: React.ChangeEvent <HTMLFormElement | HTMLSelectElement>) => {
    let dateCurrent: string = format((new Date()), 'yyyy-MM-dd')

    switch (event.target.value) {
      case 'today':
        setFilter({interval: event.target.value, start_date: format((new Date(`${dateCurrent}T00:00:00.000`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`), end_date: format((new Date(`${dateCurrent}T23:59:59.999`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`)})
        break;
      case 'yesterday':
        let yesterday: string = format(new Date((new Date()).setDate((new Date()).getDate() - 1)), 'yyyy-MM-dd')
        setFilter({interval: event.target.value, start_date: format((new Date(`${yesterday}T00:00:00.000`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`), end_date: format((new Date(`${yesterday}T23:59:59.999`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`)})
        break;
      case 'week':
        let weekCurrent: string = format(new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate() - (new Date()).getDay()), 'yyyy-MM-dd')
        setFilter({interval: event.target.value, start_date: format((new Date(`${weekCurrent}T00:00:00.000`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`), end_date: format((new Date(`${dateCurrent}T23:59:59.999`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`)})
        break;
      case 'month':
        let monthCurrent: string = format(new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1), 'yyyy-MM-dd')
        setFilter({interval: event.target.value, start_date: format((new Date(`${monthCurrent}T00:00:00.000`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`), end_date: format((new Date(`${dateCurrent}T23:59:59.999`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`)})
        break;
      case 'bimester':
        let bimesterCurrent: number = Math.floor((new Date()).getMonth()/ 2) + 1
        if (bimesterCurrent === 6) { setFilter({interval: event.target.value, start_date: format((new Date(`${dateCurrent.split('-')[0]}-11-01T00:00:00.000`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`), end_date: format((new Date(`${dateCurrent}T23:59:59.999`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`)})}
        if (bimesterCurrent === 5) { setFilter({interval: event.target.value, start_date: format((new Date(`${dateCurrent.split('-')[0]}-09-01T00:00:00.000`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`), end_date: format((new Date(`${dateCurrent}T23:59:59.999`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`)})}
        if (bimesterCurrent === 4) { setFilter({interval: event.target.value, start_date: format((new Date(`${dateCurrent.split('-')[0]}-07-01T00:00:00.000`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`), end_date: format((new Date(`${dateCurrent}T23:59:59.999`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`)})}
        if (bimesterCurrent === 3) { setFilter({interval: event.target.value, start_date: format((new Date(`${dateCurrent.split('-')[0]}-05-01T00:00:00.000`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`), end_date: format((new Date(`${dateCurrent}T23:59:59.999`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`)})}
        if (bimesterCurrent === 2) { setFilter({interval: event.target.value, start_date: format((new Date(`${dateCurrent.split('-')[0]}-03-01T00:00:00.000`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`), end_date: format((new Date(`${dateCurrent}T23:59:59.999`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`)})}
        if (bimesterCurrent === 1) { setFilter({interval: event.target.value, start_date: format((new Date(`${dateCurrent.split('-')[0]}-01-01T00:00:00.000`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`), end_date: format((new Date(`${dateCurrent}T23:59:59.999`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`)})}
        break;
      case 'trimester':
        let trimesterCurrent: number = Math.floor((new Date()).getMonth()/ 3) + 1
        if (trimesterCurrent === 4) { setFilter({interval: event.target.value, start_date: format((new Date(`${dateCurrent.split('-')[0]}-10-01T00:00:00.000`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`), end_date: format((new Date(`${dateCurrent}T23:59:59.999`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`)})}
        if (trimesterCurrent === 3) { setFilter({interval: event.target.value, start_date: format((new Date(`${dateCurrent.split('-')[0]}-07-01T00:00:00.000`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`), end_date: format((new Date(`${dateCurrent}T23:59:59.999`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`)})}
        if (trimesterCurrent === 2) { setFilter({interval: event.target.value, start_date: format((new Date(`${dateCurrent.split('-')[0]}-04-01T00:00:00.000`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`), end_date: format((new Date(`${dateCurrent}T23:59:59.999`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`)})}
        if (trimesterCurrent === 1) { setFilter({interval: event.target.value, start_date: format((new Date(`${dateCurrent.split('-')[0]}-01-01T00:00:00.000`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`), end_date: format((new Date(`${dateCurrent}T23:59:59.999`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`)})}
        break;
      case 'half':
        let halfCurrent: number = Math.floor((new Date()).getMonth()/ 6) + 1
        if (halfCurrent === 2) { setFilter({interval: event.target.value, start_date: format((new Date(`${dateCurrent.split('-')[0]}-07-01T00:00:00.000`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`), end_date: format((new Date(`${dateCurrent}T23:59:59.999`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`)})}
        if (halfCurrent === 1) { setFilter({interval: event.target.value, start_date: format((new Date(`${dateCurrent.split('-')[0]}-01-01T00:00:00.000`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`), end_date: format((new Date(`${dateCurrent}T23:59:59.999`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`)})}
        break;
      case 'year':
        setFilter({interval: event.target.value, start_date: format((new Date(`${dateCurrent.split('-')[0]}-01-01T00:00:00.000`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`), end_date: format((new Date(`${dateCurrent}T23:59:59.999`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`)})
        break;
      default:
        setFilter({interval: 'all', start_date: '', end_date: ''})
        break;
    }
  }

  const handleChangeStartDate = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let date: string = (event.target.value.length > 0) ? format((new Date(`${event.target.value}T00:00:00.000`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`) : ''
    setFilter({...filter, interval: '', start_date: date})
  }

  const handleChangeEndDate = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let date: string = (event.target.value.length > 0) ? format((new Date(`${event.target.value}T00:00:00.000`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`) : ''
    setFilter({...filter, interval: '', end_date: date})
  }

  const handleChangeContact = (item: string) => {
    navigate("/app/contact/entry/" + item)
  }

  const executeShowModalContactEntryCreate = () => {
    if (param.id && param.id !== 'none') {
      modalShow('modal-contact-entry-create')
    }
  }

  const executeShowModalContactEntryExport = () => {
    if (param.id && param.id !== 'none') {
      modalShow('modal-contact-entry-export')
    }
  }

  const executeFilterApply = () => {
    if (companyForUser && param.id && param.id !== 'none') {
      loadAccountingContactEntries(companyForUser.id, param.id)
    }
  }

  const executeFilterReset = () => {
    if (companyForUser && param.id && param.id !== 'none') {
      filter.interval = ''
      filter.start_date = ''
      filter.end_date = ''
      loadAccountingContactEntries(companyForUser.id, param.id)
    }
  }

  const executeFilterRemove = (item: string) => {
    if (companyForUser && param.id && param.id !== 'none' && (filter.interval.length !== 0 || filter.start_date.length !== 0 || filter.end_date.length !== 0)) {
      for (let key of Object.keys(filter) as Array<keyof typeof filter>) {
        if (key === item) {
          filter[key] = ''
        }
        if (item === "start_date") {
          filter.interval = ''
        }
        if (item === "end_date") {
          filter.interval = ''
        }
        if (item === "interval") {
          filter.start_date = ''
          filter.end_date = ''
        }
      }
      loadAccountingContactEntries(companyForUser.id, param.id)
    }
  }

  function uploadDropdown() {
    if (contactsForCompany) {
      setSearchContact({text: '', list: contactsForCompany})
    } else {
      setSearchContact({text: '', list: []})
    }
  }

  useEffect( () => {
    let filter_contact_entries: any = (localStorage.getItem('filter_contact_entries')) ? JSON.parse(localStorage.getItem('filter_contact_entries')!) : null

    if (companyForUser) {
      loadAccountingUnidentifiedEntries(companyForUser.id)

      if (param.id && param.id !== 'none') {
        let dateCurrent: Date = new Date()
        let yearCurrent: Date = startOfYear(dateCurrent)

        filter.interval = (filter_contact_entries && filter_contact_entries.company_id === companyForUser.id && filter_contact_entries.interval.length > 0) ? filter_contact_entries.interval : 'year'
        filter.start_date = (filter_contact_entries && filter_contact_entries.company_id && companyForUser.id) ? (filter_contact_entries.interval === 'all') ? '' : (filter_contact_entries.start_date.length > 0) ? filter_contact_entries.start_date : format(yearCurrent, `yyyy-MM-dd'T'HH:mm:ss.SSS`) : format(yearCurrent, `yyyy-MM-dd'T'HH:mm:ss.SSS`)
        filter.end_date = (filter_contact_entries && filter_contact_entries.company_id && companyForUser.id) ? (filter_contact_entries.interval === 'all') ? '' : (filter_contact_entries.end_date.length > 0) ? filter_contact_entries.end_date : format(dateCurrent, `yyyy-MM-dd'T'HH:mm:ss.SSS`) : format(dateCurrent, `yyyy-MM-dd'T'HH:mm:ss.SSS`)

        setTable({body: [], foot: []})
        setAccountingEntries(null)

        loadAccountingContactEntries(companyForUser.id, param.id)
      } else if (filter_contact_entries && filter_contact_entries.company_id === companyForUser.id && filter_contact_entries.contact_id.length > 0) {
        navigate("/app/contact/entry/" + filter_contact_entries.contact_id)
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyForUser, param])

  useEffect( () => {
    setMounted(true)
    setRoute({path: {root: lang.labels.contacts, branch: lang.labels.viewAccountingEntry}, company: false})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
    { companyForUser && contactsForCompany
      ?
      <>
        <div className="card card-flush mb-5">
          <div className="card-header">
            <div className="card-title">
              <div className="d-flex align-items-center position-relative">
                <span className="svg-icon svg-icon-1 position-absolute ms-4">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black" />
                    <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black" />
                  </svg>
                </span>
                <Dropdown onToggle={uploadDropdown}>
                  <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-350px p-0">
                    <span className="selection">
                      <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                        <span className="select2-selection__rendered" role="textbox">
                          <span className="select2-selection__placeholder">
                            {param.id && param.id !== "none" ? contactSelected(lang, contactsForCompany, param.id) : lang.labels.searchContact}
                          </span>
                        </span>
                      </span>
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                    <span className="select2-dropdown select2-dropdown--below">
                      <span className="select2-search select2-search--dropdown">
                        <input className="select2-search__field" type="text" name="contact" value={searchContact.text} onChange={handleChangeSearchContact} />
                      </span>
                      <span className="select2-results">
                        <ul className="select2-results__options" role="listbox">
                          { searchContact.list.length > 0
                            ?
                            <>
                              { searchContact.list.map (( (item, index) => { return (
                                <li key={index} className={`select2-results__option select2-results__option--selectable ${param.id === item.data.id && "select2-results__option--selected"}`} role="option" aria-selected={param.id === item.data.id}>
                                  <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeContact(item.data.id)}>{item.type === "person" ? `${item.data.first_name} ${item.data.last_name}` : `${item.data.name}`}</Dropdown.Item>
                                </li>
                              )}))}
                            </>
                            :
                            <li className="select2-results__option select2-results__message" role="alert" aria-live="assertive">{lang.labels.noResultsFound}</li>
                          }
                        </ul>
                      </span>
                    </span>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="card-toolbar">
              <div className="d-flex justify-content-end align-items-center">
                <Dropdown>
                  <Dropdown.Toggle variant={`btn ${!button ? "btn-light-primary" : "btn-primary"} mb-1 me-2`}>
                    <div className="d-flex align-items-center">
                      <span className="svg-icon svg-icon-2 ms-0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z" fill="black" />
                        </svg>
                      </span>
                      {lang.labels.filter}
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu variant="menu menu-sub menu-sub-dropdown w-250px w-md-300px" align="end">
                    <div className="mt-5 mx-5">
                      <div className="fs-5 text-dark fw-bolder">{lang.labels.filterOptions}</div>
                    </div>
                    <div className="separator border-gray-200"></div>
                    <div className="mt-3 m-5">
                      <div className="mb-3">
                        <label className="form-label fs-7">{lang.labels.timeInterval}:</label>
                        <Form.Select bsPrefix="form-select form-select-sm form-select-solid fw-bold" placeholder={lang.labels.selectOption} name="filter-interval" value={filter.interval} onChange={handleChangeInterval}>
                          <option value="" className="d-none">{lang.labels.selectOption}</option>
                          <option value="all">{lang.labels.all}</option>
                          <option value="year">{lang.labels.thisYear}</option>
                          <option value="half">{lang.labels.thisHalfYear}</option>
                          <option value="trimester">{lang.labels.thisTrimester}</option>
                          <option value="bimester">{lang.labels.thisBimester}</option>
                          <option value="month">{lang.labels.thisMonth}</option>
                          <option value="week">{lang.labels.thisWeek}</option>
                          <option value="yesterday">{lang.labels.yesterday}</option>
                          <option value="today">{lang.labels.today}</option>
                        </Form.Select>
                      </div>
                      <div className="mb-3">
                        <label className="col-12 col-md-5 col-form-label fs-7 py-2">{lang.labels.startDate}:</label>
                        <input className="col-12 col-md-7 small-input bg-light border border-light rounded" type="date" name="filter-start-date" max={filter.end_date.substring(0, 10)} value={filter.start_date.substring(0, 10)} onChange={handleChangeStartDate} />
                      </div>
                      <div className="mb-5">
                        <label className="col-12 col-md-5 col-form-label fs-7 py-2">{lang.labels.endDate}:</label>
                        <input className="col-12 col-md-7 small-input bg-light border border-light rounded" type="date" name="filter-end-date" min={filter.start_date.substring(0, 10)} value={filter.end_date.substring(0, 10)} onChange={handleChangeEndDate} />
                      </div>
                      <div className="d-flex justify-content-end">
                        <Dropdown.Item bsPrefix="btn btn-sm btn-light btn-active-light-primary" type="reset" onClick={executeFilterReset}>{lang.labels.reset}</Dropdown.Item>
                        <Dropdown.Item bsPrefix="btn btn-sm btn-primary ms-3" type="button" onClick={executeFilterApply}>{lang.labels.apply}</Dropdown.Item>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
                <button className="btn btn-primary me-2" type="button" onClick={executeShowModalContactEntryExport}>
                  <div className="d-flex align-items-center">
                    <span className="svg-icon svg-icon-2 ms-0">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.3" x="12.75" y="4.25" width="12" height="2" rx="1" transform="rotate(90 12.75 4.25)" fill="black" />
                        <path d="M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z" fill="black" />
                        <path d="M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z" fill="#C4C4C4" />
                      </svg>
                    </span>
                    {lang.labels.export}
                  </div>
                </button>
                <button className="btn btn-primary" type="button" onClick={executeShowModalContactEntryCreate}>
                  <div className="d-flex align-items-center">
                    <span className="svg-icon svg-icon-2 ms-0">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                        <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                        <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                      </svg>
                    </span>
                    {lang.labels.addCredit}
                  </div>
                </button>
              </div>
            </div>
          </div>
          { storage && button &&
            <div className="card-scroll d-flex bg-light-primary rounded border-0 p-3 mx-7 mb-4">
              <button className={`btn btn-sm btn-danger text-nowrap fs-8 px-2 py-1 mx-1 ${storage.interval.length === 0 && "d-none"}`} type="button" onClick={() => executeFilterRemove("interval")}>
                <AppStorageRemove attribute={lang.labels.interval} description={storage.interval}></AppStorageRemove>
              </button>
              <button className={`btn btn-sm btn-danger text-nowrap fs-8 px-2 py-1 mx-1 ${storage.start_date.length === 0 && "d-none"}`} type="button" onClick={() => executeFilterRemove("start_date")}>
                <AppStorageRemove attribute={lang.labels.start} description={dateFormatAbbreviated(lang.code, storage.start_date)}></AppStorageRemove>
              </button>
              <button className={`btn btn-sm btn-danger text-nowrap fs-8 px-2 py-1 mx-1 ${storage.end_date.length === 0 && "d-none"}`} type="button" onClick={() => executeFilterRemove("end_date")}>
                <AppStorageRemove attribute={lang.labels.end} description={dateFormatAbbreviated(lang.code, storage.end_date)}></AppStorageRemove>
              </button>
            </div>
          }
        </div>
        <div className="card">
          <div className="card-body p-0">
            <div className="dataTables_wrapper border border-2 rounded">
              <div className="table-responsive">
                <table className="table align-middle table-row-bordered table-row-solid gx-10 mb-2">
                  <thead className="bg-lighten">
                    <tr className="text-dark text-uppercase fw-bolder fs-7">
                      <th className="w-100px text-start">{lang.labels.date}</th>
                      <th className="min-w-300px text-start">{lang.labels.description}</th>
                      <th className="min-w-150px text-end text-nowrap">{lang.labels.debit} ({companyForUser.currency.symbol})</th>
                      <th className="min-w-150px text-end text-nowrap">{lang.labels.credit} ({companyForUser.currency.symbol})</th>
                      <th className="min-w-150px text-end text-nowrap">{lang.labels.balance} ({companyForUser.currency.symbol})</th>
                    </tr>
                  </thead>
                  { param && param.id && param.id !== "none"
                    ?
                    <>
                      { accounitngEntries
                        ?
                        <>
                          { table.body.length > 0
                            ?
                            <tbody className="text-gray-700 fw-bold">
                              { table.body.map (( (item, index) => { return (
                                <tr key={index} className="row-bordered">
                                  <td className="text-start text-nowrap">{item.date}</td>
                                  <td className="text-start">{item.description}</td>
                                  <td className="text-end text-nowrap">{item.debit}</td>
                                  <td className="text-end text-nowrap">{item.credit}</td>
                                  <td className="text-end text-nowrap">{item.balance}</td>
                                </tr>
                              )}))}
                            </tbody>
                            :
                            <tbody>
                              <tr className="text-gray-700 fw-bold row-bordered">
                                <td valign="top" colSpan={5} className="text-center">{lang.labels.noRecordsFound}</td>
                              </tr>
                            </tbody>
                          }
                          <tfoot className="text-gray-800 fw-bolder fs-7">
                            { table.foot.map (( (item, index) => { return (
                              <tr key={index}>
                                <td className="text-start text-nowrap">{item.date}</td>
                                <td className="text-start text-nowrap">{item.description}</td>
                                <td className="text-end text-nowrap">{item.debit}</td>
                                <td className="text-end text-nowrap">{item.credit}</td>
                                <td className="text-end text-nowrap">{item.balance}</td>
                              </tr>
                            )}))}
                          </tfoot>
                        </>
                        :
                        <tbody>
                          <tr>
                            <td colSpan={5}>
                              <div className="d-flex justify-content-center align-items-center w-100 h-200px">
                                <div className="spinner-border" role="status">
                                  <span className="visually-hidden">{lang.labels.loading}</span>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      }
                    </>
                    :
                    <tbody>
                      <tr>
                        <td colSpan={5}>
                          <div className="d-flex flex-column flex-center">
                            <img src={imgSearch} alt={lang.labels.background} className="mw-200px" />
                            <div className="fs-3 fw-bold mb-3">{lang.labels.noRecordsFound}</div>
                            <div className="fs-5">{lang.labels.selectContactViewTheirAccountStatement}</div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  }
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
      :
      <div className="page-preloader d-flex justify-content-center align-items-center">
        <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      </div>
      }
      <AppModalContactEntryCreate companyForUser={companyForUser} accountingAccountsForCompany={accountingAccountsForCompany} treasuryAccountsForCompany={treasuryAccountsForCompany} accountingUnidentifiedEntries={unidentifiedEntries} contact={param.id} loadAccountingUnidentifiedEntries={loadAccountingUnidentifiedEntries} loadAccountingContactEntries={loadAccountingContactEntries}></AppModalContactEntryCreate>
      <AppModalContactEntryExport companyForUser={companyForUser} contactsForCompany={contactsForCompany} table={table} contact={param.id}></AppModalContactEntryExport>
    </div>
  )
};

export default ContactEntryPage;
