import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { Company } from '../models/company.model';
import { Error } from '../models/error.model';
import { Template } from '../models/template.model';
import { InvoiceTemplate } from '../models/invoice.template.model';
import { appToken } from '../scripts/app.token.script';

export class TemplateDA {
  static apiResponse: ApiResponse

  public static async getTemplates(id_company: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let companies: Company[] | null = null
      let templates: Template[] | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/companies.data.json').then(response => { companies = response.data.companies }).catch(error => { alert(error) })
      await axios.get('../../../../../database/templates.data.json').then(response => { templates = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (companies!.some(item => item.id === id_company)) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: templates
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/templates`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.get(url, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async getQrTemplate(id_company: string, id_invoice: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let qrTemplateByInvoice: any | InvoiceTemplate = null
      let error: Error | null = null

      await axios.get('../../../../../database/qr.template.data.json').then(response => { qrTemplateByInvoice = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (qrTemplateByInvoice.id_invoice === id_invoice) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: qrTemplateByInvoice
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/invoices/${id_invoice}/qr`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.get(url, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }
}
