import React, { useEffect, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';

import { UseOutletContextOrder } from './Order';
import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { getError, getMessage } from '../../../../languages/translations/response';
import { OrderDA } from '../../../../services/order.service';
import { Error } from '../../../../models/error.model';
import { Message } from '../../../../models/message.model';
import { Order } from '../../../../models/order.model';
import { legendInvalid } from '../../../../tools/legend.data.entry.tool';
import { isValidObjectAttributes } from '../../../../scripts/compare.object.script';

import AppSaleRecordReference from '../card/SaleRecordReference';
import AppSaleFormDelivery from '../card/SaleFormDelivery';
import AppSaleFormRequirements from '../card/SaleFormRequirements';
import AppSaleFormSignatures from '../card/SaleFormSignatures';
import AppPreloader from '../../../../components/Preloader';

export interface OrderReferencePageProps {};

let errorResponse: Error, messageResponse: Message, orderResponse: Order;

const OrderReferencePage: React.FunctionComponent<OrderReferencePageProps> = props => {
  const {setRoute, companyForUser, settingsForCompany, treasuryAccountsForCompany, productsForCompany, servicesForCompany} = UseOutletContextOrder()
  const {lang} = StateLanguage()
  const param = useParams()
  const navigate = useNavigate()

  const [mounted, setMounted] = useState(false)
  const [loadIndicator, setLoadIndicator] = useState('off')
  const [customer, setCustomer] = useState({value: '', object: null as any, valid: false})
  const [concerning, setConcerning] = useState({value: '', valid: false})
  const [date, setDate] = useState({value: '', valid: false})
  const [validUntil, setValidUntil] = useState({value: '', valid: false})
  const [paymentDeadline, setPaymentDeadline] = useState({value: '', valid: false})
  const [currency, setCurrency] = useState({value: '', object: null as any, valid: false})
  const [treasuryAccount, setTreasuryAccount] = useState({value: '', valid: false})
  const [language, setLanguage] = useState({value: '', valid: false})
  const [discountType, setDiscountType] = useState({value: '', valid: false})
  const [discountValue, setDiscountValue] = useState({value: '', valid: false})
  const [street, setStreet] = useState({value: '', valid: false})
  const [streetNumber, setStreetNumber] = useState({value: '', valid: false})
  const [additional, setAdditional] = useState({value: '', valid: true})
  const [administrativeArea, setAdministrativeArea] = useState({value: '', valid: false})
  const [city, setCity] = useState({value: '', valid: false})
  const [zipCode, setZipCode] = useState({value: '', valid: false})
  const [country, setCountry] = useState({value: '', valid: false})
  const [requirements, setRequirements] = useState({list: false, value: [] as {type: string, description: string, contents: { detail: string, reference: string, quantity: string, price_unit: string, tax_included: boolean, tax_rate: string, rebate_type: string, rebate_value: string, rebate_quantity: string, unit: string }[]}[], valid: [] as {type: boolean, description: boolean, content: boolean, contents: {detail: boolean, quantity: boolean, price_unit: boolean, tax_rate: boolean, rebate_value: boolean, rebate_quantity: boolean, unit: boolean}[]}[]})
  const [signatures, setSignatures] = useState({list: true, value: [] as string[], valid: [] as boolean[]})
  const [order, setOrder] = useState<Order | undefined | null>(null)

  const loadOrder = async (id_company: string, id_order: string) => {
    await OrderDA.getOrder(id_company, id_order).then( (response) => {
      if (response.status === 200) {
        orderResponse = response.data

        let tempRequirementsValue: {type: string, description: string, contents: { detail: string, reference: string, quantity: string, price_unit: string, tax_included: boolean, tax_rate: string, rebate_type: string, rebate_value: string, rebate_quantity: string, unit: string }[]}[] = []
        let tempRequirementsValid: {type: boolean, description: boolean, content: boolean, contents: {detail: boolean, quantity: boolean, price_unit: boolean, tax_rate: boolean, rebate_value: boolean, rebate_quantity: boolean, unit: boolean}[]}[] = []
        let tempSignaturesValue: string[] = []
        let tempSignaturesValid: boolean[] = []

        for (let requirement of orderResponse.requirements) {
          let newRequirimentValue: {type: string, description: string, contents: any[]} = {
            type: requirement.type,
            description: requirement.description,
            contents: []
          }
          let newRequirementValid: {type: boolean, description: boolean, content: boolean, contents: any[]} = {
            type: true,
            description: true,
            content: true,
            contents: []
          }

          for (let content of requirement.contents) {
            let newContentValue: { detail: string, reference: string, quantity: string, price_unit: string, tax_included: boolean, tax_rate: string, rebate_type: string, rebate_value: string, rebate_quantity: string, unit: string } = {
              detail: content.detail,
              reference: content.reference,
              quantity: content.quantity.toString(),
              price_unit: content.price_unit.toString(),
              tax_included: content.tax_included,
              tax_rate: content.tax_rate.toString(),
              rebate_type: content.rebate.type,
              rebate_value: content.rebate.value.toString(),
              rebate_quantity: content.rebate.quantity.toString(),
              unit: content.unit.id
            }
            let newContentValid: {detail: boolean, quantity: boolean, price_unit: boolean, tax_rate: boolean, rebate_value: boolean, rebate_quantity: boolean, unit: boolean} = {
              detail: true,
              quantity: true,
              price_unit: true,
              tax_rate: true,
              rebate_value: true,
              rebate_quantity: true,
              unit: true
            }

            newRequirimentValue.contents.push(newContentValue)
            newRequirementValid.contents.push(newContentValid)
          }

          tempRequirementsValue.push(newRequirimentValue)
          tempRequirementsValid.push(newRequirementValid)
        }

        for (let signature of orderResponse.signatures) {
          tempSignaturesValue.push(signature)
          tempSignaturesValid.push(true)
        }

        setOrder(orderResponse)
        setCustomer({value: orderResponse.customer.data.id, object: orderResponse.customer, valid: true})
        setConcerning({value: orderResponse.concerning, valid: true})
        setDate({value: format((new Date()), 'yyyy-MM-dd'), valid: true})
        setValidUntil({value: format((new Date()), 'yyyy-MM-dd'), valid: true})
        setCurrency({value: orderResponse.currency.code, object: orderResponse.currency, valid: true})
        setTreasuryAccount({value: orderResponse.treasury_account.id, valid: true})
        setLanguage({value: orderResponse.language, valid: true})
        setDiscountType({value: 'none', valid: true})
        setDiscountValue({value: '', valid: true})
        setStreet({value: orderResponse.delivery_address.street, valid: true})
        setStreetNumber({value: orderResponse.delivery_address.number, valid: true})
        setAdditional({value: orderResponse.delivery_address.additional, valid: true})
        setAdministrativeArea({value: orderResponse.delivery_address.administrative_area, valid: true})
        setCity({value: orderResponse.delivery_address.city, valid: true})
        setZipCode({value: orderResponse.delivery_address.zip_code, valid: true})
        setCountry({value: orderResponse.delivery_address.country, valid: true})
        setRequirements({list: true, value: tempRequirementsValue, valid: tempRequirementsValid})
        setSignatures({list: true, value: tempSignaturesValue, valid: tempSignaturesValid})
      } else {
        errorResponse = response.data
        
        Swal.fire({
          title: getError(errorResponse.code, lang.code),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.okGotIt,
          customClass: {confirmButton: 'btn btn-primary'}
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      navigate('/error')
    })
  }

  const submitOrderCreate = async () => {
    setLoadIndicator('on')

    let requerimentsIsValid: boolean = requirements.valid.every((item) => isValidObjectAttributes(item))
    let signaturesIsValid: boolean = signatures.valid.every((item) => (item === true))

    if (companyForUser && order && concerning.valid && date.valid && validUntil.valid && currency.valid && treasuryAccount.valid && language.valid && street.valid && streetNumber.valid && additional.valid && administrativeArea.valid && city.valid && zipCode.valid && country.valid && requirements.list && signatures.list && requerimentsIsValid && signaturesIsValid) {
      let validUntilDate: string = format((new Date(`${validUntil.value}T23:59:59.999`)), `yyyy-MM-dd'T'HH:mm:ss.SSSxxx`)
      let addressStreetNumber: string = (streetNumber.value === 'NN' || streetNumber.value === 'nn') ? streetNumber.value.toUpperCase() : streetNumber.value

      await OrderDA.postOrder(companyForUser.id, customer.value, concerning.value, date.value, validUntilDate, currency.object, treasuryAccount.value, language.value, street.value, addressStreetNumber, additional.value, administrativeArea.value, city.value, zipCode.value, country.value, requirements.value, signatures.value, order.company, order.order_number).then( (response) => {
        if (response.status === 201) {
          messageResponse = response.data

          Swal.fire({
            title: getMessage(messageResponse.message, lang.code),
            text: lang.labels.actionCompletedRedirectingToPage,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            navigate('/app/sale/order/list', {replace: true})
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.code),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        navigate('/error')
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        if (concerning.value.length === 0) {
          legendInvalid('legend-concerning-required')
        }
        if (date.value.length === 0) {
          legendInvalid('legend-date-required')
        }
        if (validUntil.value.length === 0) {
          legendInvalid('legend-valid-until-required')
        }
        if (currency.value.length === 0) {
          legendInvalid('legend-currency-required')
        }
        if (treasuryAccount.value.length === 0) {
          legendInvalid('legend-treasury-account-required')
        }
        if (language.value.length === 0) {
          legendInvalid('legend-language-required')
        }
        if (street.value.length === 0) {
          legendInvalid('legend-street-required')
        }
        if (streetNumber.value.length === 0) {
          legendInvalid('legend-street-number-required')
        }
        if (administrativeArea.value.length === 0) {
          legendInvalid('legend-administrative-area-required')
        }
        if (city.value.length === 0) {
          legendInvalid('legend-city-required')
        }
        if (zipCode.value.length === 0) {
          legendInvalid('legend-zip-code-required')
        }
        if (country.value.length === 0) {
          legendInvalid('legend-country-required')
        }
        if (requirements.value.length === 0) {
          legendInvalid('legend-requirements-required')
        }
        if (!requerimentsIsValid) {
          for (let i = 0; i < requirements.value.length; i++) {
            if (requirements.value[i].contents.length === 0) {
              legendInvalid('legend-requirement-contents-required-' + i)
            } else {
              for (let j = 0; j < requirements.value[i].contents.length; j++) {
                if (requirements.value[i].contents[j].detail.length === 0) {
                  legendInvalid('legend-requirement-content-detail-required-' + i + j)
                }
                if (requirements.value[i].contents[j].unit.length === 0) {
                  legendInvalid('legend-requirement-content-unit-required-' + i + j)
                }
                if (requirements.value[i].contents[j].quantity.length === 0) {
                  legendInvalid('legend-requirement-content-quantity-required-' + i + j)
                }
                if (requirements.value[i].contents[j].price_unit.length === 0) {
                  legendInvalid('legend-requirement-content-price-required-' + i + j)
                }
                if (requirements.value[i].contents[j].tax_rate.length === 0) {
                  legendInvalid('legend-requirement-content-tax-required-' + i + j)
                }
                if ((requirements.value[i].contents[j].rebate_type === 'percentage' || requirements.value[i].contents[j].rebate_type === 'fixed') && (requirements.value[i].contents[j].rebate_value.length === 0 || requirements.value[i].contents[j].rebate_quantity.length === 0)) {
                  legendInvalid('legend-requirement-content-rebate-required-' + i + j)
                }
              }
            }
          }
        }
        if (!signaturesIsValid) {
          for (let i = 0; i < signatures.value.length; i++) {
            if (signatures.value[i].length === 0) {
              legendInvalid('legend-signature-required-' + i)
            }
          }
        }
        setLoadIndicator('off')
      })
    }
  }

  useEffect( () => {
    if (companyForUser && param.id) {
      loadOrder(companyForUser.id, param.id)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyForUser, param])

  useEffect( () => {
    setMounted(true)
    setRoute({path: {root: lang.labels.orders, branch: lang.labels.createOrder}, company: false})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { companyForUser && settingsForCompany && treasuryAccountsForCompany && productsForCompany && servicesForCompany && order
        ?
        <div className="form">
          <AppSaleRecordReference sale={"order"} record={order} customer={customer} concerning={concerning} date={date} validUntil={validUntil} paymentDeadline={paymentDeadline} currency={currency} treasuryAccount={treasuryAccount} language={language} qrType={null} discountType={discountType} discountValue={discountValue}  setCustomer={setCustomer} setConcerning={setConcerning} setDate={setDate} setValidUntil={setValidUntil} setPaymentDeadline={setPaymentDeadline} setCurrency={setCurrency} setTreasuryAccount={setTreasuryAccount} setLanguage={setLanguage} setQrTtype={null} setDiscountType={setDiscountType} setDiscountValue={setDiscountValue}></AppSaleRecordReference>
          <AppSaleFormDelivery customer={order.customer} street={street} streetNumber={streetNumber} additional={additional} administrativeArea={administrativeArea} city={city} zipCode={zipCode} country={country} setStreet={setStreet} setStreetNumber={setStreetNumber} setAdditional={setAdditional} setAdministrativeArea={setAdministrativeArea} setCity={setCity} setZipCode={setZipCode} setCountry={setCountry}></AppSaleFormDelivery>
          <AppSaleFormRequirements currency={currency.object} requirements={requirements} setRequirements={setRequirements}></AppSaleFormRequirements>
          <AppSaleFormSignatures signatures={signatures} setSignatures={setSignatures}></AppSaleFormSignatures>
          <div className="d-flex flex-stack flex-wrap flex-md-nowrap bg-light-primary border border-primary border-dashed rounded h-100 p-5">
            <div className="notice d-flex align-items-center mb-5 mb-md-0 me-0 me-md-5">
              <span className="svg-icon svg-icon-3tx svg-icon-primary me-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM16 13H13V10C13 9.4 12.6 9 12 9C11.4 9 11 9.4 11 10V13H8C7.4 13 7 13.4 7 14C7 14.6 7.4 15 8 15H11V18C11 18.6 11.4 19 12 19C12.6 19 13 18.6 13 18V15H16C16.6 15 17 14.6 17 14C17 13.4 16.6 13 16 13Z" fill="black" />
                  <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black" />
                </svg>
              </span>    
              <div className="d-grid">
                <span className="text-start text-dark fw-bolder fs-5">{lang.labels.processOfCreatingAOrderFromAnotherOrderCompleted}</span>
                <span className="text-justify text-gray-600 fw-bold">
                  {lang.labels.clickOn}
                  <span className="text-primary text-lowercase fw-bolder mx-1">{lang.labels.createOrder}</span>
                  {lang.labels.ifYouWantToSaveTheNewOrderWithTheChangesMade}
                </span>
              </div>
            </div>
            <button className="btn btn-lg btn-primary fw-bolder w-100 w-md-300px" type="button" data-kt-indicator={loadIndicator} onClick={submitOrderCreate}>
              <span className="indicator-label text-uppercase text-nowrap">{lang.labels.createOrder}</span>
              <span className="indicator-progress">
              {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
        </div>
        :
        <AppPreloader></AppPreloader>
      }
    </div>
  )
}

export default OrderReferencePage;
