import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { authUserLoggedIn } from '../../scripts/auth.user.script';

import logo from '../../assets/images/svg/logo-official.svg';
import background from '../../assets/images/themes/image-access.png';

export interface HomePageProps {};

const HomePage: React.FunctionComponent<HomePageProps> = props => {
  const {lang} = StateLanguage()
  const navigate = useNavigate()

  useEffect( () => {
    setTimeout( () => {
      if (authUserLoggedIn()) {
        navigate('/app/dashboard', {replace: true})
      } else {
        navigate('/auth/login', {replace: true})
      }
    }, 5700 )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="d-flex flex-column flex-root h-100">
      <div className="d-flex flex-column flex-column-fluid">
        <div className="d-flex flex-column flex-column-fluid text-center p-10">
          <div className="pt-lg-10 cursor-pointer">
            <img src={logo} alt={lang.labels.digitalAdmin} className="h-75px mb-5" />
          </div>
          <div className="mt-5 mt-lg-10">
            <h1 className="fs-2qx text-dark mb-5">{lang.labels.welcomeToDigitalAdmin}</h1>
            <div className="fw-bold fs-3 text-gray-400">
              {lang.labels.planYourBusinessApplications}
              <br />
              {lang.labels.ourServicesOrRequesting}
            </div>
          </div>
          <div className="load-container d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-275px min-h-lg-325px" style={{backgroundImage: `url(${background})`}}>
            <div className="div-3d ui">
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
              <div className="div-3d load-text">{lang.labels.digitalAdminApp}</div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-center flex-column-auto p-10">
          <div className="d-flex align-items-center fw-bold fs-6">
            <a href="https://digitaladmin.ch" className="text-muted text-hover-primary px-2">{lang.labels.about}</a>
            <a href="mailto:contact@digitaladmin.ch" className="text-muted text-hover-primary px-2">{lang.labels.purchase}</a>
            <a href="mailto:support@digitaladmin.ch" className="text-muted text-hover-primary px-2">{lang.labels.support}</a>
          </div>
        </div>
      </div>
    </div>
  )
};

export default HomePage;
