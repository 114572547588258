import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { UseOutletContext } from './Main';
import { StateLanguage } from '../../languages/config/StateLanguage';
import { modalShow } from '../../tools/modal.tool';

import AppModalNotificationWelcome from '../../components/modal/ModalNotificationWelcome';

import background from '../../assets/images/themes/image-access.png';

export interface WelcomePageProps {};

const WelcomePage: React.FunctionComponent<WelcomePageProps> = props => {
  const {setRoute, appServiceForUser, notificationsForUser, reloadUserLoggedIn, loadNotificationsForUser} = UseOutletContext()
  const {lang} = StateLanguage()

  const [mounted, setMounted] = useState(false)

  const executeShowModalNotificationMain = () => {
    if (appServiceForUser) {
      loadNotificationsForUser(appServiceForUser.api_key)
      modalShow('modal-notification-welcome')
    }
  }

  useEffect ( () => {
    setMounted(true)
    setRoute({path: null, company: false})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      <div className="card">
        <div className="card-body">
          <div className="card-px text-center mb-5">
            <h1 className="fs-2x mb-10">{lang.labels.welcomeToDigitalAdmin}</h1>
            <p className="text-gray-500 fs-5 fw-semibold mb-10">
              {lang.labels.thereAreNoCompaniesAddedYet}
              <br/>
              {lang.labels.kickstartYourBusinessByAddingAYourFirstCompany}
            </p>
            <Link to="/app/company/create" className="btn btn-primary m-3">{lang.labels.addCompany}</Link>
            <button className="btn btn-primary m-3" type="button" onClick={executeShowModalNotificationMain}>{lang.labels.viewInvitations}</button>
          </div>
          <div className="text-center">
            <img src={background} alt={lang.labels.background} className="mw-100 mh-200px mh-lg-250px" />
          </div>
        </div>
      </div>
      <AppModalNotificationWelcome appServiceForUser={appServiceForUser} notificationsForUser={notificationsForUser} reloadUserLoggedIn={reloadUserLoggedIn}></AppModalNotificationWelcome>
    </div>
  )
};

export default WelcomePage;
