import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';

import { UseOutletContextProduct } from '../Product';
import { StateLanguage } from '../../../../../languages/config/StateLanguage';
import { removeTooltip, uploadTooltip } from '../../../../../tools/tooltip.tool';
import { modalShow } from '../../../../../tools/modal.tool';
import { moneyFormat, taxRateFormat } from '../../../../../scripts/format.value.script';
import { imageProduct } from '../../../../../scripts/image.value.script';
import { measureUnitSelected } from '../../../../../scripts/list.selected.item.script';
import { discountDescription, discountType } from '../../../../../libraries/discount.library';

import AppModalProductVariantEdit from '../../../../../components/modal/ModalProductVariantEdit';

import imgDefault from '../../../../../assets/images/default.png';

export interface AppProductVariantViewProps {
  newTax: {main_modal: string | null, index: number | null, sub_index: number | null},
  newUnit: {type: string, main_modal: string | null, index: number | null, sub_index: number | null},
  variant: {value: {description_short: string, description_long: string, code: string, sku: string, codebar: string, size: string, color: string, price: string, tax_rate: string, unit: string, discount: {type: string, value: string, quantity: string}, photos: {view: string, file: any}[]}, valid: {description_short: boolean, description_long: boolean, code: boolean, sku: boolean, codebar: boolean, size: boolean, color: boolean, price: boolean, tax_rate: boolean, unit: boolean, discount: {type: boolean, value: boolean, quantity: boolean}, photos: {file: boolean, message: string}[], gallery: boolean}},
  variants: {value: {description_short: string, description_long: string, code: string, sku: string, codebar: string, size: string, color: string, price: string, tax_rate: string, unit: string, discount: {type: string, value: string, quantity: string}, photos: {view: string, file: any}[]}[], valid: {description_short: boolean, description_long: boolean, code: boolean, sku: boolean, codebar: boolean, size: boolean, color: boolean, price: boolean, tax_rate: boolean, unit: boolean, discount: {type: boolean, value: boolean, quantity: boolean}, photos: {file: boolean, message: string}[], gallery: boolean}[]},
  setNewTax: Dispatch<SetStateAction<{main_modal: string | null, index: number | null, sub_index: number | null}>>,
  setNewUnit: Dispatch<SetStateAction<{type: string, main_modal: string | null, index: number | null, sub_index: number | null}>>,
  setVariantDefault: Dispatch<SetStateAction<{value: {description_short: string, description_long: string, code: string, sku: string, codebar: string, size: string, color: string, price: string, tax_rate: string, unit: string, discount: {type: string, value: string, quantity: string}, photos: {view: string, file: any}[]}, valid: {description_short: boolean, description_long: boolean, code: boolean, sku: boolean, codebar: boolean, size: boolean, color: boolean, price: boolean, tax_rate: boolean, unit: boolean, discount: {type: boolean, value: boolean, quantity: boolean}, photos: {file: boolean, message: string}[], gallery: boolean}}>>,
  setVariant: Dispatch<SetStateAction<{value: {description_short: string, description_long: string, code: string, sku: string, codebar: string, size: string, color: string, price: string, tax_rate: string, unit: string, discount: {type: string, value: string, quantity: string}, photos: {view: string, file: any}[]}, valid: {description_short: boolean, description_long: boolean, code: boolean, sku: boolean, codebar: boolean, size: boolean, color: boolean, price: boolean, tax_rate: boolean, unit: boolean, discount: {type: boolean, value: boolean, quantity: boolean}, photos: {file: boolean, message: string}[], gallery: boolean}}>>
  setVariants: Dispatch<SetStateAction<{value: {description_short: string, description_long: string, code: string, sku: string, codebar: string, size: string, color: string, price: string, tax_rate: string, unit: string, discount: {type: string, value: string, quantity: string}, photos: {view: string, file: any}[]}[], valid: {description_short: boolean, description_long: boolean, code: boolean, sku: boolean, codebar: boolean, size: boolean, color: boolean, price: boolean, tax_rate: boolean, unit: boolean, discount: {type: boolean, value: boolean, quantity: boolean}, photos: {file: boolean, message: string}[], gallery: boolean}[]}>>
};

const AppProductVariantView: React.FunctionComponent<AppProductVariantViewProps> = ({newTax, newUnit, variant, variants, setNewTax, setNewUnit, setVariantDefault, setVariant, setVariants}) => {
  const {companyForUser, settingsForCompany} = UseOutletContextProduct()
  const {lang} = StateLanguage()

  const [idxVariant, setIdxVariant] = useState<number | null>(null)

  const executeShowModalProductVariantEdit = (index: number) => {
    setVariant({value: variants.value[index], valid: variants.valid[index]})
    setIdxVariant(index)

    setNewTax({...newTax, main_modal: 'modal-product-variant-edit'})
    setNewUnit({...newUnit, main_modal: 'modal-product-variant-edit'})
    modalShow('modal-product-variant-edit')
  }

  const executeShowModalProductVariantRemove = (index: number) => {
    let tempVariantsValue = variants.value.filter((_, temp_index) => (temp_index !== index))
    let tempVariantsValid = variants.valid.filter((_, temp_index) => (temp_index !== index))

    if (tempVariantsValue.length > 0 && tempVariantsValid.length > 0) {
      setVariantDefault({value: tempVariantsValue[0], valid: tempVariantsValid[0]})
    }

    setVariants({value: (tempVariantsValue.length > 1) ? tempVariantsValue : [], valid: (tempVariantsValid.length > 1) ? tempVariantsValid : []})
  }

  useEffect( () => {
    uploadTooltip()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyForUser, settingsForCompany])

  return (
    <>
      { companyForUser && settingsForCompany &&
        <>
          { variants.value.map (( (item, index) => { return (
            <div key={index} className="col d-flex flex-column bg-white border border-dashed border-gray-400 rounded p-5 m-2">
              <div className="d-flex flex-stack align-items-start mb-3">
                <div className="d-flex flex-column">
                  <label className="d-flex justify-content-start align-items-center">
                    <span className="svg-icon svg-icon-2 svg-icon-dark me-2">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.3" d="M20 22H4C3.4 22 3 21.6 3 21V2H21V21C21 21.6 20.6 22 20 22Z" fill="black"/>
                        <path d="M12 14C9.2 14 7 11.8 7 9V5C7 4.4 7.4 4 8 4C8.6 4 9 4.4 9 5V9C9 10.7 10.3 12 12 12C13.7 12 15 10.7 15 9V5C15 4.4 15.4 4 16 4C16.6 4 17 4.4 17 5V9C17 11.8 14.8 14 12 14Z" fill="black"/>
                      </svg>
                    </span>
                    <strong className="text-gray-900 fs-6">
                      {item.description_short}
                    </strong>
                  </label>
                  <small className="text-gray-800 text-justify">
                    {item.description_long}
                  </small>
                </div>
                <div className="d-flex justify-content-end align-items-center ms-5">
                  <button className="btn btn-icon btn-flex btn-primary btn-light-primary w-30px h-30px ms-2" type="button" onClick={() => executeShowModalProductVariantEdit(index)}>
                    <span className="svg-icon svg-icon-3" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="left" data-bs-original-title={lang.labels.edit} onClick={removeTooltip}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="black"></path>
                        <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="black"></path>
                      </svg>
                    </span>
                  </button>
                  <button className="btn btn-icon btn-flex btn-primary btn-light-primary w-30px h-30px ms-2" type="button" onClick={() => executeShowModalProductVariantRemove(index)}>
                    <span className="svg-icon svg-icon-3" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="left" data-bs-original-title={lang.labels.remove} onClick={removeTooltip}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="black"></path>
                        <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="black"></path>
                        <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="black"></path>
                      </svg>
                    </span>
                  </button>
                </div>
              </div>
              <div className="d-flex flex-row">
                <div className="flex-row-auto me-5">
                  <table className="table fw-bold gs-0 gy-0 m-0">
                    <tbody>
                      <tr>
                        <td className="w-100px text-muted fs-7">{lang.labels.code}:</td>
                        <td className="min-w-100px text-gray-800 text-nowrap text-capitalize">{(item.code.length > 0) ? item.code : lang.labels.none}</td>
                      </tr>
                      <tr>
                        <td className="w-100px text-muted fs-7">{lang.labels.sku}:</td>
                        <td className="min-w-100px text-gray-800 text-nowrap text-capitalize">{(item.sku.length > 0) ? item.sku : lang.labels.none}</td>
                      </tr>
                      <tr>
                        <td className="w-100px text-muted fs-7">{lang.labels.codebar}:</td>
                        <td className="min-w-100px text-gray-800 text-nowrap text-capitalize">{(item.codebar.length > 0) ? item.codebar : lang.labels.none}</td>
                      </tr>
                      <tr>
                        <td className="w-100px text-muted fs-7">{lang.labels.size}:</td>
                        <td className="min-w-100px text-gray-800 text-nowrap text-capitalize">{(item.size.length > 0) ? item.size : lang.labels.none}</td>
                      </tr>
                      <tr>
                        <td className="w-100px text-muted fs-7">{lang.labels.color}:</td>
                        <td className="min-w-100px text-gray-800 text-nowrap text-capitalize">{(item.color.length > 0) ? item.color : lang.labels.none}</td>
                      </tr>
                      <tr>
                        <td className="w-100px text-muted fs-7">{lang.labels.unit}:</td>
                        <td className="min-w-100px text-gray-800 text-nowrap text-capitalize">{measureUnitSelected(lang, settingsForCompany.measure_units, "product", item.unit)}</td>
                      </tr>
                      <tr>
                        <td className="w-100px text-muted fs-7">{lang.labels.price}:</td>
                        <td className="min-w-100px text-gray-800 text-nowrap">{companyForUser.currency.symbol} {moneyFormat(Number(item.price))}</td>
                      </tr>
                      <tr>
                        <td className="w-100px text-muted fs-7">{lang.labels.tax}:</td>
                        <td className="min-w-100px text-gray-800 text-nowrap">{taxRateFormat(item.tax_rate)}</td>
                      </tr>
                      <tr>
                        <td className="w-100px text-muted fs-7">{lang.labels.discount}:</td>
                        <td className="min-w-100px text-gray-800 text-nowrap text-capitalize">
                          {discountDescription(lang, companyForUser.currency, {type: item.discount.type, value: Number(item.discount.value), quantity: Number(item.discount.quantity)})}
                          { item.discount.type !== "none" &&
                            <span className="badge badge-light fs-9 text-uppercase ms-2">{discountType(lang, {type: item.discount.type, value: Number(item.discount.value), quantity: Number(item.discount.quantity)})}</span>
                          }
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="flex-row-fluid mt-3">
                  { item.photos.length > 0
                    ?
                    <Carousel autoPlay={true} showDots={true} infinite={true} partialVisible={false} arrows={false} responsive={{desktop: {breakpoint: {max: 3000, min: 992}, items: 4}, tablet: {breakpoint: {max: 991, min: 576}, items: 3}, mobile: {breakpoint: {max: 475, min: 0}, items: 2}}}>
                      { item.photos.map ((item, index) => {
                        return (
                          <div key={index} className="overflow-hidden px-1 pb-7">
                            <img src={imageProduct(item.view)} alt={lang.labels.photography + index} className="w-100 h-125px" />
                          </div>
                        )
                      })}
                    </Carousel>
                    :
                    <div className="d-flex justify-content-center align-content-center">
                      <img src={imgDefault} className="img-fluid h-150px" alt={lang.labels.photography} />
                    </div>
                  }
                </div>
              </div>
            </div>
          )}))}
        </>
      }
      <AppModalProductVariantEdit settingsForCompany={settingsForCompany} idxVariant={idxVariant} newTax={newTax} newUnit={newUnit} variant={variant} variants={variants} setIdxVariant={setIdxVariant} setNewTax={setNewTax} setNewUnit={setNewUnit} setVariant={setVariant} setVariants={setVariants}></AppModalProductVariantEdit>
    </>
  )
};

export default AppProductVariantView;
