import React from 'react';

import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { Quote } from '../../../../models/quote.model';
import { Order } from '../../../../models/order.model';
import { Invoice } from '../../../../models/invoice.model';
import { dateFormatAbbreviated, datetimeFormat12h } from '../../../../scripts/datetime.format.script';
import { countryName } from '../../../../libraries/country.library';
import { languageValue } from '../../../../libraries/language.library';
import { qrTemplateValue } from '../../../../libraries/qr.template.library';
import { contactTypeValue } from '../../../../libraries/contact.type.library';

export interface AppSaleViewDetailProps {
  sale: string,
  record: any | Quote | Order | Invoice
};

const AppSaleViewDetail: React.FunctionComponent<AppSaleViewDetailProps> = ({sale, record}) => {
  const {lang} = StateLanguage()

  const labels: {[key: string]: {reference: string, date: string}} = {
    quote: {reference: lang.labels.quoteReference, date: lang.labels.quoteDate},
    order: {reference: lang.labels.orderReference, date: lang.labels.orderDate},
    invoice: {reference: lang.labels.invoiceReference, date: lang.labels.invoiceDate}
  }

  return (
    <div className="card-body">
      <div className="d-flex flex-wrap">
        <div className="flex-equal me-5">
          <table className="table align-middle gy-1 m-0">
            <tbody className="fw-bold">
              { (sale === "order" || sale === "invoice") &&
                <tr>
                  <td className="w-200px text-gray-500">{lang.labels.quoteNumber}:</td>
                  <td className="min-w-200px text-gray-800">{record.quote.id.length > 0 ? record.quote.quote_number : lang.labels.none}</td>
                </tr>
              }
              { sale === "invoice" &&
                <tr>
                  <td className="w-200px text-gray-500">{lang.labels.orderNumber}:</td>
                  <td className="min-w-200px text-gray-800">{record.order.id.length > 0 ? record.order.order_number : lang.labels.none}</td>
                </tr>
              }
              <tr>
                <td className="w-200px text-gray-500">{labels[sale].reference}:</td>
                <td className="min-w-200px text-gray-800">{record.reference.length > 0 ? record.reference : lang.labels.none}</td>
              </tr>
              <tr>
                <td className="w-200px text-gray-500">{lang.labels.concerning}:</td>
                <td className="min-w-200px text-gray-800">{record.concerning}</td>
              </tr>
              <tr>
                <td className="w-200px text-gray-500">{lang.labels.company}:</td>
                <td className="min-w-200px text-gray-800">{record.company.name}</td>
              </tr>
              <tr>
                <td  valign="top" className="w-200px text-gray-500">{lang.labels.customer}:</td>
                <td className="min-w-200px text-gray-800">
                  <div className="d-flex align-items-center">
                    {record.customer.type === "person" ? `${record.customer.data.first_name} ${record.customer.data.last_name}` : `${record.customer.data.name}`}
                    <span className="badge badge-sm badge-light text-uppercase fs-9 ms-3">{contactTypeValue(lang, record.customer.type)}</span>
                  </div>
                  <p className="text-gray-700 fs-8 p-0 m-0">{record.customer.data.address.street} {record.customer.data.address.number}</p>
                  <p className="text-gray-700 fs-8 p-0 m-0">{record.customer.data.address.city} - {record.customer.data.address.zip_code}</p>
                  <p className="text-gray-700 fs-8 p-0 m-0">{countryName(lang.code, record.customer.data.address.country)}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex-equal">
          <table className="table align-middle gy-1 m-0">
            <tbody className="fw-bold">
              <tr>
                <td className="w-200px text-gray-500">{labels[sale].date}:</td>
                <td className="min-w-200px text-gray-800">{dateFormatAbbreviated(lang.code, record.created_date)}</td>
              </tr>
              <tr>
                <td className="w-200px text-gray-500">{lang.labels.createdAt}:</td>
                <td className="min-w-200px text-gray-800">{datetimeFormat12h(lang.code, record.created_at)}</td>
              </tr>
              <tr>
                <td className="w-200px text-gray-500">{lang.labels.validUntil}:</td>
                <td className="min-w-200px text-gray-800">{datetimeFormat12h(lang.code, record.valid_until)}</td>
              </tr>
              { sale === "order" &&
                <tr>
                  <td className="w-200px text-gray-500">{lang.labels.deliveyAddress}:</td>
                  <td className="min-w-200px text-gray-800">{record.delivery_address.number} {record.delivery_address.street}, {record.delivery_address.zip_code}, {record.delivery_address.city}, {countryName(lang.code, record.delivery_address.country)}</td>
                </tr>
              }
              <tr>
                <td className="w-200px text-gray-500">{lang.labels.currency}:</td>
                <td className="min-w-200px text-gray-800">{record.currency.code}: {record.currency.name} ({record.currency.symbol})</td>
              </tr>
              <tr>
                <td className="w-200px text-gray-500">{lang.labels.treasuryAccount}:</td>
                <td className="min-w-200px text-gray-800">{record.treasury_account.name}</td>
              </tr>
              <tr>
                <td className="w-200px text-gray-500">{lang.labels.language}:</td>
                <td className="min-w-200px text-gray-800">{languageValue(lang, record.language)}</td>
              </tr>
              { sale === "invoice" &&
                <tr>
                  <td className="w-200px text-gray-500">{lang.labels.qrType}:</td>
                  <td className="min-w-200px text-gray-800">{qrTemplateValue(lang, record.qr_type)}</td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
};

export default AppSaleViewDetail;
