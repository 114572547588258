import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { Message } from '../models/message.model';
import { Quote } from '../models/quote.model';
import { Company } from '../models/company.model';
import { Currency } from '../models/currency.model';
import { Address } from '../models/address.model';
import { CompanyQuotes } from '../models/company.quotes.model';
import { appToken } from '../scripts/app.token.script';

export class QuoteDA {
  static apiResponse: ApiResponse

  public static async getQuotes(id_company: string, interval: string, start_date: string, end_date: string, contact_id: string, treasury_id: string, status: string, expiration: string, sort_field: string, sort_mode: string, group_by: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let quotesByCompany: CompanyQuotes | null = null
      let quoteGroupsByCompany: CompanyQuotes | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/quotes.list.data.json').then(response => { quotesByCompany = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/quotes.group.data.json').then(response => { quoteGroupsByCompany = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (quotesByCompany!.id_company === id_company || quoteGroupsByCompany!.id_company === id_company) {
        if (group_by.length === 0) {
          quotesByCompany!.filter.interval = interval
          quotesByCompany!.filter.start_date = start_date
          quotesByCompany!.filter.end_date = end_date
          quotesByCompany!.filter.contact = contact_id
          quotesByCompany!.filter.treasury = treasury_id
          quotesByCompany!.filter.status = status
          quotesByCompany!.filter.expiration = expiration
          quotesByCompany!.filter.sort_field = sort_field
          quotesByCompany!.filter.sort_mode = sort_mode
          quotesByCompany!.filter.group_by = group_by
        } else {
          quoteGroupsByCompany!.filter.interval = interval
          quoteGroupsByCompany!.filter.start_date = start_date
          quoteGroupsByCompany!.filter.end_date = end_date
          quoteGroupsByCompany!.filter.contact = contact_id
          quoteGroupsByCompany!.filter.treasury = treasury_id
          quoteGroupsByCompany!.filter.status = status
          quoteGroupsByCompany!.filter.expiration = expiration
          quoteGroupsByCompany!.filter.sort_field = sort_field
          quoteGroupsByCompany!.filter.sort_mode = sort_mode
          quoteGroupsByCompany!.filter.group_by = group_by
        }

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: (group_by.length === 0) ? quotesByCompany : quoteGroupsByCompany
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let startOfDay: Date = new Date(new Date().setHours(0, 0, 0, 0))
      let endOfDay: Date = new Date(new Date().setHours(23, 59, 59, 999))
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/quotes?interval=${interval}&start_date=${start_date}&end_date=${end_date}&contact=${contact_id}&treasury_account=${treasury_id}&status=${status}&expiration=${expiration}&sort_field=${sort_field}&sort_mode=${sort_mode}&group_by=${group_by}&start_day=${startOfDay}&end_day=${endOfDay}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.get(url, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async getQuote(id_company: string, id_quote: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let quote: Quote | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/quote.data.json').then(response => { quote = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (quote!.id === id_quote) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: quote
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/quotes/${id_quote}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.get(url, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async postQuote(id_company: string, id_customer: string, concerning: string, date: string, valid_until: string, currency: Currency, id_treasury_account: string, language: string, requirements: { type: string, description: string, contents: { detail: string, reference: string, quantity: string, price_unit: string, tax_included: boolean, tax_rate: string, rebate_type: string, rebate_value: string, rebate_quantity: string, unit: string }[] }[], signatures: string[], company_data: { uuid: string, name: string, logo: string, address: Address }, reference: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let company: Company | null = null
      let message: Message | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/company.data.json').then(response => { company = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/message.data.json').then(response => { message = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (company!.id === id_company) {
        this.apiResponse = {
          status: 201,
          message: 'Success',
          data: message
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/quotes`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      let data: any = {
        concerning: concerning,
        signatures: signatures,
        company: company_data,
        valid_until: valid_until,
        currency: currency,
        treasury_account: id_treasury_account,
        customer: id_customer,
        requirements: requirements,
        created_date: date,
        language: language,
        reference: reference
      }

      await axios.post(url, data, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async putQuote(id_company: string, id_quote: string, concerning: string, date: string, valid_until: string, currency: Currency, id_treasury_account: string, language: string, requirements: { type: string, description: string, contents: { detail: string, reference: string, quantity: string, price_unit: string, tax_included: boolean, tax_rate: string, rebate_type: string, rebate_value: string, rebate_quantity: string, unit: string }[] }[], signatures: string[]): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let quote: Quote | null = null
      let message: Message | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/quote.data.json').then(response => { quote = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/message.data.json').then(response => { message = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (quote!.id === id_quote) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: message
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/quotes/${id_quote}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      let data: any = {
        concerning: concerning,
        signatures: signatures,
        requirements: requirements,
        valid_until: valid_until,
        language: language,
        currency: currency,
        treasury_account: id_treasury_account,
        created_date: date
      }

      await axios.put(url, data, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async putQuoteStatus(id_company: string, id_quote: string, new_status: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let quote: Quote | null = null
      let error: Error | null = null
      if (quote!.id === id_quote) {
        quote!.status = new_status

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: quote
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/quotes/${id_quote}/status`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      let data: any = {
        status: new_status
      }

      await axios.put(url, data, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async sendQuote(id_company: string, id_quote: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let quote: Quote | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/quote.data.json').then(response => { quote = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (quote!.id === id_quote) {
        quote!.status = 'pending'

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: quote
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/quote/${id_quote}/send`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.post(url, null, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }
}
