import React, { useEffect, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

import { UseOutletContextOrder } from './Order';
import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { getError, getMessage } from '../../../../languages/translations/response';
import { OrderDA } from '../../../../services/order.service';
import { InvoiceDA } from '../../../../services/invoice.service';
import { NotificationDL } from '../../../../services/notification.service';
import { Error } from '../../../../models/error.model';
import { Message } from '../../../../models/message.model';
import { Order } from '../../../../models/order.model';
import { Invoice } from '../../../../models/invoice.model';
import { modalShow } from '../../../../tools/modal.tool';
import { toastError, toastSuccess } from '../../../../tools/toast.tool';
import { colorStatusOrder } from '../../../../scripts/badge.color.script';
import { statusDocumentValue } from '../../../../libraries/status.document.library';

import AppSaleViewDetail from '../card/SaleViewDetail';
import AppSaleViewRequirements from '../card/SaleViewRequirements';
import AppSaleViewAmount from '../card/SaleViewAmount';
import AppSaleViewSignatures from '../card/SaleViewSignatures';
import AppButtonOrderExport from '../../../../components/button/ButtonOrderExport';
import AppModalOrderNotification from '../../../../components/modal/ModalOrderNotification';
import AppPreloader from '../../../../components/Preloader';

export interface OrderViewPageProps {};

let errorResponse: Error, messageResponse: Message, orderResponse: Order, invoiceResponse: Invoice;

const OrderViewPage: React.FunctionComponent<OrderViewPageProps> = props => {
  const {setRoute, userLoggedIn, appServiceForUser, companyForUser, settingsForCompany} = UseOutletContextOrder()
  const {lang} = StateLanguage()
  const param = useParams()
  const navigate = useNavigate()

  const [mounted, setMounted] = useState(false)
  const [order, setOrder] = useState<Order | undefined | null>(null)

  const loadOrder = async (id_company: string, id_order: string) => {
    await OrderDA.getOrder(id_company, id_order).then( (response) => {
      if (response.status === 200) {
        orderResponse = response.data
        setOrder(orderResponse)
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.code),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.okGotIt,
          customClass: {confirmButton: 'btn btn-primary'}
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      navigate('/error')
    }) 
  }

  const submitOrderStatus = async (item: string) => {
    if (appServiceForUser && companyForUser && order && item === 'approved') {
      Swal.fire({
        title: `${lang.labels.youWantToChangeOrderStatusTo} ${statusDocumentValue(lang, item).toLowerCase()}?`,
        text: lang.labels.youWillNotBeAbleToUndo,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `${lang.labels.yes}, ${statusDocumentValue(lang, item).toLowerCase()}`,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary'}
      }).then(async (result) => {
        if (result.isConfirmed) {
          await OrderDA.putOrderStatus(companyForUser.id, order.id, item).then( async (response) => {
            if (response.status === 200) {
              orderResponse = response.data

              if (order.customer.is_user) {
                let typeNotification: string = 'activity'
                let descriptionNotification: string = 'order evaluation'
                let commentNotification: string = ''
                let responseNotification: string = 'approve'

                await NotificationDL.postNotification(appServiceForUser.api_key, typeNotification, descriptionNotification, order.customer.data.uuid, commentNotification, responseNotification, null, orderResponse).then( (response) => {
                  setOrder(orderResponse)

                  if (response.status === 201) {
                    messageResponse = response.data
                    toastSuccess(getMessage(messageResponse.message, lang.code))
                  } else {
                    errorResponse = response.data
                    toastError(getError(errorResponse.code, lang.code))
                  }

                  Swal.fire({
                    title: lang.labels.successfullyUpdatedStatus,
                    text: lang.labels.actionCompletedReturningToPage,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1800
                  } as SweetAlertOptions)
                }).catch( (error) => {
                  console.error(error)
                  navigate('/error')
                })
              } else {
                setOrder(orderResponse)

                Swal.fire({
                  title: lang.labels.successfullyUpdatedStatus,
                  text: lang.labels.actionCompletedReturningToPage,
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1800
                } as SweetAlertOptions)
              }
            } else {
              errorResponse = response.data

              Swal.fire({
                title: getError(errorResponse.code, lang.code),
                text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
                icon: 'error',
                buttonsStyling: !1,
                confirmButtonText: lang.labels.okGotIt,
                customClass: {confirmButton: 'btn btn-primary'}
              } as SweetAlertOptions)
            }
          }).catch( (error) => {
            console.error(error)
            navigate('/error')
          })
        }
      })
    } else {
      modalShow('modal-order-notification')
    }
  }

  const submitOrderSend = async () => {
    if (appServiceForUser && companyForUser && order) {
      Swal.fire({
        title: lang.labels.youWantToSendTheOrderToCustomer,
        text: lang.labels.youWillNotBeAbleToUndo,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yesSend,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary'}
      }).then(async (result) => {
        if (result.isConfirmed) {
          await OrderDA.sendOrder(companyForUser.id, order.id).then( async (response) => {
            if (response.status === 200) {
              orderResponse = response.data

              if (order.customer.is_user) {
                let typeNotification: string = 'activity'
                let descriptionNotification: string = 'order evaluation'
                let commentNotification: string = ''
                let responseNotification: string = ''

                await NotificationDL.postNotification(appServiceForUser.api_key, typeNotification, descriptionNotification, order.customer.data.uuid, commentNotification, responseNotification, null, orderResponse).then( (response) => {
                  setOrder(orderResponse)

                  if (response.status === 201) {
                    messageResponse = response.data
                    toastSuccess(getMessage(messageResponse.message, lang.code))
                  } else {
                    errorResponse = response.data
                    toastError(getError(errorResponse.code, lang.code))
                  }

                  Swal.fire({
                    title: lang.labels.successfullyOrderSent,
                    text: lang.labels.actionCompletedReturningToPage,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1800
                  } as SweetAlertOptions)
                }).catch( (error) => {
                  console.error(error)
                  navigate('/error')
                })
              } else {
                setOrder(orderResponse)

                Swal.fire({
                  title: lang.labels.successfullyOrderSent,
                  text: lang.labels.actionCompletedReturningToPage,
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1800
                } as SweetAlertOptions)
              }
            } else {
              errorResponse = response.data

              Swal.fire({
                title: getError(errorResponse.code, lang.code),
                text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
                icon: 'error',
                buttonsStyling: !1,
                confirmButtonText: lang.labels.okGotIt,
                customClass: {confirmButton: 'btn btn-primary'}
              } as SweetAlertOptions)
            }
          }).catch( (error) => {
            console.error(error)
            navigate('/error')
          })
        }
      })
    }
  }

  const submitInvoiceCreate = async () => {
    if (companyForUser && order) {
      Swal.fire({
        title: lang.labels.youWantToGenerateAnInvoiceFromThisOrder,
        text: lang.labels.thisOptionIsUniqueAndNewInvoice,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yesGenerate,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary'}
      }).then(async (result) => {
        if (result.isConfirmed) {
          await InvoiceDA.postInvoiceReference(companyForUser.id, order.id).then( (response) => {
            if (response.status === 201) {
              invoiceResponse = response.data

              Swal.fire({
                title: lang.labels.successfullyCreatedInvoice,
                text: lang.labels.actionCompletedRedirectingToPage,
                icon: 'success',
                showConfirmButton: false,
                timer: 1800
              } as SweetAlertOptions).then( () => {
                navigate('/app/sale/invoice/view/' + invoiceResponse.id)
              })
            } else {
              errorResponse = response.data

              Swal.fire({
                title: getError(errorResponse.code, lang.code),
                text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
                icon: 'error',
                buttonsStyling: !1,
                confirmButtonText: lang.labels.okGotIt,
                customClass: {confirmButton: 'btn btn-primary'}
              } as SweetAlertOptions)
            }
          }).catch( (error) => {
            console.error(error)
            navigate('/error')
          })
        }
      })
    }
  }

  useEffect( () => {
    if (companyForUser && param.id) {
      loadOrder(companyForUser.id, param.id)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyForUser, param])

  useEffect( () => {
    setMounted(true)
    setRoute({path: {root: lang.labels.orders, branch: lang.labels.viewOrder}, company: false})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { userLoggedIn && companyForUser && settingsForCompany && order
        ?
        <>
          <div className="card card-flush mb-5">
            <div className="card-header pt-3">
              <div className="card-title align-items-start flex-column">
                <h1>{order.order_number}</h1>
                <label className="fs-6 fw-bold mt-1">
                  {lang.labels.status}:
                  <span className={`badge badge-light-${colorStatusOrder(order.status)} text-uppercase fs-7 ms-2`}>{statusDocumentValue(lang, order.status)}</span>
                </label>
              </div>
              <div className="card-toolbar">
                <AppButtonOrderExport companyForUser={companyForUser} settingsForCompany={settingsForCompany} order={order}></AppButtonOrderExport>
                { order.status === "in progress" &&
                  <>
                    <Link to={"/app/sale/order/update/" + order.id} className="btn btn-primary mb-1 me-2">
                      <div className="d-flex align-items-center">
                        <span className="svg-icon svg-icon-2 ms-0">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z" fill="black"/>
                            <path d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z" fill="black"/>
                            <path d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z" fill="black"/>
                          </svg>
                        </span>
                        {lang.labels.editOrder}
                      </div>
                    </Link>
                    { order.customer.is_user &&
                      <button className="btn btn-primary mb-1 me-2" type="button" onClick={submitOrderSend}>
                        <div className="d-flex align-items-center">
                          <span className="svg-icon svg-icon-2 ms-0">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M15.43 8.56949L10.744 15.1395C10.6422 15.282 10.5804 15.4492 10.5651 15.6236C10.5498 15.7981 10.5815 15.9734 10.657 16.1315L13.194 21.4425C13.2737 21.6097 13.3991 21.751 13.5557 21.8499C13.7123 21.9488 13.8938 22.0014 14.079 22.0015H14.117C14.3087 21.9941 14.4941 21.9307 14.6502 21.8191C14.8062 21.7075 14.9261 21.5526 14.995 21.3735L21.933 3.33649C22.0011 3.15918 22.0164 2.96594 21.977 2.78013C21.9376 2.59432 21.8452 2.4239 21.711 2.28949L15.43 8.56949Z" fill="black"/>
                              <path opacity="0.3" d="M20.664 2.06648L2.62602 9.00148C2.44768 9.07085 2.29348 9.19082 2.1824 9.34663C2.07131 9.50244 2.00818 9.68731 2.00074 9.87853C1.99331 10.0697 2.04189 10.259 2.14054 10.4229C2.23919 10.5869 2.38359 10.7185 2.55601 10.8015L7.86601 13.3365C8.02383 13.4126 8.19925 13.4448 8.37382 13.4297C8.54839 13.4145 8.71565 13.3526 8.85801 13.2505L15.43 8.56548L21.711 2.28448C21.5762 2.15096 21.4055 2.05932 21.2198 2.02064C21.034 1.98196 20.8409 1.99788 20.664 2.06648Z" fill="black"/>
                            </svg>
                          </span>
                          {lang.labels.sendOrder}
                        </div>
                      </button>
                    }
                    <Dropdown>
                      <Dropdown.Toggle variant="btn btn-primary mb-1 me-2">
                        <div className="d-flex align-items-center">
                          {lang.labels.actions}
                          <span className="svg-icon svg-icon-2 me-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black"></path>
                            </svg>
                          </span>
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu variant="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary w-200px p-3" align="end">
                        <Dropdown.Item bsPrefix="menu-item" onClick={() => submitOrderStatus("approved")}>
                          <p className="menu-link fw-bold px-5 m-0">{lang.labels.approveOrder}</p>
                        </Dropdown.Item>
                        <Dropdown.Item bsPrefix="menu-item" onClick={() => submitOrderStatus("canceled")}>
                          <p className="menu-link fw-bold px-5 m-0">{lang.labels.cancelOrder}</p>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </>
                }
                { userLoggedIn.uuid === order.customer.data.uuid && order.status === "pending" &&
                  <>
                    <button className="btn btn-primary mb-1 me-2" type="button" onClick={() => submitOrderStatus("approved")}>
                      <div className="d-flex align-items-center">
                        <span className="svg-icon svg-icon-2 ms-0">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M10.3 14.3L11 13.6L7.70002 10.3C7.30002 9.9 6.7 9.9 6.3 10.3C5.9 10.7 5.9 11.3 6.3 11.7L10.3 15.7C9.9 15.3 9.9 14.7 10.3 14.3Z" fill="black"/>
                            <path d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM11.7 15.7L17.7 9.70001C18.1 9.30001 18.1 8.69999 17.7 8.29999C17.3 7.89999 16.7 7.89999 16.3 8.29999L11 13.6L7.70001 10.3C7.30001 9.89999 6.69999 9.89999 6.29999 10.3C5.89999 10.7 5.89999 11.3 6.29999 11.7L10.3 15.7C10.5 15.9 10.8 16 11 16C11.2 16 11.5 15.9 11.7 15.7Z" fill="black"/>
                          </svg>
                        </span>
                        {lang.labels.approveOrder}
                      </div>
                    </button>
                    <button className="btn btn-primary mb-1 me-2" type="button" onClick={() => submitOrderStatus("canceled")}>
                      <div className="d-flex align-items-center">
                        <span className="svg-icon svg-icon-2 ms-0">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M12 10.6L14.8 7.8C15.2 7.4 15.8 7.4 16.2 7.8C16.6 8.2 16.6 8.80002 16.2 9.20002L13.4 12L12 10.6ZM10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 8.99999 16.4 9.19999 16.2L12 13.4L10.6 12Z" fill="black"/>
                            <path d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM13.4 12L16.2 9.20001C16.6 8.80001 16.6 8.19999 16.2 7.79999C15.8 7.39999 15.2 7.39999 14.8 7.79999L12 10.6L9.2 7.79999C8.8 7.39999 8.2 7.39999 7.8 7.79999C7.4 8.19999 7.4 8.80001 7.8 9.20001L10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 9 16.4 9.2 16.2L12 13.4L14.8 16.2C15 16.4 15.3 16.5 15.5 16.5C15.7 16.5 16 16.4 16.2 16.2C16.6 15.8 16.6 15.2 16.2 14.8L13.4 12Z" fill="black"/>
                          </svg>
                        </span>
                        {lang.labels.cancelOrder}
                      </div>
                    </button>
                  </>
                }
                { order.status === "approved" &&
                  <button className="btn btn-primary mb-1 me-2" type="button" onClick={submitInvoiceCreate}>
                    <div className="d-flex align-items-center">
                      <span className="svg-icon svg-icon-2 ms-0">
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.3" d="M8.9 21L7.19999 22.6999C6.79999 23.0999 6.2 23.0999 5.8 22.6999L4.1 21H8.9ZM4 16.0999L2.3 17.8C1.9 18.2 1.9 18.7999 2.3 19.1999L4 20.9V16.0999ZM19.3 9.1999L15.8 5.6999C15.4 5.2999 14.8 5.2999 14.4 5.6999L9 11.0999V21L19.3 10.6999C19.7 10.2999 19.7 9.5999 19.3 9.1999Z" fill="black"/>
                          <path d="M21 15V20C21 20.6 20.6 21 20 21H11.8L18.8 14H20C20.6 14 21 14.4 21 15ZM10 21V4C10 3.4 9.6 3 9 3H4C3.4 3 3 3.4 3 4V21C3 21.6 3.4 22 4 22H9C9.6 22 10 21.6 10 21ZM7.5 18.5C7.5 19.1 7.1 19.5 6.5 19.5C5.9 19.5 5.5 19.1 5.5 18.5C5.5 17.9 5.9 17.5 6.5 17.5C7.1 17.5 7.5 17.9 7.5 18.5Z" fill="black"/>
                        </svg>
                      </span>
                      {lang.labels.generateInvoice}
                    </div>
                  </button>
                }
                { order.status === "canceled" || order.status === "completed"
                  ?
                  <Link to={"/app/sale/order/create/reference/" + order.id} className="btn btn-primary mb-1">
                    <div className="d-flex align-items-center">
                      <span className="svg-icon svg-icon-2 ms-0">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect opacity="0.5" x="7" y="2" width="14" height="16" rx="3" fill="black"/>
                          <rect x="3" y="6" width="14" height="16" rx="3" fill="black"/>
                        </svg>
                      </span>
                      {lang.labels.makeCopy}
                    </div>
                  </Link>
                  :
                  <Link to={"/app/sale/order/create/reference/" + order.id} className="btn btn-icon btn-primary mb-1">
                    <span className="svg-icon svg-icon-2 icon-primary">
                      <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="50.000000pt" height="50.000000pt" viewBox="0 0 50.000000 50.000000" preserveAspectRatio="xMidYMid meet">
                        <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)" fill="#f6f5f4" stroke="none">
                          <path d="M150 440 c0 -19 7 -20 115 -20 l114 0 4 -122 c2 -80 6 -115 11 -100 5 12 12 22 17 22 17 0 9 -37 -11 -55 -11 -10 -20 -29 -20 -42 0 -20 5 -23 40 -23 l40 0 0 180 0 180 -155 0 c-148 0 -155 -1 -155 -20z"/>
                          <path d="M50 220 l0 -180 155 0 155 0 0 50 0 50 -41 0 c-36 0 -40 -2 -29 -15 23 -28 0 -29 -28 -2 l-27 27 27 27 c28 27 51 26 28 -2 -11 -13 -7 -15 29 -15 l41 0 0 120 0 120 -155 0 -155 0 0 -180z m250 110 c0 -6 -38 -10 -95 -10 -57 0 -95 4 -95 10 0 6 38 10 95 10 57 0 95 -4 95 -10z m0 -60 c0 -6 -38 -10 -95 -10 -57 0 -95 4 -95 10 0 6 38 10 95 10 57 0 95 -4 95 -10z m-70 -60 c0 -6 -27 -10 -60 -10 -33 0 -60 4 -60 10 0 6 27 10 60 10 33 0 60 -4 60 -10z"/>
                        </g>
                      </svg>
                    </span>
                  </Link>
                }
              </div>
            </div>
            <AppSaleViewDetail sale={"order"} record={order}></AppSaleViewDetail>
          </div>
          <AppSaleViewRequirements record={order}></AppSaleViewRequirements>
          <AppSaleViewAmount sale={"order"} record={order}></AppSaleViewAmount>
          <AppSaleViewSignatures record={order}></AppSaleViewSignatures>
        </>
        :
        <AppPreloader></AppPreloader>
      }
      <AppModalOrderNotification appServiceForUser={appServiceForUser} companyForUser={companyForUser} order={order} setOrder={setOrder}></AppModalOrderNotification>
    </div>
  )
}

export default OrderViewPage;
