import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { Message } from '../models/message.model';
import { AppService } from '../models/app.service.model';
import { User } from '../models/user.model';
import { appToken } from '../scripts/app.token.script';

export class UserDL {
  static apiResponse: ApiResponse

  public static async getUser(api_key: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let appServices: AppService[] | null = null
      let user: User | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/system.apps.data.json').then(response => { appServices = response.data.system_apps }).catch(error => { alert(error) })
      await axios.get('../../../../../database/user.data.json').then(response => { user = response.data.user }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (appServices!.some(item => item.api_key === api_key)) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: user
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_LOGIN}/${api_key}/users`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.get(url, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data.user
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async putAccountUser(api_key: string, avatar: any, first_name: string, last_name: string, phone: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let appServices: AppService[] | null = null
      let message: Message | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/system.apps.data.json').then(response => { appServices = response.data.system_apps }).catch(error => { alert(error) })
      await axios.get('../../../../../database/message.data.json').then(response => { message = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (appServices!.some(item => item.api_key === api_key)) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: message
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_LOGIN}/${api_key}/users`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      let data: any = new FormData()
      data.append('first_name', first_name)
      data.append('last_name', last_name)
      data.append('phone', phone)
      data.append('avatar', avatar)

      await axios.put(url, data, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async putAccountEmail(api_key: string, email: string, password: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let appServices: AppService[] | null = null
      let message: Message | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/system.apps.data.json').then(response => { appServices = response.data.system_apps }).catch(error => { alert(error) })
      await axios.get('../../../../../database/message.data.json').then(response => { message = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (appServices!.some(item => item.api_key === api_key)) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: message
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_LOGIN}/${api_key}/users/email`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      let data: any = {
        email: email,
        password: password
      }

      await axios.put(url, data, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async putAccountPassword(api_key: string, password: string, new_password: string, new_password_confirm: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let appServices: AppService[] | null = null
      let message: Message | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/system.apps.data.json').then(response => { appServices = response.data.system_apps }).catch(error => { alert(error) })
      await axios.get('../../../../../database/message.data.json').then(response => { message = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (appServices!.some(item => item.api_key === api_key)) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: message
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_LOGIN}/${api_key}/users/password`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      let data: any = {
        current_password: password,
        password: new_password,
        password_confirmation: new_password_confirm
      }

      await axios.put(url, data, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }
}
