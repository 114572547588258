import { Unit } from "../models/unit.model";

export function unitServiceList(measure_units: Unit[]): Unit[] {
  return measure_units.filter((item) => (item.type === 'service'))
}

export function unitProductList(measure_units: Unit[]): Unit[] {
  return measure_units.filter((item) => (item.type === 'product'))
}

export function companyYearList(creation_date: string): number[] {
  let dateString: string = (creation_date.length === 10) ? `${creation_date}T00:00:00.000` : creation_date
  let dateValue = new Date(dateString)
  let yearStart = dateValue.getFullYear()
  let yearCurrent = new Date().getFullYear()
  let list: number[] = []

  for (let year = yearCurrent; year >= yearStart; year--) {
    list.push(year)
  }

  return list
}
