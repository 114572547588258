import React, { Dispatch, SetStateAction, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { CategoryDA } from '../../services/category.service';
import { Error } from '../../models/error.model';
import { Company } from '../../models/company.model';
import { Category } from '../../models/category.model';
import { AccountingAccount } from '../../models/accounting.account.model';
import { legendInvalid, legendInvalidIcon, legendValidInvalidRestart, legendValidInvalidIconRestart, legendValid } from '../../tools/legend.data.entry.tool';
import { modalHide } from '../../tools/modal.tool';
import { isValidObjectAttributes } from '../../scripts/compare.object.script';

import AppCategoryForm from '../../interfaces/main/inventory/category/card/CategoryForm';

export interface AppModalCategoryCreateProps {
  companyForUser: Company | undefined | null,
  categoriesForCompany: Category[] | undefined | null,
  accountingExpenseAccountsForCompany: AccountingAccount[] | undefined | null,
  accountingVatAccountsForCompany: AccountingAccount[] | undefined | null,
  accountingProductAccountsForCompany: AccountingAccount[] | undefined | null,
  category: {value: string, valid: boolean} | null,
  categories: Category[] | undefined | null,
  setCategoriesForCompany: Dispatch<SetStateAction<Category[] | undefined | null>>,
  setCategory: Dispatch<SetStateAction<{value: string, valid: boolean}>> | null,
  setCategories: Dispatch<SetStateAction<Category[] | undefined | null>> | null,
  setObjectAccounts: Dispatch<SetStateAction<{value: {expense: string, vat: string, product: string}, valid: {expense: boolean, vat: boolean, product: boolean}}>> | null
};

let errorResponse: Error, categoryResponse: Category;

const AppModalCategoryCreate: React.FunctionComponent<AppModalCategoryCreateProps> = ({companyForUser, categoriesForCompany, accountingExpenseAccountsForCompany, accountingVatAccountsForCompany, accountingProductAccountsForCompany, category, categories, setCategoriesForCompany, setCategory, setCategories, setObjectAccounts}) => {
  const {lang} = StateLanguage()
  const navigate = useNavigate()

  const [loadIndicator, setLoadIndicator] = useState('off')
  const [name, setName] = useState({value: '', valid: false})
  const [description, setDescription] = useState({value: '', valid: false})
  const [names, setNames] = useState({value: {en: '', fr: '', it: '', de: ''}, valid: {en: true, fr: true, it: true, de: true}})
  const [accounts, setAccounts] = useState({value: {expense: '', vat: '', product: ''}, valid: {expense: false, vat: false, product: false}})

  const submitCategoryCreate = async () => {
    setLoadIndicator('on')

    let namesIsValid: boolean = isValidObjectAttributes(names.valid)
    let accountsIsValid: boolean = isValidObjectAttributes(accounts.valid)

    if (companyForUser && categoriesForCompany && name.valid && description.valid && namesIsValid && accountsIsValid) {
      await CategoryDA.postCategory(companyForUser.id, name.value, description.value, names.value, accounts.value,).then( (response) => {
        if (response.status === 201) {
          categoryResponse = response.data
          setCategoriesForCompany([categoryResponse, ...categoriesForCompany])

          if (category && setCategory) {
            setCategory({...category, value: categoryResponse.id, valid: true})
            legendValid('legend-category-required')
          }

          if (categories && setCategories) {
            setCategories([categoryResponse, ...categories])
          }

          if (setObjectAccounts) {
            setObjectAccounts({value: {expense: categoryResponse.accounts.expense, vat: categoryResponse.accounts.vat, product: categoryResponse.accounts.product}, valid: {expense: true, vat: true, product: true}})
          }

          Swal.fire({
            title: lang.labels.successfullyCreatedCategory,
            text: lang.labels.actionCompletedReturningToPage,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            executeHideModalCategoryCreate()
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: `${errorResponse.message}!`,
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        navigate('/error')
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        if (name.value.length === 0) {
          legendInvalidIcon('modal-category-create-input-name', 'modal-category-create-legend-name-required')
        }
        if (description.value.length === 0) {
          legendInvalid('modal-category-create-legend-description-required')
        }
        if (accounts.value.expense.length === 0) {
          legendInvalid('modal-category-create-legend-account-expense-required')
        }
        if (accounts.value.vat.length === 0) {
          legendInvalid('modal-category-create-legend-account-vat-required')
        }
        if (accounts.value.product.length === 0) {
          legendInvalid('modal-category-create-legend-account-product-required')
        }
        setLoadIndicator('off')
      })
    }
  }

  const executeHideModalCategoryCreate = () => {
    modalHide('modal-category-create')

    setTimeout( () => {
      restartModal()
    }, 200 )
  }

  function restartModal() {
    legendValidInvalidIconRestart('modal-category-create-input-name', 'modal-category-create-legend-name-valid')
    legendValidInvalidIconRestart('modal-category-create-input-name', 'modal-category-create-legend-name-required')
    legendValidInvalidRestart('modal-category-create-legend-description-valid')
    legendValidInvalidRestart('modal-category-create-legend-description-required')
    legendValidInvalidRestart('modal-category-create-legend-account-expense-required')
    legendValidInvalidRestart('modal-category-create-legend-account-vat-required')
    legendValidInvalidRestart('modal-category-create-legend-account-product-required')

    for (var index in Object.keys(names.valid)) {
      legendValidInvalidIconRestart('modal-category-create-input-language-name-' + index, 'modal-category-create-legend-language-name-valid-' + index)
    }

    if (category && setCategories) {
      legendValid('legend-category-required')
    }

    if (setObjectAccounts) {
      legendValid('legend-account-expense-required')
      legendValid('legend-account-vat-required')
      legendValid('legend-account-product-required')
    }

    setName({value: '', valid: false})
    setDescription({value: '', valid: false})
    setNames({value: {en: '', fr: '', it: '', de: ''}, valid: {en: true, fr: true, it: true, de: true}})
    setAccounts({value: {expense: '', vat: '', product: ''}, valid: {expense: false, vat: false, product: false}})
  }

  return (
    <div id="modal-category-create" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="text-capitalize">{lang.labels.createCategory}</h2>
          </div>
          <div className="modal-body py-0">
            { companyForUser && accountingExpenseAccountsForCompany && accountingVatAccountsForCompany && accountingProductAccountsForCompany
              ?
              <AppCategoryForm component={"modal-category-create"} category={null} name={name} description={description} names={names} accounts={accounts} setName={setName} setDescription={setDescription} setNames={setNames} setAccounts={setAccounts}></AppCategoryForm>
              :
              <div className="d-flex justify-content-center align-items-center w-100 h-200px">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">{lang.labels.loading}</span>
                </div>
              </div>
            }
          </div>
          <div className="modal-footer flex-center">
            <button className="btn btn-light mx-2" type="reset" onClick={executeHideModalCategoryCreate}>{lang.labels.cancel}</button>
            <button className="btn btn-primary mx-2" type="button" data-kt-indicator={loadIndicator} onClick={submitCategoryCreate}>
              <span className="indicator-label">{lang.labels.create}</span>
              <span className="indicator-progress">
                {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppModalCategoryCreate;
