import React from 'react';
import FileSaver from 'file-saver';

import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { ProviderOrder } from '../../../../models/provider.order.model';
import { ProviderInvoice } from '../../../../models/provider.invoice.model';
import { Expense } from '../../../../models/expense.model';
import { Voucher } from '../../../../models/voucher.model';
import { datetimeFormat12h } from '../../../../scripts/datetime.format.script';

export interface AppPurchaseViewVouchersProps {
  purchase: string,
  record: any | ProviderOrder | ProviderInvoice | Expense
};

const AppPurchaseViewVouchers: React.FunctionComponent<AppPurchaseViewVouchersProps> = ({purchase, record}) => {
  const {lang} = StateLanguage()

  const executeDownloadFileVoucher = (item: Voucher, index: number) => {
    let element: HTMLElement = document.getElementById('button-download-file-voucher-' + index) as HTMLElement
    element.setAttribute('data-kt-indicator', 'on')

    FileSaver.saveAs(item.file, `${record.company.name.replaceAll(' ', '_').toLowerCase()}_${item.name.replaceAll(' ', '_').toLowerCase()}.pdf`)

    setTimeout( () => {
      element.setAttribute('data-kt-indicator', 'off')
    }, 500 )
  }

  function sizeFileVoucher(size: number): string {
    return `${(size / 1024).toFixed(0)} Kb`
  }

  return (
    <div className={`card card-flush ${purchase === "expense" ? "mb-0" : "mb-5"}`}>
      <div className="card-header">
        <div className="card-title">
          <h4>{lang.labels.vouchers}</h4>
        </div>
      </div>
      <div className="card-body pt-0">
        <div className="dataTables_wrapper">
          <div className="table-responsive">
            <table className="table align-middle table-row-dashed gy-2 mb-2">
              <thead className="border-bottom">
                <tr className="text-muted text-uppercase fw-bolder fs-7">
                  <th className="min-w-200px text-nowrap text-start">{lang.labels.name}</th>
                  <th className="min-w-100px text-nowrap text-start">{lang.labels.size}</th>
                  <th className="min-w-100px text-nowrap text-start">{lang.labels.lastModified}</th>
                  <th className="w-100px text-nowrap text-end">{lang.labels.download}</th>
                </tr>
              </thead>
              { record.vouchers.length > 0
                ?
                <tbody className="text-gray-700 fw-bold">
                  { record.vouchers.map (( (item: Voucher, index: number) => { return (
                    <tr key={index}>
                      <td>
                        <div className="d-flex align-items-center cursor-pointer" data-bs-toggle="modal" data-bs-target={`#voucher-pdf-${index}`}>
                          <span className="svg-icon svg-icon-2 me-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22Z" fill="black"></path>
                              <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black"></path>
                            </svg>
                          </span>
                          <span className="text-gray-800 text-hover-primary">{item.name}</span>
                        </div>
                        <div id={`voucher-pdf-${index}`} className="modal fade" tabIndex={-1} aria-hidden="true">
                          <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                              <iframe id='modal-voucher-view-container' title={item.name} src={item.file} width="100%" height="100%" />
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>{sizeFileVoucher(item.size)}</td>
                      <td>{datetimeFormat12h(lang.code, item.created_at)}</td>
                      <td valign="middle" className="text-end p-1">
                        <button id={"button-download-file-voucher-" + index} className="btn btn-sm btn-icon btn-flex btn-light-primary btn-active-primary h-30px w-30px" type="button" data-kt-indicator="off" onClick={() => executeDownloadFileVoucher(item, index)}>
                          <span className="indicator-label">
                            <span className="svg-icon svg-icon-3">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM13 15.4V10C13 9.4 12.6 9 12 9C11.4 9 11 9.4 11 10V15.4H8L11.3 18.7C11.7 19.1 12.3 19.1 12.7 18.7L16 15.4H13Z" fill="black"/>
                                <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black"/>
                              </svg>
                            </span>
                          </span>
                          <span className="indicator-progress">
                            <span className="spinner-border spinner-border-sm align-middle"></span>
                          </span>
                        </button>
                      </td>
                    </tr>
                  )}))}
                </tbody>
                :
                <tbody>
                  <tr className="text-gray-700 fw-bold">
                    <td valign="top" colSpan={4} className="text-center">{lang.labels.noRecordsFound}</td>
                  </tr>
                </tbody>
              }
            </table>
          </div>
        </div>
      </div>
    </div>
  )
};

export default AppPurchaseViewVouchers;
