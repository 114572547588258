import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { Dropdown } from 'react-bootstrap';
import { FaExclamationCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { PaymentDA } from '../../services/payment.service';
import { Error } from '../../models/error.model';
import { Invoice } from '../../models/invoice.model';
import { TreasuryAccount } from '../../models/treasury.account.model';
import { AccountingEntry } from '../../models/accounting.entry.model';
import { Company } from '../../models/company.model';
import { AccountingAccount } from '../../models/accounting.account.model';
import { paymentMethodList, paymentMethodValue } from '../../libraries/payment.library';
import { accountingAccountSelected, accountingEntryTransactionSelected, treasuryAccountSelected } from '../../scripts/list.selected.item.script';
import { getError } from '../../languages/translations/response';
import { legendInvalid, legendInvalidIcon, legendValid, legendValidInvalidRestart, legendValidInvalidIconRestart, legendValidIcon } from '../../tools/legend.data.entry.tool';
import { modalHide } from '../../tools/modal.tool';
import { uploadTooltip } from '../../tools/tooltip.tool';
import { moneyFormat } from '../../scripts/format.value.script';
import { evaluateLegendValidRequiredIcon } from '../../scripts/validate.legend.script';
import { dateFormatAbbreviated, dateFormatLocalized, timeFormat12h } from '../../scripts/datetime.format.script';
import { expressions } from '../../libraries/regular.expressions.library';

import AppLegend from '../element/Legend';

export interface AppModalPaymentCreateProps {
  companyForUser: Company | undefined | null,
  accountingAccountsForCompany: AccountingAccount[] | undefined | null,
  treasuryAccountsForCompany: TreasuryAccount[] | undefined | null,
  entries: AccountingEntry[] | undefined | null,
  invoice: Invoice | undefined | null,
  setInvoice: Dispatch<SetStateAction<Invoice | undefined | null>>,
  setEntries: Dispatch<SetStateAction<AccountingEntry[] | undefined | null>>,
  loadEntries: Function
};

let errorResponse: Error, paymentResponse: Invoice;

const AppModalPaymentCreate: React.FunctionComponent<AppModalPaymentCreateProps> = ({companyForUser, accountingAccountsForCompany, treasuryAccountsForCompany, entries, invoice, setInvoice, setEntries, loadEntries}) => {
  const {lang} = StateLanguage()
  const navigate = useNavigate()

  const [loadIndicator, setLoadIndicator] = useState('off')
  const [datetimeCurrent, setDateTimeCurrent] = useState('')
  const [amountCredit, setAmountCredit] = useState({value: '', valid: false})
  const [amountDebit, setAmountDebit] = useState({value: '', valid: false})
  const [treasury, setTreasury] = useState({value: '', valid: false})
  const [method, setMethod] = useState({value: '', valid: false})
  const [date, setDate] = useState({value: '', valid: false, starting: true})
  const [entry, setEntry] = useState({value: '', valid: false, object: null as any})

  const submitPaymentCreate = async () => {
    setLoadIndicator('on')

    let isValidTransaction: boolean = (entry.valid) ? true : (amountDebit.valid && date.valid && treasury.valid) ? true : false

    if (companyForUser && invoice && amountCredit.valid && isValidTransaction) {
      let companyData = { subject_tax: companyForUser.subject_tax, vat_debt_method: companyForUser.vat_debt_method, tax_method: companyForUser.tax_method, tax_rate: companyForUser.tax_rate }

      await PaymentDA.postPaymentInvoice(companyForUser.id, invoice.id, amountCredit.value, amountDebit.value, date.value, method.value, treasury.value, entry.value, companyData).then( (response) => {
        if (response.status === 201) {
          paymentResponse = response.data

          setInvoice(paymentResponse)
          loadEntries(companyForUser.id)
          Swal.fire({
            title: lang.labels.successfullyCreatedPayment,
            text: lang.labels.actionCompletedReturningToPage,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            executeHideModalPaymentCreateInvoice()
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.code),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        navigate('/error')
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        if (amountCredit.value.length === 0) {
          legendInvalidIcon('modal-payment-create-invoice-input-amount-credit', 'modal-payment-create-invoice-legend-amount-credit-required')
        }
        if (entry.value.length === 0 && amountDebit.value.length === 0) {
          legendInvalidIcon('modal-payment-create-invoice-input-amount-debit', 'modal-payment-create-invoice-legend-amount-debit-required')
        }
        if (entry.value.length === 0 && treasury.value.length === 0) {
          legendInvalid('modal-payment-create-invoice-legend-treasury-required')
        }
        if (entry.value.length === 0 && method.value.length === 0) {
          legendInvalid('modal-payment-create-invoice-legend-method-required')
        }
        if (entry.value.length === 0 && date.value.length === 0) {
          legendInvalid('modal-payment-create-invoice-legend-date-required')
        }
        if (entry.value.length === 0 && amountDebit.value.length === 0 && treasury.value.length === 0 && method.value.length === 0) {
          legendInvalid('modal-payment-create-invoice-legend-entry-required')
        }
        if (entry.value.length === 0 && amountDebit.value.length === 0 && treasury.value.length === 0 && method.value.length === 0 && date.value.length === 0) {
          legendInvalid('modal-payment-create-invoice-legend-entry-required')
        }
        setLoadIndicator('off')
      })
    }
  }

  const handleChangeAmountCredit = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setAmountCredit({...amountCredit, value: event.target.value})
  }

  const handleChangeAmountDebit = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setAmountDebit({...amountDebit, value: event.target.value})
    setEntry({value: '', valid: false, object: null})

    if (invoice) {
      if (expressions.currency.test(event.target.value)) {
        if (Number(event.target.value) >= invoice.amount.debt) {
          setAmountCredit({value: invoice.amount.debt.toString(), valid: true})
        } else {
          setAmountCredit({value: event.target.value, valid: true})
        }
        legendValidIcon('modal-payment-create-invoice-input-amount-credit', 'modal-payment-create-invoice-legend-amount-credit-required')
      } else {
        setAmountCredit({value: '', valid: false})
        legendValidInvalidIconRestart('modal-payment-create-invoice-input-amount-credit', 'modal-payment-create-invoice-legend-amount-credit-valid')
      }
      legendValidInvalidRestart('modal-payment-create-invoice-legend-entry-required')
    }
  }

  const handleChangeDate = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    if (event.target.value.length > 0) {
      setDate({...date, value: event.target.value, valid: true})
      legendValid('modal-payment-create-invoice-legend-date-required')
    } else {
      setDate({...date, value: event.target.value, valid: false})
      legendInvalid('modal-payment-create-invoice-legend-date-required')
    }

    setEntry({value: '', valid: false, object: null})
    legendValidInvalidRestart('modal-payment-create-invoice-legend-entry-required')
  }

  const handleChangeEntry = (item: AccountingEntry) => {
    setEntry({value: item.id, object: item, valid: true})
    legendValid('modal-payment-create-invoice-legend-entry-required')

    if (invoice) {
      setAmountCredit({value: (item.balance >= invoice.amount.debt) ? invoice.amount.debt.toString() : item.balance.toString(), valid: true})
      setAmountDebit({value: '', valid: false})
      setTreasury({value: '', valid: false})
      setMethod({value: '', valid: false})
      setDate({value: '', valid: false, starting: true})

      legendValidIcon('modal-payment-create-invoice-input-amount-credit', 'modal-payment-create-invoice-legend-amount-credit-valid')
      legendValidIcon('modal-payment-create-invoice-input-amount-credit', 'modal-payment-create-invoice-legend-amount-credit-required')
      legendValidInvalidIconRestart('modal-payment-create-invoice-input-amount-debit', 'modal-payment-create-invoice-legend-amount-debit-valid')
      legendValidInvalidIconRestart('modal-payment-create-invoice-input-amount-debit', 'modal-payment-create-invoice-legend-amount-debit-required')
      legendValidInvalidRestart('modal-payment-create-invoice-legend-treasury-required')
      legendValidInvalidRestart('modal-payment-create-invoice-legend-method-required')
      legendValidInvalidRestart('modal-payment-create-invoice-legend-date-required')
    }
  }

  const handleChangeTreasury = (item: string) => {
    setTreasury({value: item, valid: true})
    setEntry({value: '', valid: false, object: null})

    legendValid('modal-payment-create-invoice-legend-treasury-required')
    legendValidInvalidRestart('modal-payment-create-invoice-legend-entry-required')
  }

  const handleChangeMethod = (item: string) => {
    setMethod({value: item, valid: true})
    setEntry({value: '', valid: false, object: null})

    legendValid('modal-payment-create-invoice-legend-method-required')
    legendValidInvalidRestart('modal-payment-create-invoice-legend-entry-required')
  }

  const validateAmountCredit = () => {
    evaluateLegendValidRequiredIcon(expressions.currency, amountCredit, setAmountCredit, 'modal-payment-create-invoice-input-amount-credit', 'modal-payment-create-invoice-legend-amount-credit-valid', 'modal-payment-create-invoice-legend-amount-credit-required')
  }

  const validateAmountDebit = () => {
    evaluateLegendValidRequiredIcon(expressions.currency, amountDebit, setAmountDebit, 'modal-payment-create-invoice-input-amount-debit', 'modal-payment-create-invoice-legend-amount-debit-valid', 'modal-payment-create-invoice-legend-amount-debit-required')
  }

  const executeHideModalPaymentCreateInvoice = () => {
    modalHide('modal-payment-create-invoice')

    setTimeout( () => {
      restartModal()
    }, 200 )
  }

  function identifiedEntries(list: AccountingEntry[]): AccountingEntry[] {
    return list.filter((item) => (item.id_contact.length > 0))
  }

  function unidentifiedEntries(list: AccountingEntry[]): AccountingEntry[] {
    return list.filter((item) => (item.id_contact.length === 0))
  }

  function datePayment(): boolean {
    switch (true) {
      case (invoice && date.starting && !date.valid && date.value === ''):
        setDate({value: format((new Date()), 'yyyy-MM-dd'), valid: true, starting: false})
        return true
      case (date.valid && date.value.length > 0):
        return true
      default:
        return false
    }
  }

  function maxDate(): string {
    return format((new Date()), 'yyyy-MM-dd')
  }

  function restartModal() {
    legendValidInvalidIconRestart('modal-payment-create-invoice-input-amount-debit', 'modal-payment-create-invoice-legend-amount-debit-valid')
    legendValidInvalidIconRestart('modal-payment-create-invoice-input-amount-debit', 'modal-payment-create-invoice-legend-amount-debit-required')
    legendValidInvalidIconRestart('modal-payment-create-invoice-input-amount-credit', 'modal-payment-create-invoice-legend-amount-credit-valid')
    legendValidInvalidIconRestart('modal-payment-create-invoice-input-amount-credit', 'modal-payment-create-invoice-legend-amount-credit-required')
    legendValidInvalidRestart('modal-payment-create-invoice-legend-treasury-required')
    legendValidInvalidRestart('modal-payment-create-invoice-legend-method-required')
    legendValidInvalidRestart('modal-payment-create-invoice-legend-date-required')
    legendValidInvalidRestart('modal-payment-create-invoice-legend-entry-required')

    setAmountCredit({value: '', valid: false})
    setAmountDebit({value: '', valid: false})
    setTreasury({value: '', valid: false})
    setMethod({value: '', valid: false})
    setDate({value: '', valid: false, starting: true})
    setEntry({value: '', valid: false, object: null})
  }

  useEffect(() => {
    uploadTooltip()
  }, [companyForUser, accountingAccountsForCompany, treasuryAccountsForCompany, entries, invoice])

  useEffect(() => {
    const timerID = setInterval(() => {
      let currentDate: string = format((new Date()), `yyyy-MM-dd'T'HH:mm:ss.SSSxxx`)
      setDateTimeCurrent(`${timeFormat12h(currentDate)}, ${dateFormatAbbreviated(lang.code, currentDate)}`)
    }, 1000)

    return () => {
      clearInterval(timerID)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div id="modal-payment-create-invoice" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="text-capitalize">{lang.labels.createNewPaymentForInvoice}</h2>
          </div>
          <div className="modal-body">
            { companyForUser && accountingAccountsForCompany && treasuryAccountsForCompany && entries && invoice
              ?
              <div className="form">
                <div className="d-flex flex-column flex-xl-row">
                  <div className="col-xl-6 mb-5 mb-xl-0">
                    <div className="h-100 m-0">
                      <div className="border border-dashed border-gray-300 card-rounded h-100 bg-lighten">
                        <h4 className="text-dark text-center text-uppercase p-5 pb-2">{lang.labels.debit}</h4>
                        <div className="border border-dashed border-gray-300 w-100"></div>
                        <div className="p-5">
                          <h6 className="text-center mb-4">{lang.labels.enterNewTransaction}</h6>
                          <div className="fv-row mb-3">
                            <label className="form-label">
                              <div className="d-flex align-items-center">
                                <span className="required">{lang.labels.depositAmount}</span>
                                <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.amountMoneyEnteredInTransaction}><FaExclamationCircle /></i>
                              </div>
                            </label>
                            <div className="position-relative">
                              <div className="position-absolute translate-middle-y top-50 start-0 form-control bg-transparent border-0 border-end rounded-0 rounded-start fw-bolder text-center w-60px h-auto px-2">{invoice.currency.symbol}</div>  
                              <input id="modal-payment-create-invoice-input-amount-debit" className="form-control ps-20" type="text" name="amount-debit" placeholder="0.00" value={amountDebit.value} onChange={handleChangeAmountDebit} onKeyUp={validateAmountDebit} onBlur={validateAmountDebit} />
                            </div>
                            <AppLegend component={"modal-payment-create-invoice"} attribute={{validity: amountDebit.valid, name: "amount-debit", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                          </div>
                          <div className="fv-row mb-3">
                            <label className="form-label">
                              <div className="d-flex align-items-center">
                                <span className="required">{lang.labels.treasuryAccount}</span>
                                <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.sourceTheAmount}><FaExclamationCircle /></i>
                              </div>
                            </label>
                            <Dropdown>
                              <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                                <span className="selection">
                                  <span className="select2-selection select2-selection--single form-select" aria-disabled="false">
                                    {treasury.valid ? treasuryAccountSelected(lang, treasuryAccountsForCompany, treasury.value) : lang.labels.selectOption}
                                  </span>
                                </span>
                              </Dropdown.Toggle>
                              <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                                <span className="select2-dropdown select2-dropdown--below">
                                  <span className="select2-results">
                                    <ul className="select2-results__options" role="listbox">
                                      <li className={`select2-results__option select2-results__option--selectable ${treasuryAccountsForCompany.length > 0 && "d-none"}`}>
                                        <Dropdown.Item bsPrefix="select2-results__option__text">{lang.labels.selectOption}</Dropdown.Item>
                                      </li>
                                      { treasuryAccountsForCompany.map (( (item, index) => { return (
                                        <li key={index} className={`select2-results__option select2-results__option--selectable ${item.id === treasury.value && "select2-results__option--selected"}`} role="option" aria-selected={item.id === treasury.value}>
                                          <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeTreasury(item.id)}>{item.name}</Dropdown.Item>
                                        </li>
                                      )}))}
                                    </ul>
                                  </span>
                                </span>
                              </Dropdown.Menu>
                            </Dropdown>
                            <AppLegend component={"modal-payment-create-invoice"} attribute={{validity: treasury.valid, name: "treasury", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                          </div>
                          <div className="row">
                            <div className="col-lg-6 fv-row mb-3 mb-lg-0 d-none">
                              <label className="form-label required">{lang.labels.paymentMethod}</label>
                              <Dropdown>
                                <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                                  <span className="selection">
                                    <span className="select2-selection select2-selection--single form-select" aria-disabled="false">
                                      {method.valid ? paymentMethodValue(lang, method.value) : lang.labels.selectOption}
                                    </span>
                                  </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                                  <span className="select2-dropdown select2-dropdown--below">
                                    <span className="select2-results">
                                      <ul className="select2-results__options" role="listbox">
                                        { paymentMethodList(lang).map (( (item, index) => { return (
                                          <li key={index} className={`select2-results__option select2-results__option--selectable ${item.code === method.value && "select2-results__option--selected"}`} role="option" aria-selected={item.code === method.value}>
                                            <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeMethod(item.code)}>{item.display}</Dropdown.Item>
                                          </li>
                                        )}))}
                                      </ul>
                                    </span>
                                  </span>
                                </Dropdown.Menu>
                              </Dropdown>
                              <AppLegend component={"modal-payment-create-invoice"} attribute={{validity: method.valid, name: "method", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                            </div>
                            <div className="col-lg-12 fv-row">
                              <label className="form-label required">{lang.labels.paymentDate}</label>
                              <input className="form-control" type="date" name="date" max={maxDate()} value={date.value} onChange={handleChangeDate} />
                              <AppLegend component={"modal-payment-create-invoice"} attribute={{validity: datePayment(), name: "date", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="border border-dashed border-gray-300 w-100"></div>
                          <div className="text-center text-uppercase px-5">{lang.labels.or}</div>
                          <div className="border border-dashed border-gray-300 w-100"></div>
                        </div>
                        <div className="p-5">
                          <h6 className="text-center mb-4">{lang.labels.useExistingTransaction}</h6>
                          <div className="fv-row mb-3">
                            <label className="form-label">
                              <div className="d-flex align-items-center">
                                <span className="required">{lang.labels.transaction}</span>
                                <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.transactionToRecordPayment}><FaExclamationCircle /></i>
                              </div>
                            </label>
                            <Dropdown>
                              <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                                <span className="selection">
                                  <span className="select2-selection select2-selection--single form-select" aria-disabled="false">
                                    {entry.valid ? `${accountingEntryTransactionSelected(lang, entries, treasuryAccountsForCompany, invoice.currency, entry.value)}` : lang.labels.selectOption}
                                  </span>
                                </span>
                              </Dropdown.Toggle>
                              <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                                <span className="select2-dropdown select2-dropdown--below">
                                  <span className="select2-results">
                                    <ul className={`select2-results__options ${entries.length > 0 && "d-none"}`} role="listbox">
                                      <li className="select2-results__option select2-results__option--selectable">
                                        <Dropdown.Item bsPrefix="select2-results__option__text">{lang.labels.selectOption}</Dropdown.Item>
                                      </li>
                                    </ul>
                                    { identifiedEntries(entries).length > 0 &&
                                      <>
                                        <span className={`select2-results__option select2-results__option--group ${unidentifiedEntries(entries).length === 0 && "d-none"}`}>
                                          <Dropdown.Header bsPrefix="select2-results__option text-dark fw-bold">{lang.labels.identifiedTransactions}</Dropdown.Header>
                                        </span>
                                        <ul className="select2-results__options mh-150px mb-2" role="listbox">
                                          { identifiedEntries(entries).map (( (item, index) => { return (
                                            <li key={index} className={`select2-results__option select2-results__option--selectable ${item.id === entry.value && "select2-results__option--selected"}`} role="option" aria-selected={item.id === entry.value}>
                                              <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeEntry(item)}>{dateFormatLocalized(lang.code,item.date)}, {treasuryAccountSelected(lang, treasuryAccountsForCompany, item.debits[0].reference.id_treasury_account)}, {invoice.currency.symbol} {moneyFormat(item.balance)}</Dropdown.Item>
                                            </li>
                                          )}))}
                                        </ul>
                                      </>
                                    }
                                    { unidentifiedEntries(entries).length > 0 &&
                                      <>
                                        <span className={`select2-results__option select2-results__option--group ${identifiedEntries(entries).length === 0 && "d-none"}`}>
                                          <Dropdown.Header bsPrefix="select2-results__option text-dark fw-bold">{lang.labels.unidentifiedTransactions}</Dropdown.Header>
                                        </span>
                                        <ul className="select2-results__options mh-150px" role="listbox">
                                          { unidentifiedEntries(entries).map (( (item, index) => { return (
                                            <li key={index} className={`select2-results__option select2-results__option--selectable ${item.id === entry.value && "select2-results__option--selected"}`} role="option" aria-selected={item.id === entry.value}>
                                              <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeEntry(item)}>{dateFormatLocalized(lang.code, item.date)}, {treasuryAccountSelected(lang, treasuryAccountsForCompany, item.debits[0].reference.id_treasury_account)}, {invoice.currency.symbol} {moneyFormat(item.balance)}</Dropdown.Item>
                                            </li>
                                          )}))}
                                        </ul>
                                      </>
                                    }
                                  </span>
                                </span>
                              </Dropdown.Menu>
                            </Dropdown>
                            <AppLegend component={"modal-payment-create-invoice"} attribute={{validity: entry.valid, name: "entry", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                          </div>
                          <div className="table-responsive">
                            <table className="table align-middle table-row-bordered mb-2 gy-1">
                              <tbody>
                                <tr className="row-bordered">
                                  <td className="text-gray-700">{lang.labels.accountingAccount}</td>
                                  <td className="fw-bold text-end">{entry.valid ? accountingAccountSelected(lang, accountingAccountsForCompany, entry.object.debits[0].id_account, companyForUser.language) : lang.labels.none}</td>
                                </tr>
                                <tr className="row-bordered d-none">
                                  <td className="text-gray-700">{lang.labels.paymentMethod}</td>
                                  <td className="fw-bold text-end">{entry.valid ? paymentMethodValue(lang, entry.object.debits[0].reference.payment_method) : lang.labels.none}</td>
                                </tr>
                                <tr className="row-bordered">
                                  <td className="text-gray-700">{lang.labels.totalDepositAmount}</td>
                                  <td className="fw-bold text-end">{entry.valid ? `${invoice.currency.symbol} ${moneyFormat(entry.object.debits[0].amount)}` : lang.labels.none}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="h-100 m-0 ps-xl-5">
                      <div className="border border-dashed border-gray-300 card-rounded h-100 bg-lighten">
                        <h4 className="text-dark text-center text-uppercase p-5 pb-2">{lang.labels.credit}</h4>
                        <div className="border border-dashed border-gray-300 w-100"></div>
                        <div className="p-5">
                          <h6 className="text-center mb-4">{lang.labels.makeCorrespondingPayment}</h6>
                          <div className="fv-row mb-5">
                            <label className="form-label required">{lang.labels.amountToPay}</label>
                            <div className="position-relative">
                              <div className="position-absolute translate-middle-y top-50 start-0 form-control bg-transparent border-0 border-end rounded-0 rounded-start fw-bolder text-center w-60px h-auto px-2">{invoice.currency.symbol}</div>  
                              <input id="modal-payment-create-invoice-input-amount-credit" className="form-control ps-20" type="text" name="amount-credit" placeholder="0.00" value={amountCredit.value} onChange={handleChangeAmountCredit} onKeyUp={validateAmountCredit} onBlur={validateAmountCredit} />
                            </div>
                            <div className="form-text text-justify">{lang.labels.thisAmountCanNotBeGreater}</div>
                            <AppLegend component={"modal-payment-create-invoice"} attribute={{validity: amountDebit.valid, name: "amount-credit", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                          </div>
                          <div className="table-responsive">
                            <table className="table align-middle table-row-bordered mb-2 gy-3">
                              <tbody>
                                <tr className="row-bordered">
                                  <td className="text-gray-700">{lang.labels.invoiceNumber}</td>
                                  <td className="fw-bold text-end">{invoice.invoice_number}</td>
                                </tr>
                                <tr className="row-bordered">
                                  <td className="text-gray-700">{lang.labels.amountTotal}</td>
                                  <td className="fw-bold text-end">{invoice.currency.symbol} {moneyFormat(invoice.amount.total)}</td>
                                </tr>
                                <tr className="row-bordered">
                                  <td className="text-gray-700">{lang.labels.amountPaid}</td>
                                  <td className="fw-bold text-end">{invoice.currency.symbol} {moneyFormat(invoice.amount.paid)}</td>
                                </tr>
                                <tr className="row-bordered">
                                  <td className="text-gray-700">{lang.labels.amountDebt}</td>
                                  <td className="fw-bold text-end">{invoice.currency.symbol} {moneyFormat(invoice.amount.debt)}</td>
                                </tr>
                                <tr className="row-bordered">
                                  <td className="text-gray-700">{lang.labels.paymentDate}</td>
                                  <td className="fw-bold text-end">{entry.valid ? dateFormatAbbreviated(lang.code, entry.object.date) : dateFormatAbbreviated(lang.code, date.value)}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="d-flex align-items-center justify-content-end d-none">
                            <span className="text-primary fw-bolder">{datetimeCurrent}</span>
                            <span className="svg-icon svg-icon-1 svg-icon-primary text-primary ms-2">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.3" d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z" fill="black"/>
                                <path d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z" fill="black"/>
                              </svg>
                            </span>
                          </div>
                        </div>  
                      </div>                
                    </div>
                  </div>
                </div>
              </div >
              :
              <div className="d-flex justify-content-center align-items-center w-100 h-200px">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">{lang.labels.loading}</span>
                </div>
              </div>
            }
          </div>
          <div className="modal-footer flex-center">
            <button className="btn btn-light mx-2" type="reset" onClick={executeHideModalPaymentCreateInvoice}>{lang.labels.discard}</button>
            <button className="btn btn-primary mx-2" type="button" data-kt-indicator={loadIndicator} onClick={submitPaymentCreate}>
              <span className="indicator-label">{lang.labels.save}</span>
              <span className="indicator-progress">
                {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppModalPaymentCreate;