import React, { useEffect, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { UseOutletContextInvoice } from './Invoice';
import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { getError } from '../../../../languages/translations/response';
import { InvoiceDA } from '../../../../services/invoice.service';
import { Error } from '../../../../models/error.model';
import { CompanyInvoices } from '../../../../models/company.invoices.model';
import { Invoice } from '../../../../models/invoice.model';
import { InvoiceGroup } from '../../../../models/invoice.group.model';
import { moneyFormat } from '../../../../scripts/format.value.script';
import { dateFormatAbbreviated } from '../../../../scripts/datetime.format.script';
import { accumulatedAmountDocuments } from '../../../../scripts/computed.amount.script';
import { colorStatusInvoice, colorStatusPayment, colorValidated, colorValidUntilInvoice } from '../../../../scripts/badge.color.script';
import { nameGroup } from '../../../../libraries/group.name.library';
import { statusDocumentValue } from '../../../../libraries/status.document.library';
import { validationBoolean } from '../../../../libraries/validation.library';

import AppSaleRecordList from '../card/SaleRecordList';
import AppSortTable from '../../../../components/element/SortTable';
import AppPreloader from '../../../../components/Preloader';

let errorResponse: Error, invoicesResponse: CompanyInvoices;

export interface InvoiceListPageProps {};

const InvoiceListPage: React.FunctionComponent<InvoiceListPageProps> = props => {
  const {setRoute, companyForUser, contactsForCompany} = UseOutletContextInvoice()
  const {lang} = StateLanguage()
  const navigate = useNavigate()

  const [mounted, setMounted] = useState(false)
  const [button, setButton] = useState({filter: false, group: false})
  const [filter, setFilter] = useState({interval: '', start_date: '', end_date: '', contact: '', treasury_account: '', status: '', expiration: '', validity: '', payment: '', sort_field: '', sort_mode: '', group: ''})
  const [invoices, setInvoices] = useState<Invoice[] | undefined | null>(null)
  const [invoiceGroups, setInvoiceGroups] = useState<InvoiceGroup[] | undefined | null>(null)
  const [storage, setStorage] = useState(null)

  const loadInvoices = async (id_company: string) => {
    let grouped: boolean = false
    let filtered: boolean = false

    await InvoiceDA.getInvoices(id_company, filter.interval, filter.start_date, filter.end_date, filter.contact, filter.treasury_account, filter.status, filter.expiration, filter.validity, filter.payment, filter.sort_field, filter.sort_mode, filter.group).then( (response) => {
      if (response.status === 200) {
        invoicesResponse = response.data

        localStorage.setItem('filter_invoices', JSON.stringify({ 
          company_id: invoicesResponse.id_company,
          interval: invoicesResponse.filter.interval,
          start_date: invoicesResponse.filter.start_date, 
          end_date: invoicesResponse.filter.end_date, 
          contact: invoicesResponse.filter.contact,
          //treasury_account: invoicesResponse.filter.treasury_id,
          status: invoicesResponse.filter.status,
          expiration: invoicesResponse.filter.expiration,
          validity: invoicesResponse.filter.validity,
          payment: invoicesResponse.filter.payment,
          sort_field: invoicesResponse.filter.sort_field,
          sort_mode: invoicesResponse.filter.sort_mode,
          group: invoicesResponse.filter.group_by
        }))

        filtered = (invoicesResponse.filter.interval === '' && invoicesResponse.filter.start_date === '' && invoicesResponse.filter.end_date === '' && invoicesResponse.filter.contact === '' && invoicesResponse.filter.status === '' && invoicesResponse.filter.expiration === '' && invoicesResponse.filter.validity === '' && invoicesResponse.filter.payment === '') ? false : true
        grouped = (invoicesResponse.filter.group_by === '') ? false : true

        if (invoicesResponse.filter.group_by === '') {
          setInvoiceGroups([])
          setInvoices(invoicesResponse.invoices)
        } else {
          setInvoices([])
          setInvoiceGroups(invoicesResponse.groups)
        }

        setButton({filter: filtered, group: grouped})
        setStorage((localStorage.getItem('filter_invoices')) ? JSON.parse(localStorage.getItem('filter_invoices')!) : null)
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.code),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.okGotIt,
          customClass: {confirmButton: 'btn btn-primary'}
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      navigate('/error')
    })
  }

  const executeSortList = (item: string) => {
    if (companyForUser) {
      if (item === filter.sort_field) {
        switch (filter.sort_mode) {
          case 'asc':
            filter.sort_mode = 'desc'
            break;
          case 'desc':
            filter.sort_mode = 'asc'
            break;
        }
      } else {
        filter.sort_field = item
        filter.sort_mode = 'asc'
      }
      loadInvoices(companyForUser.id)
    }
  }

  useEffect( () => {
    let filter_invoices: any = (localStorage.getItem('filter_invoices')) ? JSON.parse(localStorage.getItem('filter_invoices')!) : null

    if (companyForUser) {
      filter.interval = (filter_invoices && filter_invoices.company_id === companyForUser.id) ? filter_invoices.interval : ''
      filter.start_date = (filter_invoices && filter_invoices.company_id === companyForUser.id) ? filter_invoices.start_date : ''
      filter.end_date = (filter_invoices && filter_invoices.company_id === companyForUser.id) ? filter_invoices.end_date : ''
      filter.contact = (filter_invoices && filter_invoices.company_id === companyForUser.id) ? filter_invoices.contact : ''
      //filter.treasury_account = (filter_invoices && filter_invoices.company_id === companyForUser.id) ? filter_invoices.treasury_account : ''
      filter.status = (filter_invoices && filter_invoices.company_id === companyForUser.id) ? filter_invoices.status : ''
      filter.expiration = (filter_invoices && filter_invoices.company_id === companyForUser.id) ? filter_invoices.expiration : ''
      filter.validity = (filter_invoices && filter_invoices.company_id === companyForUser.id) ? filter_invoices.validity : ''
      filter.payment = (filter_invoices && filter_invoices.company_id === companyForUser.id) ? filter_invoices.payment : ''
      filter.sort_field = (filter_invoices && filter_invoices.company_id === companyForUser.id) ? filter_invoices.sort_field : ''
      filter.sort_mode = (filter_invoices && filter_invoices.company_id === companyForUser.id) ? filter_invoices.sort_mode : ''
      filter.group = (filter_invoices && filter_invoices.company_id === companyForUser.id) ? filter_invoices.group : ''

      setButton({filter: false, group: false})
      setInvoices(null)
      setInvoiceGroups(null)

      loadInvoices(companyForUser.id)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyForUser])

  useEffect( () => {
    setMounted(true)
    setRoute({path: {root: lang.labels.invoices, branch: lang.labels.listInvoices}, company: true})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { companyForUser && contactsForCompany && invoices && invoiceGroups
        ?
        <div className="card card-flush">
          <AppSaleRecordList sale={"invoice"} storage={storage} button={button} filter={filter} records={invoices} recordGroups={invoiceGroups} setFilter={setFilter} loadSaleRecord={loadInvoices}></AppSaleRecordList>
          <div className="card-body pt-0">
            <div className="dataTables_wrapper">
              <div className="table-responsive">
                <table className="table align-middle table-row-dashed mb-2">
                  <thead className="border-bottom">
                    <tr className="text-start text-muted text-uppercase fw-bolder fs-7">
                      <th className="w-100px">
                        <div className="d-flex align-items-center text-nowrap cursor-pointer" onClick={() => executeSortList("created date")}>
                          <span className="me-1">{lang.labels.date}</span>
                          <AppSortTable attribute={"created date"} field={filter.sort_field} mode={filter.sort_mode}></AppSortTable>
                        </div>
                      </th>
                      <th className="w-125px">
                        <div className="d-flex align-items-center text-nowrap cursor-pointer" onClick={() => executeSortList("invoice number")}>
                          <span className="me-1">{lang.labels.invoiceNumber}</span>
                          <AppSortTable attribute={"invoice number"} field={filter.sort_field} mode={filter.sort_mode}></AppSortTable>
                        </div>
                      </th>
                      <th className="min-w-100px">
                        <div className="d-flex align-items-center text-nowrap cursor-pointer" onClick={() => executeSortList("customer")}>
                          <span className="me-1">{lang.labels.customer}</span>
                          <AppSortTable attribute={"customer"} field={filter.sort_field} mode={filter.sort_mode}></AppSortTable>
                        </div>
                      </th>
                      <th className="min-w-100px">
                        <div className="d-flex align-items-center text-nowrap cursor-pointer" onClick={() => executeSortList("concerning")}>
                          <span className="me-1">{lang.labels.concerning}</span>
                          <AppSortTable attribute={"concerning"} field={filter.sort_field} mode={filter.sort_mode}></AppSortTable>
                        </div>
                      </th>
                      <th className="min-w-100px">
                        <div className="d-flex align-items-center text-nowrap cursor-pointer" onClick={() => executeSortList("validity")}>
                          <span className="me-1">{lang.labels.validity}</span>
                          <AppSortTable attribute={"validity"} field={filter.sort_field} mode={filter.sort_mode}></AppSortTable>
                        </div>
                      </th>
                      <th className="min-w-100px">
                        <div className="d-flex align-items-center text-nowrap cursor-pointer" onClick={() => executeSortList("status")}>
                          <span className="me-1">{lang.labels.status}</span>
                          <AppSortTable attribute={"status"} field={filter.sort_field} mode={filter.sort_mode}></AppSortTable>
                        </div>
                      </th>
                      <th className="min-w-100px">
                        <div className="d-flex align-items-center text-nowrap cursor-pointer" onClick={() => executeSortList("valid until")}>
                          <span className="me-1">{lang.labels.validUntil}</span>
                          <AppSortTable attribute={"valid until"} field={filter.sort_field} mode={filter.sort_mode}></AppSortTable>
                        </div>
                      </th>
                      <th className="min-w-100px text-end">
                        <div className="d-flex align-items-center justify-content-end text-nowrap cursor-pointer" onClick={() => executeSortList("amount payed")}>
                          <span className="me-1">{lang.labels.amountPaid}</span>
                          <AppSortTable attribute={"amount payed"} field={filter.sort_field} mode={filter.sort_mode}></AppSortTable>
                        </div>
                      </th>
                      <th className="min-w-100px text-end">
                        <div className="d-flex align-items-center justify-content-end text-nowrap cursor-pointer" onClick={() => executeSortList("amount debt")}>
                          <span className="me-1">{lang.labels.amountDebt}</span>
                          <AppSortTable attribute={"amount debt"} field={filter.sort_field} mode={filter.sort_mode}></AppSortTable>
                        </div>
                      </th>
                      <th className="min-w-100px text-end">
                        <div className="d-flex align-items-center justify-content-end text-nowrap cursor-pointer" onClick={() => executeSortList("amount total")}>
                          <span className="me-1">{lang.labels.amountTotal}</span>
                          <AppSortTable attribute={"amount total"} field={filter.sort_field} mode={filter.sort_mode}></AppSortTable>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  { invoices.length > 0 || invoiceGroups.length > 0
                    ?
                    <>
                      { button.group
                        ?
                          <>
                            { invoiceGroups.map (( (item, index) => { return (
                              <tbody key={index} className="text-gray-700 fw-bold">
                                <tr>
                                  <td colSpan={10} className="text-gray-700 fw-bolder fs-7 text-uppercase">
                                    <div className="d-flex align-items-center">
                                      <span className="svg-icon svg-icon-3 svg-icon-gray-900 me-5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                          <path d="M22 12C22 12.9 21.9 13.8 21.7 14.6L5 4.89999C6.8 3.09999 9.3 2 12 2C17.5 2 22 6.5 22 12Z" fill="black"/>
                                          <path opacity="0.3" d="M3.7 17.5C2.6 15.9 2 14 2 12C2 9.20003 3.1 6.70002 5 4.90002L9.3 7.40002V14.2L3.7 17.5ZM17.2 12L5 19.1C6.8 20.9 9.3 22 12 22C16.6 22 20.5 18.8 21.7 14.6L17.2 12Z" fill="black"/>
                                        </svg>
                                      </span>
                                      <span className="me-3">{lang.labels.group} {index + 1}:</span>
                                      {nameGroup(lang, filter.group, item.group_detail)}
                                    </div>
                                  </td>
                                </tr>
                                { item.invoices.map (( (sub_item, sub_index) => { return (
                                  <tr key={sub_index} className="row-dashed">
                                    <td className="text-nowrap">{dateFormatAbbreviated(lang.code, sub_item.created_date)}</td>
                                    <td>
                                      <Link to={"/app/sale/invoice/view/" + sub_item.id} className="fw-bolder text-nowrap text-dark text-hover-primary">{sub_item.invoice_number}</Link>
                                    </td>
                                    <td>{sub_item.customer.type === "person" ? `${sub_item.customer.data.first_name} ${sub_item.customer.data.last_name}` : `${sub_item.customer.data.name}`}</td>
                                    <td>{sub_item.concerning}</td>
                                    <td>
                                      <span className={`badge badge-light-${colorValidated(sub_item.validated)} fs-7`}>{validationBoolean(lang, sub_item.validated)}</span>
                                    </td>
                                    <td>
                                      <span className={`badge badge-light-${colorStatusInvoice(sub_item.status)} fs-7`}>{statusDocumentValue(lang, sub_item.status)}</span>
                                    </td>
                                    <td>
                                      <span className={`badge badge-light-${colorValidUntilInvoice(sub_item.status, sub_item.valid_until)} fs-7`}>{dateFormatAbbreviated(lang.code, sub_item.valid_until)}</span>
                                    </td>
                                    <td className="text-end">
                                      <span className={`badge badge-light-${colorStatusPayment(sub_item.payment_status)} fs-7`}>{sub_item.currency.symbol} {moneyFormat(sub_item.amount.paid)}</span>
                                    </td>
                                    <td className="text-end">
                                      <span className={`badge badge-light-${colorStatusPayment(sub_item.payment_status)} fs-7`}>{sub_item.currency.symbol} {moneyFormat(sub_item.amount.debt)}</span>
                                    </td>
                                    <td className="text-end text-nowrap">{sub_item.currency.symbol} {moneyFormat(sub_item.amount.total)}</td>
                                  </tr>
                                )}))}
                                <tr className={`text-gray-800 fw-bolder fs-7 ${index < invoiceGroups.length - 1 && "row-dashed"}`}>
                                  <td colSpan={7}></td>
                                  <td className="text-end text-nowrap">{companyForUser.currency.symbol} {moneyFormat(accumulatedAmountDocuments(item.invoices).paid)}</td>
                                  <td className="text-end text-nowrap">{companyForUser.currency.symbol} {moneyFormat(accumulatedAmountDocuments(item.invoices).debt)}</td>
                                  <td className="text-end text-nowrap">{companyForUser.currency.symbol} {moneyFormat(accumulatedAmountDocuments(item.invoices).total)}</td>
                                </tr>
                              </tbody>
                            )}))}
                          </>
                        :
                        <>
                          <tbody className="text-gray-700 fw-bold">
                            { invoices.map (( (item, index) => { return (
                              <tr key={index} className="row-dashed">
                                <td className="text-nowrap">{dateFormatAbbreviated(lang.code, item.created_date)}</td>
                                <td>
                                  <Link to={"/app/sale/invoice/view/" + item.id} className="fw-bolder text-nowrap text-dark text-hover-primary">{item.invoice_number}</Link>
                                </td>
                                <td>{item.customer.type === "person" ? `${item.customer.data.first_name} ${item.customer.data.last_name}` : `${item.customer.data.name}`}</td>
                                <td>{item.concerning}</td>
                                <td>
                                  <span className={`badge badge-light-${colorValidated(item.validated)} fs-7`}>{validationBoolean(lang, item.validated)}</span>
                                </td>
                                <td>
                                  <span className={`badge badge-light-${colorStatusInvoice(item.status)} fs-7`}>{statusDocumentValue(lang, item.status)}</span>
                                </td>
                                <td>
                                  <span className={`badge badge-light-${colorValidUntilInvoice(item.status, item.valid_until)} fs-7`}>{dateFormatAbbreviated(lang.code, item.valid_until)}</span>
                                </td>
                                <td className="text-end">
                                  <span className={`badge badge-light-${colorStatusPayment(item.payment_status)} fs-7`}>{item.currency.symbol} {moneyFormat(item.amount.paid)}</span>
                                </td>
                                <td className="text-end">
                                  <span className={`badge badge-light-${colorStatusPayment(item.payment_status)} fs-7`}>{item.currency.symbol} {moneyFormat(item.amount.debt)}</span>
                                </td>
                                <td className="text-end text-nowrap">{item.currency.symbol} {moneyFormat(item.amount.total)}</td>
                              </tr>
                            )}))}
                          </tbody>
                          <tfoot>
                            <tr className="text-gray-800 fw-bolder fs-7">
                              <td colSpan={7}></td>
                              <td className="text-end text-nowrap">{companyForUser.currency.symbol} {moneyFormat(accumulatedAmountDocuments(invoices).paid)}</td>
                              <td className="text-end text-nowrap">{companyForUser.currency.symbol} {moneyFormat(accumulatedAmountDocuments(invoices).debt)}</td>
                              <td className="text-end text-nowrap">{companyForUser.currency.symbol} {moneyFormat(accumulatedAmountDocuments(invoices).total)}</td>
                            </tr>
                          </tfoot>
                        </>
                      }
                    </>
                    :
                    <tbody>
                      <tr className="text-gray-700 fw-bold">
                        <td valign="top" colSpan={10} className="text-center">{lang.labels.noRecordsFound}</td>
                      </tr>
                    </tbody>
                  }
                </table>
              </div>
            </div>
          </div>
        </div>
        :
        <AppPreloader></AppPreloader>
      }
    </div>
  )
}

export default InvoiceListPage;
