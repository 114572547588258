import axios from 'axios';
import format from 'date-fns/format';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { Invoice } from '../models/invoice.model';
import { ProviderInvoice } from '../models/provider.invoice.model';
import { appToken } from '../scripts/app.token.script';
import { AccountingEntry } from '../models/accounting.entry.model';

export class PaymentDA {
  static apiResponse: ApiResponse

  public static async postPaymentInvoice(id_company: string, id_invoice: string, amount_credit: string, amount_debit: string, date: string, method: string, treasury_account_id: string, accounting_entry_id: string, company_data: { subject_tax: boolean, vat_debt_method: string, tax_method: string, tax_rate: string }): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let accountingEntries: AccountingEntry[] | null = null
      let invoice: Invoice | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/accounting.incomplete.entries.data.json').then(response => { accountingEntries = response.data.accounting_entries }).catch(error => { alert(error) })
      await axios.get('../../../../../database/invoice.data.json').then(response => { invoice = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (invoice!.id === id_invoice && invoice!.amount.debt >= Number(amount_credit)) {
        invoice!.amount.debt -= Number(amount_credit)
        invoice!.amount.paid += Number(amount_credit)
        invoice!.payment_status = (invoice!.amount.debt === 0) ? 'fully paid' : 'partially paid'
        invoice!.payments.push({
          id: `payment${(Math.floor(Math.random() * 1000)).toString()}`,
          amount: Number(amount_credit),
          source: (accounting_entry_id.length > 0) ? (accountingEntries!.find(item => item.id === accounting_entry_id)!).debits[0].reference.id_treasury_account : treasury_account_id,
          method: (accounting_entry_id.length > 0) ? (accountingEntries!.find(item => item.id === accounting_entry_id)!).debits[0].reference.payment_method : method,
          created_date: (accounting_entry_id.length > 0) ? (accountingEntries!.find(item => item.id === accounting_entry_id)!).date : date,
          created_at: `${new Date(format((new Date()), 'yyyy-MM-dd HH:mm:ss zzzz'))}`,
          updated_at: `${new Date(format((new Date()), 'yyyy-MM-dd HH:mm:ss zzzz'))}`,
          is_delete: false
        })

        this.apiResponse = {
          status: 201,
          message: 'Success',
          data: invoice
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/invoices/${id_invoice}/payments`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      let data: any = {
        amount_credit: amount_credit,
        amount_debit: amount_debit,
        date: date,
        method: method,
        treasury_account: treasury_account_id,
        accounting_entry: accounting_entry_id,
        company: company_data
      }

      await axios.post(url, data, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async postPaymentProviderInvoice(id_company: string, id_provider_invoice: string, amount: string, source: string, method: string, date: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let providerInvoice: ProviderInvoice | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/invoice.data.json').then(response => { providerInvoice = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (providerInvoice!.id === id_provider_invoice && providerInvoice!.amount.debt >= Number(amount)) {
        providerInvoice!.amount.debt -= Number(amount)
        providerInvoice!.amount.paid -= Number(amount)
        providerInvoice!.payment_status = (providerInvoice!.amount.debt === 0) ? 'fully paid' : 'partially paid'
        providerInvoice!.payments.push({
          id: `payment${(Math.floor(Math.random() * 1000)).toString()}`,
          amount: Number(amount),
          source: source,
          method: method,
          created_date: date,
          created_at: `${new Date(format((new Date()), 'yyyy-MM-dd HH:mm:ss zzzz'))}`,
          updated_at: `${new Date(format((new Date()), 'yyyy-MM-dd HH:mm:ss zzzz'))}`,
          is_delete: false
        })

        this.apiResponse = {
          status: 201,
          message: 'Success',
          data: providerInvoice
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/provider/invoices/${id_provider_invoice}/payments`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      let data: any = {
        amount: amount,
        method: method,
        source: source,
        created_date: date
      }

      await axios.post(url, data, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async deletePaymentInvoice(id_company: string, id_invoice: string, id_payment: string, company_data: { subject_tax: boolean, vat_debt_method: string, tax_method: string, tax_rate: string }): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let invoice: Invoice | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/invoice.data.json').then(response => { invoice = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (invoice!.payments.some(item => item.id === id_payment)) {
        invoice!.amount.debt += Number(invoice!.payments.find(item => item.id === id_payment)!.amount)
        invoice!.amount.paid -= Number(invoice!.payments.find(item => item.id === id_payment)!.amount)
        invoice!.payment_status = (invoice!.amount.paid === 0) ? 'not payed' : 'partially paid'
        invoice!.payments = invoice!.payments.filter(item => item.id !== id_payment)

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: invoice
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/invoices/${id_invoice}/payments/${id_payment}`

      let config: any = {
        headers: { Authorization: appToken() },
        data: { company: company_data }
      }

      await axios.delete(url, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async deletePaymentProviderInvoice(id_company: string, id_provider_invoice: string, id_payment: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let providerInvoice: ProviderInvoice | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/invoice.data.json').then(response => { providerInvoice = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (providerInvoice!.payments.some(item => item.id === id_payment)) {
        providerInvoice!.amount.debt += Number(providerInvoice!.payments.find(item => item.id === id_payment)!.amount)
        providerInvoice!.amount.paid -= Number(providerInvoice!.payments.find(item => item.id === id_payment)!.amount)
        providerInvoice!.payment_status = (providerInvoice!.amount.paid === 0) ? 'not payed' : 'partially paid'
        providerInvoice!.payments = providerInvoice!.payments.filter(item => item.id !== id_payment)

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: providerInvoice
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/provider/invoices/${id_provider_invoice}/payments/${id_payment}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.delete(url, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }
}
