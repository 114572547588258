import axios from 'axios';
import { format, subDays } from 'date-fns';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { Company } from '../models/company.model';
import { CompanyAccountingEntries } from '../models/company.accounting.entries.model';
import { AccountingEntry } from '../models/accounting.entry.model';
import { CompanyAccountingContactEntries } from '../models/company.accounting.contact.entries.model';
import { Message } from '../models/message.model';
import { appToken } from '../scripts/app.token.script';

export class AccountingEntryDA {
  static apiResponse: ApiResponse

  public static async getAccountingIncompleteEntries(id_company: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let accountingEntriesByCompany: CompanyAccountingEntries | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/accounting.incomplete.entries.data.json').then(response => { accountingEntriesByCompany = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (accountingEntriesByCompany!.id_company === id_company) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: accountingEntriesByCompany
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/accounting/entries/incomplete`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.get(url, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async getAccountingContactEntries(id_company: string, id_contact: string, interval: string, start_date: string, end_date: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let accountingContactEntriesByCompany: CompanyAccountingContactEntries | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/accounting.contact.entries.data.json').then(response => { accountingContactEntriesByCompany = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (accountingContactEntriesByCompany!.id_company === id_company && accountingContactEntriesByCompany!.id_contact === id_contact) {
        accountingContactEntriesByCompany!.filter = { interval, start_date, end_date }

        if (start_date.length > 0) {
          accountingContactEntriesByCompany!.balance_previous.date = format(subDays(new Date(start_date), 1), 'yyyy-MM-dd')
          accountingContactEntriesByCompany!.balance_previous.amount = Math.floor(Math.random() * 100000)
        }

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: accountingContactEntriesByCompany
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let startOfDay: Date = new Date(new Date().setHours(0, 0, 0, 0))
      let endOfDay: Date = new Date(new Date().setHours(23, 59, 59, 999))
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/accounting/entries/contact/${id_contact}?interval=${interval}&start_date=${start_date}&end_date=${end_date}&start_day=${startOfDay}&end_day=${endOfDay}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.get(url, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async postAccountingEntry(id_company: string, id_contact: string, id_treasury_account: string, payment_method: string, date: string, amount: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let company: Company | null = null
      let message: Message | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/company.data.json').then(response => { company = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/message.data.json').then(response => { message = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (company!.id === id_company) {
        this.apiResponse = {
          status: 201,
          message: 'Success',
          data: message
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/accounting/entries`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      let data: any = {
        contact: id_contact,
        treasury_account: id_treasury_account,
        payment_method: payment_method,
        date: date,
        amount: amount
      }

      await axios.post(url, data, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async putAccountingEntry(id_company: string, id_accounting_entry: string, id_contact: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let accountingEntries: AccountingEntry[] | null = null
      let message: Message | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/accounting.incomplete.entries.data.json').then(response => { accountingEntries = response.data.accounting_entries }).catch(error => { alert(error) })
      await axios.get('../../../../../database/message.data.json').then(response => { message = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (accountingEntries!.some(item => item.id === id_accounting_entry)) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: message
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/accounting/entries/${id_accounting_entry}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      let data: any = {
        contact: id_contact
      }

      await axios.put(url, data, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }
}
