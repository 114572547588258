import React, { useEffect, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

import { UseOutletContextExpense } from './Expense';
import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { getError, getMessage } from '../../../../languages/translations/response';
import { ExpenseDA } from '../../../../services/expense.service';
import { Error } from '../../../../models/error.model';
import { Message } from '../../../../models/message.model';
import { legendInvalid, legendInvalidIcon } from '../../../../tools/legend.data.entry.tool';
import { isValidObjectAttributes } from '../../../../scripts/compare.object.script';

import AppPurchaseRecordCreate from '../card/PurchaseRecordCreate';
import AppPurchaseFormRequirements from '../card/PurchaseFormRequirements';
import AppPurchaseFormVouchers from '../card/PurchaseFormVouchers';
import AppPreloader from '../../../../components/Preloader';

export interface ExpenseCreatePageProps {};

let errorResponse: Error, messageResponse: Message;

const ExpenseCreatePage: React.FunctionComponent<ExpenseCreatePageProps> = props => {
  const {setRoute, companyForUser, settingsForCompany, treasuryAccountsForCompany} = UseOutletContextExpense()
  const {lang} = StateLanguage()
  const navigate = useNavigate()

  const [mounted, setMounted] = useState(false)
  const [loadIndicator, setLoadIndicator] = useState('off')
  const [concerning, setConcerning] = useState({value: '', valid: false})
  const [date, setDate] = useState({value: '', valid: false})
  const [currency, setCurrency] = useState({value: '', object: null as any, valid: false})
  const [treasuryAccount, setTreasuryAccount] = useState({value: '', valid: false})
  const [paymentMethod, setPaymentMethod] = useState({value: '', valid: false})
  const [requirements, setRequirements] = useState({list: false, value: [] as {type: string, description: string, contents: {detail: string, quantity: string, price_unit: string, tax_included: boolean, tax_rate: string, unit: string}[]}[], valid: [] as {type: boolean, description: boolean, content: boolean, contents: {detail: boolean, quantity: boolean, price_unit: boolean, tax_rate: boolean, unit: boolean}[]}[]})
  const [vouchers, setVouchers] = useState({list: true, value: [] as {name: string, view: string, file: any}[], valid: [] as {name: boolean, view: boolean, file: boolean}[]})

  const submitExpenseCreate = async () => {
    setLoadIndicator('on')

    let requerimentsIsValid: boolean = requirements.valid.every((item) => isValidObjectAttributes(item))
    let vouchersIsValid: boolean = vouchers.valid.every((item) => isValidObjectAttributes(item))

    if (companyForUser && concerning.valid && date.valid && currency.valid && treasuryAccount.valid && paymentMethod.valid && requirements.list && vouchers.list && requerimentsIsValid && vouchersIsValid) {
      let companyData = {uuid: companyForUser.uuid, name: companyForUser.name, logo: companyForUser.logo, address: companyForUser.address}
      let voucherFiles = vouchers.value.map((voucher) => ({name: voucher.name, file: voucher.file}))

      await ExpenseDA.postExpense(companyForUser.id, concerning.value, date.value, currency.object, treasuryAccount.value, paymentMethod.value, requirements.value, voucherFiles, companyData).then( (response) => {
        if (response.status === 201) {
          messageResponse = response.data

          Swal.fire({
            title: getMessage(messageResponse.message, lang.code),
            text: lang.labels.actionCompletedReturningToPage,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            navigate('/app/purchase/expense/list', {replace: true})
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.code),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        navigate('/error')
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        if (concerning.value.length === 0) {
          legendInvalid('legend-concerning-required')
        }
        if (date.value.length === 0) {
          legendInvalid('legend-date-required')
        }
        if (currency.value.length === 0) {
          legendInvalid('legend-currency-required')
        }
        if (treasuryAccount.value.length === 0) {
          legendInvalid('legend-treasury-account-required')
        }
        if (paymentMethod.value.length === 0) {
          legendInvalid('legend-payment-method-required')
        }
        if (requirements.value.length === 0) {
          legendInvalid('legend-requirements-required')
        }
        if (!requerimentsIsValid) {
          for (let i = 0; i < requirements.value.length; i++) {
            if (requirements.value[i].contents.length === 0) {
              legendInvalid('legend-requirement-contents-required-' + i)
            } else {
              for (let j = 0; j < requirements.value[i].contents.length; j++) {
                if (requirements.value[i].contents[j].detail.length === 0) {
                  legendInvalid('legend-requirement-content-detail-required-' + i + j)
                }
                if (requirements.value[i].contents[j].unit.length === 0) {
                  legendInvalid('legend-requirement-content-unit-required-' + i + j)
                }
                if (requirements.value[i].contents[j].quantity.length === 0) {
                  legendInvalid('legend-requirement-content-quantity-required-' + i + j)
                }
                if (requirements.value[i].contents[j].price_unit.length === 0) {
                  legendInvalid('legend-requirement-content-price-required-' + i + j)
                }
                if (requirements.value[i].contents[j].tax_rate.length === 0) {
                  legendInvalid('legend-requirement-content-tax-required-' + i + j)
                }
              }
            }
          }
        }
        if (!vouchersIsValid) {
          for (let i = 0; i < vouchers.value.length; i++) {
            if (vouchers.value[i].name.length === 0) {
              legendInvalidIcon('input-voucher-name-' + i, 'legend-voucher-name-required-' + i)
            }
          }
        }
        setLoadIndicator('off')
      })
    }
  }

  useEffect( () => {
    setMounted(true)
    setRoute({path: {root: lang.labels.expenses, branch: lang.labels.createExpense}, company: true})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { companyForUser && settingsForCompany && treasuryAccountsForCompany
        ?
        <div className="form">
          <AppPurchaseRecordCreate purchase={"expense"} provider={null} concerning={concerning} date={date} currency={currency} paymentMethod={paymentMethod} treasuryAccount={treasuryAccount} setProvider={null} setConcerning={setConcerning} setDate={setDate} setCurrency={setCurrency} setTreasuryAccount={setTreasuryAccount} setPaymentMethod={setPaymentMethod}></AppPurchaseRecordCreate>
          <AppPurchaseFormRequirements currency={currency.object} requirements={requirements} setRequirements={setRequirements}></AppPurchaseFormRequirements>
          <AppPurchaseFormVouchers vouchers={vouchers} setVouchers={setVouchers}></AppPurchaseFormVouchers>
          <div className="d-flex flex-stack flex-wrap flex-md-nowrap bg-light-primary border border-primary border-dashed rounded h-100 p-5">
            <div className="notice d-flex align-items-center mb-5 mb-md-0 me-0 me-md-5">
              <span className="svg-icon svg-icon-3tx svg-icon-primary me-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM16 13H13V10C13 9.4 12.6 9 12 9C11.4 9 11 9.4 11 10V13H8C7.4 13 7 13.4 7 14C7 14.6 7.4 15 8 15H11V18C11 18.6 11.4 19 12 19C12.6 19 13 18.6 13 18V15H16C16.6 15 17 14.6 17 14C17 13.4 16.6 13 16 13Z" fill="black" />
                  <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black" />
                </svg>
              </span>
              <div className="d-grid">
                <span className="text-start text-dark fw-bolder fs-5">{lang.labels.processOfCreatingANewExpenseCompleted}</span>
                <span className="text-justify text-gray-600 fw-bold">
                  {lang.labels.clickOn}
                  <span className="text-primary text-lowercase fw-bolder mx-1">{lang.labels.createExpense}</span>
                  {lang.labels.ifYouWantToSaveTheNewExpense}
                </span>
              </div>
            </div>
            <button className="btn btn-lg btn-primary fw-bolder w-100 w-md-300px" type="button" data-kt-indicator={loadIndicator} onClick={submitExpenseCreate}>
              <span className="indicator-label text-uppercase text-nowrap">{lang.labels.createExpense}</span>
              <span className="indicator-progress">
              {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
        </div>
        :
        <AppPreloader></AppPreloader>
      }
    </div>
  )
}

export default ExpenseCreatePage;
