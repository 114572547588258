import React, { Dispatch, SetStateAction, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { getError } from '../../languages/translations/response';
import { SettingsDA } from '../../services/settings.services';
import { Error } from '../../models/error.model';
import { Company } from '../../models/company.model';
import { Settings } from '../../models/settings.model';
import { legendValidInvalidIconRestart, legendInvalidIcon, legendValid } from '../../tools/legend.data.entry.tool';
import { modalHide, modalShow } from '../../tools/modal.tool';
import { evaluateLegendValidRequiredIcon } from '../../scripts/validate.legend.script';
import { expressions } from '../../libraries/regular.expressions.library';
import { inventoryTypeValue } from '../../libraries/inventory.type.library';

import AppLegend from '../element/Legend';

export interface AppModalSettingCreateUnitProps {
  companyForUser: Company | undefined | null,
  data: {type: string, main_modal: string | null, index: number | null, sub_index: number | null},
  variant: {value: any, valid: any} | null,
  requirements: {list: boolean, value: any[], valid: any[]} | null,
  setData: Dispatch<SetStateAction<{type: string, main_modal: string | null, index: number | null, sub_index: number | null}>>,
  setUnitMeasure: Dispatch<SetStateAction<{value: string, valid: boolean}>> | null,
  setVariant: Dispatch<SetStateAction<{value: any, valid: any}>> | null,
  setRequirements: Dispatch<SetStateAction<{list: boolean, value: any[], valid: any[]}>> | null,
  loadSettingsForCompany: Function
};

let errorResponse: Error, settingsResponse: Settings;

const AppModalSettingCreateUnit: React.FunctionComponent<AppModalSettingCreateUnitProps> = ({companyForUser, data, variant, requirements, setData, setUnitMeasure, setVariant, setRequirements, loadSettingsForCompany}) => {
  const {lang} = StateLanguage()
  const navigate = useNavigate()

  const [loadIndicator, setLoadIndicator] = useState('off')
  const [name, setName] = useState({value: '', valid: false})
  const [symbol, setSymbol] = useState({value: '', valid: false})

  const submitSettingsParameterCreate = async () => {
    setLoadIndicator('on')

      if (companyForUser && data && name.valid && symbol.valid) {
        let unit: {type: string, name: string, symbol: string} = {type: data.type, name: name.value, symbol: symbol.value}

        await SettingsDA.putSettingsParameters(companyForUser.id, null, null, unit).then( (response) => {
          if (response.status === 200) {
            settingsResponse = response.data
            loadSettingsForCompany(companyForUser.id)

            if (setUnitMeasure) {
              setUnitMeasure({value: (settingsResponse.measure_units.find((item) => (item.type === unit.type && item.name === unit.name && item.symbol === unit.symbol))!).id, valid: true})
              legendValid('legend-unit-required')
            }

            if (variant && setVariant) {
              setVariant({value: {...variant.value, unit: (settingsResponse.measure_units.find((item) => (item.type === unit.type && item.name === unit.name && item.symbol === unit.symbol))!).id}, valid: {...variant.valid, unit: true}})

              if ("main_modal" in data) {
                legendValid(data.main_modal + '-legend-variant-unit-required')
              } else {
                legendValid('page-legend-variant-unit-required')
              }
            }

            if ("index" in data && "sub_index" in data && requirements && setRequirements) {
              let tempRequirementsValue = requirements.value.map((temp_item, temp_index) => {
                if (temp_index === data.index) {
                  temp_item.contents[data.sub_index!].unit = (settingsResponse.measure_units.find((item) => (item.type === unit.type && item.name === unit.name && item.symbol === unit.symbol))!).id
                }
                return temp_item
              })
              let tempRequirementsValid = requirements.valid.map((temp_item, temp_index) => {
                if (temp_index === data.index) {
                  temp_item.contents[data.sub_index!].unit = true
                }
                return temp_item
              })

              setRequirements({...requirements, value: tempRequirementsValue, valid: tempRequirementsValid})
              legendValid('legend-requirement-content-unit-required-' + data.index + data.sub_index)
            }

            Swal.fire({
              title: lang.labels.successfullyCreatedCompanyParameters,
              text: lang.labels.actionCompletedReturningToPage,
              icon: 'success',
              showConfirmButton: false,
              timer: 1800
            } as SweetAlertOptions).then( () => {
              setLoadIndicator('off')
              executeHideModalSettingUnitCreate()
            })
          } else {
            errorResponse = response.data

            Swal.fire({
              title: getError(errorResponse.code, lang.code),
              text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
              icon: 'error',
              buttonsStyling: !1,
              confirmButtonText: lang.labels.okGotIt,
              customClass: {confirmButton: 'btn btn-primary'}
            } as SweetAlertOptions).then( () => {
              setLoadIndicator('off')
            })
          }
        }).catch( (error) => {
          console.error(error)
          navigate('/error')
        })
      } else {
        Swal.fire({
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
          icon: 'error',
          showConfirmButton: false,
          timer: 1800
        } as SweetAlertOptions).then( () => {
          if (name.value.length === 0) {
            legendInvalidIcon('modal-settings-create-unit-input-name', 'modal-settings-create-unit-legend-name-required')
          }
          if (symbol.value.length === 0) {
            legendInvalidIcon('modal-settings-create-unit-input-symbol', 'modal-settings-create-unit-legend-symbol-required')
          }
          setLoadIndicator('off')
        })
      }
  }

  const handleChangeName = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setName({...name, value: event.target.value})
  }

  const handleChangeSymbol = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setSymbol({...symbol, value: event.target.value})
  }

  const validateName = () => {
    evaluateLegendValidRequiredIcon(expressions.text, name, setName, 'modal-settings-create-unit-input-name', 'modal-settings-create-unit-legend-name-valid', 'modal-settings-create-unit-legend-name-required')
  }

  const validateSymbol = () => {
    evaluateLegendValidRequiredIcon(expressions.text, symbol, setSymbol, 'modal-settings-create-unit-input-symbol', 'modal-settings-create-unit-legend-symbol-valid', 'modal-settings-create-unit-legend-symbol-required')
  }

  const executeHideModalSettingUnitCreate = () => {
    modalHide('modal-settings-create-unit')

    if (data.main_modal) {
      modalShow(data.main_modal)
    }

    setTimeout( () => {
      restartModal()
    }, 200 )
  }

  function restartModal() {
    legendValidInvalidIconRestart('modal-settings-create-unit-input-name', 'modal-settings-create-unit-legend-name-valid')
    legendValidInvalidIconRestart('modal-settings-create-unit-input-name', 'modal-settings-create-unit-legend-name-required')
    legendValidInvalidIconRestart('modal-settings-create-unit-input-symbol', 'modal-settings-create-unit-legend-symbol-valid')
    legendValidInvalidIconRestart('modal-settings-create-unit-input-symbol', 'modal-settings-create-unit-legend-symbol-required')

    setData({...data, type: "", index: null, sub_index: null})
    setName({value: '', valid: false})
    setSymbol({value: '', valid: false})
  }

  return (
    <div id="modal-settings-create-unit" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog mw-500px">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="text-capitalize">{lang.labels.addUnitMeasureParameter}</h2>
          </div>
          <div className="modal-body">
            { data.type.length > 0
              ?
              <div className="form">
                <div className="row mb-2">
                  <label className="col-lg-3 col-form-label required">{lang.labels.type}</label>
                  <div className="col-lg-9">
                    <input className="form-control form-control-solid is-valid" type="text" name="type" value={inventoryTypeValue(lang, data.type)} disabled />
                  </div>
                </div>
                <div className="row mb-2">
                  <label className="col-lg-3 col-form-label required">{lang.labels.name}</label>
                  <div className="col-lg-9">
                    <input id="modal-settings-create-unit-input-name" className="form-control form-control-solid" type="text" name="name" value={name.value} onChange={handleChangeName} onKeyUp={validateName} onBlur={validateName} />
                    <AppLegend component={"modal-settings-create-unit"} attribute={{validity: name.valid, name: "name", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                  </div>
                </div>
                <div className="row">
                  <label className="col-lg-3 col-form-label required">{lang.labels.symbol}</label>
                  <div className="col-lg-9">
                    <input id="modal-settings-create-unit-input-symbol" className="form-control form-control-solid" type="text" name="symbol" value={symbol.value} onChange={handleChangeSymbol} onKeyUp={validateSymbol} onBlur={validateSymbol} />
                    <AppLegend component={"modal-settings-create-unit"} attribute={{validity: symbol.valid, name: "symbol", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                  </div>
                </div>
              </div>
              :
              <div className="d-flex justify-content-center align-items-center w-100 h-200px">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">{lang.labels.loading}</span>
                </div>
              </div>
            }
          </div>
          <div className="modal-footer flex-center">
            <button className="btn btn-light mx-2" type="reset" onClick={executeHideModalSettingUnitCreate}>{lang.labels.discard}</button>
            <button className="btn btn-primary mx-2" type="button" data-kt-indicator={loadIndicator} onClick={submitSettingsParameterCreate}>
              <span className="indicator-label">{lang.labels.create}</span>
              <span className="indicator-progress">
                {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
};

export default AppModalSettingCreateUnit;
