import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FaExclamationCircle } from 'react-icons/fa';
import { format } from 'date-fns';
import TextareaAutosize from 'react-textarea-autosize';

import { UseOutletContextSale } from '../Sale';
import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { Deadline } from '../../../../models/deadline.model';
import { Currency } from '../../../../models/currency.model';
import { Contact } from '../../../../models/contact.model';
import { legendInvalid, legendInvalidIcon, legendValid, legendValidInvalidIconRestart } from '../../../../tools/legend.data.entry.tool';
import { modalShow } from '../../../../tools/modal.tool';
import { uploadTooltip } from '../../../../tools/tooltip.tool';
import { evaluateLegendValidRequired, evaluateLegendValidRequiredIcon } from '../../../../scripts/validate.legend.script';
import { contactSelected, paymentDeadlineSelected, treasuryAccountSelected } from '../../../../scripts/list.selected.item.script';
import { languageList, languageValue } from '../../../../libraries/language.library';
import { currencyList, currencyValue } from '../../../../libraries/currency.library';
import { qrTemplateList, qrTemplateValue } from '../../../../libraries/qr.template.library';
import { expressions } from '../../../../libraries/regular.expressions.library';

import AppLegend from '../../../../components/element/Legend';
import AppModalContactCreate from '../../../../components/modal/ModalContactCreate';
import AppModalSettingsCreateDeadline from '../../../../components/modal/ModalSettingsCreateDeadline';

export interface AppSaleRecordCreateProps {
  sale: string,
  customer: {value: string, object: any, valid: boolean},
  concerning: {value: string, valid: boolean},
  date: {value: string, valid: boolean},
  validUntil: {value: string, valid: boolean},
  paymentDeadline: {value: string, valid: boolean},
  currency: {value: string, object: any, valid: boolean},
  treasuryAccount: {value: string, valid: boolean},
  language: {value: string, valid: boolean},
  qrType: {value: string, valid: boolean} | null,
  discountType: {value: string, valid: boolean},
  discountValue: {value: string, valid: boolean},
  setCustomer: Dispatch<SetStateAction<{value: string, object: any, valid: boolean}>>,
  setConcerning: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setDate: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setValidUntil: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setPaymentDeadline: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setCurrency: Dispatch<SetStateAction<{value: string, object: any, valid: boolean}>>,
  setTreasuryAccount: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setLanguage: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setQrTtype: Dispatch<SetStateAction<{value: string, valid: boolean}>> | null,
  setDiscountType: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setDiscountValue: Dispatch<SetStateAction<{value: string, valid: boolean}>>
};

const AppSaleRecordCreate: React.FunctionComponent<AppSaleRecordCreateProps> = ({sale, customer, concerning, date, validUntil, paymentDeadline, currency, treasuryAccount, language, qrType, discountType, discountValue, setCustomer, setConcerning, setDate, setValidUntil, setPaymentDeadline, setCurrency, setTreasuryAccount, setLanguage, setQrTtype, setDiscountType, setDiscountValue}) => {
  const {userLoggedIn, companyForUser, settingsForCompany, treasuryAccountsForCompany, contactsForCompany, loadSettingsForCompany, loadContactsForCompany} = UseOutletContextSale()
  const {lang} = StateLanguage()

  const labels: {[key: string]: {title: string; desc: string; date: string}} = {
    quote: {title: lang.labels.newQuote, desc: lang.labels.descriptionCreateQuote, date: lang.labels.quoteDate},
    order: {title: lang.labels.newOrder, desc: lang.labels.descriptionCreateOrder, date: lang.labels.orderDate},
    invoice: {title: lang.labels.newInvoice, desc: lang.labels.descriptionCreateInvoice, date: lang.labels.invoiceDate}
  }

  const [searchContact, setSearchContact] = useState<{text: string, list: Contact[]}>({text: '', list: []})

  const handleChangeCustomer = (item: Contact) => {
    setCustomer({...customer, value: item.data.id, object: item, valid: true})
    setLanguage({...language, value: item.data.language, valid: true})
    legendValid('legend-customer-required')
    legendValid('legend-language-required')
  }

  const handleChangeConcerning = (event: React.ChangeEvent <HTMLFormElement | HTMLTextAreaElement>) => {
    setConcerning({...concerning, value: event.target.value})
  }

  const handleChangeDate = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    if (event.target.value.length > 0) {
      setDate({...date, value: event.target.value, valid: true})
      legendValid('legend-date-required')
    } else {
      setDate({...date, value: event.target.value, valid: false})
      legendInvalid('legend-date-required')
    }
  }

  const handleChangeValidUntil = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setPaymentDeadline({...paymentDeadline, value: '', valid: false})

    if (event.target.value.length > 0) {
      setValidUntil({...validUntil, value: event.target.value, valid: true})
      legendValid('legend-valid-until-required')
    } else {
      setValidUntil({...validUntil, value: event.target.value, valid: false})
      legendInvalid('legend-valid-until-required')
    }
  }

  const handleChangePaymentDeadline = (item: Deadline) => {
    let tempDate: Date = new Date()

    switch (item.type) {
      case 'day':
        tempDate.setDate(tempDate.getDate() + item.quantity)
        break;
      case 'week':
        tempDate.setDate(tempDate.getDate() + item.quantity * 7)
        break;
      case 'month':
        tempDate.setMonth(tempDate.getMonth() + item.quantity)
        break;  
      case 'year':
        tempDate.setFullYear(tempDate.getFullYear() + item.quantity)
        break;
    }

    setValidUntil({...validUntil, value: format(tempDate, 'yyyy-MM-dd'), valid: true})
    setPaymentDeadline({...paymentDeadline, value: item.id, valid: true})
    legendValid('legend-valid-until-required')
  }

  const handleChangeCurrency = (item: Currency) => {
    setCurrency({...currency, value: item.code, object: item, valid: true})
    legendValid('legend-currency-required')
  }

  const handleChangeTreasuryAccount = (item: string) => {
    setTreasuryAccount({...treasuryAccount, value: item, valid: true})
    legendValid('legend-treasury-account-required')
  }

  const handleChangeLanguage = (item: string) => {
    setLanguage({...language, value: item, valid: true})
    legendValid('legend-language-required')
  }

  const handleChangeQrType = (item: string) => {
    if (qrType && setQrTtype) {
      setQrTtype({...qrType, value: item, valid: true})
      legendValid('legend-qr-type-required')
    }
  }

  const handleChangeDiscountType = (item: string) => {
    if (item !== discountType.value) {
      setDiscountType({...discountType, value: item, valid: true})
      setDiscountValue({...discountValue, value: '', valid: (item === 'none')})
      legendValidInvalidIconRestart('input-discount', 'legend-discount-valid')
      legendValidInvalidIconRestart('input-discount', 'legend-discount-required')
    }
  }

  const handleChangeDiscountValue = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setDiscountValue({...discountValue, value: event.target.value})
  }

  const handleChangeSearchContact = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let text: string = event.target.value
    let list: Contact[] = []

    if (contactsForCompany) {
      if (text.length > 0) {
        for (let contact of contactsForCompany) {
          let name: string = (contact.type === 'person') ? (`${contact.data.first_name} ${contact.data.last_name}`).toLowerCase() : contact.data.name.toLowerCase()
          if (name.indexOf(text.toLowerCase()) !== -1) {
            list.push(contact)
          }
        }
      } else {
        list = contactsForCompany
      }
    }

    setSearchContact({text: text, list: list})
  }

  const validateConcerning = () => {
    evaluateLegendValidRequired(expressions.text, concerning, setConcerning, 'legend-concerning-valid', 'legend-concerning-required')
  }

  const validateDiscount = () => {
    switch (discountType.value) {
      case 'percentage':
        evaluateLegendValidRequiredIcon(expressions.percentage, discountValue, setDiscountValue, 'input-discount', 'legend-discount-valid', 'legend-discount-required')
        break;
      case 'fixed':
        evaluateLegendValidRequiredIcon(expressions.currency, discountValue, setDiscountValue, 'input-discount', 'legend-discount-valid', 'legend-discount-required')
        break;
      case 'none':
        setDiscountValue({...discountValue, valid: true})
        legendValidInvalidIconRestart('input-discount', 'legend-discount-valid')
        legendValidInvalidIconRestart('input-discount', 'legend-discount-required')
        break;
      default:
        setDiscountValue({...discountValue, valid: false})
        if (discountValue.value.length === 0) {
          legendInvalidIcon('input-discount', 'legend-discount-required')
        } else {
          legendInvalidIcon('input-discount', 'legend-discount-valid')
        }
        break;
    }
  }

  const executeShowModalContactCreate = () => {
    modalShow('modal-contact-create')
  }

  const executeShowModalSettingsCreateDeadline = () => {
    modalShow('modal-settings-create-deadline')
  }

  function minDate(): string {
    return format((new Date()), 'yyyy-MM-dd')
  }

  function maxDate(): string {
    return format((new Date()), 'yyyy-MM-dd')
  }

  function uploadDropdown() {
    if (contactsForCompany) {
      setSearchContact({text: '', list: contactsForCompany})
    } else {
      setSearchContact({text: '', list: []})
    }
  }

  useEffect( () => {
    setCustomer({value: '', object: null, valid: false})
    setCurrency({value: '', object: null, valid: false})
    setTreasuryAccount({value: '', valid: false})
    setLanguage({value: '', valid: false})

    if (companyForUser) {
      setCurrency({value: companyForUser.currency.code, object: companyForUser.currency, valid: true})

      if (sale === 'invoice' && qrType && setQrTtype) {
        setQrTtype({value: companyForUser.qr_type, valid: true})
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyForUser])

  useEffect( () => {
    setTreasuryAccount({value: '', valid: false})

    if (treasuryAccountsForCompany) {
      if (treasuryAccountsForCompany.length > 0 && treasuryAccountsForCompany.find((item) => (item.default === true))) {
        setTreasuryAccount({value: treasuryAccountsForCompany.find((item) => (item.default === true))!.id, valid: true})
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treasuryAccountsForCompany])

  useEffect( () => {
    setDate({value: format((new Date()), 'yyyy-MM-dd'), valid: true})
    setValidUntil({value: format((new Date()), 'yyyy-MM-dd'), valid: true})
    setDiscountType({value: 'none', valid: true})
    setDiscountValue({value: '', valid: true})
    uploadTooltip()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      { companyForUser && settingsForCompany && contactsForCompany && treasuryAccountsForCompany &&
        <div className="card card-flush mb-5">
          <div className="card-header">
            <div className="card-title">
              <h2>{labels[sale].title}</h2>
            </div>
          </div>
          <div className="card-body pt-0">
            <div className="fw-bold fs-6 mb-5">{labels[sale].desc}</div>
            <div className="row mb-0 mb-lg-2">
              <label className="col-lg-3 col-form-label required">{lang.labels.customer}</label>
              <div className="col-lg-9">
                <div className="d-flex align-self-center">
                  <div className="flex-grow-1 me-2">
                    <Dropdown onToggle={uploadDropdown}>
                      <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                        <span className="selection">
                          <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                            <span className="select2-selection__rendered" role="textbox">
                              <span className="select2-selection__placeholder">
                                {customer.valid ? contactSelected(lang, contactsForCompany, customer.value) : lang.labels.selectOption}
                              </span>
                            </span>
                          </span>
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                        <span className="select2-dropdown select2-dropdown--below">
                          <span className="select2-search select2-search--dropdown">
                            <input className="select2-search__field" type="text" name="contact" value={searchContact.text} onChange={handleChangeSearchContact} />
                          </span>
                          <span className="select2-results">
                            <ul className="select2-results__options" role="listbox">
                              { searchContact.list.length > 0
                                ?
                                <>
                                  { searchContact.list.map (( (item, index) => { return (
                                    <li key={index} className={`select2-results__option select2-results__option--selectable ${customer.value === item.data.id && "select2-results__option--selected"}`} role="option" aria-selected={customer.value === item.data.id}>
                                      <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeCustomer(item)}>{item.type === "person" ? `${item.data.first_name} ${item.data.last_name}` : `${item.data.name}`}</Dropdown.Item>
                                    </li>
                                  )}))}
                                </>
                                :
                                <li className="select2-results__option select2-results__message" role="alert" aria-live="assertive">{lang.labels.noResultsFound}</li>
                              }
                            </ul>
                          </span>
                        </span>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="flex-shrink-0">
                    <button className="btn btn-sm btn-light-primary h-100" type="button" onClick={executeShowModalContactCreate}>
                      <div className="d-flex align-items-center">
                        <span className="svg-icon svg-icon-3 ms-0">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                            <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                            <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                          </svg>
                        </span>
                        {lang.labels.addContact}
                      </div>
                    </button>
                  </div>
                </div>
                <div className="form-text text-justify">{lang.labels.searchContact}</div>
                <AppLegend attribute={{validity: customer.valid, name: "customer", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-0 mb-lg-2">
              <label className="col-lg-3 col-form-label required">{lang.labels.concerning}</label>
              <div className="col-lg-9">
                <TextareaAutosize className="form-control form-control-solid" name="concerning" minRows={2} autoComplete="off" value={concerning.value} onChange={handleChangeConcerning} onKeyUp={validateConcerning} onBlur={validateConcerning} />
                <AppLegend attribute={{validity: concerning.valid, name: "concerning", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-0 mb-lg-2">
              <label className="col-lg-3 col-form-label">
                <div className="d-flex align-items-center">
                  <span className="required">{labels[sale].date}</span>
                  <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.dateOfQuote}><FaExclamationCircle /></i>
                </div>
              </label>
              <div className="col-lg-9">
                <input className="form-control form-control-solid" type="date" name="date" max={maxDate()} value={date.value} onChange={handleChangeDate} />
                <AppLegend attribute={{validity: date.valid, name: "date", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-0 mb-lg-2">
              <label className="col-lg-3 col-form-label">
                <div className="d-flex align-items-center">
                  <span className="required">{lang.labels.validUntil}</span>
                  <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.enterADateOrSelectADefaultPaymentDeadline}><FaExclamationCircle /></i>
                </div>
              </label>
              <div className="col-lg-9">
                <div className="d-flex align-self-center">
                  <div className="flex-equal">
                    <input className="form-control form-control-solid" type="date" name="valid-until" min={minDate()} value={validUntil.value} onChange={handleChangeValidUntil} />
                  </div>
                  <div className="form-text text-center align-middle text-uppercase m-0 py-3 px-2">{lang.labels.or}</div>
                  <div className="flex-equal">
                    <Dropdown>
                      <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                        <span className="selection">
                          <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                            <span className="select2-selection__rendered" role="textbox">
                              <span className="select2-selection__placeholder">
                                {paymentDeadline.valid ? paymentDeadlineSelected(lang, settingsForCompany.payment_deadlines, paymentDeadline.value) : lang.labels.selectOption}
                              </span>
                            </span>
                          </span>
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                        <span className="select2-dropdown select2-dropdown--below">
                          <span className="select2-results">
                            <ul className="select2-results__options" role="listbox">
                              <li className="select2-results__option select2-results__option--selectable">
                                <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => executeShowModalSettingsCreateDeadline()}>
                                  <div className="d-flex align-items-center">
                                    <span className="svg-icon svg-icon-3 me-2">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                                        <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                                        <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                                      </svg>
                                    </span>
                                    {lang.labels.addDeadline}
                                  </div>
                                </Dropdown.Item>
                              </li>
                              <li className={`select2-results__option select2-results__option--selectable ${settingsForCompany.payment_deadlines.length > 0 && "d-none"}`}>
                                <Dropdown.Item bsPrefix="select2-results__option__text">{lang.labels.selectOption}</Dropdown.Item>
                              </li>
                              { settingsForCompany.payment_deadlines.map (( (item, index) => { return (
                                <li key={index} className={`select2-results__option select2-results__option--selectable ${item.id === paymentDeadline.value && "select2-results__option--selected"}`} role="option" aria-selected={item.id === paymentDeadline.value}>
                                  <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangePaymentDeadline(item)}>{item.quantity} {item.type}{item.quantity > 1 && 's'}</Dropdown.Item>
                                </li>
                              )}))}
                            </ul>
                          </span>
                        </span>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <AppLegend attribute={{validity: validUntil.valid, name: "valid-until", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-0 mb-lg-2">
              <label className="col-lg-3 col-form-label">
                <div className="d-flex align-items-center">
                  <span className="required">{lang.labels.currency}</span>
                  <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.theDefaultCurrencyIsTheCompanys}><FaExclamationCircle /></i>
                </div>
              </label>
              <div className="col-lg-9">
                <Dropdown>
                  <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                    <span className="selection">
                      <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                        <span className="select2-selection__rendered" role="textbox">
                          <span className="select2-selection__placeholder">
                            {currency.valid ? currencyValue(lang.code, currency.value) : lang.labels.selectOption}
                          </span>
                        </span>
                      </span>
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                    <span className="select2-dropdown select2-dropdown--below">
                      <span className="select2-results">
                        <ul className="select2-results__options" role="listbox">
                          { currencyList(lang.code).map (( (item, index) => { return (
                            <li key={index} className={`select2-results__option select2-results__option--selectable ${item.code === currency.value && "select2-results__option--selected"}`} role="option" aria-selected={item.code === currency.value}>
                              <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeCurrency(item)}>{item.code}: {item.name} ({item.symbol})</Dropdown.Item>
                            </li>
                          )}))}
                        </ul>
                      </span>
                    </span>
                  </Dropdown.Menu>
                </Dropdown>
                <AppLegend attribute={{validity: currency.valid, name: "currency", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-0 mb-lg-2">
              <label className="col-lg-3 col-form-label">
                <div className="d-flex align-items-center">
                  <span className="required">{lang.labels.treasuryAccount}</span>
                  <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.theDefaultBankAccountIsTheCompanys}><FaExclamationCircle /></i>
                </div>
              </label>
              <div className="col-lg-9">
                <Dropdown>
                  <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                    <span className="selection">
                      <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                        <span className="select2-selection__rendered" role="textbox">
                          <span className="select2-selection__placeholder">
                            {treasuryAccount.valid ? treasuryAccountSelected(lang, treasuryAccountsForCompany, treasuryAccount.value) : lang.labels.selectOption}
                          </span>
                        </span>
                      </span>
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                    <span className="select2-dropdown select2-dropdown--below">
                      <span className="select2-results">
                        <ul className="select2-results__options" role="listbox">
                          <li className={`select2-results__option select2-results__option--selectable ${treasuryAccountsForCompany.length > 0 && "d-none"}`}>
                            <Dropdown.Item bsPrefix="select2-results__option__text">{lang.labels.selectOption}</Dropdown.Item>
                          </li>
                          { treasuryAccountsForCompany.map (( (item, index) => { return (
                            <li key={index} className={`select2-results__option select2-results__option--selectable ${item.id === treasuryAccount.value && "select2-results__option--selected"}`} role="option" aria-selected={item.id === treasuryAccount.value}>
                              <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeTreasuryAccount(item.id)}>{item.name}</Dropdown.Item>
                            </li>
                          )}))}
                        </ul>
                      </span>
                    </span>
                  </Dropdown.Menu>
                </Dropdown>
                <AppLegend attribute={{validity: treasuryAccount.valid, name: "treasury-account", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className={`row mb-0 ${sale === "invoice" && qrType && setQrTtype && "mb-lg-2"}`}>
              <label className="col-lg-3 col-form-label">
                <div className="d-flex align-items-center">
                  <span className="required">{lang.labels.language}</span>
                  <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.theDefaultLanguageToDownloadIsTheClients}><FaExclamationCircle /></i>
                </div>
              </label>
              <div className="col-lg-9">
                <Dropdown>
                  <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                    <span className="selection">
                      <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                        <span className="select2-selection__rendered" role="textbox">
                          <span className="select2-selection__placeholder">
                            {language.valid ? languageValue(lang, language.value) : lang.labels.selectOption}
                          </span>
                        </span>
                      </span>
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                    <span className="select2-dropdown select2-dropdown--below">
                      <span className="select2-results">
                        <ul className="select2-results__options" role="listbox">
                          { languageList(lang).map (( (item, index) => { return (
                            <li key={index} className={`select2-results__option select2-results__option--selectable ${item.code === language.value && "select2-results__option--selected"}`} role="option" aria-selected={item.code === language.value}>
                              <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeLanguage(item.code)}>{item.display}</Dropdown.Item>
                            </li>
                          )}))}
                        </ul>
                      </span>
                    </span>
                  </Dropdown.Menu>
                </Dropdown>
                <AppLegend attribute={{validity: language.valid, name: "language", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            { sale === "invoice" && qrType && setQrTtype &&
              <div className="row mb-0">
                <label className="col-lg-3 col-form-label">
                  <div className="d-flex align-items-center">
                    <span className="required">{lang.labels.qrType}</span>
                    <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.theDefaultShapeOrVersionOfAQrInvoiceToDownloadIsTheClients}><FaExclamationCircle /></i>
                  </div>
                </label>
                <div className="col-lg-9">
                  <Dropdown>
                    <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                      <span className="selection">
                        <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                          <span className="select2-selection__rendered" role="textbox">
                            <span className="select2-selection__placeholder">
                              {qrType.valid ? qrTemplateValue(lang, qrType.value) : lang.labels.selectOption}
                            </span>
                          </span>
                        </span>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                      <span className="select2-dropdown select2-dropdown--below">
                        <span className="select2-results">
                          <ul className="select2-results__options" role="listbox">
                            { qrTemplateList(lang).map (( (item, index) => { return (
                              <li key={index} className={`select2-results__option select2-results__option--selectable ${item.code === qrType.value && "select2-results__option--selected"}`} role="option" aria-selected={item.code === qrType.value}>
                                <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeQrType(item.code)}>{item.display}</Dropdown.Item>
                              </li>
                            )}))}
                          </ul>
                        </span>
                      </span>
                    </Dropdown.Menu>
                  </Dropdown>
                  <AppLegend attribute={{validity: qrType.valid, name: "qr-type", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                </div>
              </div>
            }
            <div className="row d-none">
              <label className="col-lg-3 col-form-label">
                <div className="d-flex align-items-center">
                  <span className="required">{lang.labels.discount}</span>
                  <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.selectOptionAddAmountPercentageDesired}><FaExclamationCircle /></i>
                </div>
              </label>
              <div className="col-lg-9">
                <div className="d-flex align-self-center">
                  <div className="flex-shrink-0 me-3">
                    <Dropdown>
                      <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-200px p-0">
                        <span className="selection">
                          <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                            <span className="select2-selection__rendered" role="textbox">
                              <span className="select2-selection__placeholder">
                                { discountType.valid
                                  ?
                                  <>
                                    {discountType.value === "none" && lang.labels.none}
                                    {discountType.value === "percentage" && lang.labels.percentage}
                                    {discountType.value === "fixed" && lang.labels.fixedAmount}
                                  </>
                                  :
                                  <>
                                    {lang.labels.selectOption}
                                  </>
                                }
                              </span>
                            </span>
                          </span>
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                        <span className="select2-dropdown select2-dropdown--below">
                          <span className="select2-results">
                            <ul className="select2-results__options" role="listbox">
                              <li className={`select2-results__option select2-results__option--selectable ${discountType.value === "none" && "select2-results__option--selected"}`} role="option" aria-selected={discountType.value === "none"}>
                                <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeDiscountType("none")}>{lang.labels.none}</Dropdown.Item>
                              </li>
                              <li className={`select2-results__option select2-results__option--selectable ${discountType.value === "percentage" && "select2-results__option--selected"}`} role="option" aria-selected={discountType.value === "percentage"}>
                                <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeDiscountType("percentage")}>{lang.labels.percentage}</Dropdown.Item>
                              </li>
                              <li className={`select2-results__option select2-results__option--selectable ${discountType.value === "fixed" && "select2-results__option--selected"}`} role="option" aria-selected={discountType.value === "fixed"}>
                                <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeDiscountType("fixed")}>{lang.labels.fixedAmount}</Dropdown.Item>
                              </li>
                            </ul>
                          </span>
                        </span>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="flex-grow-1">
                    <input id="input-discount" className="form-control form-control-solid" type="text" name="discount" autoComplete="off" value={discountValue.value} disabled={!(discountType.value === 'percentage' || discountType.value === 'fixed')} onChange={handleChangeDiscountValue} onKeyUp={validateDiscount} onBlur={validateDiscount} />
                  </div>
                </div>
                <AppLegend attribute={{validity: discountValue.valid, name: "discount", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
          </div>
        </div>
      }
      <AppModalContactCreate userLoggedIn={userLoggedIn} companyForUser={companyForUser} contactsForCompany={contactsForCompany} languageFile={language} qrTtypeFile={null} setContact={setCustomer} setLanguageFile={setLanguage} setQrTtypeFile={null} loadContactsForCompany={loadContactsForCompany}></AppModalContactCreate>
      <AppModalSettingsCreateDeadline companyForUser={companyForUser} setValidUntil={setValidUntil} setPaymentDeadline={setPaymentDeadline} loadSettingsForCompany={loadSettingsForCompany}></AppModalSettingsCreateDeadline>
    </>
  )
};

export default AppSaleRecordCreate;
