import { Language } from "../languages/labels/language.labels";

export function statusQuoteList(lang: Language): {code: string, display: string}[] {
  return [
    { code: 'in progress', display: lang.labels.inProgress },
    { code: 'pending', display: lang.labels.pending },
    { code: 'approved', display: lang.labels.approved },
    { code: 'rejected', display: lang.labels.rejected },
    { code: 'completed', display: lang.labels.completed }
  ]
}

export function statusOrderList(lang: Language): {code: string, display: string}[] {
  return [
    { code: 'in progress', display: lang.labels.inProgress },
    { code: 'pending', display: lang.labels.pending },
    { code: 'approved', display: lang.labels.approved },
    { code: 'canceled', display: lang.labels.canceled },
    { code: 'completed', display: lang.labels.completed }
  ]
}

export function statusInvoiceList(lang: Language): {code: string, display: string}[] {
  return [
    { code: 'in progress', display: lang.labels.inProgress },
    { code: 'reminder', display: lang.labels.reminder },
    { code: 'in dispute', display: lang.labels.inDispute },
    { code: 'completed', display: lang.labels.completed }
  ]
}

export function statusDocumentList(lang: Language): {code: string, display: string}[] {
  let statusRecord = [...statusQuoteList(lang), ...statusOrderList(lang), ...statusInvoiceList(lang)]
  return Array.from((new Map(statusRecord.map((item) => [item.code, item]))).values())
}

export function statusDocumentValue(lang: Language, code: string): string {
  let statusRecord = statusDocumentList(lang).find((item) => (item.code === code))
  return statusRecord ? statusRecord.display : lang.labels.undefined
}
