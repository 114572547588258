import React, { useState } from 'react';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { AppService } from '../../models/app.service.model';
import { Notification } from '../../models/notification.model';
import { modalShow, modalHide } from '../../tools/modal.tool';
import { imageCompany } from '../../scripts/image.value.script';
import { countryName } from '../../libraries/country.library';

import AppModalNotificationCompany from './ModalNotificationCompany';

import background from '../../assets/images/themes/image-search.png';

export interface AppModalNotificationWelcomeProps {
  appServiceForUser: AppService | undefined | null,
  notificationsForUser: Notification[] | undefined | null,
  reloadUserLoggedIn: Function
};

const AppModalNotificationWelcome: React.FunctionComponent<AppModalNotificationWelcomeProps> = ({appServiceForUser, notificationsForUser, reloadUserLoggedIn}) => {
  const {lang} = StateLanguage()

  const [notification, setNotification] = useState<Notification | undefined | null>(null)

  const executeSelectNotification = (item: Notification) => {
    setNotification(item)
    executeShowModalNotificationCompany()
  }

  const executeShowModalNotificationCompany = () => {
    modalHide('modal-notification-welcome')
    modalShow('modal-notification-company')
  }

  const executeHideModalNotificationMain = () => {
    modalHide('modal-notification-welcome')
  }

  function invitations(notifications: Notification[]): Notification[] {
    return notifications.filter((item) => (!item.view && item.type === 'activity' && item.description === 'company access'))
  }

  return (
    <>
      <div id="modal-notification-welcome" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div className="modal-dialog mw-600px">
          <div className="modal-content">
            <div className="modal-header pb-0 border-0 justify-content-end">
              <button className="btn btn-sm btn-icon btn-active-color-primary" onClick={executeHideModalNotificationMain}>
                <span className="svg-icon svg-icon-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black" />
                    <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black" />
                  </svg>
                </span>
              </button>
            </div>
            <div className="modal-body pt-0">
              <div className="text-center mb-5">
                <h2 className="text-capitalize">{lang.labels.companyInvitations}</h2>
                <div className="text-muted text-justify fw-semibold">
                  {lang.labels.notificationsAboutInvitationsToSubscribe}
                </div>
              </div>
              <div className="scroll-y px-3 py-2 mh-300px border-top border-bottom">
                { notificationsForUser
                  ?
                  <>
                    { invitations(notificationsForUser).length > 0
                      ?
                      <>
                        { invitations(notificationsForUser).map (( (item, index) => { return (
                          <div key={index} className={`d-flex flex-stack py-3 cursor-pointer ${(index < invitations(notificationsForUser).length - 1) && "border-bottom border-gray-300 border-bottom-dashed"}`} onClick={() => executeSelectNotification(item)}>
                            <div className="d-flex align-items-center">
                              <div className="symbol symbol-45px">
                                <img src={imageCompany(item.detail.company.logo)} alt={lang.labels.logo} />
                              </div>
                              <div className="ms-5">
                                <div className="fs-6 fw-bold text-dark">{item.detail.company.name}</div>
                                <div className="fw-semibold text-muted">{item.detail.company.address.city}, {countryName(lang.code, item.detail.company.address.country)}</div>
                              </div>
                            </div>
                            <div className="ms-10 w-150px text-end">
                              <span className="badge badge-lg badge-light py-3 text-wrap">{item.user.first_name} {item.user.last_name}</span>
                            </div>
                          </div>
                        )}))}
                      </>
                      :
                      <div className="d-flex flex-column">
                        <div className="mb-3">
                          <div className="text-center text-dark fw-bolder fs-5 mb-1">{lang.labels.noNotifications}</div>
                          <div className="text-center text-gray-600 fw-bold">{lang.labels.thereAreNoNotifications}</div>
                        </div>
                        <div className="text-center">
                          <img src={background} alt={lang.labels.background} className="mw-100 mh-125px" />
                        </div>
                      </div>
                    }
                  </>
                  :
                  <div className="d-flex justify-content-center align-items-center w-100 h-200px">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">{lang.labels.loading}</span>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <AppModalNotificationCompany appServiceForUser={appServiceForUser} notification={notification} reloadUserLoggedIn={reloadUserLoggedIn}></AppModalNotificationCompany>
    </>
  )
}

export default AppModalNotificationWelcome;
