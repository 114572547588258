import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { CompanyServices } from '../models/company.services.model';
import { Service } from '../models/service.model';
import { Message } from '../models/message.model';
import { Names } from '../models/names.model';
import { Company } from '../models/company.model';
import { appToken } from '../scripts/app.token.script';
import { InventoryAccounts } from '../models/inventory.accounts.model';

export class ServiceDA {
  static apiResponse: ApiResponse

  public static async getServices(id_company: string, service_name: string, category_id: string, sort_field: string, sort_mode: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let servicesByCompany: CompanyServices | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/services.data.json').then(response => { servicesByCompany = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (servicesByCompany!.id_company === id_company) {
        let listServices: Service[] = []

        if (service_name.length === 0 && category_id.length === 0 && sort_mode.length === 0 && sort_field.length === 0) {
          listServices = servicesByCompany!.services
        }
        if (service_name.length === 0 && category_id.length !== 0) {
          listServices = servicesByCompany!.services.filter(item => item.category.id === category_id)
        }
        if (service_name.length !== 0) {
          for (let service of servicesByCompany!.services) {
            let name: string = service.display_name.toLowerCase()
            if (name.indexOf(service_name.toLocaleLowerCase()) !== -1) {
              listServices.push(service)
            }
          }
        }

        servicesByCompany!.filter = { name: service_name , category: category_id, sort_mode: sort_mode, sort_field: sort_field }
        servicesByCompany!.services = listServices

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: servicesByCompany
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/services?name=${service_name}&category=${category_id}&sort_field=${sort_field}&sort_mode=${sort_mode}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.get(url, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async getService(id_company: string, id_service: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let service: Service | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/service.data.json').then(response => { service = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (service!.id === id_service) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: service
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/services/${id_service}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.get(url, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async postService(id_company: string, display_name: string, description: string, code: string, sku: string, codebar: string, price: string, tax_rate: string, unit_id: string, category_id: string, discount: { type: string, value: string, quantity: string }, names: Names,  accounts: InventoryAccounts, tags: string[], photos: any[]): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let company: Company | null = null
      let message: Message | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/company.data.json').then(response => { company = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/message.data.json').then(response => { message = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (company!.id === id_company) {
        this.apiResponse = {
          status: 201,
          message: 'Success',
          data: message
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/services`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      let data: any = new FormData()
      data.append('display_name', display_name)
      data.append('description', description)
      data.append('code', code)
      data.append('sku', sku)
      data.append('codebar', codebar)
      data.append('price', price)
      data.append('tax_rate', tax_rate)
      data.append('unit', unit_id)
      data.append('id_category', category_id)
      data.append('discount[type]', discount.type)
      data.append('discount[value]', discount.value)
      data.append('discount[quantity]', discount.quantity)
      data.append('names[en]', names.en)
      data.append('names[fr]', names.fr)
      data.append('names[de]', names.de)
      data.append('names[it]', names.it)
      data.append('accounts[expense]', accounts.expense)
      data.append('accounts[vat]', accounts.vat)
      data.append('accounts[product]', accounts.product)

      tags.forEach(tag => {
        data.append('tags', JSON.stringify(tag))
      })

      photos.forEach((image, index) => {
        if (image instanceof File) {
          data.append(`photos`, image, `${display_name}`)
        } else {
          data.append(`string_url[${display_name}][${index}]`, image)
        }
      })

      await axios.post(url, data, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async putService(id_company: string, id_service: string, display_name: string, description: string, code: string, sku: string, codebar: string, price: string, tax_rate: string, unit_id: string, category_id: string, discount: { type: string, value: string, quantity: string }, names: Names, accounts: InventoryAccounts, tags: string[], photos: any[]): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let service: Service | null = null
      let message: Message | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/service.data.json').then(response => { service = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/message.data.json').then(response => { message = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (service!.id === id_service) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: message
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/services/${id_service}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      let data: any = new FormData()
      data.append('display_name', display_name)
      data.append('description', description)
      data.append('code', code)
      data.append('sku', sku)
      data.append('codebar', codebar)
      data.append('price', price)
      data.append('tax_rate', tax_rate)
      data.append('unit', unit_id)
      data.append('id_category', category_id)
      data.append('discount[type]', discount.type)
      data.append('discount[value]', discount.value)
      data.append('discount[quantity]', discount.quantity)
      data.append('names[en]', names.en)
      data.append('names[fr]', names.fr)
      data.append('names[de]', names.de)
      data.append('names[it]', names.it)
      data.append('accounts[expense]', accounts.expense)
      data.append('accounts[vat]', accounts.vat)
      data.append('accounts[product]', accounts.product)

      tags.forEach(tag => {
        data.append('tags', JSON.stringify(tag))
      })

      photos.forEach((image, index) => {
        if (image instanceof File) {
          data.append(`photos`, image, `${display_name}`)
        } else {
          data.append(`string_url[${display_name}][${index}]`, image)
        }
      })

      await axios.put(url, data, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }
    return this.apiResponse
  }

  public static async deleteServices(id_company: string, id_services: string[]): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let services: Service[] | null = null
      let message: Message | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/services.data.json').then(response => { services = response.data.services }).catch(error => { alert(error) })
      await axios.get('../../../../../database/message.data.json').then(response => { message = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (services!.some(item => id_services.includes(item.id))) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: message
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/services`

      let config: any = {
        headers: { Authorization: appToken() },
        data: { ids_services: id_services }
      }

      await axios.delete(url, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }
}
