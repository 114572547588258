import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { Message } from '../models/message.model';
import { User } from '../models/user.model';
import { Quote } from '../models/quote.model';
import { Order } from '../models/order.model';
import { Notification } from '../models/notification.model';
import { UserNotifications } from '../models/user.notification.model';
import { AppService } from '../models/app.service.model';
import { appToken } from '../scripts/app.token.script';

export class NotificationDL {
  static apiResponse: ApiResponse

  public static async getNotifications(api_key: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let appServices: AppService[] | null = null
      let notificationsByUser: UserNotifications | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/system.apps.data.json').then(response => { appServices = response.data.system_apps }).catch(error => { alert(error) })
      await axios.get('../../../../../database/notifications.data.json').then(response => { notificationsByUser = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (appServices!.some(item => item.api_key === api_key)) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: notificationsByUser
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_LOGIN}/${api_key}/notifications`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.get(url, config).then( response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch( error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async postNotification(api_key: string, type: string, description: string, uuid_user: string, comment: string, response: string, quote: Quote | null, order: Order | null): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let users: User[] | null = null
      let message: Message | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/users.data.json').then(response => { users = response.data.users }).catch(error => { alert(error) })
      await axios.get('../../../../../database/message.data.json').then(response => { message = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (users!.some(item => item.uuid === uuid_user)) {
        this.apiResponse = {
          status: 201,
          message: 'Success',
          data: message
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_LOGIN}/${api_key}/notifications`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      let data: any = {
        type: type,
        description: description,
        user: uuid_user,
        comment: comment,
        response: response,
        quote: quote,
        order: order
      }

      await axios.post(url, data, config).then( response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch( error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async postNotificationMe(api_key: string, email: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let users: User[] | null = null
      let message: Message | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/users.data.json').then(response => { users = response.data.users }).catch(error => { alert(error) })
      await axios.get('../../../../../database/message.data.json').then(response => { message = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (users!.some(item => item.email === email)) {
        this.apiResponse = {
          status: 201,
          message: 'Success',
          data: message
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_LOGIN}/${api_key}/notifications/me`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      let data: any = {
        email: email
      }

      await axios.post(url, data, config).then( response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch( error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async putNotification(api_key: string, id_notification: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let notifications: Notification[] | null = null
      let message: Message | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/notifications.data.json').then(response => { notifications = response.data.notifications }).catch(error => { alert(error) })
      await axios.get('../../../../../database/message.data.json').then(response => { message = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (notifications!.some(item => item.id === id_notification)) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: message
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_LOGIN}/${api_key}/notification/${id_notification}/view`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.put(url, null, config).then( response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch( error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }
}
