import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { InvoiceDA } from '../../services/invoice.service';
import { Company } from '../../models/company.model';
import { Error } from '../../models/error.model';
import { Invoice } from '../../models/invoice.model';
import { RequirementContent } from '../../models/requirement.content.model';
import { AccountingAccount } from '../../models/accounting.account.model';
import { Service } from '../../models/service.model';
import { Product } from '../../models/product.model';
import { moneyFormat } from '../../scripts/format.value.script';
import { modalHide } from '../../tools/modal.tool';
import { getError } from '../../languages/translations/response';
import { accountingAccountSelected } from '../../scripts/list.selected.item.script';

export interface AppModalInvoiceUpdateValidateProps {
  companyForUser: Company | undefined | null,
  accountingAccountsForCompany: AccountingAccount[] | undefined | null,
  servicesForCompany: Service[] | null | undefined,
  productsForCompany: Product[] | null | undefined,
  accountingAccounts: AccountingAccount[] | undefined | null,
  invoice: Invoice | undefined | null,
  setInvoice: Dispatch<SetStateAction<Invoice | undefined | null>>
};

let errorResponse: Error, invoiceResponse: Invoice;

const AppModalInvoiceUpdateValidate: React.FunctionComponent<AppModalInvoiceUpdateValidateProps> = ({companyForUser, accountingAccountsForCompany, servicesForCompany, productsForCompany, accountingAccounts, invoice, setInvoice}) => {
  const {lang} = StateLanguage()
  const navigate = useNavigate()

  const [loadIndicator, setLoadIndicator] = useState('off')
  const [amountTotal, setAmountTotal] = useState({value: 0, valid: false})
  const [contentAccounts, setContentAccounts] = useState({value: [] as {content: RequirementContent, accounting: string, rebate: string}[], valid: [] as boolean[]})

  const submitInvoiceValidate = async () => {
    setLoadIndicator('on')

    let contentAccountsIsValid: boolean = contentAccounts.valid.every((item) => (item === true))

    if (companyForUser && invoice && contentAccountsIsValid && amountTotal.valid) {

      let contentAccountsList = contentAccounts.value.map((item) => ({id_content: item.content.id, id_account: item.accounting, id_account_rebate: item.rebate}))
      let company = {subject_tax: companyForUser.subject_tax, vat_debt_method: companyForUser.vat_debt_method, tax_method: companyForUser.tax_method, tax_rate: companyForUser.tax_rate}

      await InvoiceDA.putInvoiceStatus(companyForUser.id, invoice.id, amountTotal.value, company, contentAccountsList).then( (response) => {
        if (response.status === 200) {
          invoiceResponse = response.data
          setInvoice(invoiceResponse)

          Swal.fire({
            title: lang.labels.successfullyUpdatedStatus,
            text: lang.labels.actionCompletedReturningToPage,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            executeHideModalInvoiceUpdateValidate()
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.code),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        navigate('/error')
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        setLoadIndicator('off')
      })
    }
  }

  const handleChangeAccoutingAccount = (index: number, item: AccountingAccount) => {
    let tempContentAccountsValue = contentAccounts.value.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item.accounting = item.id
        temp_item.rebate = accountingAccountRebate(item.id)
      }
      return temp_item
    })
    let tempContentAccountsValid = contentAccounts.valid.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item = true
      }
      return temp_item
    })

    setContentAccounts({value: tempContentAccountsValue, valid: tempContentAccountsValid})
  }

  const executeHideModalInvoiceUpdateValidate = () => {
    modalHide('modal-invoice-update-validate')
  }

  function accumulatedAmountDocuments(content_accounts: {content: RequirementContent, accounting: string, rebate: string}[]): {gross: number, net: number, tax: number, rebate: number, debit: number, credit: number} {    
    let amountGross: number = 0
    let amountNet: number = 0
    let amountTax: number = 0
    let amountRebate: number = 0
    let amountDebit: number = 0
    let amountCredit: number = 0

    if (companyForUser) {
      if (companyForUser.subject_tax) {
        if (companyForUser.vat_debt_method === 'invoicing'){
          if (companyForUser.tax_method === 'effective') {
            for (let content_account of content_accounts) {
              amountGross += content_account.content.price_net - content_account.content.tax_amount + content_account.content.rebate_amount
              amountNet += content_account.content.price_net
              amountTax += content_account.content.tax_amount
              amountRebate += content_account.content.rebate_amount
            }
          } else {
            for (let content_account of content_accounts) {
              amountGross += (content_account.content.price_net - (content_account.content.price_net * Number(companyForUser.tax_rate) / 100) + content_account.content.rebate_amount)
              amountNet += content_account.content.price_net
              amountTax += (content_account.content.price_net * Number(companyForUser.tax_rate) / 100)
              amountRebate += content_account.content.rebate_amount
            }
          }
        }
        if (companyForUser.vat_debt_method === 'cashing'){
          if (companyForUser.tax_method === 'effective') {
            for (let content_account of content_accounts) {
              amountGross += (content_account.content.price_net + content_account.content.rebate_amount)
              amountNet += content_account.content.price_net
              amountRebate += content_account.content.rebate_amount
            }
          } else {
            for (let content_account of content_accounts) {
              amountGross += (content_account.content.price_net + content_account.content.rebate_amount)
              amountNet += content_account.content.price_net
              amountRebate += content_account.content.rebate_amount
            }
          }
        }
      } else {
        for (let content_account of content_accounts) {
          amountGross += content_account.content.price_net + content_account.content.rebate_amount
          amountNet += content_account.content.price_net
          amountRebate += content_account.content.rebate_amount
        }
      }

      amountDebit = Math.round((amountNet + amountRebate) * 100) / 100
      amountCredit = Math.round((amountGross + amountTax) * 100) / 100
    }

    return {gross: amountGross, net: amountNet, tax: amountTax, rebate: amountRebate, debit: amountDebit, credit: amountCredit}
  }

  function accountingAccountForCode(accounting_account_code: string): string {
    if (accountingAccountsForCompany) {
      return accountingAccountsForCompany.find((item) => (item.code === accounting_account_code))?.id ?? ''
    } else {
      return ''
    }
  }

  function accountingAccountRebate(accounting_account_code: string): string {
    let ranges: {start: number, end: number, rebate: string}[] = [
      { start: 3000, end: 3000.99, rebate: '3091' },
      { start: 3200, end: 3200.99, rebate: '3291' },
      { start: 3400, end: 3400.99, rebate: '3491' },
      { start: 3600, end: 3600.99, rebate: '3691' }
    ]

    if (accountingAccountsForCompany && accountingAccounts) {
      let accountCode: string = accountingAccounts.find((item) => (item.id === accounting_account_code))?.code ?? ''
      let accountNumber: number = parseFloat(accountCode)
      let accountRebate: string = (!isNaN(accountNumber)) ? (ranges.find((item) => (accountNumber >= item.start && accountNumber <= item.end))?.rebate ?? '') : ''
      return accountingAccountsForCompany.find((item) => (item.code === accountRebate))?.id ?? ''
    } else {
      return ''
    }
  }

  useEffect( () => {
    if (servicesForCompany && productsForCompany && accountingAccounts && invoice) {
      let tempContentAccountsValue = invoice.requirements.flatMap((item) => {
        return item.contents.map((sub_item) => {
          let accounting: string = ''
          if (item.type === "product") {
            if (sub_item.account.length > 0) {
              accounting = sub_item.account
            } else if (sub_item.reference.length > 0) {
              accounting = productsForCompany.find((other_item) => (other_item.variants.some((other_sub_item) => (other_sub_item.id === sub_item.reference))))?.accounts.product ?? ''
            } else {
              accounting = accountingAccounts.find((other_item) => (other_item.code === '3200'))?.id ?? ''
            }
          }
          if (item.type === "service") {
            if (sub_item.account.length > 0) {
              accounting = sub_item.account
            } else  if (sub_item.reference.length > 0) {
              accounting = servicesForCompany.find((other_item) => (other_item.id === sub_item.reference))?.accounts.product ?? ''
            } else {
              accounting = accountingAccounts.find((other_item) => (other_item.code === '3400'))?.id ?? ''
            }
          }
          return {content: sub_item, accounting: accounting, rebate: accountingAccountRebate(accounting)}
        })
      })
      let tempContentAccountsValid = tempContentAccountsValue.map((item) => { return item.accounting.length > 0 })

      setContentAccounts({value: tempContentAccountsValue, valid: tempContentAccountsValid})
      setAmountTotal({value: accumulatedAmountDocuments(tempContentAccountsValue).debit, valid: (accumulatedAmountDocuments(tempContentAccountsValue).debit === accumulatedAmountDocuments(tempContentAccountsValue).credit)})
    } else {
      setContentAccounts({value: [], valid: []})
      setAmountTotal({value: 0, valid: false})
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice, accountingAccounts])

  return (
    <div id="modal-invoice-update-validate" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="text-capitalize">{lang.labels.validateInvoice}</h2>
          </div>
          <div className="modal-body">
            { companyForUser && accountingAccountsForCompany && accountingAccounts && invoice
              ?
              <div className="form">
                <div className="text-justify text-gray-800 fw-bold mb-5">
                  {lang.labels.validatingInvoiceSystemWillCreateToFollowingAccountingEntries}
                </div>
                <div className="dataTables_wrapper">
                  <div className="table-responsive">
                    <table className="table align-middle table-row-dashed gy-1 mb-2">
                      <thead className="border-bottom">
                        <tr className="text-muted text-uppercase fw-bolder fs-7">
                          <th className="min-w-100px text-nowrap text-start">{lang.labels.description}</th>
                          <th className="w-200px text-nowrap text-end">{lang.labels.debit}</th>
                          <th className="w-300px text-nowrap text-end">{lang.labels.credit}</th>
                        </tr>
                      </thead>
                      <tbody className="text-gray-600 fw-bold">
                        { contentAccounts.value.map (( (item, index) => { return (
                          <tr key={index}>
                            <td className="text-gray-700 text-start">{item.content.detail}</td>
                            <td className="d-flex flex-column text-nowrap text-end">
                              { item.content.rebate_amount > 0 &&
                                <>
                                  <span className="form-select-sm">{accountingAccountSelected(lang, accountingAccountsForCompany, item.rebate, companyForUser.language)}</span>
                                  <strong className="fs-8">{invoice.currency.symbol} {moneyFormat(item.content.rebate_amount)}</strong>
                                </>
                              }
                            </td>
                            <td className="text-nowrap text-end">
                              <Dropdown>
                                <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-300px p-0">
                                  <span className="selection">
                                    <span className="select2-selection select2-selection--single form-select form-select-sm form-select-solid" aria-disabled="false">
                                      <span className="select2-selection__rendered" role="textbox">
                                        <span className="select2-selection__placeholder">
                                          {contentAccounts.valid[index] ? accountingAccountSelected(lang, accountingAccounts, item.accounting, companyForUser.language) : lang.labels.selectOption}
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                                  <span className="select2-dropdown select2-dropdown--below">
                                    <span className="select2-results">
                                      <ul className="select2-results__options" role="listbox">
                                        <li className={`select2-results__option select2-results__option--selectable ${accountingAccounts.length > 0 && "d-none"}`}>
                                          <Dropdown.Item bsPrefix="select2-results__option__text py-2">{lang.labels.selectOption}</Dropdown.Item>
                                        </li>
                                        { accountingAccounts.map (( (other_item, other_index) => { return (
                                          <li key={other_index} className={`select2-results__option select2-results__option--selectable ${other_item.id === item.accounting && "select2-results__option--selected"}`} role="option" aria-selected={other_item.id === item.accounting}>
                                            <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeAccoutingAccount(index, other_item)}>{other_item.code}, {other_item.name[companyForUser.language.toLowerCase() as keyof typeof other_item.name]}</Dropdown.Item>
                                          </li>
                                        )}))}
                                      </ul>
                                    </span>
                                  </span>
                                </Dropdown.Menu>
                              </Dropdown>
                              { companyForUser.subject_tax 
                                ?
                                <>
                                  { companyForUser.vat_debt_method === "invoicing"  &&
                                    <>
                                      { companyForUser.tax_method === "effective"
                                        ?
                                        <strong className="fs-8">{invoice.currency.symbol} {moneyFormat(item.content.price_net - item.content.tax_amount + item.content.rebate_amount)}</strong>
                                        :
                                        <strong className="fs-8">{invoice.currency.symbol} {moneyFormat((item.content.price_net - (item.content.price_net * Number(companyForUser.tax_rate) / 100) + item.content.rebate_amount))}</strong>
                                      }
                                    </>
                                  }
                                  { companyForUser.vat_debt_method === "cashing"  &&
                                    <>
                                      { companyForUser.tax_method === "effective"
                                        ?
                                        <strong className="fs-8">{invoice.currency.symbol} {moneyFormat(item.content.price_net + item.content.rebate_amount)}</strong>
                                        :
                                        <strong className="fs-8">{invoice.currency.symbol} {moneyFormat(item.content.price_net + item.content.rebate_amount)}</strong>
                                      }
                                    </>
                                  }
                                </>
                                :
                                <strong className="fs-8">{invoice.currency.symbol} {moneyFormat(item.content.price_net + item.content.rebate_amount)}</strong>
                              }
                            </td>
                          </tr>
                        )}))}
                      </tbody>
                      <tfoot className="border-top">
                        <tr className="text-gray-700 fw-bolder fs-7">
                          <td className="d-flex flex-column text-start text-nowrap">
                            <span className="fs-8">{lang.labels.customerReceivable}</span>
                            <span className="fw-bold">{invoice.customer.type === "person" ? `${invoice.customer.data.first_name} ${invoice.customer.data.last_name}` : `${invoice.customer.data.name}`}</span>
                          </td>
                          <td>
                            <div className="d-flex flex-column text-end text-nowrap">
                              <span className="fw-bold">{accountingAccountSelected(lang, accountingAccountsForCompany, accountingAccountForCode("1100"), companyForUser.language)}</span>
                              <span className="fs-8">{invoice.currency.symbol} {moneyFormat(accumulatedAmountDocuments(contentAccounts.value).net)}</span>
                            </div>
                          </td>
                          <td></td>
                        </tr>
                        { companyForUser.subject_tax &&
                          <>
                            { companyForUser.vat_debt_method === "invoicing" &&
                              <tr className="text-gray-700 fw-bolder fs-7">
                                <td className="d-flex flex-column text-start text-nowrap">
                                  <span className="fs-8">{lang.labels.vatDue}</span>
                                  <span className="fw-bold">{invoice.invoice_number}</span>
                                </td>
                                <td></td>
                                <td>
                                  <div className="d-flex flex-column text-end text-nowrap">
                                    <span className="fw-bold">{accountingAccountSelected(lang, accountingAccountsForCompany, accountingAccountForCode("2200"), companyForUser.language)}</span>
                                    <span className="fs-8">{invoice.currency.symbol} {moneyFormat(accumulatedAmountDocuments(contentAccounts.value).tax)}</span>
                                  </div>
                                </td>
                              </tr>
                            }
                          </>
                        }
                        <tr className="text-gray-800 fw-bolder fs-7">
                          <td className="text-start text-uppercase">{lang.labels.total}</td>
                          <td className="text-end text-nowrap">{invoice.currency.symbol} {moneyFormat(accumulatedAmountDocuments(contentAccounts.value).debit)}</td>
                          <td className="text-end text-nowrap">{invoice.currency.symbol} {moneyFormat(accumulatedAmountDocuments(contentAccounts.value).credit)}</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
              :
              <div className="d-flex justify-content-center align-items-center w-100 h-200px">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">{lang.labels.loading}</span>
                </div>
              </div>
            }
          </div>
          <div className="modal-footer flex-center">
            <button className="btn btn-light mx-2" type="reset" onClick={executeHideModalInvoiceUpdateValidate}>{lang.labels.noCancel}</button>
            <button className="btn btn-primary mx-2" type="button" data-kt-indicator={loadIndicator} onClick={submitInvoiceValidate}>
              <span className="indicator-label">{lang.labels.yesValidate}</span>
              <span className="indicator-progress">
                {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
};

export default AppModalInvoiceUpdateValidate;
