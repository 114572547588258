import React, { useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import FileSaver from 'file-saver';
import autoTable from 'jspdf-autotable';
import axios from 'axios';
import { Workbook  } from 'exceljs';
import { Dropdown } from 'react-bootstrap';
import { jsPDF } from 'jspdf';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { Company } from '../../models/company.model';
import { Contact } from '../../models/contact.model';
import { legendInvalid, legendValidInvalidRestart, legendValid } from '../../tools/legend.data.entry.tool';
import { modalHide } from '../../tools/modal.tool';

import AppLegend from '../element/Legend';

import logotipo from '../../assets/images/svg/logo-official-dark.png';
import { contactSelected } from '../../scripts/list.selected.item.script';

export interface AppModalContactEntryExportProps {
  companyForUser: Company | undefined | null,
  contactsForCompany: Contact[] | undefined | null,
  table: {body: any[], foot: any[]},
  contact: string | undefined | null
};

const AppModalContactEntryExport: React.FunctionComponent<AppModalContactEntryExportProps> = ({companyForUser, contactsForCompany, table, contact}) => {
  const {lang} = StateLanguage()

  const [loadIndicator, setLoadIndicator] = useState('off')
  const [formatToExport, setFormatToExport] = useState({value: '', valid: false})

  const fileExcel = async (company: Company, contacts: Contact[], tableTitle: string[][], tableData: any[][], id: string) => {
    let workbook = new Workbook()
    let worksheet = workbook.addWorksheet('Contacts Data')

    worksheet.addRow([])
    worksheet.mergeCells(`A1:E1`)
    worksheet.getRow(1).height = 35

    let imageBuffer = await axios.get(logotipo, {responseType: 'arraybuffer'})

    let imageLogotipo = workbook.addImage({
      buffer: imageBuffer.data,
      extension: 'png'
    })

    worksheet.addImage(imageLogotipo, { tl: {col: 0.1, row: 0.2}, ext: {width: 100, height: 30}})

    let titleRow = worksheet.addRow([`${company.name} - ${lang.labels.accountStatement}`])
    titleRow.font = {size: 17, bold: true}
    titleRow.getCell(1).alignment = {vertical: 'distributed', horizontal: 'center'}

    worksheet.mergeCells(`A${titleRow.number}:E${titleRow.number}`)
    worksheet.getRow(2).height = 30

    let subtitleRow = worksheet.addRow([contactSelected(lang, contacts, id)])
    subtitleRow.font = {size: 15, bold: true}
    subtitleRow.getCell(1).alignment = {vertical: 'distributed', horizontal: 'center'}

    worksheet.mergeCells(`A${subtitleRow.number}:E${subtitleRow.number}`)
    worksheet.getRow(3).height = 20

    worksheet.addRow([])
    worksheet.mergeCells(`A4:E4`)
    worksheet.getRow(4).height = 10

    let headerRow = worksheet.addRow(tableTitle[0])
    headerRow.font = {bold: true, color: {argb: 'FFFFFF'}}
    headerRow.eachCell( (cell) => {
      cell.alignment = {vertical: 'distributed', horizontal: 'center'}
      cell.fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: '2980BA'}, bgColor: {argb: '2980BA'}}
    })

    worksheet.getRow(5).height = 20

    tableData.forEach((item) => {
      let row = worksheet.addRow(item)
      row.getCell(3).alignment = { horizontal: 'right' }
      row.getCell(4).alignment = { horizontal: 'right' }
      row.getCell(5).alignment = { horizontal: 'right' }
    })

    worksheet.getColumn(1).width = 20;
    worksheet.getColumn(2).width = 50;
    worksheet.getColumn(3).width = 25;
    worksheet.getColumn(4).width = 25;
    worksheet.getColumn(5).width = 25;

    let lastRowIndex = worksheet.rowCount
    let footer = [worksheet.getRow(lastRowIndex - 1), worksheet.getRow(lastRowIndex)]

    footer.forEach((row) => {
      row.font = { size: 10, bold: true }
    })

    workbook.xlsx.writeBuffer().then( data => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      FileSaver.saveAs(blob, `Statements_of_${contactSelected(lang, contacts, id).replaceAll(' ', '_')}_for_${company.name.replaceAll(' ', '_')}.xlsx`);
    })
  }

  const filePdf = async (company: Company, contacts: Contact[], tableTitle: string[][], tableData: any[][], id: string) => {
    let document = new jsPDF('landscape', 'mm', [340, 220])

    document.addImage(logotipo, 'PNG', 290, 5, 30, 9)

    document.setFontSize(17)
    document.setFont('helvetica', 'bold')
    document.text(`${company.name} - ${lang.labels.accountStatement}`, 25, 20)

    document.setFontSize(15)
    document.setFont('helvetica', 'bold')
    document.text(contactSelected(lang, contacts, id), 25, 28)

    autoTable(document, {
      head: tableTitle,
      body: tableData,
      startY: 33,
      headStyles: { halign: 'center' },
      bodyStyles: { fontSize: 10 },
      columnStyles: {
        [tableTitle[0].length - 3]: { halign: 'right' },
        [tableTitle[0].length - 2]: { halign: 'right' },
        [tableTitle[0].length - 1]: { halign: 'right' }
      },
      didParseCell: (data) => {
        let lastRowIndex = tableData.length - 1
        let secondLastRowIndex = tableData.length - 2
    
        if (data.row.index === lastRowIndex || data.row.index === secondLastRowIndex) {
          data.cell.styles.fontStyle = 'bold'
          data.cell.styles.fontSize = 9
        }
      }
    })

    document.save(`Statements_of_${contactSelected(lang, contacts, id).replaceAll(' ', '_')}_for_${company.name.replaceAll(' ', '_')}.pdf`)
  }

  const handleChangeFormat = (item: string) => {
    setFormatToExport({value: item, valid: true})
    legendValid('modal-contact-entry-export-legend-format-required')
  }

  const executeContactExport = () => {
    setLoadIndicator('on')

    if (companyForUser && contactsForCompany && table && contact && formatToExport.valid) {
      let tableTitle: string[][] = [[lang.labels.date.toUpperCase(), lang.labels.description.toUpperCase(), `${lang.labels.debit.toUpperCase()} (${companyForUser.currency.symbol})`, `${lang.labels.credit.toUpperCase()} (${companyForUser.currency.symbol})`, `${lang.labels.balance.toUpperCase()} (${companyForUser.currency.symbol})`]]
      let tableData: any[][]  = [[]]

      tableData.pop()

      for (let detail of table.body) {
        tableData.push([detail.date, detail.description, detail.debit, detail.credit, detail.balance])
      }

      for (let detail of table.foot) {
        tableData.push([detail.date, detail.description, detail.debit, detail.credit, detail.balance])
      }

      setTimeout( () => {
        switch (formatToExport.value) {
          case 'excel':
            fileExcel(companyForUser, contactsForCompany, tableTitle, tableData, contact)
            break;
          case  'pdf':
            filePdf(companyForUser, contactsForCompany, tableTitle, tableData, contact)
            break;
        }

        setLoadIndicator('off')
        executeHideModalContactExport()
      }, 1000)
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        setLoadIndicator('off')
        legendInvalid('modal-contact-entry-export-legend-format-required')
      })
    }
  }

  const executeHideModalContactExport = () => {
    modalHide('modal-contact-entry-export')

    setTimeout( () => {
      restartModal()
    }, 200 )
  }

  function restartModal() {
    legendValidInvalidRestart('modal-contact-entry-export-legend-format-required')
    setFormatToExport({value: '', valid: false})
  }

  return (
    <div id="modal-contact-entry-export" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog mw-500px">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="text-capitalize">{lang.labels.exportEntryContacts}</h2>
          </div>
          <div className="modal-body">
            <div className="form">
              <div className="fv-row">
                <label className="form-label required">{lang.labels.selectExportFormat}:</label>
                <Dropdown>
                  <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                    <span className="selection">
                      <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                        { formatToExport.valid
                          ?
                          <>
                            {formatToExport.value === "excel" && "Excel (.xlsx)"}
                            {formatToExport.value === "pdf" && "PDF (.pdf)"}
                          </>
                          :
                          <>
                            {lang.labels.selectOption}
                          </>
                        }
                      </span>
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                    <span className="select2-dropdown select2-dropdown--below">
                      <span className="select2-results">
                        <ul className="select2-results__options" role="listbox">
                          <li className={`select2-results__option select2-results__option--selectable ${formatToExport.value === "excel" && "select2-results__option--selected"}`} role="option" aria-selected={formatToExport.value === "excel"}>
                            <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeFormat("excel")}>{lang.labels.excel} (.xlsx)</Dropdown.Item>
                          </li>
                          <li className={`select2-results__option select2-results__option--selectable ${formatToExport.value === "pdf" && "select2-results__option--selected"}`} role="option" aria-selected={formatToExport.value === "pdf"}>
                            <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeFormat("pdf")}>{lang.labels.pdf} (.pdf)</Dropdown.Item>
                          </li>
                        </ul>
                      </span>
                    </span>
                  </Dropdown.Menu>
                </Dropdown>
                <AppLegend component={"modal-contact-entry-export"} attribute={{validity: formatToExport.valid, name: "format", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
          </div>
          <div className="modal-footer flex-center">
            <button className="btn btn-light mx-2" type="reset" onClick={executeHideModalContactExport}>{lang.labels.discard}</button>
            <button className="btn btn-primary mx-2" type="button" data-kt-indicator={loadIndicator} onClick={executeContactExport}>
              <span className="indicator-label">{lang.labels.export}</span>
              <span className="indicator-progress">
                {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppModalContactEntryExport;
