import { Dispatch, SetStateAction } from "react"
import { legendInvalid, legendInvalidIcon, legendInvalidEmpty, legendInvalidEmptyIcon, legendValid, legendValidIcon, legendValidRequired, legendValidRequiredIcon, legendValidInvalidRestart, legendValidInvalidIconRestart } from "../tools/legend.data.entry.tool"

export function evaluateLegendValidEmpty(regex: RegExp, field: any, set_field: Dispatch<SetStateAction<any>>, container: string) {
  if (field.value.length === 0) {
    set_field({...field, valid: true})
    legendValidInvalidRestart(container)
  } else {
    if (regex.test(field.value)) {
      set_field({...field, valid: true})
      legendValid(container)
    } else {
      set_field({...field, valid: false})
      legendInvalid(container)
    }
  }
}

export function evaluateLegendValidEmptyIcon(regex: RegExp, field: any, set_field: Dispatch<SetStateAction<any>>, identifier: string, container: string) {
  if (field.value.length === 0) {
    set_field({...field, valid: true})
    legendValidInvalidIconRestart(identifier, container)
  } else {
    if (regex.test(field.value)) {
      set_field({...field, valid: true})
      legendValidIcon(identifier, container)
    } else {
      set_field({...field, valid: false})
      legendInvalidIcon(identifier, container)
    }
  }
}

export function evaluateLegendValidRequired(regex: RegExp, field: any, set_field: Dispatch<SetStateAction<any>>, container_1: string, container_2: string) {
  if (regex.test(field.value)) {
    set_field({...field, valid: true})
    legendValidRequired(container_1, container_2)
  } else {
    set_field({...field, valid: false})
    legendInvalidEmpty(field.value, container_1, container_2)
  }
}

export function evaluateLegendValidRequiredIcon(regex: RegExp, field: any, set_field: Dispatch<SetStateAction<any>>, identifier: string, container_1: string, container_2: string) {
  if (regex.test(field.value)) {
    set_field({...field, valid: true})
    legendValidRequiredIcon(identifier, container_1, container_2)
  } else {
    set_field({...field, valid: false})
    legendInvalidEmptyIcon(field.value, identifier, container_1, container_2)
  }
}

export function evaluateLegendValidEmptyObject(regex: RegExp, attribute: string, field: any, set_field: Dispatch<SetStateAction<any>>, container: string) {
  if (field.value[attribute].length === 0) {
    set_field({...field, valid: {...field.valid, [attribute]: true}})
    legendValidInvalidRestart(container)
  } else {
    if (regex.test(field.value[attribute])) {
      set_field({...field, valid: {...field.valid, [attribute]: true}})
      legendValid(container)
    } else {
      set_field({...field, valid: {...field.valid, [attribute]: false}})
      legendInvalid(container)
    }
  }
}

export function evaluateLegendValidEmptyIconObject(regex: RegExp, attribute: string, field: any, set_field: Dispatch<SetStateAction<any>>, identifier: string, container: string) {
  if (field.value[attribute].length === 0) {
    set_field({...field, valid: {...field.valid, [attribute]: true}})
    legendValidInvalidIconRestart(identifier, container)
  } else {
    if (regex.test(field.value[attribute])) {
      set_field({...field, valid: {...field.valid, [attribute]: true}})
      legendValidIcon(identifier, container)
    } else {
      set_field({...field, valid: {...field.valid, [attribute]: false}})
      legendInvalidIcon(identifier, container)
    }
  }
}

export function evaluateLegendValidRequiredObject(regex: RegExp, attribute: string, field: any, set_field: Dispatch<SetStateAction<any>>, container_1: string, container_2: string) {
  if (regex.test(field.value[attribute])) {
    set_field({...field, valid: {...field.valid, [attribute]: true}})
    legendValidRequired(container_1, container_2)
  } else {
    set_field({...field, valid: {...field.valid, [attribute]: false}})
    legendInvalidEmpty(field.value[attribute], container_1, container_2)
  }
}

export function evaluateLegendValidRequiredIconObject(regex: RegExp, attribute: string, field: any, set_field: Dispatch<SetStateAction<any>>, identifier: string, container_1: string, container_2: string) {
  if (regex.test(field.value[attribute])) {
    set_field({...field, valid: {...field.valid, [attribute]: true}})
    legendValidRequiredIcon(identifier, container_1, container_2)
  } else {
    set_field({...field, valid: {...field.valid, [attribute]: false}})
    legendInvalidEmptyIcon(field.value[attribute], identifier, container_1, container_2)
  }
}

export function evaluateLegendValidRequiredIconObjectComposite(regex: RegExp, attribute: string, sub_attribute: string, field: any, set_field: Dispatch<SetStateAction<any>>, identifier: string, container_1: string, container_2: string) {
  if (regex.test(field.value[attribute][sub_attribute])) {
    set_field({...field, valid: {...field.valid, [attribute]: {...field.valid[attribute], [sub_attribute]: true}}})
    legendValidRequiredIcon(identifier, container_1, container_2)
  } else {
    set_field({...field, valid: {...field.valid, [attribute]: {...field.valid[attribute], [sub_attribute]: false}}})
    legendInvalidEmptyIcon(field.value[attribute][sub_attribute], identifier, container_1, container_2)
  }
}

export function evaluateLegendValidRequiredList(regex: RegExp, index: number, field: any, set_field: Dispatch<SetStateAction<any>>, container_1: string, container_2: string) {
  if (regex.test(field.value[index])) {
    let list = field.valid.map((temp_item: boolean, temp_index: number) => {
      if (index === temp_index) {
        return true
      } else {
        return temp_item
      }
    })
    set_field({...field, valid: list})
    legendValidRequired(container_1, container_2)
  } else {
    let list = field.valid.map((temp_item: boolean, temp_index: number) => {
      if (index === temp_index) {
        return false
      } else {
        return temp_item
      }
    })
    set_field({...field, valid: list})
    legendInvalidEmpty(field.value[index], container_1, container_2)
  }
}

export function evaluateLegendValidEmptyObjectList(regex: RegExp, index: number, attribute: string, field: any, set_field: Dispatch<SetStateAction<any>>, container: string) {
  if (field.value[index][attribute].length === 0) {
    let list = field.valid.map((temp_item: any, temp_index: number) => {
      if (index === temp_index) {
        temp_item[attribute] = true
      }
      return temp_item
    })
    set_field({...field, valid: list})
    legendValidInvalidRestart(container)
  } else {
    if (regex.test(field.value[index][attribute])) {
      let list = field.valid.map((temp_item: any, temp_index: number) => {
        if (index === temp_index) {
          temp_item[attribute] = true
        }
        return temp_item
      })
      set_field({...field, valid: list})
      legendValid(container)
    } else {
      let list = field.valid.map((temp_item: any, temp_index: number) => {
        if (index === temp_index) {
          temp_item[attribute] = false
        }
        return temp_item
      })
      set_field({...field, valid: list})
      legendInvalid(container)
    }
  }
}

export function evaluateLegendValidRequiredIconObjectList(regex: RegExp, index: number, attribute: string, field: any, set_field: Dispatch<SetStateAction<any>>, identifier: string, container_1: string, container_2: string) {
  if (regex.test(field.value[index][attribute])) {
    let list = field.valid.map((temp_item: any, temp_index: number) => {
      if (index === temp_index) {
        temp_item[attribute] = true
      }
      return temp_item
    })
    set_field({...field, valid: list})
    legendValidRequiredIcon(identifier, container_1, container_2)
  } else {
    let list = field.valid.map((temp_item: any, temp_index: number) => {
      if (index === temp_index) {
        temp_item[attribute] = false
      }
      return temp_item
    })
    set_field({...field, valid: list})
    legendInvalidEmptyIcon(field.value[index][attribute], identifier, container_1, container_2)
  }
}

export function evaluateLegendValidRequiredObjectMap(regex: RegExp, index: number, sub_index: number, attribute: string, field: any, set_field: Dispatch<SetStateAction<any>>, container_1: string, container_2: string) {
  if (regex.test(field.value[index].contents[sub_index][attribute])) {
    let list = field.valid.map((temp_item: any, temp_index: number) => {
      if (index === temp_index) {
        temp_item.contents[sub_index][attribute] = true
      }
      return temp_item
    })
    set_field({...field, valid: list})
    legendValidRequired(container_1, container_2)
  } else {
    let list = field.valid.map((temp_item: any, temp_index: number) => {
      if (index === temp_index) {
        temp_item.contents[sub_index][attribute] = false
      }
      return temp_item
    })
    set_field({...field, valid: list})
    legendInvalidEmpty(field.value[index].contents[sub_index][attribute], container_1, container_2)
  }
}
