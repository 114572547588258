import { Language } from "../languages/labels/language.labels";

export function validationList(lang: Language): {code: string, display: string}[] {
  return [
    { code: 'valid', display: lang.labels.validated },
    { code: 'invalid', display: lang.labels.draft }
  ]
}

export function validationValue(lang: Language, code: string): string {
  let validation = validationList(lang).find((item) => (item.code === code))
  return validation ? validation.display : lang.labels.undefined
}

export function validationBoolean(lang: Language, code: boolean): string {
  return code ? validationList(lang)[0].display : validationList(lang)[1].display
}
