import React, { Dispatch, SetStateAction, useState } from 'react';
import { Dropdown } from 'react-bootstrap';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { countryName, countryCode, countryList } from '../../libraries/country.library';
import { legendValid } from '../../tools/legend.data.entry.tool';

import AppLegend from './Legend';

export interface AppInputCountryProps {
  country: {value: string, valid: boolean},
  setCountry: Dispatch<SetStateAction<{value: string, valid: boolean}>>
};

const AppInputCountry: React.FunctionComponent<AppInputCountryProps> = ({country, setCountry}) => {
  const {lang} = StateLanguage()

  const [search, setSearch] = useState<{text: string, list: string[]}>({text: '', list: []})

  const handleChangeSearchCountry = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let text: string = event.target.value
    let list: string[] = []

    if (text.length > 0) {
      for (let country of countryList(lang.code)) {
        if (country.toLowerCase().indexOf(text.toLowerCase()) !== -1) {
          list.push(country)
        }
      }
    } else {
      list = countryList(lang.code)
    }

    setSearch({text: text, list: list})
  }

  const handleChangeCountry = (item: string) => {
    setCountry({...country, value: item, valid: true})
    legendValid('legend-country-required')
  }

  function uploadDropdown() {
    setSearch({text: '', list: countryList(lang.code)})
  }

  return (
    <>
      <Dropdown onToggle={uploadDropdown}>
        <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0" >
          <span className="selection">
            <span className="select2-selection select2-selection--single form-select form-select-solid">
              <span className="select2-selection__rendered" role="textbox">
                {country.valid ? countryName(lang.code, country.value) : lang.labels.selectOption}
              </span>
            </span>
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
          <span className="select2-dropdown select2-dropdown--below">
            <span className="select2-search select2-search--dropdown">
              <input className="select2-search__field" type="text" name="contact" value={search.text} onChange={handleChangeSearchCountry} />
            </span>
            <span className="select2-results">
              <ul className="select2-results__options" role="listbox">
                { search.list.length > 0
                  ?
                  <>
                    { search.list.map (( (item, index) => { return (
                      <li key={index} className={`select2-results__option select2-results__option--selectable ${countryCode(lang.code, item) === country.value && "select2-results__option--selected"}`} role="option" aria-selected={countryCode(lang.code, item) === country.value}>
                        <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeCountry(countryCode(lang.code, item))}>{item}</Dropdown.Item>
                      </li>
                    )}))}
                  </>
                  :
                  <li className="select2-results__option select2-results__message" role="alert" aria-live="assertive">{lang.labels.noResultsFound}</li>
                }
              </ul>
            </span>
          </span>
        </Dropdown.Menu>
      </Dropdown>
      <AppLegend attribute={{validity: country.valid, name: "country", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
    </>
  )
}

export default AppInputCountry;
