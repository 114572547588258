import React, { Dispatch, SetStateAction } from 'react';
import { Dropdown } from 'react-bootstrap';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { legendValid } from '../../tools/legend.data.entry.tool';
import { deadlineTypeList, deadlineTypeValue } from '../../libraries/deadline.type.library';

import AppLegend from './Legend';

export interface AppInputDeadlineTypeProps {
  component?: string,
  listOptions: {index: number, item: {id: string, type: string, quantity: string}} | null,
  deadlines: {value: {id: string, type: string, quantity: string}[], valid: {type: boolean, quantity: boolean}[]} | null,
  setDeadlines: Dispatch<SetStateAction<{value: {id: string, type: string, quantity: string}[], valid: {type: boolean, quantity: boolean}[]}>> | null
};

const AppInputDeadlineType: React.FunctionComponent<AppInputDeadlineTypeProps> = ({component, listOptions, deadlines, setDeadlines}) => {
  const {lang} = StateLanguage()

  const handleChangeDeadlineTypeList = (index: number, item: string) => {
    if (deadlines === null || setDeadlines === null) return

    let tempDeadlinesValue = deadlines.value.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item.type = item
      }
      return temp_item
    })
    let tempDeadlinesValid = deadlines.valid.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item.type = true
      }
      return temp_item
    })

    setDeadlines({value: tempDeadlinesValue, valid: tempDeadlinesValid})
    legendValid('legend-deadline-type-required-' + index)
  }

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
          <span className="selection">
            <span className="select2-selection select2-selection--single form-select form-select-solid">
              <span className="select2-selection__rendered" role="textbox">
                {listOptions && deadlines && deadlines.valid[listOptions.index].type ? deadlineTypeValue(lang, listOptions.item.type, false) : lang.labels.selectOption}
              </span>
            </span>
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
          <span className="select2-dropdown select2-dropdown--below">
            <span className="select2-results">
              <ul className="select2-results__options" role="listbox">
                { listOptions && deadlineTypeList(lang).map (( (sub_item, sub_index) => { return (
                  <li key={sub_index} className={`select2-results__option select2-results__option--selectable ${listOptions.item.type === sub_item.code && "select2-results__option--selected"}`} role="option" aria-selected={listOptions.item.type === sub_item.code}>
                    <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeDeadlineTypeList(listOptions.index, sub_item.code)}>{sub_item.display_singular}</Dropdown.Item>
                  </li>
                )}))}
              </ul>
            </span>
          </span>
        </Dropdown.Menu>
      </Dropdown>
      { listOptions && deadlines &&
        <AppLegend attribute={{validity: deadlines.valid[listOptions.index].type, name: "deadline-type", index: listOptions.index, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
      }
    </>
  )
}

export default AppInputDeadlineType;
