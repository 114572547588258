export function timeFormat24h(date: string): string {
  let dateString: string = (date.length === 10) ? `${date}T00:00:00.000` : date
  let dateValue: Date = new Date(dateString)
  let hours: string = dateValue.getHours().toString().padStart(2, '0')
  let minutes: string = dateValue.getMinutes().toString().padStart(2, '0')

  return `${hours}:${minutes} h`
}

export function timeFormat12h(date: string): string {
  let dateString: string = (date.length === 10) ? `${date}T00:00:00.000` : date
  let dateValue: Date = new Date(dateString)
  let hours: number = dateValue.getHours()
  let minutes: string = dateValue.getMinutes().toString().padStart(2, '0')
  let period: string = (hours >= 12) ? 'pm' : 'am'

  return `${hours % 12 || 12}:${minutes} ${period}`
}

export function dateFormatAbbreviated(lang_code: string, date: string): string {
  let dateString: string = (date.length === 10) ? `${date}T00:00:00.000` : date
  let dateValue: Date = new Date(dateString)
  let day: string = dateValue.getDate().toString().padStart(2, '0')
  let month: string = (dateValue.getMonth() + 1).toString()
  let year: string = dateValue.getFullYear().toString()

  let list: Record<string, string[]> = {
    EN: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    FR: ['Janv', 'Févr', 'Mars', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'],
    ES: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']
  }

  month = list[lang_code][dateValue.getMonth()]

  return `${day} ${month} ${year}`
}

export function dateFormatLocalized(lang_code: string, date: string): string {
  let dateString: string = (date.length === 10) ? `${date}T00:00:00.000` : date
  let dateValue: Date = new Date(dateString)
  let day: string = dateValue.getDate().toString().padStart(2, '0')
  let month: string = (dateValue.getMonth() + 1).toString()
  let year: string = dateValue.getFullYear().toString()
  let separator: Record<string, string> = { EN: '-', FR: '.', ES: '/' }

  return `${day}${separator[lang_code]}${month}${separator[lang_code]}${year}`
}

export function datetimeFormat24h(lang_code: string, date: string): string {
  return `${dateFormatAbbreviated(lang_code, date)}, ${timeFormat24h(date)}`
}

export function datetimeFormat12h(lang_code: string, date: string): string {
  return `${dateFormatAbbreviated(lang_code, date)}, ${timeFormat12h(date)}`
}
