export const getError = (code: number, lang: string) => {
  switch (code) {
    case 9999:
      switch (lang) {
        case "ES":
          return "¡Error de respuesta del servicio!"
        case "FR":
          return "Erreur de réponse du service!"
        default:
          return "Service response error!"
      }
    case 1000:
      switch (lang) {
        case "ES":
          return "¡Token no encontrado!"
        case "FR":
          return "Jeton non trouvé!"
        default:
          return "Token not found!"
      }
    case 1001:
      switch (lang) {
        case "ES":
          return "¡Token inválido!"
        case "FR":
          return "Jeton invalide!"
        default:
          return "Token invalid!"
      }
    case 1002:
      switch (lang) {
        case "ES":
          return "¡Ocurrió un error en la base de datos!"
        case "FR":
          return "Une erreur s'est produite dans la base de données!"
        default:
          return "Error occurred in database!"
      }
    case 1003:
      switch (lang) {
        case "ES":
          return "¡Uno o más campos obligatorios están vacíos!"
        case "FR":
          return "Un ou plusieurs champs obligatoires sont vides!"
        default:
          return "One or more mandatory fields are empty!"
      }
    case 1004:
      switch (lang) {
        case "ES":
          return "¡UUID no encontrado!"
        case "FR":
          return "UUID non trouvé!"
        default:
          return "Uuid not found!"
      }
    case 1005:
      switch (lang) {
        case "ES":
          return "¡Las contraseñas no coinciden!"
        case "FR":
          return "Les mots de passe ne correspondent pas!"
        default:
          return "Passwords do not match!"
      }
    case 1006:
      switch (lang) {
        case "ES":
          return "¡Formato inválido para el correo electrónico!"
        case "FR":
          return "Format invalide pour l'e-mail!"
        default:
          return "Invalid format for email!"
      }
    case 1007:
      switch (lang) {
        case "ES":
          return "¡Contraseña incorrecta!"
        case "FR":
          return "Mot de passe incorrect!"
        default:
          return "Wrong password!"
      }
    case 1008:
      switch (lang) {
        case "ES":
          return "¡Usuario no encontrado!"
        case "FR":
          return "Utilisateur non trouvé!"
        default:
          return "User not found!"
      }
    case 1009:
      switch (lang) {
        case "ES":
          return "¡Apikey inválida!"
        case "FR":
          return "Apikey invalide!"
        default:
          return "Invalid apikey!"
      }
    case 1010:
      switch (lang) {
        case "ES":
          return "¡El correo electrónico ya está en uso!"
        case "FR":
          return "L'e-mail est déjà pris!"
        default:
          return "Email already taken!"
      }
    case 1011:
      switch (lang) {
        case "ES":
          return "¡Debe tener al menos 8 caracteres!"
        case "FR":
          return "Doit contenir au moins 8 caractères!"
        default:
          return "Must be at least 8 characters!"
      }
    case 1012:
      switch (lang) {
        case "ES":
          return "¡Este usuario ya está suscrito a estos servicios!"
        case "FR":
          return "Cet utilisateur est déjà abonné à ces services!"
        default:
          return "This user is already subscribed to this service!"
      }
    case 1014:
      switch (lang) {
        case "ES":
          return "¡No tenemos registrado este correo en nuestra base de datos!"
        case "FR":
          return "Nous n'avons pas enregistré cet e-mail dans notre base de données!"
        default:
          return "We do not have this email registered in our database!"
      }
    case 1015:
      switch (lang) {
        case "ES":
          return "¡Error al enviar el correo!"
        case "FR":
          return "Erreur lors de l'envoi de l'e-mail!"
        default:
          return "Error sending email!"
      }
    case 1016:
      switch (lang) {
        case "ES":
          return "¡Esta empresa ya está registrada!"
        case "FR":
          return "Cette entreprise est déjà enregistrée!"
        default:
          return "This company is already registered!"
      }
    case 1017:
      switch (lang) {
        case "ES":
          return "¡El usuario ya está verificado!"
        case "FR":
          return "L'utilisateur est déjà vérifié!"
        default:
          return "The user is already verified!"
      }
    case 1018:
      switch (lang) {
        case "ES":
          return "¡Token de contraseña incorrecto!"
        case "FR":
          return "Jeton de mot de passe incorrect!"
        default:
          return "Wrong password token!"
      }
    case 1019:
      switch (lang) {
        case "ES":
          return "¡Otra apiKey inválida!"
        case "FR":
          return "Autre apiKey invalide!"
        default:
          return "Invalid other apiKey!"
      }
    case 1020:
      switch (lang) {
        case "ES":
          return "¡Token de verificación incorrecto!"
        case "FR":
          return "Jeton de vérification incorrect!"
        default:
          return "Wrong verification token!"
      }
    case 1021:
      switch (lang) {
        case "ES":
          return "¡No tienes acceso a esta empresa!"
        case "FR":
          return "Vous n'avez pas accès à cette entreprise!"
        default:
          return "You do not have access to this company!"
      }
    case 1022:
      switch (lang) {
        case "ES":
          return "¡Ya existe una factura con esta orden!"
        case "FR":
          return "Il existe déjà une facture avec cette commande!"
        default:
          return "There is already an invoice with this order!"
      }
    case 1023:
      switch (lang) {
        case "ES":
          return "¡Empresa no encontrada!"
        case "FR":
          return "Entreprise non trouvée!"
        default:
          return "Company not found!"
      }
    case 1024:
      switch (lang) {
        case "ES":
          return "¡No se puede crear una factura a partir de una orden rechazada!"
        case "FR":
          return "Impossible de créer une facture à partir d'une commande rejetée!"
        default:
          return "Unable to create an invoice from a rejected order!"
      }
    case 1025:
      switch (lang) {
        case "ES":
          return "¡UUID inválido!"
        case "FR":
          return "UUID invalide!"
        default:
          return "Invalid uuid!"
      }
    case 1026:
      switch (lang) {
        case "ES":
          return "¡Notificación no encontrada!"
        case "FR":
          return "Notification non trouvée!"
        default:
          return "Notification not found!"
      }
    case 1027:
      switch (lang) {
        case "ES":
          return "¡La cotización no se encuentra en esta empresa!"
        case "FR":
          return "Le devis n'est pas trouvé dans cette entreprise!"
        default:
          return "The quote is not found in this company!"
      }
    case 1028:
      switch (lang) {
        case "ES":
          return "¡La fecha de finalización debe ser mayor que la fecha de inicio!"
        case "FR":
          return "La date de fin doit être supérieure à la date de début!"
        default:
          return "The end date must be greater than the start date!"
      }
    case 1029:
      switch (lang) {
        case "ES":
          return "¡Correo electrónico incorrecto!"
        case "FR":
          return "E-mail incorrect!"
        default:
          return "Wrong email!"
      }
    case 1030:
      switch (lang) {
        case "ES":
          return "¡Cotización no encontrada!"
        case "FR":
          return "Devis non trouvé!"
        default:
          return "Quote not found!"
      }
    case 1031:
      switch (lang) {
        case "ES":
          return "¡No puedes invitarte a ti mismo!"
        case "FR":
          return "Vous ne pouvez pas vous inviter vous-même!"
        default:
          return "You can't invite yourself!"
      }
    case 1032:
      switch (lang) {
        case "ES":
          return "¡Factura no encontrada!"
        case "FR":
          return "Facture non trouvée!"
        default:
          return "Invoice not found!"
      }
    case 1033:
      switch (lang) {
        case "ES":
          return "¡Contacto no encontrado!"
        case "FR":
          return "Contact non trouvé!"
        default:
          return "Contact not found!"
      }
    case 1034:
      switch (lang) {
        case "ES":
          return "¡Moneda incorrecta!"
        case "FR":
          return "Devise incorrecte!"
        default:
          return "Currency incorrect!"
      }
    case 1035:
      switch (lang) {
        case "ES":
          return "¡Pago no encontrado!"
        case "FR":
          return "Paiement non trouvé!"
        default:
          return "Payment not found!"
      }
    case 1036:
      switch (lang) {
        case "ES":
          return "¡El pago ya ha sido eliminado!"
        case "FR":
          return "Le paiement a déjà été supprimé!"
        default:
          return "The payment has already been deleted!"
      }
    case 1037:
      switch (lang) {
        case "ES":
          return "¡El contacto ya ha sido eliminado!"
        case "FR":
          return "Le contact a déjà été supprimé!"
        default:
          return "The contact has already been deleted!"
      }
    case 1038:
      switch (lang) {
        case "ES":
          return "¡Factura no validada!"
        case "FR":
          return "Facture non validée!"
        default:
          return "Invoice not validated!"
      }
    case 1039:
      switch (lang) {
        case "ES":
          return "¡El monto no debe ser negativo!"
        case "FR":
          return "Le montant ne doit pas être négatif!"
        default:
          return "The amount must not be negative!"
      }
    case 1040:
      switch (lang) {
        case "ES":
          return "¡Uno de los contactos ya ha sido eliminado!"
        case "FR":
          return "L'un des contacts a déjà été supprimé!"
        default:
          return "One of the contacts has already been deleted!"
      }
    case 1041:
      switch (lang) {
        case "ES":
          return "¡Ese contacto ya existe!"
        case "FR":
          return "Ce contact existe déjà!"
        default:
          return "That contact already exists!"
      }
    case 1042:
      switch (lang) {
        case "ES":
          return "¡El contacto no pertenece a la empresa!"
        case "FR":
          return "Le contact n'appartient pas à l'entreprise!"
        default:
          return "The contact does not belong to the company!"
      }
    case 1043:
      switch (lang) {
        case "ES":
          return "¡Más de lo que deberías pagar!"
        case "FR":
          return "Plus que ce que vous devriez payer!"
        default:
          return "More than you should pay!"
      }
    case 1044:
      switch (lang) {
        case "ES":
          return "¡El contacto ya pertenece a la empresa!"
        case "FR":
          return "Le contact appartient déjà à l'entreprise!"
        default:
          return "The contact already belongs to the company!"
      }
    case 1047:
      switch (lang) {
        case "ES":
          return "¡La nueva contraseña que ingresaste es la misma que tu contraseña anterior!"
        case "FR":
          return "Le nouveau mot de passe que vous avez saisi est le même que votre mot de passe précédent!"
        default:
          return "The new password you entered is the same as your previous password!"
      }
    case 1048:
      switch (lang) {
        case "ES":
          return "¡Forma incorrecta de ordenar!"
        case "FR":
          return "Mauvaise manière de commander!"
        default:
          return "Incorrect way to order!"
      }
    case 1049:
      switch (lang) {
        case "ES":
          return "¡El nuevo correo electrónico es el mismo que el correo registrado actualmente en tu cuenta!"
        case "FR":
          return "Le nouvel e-mail est le même que l'e-mail actuellement enregistré dans votre compte!"
        default:
          return "The new email is the same as the email currently registered in your account!"
      }
    case 1050:
      switch (lang) {
        case "ES":
          return "¡Método de pago incorrecto!"
        case "FR":
          return "Mauvaise méthode de paiement!"
        default:
          return "Wrong payment method!"
      }
    case 1051:
      switch (lang) {
        case "ES":
          return "¡La cotización no existe!"
        case "FR":
          return "Le devis n'existe pas!"
        default:
          return "The quote does not exist!"
      }
    case 1052:
      switch (lang) {
        case "ES":
          return "¡Tipo incorrecto!"
        case "FR":
          return "Type incorrect!"
        default:
          return "Wrong type!"
      }
    case 1055:
      switch (lang) {
        case "ES":
          return "¡El nombre, apellido y correo electrónico no deben cambiarse!"
        case "FR":
          return "Le prénom, le nom de famille et l'e-mail ne doivent pas être modifiés!"
        default:
          return "The first name, last name and email should not be changed!"
      }
    case 1056:
      switch (lang) {
        case "ES":
          return "¡El nombre y apellido no deben cambiarse!"
        case "FR":
          return "Le prénom et le nom de famille ne doivent pas être modifiés!"
        default:
          return "The first name and last name should not be changed!"
      }
    case 1057:
      switch (lang) {
        case "ES":
          return "¡La cotización con este estado no se puede editar!"
        case "FR":
          return "Le devis avec cet état ne peut pas être modifié!"
        default:
          return "The quote with this status cannot be edited!"
      }
    case 1058:
      switch (lang) {
        case "ES":
          return "¡Ya existe una factura con esta cotización!"
        case "FR":
          return "Il existe déjà une facture avec ce devis!"
        default:
          return "There is already an invoice with this quote!"
      }
    case 1059:
      switch (lang) {
        case "ES":
          return "¡Invitación no enviada!"
        case "FR":
          return "Invitation non envoyée!"
        default:
          return "Invitation not sent!"
      }
    case 1060:
      switch (lang) {
        case "ES":
          return "¡La invitación ya ha sido aceptada!"
        case "FR":
          return "L'invitation a déjà été acceptée!"
        default:
          return "Invitation has already been accepted!"
      }
    case 1061:
      switch (lang) {
        case "ES":
          return "¡La invitación ya ha sido rechazada!"
        case "FR":
          return "L'invitation a déjà été refusée!"
        default:
          return "Invitation has already been rejected!"
      }
    case 1062:
      switch (lang) {
        case "ES":
          return "¡Estado de invitación incorrecto!"
        case "FR":
          return "Statut d'invitation incorrect!"
        default:
          return "Incorrect invitation status!"
      }
    case 1064:
      switch (lang) {
        case "ES":
          return "¡Error al enviar el correo electrónico para la verificación de la cuenta!"
        case "FR":
          return "Erreur lors de l'envoi de l'email pour la vérification du compte!"
        default:
          return "Error when sending the email for account verification!"
      }
    case 1065:
      switch (lang) {
        case "ES":
          return "¡Formato inválido para el teléfono!"
        case "FR":
          return "Format invalide pour le téléphone!"
        default:
          return "Invalid format for phone!"
      }
    case 1066:
      switch (lang) {
        case "ES":
          return "¡Formato inválido para la dirección adicional!"
        case "FR":
          return "Format invalide pour l'adresse supplémentaire!"
        default:
          return "Invalid format for additional address!"
      }
    case 1067:
      switch (lang) {
        case "ES":
          return "¡Formato inválido para el número de dirección!"
        case "FR":
          return "Format invalide pour le numéro de l'adresse!"
        default:
          return "Invalid format for address number!"
      }
    case 1068:
      switch (lang) {
        case "ES":
          return "¡Formato inválido para la calle de la dirección!"
        case "FR":
          return "Format invalide pour la rue de l'adresse!"
        default:
          return "Invalid format for address street!"
      }
    case 1069:
      switch (lang) {
        case "ES":
          return "¡Formato inválido para el código postal!"
        case "FR":
          return "Format invalide pour le code postal!"
        default:
          return "Invalid zip_code!"
      }
    case 1070:
      switch (lang) {
        case "ES":
          return "¡Invitación no encontrada!"
        case "FR":
          return "Invitation non trouvée!"
        default:
          return "Invitation not found!"
      }
    case 1071:
      switch (lang) {
        case "ES":
          return "¡Formato inválido para el bic!"
        case "FR":
          return "Format invalide pour le bic!"
        default:
          return "Invalid format for bic!"
      }
    case 1072:
      switch (lang) {
        case "ES":
          return "¡Formato inválido para el bban!"
        case "FR":
          return "Format invalide pour le bban!"
        default:
          return "Invalid format for bban!"
      }
    case 1073:
      switch (lang) {
        case "ES":
          return "¡Formato inválido para el vat!"
        case "FR":
          return "Format invalide pour le vat!"
        default:
          return "Invalid format for vat!"
      }
    case 1074:
      switch (lang) {
        case "ES":
          return "¡Formato inválido para el registro!"
        case "FR":
          return "Format invalide pour l'enregistrement!"
        default:
          return "Invalid format for register!"
      }
    case 1075:
      switch (lang) {
        case "ES":
          return "¡Formato inválido para el sitio web!"
        case "FR":
          return "Format invalide pour le site web!"
        default:
          return "Invalid format for website!"
      }
    case 1076:
      switch (lang) {
        case "ES":
          return "¡Orden de proveedor no aprobada!"
        case "FR":
          return "Commande fournisseur non approuvée!"
        default:
          return "Provider Order not approved!"
      }
    case 1077:
      switch (lang) {
        case "ES":
          return "¡Formato inválido para el ID de la empresa!"
        case "FR":
          return "Format invalide pour l'ID de l'entreprise!"
        default:
          return "Invalid format for id of company!"
      }
    case 1078:
      switch (lang) {
        case "ES":
          return "¡La notificación no pertenece al usuario!"
        case "FR":
          return "La notification n'appartient pas à l'utilisateur!"
        default:
          return "Notification does not belong to user!"
      }
    case 1079:
      switch (lang) {
        case "ES":
          return "¡La orden de proveedor no se encuentra en esta empresa!"
        case "FR":
          return "La commande fournisseur n'est pas trouvée dans cette entreprise!"
        default:
          return "The provider order is not found in this company!"
      }
    case 1080:
      switch (lang) {
        case "ES":
          return "¡El primer nombre y el correo electrónico no deben cambiarse!"
        case "FR":
          return "Le prénom et l'email ne doivent pas être modifiés!"
        default:
          return "The first name and email should not be changed!"
      }
    case 1081:
      switch (lang) {
        case "ES":
          return "¡El primer nombre no debe cambiarse!"
        case "FR":
          return "Le prénom ne doit pas être changé!"
        default:
          return "The first name should not be changed!"
      }
    case 1082:
      switch (lang) {
        case "ES":
          return "¡El apellido y el correo electrónico no deben cambiarse!"
        case "FR":
          return "Le nom de famille et l'email ne doivent pas être modifiés!"
        default:
          return "The last name and email should not be changed!"
      }
    case 1083:
      switch (lang) {
        case "ES":
          return "¡El apellido no debe cambiarse!"
        case "FR":
          return "Le nom de famille ne doit pas être changé!"
        default:
          return "The last name should not be changed!"
      }
    case 1084:
      switch (lang) {
        case "ES":
          return "¡El correo electrónico no debe cambiarse!"
        case "FR":
          return "L'email ne doit pas être changé!"
        default:
          return "The email should not be changed!"
      }
    case 1085:
      switch (lang) {
        case "ES":
          return "¡El nombre no debe cambiarse!"
        case "FR":
          return "Le nom ne doit pas être changé!"
        default:
          return "The name should not be changed!"
      }
    case 1086:
      switch (lang) {
        case "ES":
          return "¡Cotización no aprobada!"
        case "FR":
          return "Citation non approuvée!"
        default:
          return "Quote not approved!"
      }
    case 1087:
      switch (lang) {
        case "ES":
          return "¡Las plantillas no existen!"
        case "FR":
          return "Les modèles n'existent pas!"
        default:
          return "Templates does not exist!"
      }
    case 1088:
      switch (lang) {
        case "ES":
          return "¡La moneda debe ser EUR o CHF!"
        case "FR":
          return "La devise doit être EUR ou CHF!"
        default:
          return "The currency has to be EUR or CHF!"
      }
    case 1089:
      switch (lang) {
        case "ES":
          return "¡Por favor ingrese un QR-IBAN válido!"
        case "FR":
          return "Veuillez entrer un QR-IBAN valide!"
        default:
          return "Please enter a valid QR-IBAN!"
      }
    case 1090:
      switch (lang) {
        case "ES":
          return "¡Por favor ingrese un IBAN válido!"
        case "FR":
          return "Veuillez entrer un IBAN valide!"
        default:
          return "Please enter a valid IBAN!"
      }
    case 1091:
      switch (lang) {
        case "ES":
          return "¡Formato inválido para el idioma!"
        case "FR":
          return "Format invalide pour la langue!"
        default:
          return "Invalid format for language!"
      }
    case 1092:
      switch (lang) {
        case "ES":
          return "¡Formato inválido para el país!"
        case "FR":
          return "Format invalide pour le pays!"
        default:
          return "Invalid format for country!"
      }
    case 1093:
      switch (lang) {
        case "ES":
          return "¡La orden de proveedor no se encuentra en esta empresa!"
        case "FR":
          return "La commande fournisseur n'est pas trouvée dans cette entreprise!"
        default:
          return "The provider order is not found in this company!"
      }
    case 1094:
      switch (lang) {
        case "ES":
          return "¡Factura de proveedor validada!"
        case "FR":
          return "Facture fournisseur validée!"
        default:
          return "Provider invoice validated!"
      }
    case 1095:
      switch (lang) {
        case "ES":
          return "¡Factura de proveedor no encontrada!"
        case "FR":
          return "Facture fournisseur non trouvée!"
        default:
          return "Provider invoice not found!"
      }
    case 1096:
      switch (lang) {
        case "ES":
          return "¡Cuenta de tesorería eliminada!"
        case "FR":
          return "Compte de trésorerie supprimé!"
        default:
          return "Treasury Account deleted!"
      }
    case 1097:
      switch (lang) {
        case "ES":
          return "¡Factura de proveedor no validada!"
        case "FR":
          return "Facture fournisseur non validée!"
        default:
          return "Provider invoice not validated!"
      }
    case 1098:
      switch (lang) {
        case "ES":
          return "¡Orden de proveedor no encontrada!"
        case "FR":
          return "Commande fournisseur non trouvée!"
        default:
          return "Provider order not found!"
      }
    case 1099:
      switch (lang) {
        case "ES":
          return "¡Gasto no encontrado!"
        case "FR":
          return "Dépense non trouvée!"
        default:
          return "Expense not found!"
      }
    case 1100:
      switch (lang) {
        case "ES":
          return "¡El gasto ya ha sido eliminado!"
        case "FR":
          return "La dépense a déjà été supprimée!"
        default:
          return "The expense has already been deleted!"
      }
    case 1101:
      switch (lang) {
        case "ES":
          return "¡El pedido con este estado no puede ser editado!"
        case "FR":
          return "La commande avec ce statut ne peut pas être modifiée!"
        default:
          return "The order with this status cannot be edited!"
      }
    case 1102:
      switch (lang) {
        case "ES":
          return "¡El pedido no existe!"
        case "FR":
          return "La commande n'existe pas!"
        default:
          return "The order does not exist!"
      }
    case 1103:
      switch (lang) {
        case "ES":
          return "¡Pedido no cancelado!"
        case "FR":
          return "Commande non annulée!"
        default:
          return "Order not canceled!"
      }
    case 1104:
      switch (lang) {
        case "ES":
          return "¡Pedido no aprobado!"
        case "FR":
          return "Commande non approuvée!"
        default:
          return "Order not approved!"
      }
    case 1105:
      switch (lang) {
        case "ES":
          return "¡Cuenta de tesorería no encontrada!"
        case "FR":
          return "Compte de trésorerie non trouvé!"
        default:
          return "Treasury account not found!"
      }
    case 1106:
      switch (lang) {
        case "ES":
          return "¡El pedido no se encuentra en esta empresa!"
        case "FR":
          return "La commande n'est pas trouvée dans cette entreprise!"
        default:
          return "The order is not found in this company!"
      }
    case 1107:
      switch (lang) {
        case "ES":
          return "¡Pedido no encontrado!"
        case "FR":
          return "Commande non trouvée!"
        default:
          return "Order not found!"
      }
    case 1108:
      switch (lang) {
        case "ES":
          return "¡Ya existe una factura de proveedor con este pedido de proveedor!"
        case "FR":
          return "Il existe déjà une facture fournisseur avec cette commande fournisseur!"
        default:
          return "There is already a provider invoice with this provider order!"
      }
    case 1109:
      switch (lang) {
        case "ES":
          return "¡No se puede crear una factura de proveedor a partir de una orden de proveedor rechazada!"
        case "FR":
          return "Impossible de créer une facture fournisseur à partir d'une commande fournisseur rejetée!"
        default:
          return "Unable to create a provider invoice from a rejected provider order!"
      }
    case 1110:
      switch (lang) {
        case "ES":
          return "¡Tiene pagos realizados, no se puede invalidar!"
        case "FR":
          return "Des paiements ont été effectués, il ne peut pas être invalidé!"
        default:
          return "Has payments made, it cannot be invalidated!"
      }
    case 1111:
      switch (lang) {
        case "ES":
          return "¡Categoría no encontrada!"
        case "FR":
          return "Catégorie non trouvée!"
        default:
          return "Category not found!"
      }
    case 1112:
      switch (lang) {
        case "ES":
          return "¡La categoría ya está eliminada!"
        case "FR":
          return "La catégorie est déjà supprimée!"
        default:
          return "Category is already deleted!"
      }
    case 1113:
      switch (lang) {
        case "ES":
          return "¡Productos no encontrados!"
        case "FR":
          return "Produits non trouvés!"
        default:
          return "Products not found!"
      }
    case 1114:
      switch (lang) {
        case "ES":
          return "¡Productos ya eliminados!"
        case "FR":
          return "Produits déjà supprimés!"
        default:
          return "Products are already deleted!"
      }
    case 1115:
      switch (lang) {
        case "ES":
          return "¡Una o más categorías no encontradas!"
        case "FR":
          return "Une ou plusieurs catégories non trouvées!"
        default:
          return "One or more categories not found!"
      }
    case 1116:
      switch (lang) {
        case "ES":
          return "¡Una o más categorías ya eliminadas!"
        case "FR":
          return "Une ou plusieurs catégories déjà supprimées!"
        default:
          return "One or more categories already deleted!"
      }
    case 1117:
      switch (lang) {
        case "ES":
          return "¡Uno o más productos no encontrados!"
        case "FR":
          return "Un ou plusieurs produits non trouvés!"
        default:
          return "One or more products not found!"
      }
    case 1118:
      switch (lang) {
        case "ES":
          return "¡Uno o más productos ya eliminados!"
        case "FR":
          return "Un ou plusieurs produits déjà supprimés!"
        default:
          return "One or more products already deleted!"
      }
    case 1119:
      switch (lang) {
        case "ES":
          return "¡Uno o más variantes con el mismo nombre!"
        case "FR":
          return "Une ou plusieurs variantes avec le même nom!"
        default:
          return "One or more variants with the same name!"
      }
    case 1120:
      switch (lang) {
        case "ES":
          return "¡Tipo inválido! Los valores permitidos son: producto, servicio."
        case "FR":
          return "Type invalide ! Les valeurs autorisées sont : produit, service."
        default:
          return "Invalid type! Allowed values are: product, service."
      }
    case 1121:
      switch (lang) {
        case "ES":
          return "¡Formato inválido para el ID de la categoría!"
        case "FR":
          return "Format invalide pour l'ID de la catégorie!"
        default:
          return "Invalid format for the category ID!"
      }
    case 1122:
      switch (lang) {
        case "ES":
          return "¡Servicio no encontrado!"
        case "FR":
          return "Service non trouvé!"
        default:
          return "Service not found!"
      }
    case 1123:
      switch (lang) {
        case "ES":
          return "¡Servicio ya eliminado!"
        case "FR":
          return "Service déjà supprimé!"
        default:
          return "Service is already deleted!"
      }
    case 1124:
      switch (lang) {
        case "ES":
          return "¡Uno o más servicios no encontrados!"
        case "FR":
          return "Un ou plusieurs services non trouvés!"
        default:
          return "One or more services not found!"
      }
    case 1125:
      switch (lang) {
        case "ES":
          return "¡Uno o más servicios ya eliminados!"
        case "FR":
          return "Un ou plusieurs services déjà supprimés!"
        default:
          return "One or more services already deleted!"
      }
    case 1126:
      switch (lang) {
        case "ES":
          return "¡Categoría incorrecta para productos!"
        case "FR":
          return "Catégorie incorrecte pour les produits!"
        default:
          return "Incorrect category for products!"
      }
    case 1127:
      switch (lang) {
        case "ES":
          return "¡Categoría incorrecta para servicios!"
        case "FR":
          return "Catégorie incorrecte pour les services!"
        default:
          return "Incorrect category for services!"
      }
    case 1128:
      switch (lang) {
        case "ES":
          return "¡Unidad de medida no encontrada!"
        case "FR":
          return "Unité de mesure non trouvée!"
        default:
          return "Measure unit not found!"
      }
    case 1129:
      switch (lang) {
        case "ES":
          return "¡Un producto o servicio no puede quedar sin categorizar!"
        case "FR":
          return "Un produit ou service ne peut pas rester sans catégorie!"
        default:
          return "A product or service cannot remain uncategorized!"
      }
    case 1130:
      switch (lang) {
        case "ES":
          return "¡La categoría tiene elementos, no puede ser eliminada!"
        case "FR":
          return "La catégorie a des éléments, elle ne peut pas être supprimée!"
        default:
          return "The category has items, it cannot be deleted!"
      }
    case 1131:
      switch (lang) {
        case "ES":
          return "¡Una o más unidades no encontradas!"
        case "FR":
          return "Une ou plusieurs unités non trouvées!"
        default:
          return "One or more units not found!"
      }
    case 1132:
      switch (lang) {
        case "ES":
          return "¡Ya existe una cuenta de tesorería con esta cuenta contable!"
        case "FR":
          return "Il existe déjà un compte de trésorerie avec ce compte comptable!"
        default:
          return "There is already a treasury account with this accounting account!"
      }
    case 1133:
      switch (lang) {
        case "ES":
          return "¡El código de cuenta de efectivo no está en el rango de 1000 - 1009.99!"
        case "FR":
          return "Le code de compte de caisse n'est pas dans la plage 1000 - 1009.99!"
        default:
          return "The cash account code is not in the range 1000 - 1009.99!"
      }
    case 1134:
      switch (lang) {
        case "ES":
          return "¡El código de cuenta postal no está en el rango de 1010 - 1019.99!"
        case "FR":
          return "Le code de compte postal n'est pas dans la plage 1010 - 1019.99!"
        default:
          return "The postal account code is not in the range 1010 - 1019.99!"
      }
    case 1135:
      switch (lang) {
        case "ES":
          return "¡El código de cuenta bancaria no está en el rango de 1020 - 1049.99!"
        case "FR":
          return "Le code de compte bancaire n'est pas dans la plage 1020 - 1049.99!"
        default:
          return "The bank account code is not in the range 1020 - 1049.99!"
        }
    case 1136:
      switch (lang) {
        case "ES":
          return "¡Uno o más de los productos y/o servicios seleccionados no se encontraron!"
        case "FR":
          return "Un ou plusieurs des produits et/ou services sélectionnés n'ont pas été trouvés!"
        default:
          return "One or more of the selected products and/or services were not found!"
      }
    case 1137:
      switch (lang) {
        case "ES":
          return "¡Recordatorio de entrada contable no encontrado!"
        case "FR":
          return "Rappel d'écriture comptable non trouvé!"
        default:
          return "Account Entry Reminder not found!"
      }
    default:
      switch (lang) {
        case "ES":
          return "¡Error indefinido!"
        case "FR":
          return "Erreur indéfinie!"
        default:
          return "Undefined error!"
      }
  }
}

export const getMessage = (message: string, lang: string) => {
  switch (message) {
    case "Service response successfully":
      switch (lang) {
        case "ES":
          return "¡Respuesta del servicio con éxito!"
        case "FR":
          return "Réponse du service avec succès!"
        default:
          return "Service response successfully!"
      }
    case "Successfully accepted company":
      switch (lang) {
        case "ES":
          return "¡Empresa aceptada con éxito!"
        case "FR":
          return "Entreprise acceptée avec succès!"
        default:
          return "Successfully accepted company!"
      }
    case "Successfully created treasury account":
      switch (lang) {
        case "ES":
          return "¡Cuenta de tesorería creada con éxito!"
        case "FR":
          return "Compte de trésorerie créé avec succès!"
        default:
          return "Successfully created treasury account!"
      }
    case "Successfully created company":
      switch (lang) {
        case "ES":
          return "¡Empresa creada con éxito!"
        case "FR":
          return "Entreprise créée avec succès!"
        default:
          return "Successfully created company!"
      }
    case "Successfully created company parameters":
      switch (lang) {
        case "ES":
          return "¡Parámetros de empresa creados con éxito!"
        case "FR":
          return "Paramètres d'entreprise créés avec succès!"
        default:
          return "Successfully created company parameters!"
      }
    case "Successfully created contact":
      switch (lang) {
        case "ES":
          return "¡Contacto creado con éxito!"
        case "FR":
          return "Contact créé avec succès!"
        default:
          return "Successfully created contact!"
      }
    case "Successfully created expense":
      switch (lang) {
        case "ES":
          return "¡Gasto creado con éxito!"
        case "FR":
          return "Dépense créée avec succès!"
        default:
          return "Successfully created expense!"
      }
    case "Successfully created order":
      switch (lang) {
        case "ES":
          return "¡Pedido creado con éxito!"
        case "FR":
          return "Commande créée avec succès!"
        default:
          return "Successfully created order!"
      }
    case "Successfully created invoice":
      switch (lang) {
        case "ES":
          return "¡Factura creada con éxito!"
        case "FR":
          return "Facture créée avec succès!"
        default:
          return "Successfully created invoice!"
      }
    case "Successfully created notification":
      switch (lang) {
        case "ES":
          return "¡Notificación creada con éxito!"
        case "FR":
          return "Notification créée avec succès!"
        default:
          return "Successfully created notification!"
      }
    case "Successfully created payment":
      switch (lang) {
        case "ES":
          return "¡Pago creado con éxito!"
        case "FR":
          return "Paiement créé avec succès!"
        default:
          return "Successfully created payment!"
      }
    case "Successfully created provider invoice":
      switch (lang) {
        case "ES":
          return "¡Factura de proveedor creada con éxito!"
        case "FR":
          return "Facture fournisseur créée avec succès!"
        default:
          return "Successfully created provider invoice!"
      }
    case "Successfully created provider order":
      switch (lang) {
        case "ES":
          return "¡Pedido de proveedor creado con éxito!"
        case "FR":
          return "Commande fournisseur créée avec succès!"
        default:
          return "Successfully created provider order!"
      }
    case "Successfully created quote":
      switch (lang) {
        case "ES":
          return "¡Cotización creada con éxito!"
        case "FR":
          return "Devis créé avec succès!"
        default:
          return "Successfully created quote!"
      }
    case "Successfully created user":
      switch (lang) {
        case "ES":
          return "¡Usuario creado con éxito!"
        case "FR":
          return "Utilisateur créé avec succès!"
        default:
          return "Successfully created user!"
      }
    case "Successfully deleted treasury account":
      switch (lang) {
        case "ES":
          return "¡Cuenta de tesorería eliminada con éxito!"
        case "FR":
          return "Compte de trésorerie supprimé avec succès!"
        default:
          return "Successfully deleted treasury account!"
      }
    case "Successfully deleted contact":
      switch (lang) {
        case "ES":
          return "¡Contacto eliminado con éxito!"
        case "FR":
          return "Contact supprimé avec succès!"
        default:
          return "Successfully deleted contact!"
      }
    case "Successfully deleted expense":
      switch (lang) {
        case "ES":
          return "¡Gasto eliminado con éxito!"
        case "FR":
          return "Dépense supprimée avec succès!"
        default:
          return "Successfully deleted expense!"
      }
    case "Successfully deleted payment":
      switch (lang) {
        case "ES":
          return "¡Pago eliminado con éxito!"
        case "FR":
          return "Paiement supprimé avec succès!"
        default:
          return "Successfully deleted payment!"
      }
    case "Successfully invitation cancel":
      switch (lang) {
        case "ES":
          return "¡Invitación cancelada con éxito!"
        case "FR":
          return "Invitation annulée avec succès!"
        default:
          return "Successfully invitation cancel!"
      }
    case "Successfully invitation sent":
      switch (lang) {
        case "ES":
          return "¡Invitación enviada con éxito!"
        case "FR":
          return "Invitation envoyée avec succès!"
        default:
          return "Successfully invitation sent!"
      }
    case "You have successfully logged in":
      switch (lang) {
        case "ES":
          return "¡Has iniciado sesión con éxito!"
        case "FR":
          return "Vous vous êtes connecté avec succès!"
        default:
          return "You have successfully logged in!"
      }
    case "Successfully order sent":
      switch (lang) {
        case "ES":
          return "¡Pedido enviado con éxito!"
        case "FR":
          return "Commande envoyée avec succès!"
        default:
          return "Successfully order sent!"
      }
    case "Successfully updated company settings":
      switch (lang) {
        case "ES":
          return "¡Configuración de la empresa actualizada con éxito!"
        case "FR":
          return "Paramètres de l'entreprise mis à jour avec succès!"
        default:
          return "Successfully updated company settings!"
      }
    case "Successfully updated treasury account":
      switch (lang) {
        case "ES":
          return "¡Cuenta de tesorería actualizada con éxito!"
        case "FR":
          return "Compte de trésorerie mis à jour avec succès!"
        default:
          return "Successfully updated treasury account!"
      }
    case "Successfully updated default treasury account":
      switch (lang) {
        case "ES":
          return "¡Cuenta de tesorería predeterminada actualizada con éxito!"
        case "FR":
          return "Compte de trésorerie par défaut mis à jour avec succès!"
        default:
          return "Successfully updated default treasury account!"
      }
    case "Successfully updated contact":
      switch (lang) {
        case "ES":
          return "¡Contacto actualizado con éxito!"
        case "FR":
          return "Contact mis à jour avec succès!"
        default:
          return "Successfully updated contact!"
      }
    case "Successfully updated expense":
      switch (lang) {
        case "ES":
          return "¡Gasto actualizado con éxito!"
        case "FR":
          return "Dépense mise à jour avec succès!"
        default:
          return "Successfully updated expense!"
      }
    case "Successfully updated invoice":
      switch (lang) {
        case "ES":
          return "¡Factura actualizada con éxito!"
        case "FR":
          return "Facture mise à jour avec succès!"
        default:
          return "Successfully updated invoice!"
      }
    case "Successfully updated notification":
      switch (lang) {
        case "ES":
          return "¡Notificación actualizada con éxito!"
        case "FR":
          return "Notification mise à jour avec succès!"
        default:
          return "Successfully updated notification!"
      }
    case "Successfully updated order":
      switch (lang) {
        case "ES":
          return "¡Pedido actualizado con éxito!"
        case "FR":
          return "Commande mise à jour avec succès!"
        default:
          return "Successfully updated order!"
      }
    case "Successfully updated password":
      switch (lang) {
        case "ES":
          return "¡Contraseña actualizada con éxito!"
        case "FR":
          return "Mot de passe mis à jour avec succès!"
        default:
          return "Successfully updated password!"
      }
    case "Successfully updated provider invoice":
      switch (lang) {
        case "ES":
          return "¡Factura de proveedor actualizada con éxito!"
        case "FR":
          return "Facture fournisseur mise à jour avec succès!"
        default:
          return "Successfully updated provider invoice!"
      }
    case "Successfully updated provider order":
      switch (lang) {
        case "ES":
          return "¡Pedido de proveedor actualizado con éxito!"
        case "FR":
          return "Commande fournisseur mise à jour avec succès!"
        default:
          return "Successfully updated provider order!"
      }
    case "Successfully updated quote":
      switch (lang) {
        case "ES":
          return "¡Cotización actualizada con éxito!"
        case "FR":
          return "Devis mis à jour avec succès!"
        default:
          return "Successfully updated quote!"
      }
    case "Successfully updated status":
      switch (lang) {
        case "ES":
          return "¡Estado actualizado con éxito!"
        case "FR":
          return "Statut mis à jour avec succès!"
        default:
          return "Successfully updated status!"
      }
    case "Successfully quote sent":
      switch (lang) {
        case "ES":
          return "¡Cotización enviada con éxito!"
        case "FR":
          return "Devis envoyé avec succès!"
        default:
          return "Successfully quote sent!"
      }
    case "Successfully rejected company":
      switch (lang) {
        case "ES":
          return "¡Empresa rechazada con éxito!"
        case "FR":
          return "Entreprise rejetée avec succès!"
        default:
          return "Successfully rejected company!"
      }
    case "Successful request":
      switch (lang) {
        case "ES":
          return "¡Solicitud exitosa!"
        case "FR":
          return "Demande réussie!"
        default:
          return "Successful request!"
      }
    case "Verification was successful":
      switch (lang) {
        case "ES":
          return "¡Verificación exitosa!"
        case "FR":
          return "Vérification réussie!"
        default:
          return "Verification was successful!"
      }
    case "Successfully mail sent":
      switch (lang) {
        case "ES":
          return "¡Correo enviado con éxito!"
        case "FR":
          return "Courrier envoyé avec succès!"
        default:
          return "Successfully mail sent!"
      }
    case "Successfully updated user":
      switch (lang) {
        case "ES":
          return "¡Usuario actualizado con éxito!"
        case "FR":
          return "Utilisateur mis à jour avec succès!"
        default:
          return "Successfully updated user!"
      }
    case "Successfully updated account":
      switch (lang) {
        case "ES":
          return "¡Cuenta actualizada con éxito!"
        case "FR":
          return "Compte mis à jour avec succès!"
        default:
          return "Successfully updated account!"
      }
    case "Successfully subscribed":
      switch (lang) {
        case "ES":
          return "¡Suscripción realizada con éxito!"
        case "FR":
          return "Abonnement réussi!"
        default:
          return "Successfully subscribed!"
      }
    case "Successfully deleted categories":
      switch (lang) {
        case "ES":
          return "¡Categorías eliminadas con éxito!"
        case "FR":
          return "Catégories supprimées avec succès!"
        default:
          return "Successfully deleted categories!"
      }
    case "Successfully created service":
      switch (lang) {
        case "ES":
          return "¡Servicio creado con éxito!"
        case "FR":
          return "Service créé avec succès!"
        default:
          return "Successfully created service!"
      }
    case "Successfully updated service":
      switch (lang) {
        case "ES":
          return "¡Servicio actualizado con éxito!"
        case "FR":
          return "Service mis à jour avec succès!"
        default:
          return "Successfully updated service!"
      }
    case "Successfully updated product":
      switch (lang) {
        case "ES":
          return "¡Producto actualizado con éxito!"
        case "FR":
          return "Produit mis à jour avec succès!"
        default:
          return "Successfully updated product!"
      }
    case "Successfully created product":
      switch (lang) {
        case "ES":
          return "¡Producto creado con éxito!"
        case "FR":
          return "Produit créé avec succès!"
        default:
          return "Successfully created product!"
      }
    case "Successfully deleted products":
      switch (lang) {
        case "ES":
          return "¡Productos eliminados con éxito!"
        case "FR":
          return "Produits supprimés avec succès!"
        default:
          return "Successfully deleted products!"
      }
    case "Successfully created accounting entries":
      switch (lang) {
        case "ES":
          return "¡Entradas contables creadas exitosamente!"
        case "FR":
          return "Écritures comptables créées avec succès!"
        default:
          return "Successfully created accounting entries!"
      }
    case "Successfully updated accounting entries":
      switch (lang) {
        case "ES":
          return "¡Entradas contables actualizadas exitosamente!"
        case "FR":
          return "Écritures comptables mises à jour avec succès!"
        default:
          return "Successfully created accounting entries!"
      }
    default:
      switch (lang) {
        case "ES":
          return "¡Mensaje indefinido!"
        case "FR":
          return "Message indéfini!"
        default:
          return "Undefined message!"
      }
  }
}
