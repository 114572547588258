import { Language } from "../languages/labels/language.labels";

export function inventoryTypeList(lang: Language): {code: string, display: string}[] {
  return [
    { code: 'service', display: lang.labels.service },
    { code: 'product', display: lang.labels.product }
  ]
}

export function inventoryTypeValue(lang: Language, code: string): string {
  let requirementType = inventoryTypeList(lang).find((item) => (item.code === code))
  return requirementType ? requirementType.display : lang.labels.undefined
}
