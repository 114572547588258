import { Language } from "../languages/labels/language.labels";
import { AccountingAccount } from "../models/accounting.account.model";
import { AccountingEntry } from "../models/accounting.entry.model";
import { Category } from "../models/category.model"
import { Contact } from "../models/contact.model";
import { InventoryReference } from "../models/inventory.reference.model";
import { Currency } from "../models/currency.model";
import { Deadline } from "../models/deadline.model";
import { Product } from "../models/product.model";
import { ProductVariant } from "../models/product.variant.model";
import { Service } from "../models/service.model";
import { TreasuryAccount } from "../models/treasury.account.model";
import { Unit } from "../models/unit.model";
import { dateFormatAbbreviated, dateFormatLocalized } from "./datetime.format.script";
import { moneyFormat } from "./format.value.script";

export function paymentDeadlineSelected(lang: Language, list: Deadline[], id: string): string {
  if (list.length > 0 && list.find((item) => (item.id === id))) {
    let tempPaymentDeadline = list.find((item) => (item.id === id)) as Deadline
    return `${tempPaymentDeadline.quantity} ${tempPaymentDeadline.type}${tempPaymentDeadline.quantity > 1 ? 's' : ''}`
  } else {
    return lang.labels.undefined
  }
}

export function measureUnitSelected(lang: Language, list: Unit[], type: string, id: string): string {
  if (list.length > 0) {
    let services = list.filter((item)=> (item.type === 'service'))
    let products = list.filter((item)=> (item.type === 'product'))
    switch (type){
      case 'service':
        return services.find((item) => (item.id === id))?.name ?? id
      case 'product':
        return products.find((item) => (item.id === id))?.name ?? id
      default:
        return type
    }
  } else {
    return lang.labels.undefined
  }
}

export function contactSelected(lang: Language, list: Contact[], id: string): string {
  if (list.length > 0 && list.find((item) => (item.data.id === id))) {
    let contact = list.find((item) => (item.data.id === id)) as Contact
    switch (contact.type) {
      case 'person':
        return `${contact.data.first_name} ${contact.data.last_name}`
      case 'company':
        return contact.data.name
      default:
        return contact.type
    }
  } else {
    return lang.labels.undefined
  }
}

export function categorySelected(lang: Language, list: Category[], id: string): string {
  if (list.length > 0 && list.find((item) => (item.id === id))) {
    let category = list.find((item) => (item.id === id)) as Category
    return category.display_name
  } else if (id.length === 0) {
    return lang.labels.all
  } else {
    return lang.labels.undefined
  }
}

export function serviceSelected(lang: Language, list: Service[], id: string): string {
  if (list.length > 0 && list.find((item) => (item.id === id))) {
    let service = list.find((item) => (item.id === id)) as Service
    return `${service.code.length > 0 ? `${service.code}, ` : ''}${service.display_name}`
  } else {
    return lang.labels.undefined
  }
}

export function productSelected(lang: Language, list: Product[], id: string): string {
  if (list.length > 0 && list.find((item) => (item.variants.some((sub_item) => (sub_item.id === id)))) && list.flatMap((item) => (item.variants)).find((sub_item) => (sub_item.id === id))) {
    let product = list.find(item => item.variants.some((sub_item) => (sub_item.id === id))) as Product
    let variant = list.flatMap((item) => (item.variants)).find((sub_item) => (sub_item.id === id)) as ProductVariant
    return `${variant.code.length > 0 ? `${variant.code}, ` : ''}${product.display_name} ${variant.description_short}`
  } else {
    return lang.labels.undefined
  }
}

export function treasuryAccountSelected(lang: Language, list: TreasuryAccount[], id: string): string {
  if (list.length > 0 && list.find((item) => (item.id === id))) {
    let treasuryAccount = list.find((item) => (item.id === id)) as TreasuryAccount
    return treasuryAccount.name
  } else {
    return lang.labels.undefined
  }
}

export function accountingAccountSelected(lang: Language, list: AccountingAccount[], id: string, language: string): string {
  if (list.length > 0 && list.find((item) => (item.id === id))) {
    let account = list.find((item) => (item.id === id)) as AccountingAccount
    return `${account.code}, ${account.name[language.toLowerCase() as keyof typeof account.name]}`
  } else {
    return lang.labels.undefined
  }
}

export function accountingEntrySelected(lang: Language, list: AccountingEntry[], id: string): string {
  if (list.length > 0 && list.find((item) => (item.id === id))) {
    let entry = list.find((item) => (item.id === id)) as AccountingEntry
    return `${dateFormatAbbreviated(lang.code, entry.date)}, ${entry.description}`
  } else {
    return lang.labels.undefined
  }
}

export function accountingEntryTransactionSelected(lang: Language, list: AccountingEntry[], sub_list: TreasuryAccount[], currency: Currency, id: string): string {
  if (list.length > 0 && list.find((item) => (item.id === id))) {
    let entry = list.find((item) => (item.id === id)) as AccountingEntry
    return `${dateFormatLocalized(lang.code, entry.date)}, ${treasuryAccountSelected(lang, sub_list, entry.debits[0].reference.id_treasury_account)}, ${currency.symbol} ${moneyFormat(entry.balance)}`
  } else {
    return lang.labels.undefined
  }
}

export function contentReferenceSelected(lang: Language, list: InventoryReference[], id: string, type: string): string {
  if (list.length > 0 && (list.find((item) => (type === 'service' && item.id_service === id)) || (list.find((item) => (type === 'product' && item.id_variant === id))))) {
    let contentReference = ((type === 'service') ? list.find(item => item.id_service === id) : list.find(item => item.id_variant === id)) as InventoryReference
    return `${(contentReference.code.length > 0) ? `${contentReference.code}, ` : ''}${contentReference.display_name}${(contentReference.type === 'product') ? ` ${contentReference.description}` : ''}`
  } else {
    return lang.labels.undefined
  }
}
