import React, { useEffect, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { Link } from 'react-router-dom';
import { Dropdown, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { UseOutletContextContact } from './Contact';
import { StateLanguage } from '../../../languages/config/StateLanguage';
import { getError, getMessage } from '../../../languages/translations/response';
import { ContactDA } from '../../../services/contact.service';
import { Error } from '../../../models/error.model';
import { Message } from '../../../models/message.model';
import { Company } from '../../../models/company.model';
import { CompanyContacts } from '../../../models/company.contacts.model';
import { Contact } from '../../../models/contact.model';
import { Container } from '../../../styles/container.style';
import { modalShow } from '../../../tools/modal.tool';
import { imageCompany } from '../../../scripts/image.value.script';
import { contactTypeList, contactTypeValue } from '../../../libraries/contact.type.library';
import { countryName } from '../../../libraries/country.library';
import { removeTooltip, uploadTooltip } from '../../../tools/tooltip.tool';
import { sortValue } from '../../../libraries/sort.library';

import AppModalContactExport from '../../../components/modal/ModalContactExport';
import AppModalContactUpdate from '../../../components/modal/ModalContactUpdate';
import AppPagination from '../../../components/element/Pagination';
import AppStorageRemove from '../../../components/element/StorageRemove';
import AppPreloader from '../../../components/Preloader';

export interface ContactListPageProps {};

let errorResponse: Error, messageResponse: Message, contactResponse: Contact, contactsResponse: CompanyContacts;

const ContactListPage: React.FunctionComponent<ContactListPageProps> = props => {
  const {setRoute, userLoggedIn, companyForUser, contactsForCompany, loadContactsForCompany} = UseOutletContextContact()
  const {lang} = StateLanguage()
  const navigate = useNavigate()

  const [mounted, setMounted] = useState(false)
  const [button, setButton] = useState({filter: false})
  const [filter, setFilter] = useState({search: '', options: {country: '', type: '', sort: ''}})
  const [checked, setChecked] = useState({count: 0, list: [] as string[]})
  const [pagination, setPagination] = useState({current: 0, total: 0, pages: [] as number[], limits: {min: 0, max: 0}})
  const [contacts, setContacts] = useState<Contact[] | undefined | null>(null)
  const [countriesOfContacts, setCountriesOfContacts] = useState<string[] | undefined | null>(null)
  const [companiesOfContact, setCompaniesOfContact] = useState<Company[] | undefined | null>(null)
  const [contact, setContact] = useState<Contact | undefined | null>(null)
  const [storage, setStorage] = useState<any>(null)

  const loadContacts = async (id_company: string, callback: any) => {
    let filtered: boolean = false
    let totalPages: number = 1
    let totalByPage: number = 10
    let pagesOfPagination: number[] = []

    await ContactDA.getContacts(id_company, filter.search, filter.options.country, filter.options.type, filter.options.sort).then( (response) => {
      if (response.status === 200) {
        contactsResponse = response.data

        localStorage.setItem('filter_contacts', JSON.stringify({ 
          company_id: contactsResponse.id_company, 
          country: contactsResponse.filter.country, 
          type: contactsResponse.filter.type, 
          sort: contactsResponse.filter.sort
        }))

        filtered = (contactsResponse.filter.country === '' && contactsResponse.filter.type === '' && contactsResponse.filter.sort === '') ? false : true
        totalPages = (contactsResponse.contacts.length % totalByPage === 0) ? Math.floor(contactsResponse.contacts.length / totalByPage) : Math.floor(contactsResponse.contacts.length / totalByPage) + 1

        for (let i = 1; i <= totalPages; i++) {
          pagesOfPagination.push(i)
        }

        setContacts(contactsResponse.contacts)
        setButton({filter: filtered})
        setChecked({count: 0, list: []})
        setPagination({current: 1, total: totalByPage, pages: pagesOfPagination, limits: {min: 0, max: totalByPage}})
        setStorage((localStorage.getItem('filter_contacts')) ? JSON.parse(localStorage.getItem('filter_contacts')!) : null)
        callback()
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.code),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.okGotIt,
          customClass: {confirmButton: 'btn btn-primary'}
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      navigate('/error')
    })
  }

  const submitContactUpdate = async (item: string) => {
    modalShow('modal-contact-update')

    if (userLoggedIn && companyForUser) {
      await ContactDA.getContact(companyForUser.id, item).then( (response) => {
        if (response.status === 200) {
          contactResponse = response.data

          let tempCompaniesOfContact: Company[] = userLoggedIn.companies.filter((company) => (contactResponse.companies.includes(company.id)))

          setContact(contactResponse)
          setCompaniesOfContact(tempCompaniesOfContact)
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.code),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions)
        }
      }).catch( (error) => {
        console.error(error)
        navigate('/error')
      }) 
    }
  }

  const submitContactDelete = async (item: string) => {
    if (companyForUser) {
      Swal.fire({
        title: lang.labels.youWantToDeleteThisContact,
        text: lang.labels.youWillNotBeAbleToUndo,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yesDelete,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary'}
      }).then(async (result) => {
        if (result.isConfirmed) {
          await ContactDA.deleteContacts(companyForUser.id, [item]).then( (response) => {
            if (response.status === 200) {
              messageResponse = response.data

              loadContacts(companyForUser.id, () => {
                loadContactsForCompany(companyForUser.id)

                Swal.fire({
                  title: getMessage(messageResponse.message, lang.code),
                  text: lang.labels.actionCompletedReturningToPage,
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1800
                } as SweetAlertOptions)
              })
            } else {
              errorResponse = response.data

              Swal.fire({
                title: getError(errorResponse.code, lang.code),
                text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
                icon: 'error',
                buttonsStyling: !1,
                confirmButtonText: lang.labels.okGotIt,
                customClass: {confirmButton: 'btn btn-primary'}
              } as SweetAlertOptions)
            }
          }).catch( (error) => {
            console.error(error)
            navigate('/error')
          })
        }
      })
    }
  }

  const submitContactDeleteList = async () => {
    if (companyForUser) {
      Swal.fire({
        title: lang.labels.youWantToDeleteSelectedContacts,
        text: lang.labels.youWillNotBeAbleToUndo,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yesDelete,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary'}
      }).then(async (result) => {
        if (result.isConfirmed) {
          await ContactDA.deleteContacts(companyForUser.id, checked.list).then( (response) => {
            if (response.status === 200) {
              messageResponse = response.data

              loadContacts(companyForUser.id, () => {
                loadContactsForCompany(companyForUser.id)

                Swal.fire({
                  title: getMessage(messageResponse.message, lang.code),
                  text: lang.labels.actionCompletedReturningToPage,
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1800
                } as SweetAlertOptions)
              })
            } else {
              errorResponse = response.data

              Swal.fire({
                title: getError(errorResponse.code, lang.code),
                text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
                icon: 'error',
                buttonsStyling: !1,
                confirmButtonText: lang.labels.okGotIt,
                customClass: {confirmButton: 'btn btn-primary'}
              } as SweetAlertOptions)
            }
          }).catch( (error) => {
            console.error(error)
            navigate('/error')
          })
        }
      })
    }
  }

  const handleChangeSelectAllContacts = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let list: string[] = (event.target.checked) ? event.target.value.split(',') : []
    setChecked({...checked, count: list.length, list: list})
  }

  const handleChangeSelectContact = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let list: string[] = (event.target.checked) ? (!checked.list.includes(event.target.value)) ? checked.list.concat(event.target.value) : checked.list : checked.list.filter((item) => (item !== event.target.value))
    setChecked({...checked, count: list.length, list: list})
  }

  const handleChangeSort = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setFilter({...filter, options: {...filter.options, sort: event.target.value}})
  }

  const handleChangeCountry = (event: React.ChangeEvent <HTMLFormElement | HTMLSelectElement>) => {
    setFilter({...filter, options: {...filter.options, country: event.target.value,}})
  }

  const handleChangeType = (event: React.ChangeEvent <HTMLFormElement | HTMLSelectElement>) => {
    setFilter({...filter, options: {...filter.options, type: event.target.value}})
  }

  const handleChangeSearch = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    if (companyForUser) {
      filter.search = event.target.value
      filter.options = {country: '', type: '', sort: ''}
      loadContacts(companyForUser.id, () => {})
    }
  }

  const executeFilterApply = () => {
    if (companyForUser) {
      filter.search = ''
      loadContacts(companyForUser.id, () => {})
    }
  }

  const executeFilterReset = () => {
    if (companyForUser) {
      filter.options = {country: '', type: '', sort: ''}
      loadContacts(companyForUser.id, () => {})
    }
  }

  const executeFilterRemove = (item: string) => {
    if (companyForUser && (filter.options.country.length !== 0 || filter.options.type.length !== 0 || filter.options.sort.length !== 0)) {
      for (let key of Object.keys(filter.options) as Array<keyof typeof filter.options>) {
        if (key === item) {
          filter.options[key] = ''
        }
      }
      loadContacts(companyForUser.id, () => {})
    }
  }

  const executeShowModalContactExport = () => {
    modalShow('modal-contact-export')
  }

  function websitename(website: string): string {
    let url = website.startsWith("http") ? website : `https://${website}`
    let hostname = new URL(url).hostname
    let parts = hostname.split(".")

    return (hostname.split(".").length < 2) ? lang.labels.invalid : parts.slice(-2).join(".")
  }

  useEffect( () => {
    let filter_contacts: any = (localStorage.getItem('filter_contacts')) ? JSON.parse(localStorage.getItem('filter_contacts')!) : null

    if (companyForUser) {
      filter.search = ''
      filter.options.country = (filter_contacts && filter_contacts.company_id && companyForUser.id) ? filter_contacts.country : ''
      filter.options.type = (filter_contacts && filter_contacts.company_id && companyForUser.id) ? filter_contacts.type : ''
      filter.options.sort = (filter_contacts && filter_contacts.company_id && companyForUser.id) ? filter_contacts.sort : ''
      checked.count = 0
      checked.list = []
      pagination.current = 0
      pagination.total = 0
      pagination.pages = []
      pagination.limits.min = 0
      pagination.limits.max = 0
      button.filter = false

      setContacts(null)
      loadContacts(companyForUser.id, () => {})
      uploadTooltip()
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyForUser])

  useEffect( () => {
    setCountriesOfContacts(null)

    if (contactsForCompany) {
      let tempCountriesOfContacts: string[] = []
        for (let contact of contactsForCompany) {
          if (!tempCountriesOfContacts.includes(contact.data.address.country)) {
            tempCountriesOfContacts.push(contact.data.address.country)
          }
        }

        setCountriesOfContacts(tempCountriesOfContacts)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactsForCompany])

  useEffect( () => {
    setMounted(true)
    setRoute({path: {root: lang.labels.contacts, branch: lang.labels.contactsList}, company: true})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { contactsForCompany && countriesOfContacts && contacts
        ?
        <div className="card card card-flush">
          <div className="card-header">
            <div className="card-title">
              <div className="d-flex align-items-center position-relative">
                <span className="svg-icon svg-icon-1 position-absolute ms-4">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black" />
                    <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black" />
                  </svg>
                </span>
                <input className="form-control form-control-solid w-350px ps-14" type="text" placeholder={lang.labels.searchContact} value={filter.search} onChange={handleChangeSearch} />
              </div>
            </div>
            <div className="card-toolbar">
              <Container property={(checked.count === 0).toString()}>
                <div className="d-flex justify-content-end align-items-center">
                  <Dropdown>
                    <Dropdown.Toggle variant={`btn ${button.filter ? "btn-primary" : "btn-light-primary"} me-2`}>
                      <div className="d-flex align-items-center">
                        <span className="svg-icon svg-icon-2 ms-0">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z" fill="black" />
                          </svg>
                        </span>
                        {lang.labels.filter}
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="menu menu-sub menu-sub-dropdown w-300px w-md-325px" align="end">
                      <div className="mt-5 mx-5">
                        <div className="fs-5 text-dark fw-bolder">{lang.labels.filterOptions}</div>
                      </div>
                      <div className="separator border-gray-200"></div>
                      <div className="mt-3 m-5">
                        <div className="mb-3">
                          <label className="form-label fs-7">{lang.labels.sortAlphabetically}:</label>
                          <div className="d-flex row mx-0">
                            <label className="col-6 form-check form-check-sm form-check-custom form-check-solid">
                              <input className="form-check-input" type="radio" value="asc" checked={filter.options.sort === 'asc'} onChange={handleChangeSort} />
                              <span className="form-check-label fw-bold fs-8">{lang.labels.ascending}</span>
                            </label>
                            <label className="col-6 form-check form-check-sm form-check-custom form-check-solid">
                              <input className="form-check-input" type="radio" value="desc" checked={filter.options.sort === 'desc'} onChange={handleChangeSort} />
                              <span className="form-check-label fw-bold fs-8">{lang.labels.descending}</span>
                            </label>
                          </div>
                        </div>
                        <div className="mb-3">
                          <label className="form-label fs-7">{lang.labels.countryContact}:</label>
                          <Form.Select bsPrefix="form-select form-select-sm form-select-solid fw-bold" placeholder={lang.labels.selectOption} name="country" value={filter.options.country} onChange={handleChangeCountry}>
                            <option value="" className={`${ countriesOfContacts.length > 0 && "d-none"}`}>{lang.labels.selectOption}</option>
                            { countriesOfContacts.map (( (item, index) => { return (
                              <option key={index} value={item}>{countryName(lang.code, item)}</option>
                            )}))}
                          </Form.Select>
                        </div>
                        <div className="mb-5">
                          <label className="form-label fs-7">{lang.labels.typeOfContact}:</label>
                          <Form.Select bsPrefix="form-select form-select-sm form-select-solid fw-bold" placeholder={lang.labels.selectOption} name="type" value={filter.options.type} onChange={handleChangeType}>
                            <option value="" className={`${contactsForCompany.length > 0 && "d-none"}`}>{lang.labels.selectOption}</option>
                            { contactsForCompany.length > 0 &&
                              <>
                                { contactTypeList(lang).map (( (item, index) => { return (
                                  <option key={index} value={item.code}>{item.display}</option>
                                )}))}
                              </>
                            }
                          </Form.Select>
                        </div>
                        <div className="d-flex justify-content-end">
                          <Dropdown.Item bsPrefix="btn btn-sm btn-light btn-active-light-primary" type="reset" onClick={executeFilterReset}>{lang.labels.reset}</Dropdown.Item>
                          <Dropdown.Item bsPrefix="btn btn-sm btn-primary ms-3" type="button" onClick={executeFilterApply}>{lang.labels.apply}</Dropdown.Item>
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                  <button className="btn btn-primary" type="button" onClick={executeShowModalContactExport}>
                    <div className="d-flex align-items-center">
                      <span className="svg-icon svg-icon-2 ms-0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <rect opacity="0.3" x="12.75" y="4.25" width="12" height="2" rx="1" transform="rotate(90 12.75 4.25)" fill="black" />
                          <path d="M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z" fill="black" />
                          <path d="M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z" fill="#C4C4C4" />
                        </svg>
                      </span>
                      {lang.labels.export}
                    </div>
                  </button>
                </div>
              </Container>
              <Container property={(checked.count > 0).toString()}>
                <div className="d-flex justify-content-end align-items-center">
                  <div className="fw-bolder me-5">
                    <span className="text-lowercase me-2">{checked.count}</span>
                    {lang.labels.selected}
                  </div>
                  <button className="btn btn-danger" type="button" onClick={submitContactDeleteList}>{lang.labels.deleteSelected}</button>
                </div>
              </Container>
            </div>
          </div>
          { storage && button.filter &&
            <div className="card-scroll d-flex bg-light-primary rounded border-0 p-3 mx-7 mb-4">
              <button className={`btn btn-sm btn-danger text-nowrap fs-8 px-2 py-1 mx-1 ${storage.country.length === 0 && "d-none"}`} type="button" onClick={() => executeFilterRemove("country")}>
                <AppStorageRemove attribute={lang.labels.country} description={countryName(lang.code, storage.country)}></AppStorageRemove>
              </button>
              <button className={`btn btn-sm btn-danger text-nowrap fs-8 px-2 py-1 mx-1 ${storage.type.length === 0 && "d-none"}`} type="button" onClick={() => executeFilterRemove("type")}>
                <AppStorageRemove attribute={lang.labels.type} description={contactTypeValue(lang, storage.type)}></AppStorageRemove>
              </button>
              <button className={`btn btn-sm btn-danger text-nowrap fs-8 px-2 py-1 mx-1 ${storage.sort.length === 0 && "d-none"}`} type="button" onClick={() => executeFilterRemove("sort")}>
                <AppStorageRemove attribute={lang.labels.sortAlphabetically} description={sortValue(lang, storage.sort)}></AppStorageRemove>
              </button>
            </div>
          }
          <div className="card-body pt-0">
            <div className="dataTables_wrapper">
              <div className="table-responsive">
                <table className="table align-middle table-row-dashed mb-2">
                  <thead className="border-bottom">
                    <tr className="text-start text-muted text-uppercase fw-bolder fs-7">
                      <th className="w-25px sorting_disabled">
                        <div className="form-check form-check-sm form-check-custom form-check-solid">
                          <input className="form-check-input" type="checkbox" value={contacts.map(item => item.data.id)} checked={contacts.length === checked.count && checked.count > 0} onChange={handleChangeSelectAllContacts} />
                        </div>
                      </th>
                      <th className="min-w-300px text-nowrap sorting">{lang.labels.user}</th>
                      <th className="min-w-100px text-nowrap sorting">{lang.labels.type}</th>
                      <th className="min-w-100px text-nowrap sorting">{lang.labels.webSite}</th>
                      <th className="min-w-100px text-nowrap sorting">{lang.labels.phone}</th>
                      <th className="min-w-100px text-nowrap sorting">{lang.labels.email}</th>
                      <th className="w-75px text-nowrap sorting_disabled text-end">{lang.labels.actions}</th>
                    </tr>
                  </thead>
                  { contacts.length > 0
                    ?
                    <tbody className="text-gray-700 fw-bold">
                      { contacts.map (( (item, index) => { return (
                        <tr key={index} className="row-dashed">
                          <td>
                            <div className="form-check form-check-sm form-check-custom form-check-solid">
                              <input className="form-check-input" type="checkbox" value={item.data.id} checked={checked.list.includes(item.data.id)} onChange={handleChangeSelectContact} />
                            </div>
                          </td>
                          <td className="d-flex align-items-center">
                              <div className="symbol symbol-circle symbol-50px overflow-hidden me-5">
                                <Link to={"/app/contact/view/" + item.data.id}>
                                  <div className="symbol-label fs-1 bg-light-primary text-primary">
                                    { item.type === "person" &&
                                      item.data.last_name.charAt(0)
                                    }
                                    { item.type === "company" &&
                                      <>
                                        { item.data.logo.length === 0
                                          ?
                                          item.data.name.charAt(0)
                                          :
                                          <img src={imageCompany(item.data.logo)} alt={lang.labels.avatar} className="w-100 h-100" />
                                        }
                                      </>
                                    }
                                  </div>
                                </Link>
                              </div>
                              <div className="d-flex flex-column">
                                <Link to={"/app/contact/view/" + item.data.id} className="fw-bolder text-dark text-hover-primary mb-1">{item.type === "person" ? `${item.data.first_name} ${item.data.last_name}` : `${item.data.name}`}</Link>
                                <small>{item.data.address.city}, {countryName(lang.code, item.data.address.country)}</small>
                              </div>
                          </td>
                          <td className="text-capitalize">
                            <span className="badge badge-light fs-7">{contactTypeValue(lang, item.type)}</span>
                          </td>
                          <td>
                            { item.data.website.length > 0
                              ?
                              websitename(item.data.website)
                              :
                              <small>{lang.labels.none}</small>
                            }
                          </td>
                          <td>{item.data.phone}</td>
                          <td>{item.data.email}</td>
                          <td className="text-end">
                            <div className="d-flex justify-content-end">
                              <Link to={"/app/contact/entry/" + item.data.id} className="btn btn-sm btn-icon btn-light btn-active-light-primary" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="left" data-bs-original-title={lang.labels.accountStatement} onClick={removeTooltip}>
                                <span className="svg-icon svg-icon-3">
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="8" y="9" width="3" height="10" rx="1.5" fill="black"/>
                                    <rect opacity="0.5" x="13" y="5" width="3" height="14" rx="1.5" fill="black"/>
                                    <rect x="18" y="11" width="3" height="8" rx="1.5" fill="black"/>
                                    <rect x="3" y="13" width="3" height="6" rx="1.5" fill="black"/>
                                  </svg>
                                </span>
                              </Link>
                              <Dropdown>
                                <Dropdown.Toggle variant="btn btn-sm btn-icon btn-light btn-active-light-primary ms-2" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="left" data-bs-original-title={lang.labels.options}>
                                  <span className="svg-icon svg-icon-3">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="4" fill="black"/>
                                      <rect x="11" y="11" width="2.6" height="2.6" rx="1.3" fill="black"/>
                                      <rect x="15" y="11" width="2.6" height="2.6" rx="1.3" fill="black"/>
                                      <rect x="7" y="11" width="2.6" height="2.6" rx="1.3" fill="black"/>
                                    </svg>
                                  </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu variant="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary p-3 fs-7" align="end">
                                  <Dropdown.Item bsPrefix="menu-item" onClick={() => submitContactUpdate(item.data.id)}>
                                    <p className="menu-link fw-bold px-3 py-1 m-0">{lang.labels.edit}</p>
                                  </Dropdown.Item>
                                  <Dropdown.Item bsPrefix="menu-item" onClick={() => submitContactDelete(item.data.id)}>
                                    <p className="menu-link fw-bold px-3 py-1 m-0">{lang.labels.delete}</p>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </td>
                        </tr>
                      )}))}
                    </tbody>
                    :
                    <tbody>
                      <tr className="text-gray-700 fw-bold">
                        <td valign="top" colSpan={7} className="text-center">{lang.labels.noRecordsFound}</td>
                      </tr>
                    </tbody>
                  }
                </table>
              </div>
              <AppPagination property={{total_items: contacts.length, show_options: false}} pagination={pagination} setPagination={setPagination}></AppPagination>
            </div>
          </div>
        </div>
        :
        <AppPreloader></AppPreloader>
      }
      <AppModalContactExport companyForUser={companyForUser} contacts={contacts}></AppModalContactExport>
      <AppModalContactUpdate companyForUser={companyForUser} contact={contact} contacts={contacts} companiesOfContact={companiesOfContact} setContact={setContact} setCompaniesOfContact={setCompaniesOfContact} setContacts={setContacts} loadContactsForCompany={loadContactsForCompany}></AppModalContactUpdate>
    </div>
  )
};

export default ContactListPage;
