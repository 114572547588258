import { Language } from "../languages/labels/language.labels";

export function taxMethodCompanyList(lang: Language): {code: string, display: string}[] {
  return [
    { code: 'effective', display: lang.labels.effective },
    { code: 'ntdr', display: lang.labels.ntdr },
    { code: 'taf', display: lang.labels.taf }
  ]
}

export function taxMethodCompanyValue(lang: Language, code: string): string {
  let contactType = taxMethodCompanyList(lang).find((item) => (item.code === code))
  return contactType ? contactType.display : lang.labels.undefined
}
