import React, { useEffect, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { Dropdown } from 'react-bootstrap';
import { FaExclamationCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { AccountingEntryDA } from '../../services/accounting.entry.service';
import { Error } from '../../models/error.model';
import { TreasuryAccount } from '../../models/treasury.account.model';
import { Company } from '../../models/company.model';
import { AccountingEntry } from '../../models/accounting.entry.model';
import { AccountingAccount } from '../../models/accounting.account.model';
import { Message } from '../../models/message.model';
import { legendInvalid, legendInvalidIcon, legendValid, legendValidInvalidRestart, legendValidInvalidIconRestart } from '../../tools/legend.data.entry.tool';
import { modalHide } from '../../tools/modal.tool';
import { uploadTooltip } from '../../tools/tooltip.tool';
import { moneyFormat } from '../../scripts/format.value.script';
import { evaluateLegendValidRequiredIcon } from '../../scripts/validate.legend.script';
import { dateFormatLocalized } from '../../scripts/datetime.format.script';
import { accountingAccountSelected, treasuryAccountSelected } from '../../scripts/list.selected.item.script';
import { paymentMethodList, paymentMethodValue } from '../../libraries/payment.library';
import { expressions } from '../../libraries/regular.expressions.library';
import { getError, getMessage } from '../../languages/translations/response';

import AppLegend from '../element/Legend';

export interface AppModalContactEntryCreateProps {
  companyForUser: Company | undefined | null,
  accountingAccountsForCompany: AccountingAccount[] | undefined | null,
  treasuryAccountsForCompany: TreasuryAccount[] | undefined | null,
  accountingUnidentifiedEntries: AccountingEntry[] | undefined | null,
  contact: string | undefined | null,
  loadAccountingUnidentifiedEntries: Function,
  loadAccountingContactEntries: Function
};

let errorResponse: Error, messageResponse: Message;

const AppModalContactEntryCreate: React.FunctionComponent<AppModalContactEntryCreateProps> = ({companyForUser, accountingAccountsForCompany, treasuryAccountsForCompany, accountingUnidentifiedEntries, contact, loadAccountingUnidentifiedEntries, loadAccountingContactEntries}) => {
  const {lang} = StateLanguage()
  const navigate = useNavigate()

  const [loadIndicator, setLoadIndicator] = useState('off')
  const [amount, setAmount] = useState({value: '', valid: false})
  const [treasury, setTreasury] = useState({value: '', valid: false})
  const [method, setMethod] = useState({value: '', valid: false})
  const [date, setDate] = useState({value: '', valid: false, starting: true})
  const [entry, setEntry] = useState({value: '', valid: false, object: null as any})

  const submitAccountingEntryCreate = async () => {
    setLoadIndicator('on')

    let isValidTransaction: boolean = (entry.valid) ? true : (amount.valid && date.valid && treasury.valid) ? true : false

    if (companyForUser && contact && isValidTransaction) {
      if (entry.valid) {
        await AccountingEntryDA.putAccountingEntry(companyForUser.id, entry.value, contact).then( (response) => {
          if (response.status === 200) {
            messageResponse = response.data

            loadAccountingUnidentifiedEntries(companyForUser.id)
            loadAccountingContactEntries(companyForUser.id, contact)

            Swal.fire({
              title: getMessage(messageResponse.message, lang.code),
              text: lang.labels.actionCompletedReturningToPage,
              icon: 'success',
              showConfirmButton: false,
              timer: 1800
            } as SweetAlertOptions).then( () => {
              setLoadIndicator('off')
              executeHideModalPaymentCreate()
            })
          } else {
            errorResponse = response.data

            Swal.fire({
              title: getError(errorResponse.code, lang.code),
              text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
              icon: 'error',
              buttonsStyling: !1,
              confirmButtonText: lang.labels.okGotIt,
              customClass: {confirmButton: 'btn btn-primary'}
            } as SweetAlertOptions).then( () => {
              setLoadIndicator('off')
            })
          }
        }).catch( (error) => {
          console.error(error)
          navigate('/error')
        })
      } else {
        await AccountingEntryDA.postAccountingEntry(companyForUser.id, contact, treasury.value, method.value, date.value, amount.value).then( (response) => {
          if (response.status === 201) {
            messageResponse = response.data

            loadAccountingUnidentifiedEntries(companyForUser.id)
            loadAccountingContactEntries(companyForUser.id, contact)

            Swal.fire({
              title: getMessage(messageResponse.message, lang.code),
              text: lang.labels.actionCompletedReturningToPage,
              icon: 'success',
              showConfirmButton: false,
              timer: 1800
            } as SweetAlertOptions).then( () => {
              setLoadIndicator('off')
              executeHideModalPaymentCreate()
            })
          } else {
            errorResponse = response.data

            Swal.fire({
              title: getError(errorResponse.code, lang.code),
              text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
              icon: 'error',
              buttonsStyling: !1,
              confirmButtonText: lang.labels.okGotIt,
              customClass: {confirmButton: 'btn btn-primary'}
            } as SweetAlertOptions).then( () => {
              setLoadIndicator('off')
            })
          }
        }).catch( (error) => {
          console.error(error)
          navigate('/error')
        })
      }
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        if (entry.value.length === 0 && amount.value.length === 0) {
          legendInvalidIcon('modal-contact-entry-create-input-amount-debit', 'modal-contact-entry-create-legend-amount-debit-required')
        }
        if (entry.value.length === 0 && treasury.value.length === 0) {
          legendInvalid('modal-contact-entry-create-legend-treasury-required')
        }
        if (entry.value.length === 0 && method.value.length === 0) {
          legendInvalid('modal-contact-entry-create-legend-method-required')
        }
        if (entry.value.length === 0 && date.value.length === 0) {
          legendInvalid('modal-contact-entry-create-legend-date-required')
        }
        if (entry.value.length === 0 && amount.value.length === 0 && treasury.value.length === 0 && method.value.length === 0 && date.value.length === 0) {
          legendInvalid('modal-contact-entry-create-legend-entry-required')
        }
        setLoadIndicator('off')
      })
    }
  }

  const handleChangeAmount = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setAmount({...amount, value: event.target.value})
    setEntry({value: '', valid: false, object: null})

    legendValidInvalidRestart('modal-contact-entry-create-legend-entry-required')
  }

  const handleChangeDate = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    if (event.target.value.length > 0) {
      setDate({...date, value: event.target.value, valid: true})
      legendValid('modal-contact-entry-create-legend-date-required')
    } else {
      setDate({...date, value: event.target.value, valid: false})
      legendInvalid('modal-contact-entry-create-legend-date-required')
    }

    setEntry({value: '', valid: false, object: null})
    legendValidInvalidRestart('modal-contact-entry-create-legend-entry-required')
  }

  const handleChangeEntry = (item: AccountingEntry) => {
    setEntry({value: item.id, object: item, valid: true})
    legendValid('modal-contact-entry-create-legend-entry-required')

    setAmount({value: '', valid: false})
    setTreasury({value: '', valid: false})
    setMethod({value: '', valid: false})
    setDate({value: '', valid: false, starting: true})

    legendValidInvalidIconRestart('modal-contact-entry-create-input-amount', 'modal-contact-entry-create-legend-amount-valid')
    legendValidInvalidIconRestart('modal-contact-entry-create-input-amount', 'modal-contact-entry-create-legend-amount-required')
    legendValidInvalidRestart('modal-contact-entry-create-legend-treasury-required')
    legendValidInvalidRestart('modal-contact-entry-create-legend-method-required')
    legendValidInvalidRestart('modal-contact-entry-create-legend-date-required')
  }

  const handleChangeTreasury = (item: string) => {
    setTreasury({value: item, valid: true})
    setEntry({value: '', valid: false, object: null})

    legendValid('modal-contact-entry-create-legend-treasury-required')
    legendValidInvalidRestart('modal-contact-entry-create-legend-entry-required')
  }

  const handleChangeMethod = (item: string) => {
    setMethod({value: item, valid: true})
    setEntry({value: '', valid: false, object: null})

    legendValid('modal-contact-entry-create-legend-method-required')
    legendValidInvalidRestart('modal-contact-entry-create-legend-entry-required')
  }

  const validateAmount = () => {
    evaluateLegendValidRequiredIcon(expressions.currency, amount, setAmount, 'modal-contact-entry-create-input-amount', 'modal-contact-entry-create-legend-amount-valid', 'modal-contact-entry-create-legend-amount-required')
  }

  const executeHideModalPaymentCreate = () => {
    modalHide('modal-contact-entry-create')

    setTimeout( () => {
      restartModal()
    }, 200 )
  }

  function datePayment(): boolean {
    switch (true) {
      case (date.starting && !date.valid && date.value === ''):
        setDate({value: format((new Date()), 'yyyy-MM-dd'), valid: true, starting: false})
        return true
      case (date.valid && date.value.length > 0):
        return true
      default:
        return false
    }
  }

  function maxDate(): string {
    return format((new Date()), 'yyyy-MM-dd')
  }

  function restartModal() {
    legendValidInvalidIconRestart('modal-contact-entry-create-input-amount', 'modal-contact-entry-create-legend-amount-valid')
    legendValidInvalidIconRestart('modal-contact-entry-create-input-amount', 'modal-contact-entry-create-legend-amount-required')
    legendValidInvalidRestart('modal-contact-entry-create-legend-treasury-required')
    legendValidInvalidRestart('modal-contact-entry-create-legend-method-required')
    legendValidInvalidRestart('modal-contact-entry-create-legend-date-required')
    legendValidInvalidRestart('modal-contact-entry-create-legend-entry-required')

    setAmount({value: '', valid: false})
    setTreasury({value: '', valid: false})
    setMethod({value: '', valid: false})
    setDate({value: '', valid: false, starting: true})
    setEntry({value: '', valid: false, object: null})
  }

  useEffect(() => {
    uploadTooltip()
  }, [companyForUser, accountingAccountsForCompany, treasuryAccountsForCompany, accountingUnidentifiedEntries])

  return (
    <div id="modal-contact-entry-create" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="text-capitalize">{lang.labels.createClientCredit}</h2>
          </div>
          <div className="modal-body">
            { companyForUser && accountingAccountsForCompany && treasuryAccountsForCompany && accountingUnidentifiedEntries
              ?
              <div className="form">
                <div className="d-flex flex-column flex-xl-row">
                  <div className="col-xl-6 mb-5 mb-xl-0">
                    <div className="h-100 m-0">
                      <div className="border border-dashed border-gray-300 card-rounded bg-lighten h-100 p-5">
                        <h6 className="text-dark text-center text-uppercase mb-3">{lang.labels.enterNewTransaction}</h6>
                        <div className="fv-row mb-3">
                          <label className="form-label">
                            <div className="d-flex align-items-center">
                              <span className="required">{lang.labels.depositAmount}</span>
                              <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.amountMoneyEnteredInTransaction}><FaExclamationCircle /></i>
                            </div>
                          </label>
                          <div className="position-relative">
                            <div className="position-absolute translate-middle-y top-50 start-0 form-control bg-transparent border-0 border-end rounded-0 rounded-start fw-bolder text-center w-60px h-auto px-2">{companyForUser.currency.symbol}</div>  
                            <input id="modal-contact-entry-create-input-amount" className="form-control ps-20" type="text" name="amount" placeholder="0.00" value={amount.value} onChange={handleChangeAmount} onKeyUp={validateAmount} onBlur={validateAmount} />
                          </div>
                          <AppLegend component={"modal-contact-entry-create"} attribute={{validity: amount.valid, name: "amount", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                        </div>
                        <div className="fv-row mb-3">
                          <label className="form-label">
                            <div className="d-flex align-items-center">
                              <span className="required">{lang.labels.treasuryAccount}</span>
                              <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.sourceTheAmount}><FaExclamationCircle /></i>
                            </div>
                          </label>
                          <Dropdown>
                            <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                              <span className="selection">
                                <span className="select2-selection select2-selection--single form-select">
                                  {treasury.valid ? treasuryAccountSelected(lang, treasuryAccountsForCompany, treasury.value) : lang.labels.selectOption}
                                </span>
                              </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                              <span className="select2-dropdown select2-dropdown--below">
                                <span className="select2-results">
                                  <ul className="select2-results__options" role="listbox">
                                    <li className={`select2-results__option select2-results__option--selectable ${treasuryAccountsForCompany.length > 0 && "d-none"}`} role="option" aria-selected={treasuryAccountsForCompany.length === 0}>
                                      <Dropdown.Item bsPrefix="select2-results__option__text">{lang.labels.selectOption}</Dropdown.Item>
                                    </li>
                                    { treasuryAccountsForCompany.map (( (item, index) => { return (
                                      <li key={index} className={`select2-results__option select2-results__option--selectable ${item.id === treasury.value && "select2-results__option--selected"}`} role="option" aria-selected={item.id === treasury.value}>
                                        <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeTreasury(item.id)}>{item.name}</Dropdown.Item>
                                      </li>
                                    )}))}
                                  </ul>
                                </span>
                              </span>
                            </Dropdown.Menu>
                          </Dropdown>
                          <AppLegend component={"modal-contact-entry-create"} attribute={{validity: treasury.valid, name: "treasury", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 fv-row mb-3 mb-lg-0 d-none">
                            <label className="form-label required">{lang.labels.paymentMethod}</label>
                            <Dropdown>
                              <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                                <span className="selection">
                                  <span className="select2-selection select2-selection--single form-select">
                                    {method.valid ? paymentMethodValue(lang, method.value) : lang.labels.selectOption}
                                  </span>
                                </span>
                              </Dropdown.Toggle>
                              <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                                <span className="select2-dropdown select2-dropdown--below">
                                  <span className="select2-results">
                                    <ul className="select2-results__options" role="listbox">
                                      { paymentMethodList(lang).map (( (item, index) => { return (
                                        <li key={index} className={`select2-results__option select2-results__option--selectable ${item.code === method.value && "select2-results__option--selected"}`} role="option" aria-selected={item.code === method.value}>
                                          <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeMethod(item.code)}>{item.display}</Dropdown.Item>
                                        </li>
                                      )}))}
                                    </ul>
                                  </span>
                                </span>
                              </Dropdown.Menu>
                            </Dropdown>
                            <AppLegend component={"modal-contact-entry-create"} attribute={{validity: method.valid, name: "method", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                          </div>
                          <div className="col-lg-12 fv-row">
                            <label className="form-label required">{lang.labels.paymentDate}</label>
                            <input className="form-control" type="date" name="date" max={maxDate()} value={date.value} onChange={handleChangeDate} />
                            <AppLegend component={"modal-contact-entry-create"} attribute={{validity: datePayment(), name: "date", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="h-100 m-0 ps-xl-5">
                      <div className="border border-dashed border-gray-300 card-rounded bg-lighten h-100 p-5">
                        <h6 className="text-dark text-center text-uppercase mb-3">{lang.labels.selectExistingTransaction}</h6>
                        <div className="fv-row mb-5">
                          <label className="form-label required">{lang.labels.transaction}</label>
                          <Dropdown>
                            <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                              <span className="selection">
                                <span className="select2-selection select2-selection--single form-select">
                                  {entry.valid ? `${dateFormatLocalized(lang.code, entry.object.date)}, ${treasuryAccountSelected(lang, treasuryAccountsForCompany, entry.object.debits[0].reference.id_treasury_account)}, ${companyForUser.currency.symbol} ${moneyFormat(entry.object.balance)}` : lang.labels.selectOption}
                                </span>
                              </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                              <span className="select2-dropdown select2-dropdown--below">
                                <span className="select2-results">
                                  <ul className="select2-results__options" role="listbox">
                                    <li className={`select2-results__option select2-results__option--selectable ${accountingUnidentifiedEntries.length > 0 && "d-none"}`}>
                                      <Dropdown.Item bsPrefix="select2-results__option__text">{lang.labels.selectOption}</Dropdown.Item>
                                    </li>
                                    { accountingUnidentifiedEntries.map (( (item, index) => { return (
                                      <li key={index} className={`select2-results__option select2-results__option--selectable ${item.id === entry.value && "select2-results__option--selected"}`} role="option" aria-selected={item.id === entry.value}>
                                        <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeEntry(item)}>{dateFormatLocalized(lang.code, item.date)}, {treasuryAccountSelected(lang, treasuryAccountsForCompany, item.debits[0].reference.id_treasury_account)}, {companyForUser.currency.symbol} {moneyFormat(item.balance)}</Dropdown.Item>
                                      </li>
                                    )}))}
                                  </ul>
                                </span>
                              </span>
                            </Dropdown.Menu>
                          </Dropdown>
                          <div className="form-text text-justify">{lang.labels.unidentifiedTransactionToRegisterInContact}</div>
                          <AppLegend component={"modal-contact-entry-create"} attribute={{validity: entry.valid, name: "entry", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                        </div>
                        <div className="table-responsive">
                          <table className="table align-middle table-row-bordered mb-2 gy-3">
                            <tbody>
                              <tr className="row-bordered">
                                <td className="text-gray-700">{lang.labels.accountingAccount}</td>
                                <td className="fw-bold text-end">{entry.valid ? accountingAccountSelected(lang, accountingAccountsForCompany, entry.object.debits[0].id_account, companyForUser.language) : lang.labels.none}</td>
                              </tr>
                              <tr className="row-bordered d-none">
                                <td className="text-gray-700">{lang.labels.paymentMethod}</td>
                                <td className="fw-bold text-end">{entry.valid ? paymentMethodValue(lang, entry.object.debits[0].reference.payment_method) : lang.labels.none}</td>
                              </tr>
                              <tr className="row-bordered">
                                <td className="text-gray-700">{lang.labels.totalDepositAmount}</td>
                                <td className="fw-bold text-end">{entry.valid ? `${companyForUser.currency.symbol} ${moneyFormat(entry.object.debits[0].amount)}` : lang.labels.none}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>                
                    </div>
                  </div>
                </div>
              </div >
              :
              <div className="d-flex justify-content-center align-items-center w-100 h-200px">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">{lang.labels.loading}</span>
                </div>
              </div>
            }
          </div>
          <div className="modal-footer flex-center">
            <button className="btn btn-light mx-2" type="reset" onClick={executeHideModalPaymentCreate}>{lang.labels.discard}</button>
            <button className="btn btn-primary mx-2" type="button" data-kt-indicator={loadIndicator} onClick={submitAccountingEntryCreate}>
              <span className="indicator-label">{lang.labels.save}</span>
              <span className="indicator-progress">
                {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppModalContactEntryCreate;
