import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { Message } from '../models/message.model';
import { Product } from '../models/product.model';
import { Company } from '../models/company.model';
import { Names } from '../models/names.model';
import { CompanyProducts } from '../models/company.products.model';
import { appToken } from '../scripts/app.token.script';
import { InventoryAccounts } from '../models/inventory.accounts.model';

export class ProductDA {
  static apiResponse: ApiResponse

  public static async getProducts(id_company: string, product_name: string, category_id: string, sort_field: string, sort_mode: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let productsByCompany: CompanyProducts | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/products.data.json').then(response => { productsByCompany = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (productsByCompany!.id_company === id_company) {
        let listProducts: Product[] = []

        if (product_name.length === 0 && category_id.length === 0 && sort_field.length === 0 && sort_mode.length === 0) {
          listProducts = productsByCompany!.products
        }
        if (product_name.length === 0 && category_id.length !== 0) {
          listProducts = productsByCompany!.products.filter(item => item.category.id === category_id)
        }
        if (product_name.length !== 0) {
          for (let product of productsByCompany!.products) {
            let name: string = product.display_name.toLowerCase()
            if (name.indexOf(product_name.toLocaleLowerCase()) !== -1) {
              listProducts.push(product)
            }
          }
        }

        productsByCompany!.filter = { name: product_name , category: category_id, sort_mode: sort_mode, sort_field: sort_field }
        productsByCompany!.products = listProducts

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: productsByCompany
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/products?name=${product_name}&category=${category_id}&sort_field=${sort_field}&sort_mode=${sort_mode}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.get(url, config).then(response => {

        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async getProduct(id_company: string, id_product: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let product: Product | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/product.data.json').then(response => { product = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (product!.id === id_product) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: product
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/products/${id_product}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.get(url, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async postProduct(id_company: string, display_name: string, category_id: string, names: Names, accounts: InventoryAccounts, tags: string[], variants: { description_short: string, description_long: string, code: string, sku: string, codebar: string, size: string, color: string, price: string, tax_rate: string, unit: string, discount: { type: string, value: string, quantity: string }, photos: any[] }[]): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let company: Company | null = null
      let message: Message | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/company.data.json').then(response => { company = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/message.data.json').then(response => { message = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (company!.id === id_company) {
        this.apiResponse = {
          status: 201,
          message: 'Success',
          data: message
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/products`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      let data: any = new FormData()
      data.append('display_name', display_name)
      data.append('names[en]', names.en)
      data.append('names[fr]', names.fr)
      data.append('names[de]', names.de)
      data.append('names[it]', names.it)
      data.append('id_category', category_id)
      data.append('accounts[expense]', accounts.expense)
      data.append('accounts[vat]', accounts.vat)
      data.append('accounts[product]', accounts.product)

      tags.forEach(tag => {
        data.append('tags', JSON.stringify(tag))
      })

      variants.forEach((variant, index) => {
        data.append(`variants[${index}][code]`, variant.code)
        data.append(`variants[${index}][description_short]`, variant.description_short)
        data.append(`variants[${index}][description_long]`, variant.description_long)
        data.append(`variants[${index}][size]`, variant.size)
        data.append(`variants[${index}][sku]`, variant.sku)
        data.append(`variants[${index}][codebar]`, variant.codebar)
        data.append(`variants[${index}][color]`, variant.color)
        data.append(`variants[${index}][price]`, variant.price)
        data.append(`variants[${index}][tax_rate]`, variant.tax_rate)
        data.append(`variants[${index}][unit]`, variant.unit)
        data.append(`variants[${index}][discount][type]`, variant.discount.type)
        data.append(`variants[${index}][discount][value]`, variant.discount.value)
        data.append(`variants[${index}][discount][quantity]`, variant.discount.quantity)

        variant.photos.forEach((image, subIndex) => {
          if (image instanceof File) {
            data.append(`variants_photos`, image, `${variant.description_short}`)
          } else {
            data.append(`variants_string_url[${variant.description_short}][${subIndex}]`, image)
          }
        })
      })

      await axios.post(url, data, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async putProduct(id_company: string, id_product: string, display_name: string, category_id: string, names: Names, accounts: InventoryAccounts, tags: string[], variants: { description_short: string, description_long: string, code: string, sku: string, codebar: string, size: string, color: string, price: string, tax_rate: string, unit: string, discount: { type: string, value: string, quantity: string }, photos: any[] }[]): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let product: Product | null = null
      let message: Message | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/product.data.json').then(response => { product = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/message.data.json').then(response => { message = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (product!.id === id_product) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: message
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/products/${id_product}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      let data: any = new FormData()
      data.append('display_name', display_name)
      data.append('names[en]', names.en)
      data.append('names[fr]', names.fr)
      data.append('names[de]', names.de)
      data.append('names[it]', names.it)
      data.append('id_category', category_id)
      data.append('accounts[expense]', accounts.expense)
      data.append('accounts[vat]', accounts.vat)
      data.append('accounts[product]', accounts.product)

      tags.forEach(tag => {
        data.append('tags', JSON.stringify(tag))
      })

      variants.forEach((variant, index) => {
        data.append(`variants[${index}][code]`, variant.code)
        data.append(`variants[${index}][description_short]`, variant.description_short)
        data.append(`variants[${index}][description_long]`, variant.description_long)
        data.append(`variants[${index}][size]`, variant.size)
        data.append(`variants[${index}][sku]`, variant.sku)
        data.append(`variants[${index}][codebar]`, variant.codebar)
        data.append(`variants[${index}][color]`, variant.color)
        data.append(`variants[${index}][price]`, variant.price)
        data.append(`variants[${index}][tax_rate]`, variant.tax_rate)
        data.append(`variants[${index}][unit]`, variant.unit)
        data.append(`variants[${index}][discount][type]`, variant.discount.type)
        data.append(`variants[${index}][discount][value]`, variant.discount.value)
        data.append(`variants[${index}][discount][quantity]`, variant.discount.quantity)

        variant.photos.forEach((image, subIndex) => {
          if (image instanceof File) {
            data.append(`variants_photos`, image, `${variant.description_short}`)
          } else {
            data.append(`variants_string_url[${variant.description_short}][${subIndex}]`, image)
          }
        })
      })

      await axios.put(url, data, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }
    return this.apiResponse
  }

  public static async deleteProducts(id_company: string, id_products: string[]): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let products: Product[] | null = null
      let message: Message | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/products.data.json').then(response => { products = response.data.products }).catch(error => { alert(error) })
      await axios.get('../../../../../database/message.data.json').then(response => { message = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (products!.some(item => id_products.includes(item.id))) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: message
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/products`

      let config: any = {
        headers: { Authorization: appToken() },
        data: { ids_products: id_products }
      }

      await axios.delete(url, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }
}
