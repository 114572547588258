import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { getError, getMessage } from '../../languages/translations/response';
import { NotificationDL } from '../../services/notification.service';
import { Error } from '../../models/error.model';
import { Message } from '../../models/message.model';
import { AppService } from '../../models/app.service.model';
import { Notification } from '../../models/notification.model';
import { modalHide } from '../../tools/modal.tool';
import { toastError, toastSuccess } from '../../tools/toast.tool';
import { imageCompany } from '../../scripts/image.value.script';
import { moneyFormat } from '../../scripts/format.value.script';
import { countryName } from '../../libraries/country.library';

import AppLoading from '../Loading';

export interface AppModalNotificationOrderProps {
  appServiceForUser: AppService | undefined | null,
  notification: Notification | undefined | null,
  loadNotificationsForUser: Function
};

let errorResponse: Error, messageResponse: Message;

const AppModalNotificationOrder: React.FunctionComponent<AppModalNotificationOrderProps> = ({appServiceForUser, notification, loadNotificationsForUser}) => {
  const {lang} = StateLanguage()
  const navigate = useNavigate()

  const [loadIndicator, setLoadIndicator] = useState({view: 'off', close: 'off'})

  const submitNotificationView = async () => {
    if (appServiceForUser && notification) {
      setLoadIndicator({view: 'on', close: 'off'})

      if (!notification.view) {
        await NotificationDL.putNotification(appServiceForUser.api_key, notification.id).then( (response) => {
          loadNotificationsForUser(appServiceForUser.api_key)

          if (response.status === 200) {
            messageResponse = response.data
            toastSuccess(getMessage(messageResponse.message, lang.code))
          } else {
            errorResponse = response.data
            toastError(getError(errorResponse.code, lang.code))
          }

          setLoadIndicator({view: 'off', close: 'off'})
          executeHideModalNotificationOrder()
          navigate('/app/sale/order/view/' + notification.detail.order.id)
        }).catch( (error) => {
          console.error(error)
          navigate('/error')
        })
      } else {
        setLoadIndicator({view: 'off', close: 'off'})
        executeHideModalNotificationOrder()
        navigate('/app/sale/order/view/' + notification.detail.order.id)
      }
    }
  }

  const submitNotificationClose = async () => {
    if (appServiceForUser && notification) {
      setLoadIndicator({view: 'off', close: 'on'})

      if (!notification.view) {
        await NotificationDL.putNotification(appServiceForUser.api_key, notification.id).then( (response) => {
          loadNotificationsForUser(appServiceForUser.api_key)

          if (response.status === 200) {
            messageResponse = response.data
            toastSuccess(getMessage(messageResponse.message, lang.code))
          } else {
            errorResponse = response.data
            toastError(getError(errorResponse.code, lang.code))
          }

          setLoadIndicator({view: 'off', close: 'off'})
          executeHideModalNotificationOrder()
        }).catch( (error) => {
          console.error(error)
          navigate('/error')
        })
      } else {
        setLoadIndicator({view: 'off', close: 'off'})
        executeHideModalNotificationOrder()
      }
    }
  }

  const executeHideModalNotificationOrder = () => {
    modalHide('modal-notification-order')
  }

  return (
    <div id="modal-notification-order" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog mw-600px">
        <div className="modal-content">
          { notification && notification.description === "order evaluation"
            ?
            <>
              <div className="modal-body">
                <div className="text-center mb-5">
                  <h2 className="text-capitalize mb-3">{lang.labels.orderEvaluation}</h2>
                  { notification.type === "activity" && !notification.view &&
                    <div className="text-gray-800 text-justify">
                      {lang.labels.youHaveReceivedAnOrder}
                      <strong className="mx-1">{notification.detail.order.company.name}</strong>
                      {lang.labels.companyPleaseEvaluateTheOrder}
                    </div>
                  }
                  { notification.type === "answer" &&
                    <div className="text-gray-800 text-justify">
                      {lang.labels.theOrderThatYouSent}
                      <strong className="mx-1">{notification.detail.order.company.name}</strong>
                      {lang.labels.toUser}
                      <strong className="mx-1">{notification.user.first_name} {notification.user.last_name}</strong>
                      {lang.labels.hasBeen}
                      <strong className="ms-1 text-uppercase">{notification.detail.response}</strong>
                      .
                    </div>
                  }
                  { notification.detail.comment && notification.detail.comment.length > 0 &&
                    <div className="fs-7 text-gray-900 text-justify mt-3">
                      <strong className="text-uppercase me-1">{lang.labels.comment}:</strong>
                      {notification.detail.comment}
                    </div>
                  }
                </div>
                <div className="bg-light">
                  <div className="d-flex flex-stack p-3">
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-50px">
                        <img src={imageCompany(notification.detail.order.company.logo)} alt={lang.labels.logo} />
                      </div>
                      <div className="ms-5">
                        <div className="fs-6 fw-bold text-dark">{notification.detail.order.order_number}</div>
                        <div className="fw-semibold text-muted">{lang.labels.deliveyAddress}: {notification.detail.order.delivery_address.number} {notification.detail.order.delivery_address.street}, {notification.detail.order.delivery_address.zip_code}, {notification.detail.order.delivery_address.city}, {countryName(lang.code, notification.detail.order.delivery_address.country)}</div>
                        <div className="fw-semibold text-muted">{lang.labels.totalAmount}: {moneyFormat(notification.detail.order.amount.total)} {notification.detail.order.currency.code}</div>
                      </div>
                    </div>
                  </div>
                </div>
                { notification.type === 'activity' && notification.view &&
                  <div className="text-justify text-gray-900 mt-5">
                    {lang.labels.youHaveAlreadyEvaluatedOrder}
                    <strong className="mx-1">{notification.detail.order.company.name}</strong>
                    {lang.labels.yourAnswerHasBeen}
                    <strong className="ms-1 text-uppercase">{notification.detail.response}</strong>
                    .
                  </div>
                }
              </div>
              <div className="modal-footer pt-0 border-0 justify-content-center align-items-end">
                <div className="mx-3">
                  <button className="btn btn-info" type="button" data-kt-indicator={loadIndicator.view} onClick={submitNotificationView}>
                    <span className="indicator-label">{lang.labels.viewOrder}</span>
                    <span className="indicator-progress">
                      {lang.labels.pleaseWait}
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  </button>
                </div>
                { notification.type === "activity"
                  ?
                  <div className="mx-3">
                    <button className="btn btn-secondary" type="button" onClick={executeHideModalNotificationOrder}>{lang.labels.close}</button>
                  </div>
                  :
                  <div className="mx-3">
                    <button className="btn btn-secondary" type="button" data-kt-indicator={loadIndicator.close} onClick={submitNotificationClose}>
                      <span className="indicator-label">{lang.labels.okClose}</span>
                      <span className="indicator-progress">
                        {lang.labels.pleaseWait}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    </button>
                  </div>
                }
              </div>
            </>
            :
            <AppLoading></AppLoading>
          }
        </div>
      </div>
    </div>
  )
}

export default AppModalNotificationOrder;
