import React, { useState } from "react";
import Swal, { SweetAlertOptions } from "sweetalert2";
import { useNavigate } from "react-router-dom";
import autoTable from 'jspdf-autotable';

import { jsPDFWithSvg } from "../../jsPDFWithSvg";
import { StateLanguage } from '../../languages/config/StateLanguage';
import { TemplateDA } from "../../services/template.service";
import { Error } from "../../models/error.model";
import { Company } from "../../models/company.model";
import { Settings } from "../../models/settings.model";
import { Invoice } from "../../models/invoice.model";
import { QRTemplate } from "../../models/qr.template.model";
import { modalHide, modalShow } from "../../tools/modal.tool";

export interface AppButtonInvoiceExportProps {
  companyForUser: Company | undefined | null,
  settingsForCompany: Settings | undefined | null,
  invoice: Invoice
};

let errorResponse: Error, qrTemplateResponse: QRTemplate;

const AppButtonInvoiceExport: React.FunctionComponent<AppButtonInvoiceExportProps> = ({companyForUser, settingsForCompany, invoice}) => {
  const {lang} = StateLanguage()
  const navigate = useNavigate()

  const [loadIndicator, setLoadIndicator] = useState('off')
  const [error, setError] = useState<Error | undefined | null>(null)

  const submitInvoiceExport = async () => {
    if (companyForUser && settingsForCompany) {
      setLoadIndicator('on')

      if (invoice.qr_type !== 'none') {
        await TemplateDA.getQrTemplate(companyForUser.id, invoice.id).then( (response) => {
          if (response.status === 200) {
            qrTemplateResponse = response.data.data

            setTimeout( () => {
              getDataUri(companyForUser.logo, function(dataUri: any) {
                let doc: jsPDFWithSvg = new jsPDFWithSvg('portrait', 'mm', "A4") as jsPDFWithSvg

                if (companyForUser && invoice.company.id === companyForUser.id) {
                  loadScript(settingsForCompany.template_current.invoice, false).then( (ev: any) => {
                    if (ev.status) {
                      // @ts-ignore
                      generatePDF(doc, autoTable, companyForUser, dataUri, invoice, qrTemplateResponse, settingsForCompany.colors)
                    }
                  }).catch( error => {
                    console.error(error)
                  })
                }
              })
              setLoadIndicator('off')
            }, 1000)
          } else {
            errorResponse = response.data

            if (errorResponse.code === 1089 || errorResponse.code === 1090) {
              modalShow('modal-invoice-export-error')
              setError(errorResponse)
            } else {
              Swal.fire({
              title: errorResponse.message,
              text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
              icon: 'error',
              buttonsStyling: !1,
              confirmButtonText: lang.labels.okGotIt,
              customClass: {confirmButton: 'btn btn-primary'}
            } as SweetAlertOptions).then( () => {
              setLoadIndicator('off')
            })
            }
          }
        }).catch( (error) => {
          console.error(error)
          navigate('/error')
        }) 
      } else {
        setTimeout( () => {
          getDataUri(companyForUser.logo, function(dataUri: any) {
            let doc: jsPDFWithSvg = new jsPDFWithSvg('portrait', 'mm', "A4") as jsPDFWithSvg

            if (companyForUser && invoice && invoice.company.id === companyForUser.id) {
              loadScript(settingsForCompany.template_current.invoice, false).then( (ev: any) => {
                if (ev.status) {
                  // @ts-ignore
                  generatePDF(doc, autoTable, companyForUser, dataUri, invoice, null, settingsForCompany.colors)
                }
              }).catch( error => {
                console.error(error)
              })
            }
          })
          setLoadIndicator('off')
        }, 1000)
      }
    }
  }

  const loadScript = (url: string, async = true, type = "text/javascript") => {
    return new Promise((resolve, reject) => {
      try {
        const scriptElement = document.createElement("script")
        scriptElement.type = type
        scriptElement.async = async
        scriptElement.src = url

        scriptElement.addEventListener("load", () => {
          resolve({ status: true })
        })

        scriptElement.addEventListener("error", () => {
          reject({
            status: false,
            message: `${lang.labels.failedToLoadTheScript} ＄{url}`
          })
        })

        document.body.appendChild(scriptElement)
      } catch (error) {
        reject(error)
      }
    })
  }

  const executeTreasuryAccountResolve = async () => {
    executeHideModalInvoiceExportError()
    navigate('/app/treasury/list')
  }

  const executeHideModalInvoiceExportError = () => {
    modalHide('modal-invoice-export-error')
    setLoadIndicator('off')

    setTimeout( () => {
      setError(null)
    }, 200 )
  }

  function getDataUri(url: any, cb: any) {
    let image = new Image()

    image.setAttribute('crossOrigin', 'anonymous')

    image.onload = function () {
      let canvas = document.createElement('canvas')
      canvas.width = image.width
      canvas.height = image.height

      let ctx = canvas.getContext('2d')
      ctx!.fillStyle = '#FFFFFF'
      ctx!.fillRect(0, 0, canvas.width, canvas.height)
      canvas.getContext('2d')!.drawImage(image, 0, 0)
      cb(canvas.toDataURL('image/jpeg'))
    }

    image.src = url
  }

  function qrType(code: number): string {
    switch (code) {
      case 1089:
        return 'QR-IBAN'
      case 1090:
          return 'IBAN'
      default:
        return code.toString()
    }
  }

  return (
    <>
      <button className="btn btn-light-primary mb-1 me-2" type="button" data-kt-indicator={loadIndicator} onClick={submitInvoiceExport}>
        <span className="indicator-label">
          <div className="d-flex align-items-center">
            <span className="svg-icon svg-icon-2 ms-0">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <rect opacity="0.3" x="12.75" y="4.25" width="12" height="2" rx="1" transform="rotate(90 12.75 4.25)" fill="black" />
                <path d="M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z" fill="black" />
                <path d="M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z" fill="#C4C4C4" />
              </svg>
            </span>
            {lang.labels.export}
          </div>
        </span>
        <span className="indicator-progress">
          {lang.labels.pleaseWait}
          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>
      </button>
      <div id="modal-invoice-export-error" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div className="modal-dialog modal-dialog-centered mw-500px">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="text-capitalize">{lang.labels.exportError}</h2>
            </div>
            <div className="modal-body">
              <div className="text-center fs-6">
                <h5>{error ? error.message : lang.labels.none}</h5>
                <label>
                  {lang.labels.the}
                  <span className="text-primary fw-bold mx-1">{invoice ? invoice.treasury_account.name : lang.labels.undefined}</span>
                  {lang.labels.accountNeedsA}
                  <span className="text-dark fw-bold mx-1">{error ? qrType(error.code) : lang.labels.undefined}</span>
                  {lang.labels.toBeAddedInOrderToDownloadTheInvoice}
                </label>
              </div>
            </div>
            <div className="modal-footer flex-center">
              <button className="btn btn-primary mx-2" type="button" onClick={executeTreasuryAccountResolve}>{lang.labels.yesResolveError}</button>
              <button className="btn btn-light mx-2" type="reset" onClick={executeHideModalInvoiceExportError}>{lang.labels.noCancel}</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AppButtonInvoiceExport;
