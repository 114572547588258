import React, { useEffect, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';

import { UseOutletContextProviderInvoice } from './ProviderInvoice';
import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { getError, getMessage } from '../../../../languages/translations/response';
import { ProviderInvoiceDA } from '../../../../services/provider.invoice.service';
import { Error } from '../../../../models/error.model';
import { Message } from '../../../../models/message.model';
import { ProviderInvoice } from '../../../../models/provider.invoice.model';
import { legendInvalid, legendInvalidIcon } from '../../../../tools/legend.data.entry.tool';
import { uploadTooltip } from '../../../../tools/tooltip.tool';
import { isValidObjectAttributes } from '../../../../scripts/compare.object.script';

import AppPurchaseRecordUpdate from '../card/PurchaseRecordUpdate';
import AppPurchaseFormRequirements from '../card/PurchaseFormRequirements';
import AppPurchaseFormVouchers from '../card/PurchaseFormVouchers';
import AppPurchaseFormSignatures from '../card/PurchaseFormSignatures';
import AppPreloader from '../../../../components/Preloader';

export interface ProviderInvoiceUpdatePageProps {};

let errorResponse: Error, messageResponse: Message, providerInvoiceResponse: ProviderInvoice;

const ProviderInvoiceUpdatePage: React.FunctionComponent<ProviderInvoiceUpdatePageProps> = props => {
  const {setRoute, companyForUser, settingsForCompany, treasuryAccountsForCompany} = UseOutletContextProviderInvoice()
  const {lang} = StateLanguage()
  const param = useParams()
  const navigate = useNavigate()

  const [mounted, setMounted] = useState(false)
  const [loadIndicator, setLoadIndicator] = useState('off')
  const [concerning, setConcerning] = useState({value: '', valid: false})
  const [date, setDate] = useState({value: '', valid: false})
  const [currency, setCurrency] = useState({value: '', object: null as any, valid: false})
  const [treasuryAccount, setTreasuryAccount] = useState({value: '', valid: false})
  const [requirements, setRequirements] = useState({list: false, value: [] as {type: string, description: string, contents: {detail: string, quantity: string, price_unit: string, tax_included: boolean, tax_rate: string, unit: string}[]}[], valid: [] as {type: boolean, description: boolean, content: boolean, contents: {detail: boolean, quantity: boolean, price_unit: boolean, tax_rate: boolean, unit: boolean}[]}[]})
  const [vouchers, setVouchers] = useState({list: true, value: [] as {name: string, view: string, file: any}[], valid: [] as {name: boolean, view: boolean, file: boolean}[]})
  const [signatures, setSignatures] = useState({list: true, value: [] as string[], valid: [] as boolean[]})
  const [providerInvoice, setProviderInvoice] = useState<ProviderInvoice | undefined | null>(null)

  const loadProviderInvoice = async (id_company: string, id_provider_invoice: string) => {
    await ProviderInvoiceDA.getProviderInvoice(id_company, id_provider_invoice).then( (response) => {
      if (response.status === 200) {
        providerInvoiceResponse = response.data

        let tempRequirementsValue: {type: string, description: string, contents: {detail: string, quantity: string, price_unit: string, tax_included: boolean, tax_rate: string, unit: string}[]}[] = []
        let tempRequirementsValid: {type: boolean, description: boolean, content: boolean, contents: {detail: boolean, quantity: boolean, price_unit: boolean, tax_rate: boolean, unit: boolean}[]}[] = []
        let tempVouchersValue: {name: string, view: string, file: any}[] = []
        let tempVouchersValid: {name: boolean, view: boolean, file: boolean}[] = []
        let tempSignaturesValue: string[] = []
        let tempSignaturesValid: boolean[] = []

        for (let requirement of providerInvoiceResponse.requirements) {
          let newRequirimentValue: {type: string, description: string, contents: any[]} = {
            type: requirement.type,
            description: requirement.description,
            contents: []
          }
          let newRequirementValid: {type: boolean, description: boolean, content: boolean, contents: any[]} = {
            type: true,
            description: true,
            content: true,
            contents: []
          }

          for (let content of requirement.contents) {
            let newContentValue: {detail: string, quantity: string, price_unit: string, tax_rate: string, tax_included: boolean, unit: string} = {
              detail: content.detail,
              quantity: content.quantity.toString(),
              price_unit: content.price_unit.toString(),
              tax_included: content.tax_included,
              tax_rate: content.tax_rate.toString(),
              unit: content.unit.id
            }
            let newContentValid: {detail: boolean, quantity: boolean, price_unit: boolean, tax_rate: boolean, unit: boolean} = {
              detail: true,
              quantity: true,
              price_unit: true,
              tax_rate: true,
              unit: true
            }

            newRequirimentValue.contents.push(newContentValue)
            newRequirementValid.contents.push(newContentValid)
          }

          tempRequirementsValue.push(newRequirimentValue)
          tempRequirementsValid.push(newRequirementValid)
        }

        for (let voucher of providerInvoiceResponse.vouchers) {
          let newContentValue: {name: string, view: string, file: any} = {
            name: voucher.name,
            view: voucher.file,
            file: voucher.file
          }
          let newContentValid: {name: boolean, view: boolean, file: boolean} = {
            name: true,
            view: true,
            file: true
          }

          tempVouchersValue.push(newContentValue)
          tempVouchersValid.push(newContentValid)
        }

        for (let signature of providerInvoiceResponse.signatures) {
          tempSignaturesValue.push(signature)
          tempSignaturesValid.push(true)
        }

        setProviderInvoice(providerInvoiceResponse)
        setConcerning({value: providerInvoiceResponse.concerning, valid: true})
        setDate({value: providerInvoiceResponse.created_date, valid: true})
        setCurrency({value: providerInvoiceResponse.currency.code, object: providerInvoiceResponse.currency, valid: true})
        setTreasuryAccount({value: providerInvoiceResponse.treasury_account.id, valid: true})
        setRequirements({list: true, value: tempRequirementsValue, valid: tempRequirementsValid})
        setVouchers({list: true, value: tempVouchersValue, valid: tempVouchersValid})
        setSignatures({list: true, value: tempSignaturesValue, valid: tempSignaturesValid})
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.code),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.okGotIt,
          customClass: {confirmButton: 'btn btn-primary'}
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      navigate('/error')
    })
  }

  const submitProviderInvoiceUpdate = async () => {
    setLoadIndicator('on')

    let requerimentsIsValid: boolean = requirements.valid.every((item) => isValidObjectAttributes(item))
    let vouchersIsValid: boolean = vouchers.valid.every((item) => isValidObjectAttributes(item))
    let signaturesIsValid: boolean = signatures.valid.every((item) => (item === true))

    if (companyForUser && providerInvoice && concerning.valid && date.valid && currency.valid && treasuryAccount.valid && requirements.list && vouchers.list && signatures.list && requerimentsIsValid && vouchersIsValid && signaturesIsValid) {
      let voucherFiles = vouchers.value.map((voucher) => ({name: voucher.name, file: voucher.file}))

      await ProviderInvoiceDA.putProviderInvoice(companyForUser.id, providerInvoice.id, concerning.value, date.value, currency.object, treasuryAccount.value, requirements.value, voucherFiles, signatures.value).then( (response) => {
        if (response.status === 200) {
          messageResponse = response.data

          Swal.fire({
            title: getMessage(messageResponse.message, lang.code),
            text: lang.labels.actionCompletedReturningToPage,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            navigate(-1)
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.code),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        navigate('/error')
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        if (concerning.value.length === 0) {
          legendInvalid('legend-concerning-required')
        }
        if (date.value.length === 0) {
          legendInvalid('legend-date-required')
        }
        if (currency.value.length === 0) {
          legendInvalid('legend-currency-required')
        }
        if (treasuryAccount.value.length === 0) {
          legendInvalid('legend-treasury-account-required')
        }
        if (requirements.value.length === 0) {
          legendInvalid('legend-requirements-required')
        }
        if (!requerimentsIsValid) {
          for (let i = 0; i < requirements.value.length; i++) {
            if (requirements.value[i].contents.length === 0) {
              legendInvalid('legend-requirement-contents-required-' + i)
            } else {
              for (let j = 0; j < requirements.value[i].contents.length; j++) {
                if (requirements.value[i].contents[j].detail.length === 0) {
                  legendInvalid('legend-requirement-content-detail-required-' + i + j)
                }
                if (requirements.value[i].contents[j].unit.length === 0) {
                  legendInvalid('legend-requirement-content-unit-required-' + i + j)
                }
                if (requirements.value[i].contents[j].quantity.length === 0) {
                  legendInvalid('legend-requirement-content-quantity-required-' + i + j)
                }
                if (requirements.value[i].contents[j].price_unit.length === 0) {
                  legendInvalid('legend-requirement-content-price-required-' + i + j)
                }
                if (requirements.value[i].contents[j].tax_rate.length === 0) {
                  legendInvalid('legend-requirement-content-tax-required-' + i + j)
                }
              }
            }
          }
        }
        if (!vouchersIsValid) {
          for (let i = 0; i < vouchers.value.length; i++) {
            if (vouchers.value[i].name.length === 0) {
              legendInvalidIcon('input-voucher-name-' + i, 'legend-voucher-name-required-' + i)
            }
          }
        }
        if (!signaturesIsValid) {
          for (let i = 0; i < signatures.value.length; i++) {
            if (signatures.value[i].length === 0) {
              legendInvalid('legend-signature-required-' + i)
            }
          }
        }
        setLoadIndicator('off')
      })
    }
  }

  useEffect( () => {
    if (companyForUser && param.id) {
      loadProviderInvoice(companyForUser.id, param.id)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyForUser, param])

  useEffect( () => {
    setMounted(true)
    setRoute({path: {root: lang.labels.providerInvoices, branch: lang.labels.editProviderInvoice}, company: false})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { companyForUser && settingsForCompany && treasuryAccountsForCompany && providerInvoice
        ?
        <div className="form" onLoad={uploadTooltip}>
          <AppPurchaseRecordUpdate purchase={"invoice"} record={providerInvoice} concerning={concerning} date={date} currency={currency} treasuryAccount={treasuryAccount} paymentMethod={null} setConcerning={setConcerning} setDate={setDate} setCurrency={setCurrency} setTreasuryAccount={setTreasuryAccount} setPaymentMethod={null}></AppPurchaseRecordUpdate>
          <AppPurchaseFormRequirements currency={currency.object} requirements={requirements} setRequirements={setRequirements}></AppPurchaseFormRequirements>
          <AppPurchaseFormVouchers vouchers={vouchers} setVouchers={setVouchers}></AppPurchaseFormVouchers>
          <AppPurchaseFormSignatures signatures={signatures} setSignatures={setSignatures}></AppPurchaseFormSignatures>
          <div className="d-flex flex-stack flex-wrap flex-md-nowrap bg-light-primary border border-primary border-dashed rounded h-100 p-5">
            <div className="notice d-flex align-items-center mb-5 mb-md-0 me-0 me-md-5">
              <span className="svg-icon svg-icon-3tx svg-icon-primary me-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM16 13H13V10C13 9.4 12.6 9 12 9C11.4 9 11 9.4 11 10V13H8C7.4 13 7 13.4 7 14C7 14.6 7.4 15 8 15H11V18C11 18.6 11.4 19 12 19C12.6 19 13 18.6 13 18V15H16C16.6 15 17 14.6 17 14C17 13.4 16.6 13 16 13Z" fill="black" />
                  <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black" />
                </svg>
              </span>    
              <div className="d-grid">
                <span className="text-start text-dark fw-bolder fs-5">{lang.labels.processOfUpdatingAProviderInvoiceCompleted}</span>
                <span className="text-justify text-gray-600 fw-bold">
                  {lang.labels.clickOn}
                  <span className="text-primary text-lowercase fw-bolder mx-1">{lang.labels.updateProviderInvoice}</span>
                  {lang.labels.ifYouWishToSaveTheChangesMade}
                </span>
              </div>
            </div>
            <button className="btn btn-lg btn-primary fw-bolder w-100 w-md-300px" type="button" data-kt-indicator={loadIndicator} onClick={submitProviderInvoiceUpdate}>
              <span className="indicator-label text-uppercase text-nowrap">{lang.labels.updateProviderInvoice}</span>
              <span className="indicator-progress">
              {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
        </div>
        :
        <AppPreloader></AppPreloader>
      }
    </div>
  )
}

export default ProviderInvoiceUpdatePage;
