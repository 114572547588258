import React, { Dispatch, SetStateAction } from 'react';
import { Dropdown } from 'react-bootstrap';
import { format } from 'date-fns';
import TextareaAutosize from 'react-textarea-autosize';

import { UseOutletContextSale } from '../Sale';
import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { Deadline } from '../../../../models/deadline.model';
import { Currency } from '../../../../models/currency.model';
import { Quote } from '../../../../models/quote.model';
import { Order } from '../../../../models/order.model';
import { Invoice } from '../../../../models/invoice.model';
import { legendInvalid, legendInvalidIcon, legendValid, legendValidInvalidIconRestart } from '../../../../tools/legend.data.entry.tool';
import { modalShow } from '../../../../tools/modal.tool';
import { evaluateLegendValidRequired, evaluateLegendValidRequiredIcon } from '../../../../scripts/validate.legend.script';
import { datetimeFormat12h } from '../../../../scripts/datetime.format.script';
import { paymentDeadlineSelected, treasuryAccountSelected } from '../../../../scripts/list.selected.item.script';
import { languageList, languageValue } from '../../../../libraries/language.library';
import { currencyList, currencyValue } from '../../../../libraries/currency.library';
import { qrTemplateList, qrTemplateValue } from '../../../../libraries/qr.template.library';
import { expressions } from '../../../../libraries/regular.expressions.library';
import { contactTypeValue } from '../../../../libraries/contact.type.library';

import AppLegend from '../../../../components/element/Legend';
import AppModalSettingsCreateDeadline from '../../../../components/modal/ModalSettingsCreateDeadline';

export interface AppSaleRecordUpdateProps {
  sale: string,
  record: any | Quote | Order | Invoice,
  concerning: {value: string, valid: boolean},
  date: {value: string, valid: boolean},
  validUntil: {value: string, valid: boolean},
  paymentDeadline: {value: string, valid: boolean},
  currency: {value: string, object: any, valid: boolean},
  treasuryAccount: {value: string, valid: boolean},
  language: {value: string, valid: boolean},
  qrType: {value: string, valid: boolean} | null,
  discountType: {value: string, valid: boolean},
  discountValue: {value: string, valid: boolean},
  setConcerning: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setDate: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setValidUntil: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setPaymentDeadline: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setCurrency: Dispatch<SetStateAction<{value: string, object: any, valid: boolean}>>,
  setTreasuryAccount: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setLanguage: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setQrTtype: Dispatch<SetStateAction<{value: string, valid: boolean}>> | null,
  setDiscountType: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setDiscountValue: Dispatch<SetStateAction<{value: string, valid: boolean}>>
};

const AppSaleRecordUpdate: React.FunctionComponent<AppSaleRecordUpdateProps> = ({sale, record, concerning, date, validUntil, paymentDeadline, currency, treasuryAccount, language, qrType, discountType, discountValue, setConcerning, setDate, setValidUntil, setPaymentDeadline, setCurrency, setTreasuryAccount, setLanguage, setQrTtype, setDiscountType, setDiscountValue}) => {
  const {companyForUser, settingsForCompany, treasuryAccountsForCompany, loadSettingsForCompany} = UseOutletContextSale()
  const {lang} = StateLanguage()

  const labels: {[key: string]: {desc: string; reference: string, date: string}} = {
    quote: {desc: lang.labels.pleaseModifyAddOrDeleteFieldsFromQuote, reference: lang.labels.quoteReference, date: lang.labels.quoteDate},
    order: {desc: lang.labels.pleaseModifyAddOrDeleteFieldsFromOrder, reference: lang.labels.orderReference, date: lang.labels.orderDate},
    invoice: {desc: lang.labels.pleaseModifyAddOrDeleteFieldsFromInvoice, reference: lang.labels.invoiceReference, date: lang.labels.invoiceDate}
  }

  const handleChangeConcerning = (event: React.ChangeEvent <HTMLFormElement | HTMLTextAreaElement>) => {
    setConcerning({...concerning, value: event.target.value})
  }

  const handleChangeDate = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    if (event.target.value.length > 0) {
      setDate({...date, value: event.target.value, valid: true})
      legendValid('legend-date-required')
    } else {
      setDate({...date, value: event.target.value, valid: false})
      legendInvalid('legend-date-required')
    }
  }

  const handleChangeValidUntil = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setPaymentDeadline({...paymentDeadline, value: '', valid: false})

    if (event.target.value.length > 0) {
      setValidUntil({...validUntil, value: event.target.value, valid: true})
      legendValid('legend-valid-until-required')
    } else {
      setValidUntil({...validUntil, value: event.target.value, valid: false})
      legendInvalid('legend-valid-until-required')
    }
  }

  const handleChangePaymentDeadline = (item: Deadline) => {
    let tempDate: Date = new Date()

    switch (item.type) {
      case 'day':
        tempDate.setDate(tempDate.getDate() + item.quantity)
        break;
      case 'week':
        tempDate.setDate(tempDate.getDate() + item.quantity * 7)
        break;
      case 'month':
        tempDate.setMonth(tempDate.getMonth() + item.quantity)
        break;  
      case 'year':
        tempDate.setFullYear(tempDate.getFullYear() + item.quantity)
        break;
    }

    setValidUntil({...validUntil, value: format(tempDate, 'yyyy-MM-dd'), valid: true})
    setPaymentDeadline({...paymentDeadline, value: item.id, valid: true})
    legendValid('legend-valid-until-required')
  }

  const handleChangeCurrency = (item: Currency) => {
    setCurrency({...currency, value: item.code, object: item, valid: true})
    legendValid('legend-currency-required')
  }

  const handleChangeTreasuryAccount = (item: string) => {
    setTreasuryAccount({...treasuryAccount, value: item, valid: true})
    legendValid('legend-treasury-account-required')
  }

  const handleChangeLanguage = (item: string) => {
    setLanguage({...language, value: item, valid: true})
    legendValid('legend-language-required')
  }

  const handleChangeQrType = (item: string) => {
    if (qrType && setQrTtype) {
      setQrTtype({...qrType, value: item, valid: true})
      legendValid('legend-qr-type-required')
    }
  }

  const handleChangeDiscountType = (item: string) => {
    if (item !== discountType.value) {
      setDiscountType({...discountType, value: item, valid: true})
      setDiscountValue({...discountValue, value: '', valid: (item === 'none')})
      legendValidInvalidIconRestart('input-discount', 'legend-discount-valid')
      legendValidInvalidIconRestart('input-discount', 'legend-discount-required')
    }
  }

  const handleChangeDiscountValue = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setDiscountValue({...discountValue, value: event.target.value})
  }

  const validateConcerning = () => {
    evaluateLegendValidRequired(expressions.text, concerning, setConcerning, 'legend-concerning-valid', 'legend-concerning-required')
  }

  const validateDiscount = () => {
    switch (discountType.value) {
      case 'percentage':
        evaluateLegendValidRequiredIcon(expressions.percentage, discountValue, setDiscountValue, 'input-discount', 'legend-discount-valid', 'legend-discount-required')
        break;
      case 'fixed':
        evaluateLegendValidRequiredIcon(expressions.currency, discountValue, setDiscountValue, 'input-discount', 'legend-discount-valid', 'legend-discount-required')
        break;
      case 'none':
        setDiscountValue({...discountValue, valid: true})
        legendValidInvalidIconRestart('input-discount', 'legend-discount-valid')
        legendValidInvalidIconRestart('input-discount', 'legend-discount-required')
        break;
      default:
        setDiscountValue({...discountValue, valid: false})
        if (discountValue.value.length === 0) {
          legendInvalidIcon('input-discount', 'legend-discount-required')
        } else {
          legendInvalidIcon('input-discount', 'legend-discount-valid')
        }
        break;
    }
  }

  const executeShowModalSettingsCreateDeadline = () => {
    modalShow('modal-settings-create-deadline')
  }

  function minDate(): string {
    return format((new Date()), 'yyyy-MM-dd')
  }

  function maxDate(): string {
    return format((new Date()), 'yyyy-MM-dd')
  }

  return (
    <>
      { companyForUser && settingsForCompany && treasuryAccountsForCompany &&
        <div className="card card-flush mb-5">
          <div className="card-header">
            <div className="card-title">
              <h2>
                {sale === "quote" && record.quote_number}
                {sale === "order" && record.order_number}
                {sale === "invoice" && record.invoice_number}
              </h2>
            </div>
          </div>
          <div className="card-body pt-0">
            <div className="d-flex flex-wrap mb-5">
              <div className="flex-equal me-5">
                <table className="table align-middle gy-1 m-0">
                  <tbody className="fw-bold">
                    <tr>
                      <td className="w-200px text-gray-500">{lang.labels.company}:</td>
                      <td className="min-w-200px text-gray-800">{record.company.name}</td>
                    </tr>
                    <tr>
                      <td  valign="top" className="w-200px text-gray-500">{lang.labels.customer}:</td>
                      <td className="min-w-200px text-gray-800">
                        <div className="d-flex align-items-center">
                          {record.customer.type === "person" ? `${record.customer.data.first_name} ${record.customer.data.last_name}` : `${record.customer.data.name}`}
                          <span className="badge badge-sm badge-light text-uppercase fs-9 ms-3">{contactTypeValue(lang, record.customer.type)}</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="w-200px text-gray-500">{lang.labels.createdAt}:</td>
                      <td className="min-w-200px text-gray-800">{datetimeFormat12h(lang.code, record.created_at)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="flex-equal">
                <table className="table align-middle gy-1 m-0">
                  <tbody className="fw-bold">
                    <tr>
                      <td className="w-200px text-gray-500">{labels[sale].reference}:</td>
                      <td className="min-w-200px text-gray-800">{record.reference.length > 0 ? record.reference : lang.labels.none}</td>
                    </tr>
                    { (sale === "order" || sale === "invoice") &&
                      <tr>
                        <td className="w-200px text-gray-500">{lang.labels.quoteNumber}:</td>
                        <td className="min-w-200px text-gray-800">{record.quote.id.length > 0 ? record.quote.quote_number : lang.labels.none}</td>
                      </tr>
                    }
                    { sale === "invoice" &&
                      <tr>
                        <td className="w-200px text-gray-500">{lang.labels.orderNumber}:</td>
                        <td className="min-w-200px text-gray-800">{record.order.id.length > 0 ? record.order.order_number : lang.labels.none}</td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
            <div className="fw-bold fs-6 mb-5">{labels[sale].desc}</div>
            <div className="row mb-3">
              <label className="form-label required">{lang.labels.concerning}</label>
              <div className="fv-row">
                <TextareaAutosize className="form-control form-control-solid" name="concerning" minRows={2} autoComplete="off" value={concerning.value} onChange={handleChangeConcerning} onKeyUp={validateConcerning} onBlur={validateConcerning} />
                <AppLegend attribute={{validity: concerning.valid, name: "concerning", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-3">
              <label className="form-label required">{labels[sale].date}</label>
              <div className="fv-row">
                <input className="form-control form-control-solid" type="date" name="date" max={maxDate()} value={date.value} onChange={handleChangeDate} />
                <AppLegend attribute={{validity: date.valid, name: "date", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-3">
              <label className="form-label required">{lang.labels.validUntil}</label>
              <div className="fv-row">
                <div className="d-flex align-self-center">
                  <div className="flex-equal">
                    <input className="form-control form-control-solid" type="date" name="valid-until" min={minDate()} value={validUntil.value} onChange={handleChangeValidUntil} />
                  </div>
                  <div className="form-text text-center align-middle text-uppercase m-0 py-3 px-2">{lang.labels.or}</div>
                  <div className="flex-equal">
                    <Dropdown>
                      <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                        <span className="selection">
                          <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                            <span className="select2-selection__rendered" role="textbox">
                              <span className="select2-selection__placeholder">
                                {paymentDeadline.valid ? paymentDeadlineSelected(lang, settingsForCompany.payment_deadlines, paymentDeadline.value) : lang.labels.selectOption}
                              </span>
                            </span>
                          </span>
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                        <span className="select2-dropdown select2-dropdown--below">
                          <span className="select2-results">
                            <ul className="select2-results__options" role="listbox">
                              <li className="select2-results__option select2-results__option--selectable">
                                <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => executeShowModalSettingsCreateDeadline()}>
                                  <div className="d-flex align-items-center">
                                    <span className="svg-icon svg-icon-3 me-2">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                                        <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                                        <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                                      </svg>
                                    </span>
                                    {lang.labels.addDeadline}
                                  </div>
                                </Dropdown.Item>
                              </li>
                              <li className={`select2-results__option select2-results__option--selectable ${settingsForCompany.payment_deadlines.length > 0 && "d-none"}`}>
                                <Dropdown.Item bsPrefix="select2-results__option__text">{lang.labels.selectOption}</Dropdown.Item>
                              </li>
                              { settingsForCompany.payment_deadlines.map (( (item, index) => { return (
                                <li key={index} className={`select2-results__option select2-results__option--selectable ${item.id === paymentDeadline.value && "select2-results__option--selected"}`} role="option" aria-selected={item.id === paymentDeadline.value}>
                                  <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangePaymentDeadline(item)}>{item.quantity} {item.type}{item.quantity > 1 && 's'}</Dropdown.Item>
                                </li>
                              )}))}
                            </ul>
                          </span>
                        </span>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <AppLegend attribute={{validity: validUntil.valid, name: "valid-until", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-3">
              <label className="form-label required">{lang.labels.currency}</label>
              <div className="fv-row">
                <Dropdown>
                  <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                    <span className="selection">
                      <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                        <span className="select2-selection__rendered" role="textbox">
                          <span className="select2-selection__placeholder">
                            {currency.valid ? currencyValue(lang.code, currency.value) : lang.labels.selectOption}
                          </span>
                        </span>
                      </span>
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                    <span className="select2-dropdown select2-dropdown--below">
                      <span className="select2-results">
                        <ul className="select2-results__options" role="listbox">
                          { currencyList(lang.code).map (( (item, index) => { return (
                            <li key={index} className={`select2-results__option select2-results__option--selectable ${item.code === currency.value && "select2-results__option--selected"}`} role="option" aria-selected={item.code === currency.value}>
                              <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeCurrency(item)}>{item.code}: {item.name} ({item.symbol})</Dropdown.Item>
                            </li>
                          )}))}
                        </ul>
                      </span>
                    </span>
                  </Dropdown.Menu>
                </Dropdown>
                <AppLegend attribute={{validity: currency.valid, name: "currency", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-3">
              <label className="form-label required">{lang.labels.treasuryAccount}</label>
              <div className="fv-row">
                <Dropdown>
                  <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                    <span className="selection">
                      <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                        <span className="select2-selection__rendered" role="textbox">
                          <span className="select2-selection__placeholder">
                            {treasuryAccount.valid ? treasuryAccountSelected(lang, treasuryAccountsForCompany, treasuryAccount.value) : lang.labels.selectOption}
                          </span>
                        </span>
                      </span>
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                    <span className="select2-dropdown select2-dropdown--below">
                      <span className="select2-results">
                        <ul className="select2-results__options" role="listbox">
                          <li className={`select2-results__option select2-results__option--selectable ${treasuryAccountsForCompany.length > 0 && "d-none"}`}>
                            <Dropdown.Item bsPrefix="select2-results__option__text">{lang.labels.selectOption}</Dropdown.Item>
                          </li>
                          { treasuryAccountsForCompany.map (( (item, index) => { return (
                            <li key={index} className={`select2-results__option select2-results__option--selectable ${item.id === treasuryAccount.value && "select2-results__option--selected"}`} role="option" aria-selected={item.id === treasuryAccount.value}>
                              <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeTreasuryAccount(item.id)}>{item.name}</Dropdown.Item>
                            </li>
                          )}))}
                        </ul>
                      </span>
                    </span>
                  </Dropdown.Menu>
                </Dropdown>
                <AppLegend attribute={{validity: treasuryAccount.valid, name: "treasury-account", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className={`row ${(sale === "invoice" && qrType && setQrTtype) ? "mb-3" : "mb-0"}`}>
              <label className="form-label required">{lang.labels.language}</label>
              <div className="fv-row">
                <Dropdown>
                  <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                    <span className="selection">
                      <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                        <span className="select2-selection__rendered" role="textbox">
                          <span className="select2-selection__placeholder">
                            {language.valid ? languageValue(lang, language.value) : lang.labels.selectOption}
                          </span>
                        </span>
                      </span>
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                    <span className="select2-dropdown select2-dropdown--below">
                      <span className="select2-results">
                        <ul className="select2-results__options" role="listbox">
                          { languageList(lang).map (( (item, index) => { return (
                            <li key={index} className={`select2-results__option select2-results__option--selectable ${item.code === language.value && "select2-results__option--selected"}`} role="option" aria-selected={item.code === language.value}>
                              <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeLanguage(item.code)}>{item.display}</Dropdown.Item>
                            </li>
                          )}))}
                        </ul>
                      </span>
                    </span>
                  </Dropdown.Menu>
                </Dropdown>
                <AppLegend attribute={{validity: language.valid, name: "language", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            { sale === "invoice" && qrType && setQrTtype &&
              <div className="row mb-0">
                <label className="form-label required">{lang.labels.qrType}</label>
                <div className="fv-row">
                  <Dropdown>
                    <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                      <span className="selection">
                        <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                          <span className="select2-selection__rendered" role="textbox">
                            <span className="select2-selection__placeholder">
                              {qrType.valid ? qrTemplateValue(lang, qrType.value) : lang.labels.selectOption}
                            </span>
                          </span>
                        </span>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                      <span className="select2-dropdown select2-dropdown--below">
                        <span className="select2-results">
                          <ul className="select2-results__options" role="listbox">
                            { qrTemplateList(lang).map (( (item, index) => { return (
                              <li key={index} className={`select2-results__option select2-results__option--selectable ${item.code === qrType.value && "select2-results__option--selected"}`} role="option" aria-selected={item.code === qrType.value}>
                                <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeQrType(item.code)}>{item.display}</Dropdown.Item>
                              </li>
                            )}))}
                          </ul>
                        </span>
                      </span>
                    </Dropdown.Menu>
                  </Dropdown>
                  <AppLegend attribute={{validity: qrType.valid, name: "qr-type", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                </div>
              </div>
            }
            <div className="row d-none">
              <label className="form-label required">{lang.labels.discount}</label>
              <div className="fv-row">
                <div className="d-flex align-self-center">
                  <div className="flex-shrink-0 me-3">
                    <Dropdown>
                      <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-200px p-0">
                        <span className="selection">
                          <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                            <span className="select2-selection__rendered" role="textbox">
                              <span className="select2-selection__placeholder">
                                { discountType.valid
                                  ?
                                  <>
                                    {discountType.value === "none" && lang.labels.none}
                                    {discountType.value === "percentage" && lang.labels.percentage}
                                    {discountType.value === "fixed" && lang.labels.fixedAmount}
                                  </>
                                  :
                                  <>
                                    {lang.labels.selectOption}
                                  </>
                                }
                              </span>
                            </span>
                          </span>
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                        <span className="select2-dropdown select2-dropdown--below">
                          <span className="select2-results">
                            <ul className="select2-results__options" role="listbox">
                              <li className={`select2-results__option select2-results__option--selectable ${discountType.value === "none" && "select2-results__option--selected"}`} role="option" aria-selected={discountType.value === "none"}>
                                <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeDiscountType("none")}>{lang.labels.none}</Dropdown.Item>
                              </li>
                              <li className={`select2-results__option select2-results__option--selectable ${discountType.value === "percentage" && "select2-results__option--selected"}`} role="option" aria-selected={discountType.value === "percentage"}>
                                <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeDiscountType("percentage")}>{lang.labels.percentage}</Dropdown.Item>
                              </li>
                              <li className={`select2-results__option select2-results__option--selectable ${discountType.value === "fixed" && "select2-results__option--selected"}`} role="option" aria-selected={discountType.value === "fixed"}>
                                <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeDiscountType("fixed")}>{lang.labels.fixedAmount}</Dropdown.Item>
                              </li>
                            </ul>
                          </span>
                        </span>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="flex-grow-1">
                    <input id="input-discount" className="form-control form-control-solid" type="text" name="discount" autoComplete="off" value={discountValue.value} disabled={!(discountType.value === 'percentage' || discountType.value === 'fixed')} onChange={handleChangeDiscountValue} onKeyUp={validateDiscount} onBlur={validateDiscount} />
                  </div>
                </div>
                <AppLegend attribute={{validity: discountValue.valid, name: "discount", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
          </div>
        </div>
      }
      <AppModalSettingsCreateDeadline companyForUser={companyForUser} setValidUntil={setValidUntil} setPaymentDeadline={setPaymentDeadline} loadSettingsForCompany={loadSettingsForCompany}></AppModalSettingsCreateDeadline>
    </>
  )
};

export default AppSaleRecordUpdate;
