import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { Message } from '../models/message.model';
import { Company } from '../models/company.model';
import { Expense } from '../models/expense.model';
import { Currency } from '../models/currency.model';
import { Address } from '../models/address.model';
import { CompanyExpenses } from '../models/company.expenses.model';
import { appToken } from '../scripts/app.token.script';

export class ExpenseDA {
  static apiResponse: ApiResponse

  public static async getExpenses(id_company: string, interval: string, start_date: string, end_date: string, treasury_id: string, validity: string, payment_method: string, sort_field: string, sort_mode: string, group_by: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let expensesByCompany: CompanyExpenses | null = null
      let expenseGroupsByCompany: CompanyExpenses | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/expenses.list.data.json').then(response => { expensesByCompany = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/expenses.group.data.json').then(response => { expenseGroupsByCompany = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (expensesByCompany!.id_company === id_company || expenseGroupsByCompany!.id_company === id_company) {
        if (group_by.length === 0) {
          expensesByCompany!.filter.interval = interval
          expensesByCompany!.filter.start_date = start_date
          expensesByCompany!.filter.end_date = end_date
          expensesByCompany!.filter.treasury = treasury_id
          expensesByCompany!.filter.validity = validity
          expensesByCompany!.filter.payment = payment_method
          expensesByCompany!.filter.sort_field = sort_field
          expensesByCompany!.filter.sort_mode = sort_mode
          expensesByCompany!.filter.group_by = group_by
        } else {
          expenseGroupsByCompany!.filter.interval = interval
          expenseGroupsByCompany!.filter.start_date = start_date
          expenseGroupsByCompany!.filter.end_date = end_date
          expenseGroupsByCompany!.filter.treasury = treasury_id
          expenseGroupsByCompany!.filter.validity = validity
          expenseGroupsByCompany!.filter.payment = payment_method
          expenseGroupsByCompany!.filter.sort_field = sort_field
          expenseGroupsByCompany!.filter.sort_mode = sort_mode
          expenseGroupsByCompany!.filter.group_by = group_by
        }

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: (group_by.length === 0) ? expensesByCompany : expenseGroupsByCompany
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/expenses?interval=${interval}&start_date=${start_date}&end_date=${end_date}&treasury_account=${treasury_id}&validity=${validity}&payment_method=${payment_method}&sort_field=${sort_field}&sort_mode=${sort_mode}&group_by=${group_by}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.get(url, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async getExpense(id_company: string, id_expense: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let expense: Expense | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/expense.data.json').then(response => { expense = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (expense!.id === id_expense) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: expense
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/expenses/${id_expense}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.get(url, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async postExpense(id_company: string, concerning: string, date: string, currency: Currency, treasury_id: string, payment_method: string, requirements: { type: string, description: string, contents: { detail: string, quantity: string, price_unit: string, tax_rate: string, tax_included: boolean, unit: string }[] }[], vouchers: { name: string, file: any }[], company_data: { uuid: string, name: string, logo: string, address: Address }): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let company: Company | null = null
      let message: Message | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/company.data.json').then(response => { company = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/message.data.json').then(response => { message = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (company!.id === id_company) {
        this.apiResponse = {
          status: 201,
          message: 'Success',
          data: message
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/expenses`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      let data: any = new FormData()
      data.append("concerning", concerning)
      data.append("created_date", date)
      data.append("bank_account", treasury_id)
      data.append("payment_method", payment_method)
      data.append('currency', JSON.stringify(currency))
      data.append('company', JSON.stringify(company_data))

      requirements.forEach((item) => {
        data.append('requirements', JSON.stringify(item))
      })

      vouchers.forEach((item) => {
        data.append("vouchers", item.file, item.name)
      })

      await axios.post(url, data, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async putExpense(id_company: string, id_expense: string, concerning: string, date: string, currency: Currency, treasury_id: string, payment_method: string, requirements: { type: string, description: string, contents: { detail: string, quantity: string, price_unit: string, tax_rate: string, tax_included: boolean, unit: string }[] }[], vouchers: { name: string, file: any }[]): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let expense: Expense | null = null
      let message: Message | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/expense.data.json').then(response => { expense = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/message.data.json').then(response => { message = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (expense!.id === id_expense) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: message
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/expenses/${id_expense}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      let data: any = new FormData()
      data.append("concerning", concerning)
      data.append("created_date", date)
      data.append("bank_account", treasury_id)
      data.append("payment_method", payment_method)
      data.append('currency', JSON.stringify(currency))

      requirements.forEach((item) => {
        data.append('requirements', JSON.stringify(item))
      })

      vouchers.forEach((item) => {
        if (item.file instanceof File) {
          data.append("vouchers", item.file, item.name)
        } else {
          data.append("array_url", item.file)
          data.append("array_names", item.name)
        }
      })

      await axios.put(url, data, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async putExpenseValidated(id_company: string, id_expense: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let expense: Expense | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/expense.data.json').then(response => { expense = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (expense!.id === id_expense) {
        expense!.validated = (expense!.validated) ? false : true

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: expense
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/expenses/${id_expense}/status`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.put(url, null, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async deleteExpense(id_company: string, id_expense: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let expenses: Expense[] | null = null
      let message: Message | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/contacts.data.json').then(response => { expenses = response.data.expenses }).catch(error => { alert(error) })
      await axios.get('../../../../../database/message.data.json').then(response => { message = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (expenses!.some(item => id_expense === item.id)) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: message
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/expenses/${id_expense}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.delete(url, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }
}
