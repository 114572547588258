import logoDefaultUser from '../assets/images/avatar.png';
import logoDefaultCompany from '../assets/images/company.png';
import imageDefaultProduct from '../assets/images/product.png';
import imageDefaultService from '../assets/images/service.png';

export function imageUser(avatar: string): string {
  return (avatar.length === 0) ? logoDefaultUser : avatar
}

export function imageCompany(logo: string): string {
  return (logo.length === 0) ? logoDefaultCompany : logo
}

export function imageService(photo: string): string {
  return (photo.length === 0) ? imageDefaultService : photo
}

export function imageProduct(photo: string): string {
  return (photo.length === 0) ? imageDefaultProduct : photo
}
