import React, { useEffect, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';

import { UseOutletContextService } from '../service/Service';
import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { getError, getMessage } from '../../../../languages/translations/response';
import { ServiceDA } from '../../../../services/service.service';
import { Error } from '../../../../models/error.model';
import { Message } from '../../../../models/message.model';
import { Service } from '../../../../models/service.model';
import { legendInvalid, legendInvalidIcon } from '../../../../tools/legend.data.entry.tool';
import { isValidObjectAttributes } from '../../../../scripts/compare.object.script';

import AppServiceForm from './card/ServiceForm';
import AppPreloader from '../../../../components/Preloader';

export interface ServiceUpdatePageProps {};

let errorResponse: Error, messageResponse: Message, serviceResponse: Service;

const ServiceUpdatePage: React.FunctionComponent<ServiceUpdatePageProps> = props => {
  const {setRoute, companyForUser, settingsForCompany, categoriesForCompany, accountingExpenseAccountsForCompany, accountingVatAccountsForCompany, accountingProductAccountsForCompany, loadServicesForCompany} = UseOutletContextService()
  const {lang} = StateLanguage()
  const param = useParams()
  const navigate = useNavigate()

  const [mounted, setMounted] = useState(false)
  const [loadIndicator, setLoadIndicator] = useState('off')
  const [name, setName] = useState({value: '', valid: false})
  const [description, setDescription] = useState({value: '', valid: false})
  const [category, setCategory] = useState({value: '', valid: false})
  const [code, setCode] = useState({value: '', valid: false})
  const [sku, setSku] = useState({value: '', valid: false})
  const [codebar, setCodebar] = useState({value: '', valid: false})
  const [price, setPrice] = useState({value: '', valid: false})
  const [taxRate, setTaxRate] = useState({value: '', valid: false})
  const [unit, setUnit] = useState({value: '', valid: false})
  const [discount, setDicount] = useState({value: {type: 'none', value: '', quantity: ''}, valid: {type: true, value: true, quantity: true}})
  const [names, setNames] = useState({value: {en: '', fr: '', it: '', de: ''}, valid: {en: true, fr: true, it: true, de: true}})
  const [accounts, setAccounts] = useState({value: {expense: '', vat: '', product: ''}, valid: {expense: false, vat: false, product: false}})
  const [photos, setPhotos] = useState({value: [] as {view: string, file: any}[], valid: {gallery: true, list: [] as {file: boolean, message: string}[]}})
  const [tags, setTags] = useState({value: [] as string[], valid: true})
  const [service, setService] = useState<Service | undefined | null>(null)

  const loadService = async (id_company: string, id_service: string) => {
    await ServiceDA.getService(id_company, id_service).then( (response) => {
      if (response.status === 200) {
        serviceResponse = response.data

        let tempPhotosValue: {view: string, file: any}[] = []
        let tempPhotosValid: {file: boolean, message: string}[] = []

        for (let photo of serviceResponse.photos) {
          let newPhotoValue: {view: string, file: any} = {
            view: photo,
            file: photo
          }
          let newPhotoValid: {file: boolean, message: string} = {
            file: true,
            message: lang.labels.valid
          }

          tempPhotosValue.push(newPhotoValue)
          tempPhotosValid.push(newPhotoValid)
        }

        setService(serviceResponse)
        setName({value: serviceResponse.display_name, valid: true})
        setDescription({value: serviceResponse.description, valid: true})
        setCategory({value: serviceResponse.category.id, valid: true})
        setCode({value: serviceResponse.code, valid: true})
        setSku({value: serviceResponse.sku, valid: true})
        setCodebar({value: serviceResponse.codebar, valid: true})
        setPrice({value: serviceResponse.price.toString(), valid: true})
        setTaxRate({value: serviceResponse.tax_rate, valid: true})
        setUnit({value: serviceResponse.unit.id, valid: true})
        setDicount({value: {type: serviceResponse.discount.type, value: serviceResponse.discount.value.toString(), quantity: serviceResponse.discount.quantity.toString()}, valid: {type: true, value: true, quantity: true}})
        setNames({value: serviceResponse.names, valid: {en: true, fr: true, it: true, de: true}})
        setAccounts({value: serviceResponse.accounts, valid: {expense: true, vat: true, product: true}})
        setPhotos({value: tempPhotosValue, valid: {gallery: true, list: tempPhotosValid}})
        setTags({value: serviceResponse.tags, valid: true})
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.code),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.okGotIt,
          customClass: {confirmButton: 'btn btn-primary'}
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      navigate('/error')
    })
  }

  const submitServiceUpdate = async () => {
    setLoadIndicator('on')

    let namesIsValid: boolean = isValidObjectAttributes(names.valid)
    let accountsIsValid: boolean = isValidObjectAttributes(accounts.valid)
    let discountIsValid: boolean = isValidObjectAttributes(discount.valid)
    let photosIsValid: boolean = isValidObjectAttributes(photos.valid.list)

    if (companyForUser && service && name.valid && description.valid && code.valid && sku.valid && codebar.valid && price.valid && taxRate.valid && unit.valid && category.valid && tags.valid && discountIsValid && namesIsValid && accountsIsValid && photosIsValid) {
      let listPhotos = photos.value.map((sub_item) => (sub_item.file))

        await ServiceDA.putService(companyForUser.id, service.id, name.value, description.value, code.value, sku.value, codebar.value, price.value, taxRate.value, unit.value, category.value, discount.value, names.value, accounts.value, tags.value, listPhotos).then( (response) => {
        if (response.status === 200) { 
          messageResponse = response.data

          loadServicesForCompany(companyForUser.id)

          Swal.fire({
            title: getMessage(messageResponse.message, lang.code),
            text: lang.labels.actionCompletedReturningToPage,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            navigate(-1)
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.code),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        navigate('/error')
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        if (name.value.length === 0) {
          legendInvalidIcon('input-name', 'legend-name-required')
          legendInvalidIcon('input-name-other', 'legend-name-other-required')
        }
        if (category.value.length === 0) {
          legendInvalid('legend-category-required')
        }
        if (description.value.length === 0) {
          legendInvalidIcon('input-description', 'legend-description-required')
        }
        if (unit.value.length === 0) {
          legendInvalid('legend-unit-required')
        }
        if (taxRate.value.length === 0) {
          legendInvalid('legend-tax-required')
        }
        if (price.value.length === 0) {
          legendInvalidIcon('input-price', 'legend-price-required')
        }
        if (accounts.value.expense.length === 0) {
          legendInvalid('legend-account-expense-required')
        }
        if (accounts.value.vat.length === 0) {
          legendInvalid('legend-account-vat-required')
        }
        if (accounts.value.product.length === 0) {
          legendInvalid('legend-account-product-required')
        }
        if (discount.value.type !== 'none') {
          if (discount.value.value.length === 0) {
            legendInvalidIcon('input-discount-value', 'legend-discount-value-required')
          }
          if (discount.value.quantity.length === 0) {
            legendInvalidIcon('input-discount-quantity', 'legend-discount-quantity-required')
          }
        }
        setLoadIndicator('off')
      })
    }
  }

  useEffect( () => {
    if (companyForUser && param.id) {
      loadService(companyForUser.id, param.id)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyForUser, param])

  useEffect( () => {
    setMounted(true)
    setRoute({path: {root: lang.labels.services, branch: lang.labels.updateService}, company: false})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { settingsForCompany && categoriesForCompany && accountingExpenseAccountsForCompany && accountingVatAccountsForCompany && accountingProductAccountsForCompany && service
        ?
        <AppServiceForm action={"update"} loadIndicator={loadIndicator} name={name} description={description} category={category} code={code} sku={sku} codebar={codebar} price={price} taxRate={taxRate} unit={unit} discount={discount} names={names} accounts={accounts} photos={photos} tags={tags} setName={setName} setDescription={setDescription} setCategory={setCategory} setCode={setCode} setSku={setSku} setCodebar={setCodebar} setPrice={setPrice} setTaxRate={setTaxRate} setUnit={setUnit} setDicount={setDicount} setNames={setNames} setAccounts={setAccounts} setPhotos={setPhotos} setTags={setTags} submitService={submitServiceUpdate}></AppServiceForm>
        :
        <AppPreloader></AppPreloader>
      }
    </div>
  )
};

export default ServiceUpdatePage;
