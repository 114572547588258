export const contentEnglish = {
  code: "EN",
  labels: {
    // App Digital Admin
    digitalAdmin: "Digital Admin",
    digitalAdminApp: "App Digital Admin",
    digitalAdminSarl: "Digital Admin SARL",
    digitalAdminPro: "Digital Admin Pro",
    welcomeToDigitalAdmin: "Welcome to Digital Admin",
    purchasePro: "Buy Digital Admin Pro",
    signIn: "Sign in to Digital Admin",

    // Words sentences
    about: "About",
    successful: "Successful",
    error: "Error",
    accountSettings: "Account settings",
    actions: "Actions",
    additional: "Additional",
    address: "Address",
    administrativeArea: "Administrative area / Department",
    amount: "Amount",
    amountTotal: "Total amount",
    apply: "Apply",
    approved: "Approved",
    ascending: "Ascending",
    addContact: "Add contact",
    addContent: "Add content",
    addSignature: "Add signature",
    avatar: "Avatar",
    back: "Back",
    bban: "BBAN",
    bicSwift: "BIC/SWIFT",
    bimester: "Bimester",
    calculatedValuesAreReferential: "Calculated values are referential",
    cancel: "Cancel",
    canceled: "Canceled",
    city: "City",
    comment: "Comment",
    completed: "Completed",
    companyContact: "Company contact",
    content: "Content",
    registerNumber: "Register number",
    concerning: "Subject",
    contactInformation: "Contact information",
    continue: "Continue",
    country: "Country",
    create: "Create",
    createdDate: "Creation date",
    currency: "Currency",
    customer: "Customer",
    date: "Date",
    day: "Day",
    days: "Days",
    default: "Default",
    delete: "Delete",
    descending: "Descending",
    description: "Description",
    detail: "Detail",
    details: "Details",
    discard: "Discard",
    doNotGroup: "Do not group",
    edit: "Edit",
    email: "Email",
    end: "End",
    endDate: "End date",
    excel: "Excel",
    export: "Export",
    filter: "Filter",
    filterOptions: "Filter options",
    firstName: "First name",
    group: "Group",
    halfYear: "Half year",
    iban: "IBAN",
    identity: "Identity",
    inProgress: "In progress",
    justNow: "Just now",
    lastDay: "Last day",
    lastName: "Last name",
    legalInformation: "Legal information",
    list: "List",
    location: "Location",
    menu: "Menu",
    message: "Message",
    noNumber: "No number",
    noMatchingRecordsFound: "No matching records found",
    month: "Month",
    months: "Months",
    number: "Number",
    sortAlphabetically: "Sort alphabetically",
    pages: "Pages",
    pdf: "PDF",
    pending: "Pending",
    person: "Person",
    phone: "Phone",
    phoneNumber: "Phone number",
    priceGross: "Gross price",
    priceNet: "Net price",
    priceTotal: "Total price",
    product: "Product",
    products: "Products",
    qrIban: "QR-IBAN",
    quantity: "Quantity",
    rejected: "Rejected",
    remove: "Remove",
    removeReference: "Remove reference",
    removeContent: "Remove content",
    removeSignature: "Remove signature",
    removeRequirement: "Remove block",
    removeVoucher: "Remove voucher",
    requirement: "Block",
    requirements: "Blocks",
    reset: "Reset",
    saveChanges: "Save changes",
    searchResults: "Search results",
    selectExportFormat: "Select export format",
    send: "Send",
    service: "Service",
    services: "Services",
    appService: "Application service",
    appServices: "Application services",
    signature: "Signature",
    signatures: "Signatures",
    start: "Start",
    startDate: "Start date",
    status: "Status",
    street: "Street",
    subTotal: "Subtotal",
    support: "Support",
    symbol: "Symbol",
    tax: "Tax",
    taxRate: "Tax rate",
    taxTotal: "Total tax",
    trimester: "Trimester",
    total: "Total",
    totalAmount: "Total amount",
    top: "Top",
    type: "Type",
    timeInterval: "Time interval",
    interval: "Interval",
    unitMeasure: "Unit of measure",
    unitPrice: "Unit price",
    update: "Update",
    validity: "Validity",
    value: "Value",
    vatNumber: "VAT/IDE number",
    webSite: "Website",
    week: "Week",
    weeks: "Weeks",
    view: "View",
    yes: "Yes",
    zipCode: "Zip code",
    gross: "Gross",
    net: "Net",
    rebate: "Rebate",
    rebateTotal: "Total rebate",
    selectOptionAddAmountPercentageDesired: "Select an option and add the desired amount or percentage",
    changeImage: "Change image",
    changeLogo: "Change logo",
    exportError: "Export error",
    failedToLoadTheScript: "Failed to load the script",
    identificationNumberForACompany: "Identification number of a company for european operations",
    internationalBankAccountNumberIban: "International bank account number (IBAN)",
    listOfCompaniesIHaveAccess: "List of companies I have access to",
    noRecordsFound: "No records found",
    noResultsFound: "No results found",
    removeImage: "Remove image",
    selectTheTypeOfContact: "Select the type of contact you want to create",
    specifyTheUniqueUUID: "Specify the unique UUID of a user or company",
    theDefaultBankAccountIsTheCompanys: "The default treasury account is the company's",
    theDefaultCurrencyIsTheCompanys: "The default currency is the company's",
    theDefaultShapeOrVersionOfAQrInvoiceToDownloadIsTheClients: "The default shape or version of a QR invoice to download is the client's",
    theDefaultLanguageToDownloadIsTheClients: "The default language to download is the client's",
    youMustAcceptTheTermsAndConditions: "You must accept the terms and conditions",
    acceptingInvitationToAccessCompany: "Accepting invitation to access the company",
    verificationYourAccount: "Verification of your account",
    addCompany: "Add company",
    allInLicense: "All-in license",
    buyNow: "Buy now",
    comingSoon: "Coming soon",
    licenseTerms: "License terms",
    pageNotFound: "Page not found",
    viewInvitations: "View invitations",
    purchase: "Purchase",
    reload: "Reload",
    systemError: "System error",
    dashboard: "Dashboard",
    abbreviation: "Abbreviation",
    amountPurchases: "Purchase amount",
    amountSales: "Sales amount",
    best: "Best",
    companyInsight: "Company insight",
    comparativeInterval: "Comparative interval",
    comparisonIncomeAndExpenses: "Comparison of income & expenses",
    customerPayments: "Customer payments",
    customRange: "Custom range",
    debtPercent: "Debt (%)",
    distributionOfQuotes: "Distribution of quotes by status",
    distributionOfOrders: "Distribution of orders by status",
    expirationDate: "Expiration date",
    invoicesStillToBePaid: "Invoices still to be paid",
    invoicesStillToBePaidByTheCustomer: "Invoices still to be paid by the customer",
    invoicesWithMoreDebt: "Invoices with more debt",
    ordersByState: "Orders by state",
    ordersToBilled: "Orders to be billed",
    paidPercent: "Paid (%)",
    percentage: "Percentage",
    quotesByState: "Quotes by state",
    quotesAccepted: "Accepted quotes",
    quotesApproved: "Approved quotes",
    quotesToExpire: "Quotes to expire",
    retailAnalysisSample: "Retail analysis sample",
    representationOfSalesAndPurchases: "Representation of sales and purchases per month over a year",
    salesVsPurchases: "Sales vs Purchases",
    thisBimester: "This bimester",
    thisTrimester: "This trimester",
    thisHalfYear: "This half year",
    thisMonth: "This month",
    thisWeek: "This week",
    thisYear: "This year",
    today: "Today",
    turnout: "Invoice amount",
    viewAll: "View all",
    worst: "Worst",
    yesterday: "Yesterday",
    fixedAmount: "Fixed amount",
    discount: "Discount",
    account: "Account",
    changeEmail: "Change email",
    changePassword: "Change password",
    confirmNewPassword: "Confirm new password",
    currentPassword: "Current password",
    emailAddress: "Email address",
    enterNewEmailAddress: "Enter a new email address",
    fullName: "Full name",
    newPassword: "New password",
    pleaseCheckYourEmailInbox: "Please check your email inbox",
    profileCompleation: "Profile completion",
    profileDetails: "Profile details",
    signInMethod: "Sign-in method",
    updateEmail: "Update email",
    updatePassword: "Update password",
    updateUser: "Update user",
    validateAccount: "Validate account",
    viewUser: "View user",
    user: "User",
    users: "Users",
    confirmPassword: "Confirm password",
    createAnAccount: "Create an account",
    enterTheNewPasswordForTheMail: "Enter the new password for the email",
    enterYourEmailToResetYourPassword: "Enter your email to reset your password",
    goToLoginPage: "Go to login page",
    goToPreviousPage: "Go to previous page",
    language: "Language",
    languages: "Languages",
    myProfile: "My profile",
    notVerified: "Not verified",
    password: "Password",
    resetPassword: "Reset password",
    signInHere: "Sign in here",
    signOut: "Sign out",
    submit: "Submit",
    userProfile: "User profile",
    use8OrMoreCharacters: "Use 8 or more characters with a combination of uppercase and lowercase letters, numbers, and symbols",
    verified: "Verified",
    welcome: "Welcome",
    company: "Company",
    companyParameterSettings: "Company parameter settings",
    companies: "Companies",
    current: "Current",
    addDeadlinePaymentParameter: "Add payment deadline parameter",
    addImage: "Add image",
    addTax: "Add tax",
    addDeadline: "Add deadline",
    addTaxRateParameter: "Add tax rate parameter",
    addUnit: "Add unit",
    addUnitMeasureParameter: "Add unit measure parameter",
    advanceSettings: "Advanced settings",
    aNewCompanyWillBeCreated: "A new company will be created based on the entered data",
    browseUsers: "Browse users",
    companyAddress: "Company address",
    companyAdvanceSettings: "Company advanced settings",
    companyDownloadSettings: "Company download settings",
    companyGeneralSettings: "Company general settings",
    companyIdentity: "Company identity",
    companyLogo: "Company logo",
    companyName: "Company name",
    createCompany: "Create company",
    createAndSave: "Create & Save",
    createNewCompany: "Create new company",
    designOfDocumentToDownloadQuotesAndInvoices: "Design of document for downloading quotes, orders, and invoices",
    downloadSettings: "Download settings",
    dueDates: "Due dates for invoice payment",
    editCompany: "Edit company",
    free: "Free",
    generalSettings: "General settings",
    helpPage: "Help page",
    image: "Image",
    inviteUser: "Invite a user",
    legal: "Legal",
    listUsers: "List of users",
    logo: "Logo",
    name: "Name",
    new: "New",
    noResultFound: "No result found",
    overview: "Overview",
    parameterSettings: "Parameter settings",
    pleaseTryAgainWithADifferentQuery: "Please try again with a different query",
    predefinedTaxesFees: "Predefined taxes fees",
    private: "Private",
    removeLogo: "Remove logo",
    selectModel: "Select model",
    selectAndSaveDesiredLayoutToDownload: "Select and save the desired layout to download the quote, order, and invoice",
    selectAndSaveOneToFourDesiredColors: "Select and save one to four desired colors for the quote, order, and invoice design",
    sendInvitation: "Send invitation",
    settings: "Settings",
    taxValue: "Tax value",
    templateColors: "Template colors",
    templateModel: "Template model",
    theEnteredInformationHasAlreadyValidated: "The entered information has already been validated, you can now confirm and create a new company",
    theNonRequiredFields: "The non-required fields that have been filled with incorrect data will be removed and not saved",
    unitsOfMeasure: "Units of measure for an invoice",
    uuidCode: "UUID code",
    uuidCompany: "UUID company",
    uuidOrName: "UUID/Name",
    usersWhoAreSubscribed: "Users who are subscribed and have access to the company",
    viewCompany: "View company",
    viewMoreUsers: "View more users",
    treasury: "Treasury",
    treasuryAccount: "Treasury account",
    treasuryAccounts: "Treasury accounts",
    codeOfBelongingToABankingOrganization: "Code of belonging to a banking organization",
    createNewTreasuryAccount: "Create new treasury account",
    createTreasuryAccount: "Create treasury account",
    listTreasuryAccounts: "List of treasury accounts",
    membership: "Membership",
    nameOfAccount: "Name of account",
    contactUs: "Contact us",
    typeOfTreasuryAccount: "Type of treasury account",
    updateTreasuryAccountData: "Update treasury account data",
    viewTreasuryAccountsList: "View treasury accounts list",
    notification: "Notification",
    notifications: "Notifications",
    accept: "Accept",
    accessToCompany: "Access to company",
    activities: "Activities",
    answers: "Answers",
    close: "Close",
    companyInvitations: "Company invitations",
    hasReceivedANotification: "Has received a notification",
    hasReceivedAQuote: "Has received a quote",
    hasReceivedAnInvitation: "Has received an invitation",
    hasReceivedAnOrder: "Has received an order",
    newNotification: "New notification",
    noNotifications: "No notifications",
    other: "Other",
    quoteEvaluation: "Quote evaluation",
    quoteNotification: "Quote notification",
    reject: "Reject",
    contact: "Contact",
    contacts: "Contacts",
    basicBankAccountNumber: "Basic treasury account number",
    bankIdentifierCode: "Bank identifier code / Society for worldwide interbank financial telecommunication",
    contactsList: "Contacts list",
    contactUUID: "Contact UUID",
    contactIdentity: "Contact identity",
    contactIsNotAnAppUser: "The contact is not an app user",
    contactType: "Contact type",
    contactUuidAndType: "Contact UUID and type",
    countryContact: "Country contact",
    createContact: "Create contact",
    createNewContact: "Create new contact",
    createContactCompanyStructure: "Create a company type contact structure",
    createContactPersonStructure: "Create a person type contact structure",
    deleteContact: "Delete contact",
    deleteSelected: "Delete selected",
    definingTheIdentityOfContact: "Defining the identity of the contact",
    definingTheInformationOfContact: "Defining the information of the contact",
    definingTheLegalInformationOfContact: "Defining the legal information of the contact",
    definingTheSettingsToDownloadQuotesAndInvoices: "Defining the settings to download quotes and invoices",
    dragOrClickOnTheBoxToAddTheCompanyLogo: "Drag or click on the box to add the company logo",
    editContact: "Edit contact",
    enterThePostalCodeOfTheCity: "Enter the postal code of the city",
    exportContacts: "Export contacts",
    exportEntryContacts: "Export accounting entry",
    extendChanges: "Extend changes",
    fileDownloadSettings: "File download settings",
    financialEntity: "Financial entity",
    identityOfContact: "Identity of contact",
    ifAddressDoesNotHaveANumber: "If the address does not have a number, enter 'nn' or 'NN'",
    ifNecessaryEnterSomethingReferentialRegardingAddress: "If necessary, enter something referential regarding the address",
    informationOfContact: "Information of contact",
    internationalBankAccountNumber: "International bank account number",
    languageToDownload: "Language to download quote, order or invoice",
    legalInformationOfContact: "Legal information of contact",
    qrType: "QR type",
    shapesOrVersionsOfQR: "Shapes or versions of a QR-Invoice",
    selectCompanyAndSave: "Select company and save",
    selectCompaniesToCreateContact: "Select the companies to which a new contact will be added",
    typeOfContact: "Type of contact",
    updateContactData: "Update contact data",
    uuidAndType: "UUID and type",
    userUuid: "User UUID",
    viewContact: "View contact",
    viewContactsList: "View contacts list",
    quote: "Quote",
    quotes: "Quotes",
    addQuote: "Add quote",
    approveQuote: "Approve quote",
    companyQuoteList: "Company quote list",
    createQuote: "Create quote",
    newQuote: "New quote",
    dateOfQuote: "Date of quote, this can be modified later",
    editQuote: "Edit quote",
    enterADateOrSelectADefaultPaymentDeadline: "Enter a date or select a default payment deadline",
    expired: "Expired",
    exportQuotes: "Export quotes",
    generateQuote: "Generate quote",
    inTime: "In time",
    listQuotes: "List of quotes",
    quoteDate: "Quote date",
    quoteGroups: "Quote groups",
    quoteNumber: "Quote number",
    quoteReference: "Quote reference",
    quoteStatus: "Quote status",
    quoteValidity: "Quote validity",
    rejectQuote: "Reject quote",
    sendQuote: "Send quote",
    updateQuote: "Update quote",
    viewQuote: "View quote",
    purchases: "Purchases",
    sales: "Sales",
    viewInvoices: "View invoices",
    viewOrders: "View orders",
    viewQuotes: "View quotes",
    viewProviderOrders: "View provider orders",
    viewProviderInvoices: "View provider invoices",
    viewExpenses: "View expenses",
    expenses: "Expenses",
    addExpense: "Add expense",
    createExpense: "Create expense",
    editExpense: "Edit expense",
    expenseDate: "Expense date",
    expenseGroups: "Expense groups",
    expenseNumber: "Expense number",
    expenseValidity: "Expense validity",
    invalidateExpense: "Invalidate expense",
    listExpenses: "List of expenses",
    removeExpense: "Remove expense",
    updateExpense: "Update expense",
    validateExpense: "Validate expense",
    viewExpense: "View expense",
    addProviderInvoice: "Add provider invoice",
    createProviderInvoice: "Create provider invoice",
    editProviderInvoice: "Edit provider invoice",
    invalidateProviderInvoice: "Invalidate provider invoice",
    listProviderInvoices: "List of provider invoices",
    myBankAccount: "My treasury account",
    provider: "Provider",
    providerBankAccount: "Provider treasury account",
    providerInvoiceDate: "Provider invoice date",
    providerInvoiceGroups: "Provider invoice groups",
    providerInvoiceStatus: "Provider invoice status",
    providerInvoiceValidity: "Provider invoice validity",
    providerInvoices: "Provider invoices",
    providerOrderNumber: "Provider order number",
    updateProviderInvoice: "Update provider invoice",
    validateProviderInvoice: "Validate provider invoice",
    viewProviderInvoice: "View provider invoice",
    addProviderOrder: "Add provider order",
    approveProviderOrder: "Approve provider order",
    cancelProviderOrder: "Cancel provider order",
    createProviderOrder: "Create provider order",
    editProviderOrder: "Edit provider order",
    generateProviderInvoice: "Generate provider invoice",
    generateProviderOrder: "Generate provider order",
    listProviderOrders: "List of provider orders",
    providerOrderDate: "Provider order date",
    providerOrders: "Provider orders",
    providerOrderGroups: "Provider order groups",
    providerOrderReference: "Provider order reference",
    providerInvoiceReference: "Provider invoice reference",
    expenseReference: "Expense reference",
    providerOrderStatus: "Provider order status",
    updateProviderOrder: "Update provider order",
    viewProviderOrder: "View provider order",
    addVouchers: "Add voucher(s)",
    download: "Download",
    file: "File",
    lastModified: "Last modified",
    size: "Size",
    vouchers: "Vouchers",
    addFiles: "Add files",
    attachFiles: "Attach files",
    removeAll: "Remove all",
    uploadFiles: "Upload files",
    orders: "Orders",
    order: "Order",
    orderEvaluation: "Order evaluation",
    orderGroups: "Order groups",
    orderNotification: "Order notification",
    orderReference: "Order reference",
    orderStatus: "Order status",
    addOrder: "Add order",
    approveOrder: "Approve order",
    cancelOrder: "Cancel order",
    companyListOfOrders: "Company List of orders",
    createOrder: "Create order",
    delivery: "Delivery",
    deliveyAddress: "Delivery address",
    deliveryNote: "Delivery note",
    editOrder: "Edit order",
    exportOrder: "Export order",
    exportOrders: "Export orders",
    generateInvoice: "Generate invoice",
    generateOrder: "Generate order",
    listOrders: "List orders",
    orderDate: "Order date",
    orderFile: "Order file",
    orderNumber: "Order number",
    selectExportType: "Select export type",
    sendOrder: "Send order",
    thisOptionIsUniqueAndNewInvoice: "This option is unique and a new invoice cannot be created from this order",
    updateOrder: "Update order",
    viewOrder: "View order",
    invoice: "Invoice",
    invoices: "Invoices",
    addInvoice: "Add invoice",
    amountDebt: "Amount debt",
    amountPaid: "Amount paid",
    companyInvoiceList: "Company invoice list",
    createInvoice: "Create invoice",
    editInvoice: "Edit invoice",
    exportInvoices: "Export invoices",
    debt: "Debt",
    fullyPaid: "Fully paid",
    invalidInvoices: "Invalid invoices",
    invalidateInvoice: "Invalidate invoice",
    invoiceGroups: "Invoice groups",
    invoiceDate: "Invoice date",
    invoiceNumber: "Invoice number",
    invoiceStatus: "Invoice status",
    invoiceValidity: "Invoice validity",
    listInvoices: "List invoices",
    notPayed: "Not payed",
    partiallyPaid: "Partially paid",
    payed: "Payed",
    thisInvoiceHasNoPayments: "This invoice has no payments",
    updateInvoice: "Update invoice",
    validated: "Validated",
    validateInvoice: "Validate invoice",
    viewInvoice: "View invoice",
    youWillBeAbleToUndoThisAction: "You will be able to undo this action",
    payment: "Payment",
    payments: "Payments",
    amountToPay: "Amount to pay",
    createNewPayment: "Create new payment",
    deleteSomePayment: "Delete some payment",
    doNotDeletePayments: "Do not delete payments",
    makePayment: "Make payment",
    method: "Method",
    paymentDate: "Payment date",
    paymentMethod: "Payment method",
    save: "Save",
    sourceOfIncome: "Source of income",
    sourceTheAmount: "Source where the amount of money for the transaction is entered",
    cash: "Cash",
    card: "Card",
    payPal: "PayPal",
    transfer: "Transfer",
    mobile: "Mobile",
    thisOptionIncludeTaxPrice: "This option includes tax in the indicated price",
    thisOptionNotIncludeTaxPrice: "This option does not include tax in the indicated price",
    addOrRemoveContactCompanies: "Add or remove contact companies",
    notifyMe: "Notify me",
    background: "Background",
    inventory: "Inventory",
    viewProducts: "View products",
    viewProduct: "View product",
    viewServices: "View services",
    viewCategories: "View categories",
    viewStorageCenter: "View storage center",
    appLanguage: "App language",
    theUuidCanBeUsedToCreateNnewContact: "The UUID can be used to create a new contact",
    settingInformation: "Setting information",
    addOrRemoveCompanies: "Add/Remove companies",
    notGrouped: "Not grouped",
    notFiltered: "Not filtered",
    orderValidity: "Order validity",
    expiration: "Expiration",
    quoteExpiration: "Quote expiration",
    orderExpiration: "Order expiration",
    invoiceExpiration: "Invoice expiration",
    discountTotal: "Discount total",
    addService: "Add service",
    addProduct: "Add product",
    noRecords: "No records",
    quantityMinimumForRebate: "Minimum quantity for rebate",
    thisOptionRebateIsFixedAmount: "This option indicates that the rebate is a fixed amount",
    thisOptionRebateIsPercentage: "This option indicates that the rebate is a percentage",
    thisOptionRebateIsNone: "This option indicates that the rebate is none",
    totalAfterRebates: "Total after rebates",
    calculator: "Calculator",
    selectTypeRebateAndEnterValueAndQuantity: "Select the rebate type and enter the value and quantity",
    reference: "Reference",
    newOrder: "New order",
    invoiceReference: "Invoice reference",
    newInvoice: "New invoice",
    providerOrderValidity: "Provider order validity",
    expenseStatus: "Expense status",
    newProviderOrder: "New provider order",
    newProvederInvoice: "New provider invoice",
    newExpense: "New expense",
    viewVoucher: "View voucher",
    informationAdditional: "Additional information",
    no: "No",
    bank: "Bank",
    fullNameBankAccountHolder: "Full name of the bank account holder",
    bankAccountHolder: "Bank account holder",
    isNecessaryToHaveReferenceToActivateOption: "It is necessary to have a reference to activate this option",
    isNecessaryToHaveAtLeastTwoTreasuryAccountsToActivateOption: "It is necessary to have at least two treasury accounts to activate this option",
    none: "None",
    undefined: "Undefined",
    colorCombinationForDesignOfDocumentToDownload: "Color combination for the design of the document to download",
    all: "All",
    lettersTitlesSubtitles: "Letters titles subtitles",
    firstBar: "First bar",
    secondBar: "Second bar",
    barLetters: "Bar letters",
    qrIbanAndQrReference: "QR-IBAN and QR-Reference",
    ibanAndStructuredCreditorReference: "IBAN and Structured Creditor Reference (SCOR)",
    ibanWithoutReference: "IBAN Without Reference",
    cashAccount: "Cash account",
    postalAccount: "Postal account",
    bankAccount: "Bank account",
    productsList: "Products list",
    category: "Category",
    categories: "Categories",
    listCategories: "List categories",
    tags: "Tags",
    variants: "Variants",
    photography: "Photography",
    reminder: "Reminder",
    reminders: "Reminders",
    paymentStatus: "Payment status",
    year: "Year",
    createReminder: "Create reminder",
    updateReminder: "Update reminder",
    makeCopy: "Make copy",
    reminderTotal: "Reminder total",
    inDispute: "In dispute",
    createReminderFee: "Create reminder fee",
    addCategory: "Add category",
    qtyAssets: "QTY assets",
    qtyServices: "QTY services",
    qtyProducts: "QTY products",
    viewCategory: "View category",
    editCategory: "Edit category",
    deleteCategory: "Delete category",
    names: "Names",
    listCategoryItems: "List of category items",
    editListing: "Edit listing",
    updateCategoryItemList: "Update category item list",
    updateCategory: "Update category",
    nameInOtherLanguages: "Name in other languages",
    enterInLanguageWant: "Enter in the language you want",
    enterNameOfRequestedLanguages: "Enter the name of the requested languages",
    enterOrModifyInLanguage: "Enter or modify in the language you want",
    general: "General",
    categoryName: "Category name",
    categoryType: "Category type",
    createCategory: "Create category",
    editProduct: "Edit product",
    deleteProduct: "Delete product",
    productReferencePictures: "Product reference pictures",
    code: "Code",
    sku: "SKU",
    codebar: "Barcode",
    color: "Color",
    unit: "Unit",
    price: "Price",
    fixed: "Fixed",
    productName: "Product name",
    productDetails: "Product details",
    advanced: "Advanced",
    addVariant: "Add variant",
    uniqueProductCodeAssignedByCompany: "Unique product code assigned by the company",
    additionalCommentDescriptionProductVariant: "Additional comment or description for the product variant",
    selectDiscountTypeAppliedToProduct: "Select a discount type to apply to this product",
    discountType: "Discount type",
    selectDiscountTypeThatWillAppliedProduct: "Select a discount type that will be applied to this product",
    noDiscount: "No discount",
    setDiscountPercentage: "Set discount percentage",
    fixedDiscountedAmount: "Fixed discounted amount",
    quantityMinimumDiscount: "Minimum quantity for discount",
    createProduct: "Create product",
    dropFilesHereOrClickUpload: "Drop files here or click to upload",
    dropFileHereOrClickUpload: "Drop file here or click to upload",
    uploadUpToFiles: "Upload up to 10 files",
    success: "Success",
    addNewProductVariant: "Add new product variant",
    editVariant: "Edit variant",
    editProductVariant: "Edit product variant",
    updateProduct: "Update product",
    saveVariant: "Save variant",
    servicesList: "Services list",
    viewService: "View service",
    editService: "Edit service",
    deleteService: "Delete service",
    serviceReferencePictures: "Service reference pictures",
    serviceName: "Service name",
    serviceDetails: "Service details",
    createService: "Create service",
    updateService: "Update service",
    uniqueServiceCodeAssignedByCompany: "Unique service code assigned by the company",
    selectDiscountTypeAppliedToService: "Select a discount type to apply to this service",
    expenseAccount: "Expense account",
    vatAccount: "VAT account",
    accountProduct: "Product account",
    accountingAccount: "Accounting account",
    accountingAccounts: "Accounting accounts",
    accountingAccountPurchases: "Accounting account for purchases",
    accountingAccountSales: "Accounting account for sales",
    addAccountingAccount: "Add accounting account",
    rangeAllowedCodes: "Range of allowed codes",
    effective: "Effective",
    ntdr: "Net tax debt TDNF",
    taf: "Fixed rate",
    vatSpecialRegime: "Special VAT regime",
    methodAapply: "Method to apply",
    theVatRegimeCanOnlyChangedOnceAYear: "The VAT regime can only be changed once a year, just before starting a new accounting year",
    debit: "Debit",
    credit: "Credit",
    customerReceivable: "Customer receivable",
    vatDue: "VAT due",
    vatDebtMethod: "VAT debt method",
    invoicing: "Invoicing",
    cashing: "Cashing",
    valid: "Valid",
    invalid: "Invalid",
    colorIsInvalid: "Invalid color",
    theValueIsNotAValid: "The value is not valid",
    theValueIsNotAValidUUIDOrNameUser: "The value is not a valid UUID or username",
    theValueIsAtLeast8Digits: "The value is at least 8 digits",
    theValueDoesNotMatch: "The value does not match",
    theValueAlreadyExists: "The value already exists",
    limitFilesMaximum: "Maximum limit of 10 files",
    required: "Required",
    theValueIsRequired: "The value is required",
    colorIsRequired: "Color is required",
    requirementsIsRequired: "Blocks are required",
    contentsToRequirementIsRequired: "Block content is required",
    transaction: "Transaction",
    createNewPaymentForInvoice: "Create new payment for invoice",
    addTransaction: "Add transaction",
    accountingEntry: "Accounting entry",
    amountAvailable: "Amount available",
    selectExistingTransaction: "Select existing transaction",
    totalAmountDebt: "Total amount debt",
    presetCurrency: "Preset currency",
    depositAmount: "Deposit amount",
    providerInvoice: "Provider invoice",
    expense: "Expense",
    viewAccountingEntry: "View accounting entry",
    enterNewTransaction: "Enter a new transaction",
    useExistingTransaction: "Use an existing transaction",
    transactionToRecordPayment: "Transaction to be able to record the payment",
    amountMoneyEnteredInTransaction: "Amount of money entered in the transaction",
    identifiedTransactions: "Identified transactions",
    unidentifiedTransactions: "Unidentified transactions",
    totalDepositAmount: "Total deposit amount",
    makeCorrespondingPayment: "Make the corresponding payment",
    balance: "Balance",
    accountingEntries: "Accounting entries",
    contactAccountStatements: "Account statements by contact",
    contactBalance: "Contact balance",
    accountStatement: "Account statement",
    invoiceIssuance: "Invoice issuance",
    allocationInvoiceReminder: "Allocation for invoice reminder",
    transactionPostFinance: "PostFinance account transaction",
    balancePrevious: "Previous balance",
    balanceFavorContact: "Balance in your favor",
    balanceFavorCompany: "Balance in our favor",
    addCredit: "Add credit",
    createClientCredit: "Create a client credit",
    unidentifiedTransactionToRegisterInContact: "Unidentified transaction to register in the contact",
    draft: "Draft",
    beforeCreatingProviderInvoice: "Before creating an provider invoice",

    // Start sentence
    selectOneOption: "Select one of the options,",
    iAgree: "I agree &",
    youWantToChangeOrderStatusTo: "Do you want to change the order status to",
    createdAt: "Created at",
    updatedAt: "Updated at",
    validUntil: "Valid until",
    clickOn: "Click on",
    thisContactWillOnlyBeRegisteredInCompany: "This contact will only be registered in the company",
    securedPaymentBy: "Secured payment by",
    anInvitationWillBeSent: "An invitation will be sent for a user to have access to the company",
    ifYouNeedMoreInfo: "If you need more information, check at",
    toFinishCreationNewCompany: "To finish creating the new company, click on",
    theInvitationToAccess: "The invitation to access the company by the user",
    theQuoteThatYouSent: "The quote you sent to the company",
    youHaveAlreadyEvaluatedOrder: "You have already evaluated the company's order",
    youHaveAlreadyEvaluatedQuote: "You have already evaluated the company's quote",
    youHaveAlreadyRespondedTo: "You have already responded to",
    youHaveReceivedAQuote: "You have received a quote from the company",
    ifYouDoNotWishToExtendContactModificationsToOtherCompanies: "If you do not wish to extend contact modifications to other companies, do not check any and select",
    youWantToChangeQuoteStatusTo: "Do you want to change the quote status to",
    youWantToChangeProviderOrderStatusTo: "Do you want to change the provider order status to",
    theOrderThatYouSent: "The order you sent to the company",
    youHaveReceivedAnOrder: "You have received an order from the company",
    theCurrentCompanyIs: "The current company is",
    toCreateA: "To create a",
    createdBy: "Created by",
    groupBy: "Group by",
    checkOrUncheck: "Check or uncheck the",
    ifYouWishChangeVatRegimePleaseContact: "If you wish to change the VAT regime, you need to contact",
    ifYouInvalidate: "If you invalidate the invoice",
    the: "The",
    planYourBusinessApplications: "Plan your business applications by choosing one of",
    yourAccountIsNotVerified: "Your account is not verified. Validate your account through your email address by clicking on",

    // End sentence
    companyPleaseEvaluateQuote: ", please evaluate the quote.",
    theAssociatedAccountingEntriesWillBeDeleted: ", the associated accounting entries will be deleted. This means that you will not be able to make payments or generate reminder fees related to this invoice.",
    ifYouWishToSaveTheChangesMade: "if you wish to save the changes made.",
    youWantToRun: "that you want to run.",
    toContinue: "to continue.",
    waitAMomentToVerifyYourAccess: "wait a moment to verify your access.",
    hasSentARequestForAccessToTheFollowingCompany: "has sent a request for access to the following company, please respond.",
    ifYouWantToSaveTheNewQuote: "if you want to save the new quote.",
    ifYouWantToSaveTheNewQuoteWithTheChangesMade: "if you want to save the new quote with the changes made.",
    ifYouWantToSaveTheNewInvoiceWithTheChangesMade: "if you want to save the new invoice with the changes made.",
    ifYouWantToSaveTheNewExpense: "if you want to save the new expense.",
    ifYouWantToSaveTheNewProviderInvoice: "if you want to save the new provider invoice.",
    ifYouWantToSaveTheNewProviderOrder: "if you want to save the new provider order.",
    ifYouWantToSaveTheNewProviderOrderWithTheChangesMade: "if you want to save the new provider order with the changes made.",
    ifYouWantToSaveTheNewProviderInvoiceWithTheChangesMade: "if you want to save the new provider invoice with the changes made.",
    ifYouWantToSaveTheNewOrder: "if you want to save the new order.",
    ifYouWantToSaveTheNewOrderWithTheChangesMade: "if you want to save the new order with the changes made.",
    companyPleaseEvaluateTheOrder: "company, please evaluate the order.",
    ifYouWantToSaveTheNewInvoice: "if you want to save the new invoice.",
    youWillNotBeAbleToRemoveItFromThisCompany: ", you will not be able to remove it from this company, but you can select the other companies.",
    inThisCategory: "in this category.",
    theCompanyContainTreasutyAccount: "the company must contain at least one treasury account.",
    toBeAddedInOrderToDownloadTheInvoice: "must be added to download the invoice.",

    // All sentence
    youAreWishingToEnterWithTheFollowingEmailAddress: "You wish to enter with the following email address:",
    ifYouChangeYourMind: "If you change your mind, request a new application to be sent.",
    messageWillBeSentYourEmailToRecoverAccount: "A message will be sent to your email to recover the account, please check your email. You will be redirected to the login page.",
    nowYouCanLogIn: "You can now log in with the newly created account. You will be redirected to the login page.",
    newCompanyIsSelectedByDefault: "The new company is selected by default and redirected to the main page.",
    notificationWillBeDeleted: "The invitation notification to access the company will be deleted.",
    theAppWillBeTranslated: "The application will be translated into the selected language, except for the information entered in the documents.",
    userWillRespondInvitation: "The user will respond whether they accept or decline the invitation to access the company.",
    youWillNotBeAbleToUndo: "You will not be able to undo this action.",
    tokenValidForAUserWhoHasNotLoggedIn: "Token valid for a user who has not logged in, redirecting to the login page.",
    toValidateYourAccount: "To validate your account, a validation email will be sent from which you can verify your account and log in again.",
    validatedAccountForLoggedInUser: "Account validated for the logged-in user, redirecting to the main page.",
    validatedAccountForADifferentUser: "Account validated for a different user than the one currently logged in, redirecting to the login page.",
    validatedVerifiedForAUserWhoHasNotLoggedIn: "Validated and verified for a user who has not logged in, redirecting to the login page.",
    newServicesWithinTheSameApp: "New services within the same application.",
    pageInProduction: "Page in production, soon you will be able to access new functionalities within the application.",
    pleaseCorrectThePathOfThePageInOrderToAccess: "Please correct the page path to access the application.",
    pleaseTryAgainLater: "Please try again later.",
    thereAreNoCompaniesAddedYet: "No companies have been added yet.",
    tokenValidForAnUserThatIsDifferentFromLoggedIn: "Token valid for a user different from the logged-in one, redirecting to the login page.",
    validTokenForTheUserThatLoggedIn: "Valid token for the logged-in user, redirecting to the dashboard page.",
    redirectingToLogin: "Redirecting to the login page.",
    willNotBeAbleToReturnUntilYouLogin: "You will not be able to return until you log in again.",
    allTheFieldsAreValidated: "All fields are validated. If incorrect data is entered in a required form, you will not be able to proceed to the next step. However, if incorrect data is entered in a non-required form, it will not be saved.",
    pleaseClickContinue: "Click continue to enter the details of the new company to be created.",
    searchUserForInvitate: "Search for the user by their UUID or name and send a notification to access the company.",
    theFileMustBeFormat: "The file must be in jpeg, jpg, or png format.",
    ifYouHaveAnyQuestionsContactUs: "If you have any questions, you can contact us.",
    noNotificationsSeenYet: "No notifications have been seen yet.",
    thereAreNoNotifications: "No notifications have been received yet.",
    byDefaultValuesOfTheCompanyAreTaken: "By default, the company values are taken.",
    selectTheOptionsThatBestConsiderWhenDownloading: "Select the options you consider best when downloading a quote or an invoice in PDF format.",
    notificationsAboutInvitationsToSubscribe: "Notifications about invitations to subscribe and gain access to a company.",
    whenCreatingAContactByUUID: "When creating a contact by UUID, you will not be able to modify certain fields depending on the type: person (name, surname, and email) and company (name).",
    youCanDirectlyEnterUUIDOfAUserOrCompanyToAddAsContact: "You can directly enter the UUID of a user or company to add as a contact. Otherwise, choose the type of contact you want to create.",
    descriptionCreateQuote: "Enter the following details, they are required to create a new quote.",
    descriptionCreateOrder: "Enter the following details, they are required to create a new order.",
    descriptionCreateInvoice: "Enter the following details, they are required to create a new invoice.",
    sorryLooksLikeThereAreSomeErrorstryAgain: "Sorry, it looks like some errors were detected, please try again.",
    sorryLooksLikeThereAreSomeErrorsTrySolve: "Sorry, it looks like some errors were detected, please try to resolve them.",
    sorryThereIsNothingToAdd: "Sorry, there is nothing to add, please try again.",
    thankYouVeryMuchForSubscribingRedirectingToPreviousPage: "Thank you very much for subscribing, redirecting to the previous page.",
    actionCompletedReturningToPage: "Action completed, returning to the page.",
    actionCompletedRedirectingToPage: "Action completed, redirecting to the page.",
    pleaseModifyAddOrDeleteFieldsFromQuote: "Please modify, add, and/or delete the fields you want from the quote.",
    thisOptionIsUniqueAndNewOrderCannotBeCreatedFromThisQuote: "This option is unique, and a new order cannot be created from this quote.",
    pleaseEnterTheFollowingDataToCreateANewProviderInvoice: "Enter the following details, they are required to create a new provider invoice.",
    pleaseModifyAddOrDeleteFieldsFromProviderInvoice: "Modify, add, and/or delete the fields you want from the provider invoice.",
    pleaseModifyAddOrDeleteFieldsFromExpense: "Modify, add, and/or delete the fields you want from the expense.",
    pleaseEnterTheFollowingDataToCreateANewExpense: "Please enter the following details, they are required to create a new expense.",
    pleaseEnterThFollowingDataToCreateANewProviderOrder: "Enter the following details, they are required to create a new provider order.",
    pleaseModifyAddAndOrDeleteFieldsFromProviderOrder: "Modify, add, and/or delete the fields you want from the provider order.",
    fileInDifferentFormat: "File in a different format. File format: '.pdf'.",
    fileIsTooBig: "The file is too big. Maximum file size: 1 MB.",
    fileFormatMaximum1MBPerFile: "File format is '.pdf' and the maximum file size is 1 MB.",
    someOfTheUploadedFilesDoNotMeetTheRequiredCharacteristics: "Some of the uploaded files do not meet the required characteristics.",
    pleaseModifyAddOrDeleteFieldsFromOrder: "Modify, add, and/or delete the fields you want from the order.",
    pleaseModifyAddOrDeleteFieldsFromInvoice: "Modify, add, and/or delete the fields you want from the invoice.",
    thisAmountCanNotBeGreater: "This amount cannot be greater than the total debt of the invoice.",
    toCreateAnDocument: "To create a document, the company must contain at least one treasury account.",
    newContactWwillAddedToCompanyAndSelectDefault: "New contact will be added to the company and selected as default, returning to page.",
    youHaveSuccessfullyResetYourPassword: "You have successfully reset your password, you can now log in again. You will be redirected to the login page.",
    kickstartYourBusinessByAddingAYourFirstCompany: "Kickstart your business by adding your first company.",
    ifNoAmountIsEnteredTheValueWillBeZero: "If no amount is entered, the value will be 0.",
    ifYouWishYouCanAssignNameOfCategoryInLanguagesNecessary: "If you wish, you can assign the name of the category in the necessary languages.",
    productNameRequiredAndRecommendedUnique: "A product name is required, and it is recommended to be unique.",
    addProductToCategory: "Add a product to a category.",
    addTagsToProduct: "Add tags to the product, they are optional.",
    setProductMediaGallery: "Set up the product media gallery.",
    serviceNameRequiredAndRecommendedUnique: "A service name is required, and it is recommended to be unique.",
    addServiceToCategory: "Add a service to a category.",
    addTagsToService: "Add tags to the service, they are optional.",
    setServiceMediaGallery: "Set up the service media gallery.",
    aCategoryContainServicesProducts: "A category can contain both services and products.",
    ifYouBelongToRegimeMoreInformationIsRequired: "If you belong to a regime, more information will be required.",
    toPerformAnySubsequentActionWithInvoice: "To perform any subsequent action with the invoice, it must be revalidated by specifying the accounting accounts corresponding to each associated product and/or service.",
    validatingInvoiceSystemWillCreateToFollowingAccountingEntries: "By validating the invoice, the system will create the following accounting entries. Confirm the accounting accounts for each service or product in the invoice.",
    allowedFileTypes: "Allowed file types: png, jpg, jpeg.",
    whenYouUploadFileItWillPlacedReferenceProviderInvoiceCreationForm: "When you upload a PDF file, it will be placed as a reference in the provider invoice creation form.",

    // Lowercase sentences
    yearl: "year",
    years: "years",
    hour: "hour",
    hours: "hours",
    minute: "minute",
    minutes: "minutes",
    information: "information",
    items: "items",
    groups: "groups",
    selected: "selected",
    accountNeedsA: "account needs a",
    options: "options",
    with: "with",
    bestCustomers: "best customers",
    byRecentUpdates: "by recent updates",
    byTimeRange: "by time range",
    template: "template",
    evaluated: "evaluated",
    noMailFound: "no mail found",
    report: "report",
    reports: "reports",
    requestOnAccess: "request on company access, your response was",
    userHasBeen: "has been",
    yourAnswerHasBeen: ", your answer has been",
    toUser: "to user",
    hasBeen: "has been",
    byYear: "by year",
    or: "or",
    quotesToExpireThisMonth: "quotes to expire this month",
    worstCustomers: "worst customers",
    button: "button",
    termsAndConditions: "terms and conditions",
    and: "and",
    ourServicesOrRequesting: "our services or requesting a new one",
    toAddOrRemoveCategory: "that you want to add or remove from the category",
    orCall: "or call",
    invoicesNotYetPaid: "invoices not yet paid",

    // Interrogative sentences
    isDeliveryAddressTheSameAsCustomersAddress: "Is the delivery address the same as the customer's address?",
    youWantLogOut: "Do you want to log out?",
    doesItTakeALongTimeToLoadTheApp: "Does it take a long time to load the app?",
    alreadyHaveAnCccount: "Do you already have an account?",
    forgotPassword: "Forgot your password?",
    newHere: "New here?",
    canNotFindTheUserYouWant: "Can't find the user you want?",
    selectThisTreasuryAccountAsTheMainOne: "Select this treasury account as the main one?",
    youWantToDeleteThisTreasuryAccount: "Do you want to delete this treasury account?",
    youWantToSelectThisTreasuryAccountAsPrimary: "Do you want to select this treasury account as primary?",
    wantToExtendChangesToOtherCompanies: "Do you want to extend the changes to other companies?",
    youWantToDeleteThisContact: "Do you want to delete this contact?",
    youWantToDeleteSelectedContacts: "Do you want to delete the selected contacts?",
    youWantSendQuote: "Do you want to send the quote to the customer?",
    youWantToChangeQuoteStatusRejected: "Do you want to change the quote status to rejected?",
    youWantToGenerateAnOrderFromThisQuote: "Do you want to generate an order from this quote?",
    youWantToChangeStatusTheExpense: "Do you want to change the expense status?",
    youWantToDeleteThisExpense: "Do you want to delete this expense?",
    youWantToChangeStatusTheProviderInvoice: "Do you want to change the provider invoice status?",
    youWantToGenerateAProviderInvoiceFromThisProviderOrder: "Do you want to generate a provider invoice from this provider order?",
    youWantToChangeOrderStatusToCanceled: "Do you want to change the order status to canceled?",
    youWantToGenerateAnInvoiceFromThisOrder: "Do you want to generate an invoice from this order?",
    youWantToSendTheOrderToCustomer: "Do you want to send the order to the customer?",
    youWantToDeleteThisPayment: "Do you want to delete this payment?",
    youWantToChangeStatusTheInvoice: "Do you want to change the invoice status?",
    isCompanyOwnerOfBankAccount: "Is the company the owner of the bank account?",
    useReferenceWithinQR: "Use a reference within the QR?",
    youWantDeleteProduct: "Do you want to delete this product?",
    youWantDeleteSelectedProducts: "Do you want to delete the selected products?",
    youWantToDeleteThisReminder: "Do you want to delete this reminder?",
    youWantToDeleteThisCategory: "Do you want to delete this category?",
    youWantToDeleteSelectedCategories: "Do you want to delete the selected categories?",
    youWantDeleteService: "Do you want to delete this service?",
    youWantDeleteSelectedServices: "Do you want to delete the selected services?",
    isCompanySubjectToVatRegime: "Is the company subject to a VAT regime?",
    doYouWantUploadPdfFileToFacilitateRregistrationInvoice: "Do you want to upload a PDF file to facilitate the registration of a new invoice",

    // Exclamative sentences
    important: "Important!",
    yourSessionHasBeenClosed: "Your session has been closed, please sign in!",
    yourSessionHasExpired: "Your session has expired, please sign in again!",
    accountNotVerifiedYet: "Account not verified yet!",
    moneyBackGuarantee: "100% money-back guarantee!",
    somethingWentWrong: "Something went wrong!",
    thePageYouLookedNotFound: "The page you looked for was not found!",
    yourSessionIsActiveCongratulations: "Your session is active, congratulations!",
    weNeedYourAttention: "We need your attention!",
    yourAreDone: "You're done!",
    startCreatingNewTreasuryAccountsForYourCompany: "Start creating new treasury accounts for your company, it's very important for the app's functionality!",
    getNotifiedWhenWeLaunch: "Get notified when we launch!",
    processOfCreatingANewInvoiceCompleted: "Process of creating a new invoice completed!",
    processOfUpdatingAInvoiceCompleted: "Process of updating an invoice completed!",
    thereAreNoTreasuryAccounts: "There are no treasury accounts!",
    incorrectFiles: "Incorrect files!",
    invalidFileType: "Invalid file type! Allowed file types: jpeg, jpg, or png",
    fileSizeNotAllowed: "File size not allowed! Maximum file size: 1 MB",
    startAddingNewElementsToThisCategory: "Start adding new elements to this category!",
    accountingAccountAlreadySelected: "Accounting account already selected, please select another!",
    servicesProductsWithTaxGreaterThanZero: "Services or products with tax greater than zero!",
    youHaveSuccessfullySignedOut: "You have successfully signed out!",
    selectContactViewTheirAccountStatement: "Select a contact to view their account statement!",
    processOfCreatingANewOrderCompleted: "Process of creating a new order completed!",
    processOfUpdatingAnOrderCompleted: "Process of updating an order completed",
    processOfCreatingQuoteCompleted: "Process of creating a new quote completed!",
    processOfCreatingAQuoteFromAnotherQuoteCompleted: "Process of creating a quote from another quote completed!",
    processOfCreatingAOrderFromAnotherOrderCompleted: "Process of creating an order from another order completed!",
    processOfCreatingAInvoiceFromAnotherInvoiceCompleted: "Process of creating an invoice from another invoice completed!",
    processOfUpdatingAQuoteCompleted: "Process of updating a quote completed!",
    processOfCreatingANewExpenseCompleted: "Process of creating a new expense completed!",
    processOfUpdatingAnExpenseCompleted: "Process of updating an expense completed!",
    processOfCreatingANewProviderInvoiceCompleted: "Process of creating a new provider invoice completed!",
    processOfUpdatingAProviderInvoiceCompleted: "Process of updating a provider invoice completed!",
    processOfCreatedAProviderOrderCompleted: "Process of creating a provider order completed!",
    processOfCreatedAProviderInvoiceCompleted: "Process of creating a provider invoice completed!",
    processOfCreatingANewProviderOrderCompleted: "Process of creating a new provider order completed!",
    processOfUpdatingAProviderOrderCompleted: "Process of updating a provider order completed!",
    successfullyCreatedAccountingAccount: "Successfully created accounting account!",
    successfullyCreatedCategory: "Successfully created category!",
    successfullyUpdatedCategory: "Successfully updated category!",
    successfullyInvitationSent: "Invitation sent successfully!",
    successfullyInvitationCancel: "Invitation canceled successfully!",
    successfullyCreatedContact: "Contact created successfully!",
    successfullyUpdatedContact: "Contact updated successfully!",
    successfullyCreatedReminder: "Reminder created successfully!",
    successfullyUpdatedStatus: "Status updated successfully!",
    successfullyUpdatedReminder: "Reminder updated successfully!",
    successfullyCreatedPayment: "Payment created successfully!",
    successfullyCreatedCompanyParameters: "Company parameters created successfully!",
    successfullyUpdatedTreasuryAccount: "Treasury account updated successfully!",
    successfullyAcceptedInvitation: "Invitation accepted successfully!",
    successfullyLoggedIn: "You have successfully logged in!",
    successfullyUpdatedCompanySettings: "Company settings updated successfully!",
    successfullyCreatedCompany: "Company created successfully!",
    successfullyDeletedPayment: "Payment deleted successfully!",
    successfullyCreatedInvoice: "Invoice created successfully!",
    successfullyDeletedReminder: "Reminder deleted successfully!",
    successfullyOrderSent: "Order sent successfully!",
    successfullyQuoteSent: "Quote sent successfully!",
    successfullyCreatedOrder: "Order created successfully!",
    successfullyCreatedTreasuryAccount: "Treasury account created successfully!",
    successfullyUpdatedDefaultTreasuryAccount: "Default treasury account updated successfully!",
    successfullyDeletedTreasuryAccount: "Treasury account deleted successfully!",
    successfullyCreatedAccountingEntry: "Accounting entry created successfully!",
    successfullyUpdatedAccountingEntry: "Accounting entry updated successfully!",

    // Answer sentences
    okGotIt: "Ok, got it",
    yesLogOut: "Yes, log out",
    noCancel: "No, cancel",
    noContinue: "No, continue",
    yesDelete: "Yes, delete",
    yesResolveError: "Yes, resolve the error",
    yesSelect: "Yes, select",
    yesCanceled: "Yes, cancel",
    okClose: "Ok, close",
    yesReject: "Yes, reject",
    yesGenerate: "Yes, generate",
    yesSend: "Yes, send",
    yesChangeStatus: "Yes, change status",
    yesCreateTreasuryAccount: "Yes, create treasury account",
    yesValidate: "Yes, validate",
    yesInvalidate: "Yes, invalidate",
    yesUpload: "Yes, upload",

    // Suspensive sentences
    pleaseWait: "Please wait...",
    selectOption: "Select option...",
    searchContact: "Search for contact and select...",
    searchCategory: "Search for category and select...",
    searchProduct: "Search for product and select...",
    searchService: "Search for service and select...",
    search: "Search...",
    writeCommentToInvite: "Write a comment for the invitation...",
    loading: "Loading...",

    // Abbreviated sentences
    n: "N°",
    mb: "MB",
    hrs: "hrs",
    min: "min",
    sec: "sec",

    // Enumeration
    first: "first",
    second: "second",
    third: "third",
    fourth: "fourth",
    fifth: "fifth",
    sixth: "sixth",

    // Languages
    english: "English",
    french: "French",
    italian: "Italian",
    german: "German",
    spanish: "Spanish",

    // Months
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
  }
}