import React from 'react';

export interface AppPreloaderProps {};

const AppPreloader: React.FunctionComponent<AppPreloaderProps> = () => {
  return (
    <div className="page-preloader d-flex justify-content-center align-items-center">
      <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
  )
}

export default AppPreloader;
