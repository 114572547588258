// import { getCode, getCodeList, getName, getNameList } from 'country-list';
import { countries } from '../languages/translations/countries';

const codeEnMap: { [key: string]: string } = {}
const codeEsMap: { [key: string]: string } = {}
const codeFrMap: { [key: string]: string } = {}
const nameEnMap: { [key: string]: string } = {}
const nameEsMap: { [key: string]: string } = {}
const nameFrMap: { [key: string]: string } = {}

function mapCodeAndName(country: any): void {
  nameEnMap[country.en.toLowerCase()] = country.code
  nameEsMap[country.es.toLowerCase()] = country.code
  nameFrMap[country.fr.toLowerCase()] = country.code
  codeEnMap[country.code.toLowerCase()] = country.en
  codeEsMap[country.code.toLowerCase()] = country.es
  codeFrMap[country.code.toLowerCase()] = country.fr
}

countries.forEach(mapCodeAndName)

export function countryList(code_language: string): string[] {
  /* getCodeList()
  getNameList() */

  let key = code_language.toLowerCase() as 'en' | 'es' | 'fr'
  return countries.map((item) => (item[key] || item.en))
}

export function countryCode(code_language: string, name: string): string {
  /* getCode(name) */

  let key = code_language.toLowerCase() as 'en' | 'es' | 'fr'
  return (key === 'es' ? nameEsMap : key === 'fr' ? nameFrMap : nameEnMap)[name.toLowerCase()]
}

export function countryName( code_language: string, code: string): string {
  /* getName(code) */

  let key = code_language.toLowerCase() as 'en' | 'es' | 'fr'
  return (key === 'es' ? codeEsMap : key === 'fr' ? codeFrMap : codeEnMap)[code.toLowerCase()]
}
