import React, { Dispatch, SetStateAction, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import TextareaAutosize from 'react-textarea-autosize';
import { useNavigate } from 'react-router-dom';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { getError, getMessage } from '../../languages/translations/response';
import { OrderDA } from '../../services/order.service';
import { NotificationDL } from '../../services/notification.service';
import { Error } from '../../models/error.model';
import { Message } from '../../models/message.model';
import { AppService } from '../../models/app.service.model';
import { Company } from '../../models/company.model';
import { Order } from '../../models/order.model';
import { legendInvalid, legendValidInvalidRestart } from '../../tools/legend.data.entry.tool';
import { modalHide } from '../../tools/modal.tool';
import { toastError, toastSuccess } from '../../tools/toast.tool';
import { evaluateLegendValidRequired } from '../../scripts/validate.legend.script';
import { expressions } from '../../libraries/regular.expressions.library';

import AppLegend from '../element/Legend';

export interface AppModalOrderNotificationProps {
  appServiceForUser: AppService | undefined | null,
  companyForUser: Company | undefined | null,
  order: Order | undefined | null,
  setOrder: Dispatch<SetStateAction<Order | undefined | null>>
};

let errorResponse: Error, messageResponse: Message, orderResponse: Order;

const AppModalOrderNotification: React.FunctionComponent<AppModalOrderNotificationProps> = ({appServiceForUser, companyForUser, order, setOrder}) => {
  const {lang} = StateLanguage()
  const navigate = useNavigate()

  const [loadIndicator, setLoadIndicator] = useState('off')
  const [comment, setComment] = useState({value: '', valid: false})

  const submitOrderStatus = async () => {
    setLoadIndicator('on')

    if (appServiceForUser && companyForUser && order && comment.valid) {
      let statusOrder: string = 'canceled'

      await OrderDA.putOrderStatus(companyForUser.id, order.id, statusOrder).then( async (response) => {
        if (response.status === 200) {
          orderResponse = response.data

          if (order.customer.is_user) {
            let typeNotification: string = 'activity'
            let descriptionNotification: string = 'order evaluation'
            let responseNotification: string = 'cancel'

            await NotificationDL.postNotification(appServiceForUser.api_key, typeNotification, descriptionNotification, order.customer.data.uuid, comment.value, responseNotification, null, orderResponse).then( (response) => {              
              setOrder(orderResponse)

              if (response.status === 201) {
                messageResponse = response.data
                toastSuccess(getMessage(messageResponse.message, lang.code))
              } else {
                errorResponse = response.data
                toastError(getError(errorResponse.code, lang.code))
              }

              Swal.fire({
                title: lang.labels.successfullyUpdatedStatus,
                text: lang.labels.actionCompletedReturningToPage,
                icon: 'success',
                showConfirmButton: false,
                timer: 1800
              } as SweetAlertOptions).then( () => {
                setLoadIndicator('off')
                executeHideModalOrderExport()
              })
            }).catch( (error) => {
              console.error(error)
              navigate('/error')
            })
          } else {
            setOrder(orderResponse)

            Swal.fire({
              title: lang.labels.successfullyUpdatedStatus,
              text: lang.labels.actionCompletedReturningToPage,
              icon: 'success',
              showConfirmButton: false,
              timer: 1800
            } as SweetAlertOptions).then( () => {
              setLoadIndicator('off')
              executeHideModalOrderExport()
            })
          }
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.code),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions)
        }
      }).catch( (error) => {
        console.error(error)
        navigate('/error')
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        if (comment.value.length === 0) {
          legendInvalid('modal-order-notification-legend-comment-required')
        }
        setLoadIndicator('off')
      })
    }
  }

  const handleChangeComment = (event: React.ChangeEvent <HTMLFormElement | HTMLTextAreaElement>) => {
    setComment({...comment, value: event.target.value})
  }

  const validateComment = () => {
    evaluateLegendValidRequired(expressions.text, comment, setComment, 'modal-order-notification-legend-comment-valid', 'modal-order-notification-legend-comment-required')
  }

  const executeHideModalOrderExport = () => {
    modalHide('modal-order-notification')

    setTimeout( () => {
      restartModal()
    }, 200 )
  }

  function restartModal() {
    legendValidInvalidRestart('modal-order-notification-legend-comment-valid')
    legendValidInvalidRestart('modal-order-notification-legend-comment-required')
    setComment({value: '', valid: false})
  }

  return (
    <div id="modal-order-notification" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog mw-500px">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="text-capitalize">{lang.labels.orderNotification}</h2>
          </div>
          <div className="modal-body">
            <div className="form">
              <div className="text-center fs-6 mb-5">
                <h5>{lang.labels.youWantToChangeOrderStatusToCanceled}</h5>
                <label>{lang.labels.youWillNotBeAbleToUndo}</label>
              </div>
              <div className="fv-row">
                <label className="form-label required">{lang.labels.message}</label>
                <TextareaAutosize className="form-control form-control-solid" name="comment" minRows={2} maxRows={3} value={comment.value} onChange={handleChangeComment} onKeyUp={validateComment} onBlur={validateComment} />
                <AppLegend component={"modal-order-notification"} attribute={{validity: comment.valid, name: "comment", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
          </div>
          <div className="modal-footer flex-center">
            <button className="btn btn-primary mx-2" type="button" data-kt-indicator={loadIndicator} onClick={submitOrderStatus}>
              <span className="indicator-label">{lang.labels.yesCanceled}</span>
              <span className="indicator-progress">
                {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
            <button className="btn btn-light mx-2" type="reset" onClick={executeHideModalOrderExport}>{lang.labels.noCancel}</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppModalOrderNotification;
