import React, { useEffect, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { UseOutletContextOrder } from './Order';
import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { getError } from '../../../../languages/translations/response';
import { OrderDA } from '../../../../services/order.service';
import { Error } from '../../../../models/error.model';
import { CompanyOrders } from '../../../../models/company.orders.model';
import { Order } from '../../../../models/order.model';
import { OrderGroup } from '../../../../models/order.group.model';
import { moneyFormat } from '../../../../scripts/format.value.script';
import { dateFormatAbbreviated } from '../../../../scripts/datetime.format.script';
import { colorStatusOrder, colorValidUntilQuote } from '../../../../scripts/badge.color.script';
import { accumulatedAmountDocuments } from '../../../../scripts/computed.amount.script';
import { nameGroup } from '../../../../libraries/group.name.library';
import { statusDocumentValue } from '../../../../libraries/status.document.library';
import { countryName } from '../../../../libraries/country.library';

import AppSaleRecordList from '../card/SaleRecordList';
import AppSortTable from '../../../../components/element/SortTable';
import AppPreloader from '../../../../components/Preloader';

export interface OrderListPageProps {};

let errorResponse: Error, ordersResponse: CompanyOrders;

const OrderListPage: React.FunctionComponent<OrderListPageProps> = props => {
  const {setRoute, companyForUser, contactsForCompany} = UseOutletContextOrder()
  const {lang} = StateLanguage()
  const navigate = useNavigate()

  const [mounted, setMounted] = useState(false)
  const [button, setButton] = useState({filter: false, group: false})
  const [filter, setFilter] = useState({interval: '', start_date: '', end_date: '', contact: '', treasury_account: '', status: '', expiration: '', validity: '', payment: '', sort_field: '', sort_mode: '', group: ''})
  const [orders, setOrders] = useState<Order[] | undefined | null>(null)
  const [orderGroups, setOrderGroups] = useState<OrderGroup[] | undefined | null>(null)
  const [storage, setStorage] = useState(null)

  const loadOrders = async (id_company: string) => {
    let grouped: boolean = false
    let filtered: boolean = false

    await OrderDA.getOrders(id_company, filter.interval, filter.start_date, filter.end_date, filter.contact, filter.treasury_account, filter.status, filter.expiration, filter.sort_field, filter.sort_mode, filter.group).then( (response) => {
      if (response.status === 200) {
        ordersResponse = response.data

        localStorage.setItem('filter_orders', JSON.stringify({ 
          company_id: ordersResponse.id_company,
          interval: ordersResponse.filter.interval,
          start_date: ordersResponse.filter.start_date, 
          end_date: ordersResponse.filter.end_date, 
          contact: ordersResponse.filter.contact,
          //treasury_account: ordersResponse.filter.treasury,
          status: ordersResponse.filter.status,
          expiration: ordersResponse.filter.expiration,
          sort_field: ordersResponse.filter.sort_field,
          sort_mode: ordersResponse.filter.sort_mode,
          group: ordersResponse.filter.group_by
        }))

        filtered = (ordersResponse.filter.interval === '' && ordersResponse.filter.start_date === '' && ordersResponse.filter.end_date === '' && ordersResponse.filter.contact === '' && ordersResponse.filter.treasury === '' && ordersResponse.filter.status === '' && ordersResponse.filter.expiration === '') ? false : true
        grouped = (ordersResponse.filter.group_by === '') ? false : true

        if (ordersResponse.filter.group_by === '') {
          setOrderGroups([])
          setOrders(ordersResponse.orders)
        } else {
          setOrders([])
          setOrderGroups(ordersResponse.groups)
        }

        setButton({filter: filtered, group: grouped})
        setStorage((localStorage.getItem('filter_orders')) ? JSON.parse(localStorage.getItem('filter_orders')!) : null)
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.code),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.okGotIt,
          customClass: {confirmButton: 'btn btn-primary'}
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      navigate('/error')
    })
  }

  const executeSortList = (item: string) => {
    if (companyForUser) {
      if (item === filter.sort_field) {
        switch (filter.sort_mode) {
          case 'asc':
            filter.sort_mode = 'desc'
            break;
          case 'desc':
            filter.sort_mode = 'asc'
            break;
        }
      } else {
        filter.sort_field = item
        filter.sort_mode = 'asc'
      }
      loadOrders(companyForUser.id)
    }
  }

  useEffect( () => {
    let filter_orders: any = (localStorage.getItem('filter_orders')) ? JSON.parse(localStorage.getItem('filter_orders')!) : null

    if (companyForUser) {
      filter.interval = (filter_orders && filter_orders.company_id === companyForUser.id) ? filter_orders.interval : ''
      filter.start_date = (filter_orders && filter_orders.company_id === companyForUser.id) ? filter_orders.start_date : ''
      filter.end_date = (filter_orders && filter_orders.company_id === companyForUser.id) ? filter_orders.end_date : ''
      filter.contact = (filter_orders && filter_orders.company_id === companyForUser.id) ? filter_orders.contact : ''
      //filter.treasury_account = (filter_orders && filter_orders.company_id === companyForUser.id) ? filter_orders.treasury_account : ''
      filter.status = (filter_orders && filter_orders.company_id === companyForUser.id) ? filter_orders.status : ''
      filter.expiration = (filter_orders && filter_orders.company_id === companyForUser.id) ? filter_orders.expiration : ''
      filter.sort_field = (filter_orders && filter_orders.company_id === companyForUser.id) ? filter_orders.sort_field : ''
      filter.sort_mode = (filter_orders && filter_orders.company_id === companyForUser.id) ? filter_orders.sort_mode : ''
      filter.group = (filter_orders && filter_orders.company_id === companyForUser.id) ? filter_orders.group : ''

      setButton({filter: false, group: false})
      setOrders(null)
      setOrderGroups(null)

      loadOrders(companyForUser.id)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyForUser])

  useEffect( () => {
    setMounted(true)
    setRoute({path: {root: lang.labels.orders, branch: lang.labels.listOrders}, company: true})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { companyForUser && contactsForCompany && orders && orderGroups
        ?
        <div className="card card-flush">
          <AppSaleRecordList sale={"order"} storage={storage} button={button} filter={filter} records={orders} recordGroups={orderGroups} setFilter={setFilter} loadSaleRecord={loadOrders}></AppSaleRecordList>
          <div className="card-body pt-0">
            <div className="dataTables_wrapper">
              <div className="table-responsive">
                <table className="table align-middle table-row-dashed mb-2">
                  <thead className="border-bottom">
                    <tr className="text-start text-muted text-uppercase fw-bolder fs-7">
                      <th className="w-100px">
                        <div className="d-flex align-items-center text-nowrap cursor-pointer" onClick={() => executeSortList("created date")}>
                          <span className="me-1">{lang.labels.date}</span>
                          <AppSortTable attribute={"created date"} field={filter.sort_field} mode={filter.sort_mode}></AppSortTable>
                        </div>
                      </th>
                      <th className="w-125px">
                        <div className="d-flex align-items-center text-nowrap cursor-pointer" onClick={() => executeSortList("order number")}>
                          <span className="me-1">{lang.labels.orderNumber}</span>
                          <AppSortTable attribute={"order number"} field={filter.sort_field} mode={filter.sort_mode}></AppSortTable>
                        </div>
                      </th>
                      <th className="min-w-100px">
                        <div className="d-flex align-items-center text-nowrap cursor-pointer" onClick={() => executeSortList("customer")}>
                          <span className="me-1">{lang.labels.customer}</span>
                          <AppSortTable attribute={"customer"} field={filter.sort_field} mode={filter.sort_mode}></AppSortTable>
                        </div>
                      </th>
                      <th className="min-w-150px">
                        <div className="d-flex align-items-center text-nowrap cursor-pointer" onClick={() => executeSortList("concerning")}>
                          <span className="me-1">{lang.labels.concerning}</span>
                          <AppSortTable attribute={"concerning"} field={filter.sort_field} mode={filter.sort_mode}></AppSortTable>
                        </div>
                      </th>
                      <th className="min-w-100px">
                        <div className="d-flex align-items-center text-nowrap cursor-pointer" onClick={() => executeSortList("delivery address")}>
                          <span className="me-1">{lang.labels.deliveyAddress}</span>
                          <AppSortTable attribute={"delivery address"} field={filter.sort_field} mode={filter.sort_mode}></AppSortTable>
                        </div>
                      </th>
                      <th className="min-w-100px">
                        <div className="d-flex align-items-center text-nowrap cursor-pointer" onClick={() => executeSortList("valid until")}>
                          <span className="me-1">{lang.labels.validUntil}</span>
                          <AppSortTable attribute={"valid until"} field={filter.sort_field} mode={filter.sort_mode}></AppSortTable>
                        </div>
                      </th>
                      <th className="min-w-100px">
                        <div className="d-flex align-items-center text-nowrap cursor-pointer" onClick={() => executeSortList("status")}>
                          <span className="me-1">{lang.labels.status}</span>
                          <AppSortTable attribute={"status"} field={filter.sort_field} mode={filter.sort_mode}></AppSortTable>
                        </div>
                      </th>
                      <th className="min-w-100px text-end">
                        <div className="d-flex align-items-center justify-content-end text-nowrap cursor-pointer" onClick={() => executeSortList("amount total")}>
                          <span className="me-1">{lang.labels.amountTotal}</span>
                          <AppSortTable attribute={"amount total"} field={filter.sort_field} mode={filter.sort_mode}></AppSortTable>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  { orders.length > 0 || orderGroups.length > 0
                    ?
                    <>
                      { button.group
                        ?
                        <>
                          { orderGroups.map (( (item, index) => { return (
                            <tbody key={index} className="text-gray-700 fw-bold">
                              <tr>
                                <td colSpan={8} className="text-gray-700 fw-bolder fs-7 text-uppercase">
                                  <div className="d-flex align-items-center">
                                    <span className="svg-icon svg-icon-3 svg-icon-gray-900 me-5">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M22 12C22 12.9 21.9 13.8 21.7 14.6L5 4.89999C6.8 3.09999 9.3 2 12 2C17.5 2 22 6.5 22 12Z" fill="black"/>
                                        <path opacity="0.3" d="M3.7 17.5C2.6 15.9 2 14 2 12C2 9.20003 3.1 6.70002 5 4.90002L9.3 7.40002V14.2L3.7 17.5ZM17.2 12L5 19.1C6.8 20.9 9.3 22 12 22C16.6 22 20.5 18.8 21.7 14.6L17.2 12Z" fill="black"/>
                                      </svg>
                                    </span>
                                    <span className="me-3">{lang.labels.group} {index + 1}:</span>
                                    {nameGroup(lang, filter.group, item.group_detail)}
                                  </div>
                                </td>
                              </tr>
                              { item.orders.map (( (sub_item, sub_index) => { return (
                                <tr key={sub_index} className="row-dashed">
                                  <td className="text-nowrap">{dateFormatAbbreviated(lang.code, sub_item.created_date)}</td>
                                  <td>
                                    <Link to={"/app/sale/order/view/" + sub_item.id} className="fw-bolder text-nowrap text-dark text-hover-primary">{sub_item.order_number}</Link>
                                  </td>
                                  <td>{sub_item.customer.type === "person" ? `${sub_item.customer.data.first_name} ${sub_item.customer.data.last_name}` : `${sub_item.customer.data.name}`}</td>
                                  <td>{sub_item.concerning}</td>
                                  <td>{sub_item.delivery_address.number} {sub_item.delivery_address.street}, {sub_item.delivery_address.zip_code}, {sub_item.delivery_address.city}, {countryName(lang.code, sub_item.delivery_address.country)}</td>
                                  <td>
                                    <span className={`badge badge-light-${colorValidUntilQuote(sub_item.status, sub_item.valid_until)} fs-7`}>{dateFormatAbbreviated(lang.code, sub_item.valid_until)}</span>
                                  </td>
                                  <td className="text-capitalize">
                                    <span className={`badge badge-light-${colorStatusOrder(sub_item.status)} fs-7`}>{statusDocumentValue(lang, sub_item.status)}</span>
                                  </td>
                                  <td className="text-end text-nowrap">{sub_item.currency.symbol} {moneyFormat(sub_item.amount.total)}</td>
                                </tr>
                              )}))}
                              <tr className={`text-gray-800 fw-bolder fs-7 ${index < orderGroups.length - 1 && "row-dashed"}`}>
                                <td colSpan={7}></td>
                                <td className="text-end text-nowrap">{companyForUser.currency.symbol} {moneyFormat(accumulatedAmountDocuments(item.orders).total)}</td>
                              </tr>
                            </tbody>
                          )}))}
                        </>
                        :
                        <>
                          <tbody className="text-gray-700 fw-bold">
                            { orders.map (( (item, index) => { return (
                              <tr key={index} className="row-dashed">
                                <td className="text-nowrap">{dateFormatAbbreviated(lang.code, item.created_date)}</td>
                                <td>
                                  <Link to={"/app/sale/order/view/" + item.id}className="fw-bolder text-nowrap text-dark text-hover-primary">{item.order_number}</Link>
                                </td>
                                <td>{item.customer.type === "person" ? `${item.customer.data.first_name} ${item.customer.data.last_name}` : `${item.customer.data.name}`}</td>
                                <td>{item.concerning}</td>
                                <td>{item.delivery_address.number} {item.delivery_address.street}, {item.delivery_address.zip_code}, {item.delivery_address.city}, {countryName(lang.code, item.delivery_address.country)}</td>
                                <td>
                                  <span className={`badge badge-light-${colorValidUntilQuote(item.status, item.valid_until)} fs-7`}>{dateFormatAbbreviated(lang.code, item.valid_until)}</span>  
                                </td>
                                <td className="text-capitalize">
                                  <span className={`badge badge-light-${colorStatusOrder(item.status)} fs-7`}>{statusDocumentValue(lang, item.status)}</span>
                                </td>
                                <td className="text-end text-nowrap">{item.currency.symbol} {moneyFormat(item.amount.total)}</td>
                              </tr>
                            )}))}
                          </tbody>
                          <tfoot>
                            <tr className="text-gray-800 fw-bolder fs-7">
                              <td colSpan={7}></td>
                              <td className="text-end text-nowrap">{companyForUser.currency.symbol} {moneyFormat(accumulatedAmountDocuments(orders).total)}</td>
                            </tr>
                          </tfoot>
                        </>
                      }
                    </>
                    :
                    <tbody>
                      <tr className="text-gray-700 fw-bold">
                        <td valign="top" colSpan={8} className="text-center">{lang.labels.noRecordsFound}</td>
                      </tr>
                    </tbody>
                  }
                </table>
              </div>
            </div>
          </div>
        </div>
        :
        <AppPreloader></AppPreloader>
      }
    </div>
  )
}

export default OrderListPage;
