import React, { Dispatch, SetStateAction } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';

import { UseOutletContext } from '../Main';
import { User } from '../../../models/user.model';
import { AppService } from '../../../models/app.service.model';
import { Company } from '../../../models/company.model';
import { Settings } from '../../../models/settings.model';
import { Contact } from '../../../models/contact.model';
import { TreasuryAccount } from '../../../models/treasury.account.model';

export interface PurchasePageProps {};

type ContexType = {
  setRoute: Dispatch<SetStateAction<{path: {root: string, branch: string} | null, company: boolean} | null>>,
  userLoggedIn: User | undefined | null,
  appServiceForUser: AppService | undefined | null,
  companyForUser: Company | undefined | null,
  settingsForCompany: Settings | null | undefined,
  treasuryAccountsForCompany: TreasuryAccount[] | null | undefined,
  contactsForCompany: Contact[] | undefined | null,
  loadSettingsForCompany: Function,
  loadContactsForCompany: Function
};

const PurchasePage: React.FunctionComponent<PurchasePageProps> = props => {
  const {setRoute, userLoggedIn, appServiceForUser, companyForUser, settingsForCompany, treasuryAccountsForCompany, contactsForCompany, loadSettingsForCompany, loadContactsForCompany} = UseOutletContext()

  return (
    <Outlet context={{setRoute, userLoggedIn, appServiceForUser, companyForUser, settingsForCompany, treasuryAccountsForCompany, contactsForCompany, loadSettingsForCompany, loadContactsForCompany}}></Outlet>
  );
}

export function UseOutletContextPurchase() {
  return useOutletContext<ContexType>()
};

export default PurchasePage;
