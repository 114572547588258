import React, { Dispatch, SetStateAction } from 'react';
import { Dropdown } from 'react-bootstrap';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { legendValid } from '../../tools/legend.data.entry.tool';
import { inventoryTypeList, inventoryTypeValue } from '../../libraries/inventory.type.library';

import AppLegend from './Legend';

export interface AppInputUnitTypeProps {
  component?: string,
  listOptions: {index: number, item: {id: string, type: string, name: string, symbol: string}} | null,
  units: {value: {id: string, type: string, name: string, symbol: string}[], valid: {type: boolean, name: boolean, symbol: boolean}[]} | null,
  setUnits: Dispatch<SetStateAction<{value: {id: string, type: string, name: string, symbol: string}[], valid: {type: boolean, name: boolean, symbol: boolean}[]}>> | null
};

const AppInputUnitType: React.FunctionComponent<AppInputUnitTypeProps> = ({component, listOptions, units, setUnits}) => {
  const {lang} = StateLanguage()

  const handleChangeUnitTypeList = (index: number, item: string) => {
    if (units === null || setUnits === null) return

    let tempUnitsValue = units.value.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item.type = item
      }
      return temp_item
    })
    let tempUnitsValid = units.valid.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item.type = true
      }
      return temp_item
    })

    setUnits({value: tempUnitsValue, valid: tempUnitsValid})
    legendValid('legend-unit-type-required-' + index)
  }

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
          <span className="selection">
            <span className="select2-selection select2-selection--single form-select form-select-solid">
              <span className="select2-selection__rendered" role="textbox">
                {listOptions && units && units.valid[listOptions.index].type ? inventoryTypeValue(lang, listOptions.item.type) : lang.labels.selectOption}
              </span>
            </span>
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
          <span className="select2-dropdown select2-dropdown--below">
            <span className="select2-results">
              <ul className="select2-results__options" role="listbox">
                { listOptions && inventoryTypeList(lang).map (( (sub_item, sub_index) => { return (
                  <li key={sub_index} className={`select2-results__option select2-results__option--selectable ${listOptions.item.type === sub_item.code && "select2-results__option--selected"}`} role="option" aria-selected={listOptions.item.type === sub_item.code}>
                    <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeUnitTypeList(listOptions.index, sub_item.code)}>{sub_item.display}</Dropdown.Item>
                  </li>
                )}))}
              </ul>
            </span>
          </span>
        </Dropdown.Menu>
      </Dropdown>
      { listOptions && units &&
        <AppLegend attribute={{validity: units.valid[listOptions.index].type, name: "unit-type", index: listOptions.index, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
      }
    </>
  )
}

export default AppInputUnitType;
