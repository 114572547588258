import React from 'react';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { Legend } from '../../styles/legend.style';

export interface AppLegendProps {
  component?: string,
  attribute: {validity: boolean, name: string, index: number | null, sub_index: number | null},
  container: {valid: boolean, required: boolean, size: boolean, type: boolean, identical: boolean, exists: boolean, max: boolean}
};

const AppLegend: React.FunctionComponent<AppLegendProps> = ({component, attribute, container}) => {
  const {lang} = StateLanguage()

  return (
    <Legend property={attribute.validity.toString()}>
      { container.valid &&
        <div id={`${component ? `${component}-` : ""}legend-${attribute.name}-valid${attribute.index !== null ? `-${attribute.index}` : ""}${attribute.sub_index !== null ? `${attribute.sub_index}` : ""}`} className="fv-plugins-message-container invalid-feedback d-none">
          <div data-field={attribute.name} data-validator="regexp">{attribute.sub_index === null ? lang.labels.theValueIsNotAValid : lang.labels.invalid}</div>
        </div>
      }
      { container.required &&
        <div id={`${component ? `${component}-` : ""}legend-${attribute.name}-required${attribute.index !== null ? `-${attribute.index.toString()}` : ""}${attribute.sub_index !== null ? `${attribute.sub_index}` : ""}`} className="fv-plugins-message-container invalid-feedback d-none">
          <div data-field={attribute.name} data-validator="notEmpty">{attribute.sub_index === null ? lang.labels.theValueIsRequired : lang.labels.required}</div>
        </div>
      }
      { container.size &&
        <div id={`${component ? `${component}-` : ""}legend-${attribute.name}-size${attribute.index !== null ? `-${attribute.index.toString()}` : ""}${attribute.sub_index !== null ? `-${attribute.sub_index}` : ""}`} className="fv-plugins-message-container invalid-feedback d-none">
          <div data-field={attribute.name} data-validator="size">{lang.labels.fileSizeNotAllowed}</div>
        </div>
      }
      { container.type &&
        <div id={`${component ? `${component}-` : ""}legend-${attribute.name}-type${attribute.index !== null ? `-${attribute.index.toString()}` : ""}${attribute.sub_index !== null ? `-${attribute.sub_index}` : ""}`} className="fv-plugins-message-container invalid-feedback d-none">
          <div data-field={attribute.name} data-validator="type">{lang.labels.invalidFileType}</div>
        </div>
      }
      { container.identical &&
        <div id={`${component ? `${component}-` : ""}legend-${attribute.name}-identical${attribute.index !== null ? `-${attribute.index.toString()}` : ""}${attribute.sub_index !== null ? `-${attribute.sub_index}` : ""}`} className="fv-plugins-message-container invalid-feedback d-none">
          <div data-field={attribute.name} data-validator="identical">{lang.labels.theValueDoesNotMatch}</div>
        </div>
      }
      { container.exists &&
        <div id={`${component ? `${component}-` : ""}legend-${attribute.name}-exists${attribute.index !== null ? `-${attribute.index.toString()}` : ""}${attribute.sub_index !== null ? `-${attribute.sub_index}` : ""}`} className="fv-plugins-message-container invalid-feedback d-none">
          <div data-field={attribute.name} data-validator="exists">{lang.labels.theValueAlreadyExists}</div>
        </div>
      }
      { container.max &&
        <div id={`${component ? `${component}-` : ""}legend-${attribute.name}-max${attribute.index !== null ? `-${attribute.index.toString()}` : ""}${attribute.sub_index !== null ? `-${attribute.sub_index}` : ""}`} className="fv-plugins-message-container invalid-feedback d-none">
          <div data-field={attribute.name} data-validator="max">{lang.labels.limitFilesMaximum}</div>
        </div>
      }
    </Legend>
  )
}

export default AppLegend;
